import React, { useState, useEffect } from 'react';
import { Switch } from '../../Common/CustomSwitch';
import gear from '../../../assets/nav/gear.png';
import Select from '../../Common/Select';

export default function PositionsList(props) {

    const [openList, setOpenList] = useState('');
    const [closedList, setClosedList] = useState('');
    const [group, setGroup] = useState(false);
    const [groups, setGroups] = useState('');
    const [open, setOpen] = useState('');

    useEffect(() => {
        if (props.list) {
            var sort = props.list.sort((a, b) => (a.name > b.name) ? 1 : -1);
            var open = sort.filter(fil => { return !fil.archive });
            var byActive = open.sort((a, b) => (a.active > b.active) ? -1 : 1);
            var closed = sort.filter(fil => { return fil.status === 'closed' });
            setOpenList(byActive);
            setClosedList(closed);
            var newGroups = [];
            props.list.map(i => {
                if (!newGroups.includes(i.aff)) {
                    newGroups.push(i.aff);
                };
            });
            setGroups(newGroups);
        }
    }, [props.list]);

    return (
        <div className="nav-section">
            <div className="menu-label nav-text-color">Current Positions</div>
            <div id="group-switch">
                <Switch id="white" boolean={group} text="By Project" toggleFunction={() => setGroup(!group)} />
            </div>
            <div className="height-20" />
            {
                group ?
                groups.map((i, index) => (
                    <GroupItem key={index} i={i} index={index} openList={openList} />
                ))
                :
                openList ?
                openList.map((item, index) => (
                    <ListItem open={open} setOpen={setOpen} showCandidates={props.showCandidates} key={item.id} item={item} firebase={props.firebase} cid={props.cid} />
                ))
                :
                <div className="null-text nav-text-color">No positions created</div>
            }
        </div>
    )
}

function GroupItem(props) {

    var list = props.openList.filter(fil => { return fil.aff === props.i });

    return (
        <div className="nav-item-card">
            <div className="nav-group-label">{props.i}</div>
            <div className="height-10" />
            {
                list ? list.map((p, ind) => (
                    <div key={p.id} className="position-item">
                        <div className="position-name">{p.name}</div>
                    </div>
                )) : <div className="null-text">No positions created</div>
            }
        </div>
    )
}

function ListItem(props) {

    const [settings, setSettings] = useState(false);

    useEffect(() => {
        if (props.open !== props.item.id) {
            setSettings(false);
        } else {
            setSettings(true);
        }
    }, [props.open])

    function archive(item) {
        props.firebase.apiCompanyPosition(props.cid, item.id)
        .update({
            archive: true,
        });
    };

    function complete(item) {
        props.firebase.apiCompanyPosition(props.cid, item.id)
        .update({
            complete: true,
        })
    };

    function prepSettings(item) {
        if (!settings) {
            props.setOpen(item.id);
            showCandidates(item)
        } else {
            props.setOpen('');
            showCandidates('');
        };
    };

    function showCandidates(item) {
        props.showCandidates(item);
    }

    var item = props.item;
    return (
        <div className="nav-item-card relative" onClick={() => prepSettings(item)} >
            <img className="arrival-card-icon" src={gear} alt="Actor Settings"/>
            <div className="nav-item-card-title">{item.name}</div>
            <div className="position-options">{item.aff}</div>
            <div className={settings ? "height-45" : null}>
            {
                settings ?
                <ListItemSettings item={item} archive={archive} />
                :
                null
            }
            </div>
        </div>
    )
};

function ListItemSettings(props) {

    return (
        <div>
            <button id="archive-position" className="delete-btn" onClick={() => props.archive(props.item)}>Archive</button>
        </div>
    )
}
