import React, { useState, useEffect } from 'react';
import * as ROUTES from '../../../constants/routes';
import menu from '../../../assets/nav/menucollapse.png';

import Details from './Sections/Details';
import Team from './Sections/Team';
import Arrived from './Sections/Arrived';
import Complete from './Sections/Complete';
import Guests from './Sections/Guests';
import Schedule from './Sections/Schedule';
import Summary from './Sections/Summary/Summary';

export default function SessionNav(props) {

    const [open, setOpen] = useState(true);
    const [tab, setTab] = useState('details');
    const [arrivals, setArrivals] = useState('');
    const [summary, setSummary] = useState(false);

    function prepTab(tab) {
        setTab('summary');
    };

    function expandSummary(tab) {
        props.prepSummary()
    };

    useEffect(() => {
        if (open) {
            props.setOpen(true);   
        } else {
            props.setOpen(false);
        }
    }, [open]);

    useEffect(() => {
        if (props.pushOpen) {
            setOpen(true);
            setTab('arrived');
        }
    }, [props.pushOpen]);

    useEffect(() => {
        if (props.cid) {
            props.firebase.apiCompanyGuests(props.cid)
            .orderByChild('complete')
            .equalTo(false)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({
                        ...o[key],
                        id: key,
                    }));
                    setArrivals(l);
                } else {
                    setArrivals('');
                }
            })
        }
    }, [props.cid]);

    const functions = {
        firebase: props.firebase,   
        setTab: setTab,
        prepTab: prepTab,
    }

    const consts = {
        tab: tab,
    }

    function back() {
        props.history.push(ROUTES.HOME);
    }

    useEffect(() => {
        if (props.sessObj.complete) {
            setSummary(true);
        }
    }, [props.sessObj.complete]);

    function _completeSession() {
        props.firebase.apiSession(props.id)
        .update({
            complete: true,
        })
        _activateSession(false);
    };

    function _restartSession() {
        props.firebase.apiSession(props.id)
        .update({
            complete: false,
        });
        _activateSession(true);
    }


    function _activateSession(bool) {
        props.firebase.apiSession(props.id)
        .update({
            active: bool
        });

        if (props.sessObj.positions) {
            var l = Object.keys(props.sessObj.positions).map(key => ({
                id: key,
            }))
            l.map((item, index) => {
                props.firebase.apiCompanyPosition(props.cid, item.id)
                .update({
                    active: bool,
                });
            })
        }
    };

    return (
        <div className={open && !props.mobile ? 
            "s-page-nav nav-color s-nav-ext" : 
            open && props.mobile ? 
            "s-page-mobile-nav nav-color s-nav-ext no-scroll-bars" :
            !open && props.mobile ?
            "s-page-mobile-nav nav-color" : 
            "s-page-nav nav-color"
        }    
        >
        {props.removeToggle ?
            null
            : 
            <img className={props.mobile && !open ? 
            "nav-toggle-mobile ntg-right" : 
            props.mobile && open ? 
            "nav-toggle-mobile" :
            "nav-toggle"} src={menu} alt="Toggle Menu" onClick={() => setOpen(!open)}/>
        }
        <div className={props.fadeInContent ? 'fade-in' : 'fade-out'}>
            
            {open ? <div className="acct-settings-back-btn" onClick={() => back()}>Back to Dashboard</div> : null}
            <div className={open ? '' : "display-none"}>
            <div className="height-10" />
            {/*<StartBar 
                _activateSession={_activateSession} 
                _completeSession={_completeSession}
                _restartSession={_restartSession}
                active={props.sessObj.active}
                complete={props.sessObj.complete}    
            />*/}
            <Tabs functions={functions} consts={consts} summary={summary} />
            <div className="height-10" />
            <div className="sess-nav-scroll no-scroll-bars">
            {
                tab === 'details' ? 
                <Detail 
                    uid={props.uid} 
                    cid={props.cid} 
                    sessObj={props.sessObj} 
                    id={props.id} 
                    props={props} 
                    functions={functions} 
                    setOnlineOpen={props.setOnlineOpen}
                    onlineOpen={props.onlineOpen}
                /> :
                tab === 'arrived' ? 
                <div>
                <Arrived 
                    uid={props.uid} 
                    cid={props.cid}
                    arrivals={arrivals} 
                    sessObj={props.sessObj} 
                    id={props.id} 
                    firebase={props.firebase} 
                    setOpen={setOpen}
                />
                {/*<Schedule 
                    uid={props.uid} 
                    cid={props.cid}
                    arrivals={arrivals} 
                    sessObj={props.sessObj} 
                    id={props.id} 
                    firebase={props.firebase} 
                />*/}
                <Complete 
                    sessObj={props.sessObj}
                    id={props.id} 
                    firebase={props.firebase} 
                    cid={props.cid}
                    pushScroll={props.pushScroll}
                />
                </div> :
                tab === 'summary' ?  
                <Summary 
                    sessObj={props.sessObj}
                    id={props.id} 
                    uid={props.uid}
                    firebase={props.firebase} 
                    cid={props.cid}
                    username={props.username}
                    cname={props.cname}
                    expandSummary={expandSummary}
                />
                :
                <Users 
                    sessObj={props.sessObj}
                    id={props.id} 
                    uid={props.uid}
                    firebase={props.firebase} 
                    cid={props.cid}
                    username={props.username}
                    cname={props.cname}

                />
            }
            </div>
            </div>
            </div>
        </div>
    )
};

function Tabs(props) {
    return (
        <div className="" id="nav-tabs" >
            <div 
                id={props.consts.tab === 'details' ? 'tab-sel' : null}
                className="s-page-tab nav-tab-color nav-button-color"
                onClick={() => props.functions.setTab('details')}
            >
                Details
            </div>
            <div 
                id={props.consts.tab === 'team' ? 'tab-sel' : null}
                className="s-page-tab nav-tab-color nav-button-color"
                onClick={() => props.functions.setTab('team')}
            >
                Team
            </div>
            <div 
                id={props.consts.tab === 'arrived' ? 'tab-sel' : null}
                className="s-page-tab nav-tab-color nav-button-color"
                onClick={() => props.functions.setTab('arrived')}
                >
                Queue
            </div>
            <div 
                id={props.consts.tab === 'summary' ? 'tab-sel' : null}
                className="s-page-tab nav-tab-color nav-button-color"
                onClick={() => props.functions.prepTab('summary')}
                >
                Summary
            </div>
        </div>
    )
}

function Users(props) {
    return (
        <div>
            <Team id={props.id} cid={props.cid} username={props.username} firebase={props.firebase} uid={props.uid} team={props.sessObj.team} />
            <Guests cname={props.cname} id={props.id} cid={props.cid} firebase={props.firebase} uid={props.uid} guests={props.sessObj.guests} />
        </div>
    )
}

function Detail(props) {

    return (
        <div>
            <Details 
                uid={props.uid} 
                cid={props.cid} 
                sessObj={props.sessObj} 
                active={props.sessObj.active}
                id={props.id} 
                functions={props.functions} 
                setOnlineOpen={props.setOnlineOpen}
                onlineOpen={props.onlineOpen}
            />
        </div>
    )
}

function StartBar(props) {

    return (
        <div className="start-nav">
        {
            props.complete ? 
                <button className="start-button nav-button-color" onClick={() => props._restartSession()}>Activate Session</button>
                :
                props.active ?
                null : 
                <button className="start-button nav-button-color" onClick={() => props._activateSession(true)}>Start Session</button>
            }
            {
            !props.complete ?
            <button className="start-button nav-button-color" onClick={() => props._completeSession()}>Stop Session</button>
            :
            null
            }
            
        </div>
    )
}
