import React, { useState } from 'react';

import SlotItem from './SlotItem';

const TalentSection = (props) => {

    const [swap, setSwap] = useState('');
    const [swapActive, setSwapActive] = useState(false);
    const [slot1Index, setSwap1Index] = useState('');

    function setSwapSlot(slot, index) {
        setSwap(slot); 
        setSwap1Index(index);
        setSwapActive(true);
    }

    function selectSwap(slot, index) {
        props.firebase.slotSwitch(props.uid, props.state.sessKey, swap, slot, slot1Index, index, props.state.groupKey);
        setSwap('');
        setSwap1Index('');
        setSwapActive(false);
    };


    return (
        <div className="nav-section-container">
            <div className="nav-section-header-text">Audition Schedule</div>
            
            {
            props.state.scheduleCreated ?
            <div />
            :
            <div className="nav-section-section">
                <div className="session-modal-label">Before you create a schedule, how long will each audition be?</div>
                <input className="increment-input" type='number' name="increment" value={props.state.increment} onChange={props._onChange.bind(this)} /> minutes
                {
                props.state.increment !== '' ? 
                    <div className="generate-btn-container">
                        <button onClick={props._generateSlots} className="quick-save-btn generate-btn">Generate Schedule</button>
                    </div>
                    :
                    <div />
                }
                </div>
            }
            <div className="nav-section-section">
            {
                props.state.scheduleCreated ?
                <div>
                <div>Schedule</div>
                    <div className="nav-modal-schedule-list">
                        {
                            props.state.sessSlotTimes ?
                            props.state.sessSlotTimes.map((slot, index) => (
                                <SlotItem 
                                    key={slot.time}
                                    index={index}
                                    state={props.state}
                                    slot={slot} 
                                    _onChange={props._onChange}
                                    _onSearchTalent={props._onSearchTalent}
                                    _generateSlots={props._generateSlots}  
                                    uid={props.uid}
                                    firebase={props.firebase}
                                    _currentShareModal={props._currentShareModal}
                                    _hoverIndex={props._hoverIndex}
                                    _hoverOut={props._hoverOut}
                                    setSwapSlot={setSwapSlot}
                                    selectSwap={selectSwap}
                                    swapActive={swapActive}
                                    swap={swap}
                                />
                            ))
                            :
                            <div />
                        }
                    </div>
                </div>
                :
                <div />
            }
            </div>
            <div className="nav-sess-buttons-container">
                <button className="nav-sess-btn" onClick={props._toSessionDetails}>Previous</button>
                <button className="nav-sess-btn" onClick={props._toSessionOperations}>Next</button>
            </div>
        </div>
    )
}

export { TalentSection };