import React, { useState, useEffect } from 'react';
import * as ROUTES from '../../constants/routes';
import moment from 'moment';

import SessionSummary from '../Modals/SessionSummary/SessionSummary';
import SessionList from './SessionList/SessionList';
import Company from './Pages/Company';
import MyAccount from './Pages/MyAccount';
import Candidates from './Pages/Candidates';
import FilterDropdown from '../Common/FilterDropdown';
import { Input } from '../Common';
import { ObjectList } from '../Functions/Common';

export default function Content(props) {
    
    const [sessions, setSessions] = useState('');
    const [filtered, setFiltered] = useState('');
    const [filter, setFilter] = useState('Today');
    const [date, setDate] = useState(moment().format('MM/DD/YYYY'));


    var options = ['All', 'Previous', 'Today', 'Upcoming'];

    useEffect(() => {
        if (props.userdata.cid) {
            props.firebase.apiSessions()
            .orderByChild('company')
            .equalTo((props.userdata.cid))
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({
                        ...o[key],
                        id: key,
                    }))
                    setSessions(l);
                    if (filter === 'Today') {
                        var filters = l.filter(fil => {
                            return fil.date === date
                        });
                        setFiltered(filters);
                    }
                } 
            });
        };
    }, [props.userdata.cid]);

    const [search, setSearch] = useState('');
    const [searchActive, setSearchActive] = useState(false);
    const [searchMode, setSearchMode] = useState(false);

    useEffect(() => {
        if (search.length >= 3 && !searchActive) {
            setSearchActive(true);
        } 

        if (search.length < 3) {
            setSearchActive(false);
        }
    }, [search]);

    function filterSessions(fil) {
        if (fil === 'Today') {
            setFiltered(sessions.filter(fi => {
                return fi.date === date
            }));
            setFilter(fil);
        } else
            if (fil === 'Upcoming') {
            setFiltered(sessions.filter(fi => {
                return moment(fi.date, 'MM/DD/YYYY').isAfter(moment().format('MM/DD/YYYY'));
            }))
            setFilter(fil);
        } else if (fil === 'Previous') {
            setFiltered(sessions.filter(fi => {
                return moment(fi.date, 'MM/DD/YYYY').isBefore(moment().format('MM/DD/YYYY'));
            }))
            setFilter(fil);
        } else if (fil === 'All') {
            setFiltered(sessions);
            setFilter(fil);
        }
    }
    
    function newSession() {
        var key = props.firebase.db.ref('api/sessions/').push().key;
        var timestamp = moment().format('MM/DD/YYYY hh:mm a');
        var day = moment().format('MM/DD/YYYY');
        props.firebase.apiNewSession(props.userdata.uid, props.userdata.cid, key, timestamp, day, 'inactive');
        props.history.push(ROUTES.SESSION + '/' + key);
    }

    function openSession(item) {
        props.history.push(ROUTES.SESSION + '/' + item);
    }

    const [sum, setSum] = useState('');
    const [fadeModalIn, setFadeModalIn] = useState(false);

    function setSessSum(item) {
        if (item) {
            setSum(item);
            var fadeInTimer = setTimeout(() => {
                setFadeModalIn(true);
            }, 100);
            return (() => clearTimeout(fadeInTimer));
        } else {
            setFadeModalIn(false);
            var fadeOutTimer = setTimeout(() => {
                setSum('');
            }, 500);
            return (() => clearTimeout(fadeOutTimer));
        }
    };
    
    function prepSessSum(id) {
        setSessSum(id);
    };

    const [sPositions, setSPositions] = useState([]);
    const [sProjects, setSProjects] = useState([]);
    const [sSubjects, setSSubjects] = useState([]);
    const [sSessions, setSSessions] = useState([]);

    function clearResults() {
        setSPositions([]);
        setSProjects([]);
        setSSubjects([]);
        setSSessions([]);
    }

    function searchList(e) {
        var wait = true; 
        clearResults();
        var sess = [];
        var posi = [];
        var proj = [];
        var subj = [];
        wait = false;
            if (sessions && !wait) {
            sessions.map((s, index) => {
                var positions = s.positions ? ObjectList(s.positions) : '';
                var subjects = s.subjects ? ObjectList(s.subjects) : '';
                if (s.title.toLowerCase().includes(search.toLowerCase())) {
                    sess.push({session: s.id, sessionName: s.title, date: s.date});
                    setSSessions(sess);
                };
                if (positions) {
                    positions.map((p, index) => {
                        if (p.name.toLowerCase().includes(search.toLowerCase())) {
                            posi.push({
                                session: s.id, 
                                sessionName: s.title, 
                                position: p.name,
                                date: s.date,});
                            setSPositions(posi);
                        };
                        if (p.aff.toLowerCase().includes(search.toLowerCase())) {
                            var similar = proj.map(i => i.project);
                            if (!similar.includes(p.aff)) {
                                proj.push({
                                    session: s.id, 
                                    sessionName: s.title, 
                                    project: p.aff,
                                    date: s.date,
                                });
                                setSProjects(proj);
                            }
                        };
                    });
                };
                if (subjects) {
                    subjects.map((g, index) => {
                        if (g.name.toLowerCase().includes(search.toLowerCase())) {
                            subj.push({
                                session: s.id, 
                                name: g.name,
                                sessionName: s.title, 
                                project: g.interview.aff, 
                                position: g.interview.name,
                                date: s.date,
                            })
                            setSSubjects(subj);
                        }
                    })
                }
            });
            setSearchMode(true);
        };
    };

    function backToList() {
        clearResults();
        setSearch('');
        setSearchMode(false);
    }

    function clearSearch() {
        clearResults();
        setSearch('');
    }

    const [page, setPage] = useState('sessions');
    const [fadePageIn, setFadePageIn] = useState(true);

    useEffect(() => {
        if (props.page) {
            setFadePageIn(false);
            var pageTimer = setTimeout(() => {
                setPage(props.page);
                setFadePageIn(true);
            }, 500);
            return (() => clearTimeout(pageTimer));
        }
    }, [props.page]);



    return (
        <div className="dash-content">
            <div className={fadePageIn ? 'fade-in' : 'fade-out'}>
                {
                    page === 'company' ?
                    <Company userdata={props.userdata} firebase={props.firebase} />
                    :
                    page === 'mysettings' ?
                    <MyAccount userdata={props.userdata} firebase={props.firebase} /> :
                    page === 'candidates' ?
                    <Candidates 
                        candidates={props.candidates} 
                        positionFocus={props.positionFocus}
                        userdata={props.userdata} 
                        firebase={props.firebase}
                    /> :
                    <Sessions options={options} 
                        filter={filter} 
                        filtered={filtered} 
                        filterSessions={filterSessions} 
                        openSession={openSession}
                        newSession={newSession}   
                        setSessSum={setSessSum}  
                        sum={sum}
                        open={props.open}
                        mobile={props.mobile}
                        arrivals={props.userdata.arrivals}
                        search={search}
                        setSearch={setSearch}
                        searchActive={searchActive}
                        searchList={searchList}
                        searchMode={searchMode}
                        sPositions={sPositions} 
                        sProjects={sProjects} 
                        sSubjects={sSubjects} 
                        sSessions={sSessions} 
                        clearSearch={clearSearch}
                        prepSessSum={prepSessSum}
                        backToList={backToList}
                    /> 
                }
                {
                    sum ?
                    <div className={fadeModalIn ? "fade-in" : "fade-out"}>
                    <SessionSummary session={sum} firebase={props.firebase} setSessSum={setSessSum} />
                    </div>
                    :
                    null 
                }
            </div>
        </div>
    )
}

function Sessions(props) {

    return (
        <div className="dash-section no-scroll-bars">
            <div className="dash-header">Team Sessions</div>
            <div className="dash-header-container">
            <SearchBar 
                search={props.search} 
                setSearch={props.setSearch} 
                searchActive={props.searchActive}
                searchList={props.searchList}
                clearSearch={props.clearSearch}
                backToList={props.backToList}
                searchMode={props.searchMode}
            />
            {
                props.mobile && props.open ?
                null 
                :
                props.searchMode ?
                <div />
                :
                <div className="content-session-btns">
                    <button className="dash-session-btn content-button-color" onClick={() => props.newSession()}>New Session</button>
                    <div className={props.sum ? "dash-sess-dropdown" : "dash-sess-dropdown drop-open"}>
                        <FilterDropdown id="filter-sessions-filter" options={props.options} default={props.filter} function={(e) => props.filterSessions(e)}/>
                    </div>
                </div> 
            }
            </div>
            {
            props.searchMode ?
            <SessionSearch 
                pos={props.sPositions} 
                pro={props.sProjects} 
                sub={props.sSubjects} 
                ses={props.sSessions} 
                prepSessSum={props.prepSessSum}
            />
            :
            props.filtered && props.filtered.length > 0 ?
            <div className="content-list-scroll no-scroll-bars">
                <SessionList 
                    filtered={props.filtered} 
                    openSession={props.openSession} 
                    setSessSum={props.setSessSum} 
                    arrivals={props.arrivals}
                />
            </div>
            :   
            <div className="content-null-text">No sessions created for this time period, use the filter on the top right to change it.</div>
            }
        </div>
    )
}

function SearchBar(props) {

    function enterFunction(e) {
        if (e.keyCode === 13) {
            props.searchList()
        };
    };


    return (
        <div className="dash-search-bar">
            <Input className="dash-search" onKeyDown={(e) => enterFunction(e)} value={props.search} onChange={(e) => props.setSearch(e.target.value)} placeholder="Search sessions" />
            {
                props.searchActive || props.searchMode ?
                <div className="search-buttons">
                    <button disabled={!props.searchActive} onClick={() => props.searchList()}>Search</button> 
                    {props.searchMode ?
                        <button onClick={() => props.clearSearch()}>Clear Results</button> : <div />}
                    {props.searchMode ? 
                    <button onClick={() => props.backToList()}>Back to List</button> : <div />}
                </div> : null
            }
        </div>
    )
}

function SessionSearch(props) {

    const [sessions, setSessions] = useState('');

    useEffect(() => {
        if (props.ses) {
            setSessions(props.ses);
        } else {
            setSessions('');
        }
    }, [props.ses])

    return (
        <div className="session-search">
            {sessions.length > 0 ?
            <div className="search-section">
                <div className="search-section-label">Session Title</div>
                {
                    sessions.map((item, index) => (
                        <div key={index} className="searched-item" onClick={() => props.prepSessSum(item.session)} >
                            <div>
                                <div className="search-item-label">{item.sessionName}</div>
                                <div className="search-item-sub">Date: {item.date}</div>
                            </div>
                            <div> 
                                <button className="search-item-button">View Summary</button>
                            </div>

                        </div>
                    ))
                }
            </div>     
            :
            <div></div> 
            }      
            {props.pro.length > 0 ?
            <div className="search-section">
                <div className="search-section-label">Project Name</div>
                    {props.pro.map((item, index) => (
                        <div key={index} className="searched-item" onClick={() => props.prepSessSum(item.session)}>
                            <div>
                                <div className="search-item-label">{item.project}</div>
                                <div className="search-item-sub">Session: {item.sessionName}</div>
                            </div>
                            <div> 
                            <button className="search-item-button">View Summary</button>
                            </div>
                        </div>
                    ))
                }
            </div>
            :
            null 
            }
            {props.pos.length > 0 ?
            <div className="search-section">
                <div className="search-section-label">Positions</div>
                {
                    props.pos.map((item, index) => (
                        <div key={index} className="searched-item" onClick={() => props.prepSessSum(item.session)}>
                            <div>
                                <div className="search-item-label">{item.position}</div>
                                <div className="search-item-sub">Session: {item.sessionName}</div>
                            </div>
                            <div> 
                            <button className="search-item-button">View Summary</button>
                            </div>
                        </div>
                    ))
                }
            </div>
            :
            null 
            }
            {
                props.sub.length > 0 ?
                <div className="search-section">
                    <div className="search-section-label">Candidates</div>
                    {
                        props.sub.map((item, index) => (
                            <div key={index} className="searched-item" onClick={() => props.prepSessSum(item.session)}>
                                <div>
                                    <div className="search-item-label">{item.name}</div>
                                    <div className="search-item-sub">{item.project} for '{item.position}'</div>
                                    <div className="search-item-sub">Session: {item.sessionName}</div>
                                </div>
                                <div> 
                                <button className="search-item-button">View Summary</button>
                                </div>
                            </div>
                        ))
                    }
                </div>
                :
                null
            }
        </div>
    )
}

