import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import AddASecondRoom from './CastingSession/AddASecondRoom';

import './home.css';

import tag from '../../assets/tag.png';
import speechbubble from '../../assets/speech-bubble.png';
import staroff from '../../assets/starOff.png';
import gearhover from '../../assets/gearhover.png';

import { NullUser } from '../../assets/nulluser';

const CastingSessionItem = (props) => {

    const manualDrop = props.manualDrop;
    const checkInToDrop = props.checkInToDrop;

    const [room2, setRoom2] = useState(false)

    var session = props.item;
    var to = session.talent;
    var uo = session.users;
    var ro = session.roles;
    if (uo) { var users = Object.keys(uo).map(key => ({
        ...uo[key], id: key,
    }));
    }
    if (ro) { var roles = Object.keys(ro).map(key => ({
        ...ro[key], id: key,
    }));
    }
    if (to) {var talent = Object.keys(to).map(key => ({
        ...to[key], id: key,
    }));
    }

    var talentCount = talent ? talent.length : 0;
    var noteCount = 0;
    var allNotes = [];
    if (talent) {
        talent.map(i => {
        var talentNotes = i.notes;
        if (i.notes) {
            var notesList = Object.keys(talentNotes).map(key => ({
            ...talentNotes[key],
            id: key,
        }))
        
        if (notesList) {
            notesList.map(n => {
                return allNotes.push({
                    n
                })
            })
        }
        noteCount = allNotes.length || 0;
        }
    })
    };
    var tagCount = 0;
    var allTags = [];
    if (talent) {talent.map(i => {
        var talentTags = i.tags;
        if (talentTags) {
        var tagList = Object.keys(talentTags).map(key => ({
            ...talentTags[key],
            id: key,
        }))
        if (tagList) {
            tagList.map(t => {
                return allTags.push({
                    t
                });
            })
        }
        tagCount = allTags.length || 0;
        }
    });
    };
    


    return (
        <div key={session.id} className={manualDrop ? "session-card dropping-outline" : "session-card"} 
            onClick={manualDrop ? props.addCheckInToSession.bind(this, session) : null}
        >
            {
            room2 ?
            <AddASecondRoom setRoom2={setRoom2} _prepSession={props._prepSession} uid={props.uid} session={session} firebase={props.firebase} />
            :
            <div>
            <button className="session-edit-icon-container" onClick={props._editSessionModal.bind(this, props.item)}>
                <img className="session-edit-icon" src={gearhover} alt="Edit Session" />
            </button>
            <div className="session-card-header">
                <div>
                    <div className="display-users">
                        <div className="display-users-container">
                            {users ? users.map((user, index) => (
                                <div key={user.id} className="user-container">
                                    <div className="user-card">
                                        <img className="user-sess-avatar" src={user.avatar ? user.avatar : NullUser} alt="Profile Pic" />
                                    </div>
                                </div>
                            )) : <div></div> }
                        </div>
                    </div>
                    <div className="session-card-topline">
                        <div className="session-card-name">{session.name ? session.name: 'Draft'}</div>
                        <div className="session-card-date">{session.date ? session.date: ''}</div>
                    </div>

                    <div className="current-progress">{session.complete ? 'Session Complete' : 'Session Progress'}</div>
                    {
                        session.complete ?

                        <div className="access-session-notes" onClick={props._openSessionModal.bind(this, props.item)}>
                        View Session Notes
                        </div>
                        :
                        <div className="session-progress-bar">
                            <div className={
                                !session.prepped ? 
                                "progress-1 indicate-push-mode" : 
                                "progress-1 complete-p1"
                            }
                                onClick={
                                    !session.prepped ? 
                                    props._prepSession.bind(this, props.item) :
                                    null
                                }
                            >
                                {
                                !session.prepped ? 
                                    'Prep' : 
                                    'Prepped'
                                }
                            </div>

                            <div className={
                                session.prepped && !session.active ?
                                "progress-2 indicate-push-mode" :
                                !session.prepped ?
                                "progress-2 disabled-progress" :
                                "progress-2 complete-p2" 
                            }
                                onClick={props._activateSession.bind(this, props.item)}>
                                
                                {
                                    !session.active ? 'Start' : 'In Session' 
                                }
                            </div>

                            <div className={
                                session.active && !session.complete ?
                                "progress-3 indicate-push-mode" :
                                !session.prepped ?
                                "progress-3 disabled-progress" :
                                session.prepped && !session.active ?
                                "progress-3 disabled-progress" :
                                "progress-3 complete-p1" 
                            } onClick={props._completeCastingSession.bind(this, props.item)}>
                            
                                {
                                    !session.complete ? 'Wrap' : 'Complete' 
                                }
                                
                            </div>
                        </div>
                    }
                    <div className="session-progress-status">
                        {
                            !session.prepped ?
                            'Click prep to prepare the form and room' 
                            :
                            session.prepped && !session.active ?
                            'Click start to list the session as active' : 
                            session.active && !session.complete ?
                            'Once the session is finished, press wrap to clear the form and the room' :
                            null
                        }
                    </div>
                    <div>
                        {
                    (session.prepped && !session.complete && !session.paused) || 
                    (session.started && !session.complete && !session.paused) ?
                        <Link 
                            to={ROUTES.ROOM + '/' + session.room.value}
                        >
                        <button 
                            className="open-session-room-btn"
                            onClick={props._openSessionRoom}
                            >
                            Open
                        </button>
                        </Link>
                        :
                        <div />
                    }
                    </div>

                    

                    <div className="display-session-project">
                        <div className="session-card-header-sub-text label-sub-text">Project</div>
                        <div className="session-card-project-title">{session.project ? session.project.label : ''}</div>
                        <div className="display-session-project-roles">
                            {
                                roles ? roles.map((role, index) => (
                                    <div key={role.id} className="session-card-display">
                                    <div className="session-card-role-container">
                                        <div className="session-card-role-name">{index === roles.length - 1 ? role.name : role.name + ','}</div>
                                    </div>
                                    </div>
                            )) : <div className="session-card-display">Not selected</div> }
                        </div>
                    </div>
                    {
                        session.complete ?
                        <div />
                        :
                    <div>
                    <div className="session-card-header-sub-text label-sub-text">Location</div>
                    <div className="session-card-header-sub-text room-text">{session.room ? session.room.label : 'Please select room'}</div>
                    {session.start && session.start.length > 4 ? 
                    <div className="session-card-header-sub-text">{session.start} - {session.wrap}</div>
                    : <div />
                    }
                    </div>
                    }
                    {/*{
                        session.prepped && !session.room2 && !session.complete ? <button className="add-second-room" onClick={() => setRoom2(true)}>Add a second room</button> : ''
                    */}
                </div>
                
                
            </div>
            <div className="session-card-body">
                
                
            </div>
                <div className="session-counts" onClick={props._openSessionModal.bind(this, props.item)}>
                    <div className="count-container">
                        <img className="count-icon-session-card" src={staroff} alt="User Count" />
                        <div className="count-number">{talentCount}</div>
                    </div>
                    <div className="count-container">
                        <img className="count-icon-session-card low-o" src={speechbubble} alt="User Count" />
                        <div className="count-number">{noteCount}</div>
                    </div>
                    <div className="count-container">
                        <img className="count-icon-session-card low-o" src={tag} alt="User Count" />
                        <div className="count-number">{tagCount}</div>
                    </div>
                </div>
                {/*}
                {
                    session.complete ? 
                    <button className="session-notes-session-btn" onClick={props._openSessionModal.bind(this, props.item)}>View Session Notes</button>
                    :
                    session.project && session.room ?
                    <div className="session-card-footer">
                        {
                            session.started && !session.complete && !session.paused ?
                            <button className="prep-session-btn pause-btn" onClick={props._pauseSession.bind(this, props.item)}>Pause</button>
                            :
                            session.started && !session.complete && session.paused ?
                            <button className="prep-session-btn pause-btn" onClick={props._resumeSession.bind(this, props.item)}>Resume</button>
                            :
                            <div />
                        }
                        {
                            session.paused ?
                            <div /> 
                            :
                            <div>
                                {
                                session.date === moment().format('MM/DD/YYYY') || (session.started && !session.complete) ?
                                session.prepped && !session.started ?
                                <button className="prep-session-btn" onClick={props._activateSession.bind(this, props.item)}>Activate Session</button>
                                :
                                session.started && !session.complete ?
                                <button className="prep-session-btn" onClick={props._completeCastingSession.bind(this, props.item)}>Wrap Session</button>
                                :
                                !session.prepped ?
                                <button className="prep-session-btn" onClick={props._prepSession.bind(this, props.item)}>Prep Session</button>
                                :
                                <div />
                                :
                                <div />
                                }
                            </div>
                        }
                        
                    </div>
                    */}
                    <div />
                
            </div>
        }
        </div>
    )
}


export { CastingSessionItem };