import React, {Component} from 'react';

import moment from 'moment';

export default class CheckInList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checkIns: ''
        };
    };

    componentWillReceiveProps(nextProps, props) {
        if (nextProps.state.checkIns !== this.props.state.checkIns) {
            this.setState({
                checkIns: nextProps.state.checkIns
            })
        }
    }

    render() {

    const props = this.props;

    var checkIns = props.state.checkIns ? props.state.checkIns.filter((item) => {
        return (props.state.roleArray.includes(item.roleId))
    }) : '';

    if (props.state.assignSort === 'time' && checkIns.length > 0) {
        checkIns = checkIns.sort((a, b) => moment(a.checkInTime, 'hh:mma').isSameOrAfter(moment(b.checkInTime, 'hh:mma')) ? 1 : -1);
    } else if (props.state.assignSort === 'appt' && checkIns.length > 0) {
        checkIns = checkIns.sort((a, b) => moment(a.apptTime, 'hh:mma').isSameOrAfter(moment(b.apptTime, 'hh:mma')) ? 1 : -1);
    } 

    return (

    <div className="check-in-box"> 
        <div className="sort-check-ins">
            <div className="sort-by-text">Assign by: </div>
            <div
                onClick={props._assignSortTime} 
                className={props.state.assignSort === 'time' ? "sort-by-option cur-point sort-selected" : "sort-by-option cur-point"}>Time In</div>
            <div 
                onClick={props._assignSortAppt}
                className={props.state.assignSort === 'appt' ? "sort-by-option sort-selected cur-point" : "sort-by-option cur-point"}>Appt. Time</div>
        </div>
        <div className="check-in-scroll">
            {
                this.props.state.checkIns ?
                 <div>
                {
                    checkIns ? checkIns.map((item, index) => {
                        var available = item.dibs === '' || item.dibs === props.state.roomId;
                        return (
                            <div key={item.id} className="check-in-card-container">     
                            <div className={available ? "check-in-cards" : "room-check-card-not-my-dibs"}>
                                <div className="check-card-elements">
                                    <div className="icons">
                                        <div>
                                            {
                                                item.dibs === props.state.roomId || item.dibs === '' ?
                                                    <div>
                                                        <img 
                                                            className="dibs-icon" 
                                                            src={item.dibs === props.state.roomId ? props.bookmark : props.bookmarkoff} 
                                                            onClick={item.dibs !== props.state.roomId ? props.claimDibs.bind(this, item) : props.removeDibs.bind(this, item)} 
                                                            alt="Reserve" 
                                                        />
                                                        <div onClick={props.setPriority.bind(this, item)}>
                                                        { 
                                                            props.state.vip === item.id ?
                                                            <img className="star-icon" src={props.starOn} alt="Set as priority"/> :
                                                            <img className="star-icon" src={props.starOff} alt="Remove priority" /> 
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div />
                                            }
                                        </div>   
                                    </div>
                                    {
                                        available 
                                        ? 
                                        <div>
                                            <div className="check-card-name">{item.checkInName}</div>
                                            <div className="check-card-project">{item.checkInProject} for {item.checkInRole}</div>
                                            <div className="check-card-rep">{item.checkInRepCompany}</div>
                                            <div className="check-card-time">Time In: {item.checkInTime}</div>  
                                            <div className="check-card-time">Appt Time: {item.apptTime}</div>   
                                        </div>
                                        :
                                        <div>
                                            <div className="check-card-name-no-dibs">{item.checkInName}</div>
                                            <div className="check-card-project-no-dibs">{item.checkInProject} for {item.checkInRole}</div>
                                            <div className="check-card-rep-no-dibs">{item.checkInRepCompany}</div>
                                        </div>
                                    }
                                    
                                </div>
                            </div>
                            </div>
                        )
                    }) : ''
                } 
                
                <div className="tall-padding-bottom"></div>
                </div>
                :
                <div className="default-null-message">There are no actors in queue that fit this room</div>
            }
        </div>
    </div>
    )
    }   


}