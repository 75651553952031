import React from 'react';

import {NullUser} from '../../assets/nulluser';

export default function SelectUser(props) {
    return (
        <div className="padding-5">
            <div className="user-list-scroll" />
            {
                props.employees ?
                props.employees.sort((a, b) => (a.label > b.label) ? 1 : -1).map((item, index) => (
                    <div key={item.value} className={
                        props.thisUserSelected === item ? "employee-option selected-emp cur-point" : "employee-option cur-point"}
                        onClick={props._selectThisEmployee.bind(this, item)}
                        >
                        <img className="employee-avatar" src={NullUser} alt="Employee Headshot" />
                        <div className="employee-name">{item.label}</div>
                    </div>
                ))
                :
                <div />
            }
        </div>
    )
}
