import React, { useState } from 'react';

import TagsListItem from './TagsListItem';

import rightarrow from '../../../../assets/right-arrow.png';
import remove from '../../../../assets/remove.png';

export default function TagsList(props) {

    const [filter, setFilter] = useState('');
    const [sortType, setSortType] = useState('ABC');
    const [dropdown, setDropdown] = useState(false)
    const [dropdownHover, setDropdownHover] = useState(false);
    const [view, setView] = useState('grid');

    var allTags = props.allTags;
    var uniqueTags = [];
    var uniqueTagNames = uniqueTags ? uniqueTags.map(tag => tag.tagName) : '';
    if (props.allTags) {
        props.allTags.map(tag => {
        if (!uniqueTagNames.includes(tag.tagName)) {
            uniqueTags.push({
                ...tag,
                count: props.allTags.filter(f => { return f.tagId.includes(tag.tagId)}).length
            })
            uniqueTagNames.push(tag.tagName);
        }
    })};

    return (
        <div className="s-notes-body">
            <div className="sess-dets-back-btn" onClick={props._clickOverview}>
                <img className="sb-talent-back-arrow" src={rightarrow} alt="Back" />
                <div className="sb-talent-header-text">Back to Overview</div>
            </div>
            <div className="sb-body-header-talent taglist-header">
                <div className="sb-talent-sort-container tag-btn-sorts">
                    <div className="view-switch-container">
                        <div className="sb-filter-label">View talent:</div>
                        <button 
                            className="sd-talent-sort-option sort-text-selected"
                            onClick={() => setView(view === 'grid' ? 'list' : 'grid')}
                        >
                            {view === 'grid' ? 'Grid' : 'List'}
                        </button>
                    </div>
                    <div className="view-switch-container" onClick={() => setSortType(sortType === 'ABC' ? '123' : 'ABC')} >
                        <div className="sb-filter-label">Order tags:</div>
                            {
                                sortType === '123' ? 
                                <button 
                                    onClick={() => setSortType('ABC')} 
                                    className="sd-talent-sort-option sort-text-selected"
                                >
                                Most Used
                            </button>
                            :
                            <button 
                                onClick={() => setSortType('123')} 
                                className="sd-talent-sort-option sort-text-selected"
                            >
                                ABC
                            </button>
                            }
                        </div>
                    </div>
                    <input 
                        className="sb-talent-view-search" 
                        placeholder="Search tag..." 
                        onChange={e => setFilter(e.target.value)}
                        value={filter}
                        onFocus={() => setDropdown(true)}
                        onBlur={dropdownHover ? null : () => setDropdown(false)}
                    />
                    {
                        filter.length > 0 ? 
                        <img 
                            className="clear-filter" 
                            src={remove} 
                            alt="Clear Filter" 
                            onClick={() => setFilter('')}
                        />
                        :
                        <div />
                    }
                    {
                        dropdown && uniqueTags && uniqueTags.length > 0 ?
                        <div className="sb-select-tag-dropdown" 
                            onMouseEnter={() => setDropdownHover(true)}
                            onMouseLeave={() => setDropdownHover(false)}
                        >
                            {
                                uniqueTags
                                .sort((a, b) => (a.tagName > b.tagName) ? 1 : -1)
                                .filter(fil => { return fil.tagName.includes(filter)})
                                .map(t => (
                                    <div 
                                        className={
                                            filter.toLowerCase() === t.tagName.toLowerCase() ? 
                                            "sb-select-tag-item selected-tag-item-dropdown" :
                                            "sb-select-tag-item"
                                        } 
                                        key={t.tagId}
                                        onClick={() => [setFilter(t.tagName), setDropdownHover(false), setDropdown(false)]}    
                                    >
                                        {t.tagName}
                                    </div>
                                ))
                            }
                        </div>
                        :
                        <div />
                    }
            </div>
            <div className="s-notes-body-body">
                { uniqueTags ? 
                    sortType === 'ABC' ?
                        uniqueTags.filter(fil => {
                            return fil.tagName.includes(filter)
                        }) 
                        .sort((a, b) => (a.tagName > b.tagName) ? 1 : -1)
                        .map((item, index) => (
                        <TagsListItem 
                            key={index} 
                            item={item} 
                            view={view}
                            sortType={sortType}
                            talent={
                                allTags ? 
                                allTags.filter(fil => { return fil.tagId === item.tagId }) : 
                                ''
                            }    
                        />
                        )) : 
                        uniqueTags.filter(fil => {
                            return fil.tagName.includes(filter)
                        })
                        .sort((a, b) => (a.count > b.count) ? -1 : 1)
                        .map((item, index) => (
                        <TagsListItem 
                            key={index} 
                            item={item} 
                            view={view}
                            sortType={sortType}
                            talent={
                                allTags ? 
                                allTags.filter(fil => { return fil.tagId === item.tagId }) : 
                                ''
                            }    
                        />
                        ))
                        :
                        ''
                    }
            </div>
        </div>
    )
}
