import React from 'react';

import rightarrow from '../../../../assets/right-arrow.png';

import NotesListItem from './NotesListItem';

const NotesList = (props) => {

    var talentWithNotes = props.filterByNotesList ? props.filterByNotesList.filter(fil => {
        return fil.notes;
    }) : '';

    return (
        <div className="s-notes-body">
            <div className="sess-dets-back-btn" onClick={props._clickOverview}>
                <img className="sb-talent-back-arrow" src={rightarrow} alt="Back" />
                <div className="sb-talent-header-text">Back to Overview</div>
            </div>
            <div className="sb-body-header-talent sb-notes-header">
                <input 
                    className="sb-talent-view-search" 
                    placeholder="Search talent..." 
                    onChange={props._onChangeNoteFilter.bind(this)}
                    value={props.noteFilter}
                    />
            </div>
            <div className="s-notes-body-body">
                { talentWithNotes ? talentWithNotes.map((item, index) => (
                    <NotesListItem key={item.id} item={item} />
                )) : <div className="sb-null-text">No notes recorded</div>
                }
            </div>
        </div>
    )
}

export { NotesList };