import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { Input } from '../SignUp';

import logo from '../../assets/logos/check-in-logo.png';
import formColor from '../../assets/formColor.png';

import './signIn.css';

import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const SignInPage = () => (
  <div>
    <SignInForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE }; 
  }

  componentDidMount() {
    this.props.firebase.db.ref('api/updates/')
    .once('value', snapshot => {
      if (snapshot.exists()) {
        console.log(snapshot.val());
      }
    });
  };

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase 
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({
          fadeOut: true
        })
        var signInTimer = setTimeout(() => {
          this.setState({ ...INITIAL_STATE });
          this.props.history.push(ROUTES.HOME);
        }, 500)
        return (() => clearTimeout(signInTimer))
      })
      .catch(error => {
        this.setState({ error });
      });
    
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };



  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
    <div className="check-in-layout">
      <div className={this.state.fadeOut ? "fade-out sign-in-card" : "fade-in sign-in-card"}>
      <div className="header">
          <img className="ani-logo-sign-in" src={logo} alt="ANi Logo"/>
      </div>
      <div className="check-in-form">
      <form onSubmit={this.onSubmit}>
        <div className="check-in-header-text"></div> 
        <br />
        <div className="label">Sign in here</div>
        <Input 
          value={email}
          onChange={this.onChange}
          type="text"
          label="Email Address"
          name="email"
        />
        <Input 
          name="password"
          value={password}
          onChange={this.onChange}
          type="password"
          label="Password"
        />

        <div className="sign-in-btns">
          <button id="register" className="sign-in-btn"
            onClick={() => this.props.history.push(ROUTES.SIGN_UP)}
          >
            Register
          </button>

          <button className="sign-in-btn" disabled={isInvalid} type="submit">
            Sign in
          </button>
        </div>

        {error && <p>{error.message}</p>}
        <br />
        <PasswordForgetLink />
      </form>
      </div>

        
      </div>
      
      </div>
    );
  };
};

const style = {
  input: {
      width: 330,
      height: 50,
      marginTop: 5,
  },
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

export default SignInPage;

export { SignInForm };