import React from 'react';

import SingleController from './SingleController';

import { Switch } from '../../../Common/CustomSwitch';

export default function SettingsContainer(props) {
    return (
        <div className="setting-toggles-container">
            You can open and close these setings by clicking on the gear icon on the top right.
            <div className="height-10" />
            {/*<SingleController 
                singleController={props.singleController}
                setSingleController={props.setSingleController}
                controller={props.controller}
            />*/}
            <div className={
                !props.showQueue ?
                "show-queue-container cur-point active-setting-switch-border" :
                "show-queue-container cur-point"} 
                onClick={() => props.setShowQueue(!props.showQueue)}>
                <Switch 
                    boolean={!props.showQueue}
                    toggleFunction={null}
                    disabled={false}
                    text="  Hide check in queue during audition"
                    textStyle="large"
                />
                <div className="switch-description-additional">
                    Turning on this switch provides a better experience for narrow screens, such as a phone or an iPad mini or when the right navigation bar is expanded.
                </div>
            </div>
            <div className={
                props.hideWhileActive ?
                "show-queue-container cur-point active-setting-switch-border" :
                "show-queue-container cur-point"} 
                onClick={() => props.setHideWhileActive(!props.hideWhileActive)
            }>
                <Switch 
                    boolean={props.hideWhileActive}
                    toggleFunction={null}
                    disabled={false}
                    text="  Hide completed list during audition"
                    textStyle="large"
                />
                <div className="switch-description-additional">
                    Turning on this switch will hide the completed auditions list when there is an actor in the room.
                </div>
            </div>
        </div>
    )
}
