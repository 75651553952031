import React, { useState } from 'react';
import * as ROUTES from '../../constants/routes';

import './temp.css';

export default function TempNav(props) {

    const [sidebar, setSidebar] = useState(true);

    const signOut = () => {
        localStorage.setItem( 'SessionState', '');
        localStorage.setItem( 'ANiCheckInLocked', '');
        props.firebase.doSignOut();
      }

    return (
        <div
            className={
            sidebar ? 
                "temp-nav temp-expanded" : 
                "temp-nav temp-min"
            }>
            <div >
                Nav
            </div>
            <div className="guest-signout">
                <div className="signout-button" onClick={signOut}>
                    Sign Out
                </div>
            </div>
        </div>
    )
}
