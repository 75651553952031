import React, { Component } from 'react';

import moment from 'moment';

import rubber from '../../../../assets/rubber.png';
import share from '../../../../assets/share.png';

export default class SlotItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            nameEdit: false,
            newTalentName: '',
            share: false,
            email: '',
        };
    };

    _onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    };

    _onSelect = (e) => {
        var filter = this.props.roles.filter(f => {
            return f.value === e.target.value;
        });
        this.props.firebase.updateSessionSlotRole(this.props.uid, this.props.sessKey, this.props.slotId, filter[0].label, filter[0].value);
        this.setState({
            role: e.target.value,
        })
    }

    _onEnterNewTalent = (event) => {
        if (event.keyCode === 13 && event.shiftKey === false) {
            event.preventDefault();
            this._adjustTalentSlot(event);
          }
    };

    _adjustTalentSlot = (event) => {
       this.props.firebase.updateSlotSession(this.props.uid, this.props.sessKey, this.props.slotId, this.state.newTalentName);
    }

    _clearSessionSlot = () => {
        this.props.firebase.clearSlotSession(this.props.uid, this.props.sessKey, this.props.slotId);
    }

    _openShare = () => {
        this.setState({
            share: true,
        })
    }

    _submitInvite = () => {
        var details = this.props.details;
        var date = moment(details.sessionDate).format('MM/DD/YYYY');
        this.props.firebase.newCastingSessionInvite(
            this.props.uid, 
            this.state.email,
            details.sessionProject.label,
            details.sessionProject.value, 
            this.props.slot.role, 
            this.props.slot.roleId, 
            date,
            this.props.slot.time,
            this.props.slot.name,
            this.props.sessKey,
            'Location',
        );
        this.setState({
            share: false,
            email: ''
        });
    };


    render() {

        var slot = this.props.slot;

        return (
            <div className="tal-sche-slot-item">
                <div className="tal-sch-slot-time">{slot.time}</div>
                <div>
                    {
                        slot.name ? 
                        <div onClick={(() => this.setState({ nameEdit: true }))} className="tal-sche-slot-name">{slot.name}</div> : 
                        <input className="tal-sche-slot-input" placeholder="Add talent" name="newTalentName" value={this.state.newTalentName} onChange={this._onChange.bind(this)} onKeyDown={this._onEnterNewTalent} />
                    }
                </div>
                {this.props.slot.name ?
                <select 
                    className="tal-sch-slot-role" 
                    value={this.state.role}
                    name="role"
                    onChange={this._onSelect.bind(this)}
                    >
                    <option value={this.props.slot.roleId}>{this.props.slot.role}</option>
                    {this.props.roles.map((role, index) => (
                        <option key={role.value} value={role.value}>{role.label}</option>
                    ))}
                </select>
                :
                <div />
                }
                <div className="tal-sch-slot-status">{slot.status}</div>
                {this.props.slot.name ? <img className="erase-slot-icon" src={share} alt="Share Appt" onClick={this._openShare}/> : <div />}
                {this.props.slot.name ? <img className="erase-slot-icon" src={rubber} alt="Clear slot" onClick={this._clearSessionSlot} /> : <div />}
                {
                        this.state.share ? 
                        <div className="invite-popup"> 
                            <div className="invite-popup-text">Invite Talent via Email: </div>
                            <input className="invite-popup-input" value={this.state.email} onChange={this._onChange.bind(this)} name="email" placeholder="Email address"/>
                            <button className="share-invite-btn"><img className="erase-slot-icon share-popup-icon" src={share} alt="Share Appt" onClick={this._submitInvite}/></button>
                        </div>
                        :
                        <div />
                }
            </div>
        )
    };
};