import React, { useState, useEffect } from 'react';

import moment from 'moment';

export default function SlyQueue(props) {

    const [fade, setFade] = useState(true);

    useEffect(() => {
        if (fade) {
            setFade(false);
        };
    }, []);


    var item = props.item;

    var diff = moment(props.currentTime, 'hh:mma').diff(moment(item.checkInTime, 'hh:mma'));
    diff = diff / 1000 / 60;
    var display = diff > 60 ? '1h+' : diff + 'm';

    return (
        <div className={
            fade || props.selectFade === item.id ? 
                "fadeOutFast cur-point" :
                "fadeInNew cur-point"
            }
            onClick={!props.activeCheckIn ? props.setFade.bind(this, item) : ''}    
            id="not-next-checkin"
        >
        <div className={
              diff > 45 ?  
              "sly-on-deck-card very-long-wait" :
              diff < 45 && diff > 15 ?
              "sly-on-deck-card long-wait" :
              "sly-on-deck-card"
        }>
            <img className="on-deck-avatar" src={props.NullUser} alt="Headshot" />
            <div>
                <div className="sly-on-deck-display">{display}</div>
                <div className="sly-on-deck-name">{item.checkInName}</div>
                <div className="sly-on-deck-rep">{item.checkInRepCompany}</div>
                <div className="sly-on-deck-role">{item.checkInRole}</div>
            </div>
        </div>
        </div>
    )
}
