import React, { Component } from 'react';

import moment from 'moment';

import '../modals.css';

import trashcan from '../../../assets/trashcan.png';
import remove from '../../../assets/remove.png';

export default class NoteModalBody extends Component {

    constructor(props) {
        super(props);

        this.state = {
            newNote: '',
            uid: '',
            name: '',
            notes: '',
            readers: '',
            tags: '',
            tagDropdown: false,
            tagFilter: '',
        };
    };

    componentDidMount() {

        var sessionKey = localStorage.getItem( 'SessionState' );

        var { talent, firebase } = this.props;

        if (sessionKey) {
            firebase.sessionUserSelect(sessionKey)
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    var currentUser = snapshot.val()

                    this.setState({
                        uid: this.props.uid,
                        name: currentUser.sessionUserName,
                    })

                    firebase.talentNotes(this.props.uid, talent.checkInQId)
                    .on('value', snapshot => {
                        if (snapshot.exists()) {
                            var o = snapshot.val();
                            var l = Object.keys(o).map(key => ({
                                ...o[key],
                                id: key
                            }))
                            this.setState({
                                notes: l,
                            })
                        } else {
                            this.setState({
                                notes: '',
                            })
                        }
                    })
                } else {
                    this.setState({
                        uid: '',
                        name: '',
                    })
                }
            })
        }

        firebase.talentTags(talent.checkInQId)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                if (l) {
                    this.setState({
                        tags: l,
                    })
                } else {
                    this.setState({
                        tags: ''
                    })
                };
            } else {
                this.setState({
                    tags: '',
                })
            }
        });

        firebase.sessionTalentMatchSearch(this.props.uid, talent.checkInQId)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var sessions = o.sessions;
                if (sessions) {
                    var sessionList = Object.keys(sessions).map(key => ({
                        ...sessions[key],
                        id: key,
                    }));
                    var filtered = sessionList.filter(fil => {
                        return fil.role === talent.roleId && fil.day === talent.checkInTimeDay;
                    })
                    if (filtered) {
                        this.setState({
                            sessionMode: true,
                            sessionId: sessionList[0].id,
                        })
                    } else {
                        this.setState({
                            sessionMode: false,
                            sessionId: '',
                        })
                    }
                }
            } else {
                this.setState({
                    sessionMode: false,
                    sessionId: '',
                })
            }
        })

        firebase.userTags(this.props.uid)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }));
                if (l) {
                    this.setState({
                        allTags: l.sort((a, b) => (a.name > b.name) ? 1 : -1)
                    })
                } else {
                    this.setState({
                        allTags: '',
                    })
                }
            } else {
                this.setState({
                    allTags: '',
                })
            }
        });

        firebase.talentReaders(talent.checkInQId)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                this.setState({
                    readers: l,
                });
            } else {
                this.setState({
                    readers: '',
                })
            }
        });
    }

    componentWillUnmount() {
        var { talent } = this.props;
        this.props.firebase.notes().off()
        this.props.firebase.talentTags(talent.checkInQId).off();
        this.props.firebase.userTags(this.props.uid).off();
    }

    _onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    _toggleTags = () => {
        this.setState({ tagDropdown: !this.state.tagDropdown })
    };

    _selectTagToAdd = () => {
        this.setState({
            tagDropdown: false,
        })
    }

    _onEnterNote = (event) => {
        if (event.keyCode === 13 && event.shiftKey === false) {
          event.preventDefault();
          this._saveNote(event);
        }
    };

    _saveNote = (event) => {

        if (this.state.newNote !== '') {
            var { talent } = this.props;
            var userId = this.state.uid;
            var checkInName = talent.checkInName;
            var checkInId = talent.checkInQId;
            var projectId = talent.projectId;
            var roleId = talent.roleId;
            var timestamp = moment().format('hh:mm a');
            var daystamp = moment().format('MM/DD');
            var noteBy = this.state.name;
            var note = this.state.newNote;
            var sessionId = this.state.sessionId ? this.state.sessionId : '';
            var sessionMode = this.state.sessionMode ? this.state.sessionMode : false;

            this.props.firebase.addNote(userId, checkInName, projectId, roleId, checkInId, timestamp, daystamp, note, noteBy, sessionMode, sessionId);

            this.setState({
                newNote: ''
            });
        } else {
            alert('No note to add');
        }   
    }  

    _removeNote = (n) => {
        var uid = this.state.uid;
        var talentId = this.props.talent.id;
        var sessionId = this.state.sessionId ? this.state.sessionId : '';
        var sessionMode = this.state.sessionMode ? this.state.sessionMode : false;
        this.props.firebase.removeNote(n.id, uid, talentId, sessionId, sessionMode);
    }

    _removeTag = (t) => {
        var uid = this.state.uid;
        var talentId = this.props.talent.id;
        var ref = t.refKey;
        var sessionId = this.state.sessionId ? this.state.sessionId : '';
        var sessionMode = this.state.sessionMode ? this.state.sessionMode : false;
        this.props.firebase.removeTalentTag(uid, talentId, t.id, sessionId, sessionMode);
        this.props.firebase.removeUserTalentTag(uid, talentId, t.id, ref);
    }

    _addTalentTag = (item) => {
        var { talent } = this.props;
        const { uid } = this.props;
        var sessionId = this.state.sessionId ? this.state.sessionId : '';
        var sessionMode = this.state.sessionMode ? this.state.sessionMode : false;
        this.props.firebase.addTalentTag(uid, talent.checkInQId, item.name, talent.checkInName, item.id, talent.projectId, talent.roleId, sessionId, sessionMode );
        this.props.firebase.addUserTalentTag(uid, talent.id, item.id, item.name, talent.projectId, talent.roleId);
        this.setState({
            tagDropdown: false,
        })
    };

    render() {

        var { talent } = this.props;
        var { notes, newNote } = this.state;

        return (
            <div className="modal-note-layout">
                <div className="note-body-header">
                    <div className="talent-project">{talent.checkInProject}</div>
                    <div className="talent-role">Role of '{talent.checkInRole}'</div>
                    <div className="read-by-container">Read by: 
                        {
                            this.state.readers !== '' ?
                                this.state.readers.map((item, index) => (
                                index === this.state.readers.length - 1 ?
                                <div className="talent-reader" key={index}>{item.name}</div> :
                                <div className="talent-reader" key={index}>{item.name}, </div>
                                ))
                                :
                                <div className="talent-reader">N/A</div>
                        }
                    </div>
                    <div className="time-container">
                        <div className="time-element">Wait Time: {talent.waitTime} mins</div>
                        <div className="time-element">Audition Time: {talent.auditionTime} mins</div>
                    </div>
                </div>
                
                <div className="note-body">
                    <div className={this.state.tagDropdown ? "modal-tag-container modal-tag-container-search-open" : "modal-tag-container"}>
                        <div className="add-tag-container">
                            {
                            this.state.tagDropdown ?
                            <div className="add-tag-dropdown-notes-modal">
                                <input 
                                    className="search-tag-notes-modal" 
                                    value={this.state.tagFilter}
                                    name="tagFilter"
                                    onChange={this._onChange.bind(this)}  
                                    placeholder="Search tag..."  
                                    autoFocus
                                />
                                <div className="search-tag-notes-dropdown">
                                    {
                                        this.state.allTags ?
                                        this.state.allTags
                                            .filter(fil => { return fil.name.toLowerCase().includes(this.state.tagFilter) })
                                            .map((tag, index) => (
                                            <div 
                                                key={tag.id} 
                                                className="search-tag-tag-item"
                                                onClick={this._addTalentTag.bind(this, tag)}    
                                            >
                                                {tag.name}
                                            </div>
                                        ))
                                        :
                                        <div className="null-text">
                                            No tags created
                                        </div>
                                    }
                                </div>
                            </div> 
                            :
                            <button className="add-tag-btn-notes-modal" onClick={this._toggleTags.bind(this)}>+ Add Tag</button>
                            }
                        </div>
                        
                        <div className="note-modal-tag-list">
                            {
                            this.state.tags !== '' || this.state.tags.length > 0 ?
                            this.state.tags.map((item, index) => (
                                <div key={index} className="modal-tag">
                                    <div className="modal-tag-layout">
                                    <div className="modal-tag-name">{item.tagName}</div>
                                    <img className="delete-modal-tag" src={remove} onClick={this._removeTag.bind(this, item)} alt="Remove tag" />
                                    </div>
                                </div>
                            ))
                            :
                            <div>No tags</div>
                        }
                        </div>
                        
                    </div>
                    <div className="note-label">Notes</div>
                    <div className="new-note-input-container">
                        <input className="new-note-textarea" name="newNote" value={newNote} onKeyDown={this._onEnterNote} onChange={this._onChange.bind(this)} onSubmit={this._submitNote} placeholder="Add new note here!" />
                        <button className="new-note-submit-btn" onClick={this._saveNote}>+</button>
                    </div>
                    <div className="notes-modal-container">
                    {
                    notes !== '' ? 
                    notes.map((n, index) => {
                        if (n.noteBy) {
                            if (n.noteBy.includes(" ")) {
                                var initials = n.noteBy.split(" ").map((n) => n[0]).join("");
                            }
                        }
                        return (
                        <div key={index} className="note-container">   
                            <div className="note-avatar"><div className="note-initials">{initials ? initials : ''}</div></div>
                            <div className="note-by">
                                <div className="note-text">{n.note}</div>
                                <div className="note-user">{n.daystamp}</div>
                            </div>
                            <div className="note-btns">
                                <div />
                                <img className="remove-note" src={trashcan} onClick={this._removeNote.bind(this, n)} alt="Delete note" />
                            </div>
                        </div>
                        )
                    })
                    :
                    <div className="default-null-message">No notes</div>
                    }
                    </div>
                </div>
                

            </div>
        )
    }
}



