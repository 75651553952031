import React, { useState, useEffect } from 'react';
import { NullUser } from '../../assets/nulluser';
import SessionFocusNotes from './SessionFocusNotes';
import SessionFocusTags from './SessionFocusTags';
import moment from 'moment';

export default function SessionFocus(props) {

    const [edit, setEdit] = useState(false);
    const [e, setE] = useState('');
    const [newFocus, setNewFocus] = useState('');
    const [newFocusId, setNewFocusId] = useState('');
    const [focusId, setFocusId] = useState('');
    const [tab, setTab] = useState('');

    useEffect(() => {
        if (props.item) {
            setE(props.item)
        }
    }, [props.item]);

    function sendBack() {
        var sub = props.item.id;
        props.firebase.apiSession(props.id)
        .update({
            focus: '',
        });
        props.firebase.apiCompanyGuest(props.cid, sub)
        .update({
            status: 'waiting',
            track: {
                in: e.track.in,
                call: '',
                out: '',
            }
        });
        props.firebase.apiSessionSubject(props.id, sub)
        .remove();
        props.pushOpenFromWrap();
    };

    function complete() {
        var sub = props.item.id;
        var timestamp = moment().format('MM/DD/YYYY hh:mm:ss a');
        if (e.appt) {
            props.firebase.apiInvite(e.appt)
            .update({
                join: false,
                boot: true,
            });
        };
        props.firebase.apiSession(props.id)
        .update({
            focus: '',
        })
        props.firebase.apiCompanyGuest(props.cid, sub)
        .update({
            status: 'complete',
            track: {
                in: e.track.in,
                call: e.track.call,
                out: timestamp,
            },
            complete: true,
        })
        props.firebase.apiSessionSubject(props.id, sub)
        .update({
            track: {
                in: e.track.in,
                call: e.track.call,
                out: timestamp,
            },
        });
        props.prepAuto(false);
        props.pushOpenFromWrap();
    };

    function toNotes() {
        
    }

    const [addNote, setAddNote] = useState(false);

    const [condenseHeader, setCondenseHeader] = useState(false);

    var condense = props.mobile && condenseHeader;
    var magnified = props.magnify === props.index;


    return (
        <div id="session-focus-card" className={props.magnify === props.index ? "session-focus-container magnify-mobile relative" : "session-focus-container relative"}
            onClick={props.scrollIndex !== props.index ? () => props.prepScrollIndex(props.index) : null
            }
        >
            {props.magnify === props.index && !condense ? 
                <button className="close-magnify-button" onClick={props.mobile ? () => props.unFocusMobileCard() : null}>Close</button> : null
            }
            <div className={props.magnify === props.index ? "session-focus-card mag-mob" : "session-focus-card"} 
                >
                {
                    e ?
                    <div className="session-elements-container no-scroll-bars" onClick={props.mobile && props.scrollIndex === props.index ? () => props.focusThisMobileCard(props.index) : null}>
                        <div id={condense ? "condense-focus-header" : ''} className={props.mobile ? "session-focus-header-mobile" : "session-focus-header"}>
                            <img id={condense ? "condense-mobile-img" : ''} className="session-focus-img" src={NullUser} alt="Headshot" />
                            <div className="session-focus-details">
                                <h1>{e.name}</h1>
                                {
                                condense ? 
                                null 
                                :
                                <h3>{e.response}</h3>
                                }
                                <h2>{e.interview.name}</h2>
                                <div>
                                {
                                    props.mobile ? 
                                    null : 
                                    <FocusTimes e={e} />
                                }
                                </div>
                                
                            </div>    
                        </div>
                        <div className="session-focus-body no-scroll-bars">
                            <SessionFocusNotes 
                                username={props.username} 
                                firebase={props.firebase} 
                                id={props.id}
                                uid={props.uid}
                                cid={props.cid}
                                e={e}
                                prepAuto={props.prepAuto}
                                usertype={props.usertype}
                                mobile={props.mobile}
                                tags={props.tags}
                                widget={props.magnify === props.index}
                                setCondenseHeader={setCondenseHeader}
                                setAddNote={setAddNote}
                                magnify={props.magnify}
                                usertype={props.usertype}
                            />
                        </div>
                    </div>
                    :
                    null
                }
                  {props.focus !== e.id || props.usertype === 'Guest' || condense || magnified ? 
                        null :
                        <div className="session-focus-actions">
                            <ActionButton 
                                text="Send Back"
                                function={() => sendBack()}
                            />
                            <ActionButton 
                                text="Complete"
                                function={() => complete()}
                            />
                        </div>
                    }
            </div>
        </div>
    )
}

function FocusNav(props) {

    return (
        <div className="focus-nav">
            <div className="focus-tab">Notes</div>
            <div className="focus-tab">About</div>
            <div className="focus-tab">Materials</div>
        </div>
    )
}

const ActionButton = (props) => (
    <button
        className="action-button"
        onClick={() => props.function()}
    >
        {props.text}
    </button>
)

function FocusTimes(props) {

    const e = props.e;
    return (
        <div>
            <div className="height-10" />
            <div className="focus-time">
                <div>Arrived</div>
                <div>
                    {
                    e.track.in.split(' ')[1].split(':')[0] + 
                    ':' + 
                    e.track.in.split(' ')[1].split(':')[1] +
                    ' ' + e.track.in.split(' ')[2]}
                </div>
            </div>
            <div className="focus-time">
                <div>Called</div>
                <div>{
                    e.track.call.split(' ')[1].split(':')[0] + 
                    ':' + 
                    e.track.call.split(' ')[1].split(':')[1] +
                    ' ' + e.track.call.split(' ')[2]}
                </div>
            </div>
            {e.track.out ?
            <div className="focus-time">
                <div>Wrapped</div>
                <div>
                {
                e.track.out.split(' ')[1].split(':')[0] + 
                ':' + 
                e.track.out.split(' ')[1].split(':')[1] +
                ' ' + e.track.out.split(' ')[2]}
                </div>    
            </div>
            : 
            null 
            }
        </div>
    )
}
