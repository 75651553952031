import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';
import { withAuthorization, withAuthentication } from '../Session';
import { compose } from 'recompose';
import moment from 'moment';

import Audition from './Audition';
import Meeting from './Meeting';

import './checkin.css';

const CheckInForm = (props) => (
    <FormCompose />
);

function Form(props) {
    const [uid, setUid] = useState('');
    const [cid, setCid] = useState('');
    const [positions, setPositions] = useState('');
    const [block, setBlock] = useState(true);
    const [companyName, setCompanyName] = useState('');
    const [logo, setLogo] = useState('');
    const [greeting, setGreeting] = useState('');

    var today = moment().format('MM/DD/YYYY');

    useEffect(() => {
        props.firebase.auth.onAuthStateChanged(
            authUser => {
                if (authUser) {
                    setBlock(false);
                    setUid(authUser.uid);
                    getUserData(authUser.uid);
                } else {
                    setBlock(true);
                }
            }
        )
    }, []);

    function getPositionData(cid) {
        props.firebase.apiCompanySessions(cid)
        .orderByChild('date')
        .equalTo((today))
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key
                }))
                var post = [];
                l.map((item, index) => {
                if (item.positions) {
                    var posi = Object.keys(item.positions).map(key => ({
                        ...item.positions[key],
                        id: key
                    }));
                    posi.map((p, index) => {
                        if (post.length > 0) {
                            var common = post.map(p => p.id);
                            if (!common.includes(p.id)) {
                                post.push(p)
                            }
                        } else {
                            post.push(p)
                        }
                    });
                }
                setPositions(post);
                })
            }
        })
    };

    function getUserData(uid) {
        props.firebase.apiUser(uid)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                setCid(o.company);
                setCompanyName(o.companyName);
                props.firebase.apiCompany(o.company)
                .child('files/logo')
                .once('value', snapshot => {
                    if (snapshot.exists()) {
                        var logo = snapshot.val();
                        setLogo(logo);
                    } 
                });
                props.firebase.apiCompany(o.company)
                .child('greeting')
                .once('value', snapshot => {
                    if (snapshot.exists()) {
                        setGreeting(snapshot.val());
                    };
                })
                getPositionData(o.company);
            };
        })
    };

    return (
        <div className="check-in-bg">
            {
                block ?
                <div />
                :
                <FormCard firebase={props.firebase} cid={cid} positions={positions} greeting={greeting} logo={logo} coname={companyName} />
            }
        </div>
    )
};

function FormCard(props) {

    const [removeHeader, setRemoveHeader] = useState(false);

    return (
        <div className="form-card-container">
            <Header removeHeader={removeHeader} logo={props.logo} coname={props.coname} />
            <Body setRemoveHeader={setRemoveHeader} firebase={props.firebase} cid={props.cid} positions={props.positions} greeting={props.greeting} />
            <Footer />
        </div>
    )
};

function Header(props) {
    return (
        <div className="check-in-header">
            {props.logo ? <img className="check-in-company-logo" src={props.logo} alt="Company Logo" /> : null}
            <div className="check-in-company-name">{props.coname}</div>
            <div className="check-in-instruction">Check-in below</div>
        </div>
    );
};

function Body(props) {

    const [name, setName] = useState('');
    const [nameSet, setNameSet] = useState(false);
    const [nameDone, setNameDone] = useState(false);
    const [pck, setPck] = useState('');
    const [type, setType] = useState('interview');
    const [fadeOutName, setFadeOutName] = useState(false);

    function _setNameSet() {
        setFadeOutName(true);
        var nameTimer = setTimeout(() => {
            setNameDone(true)
            props.setRemoveHeader(true);
            setFadeOutName(false);
        }, 500);
        return (() => clearTimeout(nameTimer))
    };

    function prepPck(item) {
        var browser = window.innerWidth;
        var defaultMtg = {
            name: '',
            id: '',
        };
        var defaultInt = {
            name: '',
            id: '',
        };
        var newPck = {
            appt: '',
            browser: browser < 500 ? 'mobile' : 'web',
            name: name,
            ref: '',
            meeting: item.meeting || defaultMtg,
            interview: item.position || defaultInt,
            type: type,
            via: 'host',
            response: item.response,
        };
        props.firebase.apiNewCompanyGuest(props.cid, newPck);
        var clearTimer = setTimeout(() => {
            setName('');
            setNameSet(false);
        }, 1000);
        return (() => clearTimeout(clearTimer));
    };

    return (
        <div className="checkin-body-container">
        {
            !nameDone ?
            <div className={fadeOutName ? "fade-out" : 'fade-in'}>
            <Name name={name} setName={setName} nameSet={nameSet} setNameSet={_setNameSet} />
            </div>
            :   
            type === 'interview' ?
            <Audition prepPck={prepPck} greeting={props.greeting} positions={props.positions} setNameDone={setNameDone} setType={setType} setRemoveHeader={props.setRemoveHeader} /> :
            type === 'audition' ?
            <Audition prepPck={prepPck} greeting={props.greeting} positions={props.positions} setNameDone={setNameDone} setType={setType} setRemoveHeader={props.setRemoveHeader} /> :
            type === 'meeting' ?
            <Meeting prepPck={prepPck} setNameDone={setNameDone} /> :
            <div className="">
                <button onClick={() => setType('interview')}>Interview</button>
                <button onClick={() => setType('meeting')}>Meeting</button>
            </div>
        }
        </div>
    );
};

const Name = (props) => (
    <div className={props.nameSet ? "fadeOutCard" : "fadeInCard"}>
        <FormInput 
            value={props.name}
            onChange={(e) => props.setName(e)}
            placeholder="First and last please!"
            onClick={() => props.setNameSet(true)}
            btntext="Next"
            rule={props.name.length > 3}
            label="First and last name"
        />
    </div>
);

const FormInput = (props) => (
    <div className="input-holder">
        <div className={props.value.length > 0 ? "check-in-label top-left-check" : "check-in-label"}>{props.label}</div>
        <input 
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            className="form-input"
            autoFocus
        />
        {
            <div className={props.rule ? 'fade-in' : 'fade-out'}>
            <Button disabled={!props.rule} onClick={() => props.onClick()} text={props.btntext} />
            </div>
        }
    </div>
)

const Button = (props) => (
    <button className="check-in-button" onClick={() => props.onClick()}>{props.text}</button>
);

function Footer(props) {
    return (
        <div />
    )
};

const condition = authUser => !!authUser;

const FormCompose = compose(
    withFirebase,
    withAuthentication
    )(Form);

export default withAuthorization(condition)(CheckInForm);

export { FormCompose, Button, FormInput };
