import React, { useState } from 'react';

import { Switch } from '../../../../../Common/CustomSwitch';

import SessionScheduleItem from './SessionScheduleItem';

export default function SessionSchedule(props) {

    const [onlyActive, setOnlyActive] = useState(false);
    const [group, setGroup] = useState(false);

    const _toggleSchedule = () => {
        props.firebase.sessionInfo(props.groupId)
        .update({
            scheduleOff: !group,
        })
        var timer = null;
        clearTimeout(timer);
        timer = setTimeout(() => {
            setGroup(!group)
        }, 200)
        clearTimeout(timer);
    }

    return (
        <div>
            <div className="session-mode-section-label">
            Schedule
            </div>
            <div  className="group-session-schedule-container">
            <div className="schedule-settings-container">
                <div className="toggle-divider"></div>
                <Switch 
                    disabled={false}
                    boolean={onlyActive}
                    text="Hide open slots"
                    toggleFunction={() => setOnlyActive(!onlyActive)}
                />
                <div className="toggle-divider"></div>
                 <Switch 
                    disabled={false}
                    boolean={group}
                    text="Hide from guests"
                    toggleFunction={_toggleSchedule.bind(this)}
                />
            </div>
            <div className="schedule-scroll">
            {
                props.schedule.length > 0 ?
                    onlyActive ?
                        props.schedule.map((item, index) => (
                            item.name ?
                            <SessionScheduleItem key={item.time} item={item} />
                            :
                            null
                        ))
                    :
                    props.schedule.map((item, index) => (
                        <SessionScheduleItem key={item.time} item={item} />
                    ))
                :
                <div className="null-text">No schedule created</div>
            }
            </div>
            </div>
        </div>
    )
}
