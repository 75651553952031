import React, { Component } from 'react';

import remove from '../../assets/remove.png';

export default class ProjectRoleSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: [],
            item: '',
            roleSelect: '',
        }
    }

    componentDidMount() {
        const { item, roomId } = this.props;
        this.setState({
            item: item,
            roomId: roomId,
        })
    }

    _addRolePrep = roleSelect => {
        var projectId = roleSelect.projectId;
        var roleName = roleSelect.label;
        var roleId = roleSelect.value;
        var roomId = this.props.roomId;
        var uid = this.props.uid;
        this.props._addRole(projectId, roleName, roleId, roomId, uid);
    }

    render() {

        var item = this.props.item;

        var options = this.props.roleOptions ? this.props.roleOptions.filter((role) => {
            return (role.projectId === item.projectId);
        }) : '';

        var roomRoleIds = this.props.roomRoles ? this.props.roomRoles.map(rr => rr.id) : '';
        var selectedRoles = options.filter(f => {
            return roomRoleIds.includes(f.value);
        })
        var selectedRoleIds = selectedRoles ? selectedRoles.map(sr => sr.value) : '';

        return (
        options !== '' ? options.map((role, index) => (
        <div 
            key={role.value}
            className={selectedRoleIds !== '' && selectedRoleIds.includes(role.value) ? "room-page-role-card activated" : "room-page-role-card"}
            onClick={selectedRoleIds !== '' && selectedRoleIds.includes(role.value) ? this.props._removeRole.bind(this, role) : this._addRolePrep.bind(this, role)}
            >
            <div className="room-page-reader-contents">
                <div className="room-page-project-role">{role.label}</div>
            </div>
        </div>
        ))
        :
        <div>No roles available</div>
    )
    }
};
