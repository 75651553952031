import React from 'react';

import rubber from '../../../../assets/rubber.png';

const Page2 = (props) => {

    var list = props.state.list;
    var slotTimes = props.state.slotTimes;
    var submitted = list ? list.filter(f => { return f.submitted }) : '';
    if (submitted.length === list.length) {
        var draftsToSubmit = false;
    } else {
        draftsToSubmit = true;
    };

    var validTalent = props.state.newTalent ? true : false;

    return (
        <div className="quick-add-page-2-layout">
            {
                props.state.list ?
                <div>You have {Number(props.state.slots) - props.state.list.length} slots to fill.</div> :
                <div>You have {Number(props.state.slots)} slots to fill.</div>
            }    
            {
                props.state.list ? 
                props.state.list.length === props.state.slots ?
                <div className="">No slots remaining</div> :
                <input value={props.state.newTalent} name="newTalent" onChange={props._onChange.bind(this)} onKeyDown={validTalent ? props._onEnterTalent : console.log('')} /> :
                <input value={props.state.newTalent} name="newTalent" onChange={props._onChange.bind(this)} onKeyDown={validTalent ? props._onEnterTalent : console.log('')} />
            }
            {
                draftsToSubmit ? <button className="add-talent-to-schedule-btn" onClick={props._addTalentToSchedule}>Submit Drafts to Schedule</button> : <div />
            }
            {
                slotTimes ? 
                slotTimes.map((slot, index) => (
                    <div key={index} className="quick-list-slot-time">
                        <div className="quick-list-name-time">{slot.time}</div>
                        <div className="quick-list-name-name">{slot.name}</div>
                        <div className="quick-list-name-name">{slot.status}</div>
                        <div className="quick-list-remove-name">
                            <img className="clear-slot-icon" src={rubber} alt="Clear Slot" />
                        </div>
                    </div>
                )) 
                :
                <div>No time slots created</div>
            }
        </div>
    )
};

export { Page2 };