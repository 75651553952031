import React from 'react';

import blank from '../../assets/blankimage.png';

export default function ImageUpload(props) {
    return (
        <div className={props.imageContainer}>  
            <label className="upload-slide-picture">
            <img className={props.imageClass} src={props.link ? props.link : blank} alt="Add Element" />
            <input 
                hidden 
                type="file" 
                name="image-upload"
                onChange={(e) => props.function(e)}
            />
            </label>
        </div>
    );
};
