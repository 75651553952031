import React, { useState, useEffect } from 'react';
import { ObjectList } from '../../Functions/Common';
import moment from 'moment';
import SummaryCard from '../../Common/SummaryCard';
import Select from '../../Common/Select';
import remove from '../../../assets/remove.png';

export default function SessionSummary(props) {

    const [session, setSession] = useState('');

    useEffect(() => {
        if (props.session) {
            if (props.from === 'session-view') {
                setSession(props.session) 
            } else {
                props.firebase.apiSession(props.session)
                .on('value', snapshot => {
                    if (snapshot.exists()) {
                        var o = snapshot.val();
                        setSession(o);
                    };
                });
            }
        };
    }, []);

    return (
        <div className="modal-fullscreen">
            <div className="modal-bg" onClick={() => props.setSessSum('')}></div>
            <Modal session={session} setSessSum={props.setSessSum} />
        </div>
    );
}

function Modal(props) {

    const sess = props.session;
    const [count, setCount] = useState('');
    const [notes, setNotes] = useState('');
    const [tags, setTags] = useState('');
    const [avgWait, setAvgWait] = useState('');
    const [avgCall, setAvgCall] = useState('');
    const [avgTime, setAvgTime] = useState('');
    const [subjects, setSubjects] = useState('');
    const [positions, setPositions] = useState('');

    useEffect(() => {
        if (props.session.subjects) {
            var subjects = props.session.subjects;
            var subjectList = ObjectList(subjects);
            setCount(subjectList.length);
            countNotes(subjectList);
            countTags(subjectList);
            times(subjectList, subjectList.length);
            setSubjects(subjectList);
        }; 
    }, [props.session.subjects]);

    useEffect(() => {
        if (props.session.positions) {
            var pos = ObjectList(props.session.positions);
            setPositions(pos);
        }
    }, [props.session.positions]);

    function times(subjectList, count) {
        var waitTime = 0;
        var callTime = 0;
        var totalTime = 0;

        subjectList.map((s, index) => {
            var a = moment(s.track.in, 'MM/DD/YYYY hh:mm:ss a');
            var b = moment(s.track.call, 'MM/DD/YYYY hh:mm:ss a');
            var c = s.track.out ? moment(s.track.out, 'MM/DD/YYYY hh:mm:ss a') : 'active';
            var wait = b.diff(a, 'minutes');
            var call = c !== 'active' ? c.diff(b, 'minutes') : 1;
            var total = wait + call;
            waitTime = waitTime + wait;
            callTime = callTime + call;
            totalTime = totalTime + total;
        });
        setAvgWait(Math.round(waitTime / count));
        var avgcall = Math.round(callTime / count);
        if (avgcall === 0) {
            setAvgCall(1);
        } else {
            setAvgCall(avgcall)
        }
        setAvgTime(Math.round(totalTime / count));
    };

    function countNotes(subjectList) {
        var notes = 0;
        subjectList.map((s, index) => {
            if (s.notes) {
                var snotes = Object.keys(s.notes);
                var noteCount = snotes.length;
                notes = notes + noteCount;
            };
        });
        setNotes(notes);
    };

    function countTags(subjectList) {
        var tags = 0;
        subjectList.map((s, index) => {
            if (s.tags) {
                var stags = Object.keys(s.tags);
                var tagCount = stags.length;
                tags = tags + tagCount;
            };
        });
        setTags(tags);
    };

    return (
        <div className="modal-card no-scroll-bars">
            <img className="closeit" src={remove} alt="Close Modal" onClick={() => props.setSessSum('')}/>
            <div>
                <h1>{sess.title ? sess.title : 'Untitled Session'}</h1>
                <h2>{sess.date}</h2>
            </div>
            <div className="height-20" />
            <Analytics  
                count={count}
                notes={notes}
                tags={tags}
                avgWait={avgWait}
                avgCall={avgCall}
                avgTime={avgTime}
            />
            <div className="height-20" />
            
            <Subjects 
                subjects={subjects}
            />
        </div>
    )
}

function Analytics(props) {

    const [analytics, setAnalytics] = useState('');

    useEffect(() => {
        setAnalytics([
            {name: 'Talent Read', value: props.count || 'TBD'},
            {name: 'Notes Taken', value: props.notes || 'TBD'},
            {name: 'Tags Used', value: props.tags || 'TBD'},
            {name: 'Avg. Wait Time', value: props.avgWait ? props.avgWait + ' mins' : 'TBD'},
            {name: 'Avg. Audition Time', value: props.avgCall ? props.avgCall + ' mins' : 'TBD'},
            {name: 'Avg. Total Time', value: props.avgTime ? props.avgTime + ' mins' : 'TBD'},
        ])
    }, [props]);


    return (
        <div className="analytics-container">
            {
                analytics ?
                analytics.map((item, index) => (
                    <div key={index} className="analytics-item">
                        <div className="analytics-type">{item.name}</div>
                        <div className="analytics-value">{item.value}</div>
                    </div>
                ))
                :
                <div className="loading-value">Loading</div>
            }
        </div>
    )
}

function Subjects(props) {

    const [view, setView] = useState({
        label: 'Grid', value: "card"
    });
    const [subjects, setSubjects] = useState('');
    const [unique, setUnique] = useState('');

    const [includes, setIncludes] = useState({
        tags: true,
        notes: true,
        times: true,
        headshot: true,
    });
    const [group, setGroup] = useState({label: 'Person', value: 'person'});
    const [sort, setSort] = useState({label: 'Order', value: 'order'});

    var sortOptions = [
        {value: 'name', label: 'Name'},
        {value: 'order', label: 'Order'},
    ];

    var groupOptions = [
        {value: 'person', label: 'Person'},
        {value: 'position', label: 'Position'},
        {value: 'tag', label: 'Tag'}
    ];

    var viewOptions = [
        {value: 'card', label: 'Grid'},
        {value: 'list', label: 'List'},
    ];


    useEffect(() => {
        if (props.subjects) {
            var allTags = [];
            props.subjects.map((i, index) => {
                if (i.tags) {
                    var tags = ObjectList(i.tags);
                    tags.map((t, index) => {
                        allTags.push(t);
                    });
                };
            })
            findUniqueTags(allTags);
        }
    }, [props.subjects]);

    function findUniqueTags(tags) {
        var uniqueTags = [];
        var uniqueTagNames = uniqueTags ? uniqueTags.map(tag => tag.name) : '';
        if (tags) {
            tags.map(tag => {
            if (!uniqueTagNames.includes(tag.name)) {
                uniqueTags.push({
                    name: tag.name,
                    count: tags.filter(f => { return f.id.includes(tag.id)}).length
                })
                uniqueTagNames.push(tag.name);
            }
        })};
        setUnique(uniqueTags);
    };

    useEffect(() => {
        if (props.subjects) {
            if (sort.value === 'order') {
                setSubjects(
                    props.subjects
                    .sort((a, b) => 
                        (moment(a.track.call, 'MM/DD/YYYY hh:mm:ss a').isBefore(moment(b.track.call, 'MM/DD/YYYY hh:mm:ss a'))) ? -1 : 1)
                    );
            } else if (sort.value = 'name') {
                sortName(props.subjects);
            };
        };
    }, [props.subjects]);

    function sortName(subs) {
        setSubjects(
            subs.sort((a, b) => (a.name > b.name) ? 1 : -1)
        );
    };

    function sortOrder(subs) {
        setSubjects(
            subs
            .sort((a, b) => (moment(a.track.call, 'MM/DD/YYYY hh:mm:ss a').isBefore(moment(b.track.call, 'MM/DD/YYYY hh:mm:ss a'))) ? -1 : 1)
        );
    }

    function prepSort(item) {
        setSort(item);
        if (item.value === 'name') {
            sortName(subjects);
        } else if (item.value === 'order') {
            sortOrder(subjects);
        }
    }

    function prepGroup(item) {
        setGroup(item);
    }

    function prepView(item) {
        setView(item);
    }

    const [copytext, setCopytext] = useState('');
    const [tagdata, setTagdata] = useState('');
    const [goTags, setGoTags] = useState('');
    const [goCopy, setGoCopy] = useState(false);

    function copyList() {
        var copydata = '';
        subjects.map((item, index) => {
            var name = item.name;
            var response = item.response;
            var position = item.interview.name;
            var tags = '';
            var notes = '';
            if (item.tags) {
                var tagList = ObjectList(item.tags);
                tags = tagList.map(t => t.name);
            }
            if (item.notes) {
                var notesList = ObjectList(item.notes);
                notes = notesList.map(t => t.note);
            }
            
            var object = name + '\n' + 
                'Rep: ' + response + '\n' + 
                'Position: ' + position + '\n' + '\n' +
                'Tags: ' + (tags ? tags.join(', ') : 'No tags') + '\n' + '\n' +
                'Notes:' + '\n' + 
                (notes ? notes.join('\n') : 'No notes');
            copydata = copydata + '\n\n' + object;
        })
        setCopytext(copydata);
        setGoCopy(true);
    };

    function copyTagGroups() {
        var copytagdata = '';
        var subs = subjects;
        var uni = unique;
        var groups = [];
        if (subs && uni) {
            uni.map(item => {
                var name = item.name;
                var matches = [];
                subs.map(sub => {
                    var subTags = sub.tags ? ObjectList(sub.tags) : '';
                    if (subTags) {
                        var tagNames = subTags.map(n => n.name);
                        if (tagNames.includes(name)) {
                            matches.push(sub);
                        }
                    }
                })
                var posi = matches.map(p => p.interview.name);
                var unipos = [];
                var upos = posi.map(po => {
                    if (!unipos.includes(po)) {
                        unipos.push(po);
                    }
                })
                var posgroup = [];
                unipos.map(uni => {
                    var group = [];
                    var tal = matches.filter(fil => {
                        return fil.interview.name === uni
                    });
                    tal.map(ma => {
                        group.push(ma.name);
                    });
                    posgroup.push({
                        group: group,
                        pos: uni
                    });
                });
                groups.push({
                    positions: posgroup,
                    count: item.count,
                    name: name,
                });
            })
            var data = '';
            groups.map(g => {
                var posstring = '';
                g.positions.map(gr => {
                    var names = gr.group.join('\n');
                    posstring = posstring + gr.pos.toUpperCase() + '\n' 
                        + names + '\n\n';
                })
                var object = g.name.toUpperCase() + ' (' + g.count + ')\n\n' + posstring;
                data = data + '\n' + object;
            });
            setTagdata(data);
            setGoTags(true);
        } 
    }

    function copyToClipboard(e, which) {
        var text = e.target.value;
        var copyText = which === 'list' ? document.getElementById('textarea-copy') : document.getElementById('tagarea-copy');
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        setGoCopy(false);
        setGoTags(false);
        setCopytext('');
        setTagdata('');
        alert('Copied to clipboard!');
    };

    const [select, setSelect] = useState('');

    return (
        <div className="modal-summary-section relative">
            <h3>People</h3>
            <div className="modal-summary-group-select inline">
                <Select 
                    options={viewOptions}
                    default={'View as: ' + view.label}
                    function={prepView}
                    secondary={() => setSelect('view')}
                    push={select}
                    receive="view"
                />
            </div>
            <div className="modal-summary-group-select inline">
                <Select 
                    options={groupOptions}
                    default={'Group by: ' + group.label}
                    function={prepGroup}
                    secondary={() => setSelect('group')}
                    push={select}
                    receive="group"
                />
            </div>
            <div className="modal-summary-group-select inline">
                <Select 
                    options={sortOptions}
                    default={'Sort by: ' + sort.label}
                    function={prepSort}
                    secondary={() => setSelect('sort')}
                    push={select}
                    receive="sort"
                />
            </div>
            <div className="height-10" />
            <ViewSettings setIncludes={setIncludes} includes={includes} />
            <div className="copy-list-buttons">
                <button className="save-change-btn" id="copy-list-btn" onClick={() => copyList()}>Copy Names</button>
                <button className="save-change-btn" id="copy-list-btn" onClick={() => copyTagGroups()}>Copy Tags</button>
            </div>
            {goCopy ? 
                <textarea 
                    autoFocus 
                    onFocus={(e) => copyToClipboard(e, 'list')} 
                    value={copytext} 
                    onChange={(e) => setCopytext(e.target.value)} 
                    id="textarea-copy" 
                /> : null}
            {goTags ? 
                <textarea 
                    autoFocus 
                    onFocus={(e) => copyToClipboard(e, 'tag')} 
                    value={tagdata} 
                    onChange={(e) => setTagdata(e.target.value)} 
                    id="tagarea-copy" 
                /> : null}
            <div className="modal-summary-section-bg">
            {
                group.value === 'tag' ?
                <TagGroups subjects={subjects} unique={unique} includes={includes} view={view} />
                :
                <div className={
                    view.value === 'card' ? 
                    "subjects-container-card" : 
                    view.value === 'list' ?
                    "subjects-container-list" :
                    "subjects-container"}
                    >
                    {
                        subjects ?
                        subjects.map((item, index) => (
                            <SummaryCard key={item.id} item={item} includes={includes} />
                        ))
                        :
                        <div className="null">No people completed</div>
                    }
                </div>
            }
            </div>
        </div>
    )
}

function ViewSettings(props) {

    var i = props.includes;
    var f = props.setIncludes;

    function fit(o) {
        if (o === 'tags') {
            f({
                tags: !i.tags,
                notes: i.notes,
                times: i.times,
                headshot: i.headshot,
            })
        } else if (o === 'notes') {
            f({
                tags: i.tags,
                notes: !i.notes,
                times: i.times,
                headshot: i.headshot,
            })
        } else if (o === 'headshot') {
            f({
                tags: i.tags,
                notes: i.notes,
                times: i.times,
                headshot: !i.headshot,
            })
        } else if (o === 'times') {
            f({
                tags: i.tags,
                notes: i.notes,
                times: !i.times,
                headshot: i.headshot,
            })
        }
    };

    const settings = [
        {fit: 'headshot', bool: i.headshot, text: 'Picture'},
        {fit: 'times', bool: i.times, text: 'Times'},
        {fit: 'tags', bool: i.tags, text: 'Tags'},
        {fit: 'notes', bool: i.notes, text: 'Notes'},
    ]

    return (
        <div className="modal-summary-people-settings">
            <div className="inline spacing-20">
            <div className="modal-summary-settings-label inline">Show: </div>
            <div>
            {
                settings.map((s, index) => (
                    <div key={index} className="inline" 
                        onClick={() => fit(s.fit)}>
                        <div className="modal-check-box">
                            <div 
                                id="check"
                                className={s.bool ? "check-on" : "check-off"} />
                            <div className="modal-check-text">{s.text}</div>
                        </div>
                    </div>
                ))
            }
            </div>
            </div>
        </div>
    )
}

function TagGroups(props) {

    const [newGroups, setGroups] = useState('');

    useEffect(() => {
        var subs = props.subjects;
        var uni = props.unique;
        var groups = [];
        if (subs && uni) {
            uni.map(item => {
                var name = item.name;
                var matches = [];
                subs.map(sub => {
                    var subTags = sub.tags ? ObjectList(sub.tags) : '';
                    if (subTags) {
                        var tagNames = subTags.map(n => n.name);
                        if (tagNames.includes(name)) {
                            matches.push(sub);
                        }
                    }
                })
                groups.push({
                    subs: matches,
                    count: item.count,
                    name: name,
                });
            })
            setGroups(groups);
        } 
    }, [props.subjects, props.unique]);

    function sortTagName() {
        setGroups((a, b) => (a.name > b.name) ? 1 : -1);
    }

    function sortTagCount() {
        setGroups((a, b) => (a.count > b.count) ? 1 : -1);
    }

    return (
        <div className="tag-group-section">
            {
                newGroups ?
                newGroups.map((group, index) => (
                    <GroupItem key={index} group={group} includes={props.includes} view={props.view} />
                ))
                :
                null
            }
        </div>
    )
}

function GroupItem(props) {
    return (
        <div className="summary-group-item min-content">
            <h3>{props.group.name}</h3>
            <div className={
                props.view.value === 'card' ? 
                "subjects-container-card min-content" : 
                props.view.value === 'list' ?
                "subjects-container-list min-content" :
                "subjects-container min-content"}
                >
            {
                props.group.subs.map((sub, index) => (
                    
                    <SummaryCard key={sub.name} item={sub} includes={props.includes} />

                )) 
            }
            </div>
        </div>
    )
};