import React, { useEffect, useState } from 'react';

import {NullUser} from '../../../assets/nulluser';
import remove from '../../../assets/remove.png';

export default function SessionTalentModal(props) {

    const [ids, setIds] = useState('');
    const [notes, setNotes] = useState('');
    const [tags, setTags] = useState('');
    
    useEffect(() => {
        props.firebase.db.ref('/my-notes/' + props.uid + '/emps/' + props.sessUserId + '/talent/' + props.modalTalent.id)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var newIds = o ? Object.keys(o) : '';
                setIds(newIds);
            } else {
                setIds('');
            };
        })
    }, [])


    useEffect(() => {
        props.firebase.sessionNotes(props.groupSess, props.modalTalent.id)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var notes = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }));
                if (notes) {
                    notes = notes.sort((a, b) => (a.employeeId === props.sessUserId) ? 1 : -1);
                    setNotes(notes);
                }
            } else {
                setNotes('');
            }
        });

        props.firebase.selectCastingSessionTalent(props.uid, props.castSess, props.modalTalent.id)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var talent = snapshot.val();
                var tags = talent.tags ? talent.tags : '';
                var list = tags ? Object.keys(tags).map(key => ({
                    ...tags[key],
                    id: key
                })) : '';
                setTags(list);
            } else {
                setTags('');
            }
        });
        
    }, [])

    return (
        <div className="slim-modal-layout">
          <div className="modal-bg-overlay" onClick={props.close} />
          <div className="slim-modal-card">
                <img className="close-slim-modal" src={remove} alt="Close Modal" onClick={props.close} />
                <SessionTalentHeader name={props.modalTalent.subject} rep={props.modalTalent.subjectRep} />
                    <div className="slim-modal-section-div" />
                <div className="slim-modal-content">
                    <SessionTalentTags tags={tags} />
                    <div className="slim-modal-section-div" />
                    <SessionTalentNotes 
                        firebase={props.firebase} 
                        sessUserId={props.sessUserId} 
                        notes={notes} 
                        talentId={props.modalTalent.id}
                        uid={props.uid} 
                        castSess={props.castSess} 
                        groupSess={props.groupSess} 
                    />
                </div>
          </div>
        </div>
    )
}

function SessionTalentHeader(props) {
    return (
        <div className="session-talent-header">
            <img className="sess-modal-avatar" src={NullUser} alt="Actor Headshot" />
            <div>
                <div className="sess-modal-name">{props.name}</div>
                <div className="sess-modal-rep">{props.rep}</div>
            </div>
        </div>
    )
}

function SessionTalentNotes(props) {
    return (
        <div className="session-tal-mod-notes">
            <div className="session-tal-mod-label">Notes</div>
            {
                props.notes ? 
                props.notes.map((item, index) => (
                    <SessionTalentNote 
                        props={props} 
                        item={item}
                        key={item.id}
                    />
                ))
                :
                <div className="null-text">Click here to add a note</div>
            }
        </div>
    )
}

function SessionTalentNote(props) {

    const thisProps = props.props;

    const [note, setNote] = useState(props.item.note);
    const [edit, setEdit] = useState(false);

    const moveCursorToEnd = (e) => {
        var temp_value = e.target.value;
        e.target.value = '';
        e.target.value = temp_value;
        setEdit(true);
    };

    const saveChanges = () => {
        thisProps.firebase.editHostGroupSessionNote(thisProps.uid, thisProps.castSess, thisProps.groupSess, thisProps.talentId, props.item.id, note);
        thisProps.firebase.db.ref('my-notes/' + thisProps.uid + '/emps/' + thisProps.sessUserId + '/talent/' + thisProps.talentId + '/' + props.item.id + '/')
        .update({
            note: note,
        })
        setEdit(false);
    }

    return (
        <div className="session-tal-mod-note">
            <div className="session-tal-mod-by">{props.item.userName}</div>
            <textarea 
                className={edit ? "session-tal-mod-note edit-sess-tal-note" : "session-tal-mod-note"} 
                value={note} 
                onChange={(e) => setNote(e.target.value)}
                onFocus={(e) => moveCursorToEnd(e)} 
                onBlur={saveChanges}
            />
        </div>
    )
}

function EditSessionTalentNote(props) {

}

function SessionTalentTags(props) {
    return (
        <div className="session-tal-mod-tags">
            <div className="session-tal-mod-label">Tags</div>
            {
                props.tags ?
                props.tags.map((item, index) => (
                    <SessionTalentTag key={item.id} item={item} />
                ))
                :
                <div className="null-text">No tags</div>
            }

        </div>
    )
}

function SessionTalentTag(props) {
    return (
        <div className="sly-note-listed-tag-item">
            {props.item.tagName}
        </div>
    )
}