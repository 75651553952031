import React, { useState } from 'react';

import Overview from './PanelTabs/Overview';
import Talent from './PanelTabs/Talent';

export default function SessionPanel(props) {

    const [overview, setOverview] = useState(true);
    const [schedule, setSchedule] = useState(true);
    
    return (
        <div className="session-mode-panel">
            <div className="back-to-quickmenu" onClick={props.exitSessionMode}>Back</div>
            <div className="session-mode-title">Session Overview
            </div>

            <div className="session-nav-divider" />
            <div>
                {
                    overview ?
                    <div>
                        <div className="collapse-session-nav">
                            <div
                                className="min-collapse-nav"
                                onClick={() => setOverview(false)}>
                            
                            -
                            </div>
                            <Overview 
                                props={props}
                                firebase={props.firebase}
                            />
                        </div>
                        </div>
                        :
                        <div className="expand-label"
                            onClick={() => setOverview(true)}>
                            Expand Overview
                        </div>
                        }
                

                        <div className="session-nav-divider" />
                        {
                            schedule ?
                            <div className="collapse-session-nav">
                                <div
                                    className="min-collapse-nav"
                                    onClick={() => setSchedule(false)}>
                                
                                -
                                </div>
                                <Talent 
                                    props={props}
                                    firebase={props.firebase}
                                />
                            </div>
                            :
                            <div className="expand-label" 
                                onClick={() => setSchedule(true)}>
                                Expand Schedule
                            </div>
                        }
                        </div>

                </div>
            
    )
}
