import React, { Component } from 'react';

import { RoleItem } from './RoleItem';
import { ConfirmPopup } from '../../Common/ConfirmPopup';

import moment from 'moment';

import backarrow from '../../../assets/left-arrow.png';
import trashcan from '../../../assets/trashcan.png';

export default class ProjectView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            roleName: '',
            roles: [],
            confirmDeleteProject: false,
        };
    }

    componentDidMount() {
        const { firebase, item } = this.props;

        var projectId = item.id;

        firebase.projectSelect(projectId)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var p = snapshot.val();
                if (p.roles) {
                    var obj = p.roles;
                    var list = Object.keys(obj).map(key => ({
                        ...obj[key],
                        id: key,
                    }))
                    this.setState({
                        roles: list,
                    })

                    const active = list.filter((item) => {
                        return item.isActive
                    })

                    this.setState({
                        length: active.length,
                    })  
                } else {
                    this.setState({
                        roles: '',
                    })
                }
            } else {
                this.setState({
                    roles: '',
                })
            }
        })
    }

    componentWillUnmount() {
        var { item, firebase } = this.props;
        var projectId = item.id;
        firebase.projectSelect(projectId).off()
    }

    _deactivateRole = (item) => {
        var role = item.id;
        var roleId = item.id;
        var projectId = item.projectId;

        this.props.firebase.roleUpdate(role)
        .update({
            isActive: false,
        })

        this.props.firebase.projectRoleUpdate(projectId, roleId)
        .update({
            isActive: false,
        })
    }

    _activateRole = (item) => {
        var role = item.id;
        var roleId = item.id;
        var projectId = item.projectId;
        var userId = this.props.uid;

        if (this.state.length === 0) {
            this.props.firebase.projectAttributes(projectId)
                .update({
                isActive: true
            })

            this.props.firebase.userProjectAttributes(userId, projectId)
            .update({
                isActive: true
            })
        }

        this.props.firebase.roleUpdate(role)
        .update({
            isActive: true,
        })

        this.props.firebase.projectRoleUpdate(projectId, roleId)
        .update({
            isActive: true,
        })
    }

    _onEnterRole = (event) => {
        if (event.keyCode === 13 && event.shiftKey === false) {
            event.preventDefault();
            this._addRole(event);
        }
    }

    _addRole = (event) => {

        if (this.state.roleName !== '') {
        var roleName = this.state.roleName;
        var createdBy = this.props.uid;
        var item = this.props.item;
        var project = item.projectName;
        var projectId = item.id;
        var createdOn = moment().format('MM/DD/YY hh:mm a');

        this.props.firebase.addRole(roleName, createdBy, project, projectId, createdOn);

        this.setState({
            roleName: '',
        })
        } else {
            alert('The name can not be blank!')
        }
    }

    _onChange = (event, value) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    _deleteProjectPrompt = () => {

    }

    _deleteProject = () => {

        const { item, uid } = this.props;
        var projectId = item.id;

        this.props._setProjectListView();

        this.props.firebase.removeProject(projectId);
        this.props.firebase.removeUserProject(uid, projectId);
        this.props.firebase.removeRoomProjects(projectId);
        this.props.firebase.removeProjectRolesFromProjId(projectId);
        this.props.firebase.removeProjectRoomRolesFromProjId(projectId);
    }

    _deleteRole = (item) => {
        var role = item.id;
        var roleId = item.id;
        var projectId = item.projectId;

        this.props.firebase.roleUpdate(role)
        .remove();

        this.props.firebase.projectRoleUpdate(projectId, roleId)
        .remove();

        this.props.firebase.deleteRoleFromRoomRoles(roleId);
    }

    _releaseToJobFeed = (item) => {
        var releaseDate = moment().format('MM/DD/YYYY');
        var releaseTime = moment().format('h:mma');
        var min = 20;
        var max = 35;
        var local = false;
        var sag = false;
        var gender = 'Male';
        var fluid = 3;
        var status = 'Submissions Only';

        this.props.firebase.projectRoleUpdate(item.projectId, item.id)
        .update({
            released: true,
            releaseDate,
            releaseTime
        });

        this.props.firebase.addToFeed(
            this.props.uid,
            this.props.company,
            releaseDate,
            releaseTime,
            item.roleName,
            item.id,
            this.props.item.projectName,
            item.projectId,
            'Tags',
            min,
            max,
            local,
            sag,
            gender,
            fluid,
            status
        )

    };

    _removeFromJobFeed = (item) =>{
        this.props.firebase.projectRoleUpdate(item.projectId, item.id)
        .update({
            released: false,
            removeDate: moment().format('MM/DD/YYYY'),
            removeTime: moment().format('hh:mma'),
        })
    };

    _confirmDeleteProject = () => {
        this.setState({
            confirmDeleteProject: true,
        })
    };

    

    render() {

    const { item } = this.props;
    const { roleName, roles } = this.state;
    
    return (
        <div className="account-project-container">
            <div className="account-list-label button-label" onClick={this.props._setProjectListView} >
                <img src={backarrow} className="back-arrow" alt="Back to project list"/>
                <div className="project-panel-project-title">{item.projectName}</div>
            </div>
            <div className="project-list-container">
                <div className="list-background">
                    <input 
                        className="account-input"
                        name="roleName"
                        value={roleName}
                        onChange={this._onChange.bind(this)}
                        onKeyDown={this._onEnterRole}
                        type="text"
                        placeholder="Enter role to add..."
                    />
                {roles !== '' ?
                <div className="employees-layout">
                    {
                    roles.map((item, index) => (
                        <RoleItem 
                            key={item.id} 
                            item={item} 
                            _activateRole={this._activateRole}
                            _deactivateRole={this._deactivateRole}    
                            _deleteRole={this._deleteRole}
                            _removeFromJobFeed={this._removeFromJobFeed}
                            _releaseToJobFeed={this._releaseToJobFeed}
                            trashcan={trashcan}
                        />
                    ))
                    }
                </div>
                :
                <div className="null-text">No roles created</div>
            }
            <div className="delete-project-parent">
            <div className="delete-project-container" onClick={this._confirmDeleteProject} >
                <div className="delete-project-text">Delete Project</div>
                <img src={trashcan} className="account-project-trash-icon" alt="remove project" />
            </div>
            {
                this.state.confirmDeleteProject ? 
                    <ConfirmPopup  
                        message="Are you sure you want to delete this project?"
                        confirm={this._deleteProject}
                        cancel={(() => this.setState({ confirmDeleteProject: false}))}
                    />
                :
                <div />
            }
            </div>
        </div>
        </div>
        </div>
      )
    }
}
