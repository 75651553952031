import React, { Component } from 'react';

export default class TagItem extends Component {


    constructor(props) {
        super(props);

        this.state = {
            vis: '',
            count: '',
            activeSession: '',
            active: '',
        };
    }


    componentWillReceiveProps(nextProps, props) {
        if (nextProps.props !== this.props) {
            this.forceUpdate();
            this.setState({
                selectedTagIds: nextProps.selectedTagIds,
            })
        }
    }

    render() {

        var props = this.props;

        return (
            <div 
                key={props.index} 
                className={props.selectedTagIds && props.selectedTagIds.includes(props.item.id) ? "group-tag group-tag-selected inline-block internal-tags" : "group-tag inline-block internal-tags"}
                onClick={
                    props.disabled ? null :
                    props.selectedTagIds && props.selectedTagIds.includes(props.item.id) ? 
                    props._removeTalentTag.bind(this, props.item) : 
                    props._addTalentTag.bind(this, props.item)
                }
            >
                <div>{props.item.name}</div>
            </div>

        )
    }
}
/*
? <img src={eyeopen} alt="Visible By Group" className="eye-icon" onClick={this._makeTagPrivate.bind(this, props.item)} /> 
: <img src={eyeclosed} alt="Private" className="eye-icon" onClick={this._makeTagPublic.bind(this, props.item)} />
*/