import React, { Component } from 'react';
import moment from 'moment';

import {NavModalNav} from './NavModalNav';
import {NavModalBody} from './NavModalBody';
import arrow from '../../../../assets/arrowdown.png';
import remove from '../../../../assets/remove.png';

const INITIAL_STATE = {
    sessionName: '',
    sessionDate: new Date(),
    sessionStart: '',
    sessionWrap: '',
    sessionProject: '',
    sessionRoles: [],
    sessionUsers: [],
    sessionRoom: '',
    sessSlotTimes: '',
    nameExists: false,

    increment: '',
    scheduleCreated: false,

    sessionType: '',
    locationType: '',

    address: {
        locName: '',
        street: '',
        suite: '',
        city: '',
        state: '',
        zip: '',
        parking: '',
    },

    locName: '',
    street: '',
    suite: '',
    city: '',
    state: '',
    zip: '',
    parking: '',

    nlocName: '',
    nstreet: '',
    nsuite: '',
    ncity: '',
    nstate: '',
    nzip: '',
    nparking: '',

    hr1: '',
    hr2: '',
    min1: '',
    min2: '',
    ampm1: 'am',
    ampm2: 'am',

    nhr1: '',
    nhr2: '',
    nmin1: '',
    nmin2: '',
    nampm1: 'am',
    nampm2: 'am',

    roomOptions: '',
    projectOptions: '',
    employeeOptions: '',
    roleOptions: '',

    showRooms: false,
    showUsers: false,
    showRoles: false,

    newSessionName: '',

    email: '',
    slotInviteOpen: '',
    newTalentName: '',
    nameEdit: '',

    navMin: true,
    timeFocus: false,
    currentTimeFocus: '',
}

export default class NavModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            contentPage: 1,
            navMin: false,
            ...INITIAL_STATE,
        };
    };

    componentDidMount() {

        const { uid, firebase } = this.props;

        this.props.firebase.userRef(uid)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                this.setState({
                    groupRef: o,
                })
            }
        });

        if (this.props.editSession) {
            var sess = this.props.session;

            this.props.firebase.selectCastingSession(uid, sess.id)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    var session = snapshot.val();
                    var sessionStart = session.start;
                    var sessionWrap = session.wrap;
                    this.setState({
                        groupKey: session.groupKey,
                    })
                    if (sessionStart) { 
                        var hrsplit = sessionStart.split(":");
                        var minsplit = hrsplit[1].split("");
                        var ampm1 = minsplit[2] + minsplit[3];
                        var hr1 = hrsplit[0];
                        var min1 = minsplit[0] + minsplit[1];
                        this.setState({
                            hr1,
                            nhr1: hr1,
                            min1,
                            nmin1: min1,
                            ampm1: ampm1.includes('a') ? 'am' : 'pm',
                            nampm1: ampm1.includes('a') ? 'am' : 'pm',
                        })
                    };
                    if (sessionWrap) {
                        var hrsplit2 = sessionWrap.split(":");
                        var minsplit2 = hrsplit2[1].split("");
                        var ampm2 = minsplit2[2] + minsplit2[3];
                        var hr2 = hrsplit2[0];
                        var min2 = minsplit2[0] + minsplit2[1];
                        this.setState({
                            hr2,
                            nhr2: hr2,
                            min2,
                            nmin2: min2,
                            ampm2: ampm2.includes('a') ? 'am' : 'pm',
                            nampm2: ampm2.includes('a') ? 'am' : 'pm',
                        })
                    }
                    if (session.project) {
                    this.props.firebase.viewProjectRoles(session.project.value)
                    .once('value', snapshot => {
                        if (snapshot.exists()) {
                            var o = snapshot.val();
                            var l = Object.keys(o).map(key => ({
                                ...o[key],
                                id: key,
                            }));
                            if (l) {
                                this.setState({
                                    roleOptions: '',
                                })
                                var roleOptions = [];
                                l.map(i => {
                                    return roleOptions.push({
                                        label: i.roleName,
                                        value: i.id,
                                    })
                                });
                                this.setState({
                                    roleOptions: roleOptions,
                                    showRoles: true,
                                })
                            } else {
                                this.setState({
                                    roleOptions: '',
                                })
                            }
                        } else {
                            this.setState({
                                roleOptions: '',
                            })
                        }
                    })}
                    if (session.name) {
                        this.setState({
                            
                        })
                    }
                    this.setState({ 
                        session: session,
                        sessKey: this.props.session.id,
                        sessionProject: session.project,
                        sessionRoles: session.roles ? Object.keys(session.roles).map(key => ({ ...session.roles[key], id: key })) : '',
                        sessionStart: session.start,
                        sessionWrap: session.wrap,
                        sessionUsers: session.users ? Object.keys(session.users).map(key => ({ ...session.users[key], id: key })) : '',
                        sessionName: session.name,
                        newSessionName: session.name,
                        nameExists: session.name ? true : false,
                        sessionRoom: session.room,
                        sessSlotTimes: session.slotTimes ? session.slotTimes : '',
                        scheduleCreated: session.scheduleCreated ? true : false,
                        started: session.started,
                        sessionType: session.sessionType,
                        locationType: session.locationType,

                    })
                    if (session.address) {
                        var add = session.address;
                        this.setState({
                            locName: add.locName,
                            street: add.street,
                            suite: add.suite,
                            city: add.city,
                            state: add.state,
                            zip: add.zip,
                            parking: add.parking,

                            nlocName: add.locName,
                            nstreet: add.street,
                            nsuite: add.suite,
                            ncity: add.city,
                            nstate: add.state,
                            nzip: add.zip,
                            nparking: add.parking,
                        })
                    }
                }
            })
        } else {
            var sessKey = firebase.db.ref('user-casting-sessions/' + uid).push().key;
            this.setState({ sessKey: sessKey });

            firebase.draftNewCastingSession(uid, sessKey);
            firebase.selectCastingSession(uid, sessKey)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    var session = snapshot.val();
                    var sessSlotTimes = session.slotTimes ? session.slotTimes : '';
                    var sessionRoles = session.roles ? Object.keys(session.roles).map(key => ({ ...session.roles[key], id: key })) : '';
                    this.setState({
                        session,
                        sessSlotTimes,
                        sessionProject: session.project,
                        sessionRoles,
                        groupKey: session.groupKey,
                        sessionStart: session.start,
                        sessionWrap: session.wrap,
                        sessionUsers: session.users ? Object.keys(session.users).map(key => ({ ...session.users[key], id: key })) : '',
                        sessionName: session.name,
                        nameExists: session.name ? true : false,
                        newSessionName: session.name,
                        sessionRoom: session.room,
                        scheduleCreated: session.scheduleCreated ? true : false,
                        started: session.started,
                        sessionType: session.sessionType,
                        locationType: session.locationType,
                        shouldBeSaved: session.name ? session.name : false,
                        newSession: true,
                    })
                    if (session.start) { 
                        var hrsplit = session.start.split(":");
                        var minsplit = hrsplit[1].split("");
                        var ampm1 = minsplit[2] + minsplit[3];
                        var hr1 = hrsplit[0];
                        var min1 = minsplit[0] + minsplit[1];
                        this.setState({
                            hr1,
                            nhr1: hr1,
                            min1,
                            nmin1: min1,
                            ampm1: ampm1.includes('a') ? 'am' : 'pm',
                            nampm1: ampm1.includes('a') ? 'am' : 'pm',
                        })
                    };
                    if (session.wrap) {
                        var hrsplit2 = session.wrap.split(":");
                        var minsplit2 = hrsplit2[1].split("");
                        var ampm2 = minsplit2[2] + minsplit2[3];
                        var hr2 = hrsplit2[0];
                        var min2 = minsplit2[0] + minsplit2[1];
                        this.setState({
                            hr2,
                            nhr2: hr2,
                            min2,
                            nmin2: min2,
                            ampm2: ampm2.includes('a') ? 'am' : 'pm',
                            nampm2: ampm2.includes('a') ? 'am' : 'pm',
                        })
                    }
                    if (session.address) {
                        var add = session.address;
                        this.setState({
                            locName: add.locName,
                            street: add.street,
                            suite: add.suite,
                            city: add.city,
                            state: add.state,
                            zip: add.zip,
                            parking: add.parking,

                            nlocName: add.locName,
                            nstreet: add.street,
                            nsuite: add.suite,
                            ncity: add.city,
                            nstate: add.state,
                            nzip: add.zip,
                            nparking: add.parking,
                        })
                    }
                }
            })
        };
        firebase.user(uid)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var e = o.employees;
                var p = o.projects;
                if (p) {
                    var pl = Object.keys(p).map(key => ({
                        ...p[key],
                        id: key,
                    }))
                }
                if (e) {
                    var el = Object.keys(e).map(key => ({
                    ...e[key],
                    id: key,
                }))}
                if (el) {
                    var userOptions = [];
                    el.map(i => {
                        return userOptions.push({
                            label: i.employeeName,
                            value: i.id,
                            avatar: i.avatar ? i.avatar : '',
                        })
                    })
                    this.setState({
                        userOptions: userOptions,
                        showUsers: true,
                    });
                } else {
                    this.setState({
                        userOptions: '',
                    })
                };
                if (pl) {
                    var projectOptions = [];
                    pl.map(i => {
                        return projectOptions.push({
                            label: i.projectName,
                            value: i.id,
                            avatar: i.avatar ? i.avatar : '',
                        })
                    })
                    this.setState({
                        projectOptions: projectOptions,
                    });
                } else {
                    this.setState({
                        projectOptions: '',
                    })
                }
            } else {
                this.setState({
                    userOptions: '',
                    projectOptions: '',
                })
            };
        });
        firebase.rooms()
        .orderByChild('createdBy')
        .equalTo((uid))
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }));
                if (l) {
                    var roomOptions = [];
                    l.map(item => {
                        return roomOptions.push({
                            value: item.id,
                            label: item.roomName,
                        })
                    });
                    this.setState({
                        roomOptions: roomOptions,
                        showRooms: true,
                    })
                } else {
                    this.setState({
                        roomOptions: ''
                    });
                };
            } else {
                this.setState({
                    roomOptions: ''
                });
            };
        })
    }

    componentWillUnmount() {
        if (!this.props.editSession) {
            this.props.firebase.selectCastingSession(this.props.uid, this.state.sessKey).off();
        }
        if (this.props.editSession) {
            this.props.firebase.selectCastingSession(this.props.uid, this.props.session.id).off();
        }
    }

    _toggleNav = () => {
        this.setState({ navMin: !this.state.navMin })
        if (this.state.newSession) {
            this.setState({
                shouldBeSaved: true,
            });
        };
    };

   

    _currentShareModal = (index) => {
        this.setState({
            activeSharePopup: index,
        })
    };

    _toSessionOpp = () => {
        this.setState({ contentPage: 1 })
    }

    _toSessionDetails = () => {
        this.setState({ contentPage: 2 })
    }

    _toSessionSchedule = () => {
        this.setState({ contentPage: 3 })
    };

    _toSessionOperations = () => {
        this.setState({ contentPage: 4 })
    };



    _page1 = () => { this.setState({ contentPage: 1 }) };
    _page2 = () => { 
        if (this.state.sessionName !== '') {
        this.setState({ contentPage: 2 }) 
        } else {
            alert('Please title the session');
        }
    };
    _page3 = () => { 
        if (this.state.sessionName !== '') {
        this.setState({ contentPage: 3 }) 
        } else {
            alert('Please title the session');
        }
    };
    _page4 = () => { 
        if (this.state.sessionName !== '') {
        this.setState({ contentPage: 4 }) 
        } else {
            alert('Please title the session');
        }
    };

    _session1 = () => { this._selectInPerson() };
    _session2 = () => { this._selectOnline() };
    _session3 = () => { this._selectBoth() }

    _myoffice = () => { this.setState({ locationType: 'myoffice' }); this._selectOffice() };
    _custom = () => { this.setState({ locationType: 'custom' }); this._selectNewLocation() };

    _saveSessionName = () => {
        this.props.firebase.changeSessionName(this.props.uid, this.state.sessKey, this.state.sessionName);
        this.setState({
            shouldBeSaved: true,
        })
    }

    _onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
        if ([event.target.name] === 'hr1' && event.target.value.length === 2) { this._updateHr1() };
    };

    _setTimeFocusOn = () => {
        this.setState({
            timeFocus: true,
        })
    }

    _updateHr1 = () => {
        this.setState({
            currentTimeFocus: 2,
        })
    };

    _updateMin1 = () => {
        this.setState({
            currentTimeFocus: 3,
        })
    };

    _updateAmpm1 = () => {
        this.setState({
            currentTimeFocus: 4
        })
    }

    _updateHr2 = () => {
        this.setState({
            currentTimeFocus: 5,
        })
    };

    _updateMin2 = () => {
        this.setState({
            currentTimeFocus: 6,
        })
    }

    _updateAmpm2 = () => {
        this.setState({
            currentTimeFocus: 7
        })
    }

    _onEnterSessionName = (event) => {
        if (event.keyCode === 13 && event.shiftKey === false) {
            event.preventDefault();
            this._saveSessionName();
        };
    };

    _selectRoom = sessionRoom => {
        this.props.firebase.changeSessionRoom(this.props.uid, this.state.sessKey, sessionRoom)
    };

    _selectDate = sessionDate => {
        this.setState({
            sessionDate,
        })
    };

    _alertStarted = () => {
        alert('Already Started')
    }

    _selectProject = sessionProject => {
        this.props.firebase.changeSessionProject(this.props.uid, this.state.sessKey, sessionProject);
        this.setState({
            roleOptions: ''
        });
        this.props.firebase.viewProjectRoles(sessionProject.value)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }));
                if (l) {
                    var roleOptions = [];
                    l.map(i => {
                        return roleOptions.push({
                            label: i.roleName,
                            value: i.id,
                        })
                    });
                    this.setState({
                        roleOptions: roleOptions,
                        showRoles: true,
                    })
                } else {
                    this.setState({
                        roleOptions: '',
                    })
                }
            } else {
                this.setState({
                    roleOptions: '',
                })
            }
        })
    }

    _selectRole = (item) => {
        this.setState({
            roleOptions: this.state.roleOptions,
        })
        this.props.firebase.addRoleToSession(this.props.uid, this.state.sessKey, item);
    };

    _deselectRole = (item) => {
        this.props.firebase.removeRoleFromSession(this.props.uid, this.state.sessKey, item);
    };

    _selectUser = (item) => {
        this.props.firebase.addUserToSession(this.props.uid, this.state.sessKey, item);
    };

    _deselectUser = (item) => {
        this.props.firebase.removeUserFromSession(this.props.uid, this.state.sessKey, item);
    }

    _selectInPerson = () => {
        this.props.firebase.db.ref('user-casting-sessions/' + this.props.uid + '/' + this.state.sessKey)
        .update({
            sessionType: 'inperson'
        })
    }

    _selectOnline = () => {
        this.props.firebase.db.ref('user-casting-sessions/' + this.props.uid + '/' + this.state.sessKey)
        .update({
            sessionType: 'online'
        })
    }

    _selectBoth = () => {
        this.props.firebase.db.ref('user-casting-sessions/' + this.props.uid + '/' + this.state.sessKey)
        .update({
            sessionType: 'both'
        })
    }

    _selectOffice = () => {
        this.props.firebase.db.ref('user-casting-sessions/' + this.props.uid + '/' + this.state.sessKey)
        .update({
            locationType: 'office'
        })
    };

    _selectNewLocation = () => {
        this.props.firebase.db.ref('user-casting-sessions/' + this.props.uid + '/' + this.state.sessKey)
        .update({
            locationType: 'custom'
        });
    };

    _updateLocPark = () => {
        var s = this.state;
        var address = {
            locName: s.locName,
            street: s.street,
            suite: s.suite,
            city: s.city,
            state: s.state,
            zip: s.zip,
            parking: s.parking,
        }

        this.props.firebase.db.ref('user-casting-sessions/' + this.props.uid + '/' + this.state.sessKey)
        .update({
            address: address
        })
    };



    _saveChanges = () => {
        var s = this.state;
        var uid = this.props.uid;
        var sessionStart = s.hr1 + ':' + s.min1 + ' ' + s.ampm1;
        var sessionWrap = s.hr2 + ':' + s.min2 + ' ' + s.ampm2;
        var sessionDate = moment(this.state.sessionDate).format('MM/DD/YYYY');
        var slotTimes = s.sessSlotTimes ? s.sessSlotTimes : '';
        var hasSche = this.state.scheduleCreated ? true : false;
        var address = {
            locName: s.locName,
            street: s.street,
            suite: s.suite,
            city: s.city,
            state: s.state,
            zip: s.zip,
            parking: s.parking,
        };
        this.props.firebase.createNewCastingSession(
            uid, s.sessionName, sessionDate, sessionStart, sessionWrap, s.sessionRoom, s.sessionProject, s.sessKey, slotTimes, hasSche, address
        );
    }

    _submitSession = () => {
        var s = this.state;
        var uid = this.props.uid;
        var sessionStart = s.hr1 + ':' + s.min1 + ' ' + s.ampm1;
        var sessionWrap = s.hr2 + ':' + s.min2 + ' ' + s.ampm2;
        var sessionDate = moment(this.state.sessionDate).format('MM/DD/YYYY');
        var slotTimes = s.sessSlotTimes ? s.sessSlotTimes : '';
        var hasSche = this.state.scheduleCreated ? true : false;
        this.props.firebase.createNewCastingSession(
            uid, s.sessionName, sessionDate, sessionStart, sessionWrap, s.sessionUsers, s.sessionRoom, s.sessionProject, s.sessionRoles, s.sessKey, slotTimes, hasSche
        );
        this.setState({
            ...INITIAL_STATE
        });
        this.props._closeModal();
    }
    
    _deleteSession = () => {

        this.setState({
            confirmDeleteAlert: true,
        })

    }

    _confirmDeleteSession = () => {

        this.setState({
            confirmDeleteAlert: false,
        });

        this.props.firebase.selectCastingSession(this.props.uid, this.state.sessKey).remove();

        this.props._closeModal();
    }

    _createTalentSchedule = () => {
        this.props.firebase.db.ref('user-casting-sessions/' + this.props.uid + '/' + this.state.sessKey)
        .update({
            scheduleCreated: true,
        })
    };

    _generateSlots = () => {

        this._createTalentSchedule();

        var startTime = this.state.sessionStart;
        var wrapTime = this.state.sessionWrap;
        var difference = this._timeDifference(wrapTime, startTime);
        var slots = difference/this.state.increment;

        this._listSlotTimes(slots, 0, [], startTime);
    };

    _timeDifference = (time1, time2) => {
        var diff = moment(time1, "hh:mma").diff(moment(time2, "hh:mma"));
        var time = diff > 60 ? diff / 1000 : 60;
        var mins = time / 60;
        return mins;
    };

    _listSlotTimes = (slots, counter, slotTimes, startTime) => {
        
        var start = moment(startTime, 'hh:mma');
        var increment = this.state.increment;
        var additional = counter * increment;
        if (counter < slots) {
            slotTimes.push({
                time: start.add(Number(additional), 'minutes').format('hh:mma'),
                name: '',
                ref: '',
                status: 'Open',
                role: '',
                invite: false,
            });
            counter++;
            this._listSlotTimes(slots, counter, slotTimes, startTime);
        } 
        if (counter === slots || (counter >= slots && counter < Math.round(slots) + 1)) { 
            this.props.firebase.addSlotTimesToSession(this.props.uid, this.state.sessKey, slotTimes);
            this.props.firebase.createSessionSchedule(this.state.sessKey, this.state.groupKey, this.state.groupRef, slotTimes);
        };
    };

    // Functionality for Slots

    _onEnterNewTalent = (event, index) => {
        if (event.keyCode === 13 && event.shiftKey === false) {
            event.preventDefault();
            this._adjustTalentSlot(event, index);
          }
    };


    _onSelect = (e) => {
        var filter = this.props.roles.filter(f => {
            return f.value === e.target.value;
        });
        this.props.firebase.updateSessionSlotRole(this.props.uid, this.props.sessKey, this.props.slotId, filter[0].label, filter[0].value, this.state.groupKey);
        this.setState({
            role: e.target.value,
        })
    };

    _adjustTalentSlot = (event, index) => {
        this.props.firebase.updateSlotSession(this.props.uid, this.props.sessKey, index, this.state.newTalentName, this.state.groupKey);
    };

    _clearSessionSlot = () => {
        this.props.firebase.clearSlotSession(this.props.uid, this.props.sessKey, this.props.slotId, this.state.groupKey);
    };

    _openShare = () => {
        this.setState({
            share: true,
        })
    };

    _hoverIndex = (index) => {
        if (this.state.hoverIndex !== index) {  
            this.setState({
                hoverIndex: index,
            })
        }
    };

    _hoverOut = () => {
        this.setState({
            hoverIndex: '',
        });
    };

    _submitInvite = () => {
        var details = this.props.details;
        var date = moment(details.sessionDate).format('MM/DD/YYYY');
        this.props.firebase.newCastingSessionInvite(
            this.props.uid, 
            this.state.email,
            details.sessionProject.label,
            details.sessionProject.value, 
            this.props.slot.role, 
            this.props.slot.roleId, 
            date,
            this.props.slot.time,
            this.props.slot.name,
            this.props.sessKey,
            'Location',
            this.state.groupKey
        );
        this.setState({
            share: false,
            email: ''
        });
    };

    _closeModalPrep = () => {
        if (this.state.newSession) {
            if (this.state.shouldBeSaved) {
                this.props._closeModal();
            } else {
                this.props.firebase.selectCastingSession(this.props.uid, this.state.sessKey).remove();
                this.props._closeModal();
            }
        } else {
            this.props._closeModal();
        }
    };

    _finishSession = () => {
        this._closeModalPrep();
    };

    render() {

        var s = this.state;
        const showSave = 
            s.hr1 !== s.nhr1 ||
            s.hr2 !== s.nhr2 ||
            s.min1 !== s.nmin1 ||
            s.min2 !== s.nmin2 ||
            s.ampm1 !== s.nampm1 ||
            s.ampm2 !== s.nampm2 ||
            s.locName !== s.nlocName ||
            s.street !== s.nstreet ||
            s.suite !== s.nsuite ||
            s.city !== s.ncity ||
            s.state !== s.nstate ||
            s.zip !== s.nzip ||
            s.parking !== s.nparking
        ;


    return (
        <div className="custom-modal-layout">
            <div className="modal-bg-overlay"/>
            <div className="nav-modal-card">
                <div className={this.state.navMin ? "nav-modal-nav nav-minimized" : "nav-modal-nav"}>
                {
                    this.state.navMin ?
                        this.state.sessionName ?
                        <img className="nav-modal-arrow arrow-right" src={arrow} alt="Toggle Nav" onClick={this._toggleNav.bind(this)} />
                        :
                        <div />
                    :
                    <div>
                        <img className="nav-modal-arrow arrow-left" src={arrow} alt="Toggle Nav" onClick={this._toggleNav.bind(this)} />
                        <NavModalNav 
                            state={this.state} 
                            _page1={this._page1}
                            _page2={this._page2}
                            _page3={this._page3}
                            _page4={this._page4}
                            _deleteSession={this._deleteSession}
                        />
                        {
                        this.state.confirmDeleteAlert ?
                        <div className="delete-alert-container">
                            <div className="delete-alert-text">Are you sure you want to delete this session?</div>
                            <div className="delete-alert-btns">
                                <button onClick={(() => this.setState({ confirmDeleteAlert: false }))} className="delete-alert-btn alert-confirm">Cancel</button>
                                <button onClick={this._confirmDeleteSession} className="delete-alert-btn alert-cancel">Yes</button>
                            </div>
                        </div>
                        :
                        <div />
                        }
                    </div>
                }
                </div>
                <div 
                    className="nav-modal-content" 
                    onClick={this.state.activeSharePopup && this.state.hoverIndex !== this.state.activeSharePopup ? (() => this.setState({ activeSharePopup: '' })) : null} >
                    <button onClick={this._closeModalPrep} className="close-modal-btn"><img src={remove} className="close-modal-btn" alt="Close Modal" /></button>
                    <NavModalBody 
                        state={this.state} 
                        uid={this.props.uid}
                        firebase={this.props.firebase}

                        showSave={showSave}
                        _currentShareModal={this._currentShareModal}
                        _hoverIndex={this._hoverIndex}
                        _hoverOut={this._hoverOut}
                        
                        _onChange={this._onChange}
                        _selectProject={this._selectProject}
                        _selectRole={this._selectRole}
                        _deselectRole={this._deselectRole}

                        _selectInPerson={this._selectInPerson}
                        _selectOnline={this._selectOnline}
                        _selectBoth={this._selectBoth}
                        _selectOffice={this._selectOffice}
                        _selectCustom={this._selectCustom}

                        _saveSessionName={this._saveSessionName}
                        _onEnterSessionName={this._onEnterSessionName}
                        _saveChanges={this._saveChanges}
                        _selectUser={this._selectUser}
                        _deselectUser={this._deselectUser}
                        _selectRoom={this._selectRoom}
                        _selectDate={this._selectDate}
                        _generateSlots={this._generateSlots}
                        _editSlotName={this._editSlotName}
                        _onSelect={this._onSelect}
                        _openShare={this._openShare}
                        _clearSessionSlot={this._clearSessionSlot}
                        _submitInvite={this._submitInvite}
                        _onEnterNewTalent={this._onEnterNewTalent}
                        _session1={this._session1}
                        _session2={this._session2}
                        _session3={this._session3}
                        _myoffice={this._myoffice}
                        _custom={this._custom}
                        _setSlotRole={this._setSlotRole}
                        _sendSlotInvite={this._sendSlotInvite}
                        _onSearchTalent={this._onSearchTalent}

                        _toSessionOpp={this._toSessionOpp}
                        _toSessionDetails={this._toSessionDetails}
                        _toSessionOperations={this._toSessionOperations}
                        _toSessionSchedule={this._toSessionSchedule}

                        _finishSession={this._finishSession}
                        
                        />
                </div>
            </div>
        </div>
    );
}
}
;