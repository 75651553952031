import React, { useState } from 'react';

import filter from '../../assets/filtericon.png';

export default function FilterDropdown(props) {

    const [select, setSelect] = useState(props.default);
    const [open, setOpen] = useState(false);

    const initiateFunction = (item) => {
        props.function(item);
        setSelect(item);
        setOpen(false);
    };

    return (
        <div className={open ? "filter-box filter-box-shadow" : "filter-box"}>
        <div className={open ? "filter-dropdown-container" : "filter-dropdown-container"}
            onClick={() => setOpen(!open)}
        >
            <div className="filter-content">
                {select}
            </div>
            <div className="filter-toggle">
                <img className="filter-toggle" src={filter} alt="Filter Sessions" />
            </div>
        </div>
        {
            open ? 
            <div id={props.id} className="filter-options">
                {
                    props.options.map((item, index) => (
                        <div 
                            className={item === select ? "filter-option select-filter-option" : "filter-option"} 
                            value={item}
                            onClick={initiateFunction.bind(this, item)}
                            key={index}
                            >
                            
                            {item}</div>
                    ))
                }
            </div>
            :
            <div />
        }

        </div>
    )
}
