import React from 'react';

import DashSessionsList from './DashComponents/DashSessionsList';

export default function TempHome(props) {
    return (
        <div className="temp-dash-main-content">
            <div className="home-page-section">
                <div className="home-page-header">
                    Dashboard
                </div>
                <div className="home-page-section-divider">
                    <DashSessionsList props={props} />
                </div>
            </div>
        </div>
    )
}
