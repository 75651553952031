import React from 'react';

const EmployeeFAQ = () => {

    return (

        <div className="topic">
        <div className="topic-header">Creating and utilizing employee accounts</div>
        <div className="topic-description">
            Although your company uses one account on ANi Check In, your employees have the option to log themselves in to a session to contribute to certain features as themselves.
            <br />
            <br />
            <br />
            <div className="topic-sub-header">EMPLOYEES</div>
            <b>The employees section</b> is located on your Account page. Since employees will be reading actors for auditions, we often refer to them as 'Readers' on the platform. 
            <br />
            <br />
            <b>To add an employee</b>, type in the employee's name at the top of the employee section and press enter. 
            <br />
            <br />
            <b>To delete an employee</b>, click on the trashcan to the right of the employee's name. Please note you will still be able to see the employee's notes, chat messages, and they will be listed on previously read actor cards as their reader.
            <br />
            <br />
            <div className="topic-sub-header">USER SESSIONS</div>
            As an employee on the account, you can interact with the app as yourself by selecting your name on the <b>'Select employee' dropdown</b> on the top left of the screen. Your device will remember who you are every time you come back to the site until you either logout or change the name in the dropdown.
            <br />
            <br />
            The benefits of doing this will enable the rest of your team to see your name next to notes you take on actors or chat messages you send to the group.
            <br />
            <br />
            We will be adding in an <b>Employee PIN</b> number soon that you will have to type in when you select your name from the dropdown. This is to prevent other employees from completing actions under your name. 
        </div>      
        </div>
    )
}

export { EmployeeFAQ }