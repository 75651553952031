import React, { useState, useEffect } from 'react';
import FilterDropdown from '../../../Common/FilterDropdown';
import ArrivalCard from '../../../Common/ArrivalCard';
import gear from '../../../../assets/nav/gear.png';
import moment from 'moment';

export default function Arrived(props) {

    const [arrivals, setArrivals] = useState('');
    const [filtered, setFiltered] = useState('');
    const [filter, setFilter] = useState('');
    const [ids, setIds] = useState('');
    const [focus, setFocus] = useState('');

    useEffect(() => {
        if (props.arrivals) {
            setArrivals(props.arrivals);
        } else {
            setArrivals('');
        }
        prepFilter();
    }, [props.arrivals]);

    useEffect(() => {
        if (props.sessObj.positions) {
            var obj = props.sessObj.positions;
            var pos = Object.keys(obj).map(key => ({
                id: key,
            }));
            var posIds = pos.map(i => i.id);
            setIds(posIds)
        } else {
            setIds('');
        };
    }, [props.sessObj]);

    useEffect(() => {
        setFilter('Session')
    }, [])

    useEffect(() => {
        prepFilter(filter)
    }, [filter]);

    function prepFilter() {
        if (props.arrivals) {
            if (filter === 'Session' && ids) {
                var fil = props.arrivals.filter(fil => {
                    return ids.includes(fil.interview.id) && fil.complete === false;
                });
                setFiltered(fil);
            };
            if (filter === 'All') {
                setFiltered(props.arrivals);
            }
        } else {
            setFiltered('');
        }
    };

    function prepFocus(item) {
        if (props.sessObj.location.office) {
            var pck = {
                room: props.sessObj.location.office.name,
                name: item.name,
                by: props.uid,
                cid: props.cid,
            }
            props.firebase.apiNewAlert(pck);
        }
        var timestamp = moment().format('MM/DD/YYYY hh:mm:ss a');
        props.firebase.apiSession(props.id)
        .update({
            focus: item.id
        });
        props.firebase.apiCompanyGuest(props.cid, item.id)
        .update({
            session: props.id,
            track: {
                call: timestamp,
                in: item.track.in,
                out: '',
            },
            status: 'active',
        });
        var pck = {
            name: item.name,
            response: item.response,
            type: item.type,
            track: {
                call: timestamp,
                in: item.track.in,
                out: '',
            },
            interview: item.interview,
            meeting: item.meeting,
        };
        if (item.appt) {
            props.firebase.apiInvite(item.appt)
            .update({
                join: true,
            });
        };
        props.firebase.apiNewSessionSubject(props.id, item.id, pck);
        props.setOpen(false);
    };

    return (
        <div>
            <div className="filter-holder">
                <Filter filter={filter} setFilter={setFilter} />
            </div>
            <List filtered={filtered} focus={props.sessObj.focus} prepFocus={prepFocus} />
        </div>
    );
};

function Filter(props) {

    var options = ['All', 'Session'];

    return (
        <FilterDropdown 
            options={options}
            function={props.setFilter}
            default='Session'
        />
    )
};

function List(props) {
    return (
        <div className="arrival-list">
        <div className="nav-section-label">Checked In</div>
        {
            props.filtered ? 
            props.filtered.map((item, index) => (
                <ListItem focus={props.focus} key={item.id} item={item} prepFocus={props.prepFocus} />
            )) 
            :
            <div className="null-text">No one checked in</div>
        }
        </div>
    )
};

function ListItem(props) {

    const [settings, setSettings] = useState(false);

    function sendBackToCheckIn() {
    };

    return (
        <div className="relative">
            <img className="arrival-card-icon" src={gear} alt="Actor Settings" onClick={() => setSettings(!settings)} />
            <div className={props.focus === props.item.id ? "arrival-card-container focused-arrival" : "arrival-card-container"}
                onClick={!props.focus ? () => props.prepFocus(props.item) : null}
            >   
                <ArrivalCard 
                    name={props.item.name} 
                    pos={props.item.interview.name} 
                    sub={props.item.response} 
                    type="checked" track={props.item.track}  
                    settings={settings}
                    removeFunction={sendBackToCheckIn}    
                />
            </div>
        </div>
    )
}
