import React, { useState, useEffect } from 'react';
import { Button, FormInput } from './Form';

export default function Audition(props) {

    const [position, setPosition] = useState('');
    const [notSure, setNotSure] = useState(false);
    const [questions, setQuestions] = useState('');
    const [response, setResponse] = useState('');
    const [resFade, setResFade] = useState(false);
    const [stage, setStage] = useState(1);
    const [custom, setCustom] = useState('');
    
    function submitCustom() {

    }

    function selectPosition(item) {
        var pos = {name: item.name, aff: item.aff, id: item.id};
        setPosition(pos);
        var posTimer = setTimeout(() => {
            setStage(2)
        }, 300);
        var resFadeTimer = setTimeout(() => {
            setResFade(true);
        }, 600);
        return (() => clearTimeout(posTimer, resFadeTimer));
    };    

    function addAnswer(ans) {
        setResponse(ans);
        setResFade(false);
        var pck = {
            position: position,
            response: ans,
        }
        props.prepPck(pck);
        var ansTimer = setTimeout(() => {
            setStage(3)
            setResponse('');
            setPosition('');
            setNotSure(false);
        }, 500);
        var thanksTimer = setTimeout(() => {
            setStage(1);
            props.setNameDone(false);
            props.setRemoveHeader(false);
        }, 5000);
        return (() => clearTimeout(ansTimer, thanksTimer));
    };

    return (
        <div className="interview-container">
            {
                stage === 1 ?
                <SelectPosition 
                    position={props.position} 
                    positions={props.positions} 
                    custom={custom} 
                    setCustom={setCustom} 
                    submitCustom={submitCustom} 
                    selectPosition={selectPosition}
                /> :
                stage === 2 ?
                <div className={resFade ? "fadeInHeader" : "fadeOutHeader"}>
                    <Questions resFade={resFade} addAnswer={addAnswer} /> 
                </div>
                :
                stage === 3 ?
                <ThankYou greeting={props.greeting} />
                :
                null
            }
        </div>
    )
}

function SelectPosition(props) {
    return (
        <div className={props.position ? "fadeOutHeader" : "fadeInHeader"}>
            <div id="positions-list" className="input-holder">
            <div className="check-in-label-question">Which position are you here for?</div>
            <div className="interview-positions">
                {
                    props.positions ?
                    props.positions.map((item, index) => (
                        <PositionItem key={item.id} item={item} selectPosition={props.selectPosition} />
                    ))
                    :
                    <FormInput 
                        value={props.custom}
                        onChange={(e) => props.setCustom(e)}
                        label="Enter the position title"
                        onClick={() => props.submitCustom()}
                        btntext="Submit"
                    />
                }
            </div>
            <button className="question-button" onClick={() => props.setNotSure(true)}>Not sure?</button>
            </div>
        </div>
    )
}

function PositionItem(props) {
    return (
        <div className="form-position-item" onClick={() => props.selectPosition(props.item)}>
            {props.item.name}
        </div>
    )
};

function Questions(props) {

    const [answer, setAnswer] = useState('');

    function saveAnswer() {
        setAnswer('');
        props.addAnswer(answer);
    };

    return (
        <FormInput 
            value={answer}
            onChange={(e) => setAnswer(e)}
            onClick={() => saveAnswer()}
            btntext={answer.length > 0 ? "Submit" : "No representation"}
            label="If represented, type company"
            rule={true}
            autoFocus
        />
    )
}

function ThankYou(props) {
    return (
        <div>
            <div className="check-in-thanks">{props.greeting}</div>
        </div>
    )
}