import React from 'react';

import tagCountIcon from '../../assets/tag.png';
import noteCountIcon from '../../assets/speech-bubble.png';


const SearchListContent = (props) => {

    return (
        props.numfilters < 1 ?
        <div className="no-filters-text">
            Please enter at least one filter to continue!
        </div>
        :
        <div className="search-list-container">
            <div className="search-list-left">
                <div className="table-header">Name</div> 
                { 
                props.masterFilter !== '' ?
                props.masterFilter.map((item, index) => (
                    <div key={index} className="search-list-item-container" onClick={props._openNotesModal.bind(this, item)}>
                        <div className="item-name">
                            <div className="item-name-border">
                                {item.checkInName}
                            </div>
                        </div>
                </div>))
                :
                <div />
                }
            </div>
            <div className="search-list-right">
                <div className="search-list-table">
                    <div className="table-header">Project</div>
                    <div className="table-header">Role</div>
                    <div className="table-header">Rep</div>
                    <div className="table-header">Notes</div>
                    <div className="table-header">Date</div>
                    <div className="table-header" />
                </div>
            {
                props.masterFilter !== '' ?
                props.masterFilter.map((item, index) => (
                    <div key={index} onClick={props._openNotesModal.bind(this, item)} className="search-list-item-container">
                        <div className="search-list-table">
                            <div className="item-element">{item.checkInProject}</div>
                            <div className="item-element">{item.checkInRole}</div>
                            <div className="item-element">{item.checkInRepCompany}</div>
                            <div className="item-element">
                                <div className="item-counts">
                                    <img className="count-icon" alt="Note Count" src={noteCountIcon} />
                                    <div className="item-count">{item.notes}</div>
                                    <img className="count-icon" alt="Tag Count" src={tagCountIcon} />
                                    <div className="item-count">{item.tags}</div>
                                </div>
                            </div>
                            <div className="item-element">{item.checkInTimeDay}</div>   
                            <div className="item-element" />
                        </div>
                    </div>
                ))
                :
                <div className="null-table">
                    No talent match the filters
                </div>
                }
            </div>
        </div>
        )

};

export { SearchListContent };