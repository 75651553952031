import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import moment from 'moment';

import ProfileImageUpload from './ProfileImageUpload';
import { TalentAuditions } from './TalentAuditions';
import { TalentJobFeed } from './TalentJobFeed';

import { NullUser } from '../../../assets/nulluser';
import arrow from '../../../assets/arrowdown.png';

import { withFirebase } from '../../Firebase';
import { withAuthorization } from '../../Session';
import * as ROUTES from '../../../constants/routes';

import '../talent.css';

const INITIAL_STATE = {
    avatar: '',
    username: '',
    rep: '',
    auditions: '',
    auditionsLoading: true,
    jobsLoading: true,
    navbar: true,
};

class TalentDashboardBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...INITIAL_STATE
        };
    };

    componentDidMount() {

        this.setState({
            jobsLoading: false,
        })

        this.listener = this.props.firebase.auth.onAuthStateChanged(
            authUser => {
                authUser
                ?
                this.setState({ uid: authUser.uid })
                : 
                this.setState({ authUser: null });


                if (authUser) {
                this.props.firebase.talentUser(authUser.uid)
                .on('value', snapshot => {
                    if (snapshot.exists()) {
                        var o = snapshot.val();
                        this.setState({
                            user: snapshot.val(),
                            avatar: o.avatar ? o.avatar : NullUser,
                            username: o.username ? o.username : '',
                            newUsername: o.username ? o.username : '',
                            rep: o.rep ? o.rep : '',
                            newRep: o.rep ? o.rep : '',
                        })
                    };
                })

                this.props.firebase.talentAppts(authUser.uid)
                .on('value', snapshot => {
                    if (snapshot.exists()) {
                        var o = snapshot.val();
                        var l = Object.keys(o).map(key => ({
                            ...o[key],
                            id: key
                        }));
                        this.setState({ 
                            auditions: l ? l : '',
                            auditionsLoading: false,
                        })
                    } else {
                        this.setState({ 
                            auditions: '', 
                            auditionsLoading: false 
                        })
                    };
                })

                const today = moment().format('MM/DD/YYYY');

                this.props.firebase.jobFeed()
                .orderByChild('date')
                .equalTo((today))
                .on('value', snapshot => {
                    if (snapshot.exists()) {
                        var o = snapshot.val();
                        var l = Object.keys(o).map(key => ({
                            ...o[key],
                            id: key,
                        }));
                        this.setState({
                            jobs: l ? l : '',
                        });
                    } else {
                        this.setState({
                            jobs: '',
                        });
                    };
                })
                }
            }
        )
    }

    componentWillUnmount() {
        this.props.firebase.talentUser(this.state.uid).off();
        this.props.firebase.talentAppts(this.state.uid).off();
        this.props.firebase.jobFeed().off();
    }

    _handleProfPicUpload = (e) => {
        if (e.target.files[0]) {
            const image = e.target.files[0];
            this.props.firebase.uploadTalentAvatar(this.state.uid, image);
        };
    };

    _changeUsername = () => {
        this.props.firebase.talentUser(this.state.uid)
        .update({
            username: this.state.username,
        });
        this.props.firebase.updateTalentSearch(this.state.uid)
        .update({
            username: this.state.username,
        })
    };

    _changeRepCompany = () => {
        this.props.firebase.talentUser(this.state.uid)
        .update({
            rep: this.state.rep,
        })

        this.props.firebase.updateTalentSearch(this.state.uid)
        .update({
            rep: this.state.rep
        });
    };

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    _toggleNav = () => {
        this.setState({ navbar: !this.state.navbar })
    };

    render() {

        return (

            <div className="talent-dashboard-container">
                <div className={this.state.navbar ? "tal-dash-settings" : "tal-dash-settings dash-sidebar-min"}>
                    <img 
                        className={this.state.navbar ? "tal-dash-min-arrow right-arrow" : "tal-dash-min-arrow left-arrow"} 
                        src={arrow} 
                        alt="Toggle Navbar" 
                        onClick={this._toggleNav.bind(this)} 
                    />
                    {
                        this.state.navbar ? 
                        <div>
                        <div className="tal-dash-pic">
                        <div className="tal-dash-title">My Account</div>
                        {
                            this.state.avatar 
                            ? 
                            <div className="tal-dash-prof-pic-container">
                            <ProfileImageUpload avatar={this.state.avatar} _handleProfPicUpload={this._handleProfPicUpload} />
                            </div>
                            :
                            <div />
                        }
                        </div>
                        <div>
                            <div className="tal-dash-label">Email Address</div>

                        </div>
                        <div>
                            <div className="tal-dash-label">My ANi ID</div>
                            <input className="tal-dash-input" value={this.state.username} onChange={this.onChange.bind(this)} name="username" />
                            {
                                this.state.username !== this.state.newUsername ? 
                                <button onClick={this._changeUsername}>Save</button>
                                :
                                <div />
                            }
                            <div>Your username will not be displayed to casting directors, but you can use it to auto-populate audition forms upon checkin.</div>
                        </div>
                        <div>
                            <div className="tal-dash-label">Rep Company</div>
                            <input className="tal-dash-input" value={this.state.rep} onChange={this.onChange.bind(this)} name="rep" />
                            {
                                this.state.rep !== this.state.newRep ? 
                                <button onClick={this._changeRepCompany}>Save</button>
                                :
                                <div />
                            }
                            <div>Please enter in your primary representation company that you'd like to show up on Check in Forms.</div>
                        </div>
                        <div>
                            <div className="tal-dash-label">Short Bio</div>
                            <textarea className="tal-dash-textarea" placeholder="Enter bio here" />
                        </div>
                        
                        </div>
                        :
                        <div />
                        }
                    </div>
                    {
                        this.state.jobsLoading ?
                        <div />
                        :
                        <TalentJobFeed state={this.state} />
                    }
                    {
                        this.state.auditionsLoading ?
                        <div />
                        :
                        <TalentAuditions state={this.state} />
                    }
                    
            </div>
        )
    };
};

const TalentDashboard = compose(
    withRouter,
    withFirebase,
)(TalentDashboardBase);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(TalentDashboard);


