import React from 'react';

import DatePicker from 'react-datepicker';

import inperson from '../../../../../assets/talk-show.png';
import online from '../../../../../assets/tvsession.png';
import bothicon from '../../../../../assets/bothicon.png';


const DetailsSection = (props) => {

    const s = props.state;

    return (
        <div className= "nav-section-container">
            <div className="nav-section-header-text">Details Section</div>
            
            <div className="nav-content-container">

            <div className="nav-section-section">
                <div className="session-modal-label">Which date is the session?</div>
                <div className="date-picker-input">
                <DatePicker 
                    className="date-picker-input center-input date-picker" 
                    selected={props.state.sessionDate}
                    onChange={props._selectDate} 
                    dateFormat="MM / dd / yyyy"
                />
                </div>
            </div>

            <div className="nav-section-section">
                <div className="session-modal-label">Set the session's time window</div>
                
                <div className="inline-block margin-right-30 margin-bottom-20">
                    <div className="session-modal-sub-label">Start Time</div>
                    <div className="time-inputs">
                        <input 
                            className="session-modal-time-input center-input" 
                            value={props.state.hr1} 
                            type="number" 
                            name="hr1"
                            onChange={props._onChange.bind(this)} 
                            placeholder="10" 
                            onFocus={props._setTimeFocusOn}
                            onBlur={props._updateHr1}
                        />
                        <input 
                            className="session-modal-time-input center-input" 
                            value={props.state.min1} 
                            type="number" 
                            name="min1" 
                            onChange={props._onChange.bind(this)} 
                            placeholder="30" 
                            onFocus={props._setTimeFocusOn}
                            onBlur={props._updateMin1}
                        />
                        <select 
                            className="session-modal-input-select center-input" 
                            value={props.state.ampm1} 
                            name="ampm1" 
                            onChange={props._onChange.bind(this)}
                            onFocus={props._setTimeFocusOn}
                            onBlur={props._updateAmpm1}
                        >
                            <option value="am">AM</option>
                            <option value="pm">PM</option>
                        </select>
                    </div>
                </div>

                <div className="inline-block margin-right-20 margin-bottom-20">
                    <div className="session-modal-sub-label">Wrap Time</div>
                    <div className="time-inputs">
                        <input className="session-modal-time-input center-input" value={props.state.hr2} type="number" name="hr2" onChange={props._onChange.bind(this)} placeholder="2" />
                        <input className="session-modal-time-input center-input" value={props.state.min2} type="number" name="min2" onChange={props._onChange.bind(this)} placeholder="00" />
                        <select className="session-modal-input-select center-input" value={props.state.ampm2} name="ampm2" onChange={props._onChange.bind(this)}>
                            <option value="am">AM</option>
                            <option value="pm">PM</option>
                        </select>
                    </div>
                </div>

                {   
                    s.hr1 !== s.nhr1 ||
                    s.hr2 !== s.nhr2 ||
                    s.min1 !== s.nmin1 ||
                    s.min2 !== s.nmin2 ||
                    s.ampm1 !== s.nampm1 ||
                    s.ampm2 !== s.nampm2
                    ?
                    <div className="inline-block margin-bottom-20">
                        <button className="save-changes-nav-btn" onClick={props._saveChanges}>Save Changes</button>
                    </div>
                    :
                    <div />
                }
            </div>

            <div className="nav-section-section">
                <div className="session-modal-label">How are the actors auditioning?</div>
                <button 
                    className={props.state.sessionType === 'inperson' ? "session-type-option selected-option" : "session-type-option"} 
                    onClick={props._session1} 
                >
                    <div className="session-type-option-icon">
                        <img className="session-type-img" src={inperson} alt="Icon" />
                    </div>
                    <div className="session-type-option-text">In Person</div>
                </button>
                <button 
                    className={props.state.sessionType === 'online' ? "session-type-option selected-option" : "session-type-option"} 
                    onClick={props._session2} 
                >
                    <div className="session-type-option-icon">
                        <img className="session-type-img" src={online} alt="Icon" />
                    </div>
                    <div className="session-type-option-text">Online</div>
                </button>
                <button 
                    className={props.state.sessionType === 'both' ? "session-type-option selected-option" : "session-type-option"} 
                    onClick={props._session3} 
                >
                    <div className="session-type-option-icon">
                        <img className="session-type-img" src={bothicon} alt="Icon" />
                    </div>
                    <div className="session-type-option-text">Both</div>
                </button>
            </div>

            {
                props.state.sessionType === 'inperson' || props.state.sessionType === 'online' ? 
                <div className="nav-section-section">
                    <div className="session-modal-label">Where is the session taking place?</div>
                    
                    <button 
                        className={props.state.locationType === 'office' ? "session-type-option selected-option type-role" : "session-type-option type-role"}
                        onClick={props._myoffice}    
                    >
                        <div className="session-type-option-text">My Office</div>
                    </button>

                    <button 
                        className={props.state.locationType === 'custom' ? "session-type-option selected-option type-role" : "session-type-option type-role"}
                        onClick={props._custom}    
                    >
                        <div className="session-type-option-text">New Location</div>
                    </button>

                    {
                        props.state.locationType === "custom" ?
                        <div className="padding-bottom-200">
                            <div className="custom-location-section">
                                <div className="session-modal-sub-label">Please enter the address</div>
                                    <div className="manual-address-inputs">
                                        <div><input value={props.state.locName} name="locName" className="address-input-nav" placeholder="Location name" onChange={props._onChange.bind(this)} /></div>
                                        <div><input value={props.state.street} name="street" className="address-input-nav" placeholder="Street address" onChange={props._onChange.bind(this)} /></div>
                                        <div><input value={props.state.suite} name="suite" className="address-input-nav" placeholder="Suite or apt no." onChange={props._onChange.bind(this)} /></div>
                                        <div className="city-state-zip">
                                        <input placeholder="Los Angeles" value={props.state.city} name="city" onChange={props._onChange.bind(this)} />
                                        <input placeholder="CA" value={props.state.state} name="state" onChange={props._onChange.bind(this)} />
                                        <input placeholder="90025" value={props.state.zip} name="zip" onChange={props._onChange.bind(this)} />
                                    </div>
                                </div>
                            </div>
                            <div className="custom-location-section">
                                <div className="session-modal-sub-label">Are there any parking instructions?</div>
                                <textarea value={props.state.parking} name="parking" placeholder="Please provide parking instructions here..." className="parking-instructions" onChange={props._onChange.bind(this)}/> 
                            </div>
                        </div>
                        :
                        props.state.locationType === "office" ?
                        <div className="padding-bottom-200">
                        <div className="session-modal-label">What room in your office?</div>
                        {
                            props.state.roomOptions ? props.state.roomOptions.map((room, index) => (
                            <button 
                            className={props.state.sessionRoom ? props.state.sessionRoom.value === room.value ? "session-type-option selected-option type-role" : "session-type-option type-role" : "session-type-option type-role"}
                            onClick={props._selectRoom.bind(this, room)}    
                            key={room.value}
                            >
                                <div className="session-type-option-text">{room.label}</div>
                            </button>
                            ))
                            :
                            <div>No rooms created</div>
                        }
                        </div>  
                        :
                        <div />
                    }
                </div>
                :
                <div />
            }
            
        </div>
        <div className="nav-sess-buttons-container">
            <button className="nav-sess-btn" onClick={props._toSessionOpp}>Previous</button>
            {
                s.sessionDate && 
                s.hr1 && s.hr2 && s.min1 && s.min2 && s.ampm1 && s.ampm2 &&
                s.sessionType !== '' &&
                s.locationType !== '' ?
                <button className="nav-sess-btn" onClick={props._toSessionSchedule}>Next</button>
                :
                <button disabled className="nav-sess-btn disabled-nav-btn">Next</button>
            }
        </div>
    </div>

        
    )
}

export { DetailsSection };