import React from 'react';

import { NullUser } from '../../../../../assets/nulluser';

import userblank from '../../../../../assets/user-blank.png';

const OperationsSection = (props) => {

    var userIds = props.state.sessionUsers ? props.state.sessionUsers.map(user => user.id) : '';

    return (
        <div className="nav-section-container">
            <div className="nav-section-header-text">Operations Section</div>
            <div className="nav-section-section">
                <div className="session-modal-label">Who from your team will be in the session?</div>
                {
                    props.state.userOptions ?
                    props.state.userOptions.map((user, index) => (
                        <button 
                        key={user.value} 
                        className={userIds ? userIds.includes(user.value) ? "session-type-option selected-option" : "session-type-option" : "session-type-option"}
                        onClick={userIds ? userIds.includes(user.value) ? props._deselectUser.bind(this, user) : props._selectUser.bind(this, user) : props._selectUser.bind(this, user)}  
                        >
                         <div className="session-user-avatar-container">
                            <img src={user.avatar ? user.avatar : NullUser} className="session-user-avatar" alt="User Profile Avatar" />
                         </div>
                        <div className="session-type-option-text">{user.label}</div>
                        </button>
                    ))
                    :
                    <div>No employees listed</div>
                }
            </div>
            <div className="nav-sess-buttons-container">
                <button className="nav-sess-btn" onClick={props._toSessionSchedule}>Previous</button>
                <button className="nav-sess-btn" onClick={props._finishSession}>Complete</button>
            </div>
        </div>
    )
}

export { OperationsSection };