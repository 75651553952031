import React, { useState, useEffect } from 'react';
import ArrivalCard from '../../Common/ArrivalCard';
import gear from '../../../assets/nav/gear.png';

export default function DashArrival(props) {

    return (
        <div className="nav-section">
            <div className="acct-settings-back-btn" onClick={() => props.setTab('')}>Back to Dashboard</div>
            <div className="menu-header nav-text-color">Current Check-Ins</div>
            {
                props.arrivals ? 
                <div className="nav-arrivals-padding">
                {
                props.arrivals.map((item, index) => (
                    <ArrivalComponent item={item} key={item.id} sub={item.interview.name} aff={item.interview.aff} firebase={props.firebase} cid={props.cid} />
                ))
                }
                </div>
                :
                null
            }
        </div>
    )
}

function ArrivalComponent(props) {
    const [settings, setSettings] = useState(false);
    
    function removeFunction() {
        props.firebase.apiCompanyGuest(props.cid, props.item.id)
        .remove();
    };

    return (
        <div className="relative">
            <img className="arrival-card-icon" src={gear} alt="Actor Settings" onClick={() => setSettings(!settings)} />
            <ArrivalCard 
                key={props.item.id} 
                functionText="Delete Check-In"
                removeFunction={removeFunction} name={props.item.name} sub={props.item.interview.name} aff={props.item.interview.aff} settings={settings} />
        </div>
    )
};