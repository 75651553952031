import React, {useState, useEffect} from 'react';

import moment from 'moment';

import TalentTags from './TalentTags';
import TalentNotes from './TalentNotes';
import TakesSection from './TakesSection';
import GroupTags from './GroupSession/GroupTags/GroupTags';
import GroupNotes from './GroupSession/GroupNotes/GroupNotes';
import CustomMarker from './GroupSession/CustomMarker/CustomMarker';
import CustomMarkerAdd from './GroupSession/CustomMarker/CustomMarkerAdd';
import FocusTalent from './ActiveTalentCard/FocusTalent';
import UpNext from './ActiveTalentCard/UpNext';
import SettingsContainer from './ActiveTalentCard/Settings/SettingsContainer';

import { NullUser } from '../../assets/nulluser';
import quickpencil from '../../assets/quickpencil.png';

import { Switch } from '../Common/CustomSwitch';


import './room.css';
import '../transitions.css';
import '../mobile.css';


const ActiveTalentCard = (props) => {

    var timer;
    var modalTimer = null;

    const [visible, setVisible] = useState(true);
    const [helpers, setHelpers] = useState(true);
    const [countOff, setCountOff] = useState('');
    const [testMode, setTestMode] = useState(true);
    const [active, setActive] = useState('');
    const [checkIns, setCheckIns] = useState('');
    const [checkInsLength, setCheckInsLength] = useState('');
    const [waiting, setWaiting] = useState(false);
    const [topFadeIn, setTopFadeIn] = useState(false);
    const [itemToAppear, setItemToAppear] = useState('');
    const [animationStop, setAnimationStop] = useState(true);
    const [fadeAddNote, setFadeAddNote] = useState(true);
    const [showQueue, setShowQueue] = useState(true);
    const [hideWhileActive, setHideWhileActive] = useState(false);
    const [settingsOn, setSettingsOn] = useState(true);
    const [singleController, setSingleController] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(true);
    const [thisActiveCheckIn, setActiveCheckIn] = useState('');
    const [mobileAddNote, setMobileAddNote] = useState(false);
    const [schedule, setSchedule] = useState('');

    /*useEffect(() => {
        setVisible(true);

        if (props.countOff !== '') {
            setCountOff(props.countOff)
        };

    }, []);

    */

    const _refreshFocus = () => {
        setAnimationStop(true);
        setTopFadeIn(false);
        setItemToAppear('');
        setFadeAddNote(true);
    }

    const removeCard = () => {
        props.firebase.selectCastingSessionTalent(props.uid, props.sessionId, itemToAppear.id).off();
        props._complete();
        _refreshFocus();
    }
    
    const _setCountOff = () => {
        if (countOff === false) {
            props.firebase.sessionInfo(props.activeSession)
            .update({
                countOff: true,
            });
            setCountOff(true);
            
        } else {
            props.firebase.sessionInfo(props.activeSession)
            .update({
                countOff: false,
            })
            setCountOff(false);
          
        }
    }

    const prepTalentToAssign = (item) => {
        setTopFadeIn(true);
        setAnimationStop(false);
        setItemToAppear(item);
    }

    const prepSendBack = () => {
        setAnimationStop(true);
        setTopFadeIn(false);
        setItemToAppear('');
        setFadeAddNote(true);
        props._sendTalentBackToList();

        var thisTalent = schedule ? schedule.filter(fil => {
            return fil.tempId === props.talentId
        }) : '';

        props.firebase.sessionTalentSelect(props.activeSession, props.talentId).remove();

        if (thisTalent) {
            props.firebase.checkInSessionUser(props.activeSession, thisTalent[0].id)
            .update({
                status: 'checking in',
            });

            props.firebase.checkInCastingUser(props.uid, props.sessionId, thisTalent[0].id)
            .update({
                status: 'checking in',
            })
        }
    }

    const scheduleReferenceList = (schedule) => {
        setSchedule(schedule);
    }

    const prepAssign = () => {
        setAnimationStop(true);
        setFadeAddNote(false);
        var tal = itemToAppear;
        var roomName = props.roomName;
        var uid = props.uid;
        var name = tal.checkInName;
        var project = tal.checkInProject;
        var role = tal.checkInRole;
        var rep = tal.checkInRepCompany;
        var roomId = props.roomId;
        var time = tal.checkInTime;
        var day = tal.checkInDay;
        var callIn = moment().format('hh:mma');
        var projectId = tal.projectId;
        var roleId = tal.roleId;
        var accountId = tal.accountId;
        var waitLength = moment(callIn, "hh:mma").diff(moment(time, "hh:mma"));
        var waitTIme = (waitLength / 1000) / 60;

        props.firebase.inSession(name, project, role, rep, roomName, tal.id, time, day, callIn, projectId, roleId, waitLength, accountId, props.sessionMode, props.sessionId);

        props.firebase.removeCheckInQ(tal.id);
        props.firebase.userCheckinSelect(accountId, tal.id).remove();

        props.firebase.assignRoom(roomId)
        .update({
            activeCheckIn: true,
            checkInId: tal.id,
            checkInName: name,
            checkInProject: project,
            checkInRole: role,
            timeCalled: callIn,
            checkInRepCompany: rep,
        });

        props.firebase.assignUserRoom(props.uid, roomId)
        .update({
            activeCheckIn: true,
            checkInId: tal.id,
            checkInName: name,
            checkInProject: project,
            checkInRole: role,
            timeCalled: callIn,
            checkInRepCompany: rep,
        });

        const message = ("Please send " + name + " to " + roomName);

        // IF EXTERNAL SESSION
        if (props.activeGroup === true) {
            props.firebase.addSubjectToSessionTalent(accountId, tal.id, name, project, role, projectId, roleId, rep, props.activeSession);
        };

        // IF CASTING SESSION
        if (props.sessionMode) {
            props.firebase.addSubjectToSession(props.uid, props.sessionId, tal.id, name, rep, callIn, roleId, role, time);
        };

        props.firebase.createAlert(props.uid, roomId, roomName, name, message, callIn, tal.id);

        props.firebase.sendToReception(props.uid, name, roomName);
        if (props.receptionEmail) {
            props.firebase.sendEmailToReception(name, roomName, props.receptionEmail);
        };

        props.updateStateWithTalent({
            checkInId: tal.id,
            checkInName: name,
            checkInProject: project,
            checkInProjectId: projectId,
            checkInRoleId: roleId,
            checkInRole: role,
            activeCheckIn: true,
            projectId: projectId,
            roleId: roleId,
            checkInRepCompany: rep,
            checkInTime: time,
            checkInTimeDay: day,
        })
    }

    const _showQueue = () => {
        showQueue(true);
    };

    useEffect(() => {
        if (props.settingsOpen && !settingsOpen) {
            setSettingsOpen(true)
        } else if (!props.settingsOpen && settingsOpen) {
            setSettingsOpen(false);
        }
    }, [props.settingsOpen])

    useEffect(() => {
        if (props.checkIns && props.checkIns.length > 0 && props.checkIns !== checkIns) {
            setCheckInsLength(props.checkIns.length)
            setCheckIns(props.checkIns);
        } else {
            setCheckIns('');
        }
    }, [props.checkIns]);


    /*
<div className="rp-active-talent-tags-container">
    <div className="note-label view-2">Internal Tags</div>
    {
        !helpers ?
        <div className="group-focus-description">
            Internal Tags are only visible to internal users, and do not effect the shared tags.
            By default, the pin bar will show your top 8 most common tags, but you can adjust the list if your tags section on the quick bar.
        </div>
        :
        <div />
    }
    <TalentTags 
        sessionTags={props.sessionTags} 
        activeGroup={props.activeGroup} 
        activeSession={props.activeSession} 
        firebase={props.firebase} 
        uid={props.uid} 
        talentId={props.talentId} 
        talentName={props.name} 
        projectId={props.projectId} 
        projectName={props.projectName}
        roleId={props.roleId} 
        roleName={props.roleName}
        activeViewStyle={activeViewStyle}
        roomId={props.roomId}
        sessionMode={props.sessionMode}
        sessionId={props.sessionId}
        groupRef={props.groupRef}
        sessUserName={props.sessUserName}
    />
</div>
    */

    if (props.sessionData && props.sessionData.customMarker) {
        var marker = props.sessionData.customMarker;
        var show = true;
    };

    useEffect(() => {
        if (props.activeCheckIn && !active) {
            setActive(true);
            if (props.activeCheckIn !== thisActiveCheckIn) {
                setActiveCheckIn(props.activeCheckIn ? props.activeCheckIn : '');
            };
            if (fadeAddNote) {
                setFadeAddNote(false);
            }
        } else {
            if (!props.activeCheckIn && active) {
                setActive(false);
            }
            if (!props.activeCheckIn && thisActiveCheckIn) {
                setActiveCheckIn('');
            }
        }
    }, [props.activeCheckIn]);
    

    var activeViewStyle = 2;

    return (
        <div id="active-card-relative-mobile">
            {
                settingsOpen ?
                <div className="settings-container-absolute">
                <SettingsContainer 
                    helpers={helpers}
                    showQueue={showQueue}
                    setShowQueue={setShowQueue}
                    hideWhileActive={hideWhileActive}
                    setHideWhileActive={setHideWhileActive}
                    singleController={singleController}
                    setSingleController={setSingleController}
                    controller={props.controller}
                />
                </div>
                :
                <div />
            }
           
            <div className={props.sidebar ? "rp-active-talent-card-2" : "rp-active-talent-card-3"}>
                <div id={
                    props.mobileState === 'checkins' ? 
                    "mobile-state-show" : 
                    props.mobileState === 'complete' ?
                    "mobile-state-hide" :
                    "mobile-state-show"}
                >
                <div className="focus-talent-layout">
                    <FocusTalent 
                        visible={visible}
                        activeCheckIn={props.activeCheckIn}
                        NullUser={NullUser}
                        name={props.name}
                        rep={props.rep}
                        project={props.project}
                        role={props.role}
                        removeCard={removeCard}
                        _sendTalentBackToList={prepSendBack}
                        waiting={waiting}
                        assignFromSessionClick={props.assignFromSessionClick}
                        checkIns={props.checkIns}
                        topFadeIn={topFadeIn}
                        itemToAppear={itemToAppear}
                        prepAssign={prepAssign}
                        setFadeAddNote={setFadeAddNote}
                        setMobileAddNote={setMobileAddNote}
                        
                    />

                    {
                    thisActiveCheckIn !== '' ?
                    <img 
                        src={quickpencil}
                        alt="Add Note"
                        className={mobileAddNote ? "hide-div" : "sly-note-add-note-mobile-btn"} 
                        onClick={() => setMobileAddNote(true)}
                    />
                    :
                    null
                    }
                    <div className={
                        !showQueue && thisActiveCheckIn ? 
                        "hide-div fadeOutHeader" :
                        "show-queue fadeInHeader"
                    }>
                    
                    <UpNext
                        uid={props.uid} 
                        firebase={props.firebase} 
                        checkIns={checkIns} 
                        NullUser={NullUser} 
                        prepTalentToAssign={prepTalentToAssign}
                        roleArray={props.roleArray}
                        castingSession={props.sessionId}
                        groupSession={props.activeSession}
                        activeCheckIn={props.activeCheckIn}
                        scheduleReferenceList={scheduleReferenceList}
                    />
                    </div>
                    </div>
                    {/*
                    <div className="complete-button-container-2">
                    {
                        props.activeCheckIn ?
                        <div>
                            <div className="note-label view-2">Default Markers</div>
                            {show && marker ?
                                <CustomMarker  
                                    firebase={props.firebase}
                                    uid={props.uid}
                                    sessionId={props.sessionId}
                                    sessionMode={props.sessionMode}
                                    activeSession={props.activeSession}
                                    markers={props.sessionData.markers} 
                                    talentId={props.talentId}
                                    sessUserName={props.sessUserName}
                                />
                            :
                                <CustomMarkerAdd 
                                    firebase={props.firebase}
                                    uid={props.uid}
                                    sessionId={props.sessionId}
                                    sessionMode={props.sessionMode}
                                    activeSession={props.activeSession} 
                                />
                            }
                        </div>
                        :
                        <div />
                    }
                    </div>
                    */}
                
                    {/*
                    {
                        props.activeGroup && props.activeSession ?
                        <div className="rp-active-talent-tags-container">
                           
                            <div className="note-label view-2">Shared Tags</div>
                            { 
                                !helpers ?
                                <div className="group-focus-description">
                                    Shared tags are used to tally votes from each person attending the session. If there are specific answers or opinions you need to know, this is an easy way to set it up. You can choose to hide or show the vote count during the session.
                                </div>
                                :
                                <div />
                            }
                            
                            <Switch 
                                boolean={countOff}
                                toggleFunction={_setCountOff}
                                disabled={false}
                                text="Hide count"
                            />
                            <br />
                            <GroupTags
                                uid={props.uid}
                                sessionTags={props.sessionTags}
                                activeGroup={props.activeGroup} 
                                activeSession={props.activeSession} 
                                talentId={props.talentId}
                                projectId={props.projectId}
                                roleId={props.roleId}
                                firebase={props.firebase} 
                                _deselectPublicTag={props._deselectPublicTag}
                                _selectPublicTag={props._selectPublicTag}
                                groupRef={props.groupRef}
                                sessionId={props.sessionId}
                                sessionMode={props.sessionMode}
                                talentName={props.name} 
                                countShow={props.countOff}

                            />
                        </div>
                        :
                        <div />
                        }
                    */}
                </div>
               {
                testMode ? 
                <div className="left-border">
                
                <div className="rp-active-talent-tags-container sly-pad">
                    <GroupNotes
                        activeGroup={props.activeGroup} 
                        activeSession={props.activeSession} 
                        sessionId={props.sessionId}
                        sessionMode={props.sessionMode}
                        firebase={props.firebase} 
                        sessionTags={props.sessionTags} 
                        uid={props.uid} 
                        talentId={props.talentId} 
                        talentName={props.name} 
                        projectId={props.projectId} 
                        projectName={props.project}
                        roleId={props.roleId} 
                        roleName={props.role}
                        activeViewStyle={activeViewStyle}
                        roomId={props.roomId}
                        groupRef={props.groupRef}
                        sessUserName={props.sessUserName} 
                        sessUserId={props.sessUserId}
                        allSessionNotes={props.allSessionNotes}
                        fadeAddNote={fadeAddNote}
                        hideWhileActive={hideWhileActive}
                        mobileAddNote={mobileAddNote}
                        setMobileAddNote={setMobileAddNote}
                        mobileState={props.mobileState}
                        _openTalentModal={props._openTalentModal}
                    />
                </div>
                </div>
                    :
                
                    
                    props.activeSession ?
                    <div>
                    <div className="rp-active-talent-tags-container">
                    <div className="note-label view-2">Internal Tags</div>
                    {
                    !helpers ?
                    <div className="group-focus-description">
                        Internal Tags are only visible to internal users, and do not effect the shared tags.
                        By default, the pin bar will show your top 8 most common tags, but you can adjust the list if your tags section on the quick bar.
                    </div>
                    :
                    <div />
                        }
                        {
                        !props.activeCheckIn ?
                        <div />
                        :
                        
                        <TalentTags 
                            sessionTags={props.sessionTags} 
                            activeGroup={props.activeGroup} 
                            activeSession={props.activeSession} 
                            firebase={props.firebase} 
                            uid={props.uid} 
                            talentId={props.talentId} 
                            talentName={props.name} 
                            projectId={props.projectId} 
                            projectName={props.projectName}
                            roleId={props.roleId} 
                            roleName={props.roleName}
                            activeViewStyle={activeViewStyle}
                            roomId={props.roomId}
                            sessionMode={props.sessionMode}
                            sessionId={props.sessionId}
                            groupRef={props.groupRef}
                            sessUserName={props.sessUserName}
                        />
                        }
                        </div>
                            <div className="rp-active-talent-tags-container">
                                <div className="note-label view-2">Notepad</div>
                                { 
                                    !helpers ?
                                    <div className="group-focus-description">
                                        When guest accounts enter a session, privacy settings get added to each note. By default, your note will be private and only visible internally.
                                        To make the note visible to all users in the session, press the eye icon on the right side of one of your notes.
                                    </div>
                                    :
                                    <div />
                                }
                                <GroupNotes
                                    activeGroup={props.activeGroup} 
                                    activeSession={props.activeSession} 
                                    sessionId={props.sessionId}
                                    sessionMode={props.sessionMode}
                                    firebase={props.firebase} 
                                    sessionTags={props.sessionTags} 
                                    uid={props.uid} 
                                    talentId={props.talentId} 
                                    talentName={props.name} 
                                    projectId={props.projectId} 
                                    projectName={props.project}
                                    roleId={props.roleId} 
                                    roleName={props.role}
                                    activeViewStyle={activeViewStyle}
                                    roomId={props.roomId}
                                    groupRef={props.groupRef}
                                    sessUserName={props.sessUserName} 
                                />
                            </div>
                        </div>
                        :
                        <div>
                        <div className="rp-active-talent-tags-container">
                            <div className="note-label view-2">Quick Tags</div>
                            <TalentTags 
                                sessionTags={props.sessionTags} 
                                activeGroup={props.activeGroup} 
                                activeSession={props.activeSession} 
                                firebase={props.firebase} 
                                uid={props.uid} 
                                talentId={props.talentId} 
                                talentName={props.name} 
                                projectId={props.projectId} 
                                roleId={props.roleId} 
                                activeViewStyle={activeViewStyle}
                                roomId={props.roomId}
                                sessionMode={props.sessionMode}
                                sessionId={props.sessionId}
                            />
                        </div>
                        <div className="rp-active-talent-notes-container">
                            <div className="note-label">Notes</div>
                            <TalentNotes 
                                note={props.note} 
                                notes={props.notes}
                                _onNoteChange={props._onNoteChange} 
                                _addNote={props._addNote}
                                _onEnterNote={props._onEnterNote}
                                _removeNote={props._removeNote}
                                _addSessionNote={props._addSessionNote}
                                activeGroup={props.activeGroup}
                                activeViewStyle={activeViewStyle}
                                
                            />
                        </div>
                        </div>
                }
            </div>        
        </div>
    )
}

export { ActiveTalentCard };


/*
<TakesSection 
    firebase={props.firebase}
    takes={props.takes}
    _addTake={props._addTake}
    _stopTake={props._stopTake}
    _favoriteTake={props._favoriteTake}
    _addNoteToTake={props._addNoteToTake}
    count={props.count}
    countActive={props.countActive}
    _confirmCount={props._confirmCount}
    _addMark={props._addMark}
/>
*/