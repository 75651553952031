import React, { Component } from 'react';

import { withAuthorization } from '../Session';

class AdminPage extends Component {
 

  render() {

    return (
      <div>
      </div>
    );
  }
}


const condition = authUser => !!authUser;

export default withAuthorization(condition)(AdminPage);
