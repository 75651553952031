import React from 'react';

const CustomDropdown = (props) => {

    var filtered = props.list.filter(f => {
        if (props.searchFilter !== '') {
        return f.label.includes(props.searchFilter) 
        } else {
            return props.list;
        }
    });

    return (
    <div className="custom-dropdown" onMouseLeave={props.onMouseLeave}>
        <div className="dropdown-message-container">
            <input className="dropdown-message" autoComplete='off' name={props.inputName} value={props.searchFilter} placeholder={props.dropdownMessage} onChange={props._onChangeSearch.bind(this)}/>
        </div>
        {
            filtered.map((item, index) => {
                if (props.selectedItems.includes(item.value)) {
                    return (
                        <div key={index} className="custom-dropdown-item-select" onClick={props.deselectFunction.bind(this, item)}>{item.label}</div>
                    )
                } else {
                    return (
                        <div key={index} className="custom-dropdown-item" onClick={props.selectFunction.bind(this, item)}>{item.label}</div>
                    )
                }
            })
        }
    </div>
    )
}

export { CustomDropdown };