import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import SignUp from './SignUp';
import logo from '../../assets/logos/check-in-logo.png';
import formColor from '../../assets/formColor.png';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import '../CheckIn/checkin.css';

const INITIAL_STATE = {
  username: '',
  company: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isAdmin: null,
  error: null,
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE};
  }
  
  onSubmit = event => {
    const { username, email, passwordOne, company } = this.state;

    event.preventDefault();

    this.props.firebase 
    .doCreateUserWithEmailAndPassword(email, passwordOne)
    .then(authUser => {
    // Create a user in your Firebase realtime database
      var companyId = this.props.firebase.apiCompanyKey(); 
        console.log(companyId);
        console.log(authUser.user.uid);

        this.props.firebase.newCompany(authUser.user.uid, username, company, email, companyId);

        return this.props.firebase
          .apiUser(authUser.user.uid)
          .update({
            name: username,
            email: email,
            company: companyId,
            companyName: company,
            emailNotifications: false,
            permission: 'admin',
            lastUpdate: 0,
          });
        })
        .then(() => {
          //this.props.firebase.newAccountCreate(email, username);
          this.setState({ ...INITIAL_STATE });
          
          this.props.history.push(ROUTES.HOME);


        })
        .catch(error => {
          console.log(error);
        });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
      company,
    } = this.state;

    const isInvalid = 
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

    const inputs = [
      {name: 'username', value: username, onChange: this.onChange, label: 'Full Name', type: "text"},
      {name: 'company', value: company, onChange: this.onChange, label: 'Company', type: "text"},
      {name: 'email', value: email, onChange: this.onChange, label: 'Email Address', type: "email"},
      {name: 'passwordOne', value: passwordOne, onChange: this.onChange, label: 'Password', type: "password"},
      {name: 'passwordTwo', value: passwordTwo, onChange: this.onChange, label: 'Confirm Password', type: "password"}
    ]

    return (
      /*<SignUp firebase={this.props.firebase} history={this.props.history} />*/
      <div className="check-in-layout">
        <div className="sign-in-card">
        <div className="header" id="register-header">
          </div>

          <div className="check-in-form">
          <div className="label">Register your company here</div>
          <form onSubmit={this.onSubmit}>
              {
                inputs.map(i => (
                  <Input 
                    name={i.name}
                    value={i.value}
                    onChange={i.onChange}
                    type={i.type}
                    label={i.label}
                  />
                ))
              }

              <div id="one-btn" className="sign-in-btns">
              <button type="submit" disabled={isInvalid} className="sign-in-btn" id="register">Create My Account</button>
              </div>
              {error && <p>{error.message}</p>}
          </form>

          </div>
          <img id="register-logo" className="ani-logo-sign-in" src={logo} alt="ANi Logo"/>
          
        </div>
      </div>
    );
  }
}

const Input = (props) => (
  <div className="sign-in-input-box relative">
    <p className={
      props.value.length > 0 ? "top-left-shift" : ''
    }>{props.label}</p>
    <input 
      className="sign-in-input"
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      type={props.type}
    />
  </div>
)

export { Input };

const style={
  input: {
      width: 330,
      height: 50,
      marginTop: 5,
  },
};


const SignUpLink = () => (
  <div style={{textAlign: 'center', marginTop: 10}}>
    <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </div>
);

const SignUpForm = compose(
    withRouter,
    withFirebase,
    )(SignUpFormBase);

export default SignUpForm;

export { SignUpForm, SignUpLink };
