import React from 'react';
import { 
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import NewCastingSessionPage from '../NewCasting';
import CheckInPage from '../CheckIn';
import CheckInForm from '../CheckIn2/Form';
import AddRolesPage from '../AddRoles';
import RoomPage from '../Room';
import LockScreen from '../Security/LockScreen';
import AdvancedSearch from '../Search/Search';
import GuestRegisterForm from '../GroupSessions/GuestRegister';
import SessionGuest from '../SessionView/Guests/SessionGuest';
import TempDashboard from '../TempDashboard/TempDashboard';
import TalentSignUp from '../TalentPortal/TalentSignUp/TalentSignUp';
import TalentDashboard from '../TalentPortal/TalentDashboard/TalentDashboard';
import ApptInvite from '../ApptInvite/InviteLink';
import SessionOverviewSignIn from '../SessionOverview/Access/SessionOverviewSignIn';
import SessionOverviewTalent from '../SessionOverview/SubjectBoard/TalentOverview';
import TestPage from '../ANiNote/TestPage';
import SessionView from '../SessionView/SessionView';
import Dashboard from '../Dashboard/Dashboard';
import CompanyVerification from '../Verification/CompanyVerification';
import InviteSignUp from '../Verification/InviteSignUp';
import PortalCheckIn from '../ApptInvite/PortalCheckIn';
import MobileForm from '../CheckIn-Mobile/MobileForm';

import '../colors.css';
import '../themes.css';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

const App = () => (
      <div className="app-layout">
      <Router>
            <Switch>
              <Route exact path='/check-in' component={CheckInContainer} />
              <Route component={NavContainer} />
              <Route component={SessionGuestContainer} />
            </Switch>
      </Router>
      </div>
);

const NavContainer = () => (
  <div className="app-layout">
    <Navigation />
    <Route path={ROUTES.LANDING} component={LandingPage} />
    <Route path={ROUTES.SIGN_IN} component={SignInPage} />
    <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
    <Route path={ROUTES.TALENT_SIGN_UP} component={TalentSignUp} />
    <Route exact path={'/'} component={Dashboard} />
    <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
    <Route path={ROUTES.ACCOUNT} component={AccountPage} />
    <Route path={ROUTES.ADMIN} component={AdminPage} />
    <Route path={ROUTES.NEW_CASTING} component={NewCastingSessionPage} />
    <Route path={ROUTES.ADD_ROLES} component={AddRolesPage} />
    <Route path={ROUTES.ROOM} component={RoomPage} />
    <Route path={ROUTES.SEARCH} component={AdvancedSearch} />
    <Route path={ROUTES.TEMP_DASHBOARD} component={TempDashboard} />
    <Route path={ROUTES.GUEST_REGISTER} component={GuestRegisterForm} />
    <Route path={ROUTES.SESSION_GUEST} component={SessionGuest} />
    <Route path={ROUTES.TALENT_DASHBOARD} component={TalentDashboard} />
    <Route path={ROUTES.APPT_INVITE} component={ApptInvite} />
    <Route path={ROUTES.SESSION} component={SessionView} />
    <Route path={ROUTES.SESSION_OVERVIEW_GUEST_SIGN_IN} component={SessionOverviewSignIn} />
    <Route path={ROUTES.SESSION_OVERVIEW_TALENT} component={SessionOverviewTalent} />
    <Route path={ROUTES.VERIFY_CO} component={CompanyVerification} />
    <Route path={ROUTES.VERIFY_INVITE} component={InviteSignUp} />
    <Route path={ROUTES.PORTAL_CHECKIN} component={PortalCheckIn} />
    <Route path={ROUTES.MOBILE_CHECKIN} component={MobileForm} />
  </div>
)



const CheckInContainer = () => (
  <div>
    <Route path={ROUTES.CHECK_IN} component={CheckInForm} />
  </div>
)

const SessionGuestContainer = () => (
  <div>
    <Route path={ROUTES.SESSION_GUEST} component={SessionGuest} />
  </div>
)

export default withAuthentication(App);