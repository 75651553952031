import React from 'react';

import { ActiveTalentCard } from './ActiveTalentCard';

import imhere from '../../assets/working-with-laptop.png';
import imnot from '../../assets/lazy.png';


const RoomTalent = (props) => {

    return (

    <div className="rp-active-talent">
        <div className="rp-active-talent-bg">
        {
            props.activeSession ?
            <ActiveTalentCard 
                _openTalentModal={props._openSessTalentModal}
                mobileState={props.mobileState}
                roleArray={props.roleArray}
                checkIns={props.checkIns}
                sessionNotesLoaded={props.sessionNotesLoaded}
                allSessionNotes={props._allSessionNotes}
                _sendTalentBackToList={props._sendTalentBackToList}
                firebase={props.firebase}
                uid={props.uid}
                rep={props.rep}
                talentId={props.checkInId}
                note={props.note} 
                notes={props.notes}
                name={props.checkInName} 
                project={props.checkInProject} 
                role={props.checkInRole} 
                activeSession={props.activeSession}
                activeGroup={props.activeGroup}
                sessionTags={props.sessionTags}
                projectId={props.projectId}
                roleId={props.roleId}
                _onNoteChange={props._onNoteChange} 
                _addNote={props._addNote}
                _onEnterNote={props._onEnterNote}
                _removeNote={props._removeNote}
                _addSessionNote={props._addSessionNote}
                _complete={props._prepCountToComplete} 
                sidebar={props.sidebar}
                roomId={props.roomId}
                roomName={props.roomName}
                sessionId={props.sessionId}
                sessionMode={props.sessionMode}
                _addTake={props._addTake}
                _stopTake={props._stopTake}
                takes={props.takes}
                _favoriteTake={props._favoriteTake}
                _addNoteToTake={props._addNoteToTake}
                count={props.count}
                countActive={props.countActive}
                _confirmCount={props._confirmCount}
                _addMark={props._addMark}
                groupRef={props.groupRef}
                sessUserName={props.sessUserName}
                sessUserId={props.sessUserId}
                countOff={props.countOff}
                assignFromSessionClick={props.assignFromSessionClick}
                updateStateWithTalent={props.updateStateWithTalent}
                activeCheckIn={props.activeCheckIn}
                sessionData={props.sessionData}
                attendees={props.attendees}
                _snoozeReader={props._snoozeReader}
                _unsnoozeReader={props._unsnoozeReader}
                controller={props.controller}
                settingsOpen={props.settingsOpen}

            />
            :
            !props.activeSession && props.activeCheckIn === false ?
                <div className="rp-current-available-container">
                    
                    <div className="rp-current-available">Room Open</div>
                    {
                        props.okay === false ?
                        <div className="rp-current-available-description">There are no actors that match this room</div>
                        :
                        <div>
                            <div className="rp-current-available-description">Click 'Send Next' to assign the next actor in queue</div>
                            <div className="rp-current-available-btn">
                                <button className="rp-assign-btn" onClick={props.assignFromSessionClick}>Send Next</button>
                            </div>
                        </div>
                    }
                    <div className="user-alertness">
                    {   
                    props.attendees ?
                        <div>
                        <div className="rp-current-available">Active Users</div>
                        {props.attendees.map((item, index) => (
                            <div key={item.rrid} className={item.isAway ? "room-reader-snooze-item" : "room-reader-snooze-item im-alert"} onClick={item.isAway ? props._unsnoozeReader.bind(this, item) : props._snoozeReader.bind(this, item)}>
                                <div className="status-icon-container"><img src={item.isAway ? imnot : imhere } className="status-icon" alt="Status"  /></div>
                                <div className="room-reader-snooze-name">{item.name}</div>
                                <div 
                                    className={item.isAway ? "snoozed-room-reader" : "active-room-reader"}>
                                </div>
                            </div>
                        ))}
                        </div>
                        :
                        <div className="rp-current-available-description margin-top-50">No users active</div>
                    }

                    {props.attendees ? <div className="rp-current-available-description-wide">Set your status to away if you have time between sessions to receive notifications when actors check in!</div> : <div />}
                    </div>
                </div>
                :
                <ActiveTalentCard 
                    _sendTalentBackToList={props._sendTalentBackToList}
                    firebase={props.firebase}
                    uid={props.uid}
                    talentId={props.checkInId}
                    note={props.note} 
                    notes={props.notes}
                    name={props.checkInName} 
                    project={props.checkInProject} 
                    role={props.checkInRole} 
                    rep={props.rep}
                    activeSession={props.activeSession}
                    activeGroup={props.activeGroup}
                    sessionTags={props.sessionTags}
                    projectId={props.projectId}
                    roleId={props.roleId}
                    _onNoteChange={props._onNoteChange} 
                    _addNote={props._addNote}
                    _onEnterNote={props._onEnterNote}
                    _removeNote={props._removeNote}
                    _addSessionNote={props._addSessionNote}
                    _complete={props._prepCountToComplete} 
                    sidebar={props.sidebar}
                    roomId={props.roomId}
                    sessionId={props.sessionId}
                    sessionMode={props.sessionMode}
                    _addTake={props._addTake}
                    _stopTake={props._stopTake}
                    takes={props.takes}
                    _favoriteTake={props._favoriteTake}
                    _addNoteToTake={props._addNoteToTake}
                    count={props.count}
                    countActive={props.countActive}
                    _confirmCount={props._confirmCount}
                    _addMark={props._addMark}
                    groupRef={props.groupRef}
                    sessUserName={props.sessUserName}
                    countOff={props.countOff}
                    activeCheckIn={props.activeCheckIn}
                    allSessionNotes={props.allSessionNotes}
                    
                />
            }
        </div>
    </div>
    )
}

export { RoomTalent };