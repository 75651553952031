import React from 'react';

import { NullUser } from '../../../../../assets/nulluser';

export default function Attendees(props) {

    var intObj = props.internals ? props.internals : '';
    var int = intObj ? Object.keys(intObj).map(key => ({
        ...intObj[key],
        id: key,
    })) : '';
    
    var extObj = props.externals ? props.externals : '';
    var ext = extObj ? Object.keys(extObj).map(key => ({
        ...extObj[key],
        id: key
    })) : '';

    return (
        <div className="attendees-container">

            <div className="internal-attendees">
                <div className="session-mode-label">Internal</div>
                {
                    int ? 
                    int.map((i, index) => (
                        <div key={index} className="session-mode-user-container">
                            <img className="session-mode-user-avatar"
                                src={NullUser}
                                alt="Employee Headshot"
                            />
                            <div className="session-mode-user-name">
                                {i.name}
                            </div>
                        </div>
                    )) : <div className="null-text">No internals</div>
                }
            </div>

            <div className="external-attendees">
                <div className="session-mode-label">Guests</div>
                {
                    ext ? 
                    ext.map((e, index) => (
                        <div key={index} className="session-mode-user-container">
                            <img className="session-mode-user-avatar"
                                src={NullUser}
                                alt="Employee Headshot"
                            />
                            <div>
                            <div className="session-mode-user-name">
                                {e.name}
                            </div>
                            <div className="session-mode-user-email">
                                {e.email}
                            </div>
                            <div className="remove-guest">Remove</div>
                            </div>
                        </div>
                    )) : <div className="null-text">No guests</div>
                }
            </div>

        </div>
    )
}
