import React, { useState, useEffect } from 'react';

import noteblank from '../../assets/light-note-placeholder.png';
import addnote from '../../assets/addnotebtn.png';
import addtag from '../../assets/circletag.png';
import cancel from '../../assets/remove.png';
import { NullUser } from '../../assets/nulluser';
import addplus from '../../assets/plus-button.png';
import pencil from '../../assets/pencil.png';

export default function SessionFocusNotes(props) {

    const [newNote, setNewNote] = useState('');
    const [addNote, setAddNote] = useState(false);
    const [addTag, setAddTag] = useState(false);

    var mobile = props.mobile;

    useEffect(() => {
        if ((addNote && mobile) || (addTag && mobile)) {
            props.setCondenseHeader(true);
        } else {
            props.setCondenseHeader(false);
        }
    }, [addNote, addTag]);

    function saveNote() {
        var note = newNote;
        var pck = {
            username: props.username,
            user: props.uid,
            note: note,
            privacy: 'team',
            session: props.id,
            cid: props.cid,
            sid: props.e.id,
        }
        props.firebase.apiNewSessionNote(pck);
        setNewNote('');
        setAddNote(false);
    }

    function widgetClick(type) {
        return (
        type === 'note' ?
            addNote ?
            setAddNote(false) : 
                addTag ?
                (setAddTag(false), setAddNote(true))
                : setAddNote(true)
            :
            addTag ?
            setAddTag(false) :
            addNote ?
                (setAddNote(false), setAddTag(true)) :
                setAddTag(true)
        )
    }

    const [tagsList, setTagsList] = useState('');
    const [tagIds, setTagIds] = useState('');

    useEffect(() => {
        if (props.e.tags) {
            var list = ObjectList(props.e.tags);
            var listIds = list.map(i => i.id);
            setTagsList(list)
            setTagIds(listIds);
        } else {
            setTagsList('');
            setTagIds('');
        }
    }, [props.e.tags]);

    function clickTag(tag) {
        var pck = {
            username: props.username,
            user: props.uid,
            tag: tag,
            privacy: 'team',
            session: props.id,
            cid: props.cid,
            sid: props.e.id,
        }
        tagIds.includes(tag.id) ? 
        props.firebase.apiRemoveSubjectTag(pck) :
        props.firebase.apiNewSessionTag(pck, '') 
    }

    const [addOpen, setAddOpen] = useState(false);

    useEffect(() => {
        if (addNote && props.mobile) {
            var height = window.innerHeight;
            var notebox = height - 220;
            document.documentElement.style.setProperty('--height-foc-mob-scroller', notebox + 'px');
        }
    }, [addNote]);

    return (
        <div className={"focus-note-container"}>
            <div className="focus-note-bg">
            {
            props.mobile ? 
                <div id={"full-height"}> 
                {
                    addNote ?
                    <NewNote 
                        mobile={mobile} 
                        setAuto={props.prepAuto} 
                        setAddNote={setAddNote} 
                        newNote={newNote} 
                        setNewNote={setNewNote} 
                        saveNote={saveNote} 
                    /> 
                    : 
                    null 
                }
                {
                    addTag ?
                    <div>
                        <button className='done-tag' onClick={() => setAddTag(false)}>Done</button>
                        <div className="height-15" />
                        <NewTag 
                            setAuto={props.prepAuto} 
                            clickTag={clickTag} 
                            tags={props.tags} 
                            tagIds={tagIds} 
                            mobile={mobile}
                        />
                        </div>
                    : 
                    null 
                }
                {
                addNote || addTag ? 
                null 
                :
                <TagsList 
                    mobile={props.mobile} 
                    user={props.uid} 
                    usertype={props.usertype} 
                    setAuto={props.prepAuto} 
                    firebase={props.firebase} 
                    e={props.e} 
                    cid={props.cid} 
                    focusId={props.focusId} 
                    session={props.id} 
                    tagsList={tagsList}
                    widget={props.widget}
                />
                }
                {
                addNote || addTag ? 
                null :
                <NotesList 
                    mobile={props.mobile} 
                    user={props.uid} 
                    usertype={props.usertype} 
                    setAuto={props.prepAuto} 
                    firebase={props.firebase} 
                    e={props.e} 
                    cid={props.cid} 
                    focusId={props.focusId} 
                    session={props.id} 
                    widget={props.widget}
                    addNote={addNote}
                    magnify={props.magnify}
                />
                }
                {
                    props.widget && !addNote && !addTag ?
                    <div className="add-elements-container">
                        {props.usertype === 'guest' ? <div /> : <img className="focus-add-note" src={addtag} alt="Add Note" onClick={() => widgetClick('tag')} />}  
                        <img className="focus-add-note" src={addnote} alt="Add Note" onClick={() => widgetClick('note')} />
                    </div>
                    :               
                    null
                }
            </div>
            :
            <div>
                <div className="focus-body-section">   
                    <div>
                        <div className="height-10" />
                        <div className="focus-body-label">Tags</div>
                    {
                        addTag ?
                        <NewTag setAuto={props.prepAuto} clickTag={clickTag} tags={props.tags} tagIds={tagIds} /> :
                        <TagsList 
                            mobile={mobile} 
                            user={props.uid} 
                            usertype={props.usertype} 
                            setAuto={props.prepAuto} 
                            firebase={props.firebase} 
                            e={props.e} 
                            cid={props.cid} 
                            focusId={props.focusId} 
                            session={props.id} 
                            tagsList={tagsList}
                        />
                    }
                    </div>
                    <img className="focus-add-note" src={addtag} alt="Add Note" onClick={() => widgetClick('tag')} />   
                </div>
                <div className="focus-body-section">
                    <div>
                        <div className="focus-body-label">Notes</div>
                        {
                            addNote ?
                            <NewNote setAuto={props.prepAuto} setAddNote={setAddNote} newNote={newNote} setNewNote={setNewNote} saveNote={saveNote} /> : null 
                        }
                            <NotesList 
                                mobile={mobile} 
                                user={props.uid} 
                                usertype={props.usertype} 
                                setAuto={props.prepAuto} 
                                firebase={props.firebase} 
                                e={props.e} 
                                cid={props.cid} 
                                focusId={props.focusId} 
                                session={props.id} 
                            />
                    </div>
                    <img className="focus-add-note" src={addnote} alt="Add Note" onClick={() => widgetClick('note')} />
                </div>
            </div>
            }
            </div>
        </div>
    )
}

function ObjectList(objs) {
    if (objs) {
        return Object.keys(objs).map(key => ({
            ...objs[key],
            id: key,
        }))
    } else {
        return ('');
    };
};

function NewNote(props) {

    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        setFadeIn(true);
        props.setAuto(true);
    }, []);

    return (
        <div className={!props.mobile ? "f-add-note-box" : "f-add-note-box mobile-add-note-box"}>
            <textarea 
                className={props.mobile ? "focus-textarea-new no-scroll-bars mobile-foc-textarea" : "focus-textarea-new no-scroll-bars"}
                value={props.newNote} 
                onChange={(e) => props.setNewNote(e.target.value)}
                placeholder="Add note..."
                autoFocus
            />
            <div className={props.mobile ? "mobile-focus-add-note-buttons" : "focus-add-note-buttons"}>
                <button id="m-foc-note-btn" className="delete-btn" onClick={() => props.setAddNote(false)}>Cancel</button>
                <button id="m-foc-note-btn" disabled={props.newNote.length === 0} className="save-change-btn" onClick={() => props.saveNote()}>Submit</button>
            </div>
        </div>
    )
};

function NewTag(props) {

    const [fadeIn, setFadeIn] = useState(false);
    useEffect(() => {
        setFadeIn(true);
        props.setAuto(true);
    }, []);

    var m = props.mobile;

    return (
            <div className={m ? "focus-tag-add-mobile-container" : "focus-tag-add-container"}>
                <div className="focus-tag-add-label">Select Tags</div>
                {
                    props.tags ? 
                    <div className="focus-tag-add-list no-scroll-bars">
                    {
                    props.tags.sort((a, b) => (a.name > b.name) ? 1 : -1)
                    .map((item, index) => (
                        <div 
                            className={
                                props.tagIds.includes(item.id) && m ? 
                                "focus-tag-add-item mob-tag-it selected-tag-item cur-point" : 
                                props.tagIds.includes(item.id) && !m ?
                                "focus-tag-add-item selected-tag-item cur-point" :
                                m ? "focus-tag-add-item mob-tag-it cur-point" :
                                "focus-tag-add-item cur-point"} 
                            key={item.id} 
                            onClick={() => props.clickTag(item)}>
                            {item.name}
                        </div>
                    ))
                    }
                    </div>
                    :
                    <div className="null-description">No tags created</div>
                }
            </div>
    )

}

function TagsList(props) {

    var tags = props.tagsList;

    return (
        tags && tags.length > 0 ? 
        <div className="focus-tag-list">
        {props.mobile ? <div className="height-15" /> : null}
        {
            tags.sort((a, b) => (a.name > b.name) ? 1 : -1).map((item, index) => (
                <div className="focus-tag-list-item" key={item.id}>
                    {item.name}
                </div>
            ))
            
        }
        </div>
        :
        null
    )
};

function NotesList(props) {

    const [notesList, setNotesList] = useState('');

    useEffect(() => {
        if (props.e.notes) {
            var list = ObjectList(props.e.notes);
            setNotesList(list)
        } else {
            setNotesList('');
        }
    }, [props.e.notes]);

    const [exp, setExp] = useState('');
    const [noteVal, setNoteVal] = useState('');
    const [noteEdit, setNoteEdit] = useState('');

    function toEnd() {
        if (!noteEdit) {
            setNoteEdit(noteVal);
        }
    }

    function prepExp(item) {
        if (item.user === props.user) {
            if (!exp) {
                setNoteVal(item.note);
                setExp(item.id);
                props.setAuto(true);
            }
        }
    }

    function deleteNote(item) {
        props.firebase.apiSessionSubjectNote(props.session, props.e.id, item.id)
        .remove()
        if (props.usertype !== 'Guest') {
            props.firebase.apiCompanySubjectNote(props.cid, props.e.id, item.id)
            .remove()
        }

        setNoteEdit('');
        setNoteVal('');
        setExp('');
    }

    function saveChanges(item) {
        var edit = noteEdit;
        props.firebase.apiSessionSubjectNote(props.session, props.e.id, item.id)
        .update({
            note: edit
        })
        if (props.usertype !== 'Guest') {
            props.firebase.apiCompanySubjectNote(props.cid, props.e.id, item.id)
            .update({
                note: edit
            });
        }
        setNoteEdit('');
        setNoteVal('');
        setExp('');
    };

    const [editThis, setEditThis] = useState('');

    return (
        <div id={props.e.id}
            className="notes-list-box no-scroll-bars"
        >
        {notesList ? 
        notesList.filter(fil => {
            return (fil.privacy === 'team' || fil.user === props.user) 
        })
        .map((item, index) => (
            

            <div key={item.id} className={
                exp === item.id ? "foc-note-item edit-foc-note" : "foc-note-item"} onClick={props.mobile ? null : () => prepExp(item)}>
                <div>
                    <img src={NullUser} alt="Headshot" id={props.widget ? "magnify-img" : ''} />
                </div>
                <div>
                    {
                        exp === item.id ? 
                        <div>
                        <div className="relative">
                        <img className="cancel-note-btn" src={cancel} alt="Cancel Note" 
                            onClick={() => setExp('')}
                        />
                        <textarea 
                            className="edit-foc-textarea" 
                            autoFocus 
                            onFocus={() => toEnd()} 
                            value={noteEdit} 
                            onChange={(e) => setNoteEdit(e.target.value)} 
                        /> 
                        </div>
                        <button 
                            id="delete-note-btn" 
                            className="delete-btn button-box-shadow" 
                            onClick={() => deleteNote(item)}>
                                Delete Note
                         </button>
                         <button 
                            id="save-edit-note-btn" 
                            disabled={noteVal === noteEdit} 
                            className="save-change-btn button-box-shadow" 
                            onClick={() => saveChanges(item)}>
                                Save Changes
                         </button>
                        
                        </div>
                        :
                        <h3 className={props.widget ? "magnify-text-h3" : ''}>{item.note}</h3>
                        }
                    <h4 className={props.widget ? "magnify-text-h4" : ''}>{item.username} on {item.created.split(' ')[0].split('/')[0]}/{item.created.split(' ')[0].split('/')[1]}</h4>
                </div>
            </div>
        ))
        :
        null
        }
            <div id={props.e.id + 'scroller'} />
        </div>
    )
}