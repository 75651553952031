import React, { useState, useEffect } from 'react';

import moment from 'moment';

export default function AddASecondRoom(props) {


    const [openRooms, setOpenRooms] = useState('');

    useEffect(() => {
        props.firebase.rooms()
        .orderByChild('createdBy')
        .equalTo((props.uid))
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var list = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }));
                var open = list.filter(fil => {
                    return fil.isActive === false;
                })
                if (open) {
                    setOpenRooms(open);
                };
            };
        })
    }, []);

    function _addSecondRoom(item) {
        props.setRoom2(false);
        var time = moment().format('hh:mm a');
        var uo = props.session.users;
        var ro = props.session.roles;
        var users = uo ? Object.keys(uo).map(key => ({
            ...uo[key], id: key,
        })) : '';
        var groupKey = props.session.groupKey;
        var roles = ro ? Object.keys(ro).map(key => ({
            ...ro[key], id: key,
        })) : '';
        props.firebase.prepSecondSessionRoom(props.uid, props.session, users, roles, groupKey, time, item);
    }
    
    return (
        <div>
            <div className="second-room-label">
                Select Room
            </div>
            <div className="second-room-open-rooms-list">
                {
                    openRooms ?
                    openRooms.map((item, index) => (
                        <RoomOption key={item.id} _addSecondRoom={_addSecondRoom} item={item} />
                    ))
                    :
                    <div className="null-text">No Rooms Available</div>
                }
            </div>
            
        </div>
    )
}

function RoomOption(props) {

    return (
        <div className="second-room-option cur-point" onClick={props._addSecondRoom.bind(this, props.item)}>
            {props.item.roomName}
        </div>
    )
}
