import React from 'react';

const ConfirmPopup = (props) => {

    return (

        <div className="confirm-pop-up-container">
            <div className="confirm-pop-up-bg">
                <div className="confirm-pop-up-message">
                    {props.message}
                </div>
                <div className="confirm-pop-up-buttons">
                    <button 
                        className="confirm-pop-up-button pop-cancel"
                        onClick={props.cancel}    
                    >
                        Cancel
                    </button>
                    <button 
                        className="confirm-pop-up-button pop-confirm"
                        onClick={props.confirm}    
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    )
};

export { ConfirmPopup };