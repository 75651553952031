import React, { Component } from 'react';

import moment from 'moment';

import SlyQueue from './SlyQueue';

export default class UpNext extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checkins: props.checkIns,
            roleArray: props.roleArray,
            sort: 'time',
            currentTime: moment().format('hh:mma'),
            filteredCheckIns: '',
            tab: 'checkins',
            schedule: '',
        };
    };

    componentWillReceiveProps(nextProps, props) {
        if (nextProps.roleArray !== this.props.roleArray) {
            this.setState({
                roleArray: nextProps.roleArray,
                filterCheckIns: this.state.checkins ? this.state.checkins.filter(fil => {
                    return nextProps.roleArray.includes(fil.roleId);
                }) : '',
            })
        };
    }


    componentDidMount() {


        this.props.firebase.userCheckins(this.props.uid)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }));
                this.setState({
                    checkins: l,
                    order: l,
                    loading: false,
                    filterCheckIns: l.filter(fil => {
                        return this.state.roleArray ? this.state.roleArray.includes(fil.roleId) : '';
                    })
                })
            } else {
                this.setState({
                    order: '',
                    loading: false,
                    checkins: '',
                    filterCheckIns: '',
                })
            }}
        );
        
        this.props.firebase.selectCastingSessionSchedule(this.props.uid, this.props.castingSession)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                this.setState({
                    schedule: l,
                })
                this.props.scheduleReferenceList(l);
            };
        });
    
        this.interval = setInterval(this.updateTime, 60000);
        this.setState({
            intervalToClear: this.interval,
        });

    }

    tabSchedule = () => {
        this.setState({
            tab: 'schedule',
        })
    }

    tabCheckins = () => {
        this.setState({
            tab: 'checkins',
        })
    }

    updateTime = () => {
        this.setState({
            currentTime: moment().format('hh:mma')
        });
    };

    componentWillUnmount() {
        clearTimeout(this.interval);
        this.props.firebase.userCheckins(this.props.uid).off();
    }

    setFade = (item) => {
        this.setState({
            fade: item.id,
        })
        this.props.prepTalentToAssign(item);
        var fTimer1 = setTimeout(() => {
            this.setState({
                fade: '',
            })
        }, 1000)
        return (() => clearTimeout(fTimer1));
    }

    setScheduleFade = (item) => {
        this.props.firebase.userCheckinSelect(this.props.uid, item.tempId)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var id = item.tempId;
                var tal = {id: id, ...o}
                this.setFade(tal);
            }
        })

        this.props.firebase.checkInSessionUser(this.props.groupSession, item.id)
        .update({
            status: 'complete',
        });

        this.props.firebase.checkInCastingUser(this.props.uid, this.props.castingSession, item.id)
        .update({
            status: 'complete',
        })
    }
    


    render() {

        var next = '';

        next = this.state.filterCheckIns ? this.state.filterCheckIns[0] : '';

        var newDiff = next ? moment(this.state.currentTime, 'hh:mma').diff(moment(next.checkInTime, 'hh:mma')) : '';
        var diff = newDiff / 1000 / 60;
        var list = this.state.filterCheckIns ? this.state.filterCheckIns : '';
        var display = diff > 60 ? '1h+' : diff + 'm';

        return (
            this.state.loading ?
            <div />
            :
            <div>
            <div className="check-in-list-tabs">
                <div 
                    className=
                    {
                        this.state.tab === 'checkins' ?
                        "check-in-list-tab selected-list-tab" :
                        "check-in-list-tab"
                    }  
                    onClick={() => this.setState({tab: 'checkins'})}>Checked In</div>
                <div className=
                    {
                        this.state.tab === 'schedule' ?
                        "check-in-list-tab selected-list-tab" :
                        "check-in-list-tab"
                    }  
                    onClick={() => this.setState({tab: 'schedule'})}>Schedule</div>
            </div>
            {
                this.state.tab === 'checkins' ?
                <CheckInsView  
                    next={next}
                    fade={this.state.fade}
                    filterCheckIns={this.state.filterCheckIns}
                    setFade={this.setFade}
                    NullUser={this.props.NullUser}
                    list={list}
                    diff={diff}
                    display={display}
                    currentTime={this.state.currentTime}
                    activeCheckIn={this.props.activeCheckIn}
                />
                :
                <ScheduleView 
                    schedule={this.state.schedule}
                    setScheduleFade={this.setScheduleFade}
                    activeCheckIn={this.props.activeCheckIn}
                />
            }
            </div>
        )
    }
}

const ScheduleView = (props) => {
    var schedule = props.schedule;
    var filter = schedule ? schedule.filter(fil => {
        return fil.name !== ''
    }) : '';

    return (
        <div className="sly-check-in-list">
            <div className="display-header">Schedule</div>
            <div className="session-schedule-container">
            {
                filter ?
                filter.map((item, index) => (
                    <ScheduleItem key={index} item={item} setScheduleFade={props.setScheduleFade} activeCheckIn={props.activeCheckIn} />
                ))
                :
                <div className="null-text">No schedule created</div>
            }
            </div>
        </div>
    )
}

const ScheduleItem = (props) => {
    var item = props.item;
    return (
        <div 
            className={
                item.status === 'checking in' ? 
                "sess-schedule-item green-border" : 
                item.status === 'complete' ?
                "sess-schedule-item complete-sess-sche-item" : 
                "sess-schedule-item"
            }
            onClick={item.status === 'checking in' && !props.activeCheckIn ? props.setScheduleFade.bind(this, item) : null}
            >
            <div>
            <div className="sess-schedule-time">{item.time}</div>
            </div>
            <div>
                <div className="sess-schedule-name">{item.name}</div>
                {item.status !== 'complete' ? <div className="sess-schedule-role">{item.role}</div> : null}
            </div>
        </div>
    )
}

const CheckInsView = ({
        next, fade, filterCheckIns, setFade, NullUser, list, diff, display, currentTime, activeCheckIn
    }) => {
    return (
    <div className="sly-check-in-list">
        <div className="display-header">Checked In</div>
        {
        filterCheckIns ?
            <div>
                
                {
                next ?
                    <div>
                        <div className="sly-check-in-label">
                            Currently on deck
                        </div>
                        <div className={fade === next.id ? 
                            "fadeOutSlow cur-point" : "fadeInHeader cur-point"
                        }>
                            <div className={
                                diff > 30 ?  
                                "sly-on-deck-card very-long-wait" :
                                diff < 30 && diff > 15 ?
                                "sly-on-deck-card long-wait" :
                                diff < 15 ?
                                "sly-on-deck-card" :
                                "sly-on-deck-card"
                            }
                                onClick={!activeCheckIn ? () => setFade(next) : null}
                                
                                >
                                        <img className="on-deck-avatar" src={NullUser} alt="Headshot" />
                                        <div>
                                            <div className="sly-on-deck-display">{display}</div>
                                            <div className="sly-on-deck-name">{next.checkInName}</div>
                                            <div className="sly-on-deck-rep">{next.checkInRepCompany}</div>
                                            <div className="sly-on-deck-role">{next.checkInRole}</div>
                                        </div>
                                    </div>
                        </div>
                        
                    </div>
                    :
                    <div />
                }

                    <div className="height-15" />

                {list && list.length > 1 ?
                <div>
                    <div className="sly-check-in-label">
                        Checked in
                    </div>
                    <div className="sly-q-wide-scroll">
                    <div id="sly-q-wide-scroll">
                    {
                        list.map((item, index) => {
                            if (index !== 0) {
                                return <SlyQueue 
                                    currentTime={currentTime} 
                                    NullUser={NullUser} 
                                    key={item.id} item={item} 
                                    setFade={setFade}
                                    selectFade={fade}
                                    activeCheckIn={activeCheckIn}
                                />
                            }
                        })  
                    }
                    </div>
                    </div>
                    <div className="height-10" />
                    </div>
                    :
                    list && list.length === 1 ?
                    <div />
                    :
                    <div className="sly-check-in-label"></div>
                }
            </div>
            :
            <div />
            }
    </div>
    )
}

    