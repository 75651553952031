import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';

import { Link } from 'react-router-dom';

import '../Navigation/app.css';

import logo from '../../assets/aniicon.png';
import formColor from '../../assets/formColor.png';


export default class LockScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            co: 0
        };
    };


    _handleLogOutCount = () => {
        this.setState({
            co: this.state.co + 1
        })
        if (this.state.co === 3) {
            this.props.signOut()
        };
    };

    render() {

        return (
            <div className="background">
                <div className="check-in-layout">
                <div className="check-in-form-container">
                    <div className="header">
                        <div className="low-opacity"><img className="form-color" src={formColor} alt="Background Color"/></div>
                        <img className="ani-logo" src={logo} alt="ANi Logo" onClick={this._handleLogOutCount} />
                    </div>
                    <div className="pin-body">
                        <div className="input-header">Please enter your ANi pin</div>
                        <input className="pin-input" name="apple" onChange={this.props._handleChange.bind(this)} value={this.props.apple} />
                        <br />
                        <div className="link-back">Click below if you meant to go <br />to the 'Check In page'</div>
                        <Link to={ROUTES.CHECK_IN}><button className="to-check-in-btn">Back to check in</button></Link>
                    </div>
                    </div>
                </div>
            </div>

        )
    }
}