import React from 'react';

import trash from '../../assets/trashmodern.png';

import Select from 'react-select';

const CheckInCard = (props) => {

    var { c, activeProjectsArray, activeRolesArray, roomOptions, roomToAssignRole, _assignRoleToRoom, _handleTimeDiff } = props;
    if (c.dibs !== '' && roomOptions !== '') {
        var assignedRoom = roomOptions.filter(f => {
            return f.value === c.dibs
        });
        if (assignedRoom) {
            var assignedRoomName = assignedRoom[0].label ? assignedRoom[0].label : '';
        } else {
            assignedRoomName = '';
        }
    };

    return (
        <div key={c.id} 
            className="check-in-card-container" 
            onMouseEnter={props._showDelete.bind(this, c)}
            onMouseLeave={props._removeDelete}
        >
        <div className="check-in-cards">
            <div className="check-in-card-elements">
            <div className="check-in-times-container">
                <div className="check-in-card-time">{c.checkInTime}</div>
                {_handleTimeDiff(c.apptTime, c.checkInTime)}
            </div>
            <div className="check-in-card-name">{c.checkInName}</div>
            { activeProjectsArray ? 
                activeProjectsArray.includes(c.projectId) ?
                <div className="check-in-card-line-two text-bold">{c.checkInProject}</div>
                :
                <div className="check-in-card-line-two text-bold text-red">{c.checkInProject}</div>
                : <div className="check-in-card-line-two text-bold text-red">{c.checkInProject}</div>
                }
            <div className="check-in-card-line-two"> for</div>
            { activeRolesArray.includes(c.roleId) ?
                <div className="check-in-card-line-two text-bold">'{c.checkInRole}'</div>
                :
                <div className="check-in-card-line-two text-bold text-red">'{c.checkInRole}'</div>
            }
            <div className="check-in-card-rep">Rep: {c.checkInRepCompany}</div>

            { activeRolesArray.includes(c.roleId) ?
                <div />
                :
                <Select 
                className="assign-role-select"
                options={roomOptions} 
                value={roomToAssignRole}
                placeholder="Assign role..." 
                isSearchable={false}
                onChange={_assignRoleToRoom.bind(this, c)}
                />
            }
            {
                props.manualDrop && props.checkInToDrop === c ?
                <div>
                    <div className="connect-to-session">Click on a session to add</div>
                    <div className="connect-to-session red-text" onClick={props.cancelManualDrop}>Cancel</div>
                </div>
                :
                <button
                    className="connect-to-session"
                    onClick={props.prepToAddManually.bind(this, c)}
                >
                    Session already wrapped?
                </button>
            }
            </div>
            {
                assignedRoomName && assignedRoomName !=='' ?
                <div className="room-dibs-assignment">Assigned to: {assignedRoomName}</div>
                :
                <div />
            }
            {
                props.checkInHoverId === c.id ?
                <img 
                    className="remove-checkin-trash" 
                    src={trash} 
                    alt="Delete Check In" 
                    onClick={props._deleteCheckIn.bind(this, c)}
                />
                :
                <div />
            }
        </div>
        </div>

    )

}

export {CheckInCard};