import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';

export default function DashSessionsItem(props) {

    const [loading, setLoading] = useState(true);
    const [sessionInfo, setSessionInfo] = useState('');

    useEffect(() => {
        if (props.item !== '') {
            setLoading(false);
            console.log(props.item);
            
            props.firebase.sessionInfo(props.item)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    setSessionInfo(snapshot.val());
                }
            })
        } else {
            setLoading(true)
        }
        return props.firebase.sessionInfo(props.info).off();
    }, [props.firebase, props.item, props, loading])

    return (
        loading ?
        <div />
        :
        <Link 
            to={ROUTES.SESSION_GUEST + '/' + props.item}
            className="temp-session-card">
            {sessionInfo.company}
        </Link>
    )
}
