import React from 'react';

import Attendees from './Attendees';
import SessionModeOverview from './SessionModeOverview';

export default function Overview(props) {
    
    return (
        <div>
            <div className="session-mode-attendees">
                <div className="session-mode-section-label">
                Attendees
                </div>
                <Attendees 
                    externals={props.props.externals}
                    internals={props.props.internals}
                />
            </div>
            <div className="session-mode-overview">
                <SessionModeOverview 
                    sessionId={props.props.sessionId}
                    groupId={props.props.groupId}
                />
            </div>
        </div>
    )
}
