import React, { Component } from 'react';

import remove from '../../assets/remove.png';

export default class DeviceOwner extends Component {

    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            type: '',
        };
    };

    _setEmployee = () => {
        this.setState({
            step: 2,
            type: 'Employee',
        })
    }

    _setShared = () => {
        this.setState({
            type: 'Shared',
        });
        this.props.setDeviceAsRoom();
    }

    _setReceptionist = () => {
        this.setState({
            type: 'Receptionist',
        })
        this.props.setDeviceAsReception(this.props.firebase, this.props.uid);
    };

    _setTalent = () => {
        this.setState({
            type: 'Talent',
        });
        this.props.setDeviceAsTalent();
    }

    _setPublic = () => {
        this.setState({
            type: 'Public',
        });
        this.props.setDeviceAsPublic();
    }

    _onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    _prepSelectDeviceOwner = (item, firebase, uid) => {
        this.setState({
            type: 'Employee Thanks',
        });
        this.props.selectDeviceOwner(item, firebase, uid)
    };

    _closePopUp = () => {
        this.props._resetDevice();
    }

    _addEmployeeFromPush = () => {
        
    };


    render() {
        
        return (
            <div className="show-device-message">
                <img className="close-push-message" src={remove} alt="Close" onClick={this._closePopUp} />
                <div className="push-notification-direction">Push Notification Setup</div>
                {
                    this.state.type === 'Employee' ?
                    <div>
                    <div className="show-device-text">Please select who will be using this device</div>
                    <div className="push-employee-options">
                        {
                            this.props.employees ?
                        
                            this.props.employees.map((item, index) => (
                                <div className="push-employee-select" key={item.id} onClick={this._prepSelectDeviceOwner.bind(this, item, this.props.firebase, this.props.uid)}>
                                    <div className="push-employee-name">{item.employeeName}</div>
                                </div>
                            ))
                            :
                            <div>
                            <div className="show-device-text">It seems that you haven't added any employees to your account, type in their name below to add</div>
                            <input 
                                className="enter-device-employee-name" 
                                value={this.state.newEmployee} 
                                name="newEmployee"
                                onChange={this._onChange.bind(this)}
                                onKeyDown={this._addEmployeeFromPush}
                            />
                            </div>
                        }
                    </div>
                    </div>
                    :
                    this.state.type === 'Shared' ?
                    <div>
                        <div className="show-device-text">When you click in a 'Room', set 'Push Notifications' to on to receive all activity directed towards the room.</div>
                        <button onClick={this._closePopUp}>Close</button>
                    </div>
                    :
                    this.state.type === 'Talent' ?
                    <div>
                        <div className="show-device-text">This device will not receive any notifications, you can change this setting in the Quick Bar.</div>
                        <button onClick={this._closePopUp}>Close</button>
                    </div>
                    :
                    this.state.type === 'Receptionist' ?
                    <div>
                        <div className="show-device-text">This device will receive notifications when someone requests an actor from a room during a casting session.</div>
                        <button onClick={this._closePopUp}>Close</button>
                    </div>
                    :
                    this.state.type === 'Public' ?
                    <div>
                        <div className="show-device-text">This device will not receive any notifications.</div>
                        <button onClick={this._closePopUp}>Close</button>
                    </div>
                    :
                    this.state.type === 'Employee Thanks' ?
                    <div>
                        <div className="show-device-text">This device will receive notifications directed at the selected user when not on the web page.</div>
                        <button onClick={this._closePopUp}>Close</button>
                    </div>
                    :
                    <div>
                        <div className="show-device-text">How will this device be used?</div>
                        <div className="push-employee-options">
                            <div className="push-employee-select" onClick={this._setEmployee}>
                                Privately by a single employee
                            </div>
                            <div className="push-employee-select" onClick={this._setShared}>
                                Stationed in a room used by different employees
                            </div>
                            <div className="push-employee-select" onClick={this._setReceptionist}>
                                By the receptionist or employee running the front desk
                            </div>
                            <div className="push-employee-select" onClick={this._setTalent}>
                                By talent checking in
                            </div>
                            <div className="push-employee-select" onClick={this._setPublic}>
                                This is a public device and should not receive notifications of any kind
                            </div>
                        </div>
                        <div className="help-tip-bottom-text">Please note that you will be able to adjust these settings later on on your Quick Bar.</div>
                    </div>
                }
            </div>
        )
    }
}
