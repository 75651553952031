import React from 'react';
import { NullUser } from '../../assets/nulluser';

export default function ArrivalCard(props) {

    var t = props.track;
    
    return (
        <div className="arrival-card">
            <img className="arrival-card-image" src={NullUser} alt="Headshot" />
            <div className="arrival-elements">
                <div className="arrival-name">{props.name}</div>
                <div className="arrival-sub">{props.sub}</div>
                <div className="arrival-pos">{props.pos}</div>
                {
                    props.type === 'checked' ?
                    <div className="focus-time">{
                        t.in.split(' ')[1].split(':')[0] + 
                        ':' + 
                        t.in.split(' ')[1].split(':')[1] +
                        ' ' + t.in.split(' ')[2]}</div> :
                    props.type === 'complete' ?
                    <div className="focus-time">{
                        t.in.split(' ')[1].split(':')[0] + 
                        ':' + 
                        t.in.split(' ')[1].split(':')[1] +
                        ' ' + t.in.split(' ')[2] + ' - ' +
                        t.out.split(' ')[1].split(':')[0] + 
                        ':' + 
                        t.out.split(' ')[1].split(':')[1] +
                        ' ' + t.out.split(' ')[2]}</div> :
                    null
                }
            </div>
            {
                props.settings ?
                <div className="arrival-card-settings">
                    <button className="remove-guest-btn" onClick={() => props.removeFunction()}>{props.functionText}</button>
                </div>
                :
                null
            }
        </div>
    )
};
