import React, { useState, useEffect } from 'react';

import PositionsList from './PositionsList';

export default function ManagePositions(props) {

    const [positions, setPositions] = useState('');
    const [affs, setAffs] = useState('');

    useEffect(() => {
        if (props.cid) {
            props.firebase.apiCompanyPositions(props.cid)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({
                        ...o[key],
                        id: key,
                    }));
                    setPositions(l);
                } else {
                    setPositions('');
                }
            })
        };

        if (props.cid) {
        props.firebase.apiCompanyAffiliations(props.cid)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }));
                setAffs(l);
            } else {
                setAffs('');
            }
        })
    }
    }, [props.cid]);

    function addPosition(pck) {
        props.firebase.apiNewCompanyPosition(props.uid, props.cid, pck);

    }

    const [addPos, setAddPos] = useState(false);

    return (
        <div className="nav-section">
            <div className="acct-settings-back-btn" onClick={() => props.setTab('')}>Back to Dashboard</div>
            <div className="menu-header nav-text-color">My Positions</div>

            <div className="nav-scroller no-scroll-bars">
            <div className="padding-sides-10">
            <div className="height-10" />
            <div className={addPos ? "nav-item-card add-pos-exp cur-point" : "nav-item-card cur-point"}>
            {
                addPos ? 
                <PositionAdd addPosition={addPosition} affs={affs} setAddPos={setAddPos} /> :
                <div className="new-position" onClick={() => setAddPos(true)}>Add Position</div>
            }
            </div>
            </div>
            <PositionsList showCandidates={props.showCandidates} list={positions} firebase={props.firebase} cid={props.cid} />
            </div>
        </div>
    )
}

function PositionAdd(props) {

    const [pos, setPos] = useState('');
    const [posSet, setPosSet] = useState(false);
    const [aff, setAff] = useState('');
    const [affObj, setAffObj] = useState('');
    const [affKey, setAffKey] = useState('');
    const [affShow, setAffShow] = useState(false);
    const [filters, setFilters] = useState('');

    function prepPck() {
        var affKey = affObj ? affObj.id : '';
        var pck = {name: pos, aff: aff, affKey: affKey};
        props.addPosition(pck);
        setPos('');
        setPosSet(false);
        setAff('');
        setAffObj('');
        setAffShow(false)
        props.setAddPos(false);
    };

    function selectAff(item) {
        setAffObj(item);
        setAff(item.name);
        setAffShow(false);
    }

    function handleKeyDown(e) {
        if (e.keyCode === 13 || e.keyCode === 9) {
            if (filters.length === 1) {
                setAffObj(filters[0]);
                setAff(filters[0].name);
            }
        };
    };

    function prepAff(e) {
        setAff(e);
        var filters = props.affs.filter(fil => { return fil.name.toLowerCase().includes(e.toLowerCase())});
        setFilters(filters);
    }

    var invalid = pos === '';

    return (
        <div id="create-new-position">
            <div className="menu-input-container">
                <div className="menu-label nav-text-color">Position</div>
                <input 
                    className="nav-input"
                    onChange={(e) => setPos(e.target.value)}
                    value={pos}
                    placeholder="Position name or title"
                    id="pos-input-bg"
                    autoFocus
                />
            </div>
            <div className="menu-input-container">
                <div className="menu-label nav-text-color">Group or Project</div>
                <input 
                    className="nav-input"
                    onChange={(e) => prepAff(e.target.value)}
                    value={aff}
                    placeholder="Search or create new"
                    id="pos-input-bg"
                    disabled={pos.length < 2}
                    onFocus={() => setAffShow(true)}
                    onKeyDown={(e) => handleKeyDown(e)}
                    autoComplete="off"
                />

                {affShow ? <AffDisplay affs={props.affs} filters={filters} selectAff={selectAff} /> : null}
            </div>
            <div className="session-item-buttons">
                <button className="cancel-nav-btn" onClick={() => props.setAddPos(false)}>Cancel</button>
                <button disabled={invalid} className="nav-button-color" onClick={() => prepPck()}>Confirm Add</button>
            </div>
        </div>
    )
}

function AffDisplay(props) {

    var list = props.filters ? props.filters : props.affs; 

    return (
        <div className="aff-container">
            {
                list ?
                list.map((item, index) => (
                    <div key={item.id} className="aff-item" onClick={() => props.selectAff(item)}>
                        <div></div>
                        <div>{item.name}</div>
                    </div>
                ))
                :
                <div className="null-text">None created</div>
            }
        </div>
    )
}
