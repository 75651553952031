import React from 'react';

const RoomFAQ = () => {

    return (

        <div className="topic">
        <div className="topic-header">Setting up a room</div>
        <div className="topic-description">
            Setting up your rooms correctly is the key to making the 'Actor Assigning' process work flawlessly. Luckily, the process of setting up a room is quick! 
            <br />
            <br />
            The first step is to make sure the room is set to <b>active</b> in your account settings. If you see it on your dashboard already then you are good to go.
            <br />
            <br />
            There are two ways you can go about adding employees (aka readers), projects and roles to a room, both of which are listed below.
            <br />
            <br />
            <div className="topic-sub-header">ROOM SETTINGS MODAL</div>
            <b>The room settings modal</b> for each active room is accessible from your dashboard. To open the modal click on the <b>gear icon</b> on the top right of an individual room's card. 
            <br />
            <br />
            To close the room modal, click on the x at the top right.
            <br />
            <br />
            <b>The active readers section</b> refers to employees that are assigned to the room. <b>To add or remove an employee</b>, click on the + icon to the immediate right of where it says 'Active Readers'. This will expand a dropdown of the employees associated with your company account, and all you have to do is click them on or off.
            <br />
            <br />
            You can also remove an employee without opening the dropdown by clicking on the 'x' next to their name. 
            <br />
            <br />
            <b>The active projects section</b> refers to projects that are assigned to the room. <b>To add or remove a project</b>, click on the + icon to the immediate right of where it says 'Active Projects'. This will expand a dropdown of all of the projects associated with your company account, and all you have to do is click them on or off.
            <br />
            <br />
            As a helpful tool, if you add a project that is not active in your Account settings, it will activate it for you. However, it will not deactivate it if you remove the project as it could be active in other rooms. This will be a preference you can switch on and off soon. 
            <br />
            <br />
            <b>To add and remove roles</b>, click on the dropdown arrow on the listed room. This will pull up all of the roles connected to the project, and all you have to do is click them on or off. 
            <br />
            <br />
            As a helpful tool, if you add a role that is not active in your Account settings, it will activate it for you. However, it will not deactivate it if you remove the role as it could be active in other rooms. This will be a preference you can switch on and off soon. 
            <br />
            <br />
            <div className="topic-sub-header">ROOM PAGE</div>
            <b>The room page</b> for each active room is accessible by clicking on the room's title from the dashboard. This opens up a focused view on the room, and filters all of the checked in actors that match the projects and roles currently assigned to the room. 
            <br />
            <br />
            The process of adding employees, projects and roles to the room is exactly the same as the room modal, but in the room view you also have the option of removing projects by clicking the 'x' on the top right of the listed project, and the 'x' to the right of the role's name.
            <br />
            <br />
            <div className="topic-sub-header">ROGUE CHECK INS</div>
            In the case where an actor checks in for a project or a role that is manually inputted, or active but not assigned to a room, their check in cards (see Assigning Actors to Rooms) will display red text, and it will include a dropdown of your active rooms. From this dropdown, you can automatically assign the project and/or role to the selected room.
        </div>      
        </div>

    )
}

export { RoomFAQ };