import React, { Component } from 'react';

import { NullUser } from '../../assets/nulluser';

export default class UserForm extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            username: '',
            userEntered: false,
        }
    };

    _onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    };

    _onEnterUser = (event) => {
        if (event.keyCode === 13 && event.shiftKey === false) {
            event.preventDefault();
            this._enterUser();
          }
    }

    _enterUser = () => {
        
        this.props.firebase.talentSearch()
        .orderByChild('username')
        .equalTo((this.state.username))
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var user = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }));
                this.setState({
                    talent: user[0],
                    userEntered: true,
                });
                this.props._setUserDetails(user[0].name, user[0].rep, user[0].id);
            }
        })
    }

    _prepSelectProject = (item) => {
        var projectId = {value: item.id, label: item.projectName}
        this.props.handleChangeProjectSelect(projectId);
    };

    _prepSelectRole = (item) => {
        var roleId = {value: item.value, label: item.label}
        this.props.handleChangeRoleSelect(roleId);
    };

    render() {

        return (

            <div className="check-in-form-div-user">
                {
                    this.state.userEntered ? 
                    <div>
                        <div className="user-box">
                        <img className="user-checkin-avatar" src={this.state.talent.avatar ? this.state.talent.avatar : NullUser} alt="Headshot" />
                        <div>
                            <div className="user-name-text">{this.state.talent.name}</div>
                            <div className="user-rep-text">{this.state.talent.rep}</div>
                        </div>
                        </div>
                        {
                            this.props.state.projectConfirmed ?
                            <div className="projects-input-container fadeIn">
                            {
                                this.props.state.customProject !== '' ?
                                <div className="input-container-position">
                                    <img 
                                        className="edit-input-icon"
                                        alt="Confirm"
                                        src={this.props.graypencil}
                                        onClick={this.props._editProject}   
                                    />
                                    <div className="input-label">Project</div>
                                    <div className="confirmed-text">{this.props.state.customProject}</div>
                                </div>
                                :
                                <div className="input-container-position">
                                    <img 
                                        className="edit-input-icon"
                                        alt="Confirm"
                                        src={this.props.graypencil}
                                        onClick={this.props._editProject}   
                                    />
                                    <div className="input-label">Project</div>
                                    <div className="confirmed-text">{this.props.state.projectId.label}</div>
                                </div>
                                }
                            </div>
                            :
                            <div className="projects-input-container fadeIn">
                            {
                                this.props.state.customProjectShow || this.props.state.projects === '' ? 
                                    <div className="projects-input-container fadeIn">
                                        <div className={this.props.state.currentFocus === 'project' ? "input-label focus-label" : "input-label"}>Project Title</div>
                                        <input
                                            className="check-in-input"
                                            type="text"
                                            autoFocus
                                            name="customProject"
                                            value={this.props.state.customProject}
                                            autoComplete="off"
                                            placeholder="Type project title..."
                                            onChange={this.props.onChangeInputs}
                                            onKeyDown={this.props._onEnterCustomProject}
                                        />
                                        <button className="confirm-input-container" onClick={this.props._confirmCustomProject} >
                                            <img 
                                                className="confirm-input-icon"
                                                alt="Confirm"
                                                src={this.props.state.customProject.length > 2 ? this.props.checkpink : this.props.checkgray}
                                            />
                                        </button>
                                    </div>
                                    :
                                    <div>
                                        <div className={this.props.state.currentFocus === 'project' ? "input-label focus-label" : "input-label"}>Which project are you here for?</div>
                                        <ProjectList 
                                            projects={this.props.state.projects} 
                                            _prepSelectProject={this._prepSelectProject}
                                            _selectUnlistedProject={this.props._selectUnlistedProject}
                                        />
                                    </div>
                                }
                            </div>
                        }

                        {
                            this.props.state.projectConfirmed ?

                            this.props.state.roleConfirmed ?
                            <div className="projects-input-container fadeIn">
                                {
                                    this.props.state.customRole !== '' ?
                                    <div className="input-container-position">
                                        <img 
                                            className="edit-input-icon"
                                            alt="Confirm"
                                            src={this.props.graypencil}
                                            onClick={this.props._editRole}   
                                        />
                                        <div className="input-label">Role</div>
                                        <div className="confirmed-text">{this.props.state.customRole}</div>
                                    </div>
                                    :
                                    <div className="input-container-position">
                                        <img 
                                            className="edit-input-icon"
                                            alt="Confirm"
                                            src={this.props.graypencil}
                                            onClick={this.props._editRole}   
                                        />
                                        <div className="input-label">Role</div>
                                        <div className="confirmed-text">{this.props.state.roleId.label}</div>
                                    </div>
                                }
                            </div>
                            :
                            <div className="projects-input-container fadeIn">
                            {
                                this.props.state.customRoleShow || this.props.state.projectRoles === '' ?
                                    <div className="projects-input-container fadeIn">
                                        <div className={this.props.state.currentFocus === 'role' ? "input-label focus-label" : "input-label"}>Role Name</div>
                                        <input
                                            className="check-in-input"
                                            type="text"
                                            autoFocus
                                            name="customRole"
                                            value={this.props.state.customRole}
                                            autoComplete="off"
                                            placeholder="Type role name..."
                                            onChange={this.props.onChangeInputs}
                                            onKeyDown={this.props._onEnterCustomRole}
                                        />
                                        <button className="confirm-input-container" onClick={this.props._confirmCustomRole} >
                                            <img 
                                                className="confirm-input-icon"
                                                alt="Confirm"
                                                src={this.props.state.customRole.length > 3 ? this.props.checkpink : this.props.checkgray}
                                            />
                                        </button>
                                    </div>
                                    :
                                    <div>
                                        <div className={this.props.state.currentFocus === 'role' ? "input-label focus-label" : "input-label"}>Which role are you auditioning for?</div>
                                        <RoleList 
                                            projectRoles={this.props.state.projectRolesArray} 
                                            _prepSelectRole={this._prepSelectRole}
                                            _selectUnlistedRole={this.props._selectUnlistedRole}
                                        />
                                    </div>
                                }
                            </div>
                            :
                            <div />
                        }
                    
                        {
                            this.props.state.projectConfirmed &&
                            this.props.state.roleConfirmed ?
                            this.props.renderBtn(this.props.isInvalid, this.props.checkType)
                            :
                            <div />
                        }
                    </div>
                    :
                    <div>
                        <div className={this.props.state.currentFocus === 'name' ? "input-label focus-label" : "input-label"}>Enter ANi ID</div>
                        <input 
                            autoFocus   
                            className="check-in-input"
                            value={this.state.username} 
                            name="username" 
                            autoComplete="off"
                            onChange={this._onChange} 
                            onKeyDown={this._onEnterUser}
                        />
                        <button className="confirm-input-container" onClick={this._enterUser} >
                            <img 
                                className="confirm-input-icon"
                                alt="Confirm"
                                src={this.state.username.length > 3 ? this.props.checkpink : this.props.checkgray}
                            />
                        </button>
                    </div>
                }
            </div>
        )
    }
};

const ProjectList = (props) => {
    if (props.projects) {
        return (
            <div>
                {props.projects.map((proj, index) => (
                    <div 
                        className="check-in-project-option"
                        onClick={props._prepSelectProject.bind(this, proj)}
                        key={proj.id}
                    >
                    <div className="check-in-proj-title">
                            {proj.projectName}
                        </div>
                    </div>
                ))
                }
                <div className="not-listed-option" onClick={props._selectUnlistedProject}>Not listed?</div>
            </div>
        )
    }
}

const RoleList = (props) => {
    if (props.projectRoles) {
        return (
            <div>
                {props.projectRoles.map((role, index) => (
                    <div 
                        className="check-in-project-option"
                        onClick={props._prepSelectRole.bind(this, role)}
                        key={role.value}
                    >
                    <div className="check-in-proj-title">
                            {role.label}
                        </div>
                    </div>
                ))
                }
                <div className="not-listed-option" onClick={props._selectUnlistedRole}>Not listed?</div>
            </div>
        )
    }
}