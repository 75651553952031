import React, { useState, useEffect } from 'react';
import { NullUser } from '../../../../assets/nulluser';
import trash from '../../../../assets/trashmodernred.png';

export default function Guests(props) {

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [edit, setEdit] = useState(false);
    const [sent, setSent] = useState(false);

    function inviteGuest() {
        var pck = {
            email: email,
            name: name,
            id: props.id,
            cname: props.cname,
        }
        props.firebase.apiNewSessionInvite(pck);
        setSent(true);
        setEmail('');
        setName('');
        var timer = setTimeout(() => {
            setSent(false);
        }, 2000);
        return (() => clearTimeout(timer));
    };

    function removeGuest(item) {
        props.firebase.apiSession(props.id)
        .child('guests/' + item.id)
        .remove();
    };

    return (
        <div>
        <div className="details-section relative expand-to-fit">
            <button className="edit-team" onClick={() => setEdit(!edit)}>Edit Guests</button>
        <div className="nav-section-header color-nav-section">Guests</div>
        <div className="team-section">
            <div className="sess-add-team">
                {
                    edit ?
                    <div>
                    <InviteGuests 
                        uid={props.uid} id={props.id}
                        name={name} setName={setName} email={email} setEmail={setEmail} inviteGuest={inviteGuest} />
                        <div className="nav-divider" />
                        <EditGuestList guests={props.guests} removeGuest={removeGuest} />
                    </div>
                    :
                    <GuestList guests={props.guests} />
                }
            </div>
        </div>
        </div>
        </div>
    )
}

function InviteGuests(props) {

    var disabled = props.name.length < 1 || props.email.length < 5 && !props.email.includes('@');

    return (
        <div className="invite-guests-container">  
            {
                props.sent ?
                <div className="sent-msg">Invite has been sent!</div>
                :
                <div className="invite-box">
                    <div className="invite-box-header">Invite via Email</div>
                    <div className="height-10" />
                    <div>
                        <div className="input-label">Guest Name</div>
                        <input className="nav-input" value={props.name} onChange={(e) => props.setName(e.target.value)} />
                    </div>
                    <div className="height-10" />
                    <div>
                        <div className="input-label">Email Address</div>
                        <input className="nav-input" value={props.email} onChange={(e) => props.setEmail(e.target.value)} />
                    </div>
                    <button disabled={disabled} className="send-invite-session" onClick={() => props.inviteGuest()}>Send Invite</button>
                </div>
            }
        </div>
    )
}

function EditGuestList(props) {
    var guests = props.guests ? Object.keys(props.guests).map(key => ({
        ...props.guests[key],
        id: key,
    })) : '';

    return (
        guests ?
        guests.map((item, index) => (
            <div className="active-team-member" key={item.id} >
                <img className="active-team-avatar" src={NullUser} alt="Team Headshot" />
                <div>
                <div className="active-team-name">{item.name}</div>
                <div className="active-team-email">{item.email}</div>
                </div>
                <img className="remove-guest" src={trash} alt="Remove Guest" onClick={() => props.removeGuest(item)} />
            </div>
        ))
        :
        <div className="null-text">No invites sent</div>
    )
}

function GuestList(props) {

    var guests = props.guests ? Object.keys(props.guests).map(key => ({
        ...props.guests[key],
        id: key,
    })) : '';

    return (
        guests ?
        guests.map((item, index) => (
            <div className="active-team-member" key={item.id} >
                <img className="active-team-avatar" src={NullUser} alt="Team Headshot" />
                <div>
                <div className="active-team-name">{item.name}</div>
                <div className="active-team-email">{item.email}</div>
                </div>
                <AwayStatus item={item} />
            </div>
        ))
        :
        <div className="null-text">No invites sent</div>
    )
};


function AwayStatus(props) {
    return (
        <div className={props.item.status === 'accepted' ? 'green-circle' : 'red-circle'} />
    )
}
