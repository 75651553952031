import React, { useEffect, useState } from 'react';
import { ObjectList } from '../Functions/Common';
import question from '../../assets/question.png';
import remove from '../../assets/remove.png';

import './update.css';

export default function Update(props) {

    useEffect(() => {
        if (props.uid) {
            props.firebase.apiUser(props.uid)
            .child('lastUpdate')
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    var last = snapshot.val();
                    checkForUpdates(last);
                }
            })
        }
    }, [props.uid]);

    const [updates, setUpdates] = useState('');
    const [page, setPage] = useState(0);
    const [count, setCount] = useState('');
    const [show, setShow] = useState(false);
    const [caughtUp, setCaughtUp] = useState('');

    function refresh() {
        openUpdates()
        setShow(true);
    };

    function checkForUpdates(last) {
        props.firebase.updates()
        .child('current')
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var c = snapshot.val();
                var n = c.split(' ')[1];
                setCaughtUp(n);
                if (last < n) {
                    openUpdates(last);
                };
            };
        });
    };

    function openUpdates(last) {
        props.firebase.features()
        .orderByChild('status')
        .equalTo('Released')
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var obj = snapshot.val();
                var list = ObjectList(obj);
                setCount(list.length); 
                setUpdates(list);
                setShow(true);
            } else {
                setCount('');
                setUpdates('');
                setShow(false);
            }
        });
    };

    function next() {
        setPage(page === count ? 'Thanks' : page + 1);
    };

    function prev() {
        setPage(page === 1 ? null : page - 1);
    };

    function finish() {
        props.firebase.apiUser(props.uid)
        .update({
            lastUpdate: caughtUp,
        });
        setShow(false);
        setPage(1);
        setCaughtUp('');
    };

    return (
        show ?
        <div className="ani-update-container">
            <img className="closeout" src={remove} alt="Close Out" onClick={() => setShow(false)} />
            {
                page === 0 ?
                <WelcomeBack setPage={setPage} />
                :
                page === 'Thanks' ?
                <Thanks finish={finish} /> :
                <DisplayUpdates updates={updates} page={page} next={next} prev={prev} count={count} />
            }
        </div>
        :
        <img src={question} alt="Questions?" className="update-widget" onClick={() => refresh()}/>
    )
}

function WelcomeBack(props) {
    return (
        <div className="update-content">
            <div className="header">Welcome back!</div>
            <div className="body">Before you begin, please take a few moments to learn what's new with Check-In. It will only take a minute!</div>
            <div className="footer">
                <div />
                <button className="lets-begin" onClick={() => props.setPage(1)}>Let's begin!</button>
            </div>
        </div>
    )
};

function Thanks(props) {
    return (
        <div className="update-content">
            <div className="header">Thank you!</div>
            <div className="body">If you need to look at these again, you will be able to access these updates by clicking the button on the bottom right.</div>
            <div className="footer">
                <div />
                <button className="lets-begin" onClick={() => props.finish()}>Close</button>
            </div>
        </div>
    )
};

function DisplayUpdates(props) {

    const [fadeIn, setFadeIn] = useState(false);
    const [current, setCurrent] = useState('');
    const [page, setPage] = useState(1);

    useEffect(() => {
        setCurrent(props.updates[0]);
        setPage(1);
        setFadeIn(true);
    }, []);

    useEffect(() => {
        if (props.page !== page) {
            setFadeIn(false);
            var fadeTimer = setTimeout(() => {
                setCurrent(props.updates[props.page - 1]);
                setFadeIn(true);
                setPage(props.page);
            }, 500);
            return (() => clearTimeout(fadeTimer));
        }
    }, [props.page]);

    return (
        current ?
        <div className={fadeIn ? "fade-in" : "fade-out"}>
            <UpdateLayout header={current.label} description={current.description} next={props.next} prev={props.prev} />
        </div>
        :
        null
    )
};

function UpdateLayout(props) {

    return (
        <div className="update-content">
            <div className="header">{props.header}</div>
            <div className="body">
               {props.description}
            </div>
            <div className="footer">
                {props.page === 1 ? <div /> : <button onClick={() => props.prev()}>Go Back</button>}
                <button onClick={() => props.next()}>Next</button>
            </div>
        </div>
    )
}
