import React, { useState, useEffect } from 'react';

export default function Rooms(props) {

    const [rooms, setRooms] = useState('');
    const [newRoom, setNewRoom] = useState('');

    useEffect(() => {
        if (props.cid) {
            props.firebase.apiCompanyRooms(props.cid)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({
                        ...o[key],
                        id: key,
                    }))
                    setRooms(l);
                } else {
                    setRooms('');
                }
            })
        }
    }, [props.cid])

    function deleteRoom(room) {

    }

    function addRoom(e) {
        var r = newRoom;
        if (e.keyCode === 13) {
            props.firebase.apiNewRoom(props.cid, props.uid, r);
            setNewRoom('');
        }
    }

    return (
        <div className="nav-section">
            <div className="acct-settings-back-btn" onClick={() => props.setTab('')}>Back to Dashboard</div>
            <div className="menu-header nav-text-color">Company Rooms</div>
            <div className="nav-scroller no-scroll-bars">
            <div className="nav-section">
            <div className="nav-section-add">
                <input className="nav-input" value={newRoom} onChange={(e) => setNewRoom(e.target.value)} placeholder="Add new room" 
                    onKeyDown={(e) => addRoom(e)}
                />
            </div>
            <div className="height-20" />
            {
                rooms ?
                rooms.sort((a, b) => (a.name > b.name) ? 1 : -1)
                .map((item, index) => (
                    <RoomItem item={item} index={index} deleteRoom={deleteRoom} />
                ))
                :
                null
            }
            </div>
            </div>
        </div>
    )
}

function RoomItem(props) {
    return (
        <div className="nav-item-card">
            <div className="nav-item-card-title">{props.item.name}</div>
        </div>
    )
}