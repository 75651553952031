import React, { useState, useEffect } from 'react';
import { NullUser } from '../../assets/nulluser';
import { ObjectList } from '../Functions/Common';
import moment from 'moment';


export default function SummaryCard(props) {

    const [wait, setWait] = useState('TBD');
    const [call, setCall] = useState('TBD');
    const [total, setTotal] = useState('TBD');

    const inc = props.includes;

    useEffect(() => {

        var t = props.item.track;

        var a = moment(t.in, 'MM/DD/YYYY hh:mm:ss a');
        var b = moment(t.call, 'MM/DD/YYYY hh:mm:ss a');
        var c = t.out ? moment(t.out, 'MM/DD/YYYY hh:mm:ss a') : 'active';
        var bw = b.diff(a, 'minutes');
        var cw = c !== 'active' ? c.diff(b, 'minutes') : 'TBD';
        var tw = cw !== 'TBD' ? bw + cw : 'TBD';
        setWait(bw + ' mins');
        setCall(cw === 'TBD' ? 'TBD' : bw + cw + ' mins');
        setTotal(cw === 'TBD' ? 'TBD' : bw + cw + ' mins');
    })

    return (
        <div className="summary-card">
            <div className={inc.headshot ? "summary-header" : "summary-header-nohs"}>
                {inc.headshot ? <img src={NullUser} alt="Subject" /> : null}
                <div>
                    <div className="summary-card-name">{props.item.name}</div>
                    <div className="summary-card-pos">{props.item.interview.name}</div>
                </div>
            </div>
            <div className="summary-card-body">
                {inc.notes ? 
                    <div className="summary-card-body-section">
                        <Notes notes={props.item.notes} />
                    </div>
                    :
                    null
                }
                 {inc.tags ? 
                    <div className="summary-card-body-section">
                        <Tags tags={props.item.tags} />
                    </div> 
                    : 
                    null
                }
            </div>
            {
                inc.times ? <div className="height-20" /> : null
            }
            {
                inc.times ? 
                <div className="summary-card-times">
                    <div className="summary-card-time">Wait: {wait}</div>
                    <div className="summary-card-time">Length: {call}</div>
                    <div className="summary-card-time">Total: {total}</div>
                </div>
                :
                null 
            }   
        </div>
    )
}

function Tags(props) {

    const [tags, setTags] = useState('');

    useEffect(() => {
        if (props.tags) {
            var list = ObjectList(props.tags);
            setTags(list);
        }
    }, [props.tags]);

    return (
        <div className="list-section">
            {
                tags ?
                tags.map((tag, index) => (
                    <div key={tag.id} className="summary-tag">{tag.name}</div>
                ))
                :
                null
            }
        </div>
    )
}

function Notes(props) {

    const [notes, setNotes] = useState('');

    useEffect(() => {
        if (props.notes) {
            setNotes(ObjectList(props.notes));
        }
    }, [props.notes]);

    return (
        <div className="list-section">
            {
                notes ?
                notes.map((note, index) => (
                    <div key={note.id} className="summary-note">
                        <div className="summary-bullet" />
                        <div>
                            <div className="summary-note-text">
                                {note.note}
                            </div>
                            <div className="summary-note-by">
                                {note.username}
                            </div>
                        </div>
                    </div>
                ))
                :
                <div className="summary-null">No notes</div>
            }
        </div>
    )
}
