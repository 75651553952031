import React from 'react';

import './common.css';

const Switch = (props) => {

    return (

        <div className="switch-common-component-container" 
            onClick={!props.disabled ? props.toggleFunction : null}>
            <div className={props.boolean ? props.disabled ? "switch-background disabled-active-switch" : "switch-background active-switch" : "switch-background dormant-switch"}>
                <div className={props.boolean ? "switch-knob right-knob" : "switch-knob left-knob"}></div>
            </div>
            <div id={props.id} className={props.textStyle === 'large' ? "switch-common-text switch-large-text" : "switch-common-text"}>
                {props.text}
            </div>
        </div>
    )
};

export { Switch };