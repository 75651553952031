import React from 'react';

export default function NoteDisplaySettings(props) {
    return (
        <div className="display-settings-container">
            <div className="display-header">Completed Auditions</div>
            <div className="display-label inline-block">
                Style:
            </div>
            <div className="display-options inline-block">
                <div 
                    className={
                        props.cardSize === 'small' ? 
                        "display-option cur-point inline-block display-select" :
                        "display-option cur-point inline-block"
                    }
                    onClick={() => props._switchView('small')}
                >
                    Name Only
                </div>
                <div 
                    className={
                        props.cardSize === 'medium' ? 
                        "display-option cur-point inline-block display-select" :
                        "display-option cur-point inline-block"
                    }
                    onClick={() => props._switchView('medium')}
                >
                    Show Tags
                </div>
            </div>
        </div>
    )
}
