import React from 'react';

import { OppSection } from './BodyComponents/OppSection';
import { DetailsSection } from './BodyComponents/DetailsSection';
import { TalentSection } from './BodyComponents/TalentSection';
import { OperationsSection } from './BodyComponents/OperationsSection';


const NavModalBody = (props) => {

    return (

        <div className="nav-modal-body-container">
            {
                props.state.contentPage === 2 ?
                <DetailsSection 
                    state={props.state} 

                    _saveChanges={props._saveChanges}

                    showSave={props.showSave}
                    
                    _onChange={props._onChange}
                    _selectDate={props._selectDate}

                    _selectRoom={props._selectRoom}

                    _session1={props._session1}
                    _session2={props._session2}
                    _session3={props._session3}

                    _myoffice={props._myoffice}
                    _custom={props._custom}

                    _selectInPerson={props._selectInPerson}
                    _selectOnline={props._selectOnline}
                    _selectBoth={props._selectBoth}
                    _selectOffice={props._selectOffice}
                    _selectCustom={props._selectCustom}

                    _toSessionOpp={props._toSessionOpp}
                    _toSessionSchedule={props._toSessionSchedule}

                    />
                
                :
                props.state.contentPage === 3 ?
                <TalentSection 
                    state={props.state} 
                    _onChange={props._onChange}
                    _onSearchTalent={props._onSearchTalent}
                    _generateSlots={props._generateSlots}  
                    uid={props.uid}
                    firebase={props.firebase}
                    _currentShareModal={props._currentShareModal}
                    _hoverIndex={props._hoverIndex}
                    _hoverOut={props._hoverOut}
                    _toSessionDetails={props._toSessionDetails}
                    _toSessionOperations={props._toSessionOperations}
                />
                
                :
                props.state.contentPage === 4 ?
                <OperationsSection 
                    state={props.state} 
                    _selectUser={props._selectUser}
                    _deselectUser={props._deselectUser}
                    _toSessionSchedule={props._toSessionSchedule}
                    _finishSession={props._finishSession}
                    />
                :
                <OppSection 
                    state={props.state} 
                    
                    _onChange={props._onChange}
                    _selectProject={props._selectProject}
                    _selectRole={props._selectRole}
                    _deselectRole={props._deselectRole}

                    _saveSessionName={props._saveSessionName}
                    _onEnterSessionName={props._onEnterSessionName}

                    _toSessionDetails={props._toSessionDetails}
                />
            }
        </div>
    )
}

export { NavModalBody };