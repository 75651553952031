import React, { useState, useEffect } from 'react';

export default function Tags(props) {

    const [tags, setTags] = useState('');
    const [newTag, setNewTag] = useState('');

    useEffect(() => {
        if (props.cid) {
            props.firebase.apiCompanyTags(props.cid)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({
                        ...o[key],
                        id: key,
                    }))
                    setTags(l);
                } else {
                    setTags('');
                }
            })
        }
    }, [props.cid])

    function deleteTag(room) {

    }

    function addTag(e) {
        var t = newTag;
        if (e.keyCode === 13) {
            var pck = {
                name: t,
                uid: props.uid,
                cid: props.cid,
            }
            props.firebase.apiNewCompanyTag(pck);
            setNewTag('');
        }
    }

    return (
        <div className="nav-section">
            <div className="acct-settings-back-btn" onClick={() => props.setTab('')}>Back to Dashboard</div>
            <div className="menu-header nav-text-color">Company Tags</div>
            <div className="nav-scroller no-scroll-bars">
            <div className="nav-section">
            <div className="nav-section-add">
                <input className="nav-input" value={newTag} onChange={(e) => setNewTag(e.target.value)} placeholder="Add new tag" 
                    onKeyDown={(e) => addTag(e)}
                />
            </div>
            <div className="height-20" />
            {
                tags ?
                tags.sort((a, b) => (a.name > b.name) ? 1 : -1)
                .map((item, index) => (
                    <TagItem item={item} index={index} deleteTag={deleteTag} />
                ))
                :
                null
            }
            </div>
            </div>
        </div>
    )
}

function TagItem(props) {
    return (
        <div className="nav-item-card">
            <div className="nav-item-card-title">{props.item.name}</div>
        </div>
    )
}