import React, { Component } from 'react';

import TagItem from './TagItem';

export default class TalentTags extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedTags: '',
            userTags: '',
            sessionTags: '',
            sessionUserTags: '',
            selectedTagIds: '',
            sessionTagIds: '',
            sessionUserTagIds: '',
            activeSession: '',
            hideTags: false,
        }
    };

    componentWillReceiveProps(nextProps, props) {
        if (nextProps.talentId !== this.props.talentId) {
            this.forceUpdate();
        }

        if (nextProps.talentId === '') {
            if (this.state.disabled === false) {
            this.setState({
                disabled: true,
            })
            }
        } else {
            if (this.state.disabled === true) {
            this.setState({
                disabled: false,
            })
        }
        }
    }
    componentDidMount() {

        this.props.firebase.userTags(this.props.uid)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key
                }));
                this.setState({
                    userTags: l,
                })
            } else {
                this.setState({
                    userTags: ''
                });
            }
        })

        this.props.firebase.talentTags(this.props.talentId)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key
                }))
                this.setState({
                    selectedTags: l,
                    selectedTagIds: l.map(i => i.id)
                })
            } else {
                this.setState({
                    selectedTags: '',
                    selectedTagIds: '',
                })
            }
        })
    };

    componentWillUnmount() {
        this.props.firebase.userTags(this.props.uid).off();
        this.props.firebase.talentTags(this.props.talentId).off();
    }

    _addTalentTag = (item) => {
        const { uid, talentId, talentName, projectId, roleId, sessionId, sessionMode } = this.props;
        this.props.firebase.addTalentTag(uid, talentId, item.name, talentName, item.id, projectId, roleId, sessionId, sessionMode );
        this.props.firebase.addUserTalentTag(uid, talentId, item.id, item.name, projectId, roleId);
    };

    _removeTalentTag = (item) => {
        const { uid, talentId, sessionId, sessionMode } = this.props;
        this.props.firebase.removeTalentTag(uid, talentId, item.id, sessionId, sessionMode);
        this.props.firebase.removeUserTalentTag(uid, talentId, item.id);
    };

    render() {

        const { userTags, selectedTags, selectedTagIds, sessionTags, sessionTagIds, sessionUserTags, sessionUserTagIds } = this.state;
        const props = this.props;

        var sortedTags = userTags ? userTags.sort((a, b) => (a.name > b.name) ? 1 : -1) : '';

        return (
            this.state.hideTags ? 
            <div 
                className="show-tags"
                onClick={() => this.setState({ hideTags: false })}    
            >Show Tags</div>
            :
            <div className={props.activeViewStyle === 1 ? "talent-tag-list" : "talent-tag-list-2"}>
                <div 
                    className="show-tags"
                    onClick={() => this.setState({hideTags: true})}
                    >Hide Tags</div>
                {
                    userTags ? userTags.sort((a, b) => (a.name > b.name) ? 1 : -1).map((item, index) => (
                        <TagItem 
                            key={index} 
                            props={props}
                            disabled={this.state.disabled}
                            item={item} 
                            selectedTags={selectedTags} 
                            selectedTagIds={selectedTagIds}
                            sessionTags={sessionTags}
                            sessionTagIds={sessionTagIds}
                            sessionUserTags={sessionUserTags}
                            sessionUserTagIds={sessionUserTagIds}
                            _removeTalentTag={this._removeTalentTag} 
                            _addTalentTag={this._addTalentTag}
                            projectId={props.projectId}
                            roleId={props.roleId}
                            activeViewStyle={props.activeViewStyle}
                        />
                    )
                    )
                    :
                    <div>No tags created</div>
                }
            </div>
        )
    }
}