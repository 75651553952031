import React, { Component } from 'react';

import SessionHeader from './SessionHeader';
import SessionContent from './SessionContent';

import WelcomeMessage from './WelcomeMessage';

var timer;

export default class TalentOverview extends Component {


    constructor(props) {
        super(props);

        this.state = {
            welcome: false,
        }
    }

    componentDidMount() {
        clearTimeout(timer);
        timer = setTimeout(() => {
            this.setState({
                welcome: true,
            })
        }, 200);
        clearTimeout(timer);
    }

    render() {
        return (
            <div className="gray-background">
            {
                this.state.welcome ?
                <div className="fadeInFast">
                    <WelcomeMessage />
                </div>
                :
                <div />
            }
                <SessionHeader />
                <SessionContent />
                
            </div>
        )
    }
}
