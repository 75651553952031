import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { withAuthorization, withAuthentication } from '../Session';
import * as ROUTES from '../../constants/routes';
import { compose } from 'recompose';
import moment from 'moment';
import discoveranilogo from '../../assets/ANiLogoDark.png';

function InviteLink(props) {

    const [data, setData] = useState('');
    const [id, setId] = useState('');

    useEffect(() => {
        var URL = window.location.pathname;
        var invite = URL.replace('/appt-invite/', '');
        setId(invite);
        var iden = window.location.hash ? window.location.hash.substr(1) : '';
        props.firebase.apiInvite(invite)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                setData(o);
                if (iden && iden === 'accept') {
                   props.firebase.apiSessionSlot(o.session, o.slot)
                   .update({
                        status: 'confirmed',
                   });
                };
            };
        })
    }, []);

    
    return (
        <div className="invite-bg">
            <div className="invite-card">
                <img className="invite-logo" src={discoveranilogo} alt="Logo" />
                <div className="input-container">
                    <div className="register-form-header">Thanks for confirming!</div>
                    <div>
                        <div className="invite-text">You will be receiving a confirmation email shortly with more details on how to access the video session.</div>
                        <div className="height-30" />
                        <div className="register-form-header">What now?</div>
                        <div className="invite-text">Prior to the appointment time, please download the app below to your desktop or mobile device.</div>
                        <a href="https://heyjoe.vidyocloud.com/download.html?lang=en" target="_blank" className="download-app">Download App!</a>
                        
                    </div>
                <div className="height-30" />
                </div>
              
            </div>
        </div>
    )
}


const condition = authUser => !!authUser;

const VerCompose = compose(
    withFirebase,
    withRouter,
    withAuthentication
    )(InviteLink);

export default withAuthorization(condition)(InviteLink);