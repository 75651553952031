import React from 'react';

const FormFAQ = () => {

    return (

        <div className="topic">
        <div className="topic-header">Setting up the actor check in form</div>
        <div className="topic-description">
            <b>The actor check in form</b> is what the actors see when they use the app. 
            This form has 4 fields, which are their <b>name</b>, their <b>representation company</b>, and 
            then the <b>project</b> and <b>role</b> they are reading for. 
            <br />
            <br />
            <div className="topic-sub-header">Displaying the check in page</div>
            <b>To open the actor's check in page</b>, click on <b>'Check In'</b> located at 
            the top in your nav bar. The page opens up in a new tab, preventing actors 
            from accidentally pressing 'back' in the browser. <b>As a general safety tip, 
            make sure you close out of all sensitive tabs and pages prior to leaving 
            the device out!</b>
            <br />
            <br />
            <div className="topic-sub-header">Keeping the page secure</div>
            As an extra layer of protection, prior to leaving your device out for 
            actors, <b>press the 'Lock' icon on the top right of the check in form</b>. This 
            will lock the device's access to all sensitive pages on ANi Check In, keeping your 
            account safe. <b>To unlock the device</b>, retype in the main URL and press enter. 
            This will pull up a 'Lock Screen', where you will need to enter in your company's 
            ANi pin (default set to 2288). 
            <br />
            <br />
            <b>If you can't remember your pin</b>, you will need to log out and sign back in. 
            To do this, press the ANi Icon 4 times to logout and take you back to the sign in page.
            <br />
            <br />
            <div className="topic-sub-header">Form inputs</div>
            The name and representation company at this state is manually inputted (best to have a 
            small keyboard out if you are using an ipad!), but the <b>projects and roles are picked 
            via a dropdown</b>, which automatically filters with the projects and roles you set 
            as <b>'active'</b> in your accounts settings. This list is updated each time you make 
            a change so you don't need to keep refreshing the check in page. (see Account Settings).
            <br />
            <br />
            <div className="topic-sub-header">Selecting projects</div>
            <b>The project dropdown</b> is populated with the projects that are 'active' in your account, 
            and there are safeguards put in place for the below situations:
            <br />
            <br />
            <b>If you don't have any projects set to 'active'</b>, it will display a manual input 
            where they can type it in themselves. This will create a temporary project in your 
            account so you can assign it easily from your dashboard, and you will be able to 
            match it to an existing project later on.
            <br />
            <br />
            <b>If you have projects set to 'active', but the actor isn't there for any of them</b>, they 
            will be able to select the option <b>'Don't see your project?'</b>, which will then display a 
            manual input, where it will do the same as listed above.
            <br />
            <br />
            <div className="topic-sub-header">Selecting roles</div>
            <b>The role dropdown will display once a project is selected or typed in.</b> The only reason 
            it would display right away is if there are no projects set to 'active', and it would require 
            manual input anyways. 
            <br />
            <br />
            Once it appears, the dropdown will populate with <b>only the 'active' roles connected to 
                the project</b>, and there are safeguards put in place for the below situations: 
            <br />
            <br />
            <b>If there are no roles set to 'active'</b>, it will display a manual input where they 
            can type it in themselves. This will create a temporary role in your account under the 
            selected project so you can assign it easily from your dashboard, and you will be able to 
            match it to an existing role later on.
            <br />
            <br />
            <b>If there are roles set to 'active' on the selected prjoect, but the actor isn't there 
                for any of them</b>, they will be able to select the option <b>'Don't see your 
                role?'</b>, which will then display a manual input, where it will do the same as listed above.
            <br />
            <br />
            <div className="topic-sub-header">THANK YOU MESSAGE</div>
            Once they complete the check in form and press submit, it will display a <b>timed, 
            customizable thank you message</b> and then revert back to the default form for the next actor.
            <br />
            <br />
            <b>You can edit the thank you message on the right side of your dashboard by clicking on 
            'Customize your greeting message'</b>.
            </div>      
        </div>

    )
}

export { FormFAQ };