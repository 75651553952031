import React from 'react';

const FirebaseContext = React.createContext(null);

//From SignUp Form
//Rather than using a render prop component, 
//which is automatically given with React’s Context Consumer component, 
//it may be simpler to use a higher-order component. 

export const withFirebase = Component => props => (
    <FirebaseContext.Consumer>
        {firebase => <Component {...props} firebase={firebase} />}
    </FirebaseContext.Consumer>
);

export default FirebaseContext;