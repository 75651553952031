import React, {Component} from 'react';

import { EditAppt } from './EditAppt';

import confirmedicon from '../../../assets/confirmedschedule.png';
import pendingicon from '../../../assets/pendingschedule.png';
import rescheduleicon from '../../../assets/refreshschedule.png';
import trash from '../../../assets/trashmodern.png';
import trashred from '../../../assets/trashmodernred.png';

export default class ApptBlock extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dropdown: false,
            hr: '',
            newHr: '',
            adjustedHr: '',
            min: '',
            newMin: '',
            num: '',
            ampm: '',
            newAmpm: '',
            aid: '',
            newDate: '',
            date: '',
            deleting: false,
            editMode: false,
            showFields: false,
            status: '',
            newStatus: '',
            dateEdit: true, 
            month: '',
            newMonth: '',
            day: '',
            newDay: '',
            year: '',
            newYear: '',
            disabled: true,
        }
    }

    componentWillReceiveProps(nextProps, props) {
        if (nextProps.i !== this.props.i) {
            this.setState({
                status: nextProps.i.status,
                hr: nextProps.i.hr,
                min: nextProps.i.min,
                ampm: nextProps.i.ampm,
                aid: nextProps.i.id,
                date: nextProps.i.date,
            })
        }
    }

    componentWillMount() {
        var dateArray = this.props.i.date.split("/");
        var month = dateArray[0];
        var day = dateArray[1];
        var year = dateArray[2];

        this.setState({
            status: this.props.i.status,
            newStatus: this.props.i.status,
            min: this.props.i.min,
            newMin: this.props.i.min,
            hr: this.props.i.hr,
            newHr: this.props.i.hr,
            adjustedHr: this.props.i.hr > 12 ? this.props.i.hr - 12 : 
                this.props.i.hr === 0 ? 12 :
                this.props.i.hr,
            ampm: this.props.i.ampm,
            newAmpm: this.props.i.ampm,
            num: this.props.i.num,
            newNum: this.props.i.num,
            aid: this.props.i.id,
            date: this.props.i.date,
            month: month,
            newMonth: month,
            day: day,
            newDay: day,
            year: year,
            newYear: year,
        })
    }

    _prepRemoveAppt = () => {
        this.setState({
            deleting: true,
        })
        var timer = setTimeout(() => {
            this._handleRemoveAppt();
        }, 500);
        clearTimeout(timer);
        return () => {
            clearTimeout(timer);
        }
    }

    _toPending = () => {
        this.setState({
            newStatus: 'pending',
        })
    }

    _toConfirmed = () => {
        this.setState({
            newStatus: 'confirmed',
        })
    }

    _toReschedule = () => {
        this.setState({
            newStatus: 'reschedule',
        })
    }

    _openDateEdit = () => {
        this.setState({
            dateEdit: true,
        })
    }

    _closeDateEdit = () => {
        this.setState({
            dateEdit: false,
        })
    }
    
    _handleRemoveAppt = () => {
        var { uid } = this.props;
        var aid = this.state.aid;
        this.props.firebase.removeAppt(uid, aid);
    }

    _saveApptChanges = () => {
        var { uid } = this.props;
        var aid = this.state.aid;
        var s = this.state;
        this.props.firebase.userAppt(uid, aid)
        .update({
            status: s.newStatus,
            min: s.newMin,
            hr: 
                s.newAmpm === 'pm' && s.adjustedHr < 12 ? (Number(s.adjustedHr) + 12) : 
                Number(s.adjustedHr) === 12 && s.newAmpm === 'am' ? 0 :
                Number(s.adjustedHr) === 12 && s.newAmpm === 'pm' ? 12 :
                s.adjustedHr
                ,
            ampm: s.newAmpm,
            date: s.newMonth + '/' + s.newDay + '/' + s.newYear,
            num: s.newAmpm === 'pm' && Number(s.adjustedHr) !== 12 ? ((Number(s.adjustedHr) + 12) * 12) + (Number(s.newMin) / 5) + 1 : 
                s.newAmpm === 'pm' && Number(s.adjustedHr) === 12 ? ((Number(s.adjustedHr) * 12) + (Number(s.newMin) / 5) + 1) :
                s.newAmpm === 'am' && Number(s.adjustedHr) === 12 ? (Number(s.newMin) / 5) + 1 :
                Number(s.adjustedHr) * 12 + (Number(s.newMin) / 5) + 1,
        })
        this.setState({
            editMode: false,
        })
    }

    _cancelApptChanges = () => {
        var s = this.state;
        this.setState({
            newStatus: s.status,
            newMin: s.min,
            newHr: s.hr,
            newAmpm: s.ampm,
            newDay: s.day,
            newMon: s.month,
            newYear: s.year,
        })
        this.setState({
            editMode: false,
        })
    }

    setStatusIcon = () => {
        if (this.state.status === 'pending') {
            return <img src={pendingicon} alt="Pending" className="appt-status-icon" />
        } else if (this.state.status === 'reschedule') {
            return <img src={rescheduleicon} alt="Reschedule" className="appt-status-icon" />
        } if (this.state.status === 'confirmed') {
            return <img src={confirmedicon} alt="Confirmed" className="appt-status-icon" />
        }
    }

    _setEditMode = () => {
        this.setState({
            editMode: !this.state.editMode,
        })
    };

    _onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    _onChangeHr = (event) => {
        this.setState({
            adjustedHr: Number(event.target.value) > 12 ? (Number(event.target.value) - 12).toString() : event.target.value,
            newHr: Number(event.target.value) === 12 && this.state.newAmpm === 'am' ? 0 : 
                Number(event.target.value) !== 12 && this.state.newAmpm === 'pm' ? Number(event.target.value) + 12 :
                event.target.value,
        })
    }

    render() {

        var props = this.props;
        var s = this.state;
        var disabled = 
            s.day !== s.newDay ||
            s.month !== s.newMonth ||
            s.status !== s.newStatus ||
            s.year !== s.newYear ||
            s.min !== s.newMin ||
            s.hr !== s.newHr ||
            s.ampm !== s.newAmpm;

        return (
            <div className="inline-block">
            <div className={this.state.deleting ? "fadeOut" : ""}>
            <div className={
                this.state.editMode ? "appt-block-edit-mode" : "appt-block"}
                draggable={this.state.editMode ? false : true}
                onDragStart={props._onDragStart.bind(this, props.i)} 
                onDragEnd={props._onDragEnd.bind(this, props.i)}
                >
                <div className="change-time-select" onClick={this._setEditMode.bind(this)}>
                    {this.setStatusIcon()}
                    <div className="appt-min">{Number(s.num > 156) ? (Number(s.hr - 12).toString()) : s.hr}:{s.min}{s.ampm}</div>
                </div>
                <div className="appt-name">{props.i.name}</div>
                <div className="appt-project">{props.i.projectName}</div>
                <img className="trash-icon-appt" src={trash} alt="Delete Appt" />
                <img className="trash-icon-appt-red" src={trashred} alt="Delete Appt" onClick={this._prepRemoveAppt} />
                <div className="edit-appt-box">
                    {this.state.editMode ? 
                        <EditAppt
                        confirmedicon={confirmedicon}
                        rescheduleicon={rescheduleicon}
                        pendingicon={pendingicon}
                        props={props}
                        state={s}
                        _toConfirmed={this._toConfirmed}
                        _toPending={this._toPending}
                        _toReschedule={this._toReschedule}
                        _onChange={this._onChange}
                        _onChangeHr={this._onChangeHr}
                        _saveApptChanges={this._saveApptChanges}
                        _cancelApptChanges={this._cancelApptChanges}
                        disabled={disabled}
                        />  
                        :
                        <div />
                    }
                </div>      
            </div>
            </div>
            </div>
        )
    }
};