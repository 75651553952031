import React from 'react';

const AssignFAQ = () => {

    return (

        <div className="topic">
        <div className="topic-header">Assigning actors to rooms</div>
        <div className="topic-description">
            Our assigning functionality gives you the flexibility to read actors in various orders, 
            and you can complete the assign process via your 'Dashboard' or inside of the 'Room View'.
            <br />
            <br />
            By default, the incoming actors will show up on the right side of the app under the <b>Checked In tab</b>. 
            The actors will be sorted by the time they checked in, where the one 
            who has been there the longest will be on top.
            <br />
            <br />
            In order for an actor to be assigned to a room, <b>there needs to be an active room that 
            matches their project and role.</b> If there isn't a room that matches, the text on the actor's 
            card will display as red. In this case, you can use the dropdown to assign their role to 
            an active room.
            <br />
            <br />
            <div className="topic-sub-header">Assigning from dashboard</div>
            From the 'Dashboard View', an <b>Assign Next button</b> will appear if the room is open, 
            and if there is an actor in queue that matches. By clicking on the button, it will automatically 
            assign the first actor on the list that matches the criteria.
            <br />
            <br />
            The actor's card will then appear inside the room card, where you will be prompted with 
            a <b>Complete button</b>. By clicking on complete, it will open the room 
            back up, and send the actor's card to the <b>Completed tab on the right.</b>
            <br />
            <br />
            <div className="topic-sub-header">Assigning from room view</div>
            The room view has more options when assigning actors. To start, the check in queue 
            on the right of the screen will automatically filter the full actor queue to just 
            the actors that match your room. The list will update in real time whenever you make 
            an adjustment to the room as well.
            <br />
            <br />
            To assign the next actor, you simply press the <b>blue Send Next button</b> located 
            in the middle of the page. This will by default assign the actor at the top of the 
            list unless you prioritize an actor, or another room has 'dibs' on the actor. 
            (Explained below).
            <br />
            <br />
            Once you press Send Next, anyone who is in the Dashboard View will receive an alert 
            saying 'Please send Actor to Room'. This is in the case where a Casting Director 
            is in the room, and an assistant or receptionist is outside pulling in actors.
            <br />
            <br />
            To complete the audition, press the <b>green Complete button</b>, which will clear 
            the room, and also the alert from the Dashboard View. 
            <br />
            <br />
            <div className="topic-sub-header">Prioritizing actor assignments</div>
            From inside of the Room View, you will notice each actor has 2 icons on their card, 
            which are a star and a bookmark.
            <br />
            <br />
            Pressing the star icon prioritizes the actor's position in the queue, making them 
            next up when clicking the Send Next button. There can only be one prioritized actor per room.
            <br />
            <br />
            Pressing the bookmark <b>claims dibs</b> on the actor, meaning that no other room can assign them. 
            This is useful when you have multiple active rooms reading for the same roles. If another 
            room has dibs on the actor, the actor will be greyed out in your room view.
        </div>      
        </div>

    )
}

export { AssignFAQ };