import React, { useState } from 'react';

export default function StartSessionPopUp(props) {

    const [session, setSession] = useState('');

    const _prepSession = (e) => {
        e.preventDefault();
        if (session === '') {
            alert('Select a session first!');
        } else {
            props._prepSession(session);
        }
        
    }

    return (
        <div className="background-container">
            <div className="start-session-pop-up">
                <div className="heads-up">Heads up!</div>
                <div className="height-10" />
                You have a session coming up in this room, would you like to prep the room?
                <div className="height-20" />

                <div>Select session</div>
                {
                    props.sessionsToPrep ?
                    props.sessionsToPrep.map((item, index) => (
                        <div 
                            key={index} 
                            className={session === item ? "session-to-click selected-session" : "session-to-click"} 
                            onClick={
                                session === item ? 
                                () => setSession('') :
                                () => setSession(item)}
                            >
                            {item.name}
                        </div>
                    ))
                    :
                    <div />
                }
                <div className="height-40" />
                <div className="prep-buttons">
                    <button 
                        className="no-button feedback" 
                        onClick={props._close}>No Thanks</button>
                    {
                    session ? 
                    <button 
                        className="start-prep-button feedback"
                        onClick={_prepSession}
                        >Prep Session
                    </button>
                    :
                    <div />
                    }
                </div>
            </div>  
            
        </div>
    )
}
