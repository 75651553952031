import React, { useState, useEffect } from 'react';
import arrowdown from '../../assets/arrowdown.png';

export default function Select(props) {

    const [open, setOpen] = useState(false);

    var lab = props.selection === 'all' ? 'All Access' : 
        props.selection === 'admin' ? 'Admin' :
        'Basic';

    function prepFunction(item) {
        props.function(item);
        setOpen(false);
    }

    useEffect(() => {
        if (open && props.secondary) {
            prepSecondary();
        }
    }, [open])

    useEffect(() => {
        if (props.push !== props.receive) {
            setOpen(false);
        }
    }, [props.push]);

    function prepSecondary() {
        props.secondary(props.selectItem);
    }

    return (
        <div className="common-select-component">
            <div className="common-select-input" onClick={() => setOpen(!open)}>
                <div>{props.default}</div>
                <img className="common-select-icon" src={arrowdown} alt="Icon" />
            </div>  
            { open ?
            <div className={props.direction === 'up' ? "common-select-options dir-up" : "common-select-options"}>
                {
                    props.options.map((item, index) => (
                        <div 
                            key={index}
                            className={item.value === props.default ? "common-select-option common-selected" : "common-select-option"}
                            onClick={() => prepFunction(item)}
                        >
                            {item.label}
                        </div>
                    ))
                }
            </div> : null }
        </div>
    );
};
