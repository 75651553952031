import React, { useState, useEffect } from 'react';
import { NullUser } from '../../../assets/nulluser';
import ImageUpload from '../../Common/ImageUpload';
import Select from '../../Common/Select';
import { Switch, Input } from '../../Common';
import { ObjectList } from '../../Functions/Common';

export default function Company(props) {

    const [accounts, setAccounts] = useState('');
    const [office, setOffice] = useState('');
    const [rooms, setRooms] = useState('');
    const [company, setCompany] = useState('');
    const [logo, setLogo] = useState('');
    const [pending, setPending] = useState('');
    const [admin, setAdmin] = useState(false);
    const [adminId, setAdminId] = useState('');

    useEffect(() => {
        if (props.userdata.cid) {
            props.firebase.apiCompany(props.userdata.cid)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var accs = o.accounts ? ObjectList(o.accounts) : '';
                    setAccounts(accs.filter(fil => { return !fil.disabled}));
                    setPending(o.temps ? ObjectList(o.temps) : '');
                    setOffice(o.office);
                    setRooms(o.rooms);
                    setCompany(o.name);
                    setLogo(o.files && o.files.logo ? o.files.logo : '');
                    setAdminId(o.admin);
                }
            });
        }
    }, [props.userdata.cid]);

    const [add, setAdd] = useState(false);
    const [showPending, setShowPending] = useState(false);

    return (
        <div className="dash-section no-scroll-bars">
            <div className="dash-sub-section">
                <div className="dash-header-container">
                <div className="dash-header">Employee Accounts</div>
                {add ?
                    <AddAccount 
                        cid={props.userdata.cid}
                        myemail={props.userdata.myemail} 
                        firebase={props.firebase} 
                        company={company} 
                        setAdd={setAdd} 
                        /> :
                    <div>
                        <div className="employee-account-btns">
                        {pending ? <button className="dash-session-btn account-btn" onClick={() => setShowPending(!showPending)}>
                            {showPending ? 'Registered' : 'Pending'}</button> : <div />}
                        <button className="dash-session-btn account-btn nav-button-color" onClick={() => setAdd(true)}>New Account</button>
                    </div>
                    </div>
                }
                </div>
                {
                    showPending && pending ?
                    <PendingAccounts pending={pending} cid={props.userdata.cid} firebase={props.firebase} setShowPending={setShowPending} /> :
                    <Accounts adminId={adminId} accounts={accounts} uid={props.userdata.uid} cid={props.userdata.cid} firebase={props.firebase} />
                }
            </div>
            <div className="dash-sub-section">
                <div className="dash-header">Office Details</div>
                <Office office={office} firebase={props.firebase} cid={props.userdata.cid} logo={logo} />
            </div>
            <div className="height-40" />
        </div>
    )
};

function AddAccount(props) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(false);

    function confirmAccount() {

        var gen = Math.random().toString(36).substr(2, 6).toUpperCase();
        var tempKey = props.firebase.db.ref('api/companies/' + props.company + '/temps/').push().key;

        var pck = {
            name: name,
            myemail: props.myemail,
            email: email,
            code: gen,
            company: props.cid,
            companyName: props.company,
            tempKey: tempKey,
        }
        props.firebase.newCompanyAccount(pck);
        setMessage(true);
        var messageTimer = setTimeout(() => {
            setMessage(false);
            props.setAdd(false);
        }, 1000);
        return (() => clearTimeout(messageTimer));
    };

    return (
        <div className="add-account-container">
            {
                message ? 
                <div className="thanks-message">Invite sent!</div>
                :
                <div>
                    <div className="add-account-input">
                        <h3>Name</h3>
                        <Input placeholder="ex. John Smith" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="add-account-input">
                        <h3>Email</h3>
                        <Input placeholder="ex. ani@anicheckin.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <button disabled={!email || !name} className="save-change-btn" id="cancel-popup" onClick={() => confirmAccount()}>Confirm Account</button>
                    <div className="popup-divider" />
                    <button id="cancel-popup" className="delete-btn" onClick={() => props.setAdd(false)}>Cancel</button>
                </div>
            }
        </div>
    )

}

function PendingAccounts(props) {

    function removeInvite(item) {
        props.firebase.companyAccountRecover(item.id).remove();
        props.firebase.apiTempAccount(props.cid, item.id).remove();
    }

    return (
        <div>
            {
                props.pending ? props.pending.map((item, index) => (
                    <div className="account-item">
                        <div>
                            <h2>{item.name}</h2>
                            <h3>{item.email}</h3>
                            <h4>{item.timestamp.split(' ')[0]}</h4>
                        </div>
                        <button className="delete-btn" onClick={() => removeInvite(item)}>Cancel Invite</button>
                    </div>
                ))
                :
                null
            }
        </div>
    )
}

function Accounts(props) {
    return (
        <div className="accounts-list">
            {
                props.accounts ? 
                props.accounts.map((item, index) => (
                    <AccountItem adminId={props.adminId} uid={props.uid} key={item.id} item={item} cid={props.cid} firebase={props.firebase} />
                ))
                :
                <div className="null-text">No Accounts Created</div>
            }
        </div>
    )
}

function AccountItem(props) {

    const options = [
        {id: 0, label: 'Admin', value: 'admin', description: 'Has access to company settings and all priveledges enabled'},
        {id: 1, label: 'Basic', value: 'basic', description: 'Can create, edit or view all sessions, positions and tags'},
    ];

    const positionType = [
        {id: 0, label: 'Reception', value: 'reception', description: 'This account will receive notifications from all session rooms'},
        {id: 1, label: 'Assistant', value: 'assistant', description: 'This account can be connected to executive accounts'},
        {id: 2, label: 'Executive', value: 'executive', description: 'This account can be connected with assistants'},
    ]

    function selectItem(item) {
        props.firebase.apiCompanyAccount(props.cid, props.item.id)
        .update({
            permission: item.value,
        });
        props.firebase.apiUser(props.item.id)
        .update({
            permission: item.value,
        })
    };

    function toggleEmailNotifications(item) {
        props.firebase.apiCompanyAccount(props.cid, props.item.id)
        .update({
            emailNotifications: !props.item.emailNotifications
        });
        props.firebase.apiUser(props.item.id)
        .update({
            emailNotifications: !props.item.emailNotifications
        })
    };

    function removeAccount() {
        props.firebase.apiUser(props.item.id)
        .update({
            disabled: true,
            company: '',
            companyName: '',
        });
        props.firebase.apiCompanyAccount(props.cid, props.item.id)
        .update({
            disabled: true,
        })
    };

    const [remove, setRemove] = useState(false);

    return (
        <div className="account-item">
            <div>
                <img className="user-hs" src={props.item.avatar ? props.item.avatar : NullUser} alt="User Headshot" />
            </div>
            <div>
                <h2>{props.item.name}</h2>
                <h3>{props.item.email}</h3>
            </div>
            <div className="height-10" />
            {
                remove ?
                <div className="are-you-sure">
                    <div>Are you sure you want to remove this account?</div>
                    <div>
                    <button id="remove-color" className="save-changes-btn" onClick={() => setRemove(false)}>No, don't remove</button>
                    <button className="save-changes-btn" onClick={() => removeAccount()}>Yes, remove account</button>
                    </div>
                </div>
                :
                <div>
                    <div>
                        <div className="label">Permissions</div>
                        {
                        props.adminId === props.item.id ?
                        <div>Account Owner</div>
                        :
                        <Select direction="up" options={options} default={props.item.permission} function={selectItem} />
                        }
                    </div>
                    <div className="height-10" />
                    <div>
                        <div className="label">Notifications</div>
                        <div className="height-10" />
                        <Switch 
                            text="Email"
                            boolean={props.item.emailNotifications}
                            toggleFunction={toggleEmailNotifications}
                        />
                    </div>
                    <div className="height-20" />
                    {
                    props.uid === props.item.id ? null :
                    <div className="disable-account-container">
                        <button className="save-changes-btn" onClick={() => setRemove(true)}>Remove Account</button>
                    </div>}
                </div>
            }
        </div>
    )
}

function Office(props) {

    return (
        <div className="company-settings-container">
            <LogoUpload 
                cid={props.cid} firebase={props.firebase} logo={props.logo}
            />
        </div>
    )
}

function LogoUpload(props) {
    function sendBackLink(link) {
        props.firebase.apiCompany(props.cid)
        .child('files')
        .update({
            logo: link,
        });
    };

    function uploadImage(e) {
        if (e.target.files[0]) {
            const image = e.target.files[0];
            props.firebase.uploadImage(props.cid, image, sendBackLink);
        }
    }

    return (
        <div className="company-logo-container">
            <ImageUpload link={props.logo} imageClass='image-company-logo-settings' imageContainer='container-image-company-logo-settings' 
                function={uploadImage}
            />
        </div>
    )
}