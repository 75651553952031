import React, { Component } from 'react';
import moment from 'moment';

import {CastingSessionItem} from './CastingSessionItem';
import CastingSessionQuickstart from './CastingSessionQuickstart';
import FilterDropdown from '../Common/FilterDropdown';

import './home.css';

export default class CastingSessionsList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            date: moment().format('MM/DD/YYYY'),
            dateFormat: moment().format('MM/DD/YYYY'),
            sessions: '',
            sessionFilter: 'today',
            search: '',   
            statusFilter: 'All',
        }
    }

    shouldComponentUpdate(nextState, state) {
        return nextState.allSessions !== this.state.allSessions;
    }

    componentDidMount() {
        var date = moment().format('MM/DD/YYYY');

        this.props.firebase.userCastingSessions(this.props.uid)
        .on('value', snapshot => {
            var filter = this.state.sessionFilter;
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({ ...o[key], id: key }));
                if (l) { 
                    var sessions = l.filter(f => { return f.date === date });
                    this.setState({ 
                        allSessions: l, 
                        sessions,
                    })
                    this._filterLists(l, filter);
                }
                else { this.setState({ allSessions: '', sessions: '' })};
            } else { this.setState({ allSessions: '', sessions: '' })};
        });

    }

    componentWillUnmount() {
        this.props.firebase.userCastingSessions(this.props.uid).off();
    }

    _filterLists = (allSessions, filter) => {
        if (allSessions) {
            if (filter === 'today') {
                var sessions = allSessions.filter(f => {
                    return f.date === this.state.date;
                })
                if (sessions.length > 0) {
                    this._prepExpandSession()
                    this._prepSetSectionLength(sessions.length);
                } else {
                    this.props._minimizeSessions()
                }
                var sort = sessions.sort((a, b) => (a.wrapped && !b.wrapped) ? 1 : -1);
                this.setState({
                    sessions: sort,
                    sessionFilter: 'today',
                })
            } else {
                if (filter === 'all') {
                    if (allSessions) {
                        this._prepExpandSession()
                        this._prepSetSectionLength(allSessions.length);
                    }
                    this.setState({
                        sessions: allSessions,
                    })
                }
            }
           
        }
    };

    _filterListsByToday = () => {
        if (this.state.allSessions) {
            var sessions = this.state.allSessions.filter(f => {
                return f.date === moment(this.state.date).format('MM/DD/YYYY');
            });
            if (sessions.length > 0) {
                this._prepExpandSession();
                this._prepSetSectionLength(sessions.length);
            } else {
                this.props._minimizeSessions()
                this._prepSetSectionLength(sessions.length);
            }
            this.setState({
                sessions,
                sessionFilter: 'today',
            })
        }
    }

    _filterStatusByAll = () => {
        if (this.state.sessions) {
            this.setState({
                sessions: this.state.sessions,
            })
        }
    }

    _filterStatusByUpcoming = () => {
        if (this.state.sessions) {
            var sessions = this.state.sessions.filter(f => {
                return f.prepped === false;
            })
            this.setState({
                sessionTypes: sessions,
            })
        }
    }

    _filterStatusByActive = () => {
        if (this.state.sessions) {
            var sessions = this.state.sessions.filter(f => {
                return f.prepped === true;
            })
            this.setState({
                sessionTypes: sessions,
            })
        }
    }

    _filterStatusByCompleted = () => {
        if (this.state.sessions) {
            var sessions = this.state.sessions.filter(f => {
                return f.wrapped === true;
            })
            this.setState({
                sessionTypes: sessions,
            })
        }
    };

    _filterListsByAll = () => {

    if (this.state.allSessions) {
        this.setState({
           sessions: this.state.allSessions,
           sessionFilter: 'all',
        });
        if (this.state.allSessions.length > 0) {
            this._prepExpandSession()
            this._prepSetSectionLength(this.state.allSessions.length);
        } else {
            this.props._minimizeSessions();
            this._prepSetSectionLength(0);
        };
    }
    };

    _prepExpandSession = () => {
        this.props._expandSessions();
    }

    _prepSetSectionLength = (length) => {
        this.props._setSectionLength(length)
    };

    _filterListsByDraft = () => {

        if (this.state.allSessions) {
        var sessions = this.state.allSessions.filter(f => {
            return f.draft
        });
        if (sessions.length > 0) {
            this.props._expandSessions();
            this._prepSetSectionLength(sessions.length);
        } else {
            this.props._minimizeSessions()
        };
        this.setState({
            sessions,
            sessionFilter: 'drafts',
        })
    }
    }


    _prepSession = (session) => {
        var time = moment().format('hh:mm a');
        var uo = session.users;
        var ro = session.roles;
        var users = Object.keys(uo).map(key => ({
            ...uo[key], id: key,
        }));
        var groupKey = session.groupKey;
        var roles = Object.keys(ro).map(key => ({
            ...ro[key], id: key,
        }));
        this.props.firebase.prepSession(this.props.uid, session, users, roles, time, groupKey);
    }

    _activateSession = (session) => {
        var time = moment().format('hh:mm a');
        var groupKey = session.groupKey;
        this.props.firebase.activateSession(this.props.uid, session.id, time, groupKey);
    }

    _completeCastingSession = (session) => {
        var time = moment().format('hh:mm a');
        var ro = session.roles;
        var roles = Object.keys(ro).map(key => ({
            ...ro[key], id: key,
        }));
        var uo = session.users;
        var users = Object.keys(uo).map(key => ({
            ...uo[key], id: key,
        }));
        this.props.firebase.completeCastingSession(this.props.uid, session, users, roles, time);
    };

    _pauseSession = (session) => {
        var time = moment().format('hh:mm a');
        var ro = session.roles;
        var roles = Object.keys(ro).map(key => ({
            ...ro[key], id: key,
        }));
        var uo = session.users;
        var users = Object.keys(uo).map(key => ({
            ...uo[key], id: key,
        }));
        this.props.firebase.pauseCastingSession(this.props.uid, session, users, roles, time);
    }

    _resumeSession = (session) => {
        var time = moment().format('hh:mm a');
        var uo = session.users;
        var ro = session.roles;
        var users = Object.keys(uo).map(key => ({
            ...uo[key], id: key,
        }));
        var groupKey = session.groupKey;
        var roles = Object.keys(ro).map(key => ({
            ...ro[key], id: key,
        }));
        this.props.firebase.resumeCastingSession(this.props.uid, session, users, roles, time, groupKey);
    }

    _handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
       
        if (this.state.search.length > 0) {
            var filteredSessions = this.state.allSessions.filter(sess => {
                var rolesArray = [];
                var roles = sess.roles ? Object.keys(sess.roles).map(key => ({
                    ...sess.roles[key],
                    id: key,
                })) : '';
                if (roles) {
                    roles.map(r => {
                        if (r.name.toLowerCase().includes(this.state.search.toLowerCase())) {
                            return rolesArray.push({
                                role: r.name.toLowerCase(),
                            })
                        }
                    });
                };
                if (sess.project && sess.project.label && sess.name) {
                    return (
                        sess.name.toLowerCase().includes(this.state.search.toLowerCase()) ||
                        sess.project.label.toLowerCase().includes(this.state.search.toLowerCase()) ||
                        rolesArray.length > 0
                    )
                } else if (sess.project && sess.project.label && !sess.name) {
                    return (
                        sess.project.label.toLowerCase().includes(this.state.search.toLowerCase()) ||
                        rolesArray.length > 0
                    )
                } else if (!sess.project && !sess.project.label && sess.name) {
                    return (
                        sess.name.toLowerCase().includes(this.state.search.toLowerCase()) ||
                        rolesArray.length > 0
                    )
                } else {
                    return (
                        rolesArray.length > 0
                    )
                }
            });
            if (filteredSessions.length > 0) {
                this._prepExpandSession()
                this._prepSetSectionLength(filteredSessions.length);
            } else {
                this.props._minimizeSessions()
            }
            this.setState({
                sessions: filteredSessions,
                sessionFilter: '',
            });
        } else {
            if (this.state.allSessions.length > 0) {
                this._prepExpandSession()
                this._prepSetSectionLength(this.state.allSessions.length);
            } else {
                this.props._minimizeSessions()
            }
            this.setState({
                sessions: this.state.allSessions,
            })
        }
    };

    _setSessionFilter = (value) => {
        if (value === 'Today') {
            this._filterListsByToday()
        } else if (value === 'All') {
            this._filterListsByAll()
        } else if (value === 'Draft') {
            this._filterListsByDraft()
        }
    };

    _setStatusFilter = (value) => {
        this.setState({
            statusFilter: value,
        })
        if (value === 'All') {
            this._filterStatusByAll()
        } else if (value === 'Upcoming') {
            this._filterStatusByUpcoming()
        } else if (value === 'Active') {
            this._filterStatusByActive()
        } else if (value === 'Completed') {
            this._filterStatusByCompleted()
        }
    }

    addCheckInToSession = (session) => {
        var c = this.props.checkInToDrop;
        this.props.firebase.completed(
            c.checkInName,
            c.checkInProject,
            c.checkInRole,
            c.checkInRepCompany,
            session.room.value,
            c.id,
            c.projectId,
            c.roleId,
            c.checkInTime,
            c.checkInDay,
            moment().format('hh:mma'),
            moment().format('hh:mma'),
            '0',
            '0',
            '0',
            this.props.uid,
            '0',
            '0',
        );
        this.props.firebase.removeCheckInQ(c.id).remove();
        this.props.firebase.db.ref('user-check-ins/' + this.props.uid + '/' + c.id).remove();
        this.props.firebase.addSubjectToSessionTalent(
            this.props.uid, 
            c.id,
            c.checkInName,
            c.checkInProject,
            c.checkInRole,
            c.projectId,
            c.roleId,
            c.checkInRepCompany,
            session.groupKey,
        );
        this.props.firebase.addSubjectToSession(
            this.props.uid,
            session.id,
            c.id,
            c.checkInName,
            c.checkInRepCompany,
            moment().format('hh:mma'),
            c.roleId,
            c.checkInRole,
            c.checkInTime,
        );
        this.props.firebase.selectCastingSessionTalent(this.props.uid, session.id, c.id).update({wrap: moment().format('hh:mma')});
        this.props.removeManualDropStatus();
    }

    render() {

        var sessions = this.state.sessions ? this.state.sessions : '';
        if (this.state.sessionTypes) {
            sessions = this.state.sessionTypes;
        }

        return (
        
            !this.state.allSessions ? 
            <div className="todays-sessions-list-layout">
            <div className="null-text">No sessions created</div>
            </div>
            :
            <div className="todays-sessions-list-layout">
                <div className="inline-block">
                <FilterDropdown 
                      options={['Today', 'All', 'Drafts']}
                      function={this._setSessionFilter}
                />
                </div>
                <div className="filter-sessions-layout">
                    <div className="filter-session-search-bar">
                        <input className="session-search-bar" autoComplete="off" onChange={this._handleChange.bind(this)} value={this.state.search} name="search" placeholder="Search by name, project or role..." />
                    </div>
                </div>
                <div className="todays-sessions-list"> 
                {sessions.length > 0 ?
                    sessions.map((item, index) => (
                        <CastingSessionItem 
                            firebase={this.props.firebase}
                            _resumeSession={this._resumeSession}
                            _pauseSession={this._pauseSession}
                            _editSessionModal={this.props._editSessionModal} 
                            key={item.id} item={item} 
                            _prepSession={this._prepSession} 
                            _completeCastingSession={this._completeCastingSession} 
                            _activateSession={this._activateSession} 
                            _openSessionModal={this.props._openSessionModal}     
                            uid={this.props.uid}
                            manualDrop={this.props.manualDrop}
                            checkInToDrop={this.props.checkInToDrop}
                            addCheckInToSession={this.addCheckInToSession}
                        />
                    ))
                    :
                    <div className="null-text">No sessions on the schedule</div>
                }
                </div>
            </div>

        )
    }
}