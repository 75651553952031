import React, { Component } from 'react';
import { TalentScheduleList } from './TalentSchedule/TalentScheduleList';

import moment from 'moment';

export default class SessionTalentSchedule extends Component {

    constructor(props) {
        super(props);

        this.state = {
            listKey: '',
            exists: false,
            increment: '',
            slots: '',
            slotTimes: '',
            list: '',
            newTalent: '',
            sessKey: '',
            sessSlotTimes: '',
        }
    };

    componentWillReceiveProps(nextProps, props) {
        if (nextProps.state.sessSlotTimes !== this.props.state.sessSlotTimes) {
            this.setState({
                sessSlotTimes: nextProps.state.sessSlotTimes
            });
        };
    };

    componentDidMount() {
        var p = this.props;
        var pp = this.props.props;
        var ps = this.props.state;

        this.setState({
            sessKey: this.props.state.sessKey,
            sessSlotTimes: this.props.state.sessSlotTimes ? this.props.state.sessSlotTimes : '',
        });

    };

    _generateSlots = () => {
        var p = this.props;
        var pp = this.props.props;
        var ps = this.props.state;
        
        var startTime = ps.hr1 + ':' + ps.min1 + ps.ampm1;
        var wrapTime = ps.hr2 + ':' + ps.min2 + ps.ampm2;
        var difference = this._timeDifference(wrapTime, startTime);
        var slots = difference/this.state.increment;

        this._listSlotTimes(slots, 0, [], startTime);
    }
    
    _timeDifference = (time1, time2) => {
        var diff = moment(time1, "hh:mma").diff(moment(time2, "hh:mma"));
        var time = diff > 60 ? diff / 1000 : 60;
        var mins = time / 60;
        return mins;
    };

    _listSlotTimes = (slots, counter, slotTimes, startTime) => {
        var pp = this.props.props;
        
        var start = moment(startTime, 'hh:mma');
        var increment = this.state.increment;
        var additional = counter * increment;
        if (counter < slots) {
            slotTimes.push({
                time: start.add(Number(additional), 'minutes').format('hh:mma'),
                name: '',
                ref: '',
                status: 'Open',
                role: '',
            });
            counter++;
            this._listSlotTimes(slots, counter, slotTimes, startTime);
        } else {
            console.log('');
        };
        if (counter === slots) { 
            pp.firebase.addSlotTimesToSession(pp.uid, this.state.sessKey, slotTimes);
        };
    };


    _onChange = (e) => {
        this.setState({ [e.target.name]:e.target.value })
    };

    _onEnterTalent = (event) => {
        if (event.keyCode === 13 && event.shiftKey === false) {
            event.preventDefault();
            this._addTalentToList(event);
          }
    };

    _addTalentToList = (event) => {
        var slots = Object.keys(this.state.sessSlotTimes).map(key => ({ ...this.state.sessSlotTimes[key], id: key }));
        var available = slots.filter(f => f.status === 'Open');
        var slotTime = available[0].time;
        var slotId = available[0].id;
        this.props.props.firebase.addTalentToSessionSchedule(this.props.props.uid, this.props.state.sessKey, this.state.newTalent, slotTime, '');
        this.props.props.firebase.addTalentToSessionSlot(this.props.props.uid, this.props.state.sessKey, this.state.newTalent, slotId, '');
        this.setState({
            newTalent: ''
        });

    };

    render() {

        var s = this.state;
        var ps = this.props.state;
        var p = this.props;
        var pp = this.props.props;

        return (

            <div>
                <div className="go-back-btn" onClick={this.props._backToMain}>Go back</div>
                <div className="session-talent-schedule-header">
                    <div className="tal-sche-details-txt">{ps.sessionName}</div>
                    <br />
                    <div className="tal-sche-details-txt">{ps.hr1}:{ps.min1}{ps.ampm1}</div>
                    <div className="tal-sche-details-txt">-</div>
                    <div className="tal-sche-details-txt">{ps.hr2}:{ps.min2}{ps.ampm2}</div>
                </div>
                <div className="session-talent-schedule-body">
                    {
                        this.state.sessSlotTimes ? 
                        <TalentScheduleList s={s} ps={ps} p={p} pp={pp} _onChange={this._onChange} _onEnterTalent={this._onEnterTalent} />
                        :
                        <div>
                            <div>How long will each audition be?</div>
                            <input className="session-talent-increment-input" value={this.state.increment} type="number" name="increment" onChange={this._onChange.bind(this)} placeholder="15" /> minutes.
                            <button className="generate-schedule-btn" onClick={this._generateSlots}>Generate Times</button>
                        </div>
                    }
                </div>
            </div>

        )
    };
};