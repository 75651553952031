import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { withAuthorization, withAuthentication } from '../Session';
import { compose } from 'recompose';

import SessionNav from './Nav/SessionNav';
import SessionContent from './Content/SessionContent';
import SessionSummary from '../Modals/SessionSummary/SessionSummary';

import './session.css';

function SessionView(props) {

    const [uid, setUid] = useState('');
    const [cid, setCid] = useState('');
    const [username, setUsername] = useState('');
    const [id, setId] = useState('');
    const [sessObj, setSessObj] = useState('');
    const [cname, setCname] = useState('');
    const [summary, setSummary] = useState(false);

    const [mobile, setMobile] = useState(false);
    const [fadeInContent, setFadeInContent] = useState(false);

    useEffect(() => {
        var width = window.innerWidth;
        if (width < 560) {
            setMobile(true);
        }

        var fadeSessionTimer = setTimeout(() => {
            setFadeInContent(true);
        }, 200);
        return (() => clearTimeout(fadeSessionTimer));
    }, [])

    useEffect(() => { 
        props.firebase.auth.onAuthStateChanged(
            authUser => {
              if (authUser) {
                  setUid(authUser.uid);
                  props.firebase.apiUser(authUser.uid)
                  .once('value', snapshot => {
                      var o = snapshot.val();
                      var c = o.company;
                      var cname = o.companyName;
                      var u = o.name;
                      setCid(c);
                      setCname(cname);
                      setUsername(u);
                      setUid(authUser.uid);
                  })
              }
              if (authUser) {
                var URL = window.location.pathname.replace('/session/', '');
                setId(URL);
                props.firebase.apiSession(URL)
                .on('value', snapshot => {
                    if (snapshot.exists()) {
                        setSessObj(snapshot.val())
                        var sn = snapshot.val();
                        if (sn.complete && !summary) {
                            setSummary(true);
                        } else if (summary) {
                            setSummary(false);
                        }
                    };
                });
            }}
        )
    }, []);

    const [sum, setSum] = useState('');
    const [fadeModalIn, setFadeModalIn] = useState(false);

    function prepSummary() {
        if (sum) {
            setFadeModalIn(false);
            var fadeOutTimer = setTimeout(() => {
                setSum('');
            }, 500);
            return (() => clearTimeout(fadeOutTimer));
        } else {
            setSum(id);
            var fadeInTimer = setTimeout(() => {
                setFadeModalIn(true);
            }, 100);
            return (() => clearTimeout(fadeInTimer));
        }
    }

    return (
        <div className="abolute-0">
        <Session id={id} 
            uid={uid} 
            cid={cid} 
            username={username} 
            sessObj={sessObj} 
            firebase={props.firebase} 
            history={props.history} 
            cname={cname}
            summary={summary}
            mobile={mobile}
            prepSummary={prepSummary}
            fadeInContent={fadeInContent}
        />
        {
            sum ?
            <div className={fadeModalIn ? "fade-in" : "fade-out"}>
            <SessionSummary session={sessObj} firebase={props.firebase} setSessSum={prepSummary} from="session-view" />
            </div>
            :
            null 
        }

        </div>
    )
};

function Session(props) {

    const [onlineOpen, setOnlineOpen] = useState(false);
    const [push, setPush] = useState('');

    function pushScroll(index) {
        if (index === 0) {
            setPush('first');
        } else {
            setPush(index);
        }
        var pushTimer = setTimeout(() => {
            setPush('');
        }, 1000);
        return (() => clearTimeout(pushTimer));
    };

    const [mobileNav, setMobileNav] = useState(true);

    const [open, setOpen] = useState(true);
    const [pushOpen, setPushOpen] = useState(false);

    function pushOpenFromWrap() {
        setPushOpen(true);
        var pushOpenTimer = setTimeout(() => {
            setPushOpen(false);
        }, 500)
        return (() => clearTimeout(pushOpenTimer));
    };
  

    const [removeToggle, setRemoveToggle] = useState(false);

    return (
        <div className={props.mobile ? "s-page-layout-mobile color-bg" : "s-page-layout"}>
            <SessionNav 
                firebase={props.firebase} 
                history={props.history}
                id={props.id} 
                uid={props.uid} 
                username={props.username}
                cid={props.cid} 
                sessObj={props.sessObj} 
                cname={props.cname}
                onlineOpen={onlineOpen}
                setOnlineOpen={setOnlineOpen}
                schedule={props.sessObj.schedule}
                pushScroll={pushScroll}
                mobile={props.mobile}
                prepSummary={props.prepSummary}
                setOpen={setOpen}
                removeToggle={removeToggle}
                pushOpen={pushOpen}
                fadeInContent={props.fadeInContent}
            />
            <SessionContent 
                username={props.username} 
                uid={props.uid}
                firebase={props.firebase} 
                cid={props.cid} 
                id={props.id} 
                focus={props.sessObj.focus} 
                subjects={props.sessObj.subjects} 
                schedule={props.sessObj.schedule}
                positions={props.sessObj.positions}
                summary={props.summary}
                onlineOpen={onlineOpen}
                date={props.sessObj.date}
                cname={props.cname}
                push={push}
                mobile={props.mobile}
                open={open}
                setRemoveToggle={setRemoveToggle}
                pushOpenFromWrap={pushOpenFromWrap}
                fadeInContent={props.fadeInContent}
            />
        </div>
    );
};

const condition = authUser => !!authUser;

const SessionCompose = compose(
    withFirebase,
    withRouter,
    withAuthentication
    )(Session);

export default withAuthorization(condition)(SessionView);
  
export { Session };