const times = [

    { hr: '00', min: '00', num: 1},
    { hr: '00', min: '05', num: 2},
    { hr: '00', min: '10', num: 3},
    { hr: '00', min: '15', num: 4},
    { hr: '00', min: '20', num: 5},
    { hr: '00', min: '25', num: 6},
    { hr: '00', min: '30', num: 7},
    { hr: '00', min: '35', num: 8},
    { hr: '00', min: '40', num: 9},
    { hr: '00', min: '45', num: 10},
    { hr: '00', min: '50', num: 11},
    { hr: '00', min: '55', num: 12},

    { hr: '1', min: '00', num: 13},
    { hr: '1', min: '05', num: 14},
    { hr: '1', min: '10', num: 15},
    { hr: '1', min: '15', num: 16},
    { hr: '1', min: '20', num: 17},
    { hr: '1', min: '25', num: 18},
    { hr: '1', min: '30', num: 19},
    { hr: '1', min: '35', num: 20},
    { hr: '1', min: '40', num: 21},
    { hr: '1', min: '45', num: 22},
    { hr: '1', min: '50', num: 23},
    { hr: '1', min: '55', num: 24},

    { hr: '2', min: '00', num: 25},
    { hr: '2', min: '05', num: 26},
    { hr: '2', min: '10', num: 27},
    { hr: '2', min: '15', num: 28},
    { hr: '2', min: '20', num: 29},
    { hr: '2', min: '25', num: 30},
    { hr: '2', min: '30', num: 31},
    { hr: '2', min: '35', num: 32},
    { hr: '2', min: '40', num: 33},
    { hr: '2', min: '45', num: 34},
    { hr: '2', min: '50', num: 35},
    { hr: '2', min: '55', num: 36},

    { hr: '3', min: '00', num: 37},
    { hr: '3', min: '05', num: 38},
    { hr: '3', min: '10', num: 39},
    { hr: '3', min: '15', num: 40},
    { hr: '3', min: '20', num: 41},
    { hr: '3', min: '25', num: 42},
    { hr: '3', min: '30', num: 43},
    { hr: '3', min: '35', num: 44},
    { hr: '3', min: '40', num: 45},
    { hr: '3', min: '45', num: 46},
    { hr: '3', min: '50', num: 47},
    { hr: '3', min: '55', num: 48},

    { hr: '4', min: '00', num: 49},
    { hr: '4', min: '05', num: 50},
    { hr: '4', min: '10', num: 51},
    { hr: '4', min: '15', num: 52},
    { hr: '4', min: '20', num: 53},
    { hr: '4', min: '25', num: 54},
    { hr: '4', min: '30', num: 55},
    { hr: '4', min: '35', num: 56},
    { hr: '4', min: '40', num: 57},
    { hr: '4', min: '45', num: 58},
    { hr: '4', min: '50', num: 59},
    { hr: '4', min: '55', num: 60},

    { hr: '5', min: '00', num: 61},
    { hr: '5', min: '05', num: 62},
    { hr: '5', min: '10', num: 63},
    { hr: '5', min: '15', num: 64},
    { hr: '5', min: '20', num: 65},
    { hr: '5', min: '25', num: 66},
    { hr: '5', min: '30', num: 67},
    { hr: '5', min: '35', num: 68},
    { hr: '5', min: '40', num: 69},
    { hr: '5', min: '45', num: 70},
    { hr: '5', min: '50', num: 71},
    { hr: '5', min: '55', num: 72},

    { hr: '6', min: '00', num: 73},
    { hr: '6', min: '05', num: 74},
    { hr: '6', min: '10', num: 75},
    { hr: '6', min: '15', num: 76},
    { hr: '6', min: '20', num: 77},
    { hr: '6', min: '25', num: 78},
    { hr: '6', min: '30', num: 79},
    { hr: '6', min: '35', num: 80},
    { hr: '6', min: '40', num: 81},
    { hr: '6', min: '45', num: 82},
    { hr: '6', min: '50', num: 83},
    { hr: '6', min: '55', num: 84},

    { hr: '7', min: '00', num: 85},
    { hr: '7', min: '05', num: 86},
    { hr: '7', min: '10', num: 87},
    { hr: '7', min: '15', num: 88},
    { hr: '7', min: '20', num: 89},
    { hr: '7', min: '25', num: 90},
    { hr: '7', min: '30', num: 91},
    { hr: '7', min: '35', num: 92},
    { hr: '7', min: '40', num: 93},
    { hr: '7', min: '45', num: 94},
    { hr: '7', min: '50', num: 95},
    { hr: '7', min: '55', num: 96},

    { hr: '8', min: '00', num: 97},
    { hr: '8', min: '05', num: 98},
    { hr: '8', min: '10', num: 99},
    { hr: '8', min: '15', num: 100},
    { hr: '8', min: '20', num: 101},
    { hr: '8', min: '25', num: 102},
    { hr: '8', min: '30', num: 103},
    { hr: '8', min: '35', num: 104},
    { hr: '8', min: '40', num: 105},
    { hr: '8', min: '45', num: 106},
    { hr: '8', min: '50', num: 107},
    { hr: '8', min: '55', num: 108},

    { hr: '9', min: '00', num: 109},
    { hr: '9', min: '05', num: 110},
    { hr: '9', min: '10', num: 111},
    { hr: '9', min: '15', num: 112},
    { hr: '9', min: '20', num: 113},
    { hr: '9', min: '25', num: 114},
    { hr: '9', min: '30', num: 115},
    { hr: '9', min: '35', num: 116},
    { hr: '9', min: '40', num: 117},
    { hr: '9', min: '45', num: 118},
    { hr: '9', min: '50', num: 119},
    { hr: '9', min: '55', num: 120},

    { hr: '10', min: '00', num: 121},
    { hr: '10', min: '05', num: 122},
    { hr: '10', min: '10', num: 123},
    { hr: '10', min: '15', num: 124},
    { hr: '10', min: '20', num: 125},
    { hr: '10', min: '25', num: 126},
    { hr: '10', min: '30', num: 127},
    { hr: '10', min: '35', num: 128},
    { hr: '10', min: '40', num: 129},
    { hr: '10', min: '45', num: 130},
    { hr: '10', min: '50', num: 131},
    { hr: '10', min: '55', num: 132},

    { hr: '11', min: '00', num: 133},
    { hr: '11', min: '05', num: 134},
    { hr: '11', min: '10', num: 135},
    { hr: '11', min: '15', num: 136},
    { hr: '11', min: '20', num: 137},
    { hr: '11', min: '25', num: 138},
    { hr: '11', min: '30', num: 139},
    { hr: '11', min: '35', num: 140},
    { hr: '11', min: '40', num: 141},
    { hr: '11', min: '45', num: 142},
    { hr: '11', min: '50', num: 143},
    { hr: '11', min: '55', num: 144},

    { hr: '12', min: '00', num: 145},
    { hr: '12', min: '05', num: 146},
    { hr: '12', min: '10', num: 147},
    { hr: '12', min: '15', num: 148},
    { hr: '12', min: '20', num: 149},
    { hr: '12', min: '25', num: 150},
    { hr: '12', min: '30', num: 151},
    { hr: '12', min: '35', num: 152},
    { hr: '12', min: '40', num: 153},
    { hr: '12', min: '45', num: 154},
    { hr: '12', min: '50', num: 155},
    { hr: '12', min: '55', num: 156},

    { hr: '13', min: '00', num: 157},
    { hr: '13', min: '05', num: 158},
    { hr: '13', min: '10', num: 159},
    { hr: '13', min: '15', num: 160},
    { hr: '13', min: '20', num: 161},
    { hr: '13', min: '25', num: 162},
    { hr: '13', min: '30', num: 163},
    { hr: '13', min: '35', num: 164},
    { hr: '13', min: '40', num: 165},
    { hr: '13', min: '45', num: 166},
    { hr: '13', min: '50', num: 167},
    { hr: '13', min: '55', num: 168},

    { hr: '14', min: '00', num: 169},
    { hr: '14', min: '05', num: 170},
    { hr: '14', min: '10', num: 171},
    { hr: '14', min: '15', num: 172},
    { hr: '14', min: '20', num: 173},
    { hr: '14', min: '25', num: 174},
    { hr: '14', min: '30', num: 175},
    { hr: '14', min: '35', num: 176},
    { hr: '14', min: '40', num: 177},
    { hr: '14', min: '45', num: 178},
    { hr: '14', min: '50', num: 179},
    { hr: '14', min: '55', num: 180},

    { hr: '15', min: '00', num: 181},
    { hr: '15', min: '05', num: 182},
    { hr: '15', min: '10', num: 183},
    { hr: '15', min: '15', num: 184},
    { hr: '15', min: '20', num: 185},
    { hr: '15', min: '25', num: 186},
    { hr: '15', min: '30', num: 187},
    { hr: '15', min: '35', num: 188},
    { hr: '15', min: '40', num: 189},
    { hr: '15', min: '45', num: 190},
    { hr: '15', min: '50', num: 191},
    { hr: '15', min: '55', num: 192},

    { hr: '16', min: '00', num: 193},
    { hr: '16', min: '05', num: 194},
    { hr: '16', min: '10', num: 195},
    { hr: '16', min: '15', num: 196},
    { hr: '16', min: '20', num: 197},
    { hr: '16', min: '25', num: 198},
    { hr: '16', min: '30', num: 199},
    { hr: '16', min: '35', num: 200},
    { hr: '16', min: '40', num: 201},
    { hr: '16', min: '45', num: 202},
    { hr: '16', min: '50', num: 203},
    { hr: '16', min: '55', num: 204},

    { hr: '17', min: '00', num: 205},
    { hr: '17', min: '05', num: 206},
    { hr: '17', min: '10', num: 207},
    { hr: '17', min: '15', num: 208},
    { hr: '17', min: '20', num: 209},
    { hr: '17', min: '25', num: 210},
    { hr: '17', min: '30', num: 211},
    { hr: '17', min: '35', num: 212},
    { hr: '17', min: '40', num: 213},
    { hr: '17', min: '45', num: 214},
    { hr: '17', min: '50', num: 215},
    { hr: '17', min: '55', num: 216},

    { hr: '18', min: '00', num: 217},
    { hr: '18', min: '05', num: 218},
    { hr: '18', min: '10', num: 219},
    { hr: '18', min: '15', num: 220},
    { hr: '18', min: '20', num: 221},
    { hr: '18', min: '25', num: 222},
    { hr: '18', min: '30', num: 223},
    { hr: '18', min: '35', num: 224},
    { hr: '18', min: '40', num: 225},
    { hr: '18', min: '45', num: 226},
    { hr: '18', min: '50', num: 227},
    { hr: '18', min: '55', num: 228},

    { hr: '19', min: '00', num: 229},
    { hr: '19', min: '05', num: 230},
    { hr: '19', min: '10', num: 231},
    { hr: '19', min: '15', num: 232},
    { hr: '19', min: '20', num: 233},
    { hr: '19', min: '25', num: 234},
    { hr: '19', min: '30', num: 235},
    { hr: '19', min: '35', num: 236},
    { hr: '19', min: '40', num: 237},
    { hr: '19', min: '45', num: 238},
    { hr: '19', min: '50', num: 239},
    { hr: '19', min: '55', num: 240},

    { hr: '20', min: '00', num: 241},
    { hr: '20', min: '05', num: 242},
    { hr: '20', min: '10', num: 243},
    { hr: '20', min: '15', num: 244},
    { hr: '20', min: '20', num: 245},
    { hr: '20', min: '25', num: 246},
    { hr: '20', min: '30', num: 247},
    { hr: '20', min: '35', num: 248},
    { hr: '20', min: '40', num: 249},
    { hr: '20', min: '45', num: 250},
    { hr: '20', min: '50', num: 251},
    { hr: '20', min: '55', num: 252},

    { hr: '21', min: '00', num: 253},
    { hr: '21', min: '05', num: 254},
    { hr: '21', min: '10', num: 255},
    { hr: '21', min: '15', num: 256},
    { hr: '21', min: '20', num: 257},
    { hr: '21', min: '25', num: 258},
    { hr: '21', min: '30', num: 259},
    { hr: '21', min: '35', num: 260},
    { hr: '21', min: '40', num: 261},
    { hr: '21', min: '45', num: 262},
    { hr: '21', min: '50', num: 263},
    { hr: '21', min: '55', num: 264},

    { hr: '22', min: '00', num: 265},
    { hr: '22', min: '05', num: 266},
    { hr: '22', min: '10', num: 267},
    { hr: '22', min: '15', num: 268},
    { hr: '22', min: '20', num: 269},
    { hr: '22', min: '25', num: 270},
    { hr: '22', min: '30', num: 271},
    { hr: '22', min: '35', num: 272},
    { hr: '22', min: '40', num: 273},
    { hr: '22', min: '45', num: 274},
    { hr: '22', min: '50', num: 275},
    { hr: '22', min: '55', num: 276},

    { hr: '23', min: '00', num: 277},
    { hr: '23', min: '05', num: 278},
    { hr: '23', min: '10', num: 279},
    { hr: '23', min: '15', num: 280},
    { hr: '23', min: '20', num: 281},
    { hr: '23', min: '25', num: 282},
    { hr: '23', min: '30', num: 283},
    { hr: '23', min: '35', num: 284},
    { hr: '23', min: '40', num: 285},
    { hr: '23', min: '45', num: 286},
    { hr: '23', min: '50', num: 287},
    { hr: '23', min: '55', num: 288},

]

export { times };