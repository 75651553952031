
























































































































































































































































import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import firebase from 'firebase';
import moment from 'moment';
import emailjs from 'emailjs-com';

import { sendCheckInNotification, sendTalentSendNotification } from '../PushNotifications/push-notifications';

const prodConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: "ani-one.firebaseapp.com",
    databaseURL: "https://ani-check-in.firebaseio.com",
    projectId: "ani-check-in",
    storageBucket: "gs://ani-check-in.appspot.com/",
    messagingSenderId: "188205107545",
    appId: "1:188205107545:web:fbac5d04e8edcf34",
    measurementId: "G-70DNQY9Z2P"
};

const devConfig = {
    apiKey: "AIzaSyC0xuM8zABYBiNkFTrAm-5sudMjZ96Nku8",
    authDomain: "ani-one.firebaseapp.com",
    databaseURL: "https://ani-check-in-dev.firebaseio.com",
    projectId: "ani-check-in-dev",
    storageBucket: "ani-check-in-dev.appspot.com",
    messagingSenderId: "643690105383",
    appId: "1:643690105383:web:248e8bfaa5f1fb7b1e5c02",
    measurementId: "G-32KB93KDCM"
};

const userConfig = {
    apiKey: "AIzaSyD8AM_kW09f4-Tkd0oGbY2A63ME0_gaYFE",
    authDomain: "ani-one.firebaseapp.com",
    databaseURL: "https://ani-one.firebaseio.com",
    projectId: "ani-one",
    appId: "1:862700636579:web:83a2f268b4db781a0eb216",
}

const config = process.env.NODE_ENV === 'production'
    ? prodConfig
    : devConfig;


var timer;
var userbase = firebase.initializeApp(userConfig, 'userbase');


class Firebase {

    constructor() {
        app.initializeApp(config);
        this.auth = 
        process.env.NODE_ENV === 'production' ? userbase.auth() : app.auth();
        this.db = app.database();
        this.storage = app.storage();
    }

    // *** Messaing API *** //

    /*askForPermissionToReceiveNotifications = async () => {
        try {
            const messaging = this.messaging;
            await messaging.requestPermission;
            const token = await messaging.getToken();
            console.log('token', token);
    
            return token;
        } catch(error) {
            console.log(error);
        }
    }*/

    // *** Auth API ***

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);


    doSignInWithEmailAndPassword = (email, password) => 
        this.auth.signInWithEmailAndPassword(email, password);

    doCreateEmployeeWithEmailAndPassword = (employeeEmail, employeePassword) => 
        this.auth.createUserWithEmailAndPassword(employeeEmail, employeePassword);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    /*newAccountCreate (email, company) {
        emailjs.send('sendgridanioffice', 'usersignup', {email: email, company: company}, 'user_UXrnDajVZDtBHREeuIA0z')
        .then(res => {
            console.log('Sent')
        })
        .catch(err => console.log('Fail', err));
    };
    */
    

    // *** User API ***

    user = uid => this.db.ref(`users/${uid}`);

    userRef = uid => this.db.ref('users/' + uid + '/groupRef');

    users = () => this.db.ref('users');

    userpin = (uid) => this.db.ref('users/' + uid + '/sec/');

    updateSettings = () => this.db.ref('users');

    userAlerts = (createdBy) => this.db.ref('users/' + createdBy + '/alerts');

    createAlert = (myId, roomId, roomName, checkInName, message, timestamp, talentId) => {
        var alertData = {
            userId: myId,
            message: message,
            timestamp: timestamp,
        };

        var userAlertData = {
            roomId: roomId,
            roomName: roomName,
            talent: checkInName,
            message: message,
            timestamp: timestamp,
            talentId: talentId,
            seen: false,
        }

        var newAlertKey = this.db.ref().child('alerts').push().key;

        var updates = {};
        updates['/alerts/' + newAlertKey] = alertData;
        updates['/users/' + myId + '/alerts/' + newAlertKey] = userAlertData;

        return this.db.ref().update(updates);
    }
    removeAlert = (myId, alertId) => this.db.ref('users/' + myId + '/alerts/' + alertId);

    sessionUser = () => this.db.ref('/session-users/');
    sessionUserSelect = (sessionKey) => this.db.ref('/session-users/' + sessionKey);
    newSessionUser = (newSessionUserKey, createdBy, sessionUserName, sessionUserId, time, day) => {
        
        var sessionUserData = {
            accountId: createdBy,
            sessionUserName: sessionUserName,
            sessionUserId: sessionUserId,
            time: time,
            day: day,

        }

        var updates = {};
        updates['/session-users/' + newSessionUserKey] = sessionUserData;

        return this.db.ref().update(updates);
    }

    // *** Employees API ***

    employees = () => this.db.ref('employees');
    
    removeEmployee = () => this.db.ref('employees');
    removeUserEmployee = (createdBy, employeeId) => this.db.ref('users/' + createdBy + '/employees');

    updateEmployeeEmail = (uid, eid, email) => this.db.ref('users/' + uid + '/employees/' + eid).update({ email: email });

    addEmployee = (employeeName, createdBy, createdAt) => {
        var employeeData = {
            employeeName: employeeName,
            createdBy: createdBy,
            createdAt: createdAt,
            email: '',
            notifyBy: '',
        };

        var userId = createdBy;

        var newEmployeeKey = this.db.ref().child('employees').push().key;

        var userEmployeeData = {
            employeeName: employeeName,
            email: '',
        }

        var employeeUpdates = {};
        employeeUpdates['/employees/' + newEmployeeKey] = employeeData;
        employeeUpdates['/users/' + userId + '/employees/' + newEmployeeKey] = userEmployeeData;

        return this.db.ref().update(employeeUpdates);
    }

    userEmployees = (createdBy) => this.db.ref('users/' + createdBy + '/employees/');
    userEmployee = (uid, eid) => this.db.ref('users/' + uid + '/employees/' + eid);

    addRoleProject = (roleName, project) => {

        var roleData = {
            roleName: roleName,
            project: project,
        };

        var newRoleKey = this.db.ref().child('roles').push().key;
        var roles = {};
        roles['/roles/' + newRoleKey] = roleData;

        return this.db.ref().update(roles);
    };

    employeeDevices = (uid, id) => this.db.ref('user-device-push/' + uid + '/' + id);

    employeeDevice = (token, id, uid, type) => {
        var pushKey = this.db.ref('user-device-push/' + uid + '/' + id).push().key;
        this.db.ref('user-device-push/' + uid + '/' + id + '/' + pushKey)
        .update({
            token: token,
            type: type,
        });
    };

    // *** Rooms API ***

    room = roomId => this.db.ref('rooms/' + roomId);

    roomActiveProjects = (roomId) => this.db.ref('rooms/' + roomId + '/activeProjects');
    roomActiveProject = (roomId, roomProjectId) => this.db.ref('rooms/' + roomId + '/activeProjects/' + roomProjectId);

    roomActiveReaders = (roomId) => this.db.ref('rooms/' + roomId + '/roomReaders');

    snoozeRoomReader = (roomId, roomReaderId) => {

        this.db.ref('rooms/' + roomId + '/roomReaders/' + roomReaderId).update({ isAway: true });
        this.db.ref('room-readers/' + roomReaderId).update({ isAway: true });
    };

    unsnoozeRoomReader = (roomId, roomReaderId) => {
        this.db.ref('rooms/' + roomId + '/roomReaders/' + roomReaderId).update({ isAway: false });
        this.db.ref('room-readers/' + roomReaderId).update({ isAway: false });
    };

    rooms = () => this.db.ref('rooms');

    userRooms = (createdBy) => this.db.ref('users/' + createdBy + '/rooms/');

    openRooms = () => this.db.ref('rooms');

    assignRoom = (roomToAssign) => this.db.ref('rooms/' + roomToAssign);

    assignUserRoom = (myId, roomToAssign) => this.db.ref('users/' + myId + '/rooms/' + roomToAssign);
    
    clearUserRoom = (myId, roomId) => this.db.ref('users/' + myId + '/rooms/' + roomId);

    addRoom = (roomName, createdBy) => {
        var roomData = {
            roomName: roomName,
            createdBy: createdBy,
            activeCheckIn: false,
            checkInName: '',
            checkInProject: '',
            checkInRole: '',
            isActive: false,
        };

        var userId = createdBy;

        var newRoomKey = this.db.ref().child('rooms').push().key;

        var userRoomData = {
            roomName: roomName,
            activeCheckIn: false,
            isActive: false,
            checkInName: '',
            checkInProject: '',
            checkInRole: '',
            roomId: newRoomKey,
        }

        var roomUpdates = {};
        roomUpdates['/rooms/' + newRoomKey] = roomData;
        roomUpdates['/users/' + userId + '/rooms/' + newRoomKey] = userRoomData;

        return this.db.ref().update(roomUpdates);
    };


    // *** For updating IsActive on Account Page *** //
    roomAttributes = (roomId) => this.db.ref('rooms/' + roomId);
    userRoomAttributes = (createdBy, roomId) => this.db.ref('users/' + createdBy + '/rooms/' + roomId);

    // ** Deleting Room ** //
    // remove from rooms
    // remove from users/uid/rooms
    // remove from room-readers/rid/ where roomId = roomId
    // remove from room-projects/rid/ where roomId = roomId
    // remove from room-roles/rid/ where roomId = roomid


    removeRoom = () => this.db.ref('rooms');
    removeUserRoom = (createdBy, roomId) => this.db.ref('users/' + createdBy + '/rooms');

    deleteRoomReadersFromDeleteRoom = (roomId) => this.db.ref('room-readers')
    .orderByChild('roomId')
    .equalTo((roomId))
    .once('value', snapshot => {
        if (snapshot.exists()) {
            var o = snapshot.val();
            var l = Object.keys(o).map(key => ({
                ...o[key],
                id: key,
            }))
            l.map(rr => {
                return this.db.ref('room-readers/' + rr.id).remove();
            })
        }
    });

    deleteRoomProjectsFromDeleteRoom = (roomId) => this.db.ref('room-projects')
    .orderByChild('roomId')
    .equalTo((roomId))
    .once('value', snapshot => {
        if (snapshot.exists()) {
            var o = snapshot.val();
            var l = Object.keys(o).map(key => ({
                ...o[key],
                id: key,
            }))

            l.map(rp => {
                return this.db.ref('room-projects/' + rp.id).remove();
            })
        }
    });

    deleteRoomRolesFromDeleteRoom = (roomId) => this.db.ref('room-roles')
    .orderByChild('roomId')
    .equalTo((roomId))
    .once('value', snapshot => {
        if (snapshot.exists()) {
            var o = snapshot.val();
            var l = Object.keys(o).map(key => ({
                ...o[key],
                id: key,
            }))

            l.map(rr => {
                return this.db.ref('room-roles/' + rr.id).remove();
            })
        }
    });


    // ** Deleting Employee ** //
    // remove from employees
    // from from /users/uid/employees
    // from room-readers where employeeId = id
    // from rooms/id/roomReaders where employeeId = id

    deleteRoomReader = (employeeId) => this.db.ref('room-readers')
    .orderByChild('employeeId')
    .equalTo((employeeId))
    .once('value', snapshot => {
        if (snapshot.exists()) {
            var o = snapshot.val();
            var l = Object.keys(o).map(key => ({
                ...o[key],
                id: key,
            }))

            l.map(rr => {
                return this.db.ref('room-readers/' + rr.id).remove();
            })

            l.map(rr => {
                return this.db.ref('rooms/' + rr.roomId + '/roomReaders/' + rr.id).remove();
            })
        }
    });



    // ** Room Modal API **

    roomReaders = () => this.db.ref('room-readers');

    roomProjects = () => this.db.ref('room-projects');

    newRoomProjectArray = (roomId, roomProjectName) => this.db.ref('room-projects/' + roomId + '/active-projs/' + [roomProjectName]);

    selectedRoomProjects = (roomToAssign) => this.db.ref('rooms/' + roomToAssign + '/activeProjects/');

    newRoomReader = (uid, name, employeeId, roomId, timeAdded) => {

        var readerData = {
            uid: uid,
            name: name, 
            employeeId: employeeId,
            roomId: roomId,
            timeAdded: timeAdded,
            timeRemoved: '',
            active: true,
        };

        var roomReaderData = {
            uid: uid,
            name: name,
            employeeId: employeeId,
        }

        var newReaderKey = this.db.ref().child('room-readers').push().key;

        var updates = {};
        updates['/room-readers/' + newReaderKey] = readerData;
        updates['/rooms/' + roomId + '/roomReaders/' + newReaderKey] = roomReaderData;

        return this.db.ref().update(updates);
    };

    /*newRoomReader = ( roomReaderName, roomReaderId, roomId, roomReaderActive, timeReaderAdded, timeReaderRemoved  ) => {

        var roomReaderData = {
            roomReaderName: roomReaderName,
            roomReaderId: roomReaderId,
            roomId: roomId,
            roomReaderActive: true,
            timeReaderAdded: timeReaderAdded,
            timeReaderRemoved: '',
        };

        var roomReaderRoomData = {
            roomReaderName: roomReaderName,
        }

        var newRoomReaderKey = this.db.ref().child('room-readers').push().key;

        var updates = {};
        updates['/room-readers/' + newRoomReaderKey] = roomReaderData;
        updates['/rooms/' + roomId + '/roomReaders/' + newRoomReaderKey] = roomReaderRoomData;

        return this.db.ref().update(updates);
    };*/


    roomProject = (roomProjectId) => this.db.ref().child('room-projects/' + roomProjectId);

    newRoomProject = ( uid, name, projectId, roomId, timeAdded ) => {

        var projectData = {
            uid: uid,
            name: name,
            projectId: projectId,
            roomId: roomId,
            timeAdded: timeAdded,
            timeRemoved: '',
            active: true,
        };

        var roomProjectData = {
            uid: uid,
            name: name,
            projectId: projectId,
        };

        var newProjectKey = this.db.ref().child('room-projects').push().key;

        var updates = {};
        updates['/room-projects/' + newProjectKey] = projectData;
        updates['/rooms/' + roomId + '/activeProjects/' + newProjectKey] = roomProjectData;

        return this.db.ref().update(updates);
    };

    archiveRoomProject = (uid, name, projectId, roomId, timeAdded, timeRemoved) => {

        var projectData = {
            uid: uid,
            name: name,
            projectId: projectId,
            roomId: roomId,
            timeAdded: timeAdded,
            timeRemoved: timeRemoved,
            active: false,
        };

        var newProjectArchiveKey = this.db.ref().child('room-projects-archive').push().key;

        var updates = {};
        updates['/room-projects-archive/' + newProjectArchiveKey] = projectData;

        return this.db.ref().update(updates);
    }

    roomRoles = () => this.db.ref().child('room-roles');
    roomRole = (rid) => this.db.ref().child('room-roles/' + rid);
    roomActiveRoles = (roomId) => this.db.ref().child('/rooms/' + roomId + '/activeRoles/');
    roomActiveRole = (roomId, roleId) => this.db.ref().child('/rooms/' + roomId + '/activeRoles/' + roleId);
    roomActiveProjectActiveRole = (roomId, roomProjectId, roleId) => this.db.ref().child('/rooms/' + roomId + '/activeProjects/' + roomProjectId + '/activeProjectRoles/' + roleId);


    deleteRoleFromRoomRoles = (roleId) => this.db.ref().child('room-roles/')
    .orderByChild('roleId')
    .equalTo((roleId))
    .once('value', snapshot => {
        if (snapshot.exists()) {
            var o = snapshot.val();
            var l = Object.keys(o).map(key => ({
                ...o[key],
                id: key,
            }))
            l.map((r, index) => {
                return this.db.ref().child('room-roles/' + r.id).remove();
            })

            l.map((r, index) => {
                return this.deleteRoleFromRooms(r.roomId, r.roleId);
            })
        }
    })

    deleteRoleFromRooms = (roomId, roleId) => this.db.ref().child('rooms/' + roomId + '/activeRoles/' + roleId)
    .remove();



    newRoomRole = ( roleId, projectId, roleName, roomId, uid ) => {


        var newRoomRoleKey = this.db.ref().child('room-roles').push().key;

        var roleData = {
            roleId: roleId,
            projectId: projectId,
            roleName: roleName,
            roomId: roomId,
            uid: uid,
        }

        var roomRoleData = {
            projectId: projectId,
            roleName: roleName,
            roomRoleId: newRoomRoleKey,
            uid: uid,
        };

        /*
        var projectRoleData = {
            roleName: roleName,
            roomProjectId: roomProjectId,
            roomRoleId: newRoomRoleKey,
            uid: uid,
        }
        */

        var updates = {};
        updates['/room-roles/' + newRoomRoleKey] = roleData;
        updates['/rooms/' + roomId + '/activeRoles/' + roleId] = roomRoleData;
        //updates['/rooms/' + roomId + '/activeProjects/' + roomProjectId + '/activeProjectRoles/' + roleId] = projectRoleData;

        return this.db.ref().update(updates);
    };

    archiveRoomRole = ( uid, roleId, projectId, roleName, roomId, timeRemoved ) => {
        var roleData = {
            uid: uid,
            roleId: roleId,
            projectId: projectId,
            roleName: roleName,
            roomId: roomId,
            timeRemoved: timeRemoved,
        }

        var newRoleKey = this.db.ref().child('room-roles-archive').push().key;

        var updates={};
        updates['/room-roles-archive/' + newRoleKey] = roleData;

        return this.db.ref().update(updates);
    }

    roomReader = (roomReaderId) => this.db.ref().child('room-readers/' + roomReaderId);
    removeRoomReader = (roomReaderId) => this.db.ref('room-readers/' + roomReaderId);



    readersRoom = (roomId) => this.db.ref().child('rooms/' + roomId + '/roomReaders');

    archiveRoomReader = (uid, name, employeeId, roomId, timeAdded, timeRemoved) => {

        var readerData = {
            uid: uid,
            name: name, 
            employeeId: employeeId,
            roomId: roomId,
            timeAdded: timeAdded,
            timeRemoved: timeRemoved,
            active: false,
        };

        var newReaderArchiveKey = this.db.ref().child('room-readers-archive').push().key;

        var updates = {};
        updates['/room-readers-archive/' + newReaderArchiveKey] = readerData;

        return this.db.ref().update(updates);
    }



    removeRoomReaderRoom = (roomId, roomReaderId) => this.db.ref('rooms/' + roomId + '/roomReaders/' + roomReaderId);

    

    

    // ** Session API ***

    myUserId = () => this.auth().currentUser.uid;

    newSession = (project, date, createdBy) => {

        var userId = createdBy;
        
        var sessionData = {
            project: project,
            date: date,
            createdBy: createdBy
        };

        var userSessionData = {
            project: project,
            date: date
        };

        var projectSessionData = {
            project: project,
            date: date,
        };


        var newProjectKey = this.db.ref().child('projects').push().key;
        var newSessionKey = this.db.ref().child('sessions').push().key;

        var updates = {};
        updates['/sessions/' + newSessionKey] = sessionData;
        updates['/projects/' + newProjectKey + '/sessions/' + newSessionKey] = projectSessionData;
        updates['/users/' + userId + '/sessions/' + newSessionKey] = userSessionData;

        return this.db.ref().update(updates);
    };

    groupRef = (uid) => {

        var key = this.db.ref('user-group-ref/').push().key;

        this.db.ref('user-group-ref/' + key)
        .update({
            user: uid
        });

        return key;
    }

    sessions = () => this.db.ref('sessions/');

    // ** Project API ***

    projects = () => this.db.ref('projects/');
    projectSelect = (projectId) => this.db.ref('projects/' + projectId);

    projectRoleInfo = (projectId) => this.db.ref('projects/' + projectId + '/roles/');

    userProjects = (createdBy) => this.db.ref('users/' + createdBy + '/projects/');


    // ** Roles API *** //
    roles = () => this.db.ref('roles');
    roleUpdate = (role) => this.db.ref('roles/' + role);
    projectRoleUpdate = (projectId, roleId) => this.db.ref('projects/' + projectId + '/roles/' + roleId);

    // *** Used on Accounts Page for Settings Projects to Active/Inactive *** //
    projectAttributes = (projectId) => this.db.ref('projects/' + projectId);
    userProjectAttributes = (userId, projectId) => this.db.ref('users/' + userId + '/projects/' + projectId);


    project = (project, roles) => {
        var projectData = {
            project: project,
            roles: roles,
        };

        var newProjectKey = this.db.ref().child('projects').push().key;

        var projects = {};
        projects['/projects/' + newProjectKey] = projectData;

        return this.db.ref().update(projects);
    };

    // *** Removing Projects *** //

    removeRoomProject = (roomProjectId) => this.db.ref('room-projects/' + roomProjectId);
    removeRoomProjectRoom = (roomId, roomProjectId) => this.db.ref('rooms/' + roomId + '/activeProjects/' + roomProjectId);
    removeRoomActiveProject = (roomId) => this.db.ref('rooms/' + roomId + '/activeProjects');

    ///////// *** Removing a project via Account Page *** //
    removeProject = (projectId) => this.db.ref('projects/' + projectId).remove();
    
    removeUserProject = (uid, projectId) => this.db.ref('users/' + uid + '/projects/' + projectId).remove();
    

    removeRoomProjectFromTransfer = (projectId, transferTo, projectName) => this.db.ref('room-projects')
    .orderByChild('projectId')
    .equalTo(projectId)
    .once('value', snapshot => {
        if (snapshot.exists()) {
            var o = snapshot.val();
            var l = Object.keys(o).map(key => ({
                ...o[key],
                id: key
            }));

            if (l) {
                l.map((i, index) => {
                this.db.ref('room-projects')
                .orderByChild('roomId')
                .equalTo((i.roomId))
                .once('value', snapshot => {
                    if (snapshot.exists()) {
                        var roomProjs = snapshot.val();
                        var roomProjsList = Object.keys(roomProjs).map(key => ({
                            ...roomProjs[key],
                            id: key,
                        }))
                        var alreadyExists = roomProjsList.filter(f => {
                            return f.projectId === transferTo
                        });
                        if (alreadyExists.length > 0) {
                            this.db.ref('room-projects/' + i.id).remove();
                            this.db.ref('rooms/' + i.roomId + '/activeProjects/' + i.id).remove();
                        } else {
                            this.db.ref('room-projects/' + i.id)
                            .update({
                                projectId: transferTo,
                                name: projectName,
                            })
                            this.db.ref('rooms/' + roomProjsList[0].roomId + '/activeProjects/' + i.id)
                            .update({
                                projectId: transferTo,
                                name: projectName,
                            })
                        }
                    }
                    })
                })
            } 
        }
    })

                        // Removing room-projects, room/activeProjects & room/activeRoles
    removeRoomProjects = (projectId) => this.db.ref('room-projects')
    .orderByChild('projectId')
    .equalTo((projectId))
    .once('value', snapshot => {
        if (snapshot.exists()) {
            var o = snapshot.val();
            var l = Object.keys(o).map(key => ({
                ...o[key],
                id: key
            }));
        
            l.map((i, index) => {
                return this.removeRoomProject(i.id).remove()
            })}

            if (l) {
                l.map((r, index) => {
                return this.db.ref('rooms/' + r.roomId + '/activeProjects')
                .orderByChild('projectId')
                .equalTo((projectId))
                .once('value', snapshot => {
                    if (snapshot.exists()) {
                        var rapo = snapshot.val()
                        var rapl = Object.keys(rapo).map(key => ({
                            ...rapo[key],
                            id: key,
                        }))
                        this.db.ref('rooms/' + r.roomId + '/activeProjects/' + rapl[0].id)
                        .remove()
                    }
                })
            })
            }

            if (l) {
            l.map((r, index) => {
                return this.db.ref('rooms/' + r.roomId + '/activeRoles')
                .orderByChild('projectId')
                .equalTo((projectId))
                .once('value', snapshot => {
                    if (snapshot.exists()) {
                        var raro = snapshot.val()
                        var rarl = Object.keys(raro).map(key => ({
                            ...raro[key],
                            id: key,
                        }))
                        this.db.ref('rooms/' + r.roomId + '/activeRoles/' + rarl[0].id)
                        .remove()
                    }
                })
            })
            }
        })

    removeProjectRolesFromProjId = (projectId) => this.db.ref('roles')
    .orderByChild('projectId')
    .equalTo((projectId))
    .once('value', snapshot => {
        if (snapshot.exists()) {
            var o = snapshot.val();
            var l = Object.keys(o).map(key => ({
                ...o[key],
                id: key
            }));
            l.map(role => {
                return this.db.ref('roles/' + role.id)
                .remove()
            })
        }
    })

    removeProjectRoomRolesFromProjId = (projectId) => this.db.ref('room-roles')
    .orderByChild('projectId')
    .equalTo((projectId))
    .once('value', snapshot => {
        if (snapshot.exists()) {
            var o = snapshot.val();
            var l = Object.keys(o).map(key => ({
                ...o[key],
                id: key
            }));
            l.map(roomRole => {
                return this.db.ref('room-roles/' + roomRole.id)
                .remove()
            })
        }
    })


    // From project transfer if the role is currently assigned to a room // 

    removeProjectRoomRolesFromTransfer = (projectId, transferTo) => this.db.ref('room-roles')
    .orderByChild('projectId')
    .equalTo((projectId))
    .once('value', snapshot => {
        if (snapshot.exists()) {
            var o = snapshot.val();
            var l = Object.keys(o).map(key => ({
                ...o[key],
                id: key
            }));

            l.map(roomRole => {
                this.db.ref('room-roles/' + roomRole.id)
                .update({
                    projectId: transferTo,
                })
                this.db.ref('rooms/' + roomRole.roomId + '/activeRoles/' + roomRole.roleId)
                .update({
                    projectId: transferTo,
                    roleName: roomRole.roleName,
                    roomRoleId: roomRole.id, 
                    uid: roomRole.uid,
                })
            })
        }
    })





    projectRoles = () => {
        return this.db.ref('roles');
    };

    // *** Creating a project via Account Page *** //
    addProject = (projectName, createdBy, createdOn) => {
        var projectData = {
            projectName: projectName,
            createdBy: createdBy,
            createdOn: createdOn, 
            isActive: false,
            temp: false,
        };

        var userId = createdBy;

        var newProjectKey = this.db.ref().child('projects').push().key;

        var userProjectData = {
            projectName: projectName,
            createdOn: createdOn,
            isActive: false,
            temp: false,
        }

        var projectUpdates = {};
        projectUpdates['/projects/' + newProjectKey] = projectData;
        projectUpdates['/users/' + userId + '/projects/' + newProjectKey] = userProjectData;

        return this.db.ref().update(projectUpdates);
    }

    addTempProject = (projectName, uid, createdOn, newProjectKey) => {
        var projectData = {
            projectName: projectName,
            createdBy: uid,
            createdOn: createdOn,
            isActive: false,
            temp: true,
            transfer: false,
        };

        var userProjectData = {
            projectName: projectName,
            createdOn: createdOn,
            isActive: false,
            temp: true,
            transfer: false,
        };

        var userId = uid;

        var projectUpdates = {};
        projectUpdates['/projects/' + newProjectKey] = projectData;
        projectUpdates['/users/' + userId + '/projects/' + newProjectKey] = userProjectData;

        return this.db.ref().update(projectUpdates);
    }


    // *** Roles API ***

    projectRoles = () => this.db.ref('roles');

    // *** Users on Account Page Project Modal to set Active/InActive *** //
    roleAttributes = (roleId) => this.db.ref('roles/' + roleId);
    projectRoleAttributes = (projectId, roleId) => this.db.ref('projects/' + projectId + '/roles/' + roleId);
    
    viewProjectRoles = (projectId) => this.db.ref('projects/' + projectId + '/roles/');


    removeProjectRole = (projectId, roleId) => this.db.ref('projects/' + projectId + '/roles/');
    removeRole = (roleId) => this.db.ref('roles');

   
    addTempRole = (roleName, uid, project, projectId, createdOn, newRoleKey) => {

        var roleData = {
            roleName: roleName,
            createdBy: uid,
            project: project,
            projectId: projectId,
            createdOn: createdOn,
            isActive: false,
            temp: true,
        };

        var projectRoleData = {
            roleName: roleName,
            projectId: projectId,
            createdOn: createdOn,
            isActive: false,
            temp: true,
        };

        var projId = projectId;

        var updates = {};
        updates['/roles/' + newRoleKey] = roleData;
        updates['/projects/' + projId + '/roles/' + newRoleKey] = projectRoleData;

        return this.db.ref().update(updates);

    };

    transferProjectRole = (roleId, roleName, projectId, createdOn) => {
        var projectRoleData = {
            roleName: roleName,
            projectId: projectId,
            createdOn: createdOn,
            isActive: false,
        }

        var roleId = roleId;

        var updates = {};
        updates['/projects/' + projectId + '/roles/' + roleId] = projectRoleData;

        return this.db.ref().update(updates);
    }

    addRole = (roleName, createdBy, project, projectId, createdOn) => {

        var roleData = {
            roleName: roleName,
            createdBy: createdBy,
            project: project,
            projectId: projectId,
            createdOn: createdOn,
            isActive: false,
        };

        var projectRoleData = {
            roleName: roleName,
            projectId: projectId,
            createdOn: createdOn,
            isActive: false,
        }

        var projId = projectId;

        var newRoleKey = this.db.ref().child('roles').push().key;

        var updates = {};
        updates['/roles/' + newRoleKey] = roleData;
        updates['/projects/' + projId + '/roles/' + newRoleKey] = projectRoleData;

        return this.db.ref().update(updates);
    };

    deactivateProjectRoles = (projectId, role) => this.db.ref('projects/' + projectId + '/roles/' + role);

    listRoomProjectRoles = () => this.db.ref('room-projects/');

    deActivateRoomRole = (roomId, roleId) => this.db.ref('rooms/' + roomId + '/activeRoles/' + roleId);


    // *** CheckIn API ***

    checkIn = () => this.db.ref('check-ins');
    userCheckIn = (uid, checkInId) => this.db.ref('user-check-ins/' + uid + '/' + checkInId);

    

    sendCheckInAlert (templateId, variables) {
        emailjs.send('sendgridanioffice', templateId, variables, 'user_UXrnDajVZDtBHREeuIA0z')
        .then(res => {
            console.log('Sent')
        })
        .catch(err => console.log('Fail', err));
    };

    talentUserCheckIns = (talentUser, project, role, time, day, apptId, apptTime, newCheckInQId) => {
        this.db.ref('talent-user-checkins/' + talentUser + '/' + newCheckInQId)
        .update({
            project: project,
            role: role,
            time: time,
            day: day,
            apptId: apptId,
            apptTime: apptTime,
            status: 'checked-in',
        })
    };

    sendBackToQ = (talentId, name, rep, project, role, time, day, projectId, roleId, uid, apptId, apptTime, talentUserId, avatar) => {
        var checkInData = {
            checkInName: name,
            checkInRepCompany: rep,
            checkInProject: project,
            checkInRole: role,
            checkedIn: true,
            checkInTime: time,
            checkInDay: day,
            projectId: projectId,
            roleId: roleId,
            dibs: '',
            accountId: uid,
            apptId: apptId,
            apptTime: apptTime,
            talentUser: talentUserId,
            avatar: avatar,
        };

        var updates={};
        updates['/user-check-ins/' + uid + '/' + talentId] = checkInData;

        return this.db.ref().update(updates);

    }
    
    toTitleCase = (name) => {
        var lower = name.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
        return lower;
    };

    checkInQ = (name, repCompany, projectValue, projectRole, timestampHours, timestampDay, selectedProjectId, selectedRoleId, myId, apptId, apptTime, talentUser, avatar, tempId) => {
        
        var fixedName = name

        var checkInData = {
            checkInName: this.toTitleCase(name),
            checkInRepCompany: repCompany,
            checkInProject: projectValue,
            checkInRole: projectRole,
            checkedIn: true,
            checkInTime: timestampHours,
            checkInDay: timestampDay,
            projectId: selectedProjectId,
            roleId: selectedRoleId,
            dibs: '',
            accountId: myId,
            apptId: apptId,
            apptTime: apptTime,
            talentUser: talentUser,
            avatar: avatar,
        };

        var talentData = {
            name: name.toLowerCase(),
            repCompany: repCompany,
            project: projectValue,
            role: projectRole,
        }

        var newCheckInQId = tempId ? tempId : this.db.ref().child('check-in-queue').push().key;
        var rKey = this.db.ref().child('rc').push().key;

        if (talentUser) {
            this.talentUserCheckIns(talentUser, projectValue, projectRole, timestampHours, timestampDay, apptId, apptTime, newCheckInQId);
        };

        this.db.ref('rc')
        .orderByChild('name')
        .equalTo((repCompany.toLowerCase()))
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                var duplicate = l.filter(f => {
                    return f.name.toLowerCase() === repCompany.toLowerCase();
                })
                if (duplicate.length > 0) {
                    this.db.ref('rc/' + duplicate[0].id + '/cs')
                    .once('value', snapshot => {
                        if (snapshot.exists()) {
                            var co = snapshot.val();
                            var cl = Object.keys(co).map(key => ({
                                ...co[key],
                                id: key,
                            }))
                            var clientDuplicate = cl.filter(cf => {
                                return cf.name.toLowerCase() === name.toLowerCase()
                            })
                            if (clientDuplicate.length > 0) {
                            } else {
                                var clientKey = this.db.ref().child('rc/' + duplicate[0].id + '/cs/').push().key;
                                this.db.ref('rc/' + duplicate[0].id + '/cs/' + clientKey)
                                .update({
                                    name: name.toLowerCase(),
                                })
                            }
                } else {
                    this.db.ref('rc/' + rKey)
                    .update({
                        name: repCompany.toLowerCase(),
                    })
                    var cKey = this.db.ref().child('rc/' + duplicate.id + '/cs/').push().key;
                    this.db.ref('rc/' + rKey + '/cs/' + cKey)
                    .update({
                        name: name.toLowerCase(),
                    })
                }})}
                } else {
                    this.db.ref('rc/' + rKey)
                    .update({
                        name: repCompany.toLowerCase(),
                    })
                    var cKey = this.db.ref().child('rc/' + rKey + '/cs/').push().key;
                    this.db.ref('rc/' + rKey + '/cs/' + cKey)
                    .update({
                        name: name.toLowerCase(),
                    })
                };
        });

        var templateId="checkinalert";

        

        this.db.ref('room-roles/')
        .orderByChild('roleId')
        .equalTo((selectedRoleId))
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key
                }));
                var viableRooms = [];
                if (l) {
                    l.map(i => {
                        viableRooms.push({
                            room: i.roomId
                        });
                    });
                }
                if (viableRooms) {
                    viableRooms.map(room => {
                        return this.db.ref('rooms/' + room.room)
                            .once('value', snapshot => {
                            if (snapshot.exists()) {
                                var o = snapshot.val();
                                var usersObject = o.roomReaders;
                                if (usersObject) {
                                    var users = Object.keys(usersObject).map(key => ({
                                        ...usersObject[key],
                                        id: key,
                                    }));
                                    var awayUsers = users.filter(f => f.isAway);
                                    if (awayUsers) {
                                        awayUsers.map(u => {
                                            this.employeeDevices(myId, u.employeeId)
                                            .once('value', snapshot => {
                                                if (snapshot.exists()) {
                                                    var devObj = snapshot.val();
                                                    var devs = Object.keys(devObj).map(key => ({
                                                        ...devObj[key],
                                                        id: key,
                                                    }))
                                                    if (devs) {
                                                        devs.map(dev => {
                                                            return sendCheckInNotification(name, u.employeeId, dev.token);
                                                        })
                                                    };
                                                };
                                            });
                                            this.userEmployee(myId, u.employeeId)
                                            .once('value', snapshot => {
                                                if (snapshot.exists()) {
                                                    var o = snapshot.val();
                                                    var emailprep = o.email;
                                                    if (emailprep) {
                                                        var email = emailprep;
                                                        this.sendCheckInAlert(templateId, {email: email, talent: name, role: projectRole, project: projectValue});
                                                    } else {
                                                        console.log('No email');
                                                    }
                                                } else {
                                                    console.log('No record');
                                                }
                                            }
                                        )});
                                    
                                }
                            }}
                        })
                    })
                }
            }
        })

        var updates = {};
        updates['/check-in-queue/' + newCheckInQId] = checkInData;
        updates['/talent/' + newCheckInQId] = talentData;
        updates['/user-check-ins/' + myId + '/' + newCheckInQId] = checkInData;

        return (this.db.ref().update(updates));
    }

    checkInDibs = (uid, talentId) => this.db.ref('user-check-ins/' + uid + '/' + talentId);

    checkInTalent = () => this.db.ref('checkin-in-queue/');

    sessionTalentMarkers = (sess, talentId) => this.db.ref('session-agenda/' + sess + '/talent/' + talentId + '/markers/');

    selectCheckIn = (checkId) => this.db.ref('check-in-queue/' + checkId);
    selectInSession = (checkId) => this.db.ref('in-session/' + checkId);
    selectCompleted = (checkId) => this.db.ref('completed/' + checkId);
    selectUserCompleted = (uid, checkInId) => this.db.ref('user-check-ins-complete/' + uid + '/' + checkInId);

    listCheckInQ = () => this.db.ref('check-in-queue');
    listInSession = () => this.db.ref('in-session');
    listCompleted = () => this.db.ref('completed');

    removeCheckInQ = (checkInToAssign) => this.db.ref('check-in-queue/' + checkInToAssign);
    removeInSession = (checkInId) => this.db.ref('in-session/' + checkInId);

    sessionTalentMatch = (uid, talentId, sessionId, roleId, checkInTimeDay) => this.db.ref('user-talent-session-match/' + uid + '/talent/' + talentId + '/sessions/' + sessionId)
    .update({role: roleId, day: checkInTimeDay});

    sessionTalentMatchSearch = (uid, talentId) => this.db.ref('user-talent-session-match/' + uid + '/talent/' + talentId);

    inSession = (checkInName, checkInProject, checkInRole, checkInRepCompany, checkInRoom, checkInToAssign, checkInTime, checkInTimeDay, callInTime, projectId, roleId, waitTime, accountId, sessionMode, sessionId) => {
        var inSessionData = {
            checkInName: checkInName,
            checkInProject: checkInProject,
            checkInRole: checkInRole,
            checkInRepCompany: checkInRepCompany,
            checkInRoom: checkInRoom,
            checkInQId: checkInToAssign,
            checkInTime: checkInTime,
            checkInTimeDay: checkInTimeDay,
            callInTime: callInTime,
            projectId: projectId,
            roleId: roleId,
            waitTime: waitTime,
            accountId: accountId,
            notes: '0',
            tags: '0',
        };

        var newInSessionId = this.db.ref().child('in-session').push().key;

        if (sessionMode) {
            this.sessionTalentMatch(accountId, checkInToAssign, sessionId, roleId, checkInTimeDay);
        };

        var updates = {};
        updates['/in-session/' + newInSessionId] = inSessionData;

        return this.db.ref().update(updates);
    };

    /*newAccountCreate (email, company) {
        emailjs.send('sendgridanioffice', 'usersignup', {email: email, company: company}, 'user_UXrnDajVZDtBHREeuIA0z')
        .then(res => {
            console.log('Sent')
        })
        .catch(err => console.log('Fail', err));
    };*/

    sendEmailToReception = (name, room, email) => {
        emailjs.send('sendgridanioffice', 'alertreception', {email: email, room: room, name: name}, 'user_UXrnDajVZDtBHREeuIA0z')
        .then(res => {
            console.log('Sent')
        })
        .catch(err => console.log('Fail', err));
    };

    sendToReception = (uid, name, room) => {
        this.employeeDevices(uid, 'Reception')
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                if (l) {
                    l.map(dev => {
                        return sendTalentSendNotification(name, room, dev.token);
                    });
                };
            };
        })
    };

    completed = (
        checkInName, 
        checkInProject, 
        checkInRole, 
        checkInRepCompany, 
        checkInRoom, 
        checkInToAssign, 
        projectId, 
        roleId, 
        checkInTime, 
        checkInTimeDay, 
        callInTime, 
        completedTime, 
        waitTime, 
        auditionTime, 
        totalTime, 
        accountId, 
        noteCount, 
        tagCount
    ) => {
        
        var completedData = {
            checkInName: checkInName,
            checkInProject: checkInProject,
            checkInRole: checkInRole,
            checkInRepCompany: checkInRepCompany,
            checkInRoom: checkInRoom,
            checkInQId: checkInToAssign,
            projectId: projectId, 
            roleId: roleId,
            checkInTime: checkInTime,
            checkInTimeDay: checkInTimeDay,
            callInTime: callInTime,
            completedTime: completedTime,
            waitTime: waitTime.toString(),
            auditionTime: auditionTime.toString(),
            totalTime: totalTime.toString(),
            accountId: accountId,
            notes: noteCount ? noteCount.toString() : '0',
            tags: tagCount ? tagCount.toString() : '0',
        };

        var updates = {};
        updates['/completed/' + checkInToAssign] = completedData;
        updates['/user-check-ins-complete/' + accountId + '/' + checkInToAssign] = completedData; 

        return this.db.ref().update(updates);
    };


    userCheckins = (uid) => this.db.ref('user-check-ins/' + uid);
    userCheckinSelect = (uid, ref) => this.db.ref('user-check-ins/' + uid + '/' + ref);
    completedUserCheckins = (uid) => this.db.ref('user-check-ins-complete/' + uid);

    checkIns = () => this.db.ref('check-ins');

    // CHAT 

    chat = (uid) => this.db.ref('users/' + uid + '/chat/');

    newChat = (uid) => {

        var data = {
            team: '',
            group: '',
            direct: '',
        }

        var updates = {};
        updates['/users/' + uid + '/chat/'] = data;

        return this.db.ref().update(updates);
    };

    newTeamMsg = (uid, eid, name, time, day, msg) => {

        var data = {
            msg: msg,
            eid: eid,
            name: name,
            time: time,
            day: day,
            archived: false,
        }

        var teamMsgKey = this.db.ref('users/' + uid + '/chat/team/').push().key;

        var updates={};
        updates['/users/' + uid + '/chat/team/' + teamMsgKey] = data;

        return this.db.ref().update(updates);
    }

    removeTeamMsg = (uid, id) => {

        this.db.ref('users/' + uid + '/chat/team/' + id).remove();

    };




    // REMOVE PROJECT FROM ROOM PROJECT MODAL 

    _removeProjectFromRoomProjectModalDropDown = (uid, name, projectId, roomId, timeRemoved) => 

        this.roomProjects()
        .orderByChild('roomId')
        .equalTo((roomId))
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key
                }))
                var thisProject = l.filter(f => {
                    return f.projectId === projectId
                });

                var roomProjectId = thisProject[0].id;

                this.roomProject(roomProjectId)
                .once('value', snapshot => {
                    var project = snapshot.val();
                    var timeAdded = project.timeAdded;

                    // ARCHIVE ROOM

                    this.archiveRoomProject(uid, name, projectId, roomId, timeAdded, timeRemoved);

                    // REMOVE FROM ROOM-PROJECTS TABLE

                    this.removeRoomProject(roomProjectId)
                    .remove()

                    // REMOVE FROM ROOM - ACTIVE PROJECTS TABLE

                    this.removeRoomProjectRoom(roomId, roomProjectId)
                    .remove();

                    // ARCHIVE ROLES THAT WERE ACTIVE WITHIN THE PROJECT FROM ROOM ROLES TABLE AND ARCHIVE

                    this.roomActiveRoles(roomId)
                    .orderByChild('projectId')
                    .equalTo((projectId))
                    .once('value', snapshot => {
                        if (snapshot.exists()) {
                            var o = snapshot.val();
                            var l = Object.keys(o).map(key => ({
                                ...o[key],
                                id: key,
                            }))
                            l.map((role, index) => {
                                var rid = role.roomRoleId;
                                var roleId = role.id;
                                var roleName = role.roleName;
                                
                                this.archiveRoomRole(uid, roleId, projectId, roleName, roomId, timeRemoved);

                                this.roomRole(rid)
                                .remove();

                                this.roomActiveRole(roomId, roleId)
                                .remove();

                            })
                        }
                    })   
                })
            }
        }
    )

    _removeRoleFromRoomRoleModalDropdown = (uid, roomId, roleId, projectId, roleName, timeRemoved) => {

        this.roomRoles()
        .orderByChild('roomId')
        .equalTo((roomId))
        .once('value', snapshot => {
            var o = snapshot.val();
            var l = Object.keys(o).map(key => ({
                ...o[key],
                id: key,
            }))

            var thisRole = l.filter(r => {
                return r.roleId === roleId;
            })
            this.roomRole(thisRole[0].id).remove();
        })
        this.roomActiveRole(roomId, roleId).remove();
        this.archiveRoomRole(uid, roleId, projectId, roleName, roomId, timeRemoved);
    
    }


    // TALENT READERS

    talentReaders = (talentId) => this.db.ref('talent-readers/' + talentId);

    addTalentReaders = (roomId, checkInId) => {
        
        this.db.ref('room-readers')
        .orderByChild('roomId')
        .equalTo((roomId))
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }));
                if (l) {
                l.map((i, index) => {

                    var talentReaderData = {
                        name: i.name,
                        accountId: i.uid,
                    }

                    var updates={}
                    updates['/talent-readers/' + checkInId + '/' + i.employeeId] = talentReaderData;

                    return this.db.ref().update(updates);
                })
            }
        }})
    }

    // TAGS

    addSessionTag = (sess, tagId, tagName, from, count) => {

            var tagData = {
                from: from,
                tagName: tagName,
                uses: count,
                usedBy: '',
                active: true,
            };
    
            var updates={};
            updates['session-tags/' + sess + '/' + tagId] = tagData;
    
            return this.db.ref().update(updates);
        }
    
    

    removeSessionTag = (sess, tagId, subject) => {
        this.db.ref('session-tags/' + sess + '/' + tagId)
        .update({
          active: false,
        })
    };

    addSessionUserTag = (sess, subject, tagId, tagName, groupRef, projectId, roleId, addedBy) => {

        var totalCount = this.sessionTag(sess, tagId)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var uses = o.uses;
                return uses + 1;
            } else {
                return 1;
            }
        })
        
        this.sessionTag(sess, tagId).update({ uses: totalCount });
        
        this.sessionUserTag(sess, subject, tagId)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var by = o.by;
                by.push(addedBy);
                this.db.ref('session-user-tags/' + sess + '/' + subject + '/' + tagId)
                .update({
                    uses: by.length,
                    by: by,
                })
            } else {
            var tagData = {
                tagName: tagName,
                company: groupRef,
                projectId: projectId,
                roleId: roleId,
                uses: 1,
                by: [addedBy],
            }
            var updates={}
            updates['session-user-tags/' + sess + '/' + subject + '/' + tagId] = tagData;

            return this.db.ref().update(updates);
            }
        })
    }

    addUserTalentTagUser = (uid, talentId, tagId, by) => this.db.ref('user-talent-tags/' + uid + '/' + talentId + '/' + tagId + '/by/' + by.ref)
    .update({
        name: by.name,
    });

    removeUserTalentTagUser = (uid, talentId, tagId, by) => {

        this.db.ref('user-talent-tags/' + uid + '/' + talentId + '/' + tagId + '/by/')
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                if (l) {
                    var count = l.length;
                    if (count === 1) {
                        this.removeUserTalentTag(uid, talentId, tagId);
                    }
                } else {
                    this.db.ref('user-talent-tags/' + uid + '/' + talentId + '/' + tagId + '/by/' + by.ref)
                    .remove();
                }
            }
        })
    }

    addUserTalentTag = (uid, talentId, tagId, tagName, projectId, roleId) => {

        var newKey = this.db.ref('/user-tags-filter/' + uid + '/').push().key;

        var tagData = {
            tagName: tagName,
            projectId: projectId,
            roleId: roleId,
            refKey: newKey,
        }

        var filterData = {
            tag: tagId,
            subject: talentId,
            topic: projectId,
            subtopic: roleId,
        };
    

        var updates = {};
        updates['/user-talent-tags/' + uid + '/' + talentId + '/' + tagId] = tagData;
        updates['/user-tags-filter/' + uid + '/' + newKey] = filterData;

        return this.db.ref().update(updates);
    }

    removeUserTalentTag = (uid, talentId, tagId, refId) => {
        this.db.ref('/user-talent-tags/' + uid + '/' + talentId + '/' + tagId).remove();
        this.db.ref('/user-tags-filter/' + uid + '/' + refId).remove();
    }

    userTagsFilter = (uid) => this.db.ref('user-tags-filter/' + uid);

    userTalentTags = (uid, talentId) => this.db.ref('user-talent-tags/' + uid + '/' + talentId);
    userTalentTag = (uid, talentId, tagId) => this.db.ref('user-talent-tags/' + uid + '/' + talentId + '/' + tagId);

    addSessionUserTagFrom = (sess, subject, tagId, type, ref) => {
        var fromData = {
            type: type,
        }

        var updates={};
        updates['session-user-tags/' + sess + '/' + subject + '/' + tagId + '/' + ref] = fromData;

        return this.db.ref().update(updates);
    }

    duplicateSessionUserTag = (sess, subject, tagId, addedBy) => {

        var totalCount = this.sessionTag(sess, tagId)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var uses = o.uses;
                return uses + 1;
            }
        })

        this.sessionTag(sess, tagId).update({ uses: totalCount });

        this.sessionUserTag(sess, subject, tagId)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var uses = o.uses + 1;
                console.log(uses);
                var by = o.by;
                by.push(addedBy);
                this.sessionUserTag(sess, subject, tagId).update({ 
                    uses: uses,
                    by: by,
                });
            }
        })
    
    }

    removeSessionUserTag = (sess, subject, tagId, sessUserId) => {

        this.sessionUserTag(sess, subject, tagId)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var byo = o.by;
                if (byo) {
                    var by = Object.keys(byo).map(key => ({
                        ...byo[key],
                        id: key
                    }));
                    var userToRemove = by.filter(fil => { return fil.ref === sessUserId });
                    var toDelete = userToRemove[0].id;

                    if (by.length > 1) {
                        this.sessionUserTagUser(sess, subject, tagId, toDelete).remove();
                        this.sessionUserTag(sess, subject, tagId)
                        .update({
                            uses: by.length - 1
                        })
                    } else {
                        this.sessionUserTag(sess, subject, tagId).remove();
                    };
                };
            };
        });
    }
    

    sessionTags = (sess) => this.db.ref('session-tags/' + sess);
    sessionTag = (sess, tagId) => this.db.ref('session-tags/' + sess + '/' + tagId);

    sessionUserTags = (sess, subject) => this.db.ref('session-user-tags/' + sess + '/' + subject);
    sessionUserTag = (sess, subject, tagId) => this.db.ref('session-user-tags/' + sess + '/' + subject + '/' + tagId);
    sessionUserTagUser = (sess, subject, tagId, ref) => this.db.ref('session-user-tags/' + sess + '/' + subject + '/' + tagId + '/by/' + ref);


 // *** Notes API *** // 

    notes = () => this.db.ref('notes'); 

    updateNote = (noteId) => this.db.ref('notes/' + noteId);

    noteConnect = (sess) => {

        var key = this.db.ref('note-connect').push().key;

        this.db.ref('note-connect/' + key)
        .update({
            sess: sess
        });

        return key;
    }


    addNote = (userId, checkInName, projectId, roleId, checkInId, timestamp, daystamp, note, noteBy, inSession, sessionId, connectKey) => {


        var talentNoteData = {
            checkInName: checkInName,
            projectId: projectId,
            roleId: roleId,
            timestamp: timestamp,
            daystamp: daystamp,
            note: note,
            noteBy: noteBy,
            connect: connectKey ? connectKey : '',
        };

        var newNoteId = this.db.ref().child('notes').push().key;


        if (inSession) {
            this.selectCastingSessionTalentNote(userId, sessionId, checkInId, newNoteId).update({
                note: note,
                user: noteBy,
                time: timestamp,
                day: daystamp,
                connect: connectKey ? connectKey : '',
            })
        };

        this.selectUserCompleted(userId, checkInId)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                this.selectUserCompleted(userId, checkInId)
                .update({
                    notes: (Number(o.notes) + 1).toString(),
                });
            };
        })

        var updates = {};
        updates['/talent-notes/' + userId + '/talent/' + checkInId + '/notes/' + newNoteId] = talentNoteData;

        return this.db.ref().update(updates);
    }

    talentNotes = (uid, talent) => this.db.ref('/talent-notes/' + uid + '/talent/' + talent + '/notes/');

    addUserNote = (uid, talent, sess, project, role, note, newNoteId, daystamp, timestamp, noteBy) => {

        var userNoteData = {
            talent: talent,
            project: project,
            role: role,
            note: note,
            sess: sess,
            daystamp: daystamp,
            timestamp: timestamp,
            noteBy: noteBy,
        };

        var updates = {}
        updates['/user-notes/' + uid + '/' + newNoteId] = userNoteData;

        return this.db.ref().update(updates);
    }

    removeNoteLive = (noteId, uid, talentId, sessionId, sessionMode) => {
        this.db.ref('talent-notes/' + uid + '/talent/' + talentId + '/notes/' + noteId).remove();
        if (sessionMode) {
            this.selectCastingSessionTalentNote(uid, sessionId, talentId, noteId).remove();
         };
    }

    removeNote = (noteId, uid, talentId, sessionId, sessionMode) => {
     this.selectUserCompleted(uid, talentId)
     .once('value', snapshot => {
         if (snapshot.exists()) {
             var o = snapshot.val();
             this.selectUserCompleted(uid, talentId)
             .update({
                 notes: Number(o.notes) === 0 ? '0' : (Number(o.notes) - 1).toString(),
             });
         };
     });

     if (sessionMode) {
        this.selectCastingSessionTalentNote(uid, sessionId, talentId, noteId).remove();
     };

     this.db.ref('talent-notes/' + uid + '/talent/' + talentId + '/notes/' + noteId).remove();
     

    }

    talentNotes = (uid, talentId) => this.db.ref('talent-notes/' + uid + '/talent/' + talentId + '/notes/');

    allSessionNotes = (sess) => this.db.ref('session-notes/' + sess + '/talent/');

    sessionNotes = (sess, talentId) => this.db.ref('session-notes/' + sess + '/talent/' + talentId);

    sessionNote = (sess, noteId, talentId) => this.db.ref('session-notes/' + sess + '/talent/' + talentId + '/' + noteId);

    removeSessionNote = (sess, noteId) => this.db.ref('session-notes/' + sess + '/' + noteId).remove();

    editGuestGroupSessionNote = (uid, groupId, talentId, noteId, note) => {
        this.sessionNote(groupId, noteId, talentId)
        .update({
            note: note,
        })

        this.db.ref('/talent-notes/' + uid + '/talent/' + talentId + '/notes/' + noteId)
        .update({
            note: note,
        })
    }

    removeHostGroupSessionNote = (uid, castingId, groupId, talentId, noteId) => {
        this.sessionNote(groupId, noteId, talentId).remove();
        this.db.ref('/talent-notes/' + uid + '/talent/' + talentId + '/notes/' + noteId).remove();
        this.selectCastingSessionTalentNote(uid, castingId, talentId, noteId).remove();
    }


    removeGuestSessionNote = (uid, groupId, talentId, noteId) => {
        this.sessionNote(groupId, noteId, talentId).remove();
        this.db.ref('/talent-notes/' + uid + '/talent/' + talentId + '/notes/' + noteId).remove();
    }


    editHostGroupSessionNote = (uid, castingId, groupId, talentId, noteId, note) => {

        this.sessionNote(groupId, noteId, talentId)
        .update({
            note: note,
        })

        this.db.ref('/talent-notes/' + uid + '/talent/' + talentId + '/notes/' + noteId)
        .update({
            note: note,
        })

        this.selectCastingSessionTalentNote(uid, castingId, talentId, noteId)
        .update({
            note: note,
        })
    };
   
    addSessionNote = (
        sess, noteId, groupRef, employee, talent, talentId, 
        project, projectId, role, roleId, note, privacy, timestamp, 
        daystamp, uid, castSessId, account, sessionUser, sessionUserName) => {

        var noteKey = noteId ? noteId : this.db.ref('session-notes/' + sess + '/talent/').push().key;

        var sessionNoteData = {
            ref: groupRef,
            employee: employee, 
            subject: talent,
            subjectId: talentId,
            topic: project,
            topicId: projectId,
            subtopic: role,
            subtopicId: roleId,
            note: note,
            privacy: privacy,
            timestamp: timestamp,
            daystamp: daystamp,
            archive: false,
            sessionUser: sessionUser,
            userName: sessionUserName,
        }

        if (account === 'host') {
            this.selectCastingSessionTalentNote(uid, castSessId, talentId, noteKey).update({
                note: note,
                user: employee,
                time: timestamp, 
                day: daystamp,
            })
            .finally(
            )
        }

        var userTalentNoteData = {
            session: sess,
            employee: employee, 
            subject: talent,
            subjectId: talentId,
            topic: project,
            topicId: projectId,
            subtopic: role,
            subtopicId: roleId,
            note: note,
            privacy: privacy,
            timestamp: timestamp,
            daystamp: daystamp,
            archive: false,
        }

        var updates = {}
        updates['/session-notes/' + sess + '/talent/' + talentId + '/' + noteKey] = sessionNoteData;
        updates['/talent-notes/' + uid + '/talent/' + talentId + '/notes/' + noteKey] = userTalentNoteData;

        return this.db.ref().update(updates);
    }


    addUserTag = (uid, tagName, tagType) => {

        var tagData = {
            name: tagName.toLowerCase(),
            type: tagType,
            count: 0,
        }

        var tagKey = this.db.ref('user-tags/' + uid).push().key;

        var updates={};
        updates['user-tags/' + uid + '/' + tagKey] = tagData;

        return this.db.ref().update(updates);
    };

    userTags = (uid) => this.db.ref('user-tags/' + uid);

    userTag = (uid, tagId) => this.db.ref('user-tags/' + uid + '/' + tagId);

    removeUserTag = (uid, tagId) => this.userTag(uid, tagId).remove();


    // TALENT TAGS 

    addTalentTagUser = (talentId, tagId, by) => this.db.ref('talent-tags/' + talentId + '/' + tagId + '/by/' + by.ref)
    .update({
        name: by.name,
    });

    addTalentTag = (uid, talentId, tagName, talentName, tagId, projectId, roleId, sessionId, sessionMode) => {

        this.selectUserCompleted(uid, talentId)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                this.selectUserCompleted(uid, talentId)
                .update({
                    tags: (Number(o.tags) + 1).toString(),
                });
            };
        })

        this.userTag(uid, tagId)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                this.userTag(uid, tagId)
                .update({
                    count: Number(o.count) + 1
                });
            }
        })

        var tagData = {
            tagId: tagId,
            talentName: talentName,
            tagName: tagName,
            projectId: projectId,
            roleId: roleId,
        }

        if (sessionMode) {
            this.selectCastingSessionTalentTag(uid, sessionId, talentId, tagId).update({
                tagName: tagName
            });
        };
        
        var updates={};
        updates['/talent-tags/' + talentId + '/' + tagId] = tagData;

        return this.db.ref().update(updates)

    }

    talentTags = (talentId) => this.db.ref('talent-tags/' + talentId);

    talentTag = (talentId, tagId) => this.db.ref('talent-tags/' + talentId + '/' + tagId);
  
    

    removeTalentTag = (uid, talentId, tagId, sessionId, sessionMode) => {

        this.selectUserCompleted(uid, talentId)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                this.selectUserCompleted(uid, talentId)
                .update({
                    tags: Number(o.tags) === 0 ? '0' : (Number(o.tags) - 1).toString(),
                });
            };
        })

        this.userTag(uid, tagId)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                this.userTag(uid, tagId)
                .update({
                    count: Number(o.count) > 0 ? Number(o.count) - 1 : 0
                });
            }
        })

        if (sessionMode) {
            this.selectCastingSessionTalentTag(uid, sessionId, talentId, tagId).remove();
        };

        this.talentTag(talentId, tagId).remove();
    }

    createUserTagGroup = (uid, group) => {
        var data = {
            group: group.toLowerCase(), 
            sentTo: '',
            tags: '',
        }

        var groupKey = this.db.ref('user-tag-groups/' + uid).push().key;

        var updates = {}
        updates['user-tag-groups/' + uid + '/' + groupKey] = data;

        return this.db.ref().update(updates);
    }

    addUserTagGroupTag = (uid, group, groupName, tag, isNew) => {

        var data = {
            tagName: isNew ? tag.toLowerCase() : tag.tagName
        };

        var tagKey = this.db.ref('user-tags/' + uid).push().key;

        if (isNew) {
            this.db.ref('user-tags/' + uid + '/' + tagKey)
            .update({
                name: tag.toLowerCase(),
                type: 'Talent',
                count: 0,
            })
        };
        
        var key = this.db.ref('adm-tag-group-connect/').push().key;

        var adata = {
            group: groupName,
            tag: isNew ? tag.toLowerCase() : tag.tagName,
            by: uid,
        }   

        var updates = {};
        isNew ?
            updates['user-tag-groups/' + uid + '/' + group + '/tags/' + tagKey] = data : 
            updates['user-tag-groups/' + uid + '/' + group + '/tags/' + tag.id] = data; 
        updates['adm-tag-group-connect/' + key] = adata;

        return this.db.ref().update(updates);
    }

    userTagGroups = (uid) => this.db.ref('user-tag-groups/' + uid);
    userTagGroup = (uid, group) => this.db.ref('user-tag-groups/' + uid + '/' + group);
    userTagGroupTag = (uid, group, tag) => this.db.ref('user-tag-groups/' + uid + '/' + group + '/' + tag);

    shareTagGroup = (sender, senderName, receiver, receiverName, receiverEmail, group) => {

        var data = '';
        this.userTagGroup(sender, group)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                data.push({
                    ...o,
                    from: senderName,
                });
            };
        });

        var sentToData = {
            name: receiverName,
        }

        var updates = {};
        updates['user-tag-groups/' + receiver] = data;
        updates['user-tag-groups/' + sender + '/' + group + '/sentTo/' + receiverEmail] = sentToData;
    };


    // APPOINTMENTS API // 

    createAppt = (uid, name, projectId, projectName, roleId, roleName, type, hr, min, ampm, date, createdOn) => {

        var apptData = {
            name: name,
            projectId: projectId,
            projectName: projectName,
            roleId: roleId,
            roleName: roleName,
            type: type,
            hr: hr,
            min: min,
            ampm: ampm,
            date: date,
            createdOn: createdOn,
            editedOn: '',
            num: (hr * 12) + (min / 5) + 1,
            checkedIn: false,
            status: 'pending',
        }

        var apptKey = this.db.ref('user-appts/' + uid).push().key;

        var updates={};
        updates['user-appts/' + uid + '/' + apptKey] = apptData;

        return this.db.ref().update(updates);
    }

    createApptFromQuickList = (uid, name, projectId, projectName, roleId, roleName, type, hr, min, ampm, date, createdOn, listId, listKey) => {
        var apptData = {
            name: name,
            projectId: projectId,
            projectName: projectName,
            roleId: roleId,
            roleName: roleName,
            type: type,
            hr: hr,
            min: min,
            ampm: ampm,
            date: date,
            createdOn: createdOn,
            editedOn: '',
            num: (hr * 12) + (min / 5) + 1,
            checkedIn: false,
            status: 'pending',
            listId: listId,
            listKey: listKey,
        }

        var apptKey = this.db.ref('user-appts/' + uid).push().key;

        var updates={};
        updates['user-appts/' + uid + '/' + apptKey] = apptData;

        return this.db.ref().update(updates);
    }

    userAppts = (uid) => this.db.ref('user-appts/' + uid);
    userAppt = (uid, aid) => this.db.ref('user-appts/' + uid + '/' + aid);

    removeAppt = (uid, aid) => this.db.ref('user-appts/' + uid + '/' + aid).remove();


    // USER INVITES API //

    invite = (inv) => this.db.ref('invites/' + inv);
    userInvite = (uid, inv) => this.db.ref('/user-invites/' + uid + '/' + inv);

    sessionConnect = (uid, session) => this.db.ref('user-casting-group-session-connect/' + uid + '/' + session);

    sendRoomInviteViaEmail = (company, uid, room, email, sent, session, roomName, attendees, sessionId, sessionMode, sessionName, sessionSchedule) => {


        clearTimeout(timer);

        var newSessionKey = this.db.ref('user-sessions/' + uid).push().key;

        var newInviteKey = this.db.ref('user-invites/' + uid).push().key;
        
        var userInviteData = {
            name: '',
            room: room,
            sent: sent,
            status: 'pending',
            expired: false,
            expiredAt: '',
            email: email,
            signup: false,
            session: session !== '' ? session : newSessionKey,
            sessionName: sessionName,
        };

        var inviteData = {
            n: sessionName,
            r: room,
            u: uid,
            c: company,
            e: email,
            s: session !== '' ? session : newSessionKey
        };

        const templateId = 'ani_session_invite';

        this.sendEmailInvite(templateId, {company: company, inv: newInviteKey, email: email})

        var updates={};
        updates['user-invites/' + uid + '/' + newInviteKey] = userInviteData;
        updates['invites/' + newInviteKey] = inviteData;

        this.db.ref().update(updates)

        if (session === '') {

            this.db.ref('session-agenda/' + newSessionKey)
            .update({
                company: company,
                room: roomName,
                usersInt: attendees !== '' ? attendees : '',
                usersExt: '',
                focus: '',
                talent: '',
                sessionName: sessionName,
                schedule: sessionSchedule,
                scheduleOff: true,
                countOff: false,
                customMark: false,
            });

            this.db.ref('user-sessions/' + uid + '/' + newSessionKey)
            .update({
                name: sessionName,
                room: room,
                date: sent,
            });
   
            
            this.db.ref('rooms/' + room)
            .update({
                activeSession: newSessionKey
            });

            this.db.ref('user-casting-group-session-connect/' + uid + '/' + sessionId)
            .update({
                group: newSessionKey
            });

            timer = setTimeout(() => {
                
                this.db.ref('session-agenda/' + newSessionKey + '/access/' + newInviteKey)
                .update({
                    email: email,
                });
                this.db.ref('user-sessions/' + uid + '/' + newSessionKey + '/access/' + newInviteKey)
                .update({
                    email: email,
                }) 
            }, 2000);

            clearTimeout(timer);

        } else {
            this.db.ref('user-sessions/' + uid + '/' + newSessionKey + '/access/' + newInviteKey)
            .update({
                email: email,
            });

            this.db.ref('session-agenda/' + session + '/access/' + newInviteKey)
            .update({
                email: email,
            });
        }
    }



    sendEmailInvite (templateId, variables) {
        emailjs.send('sendgridanioffice', templateId, variables, 'user_UXrnDajVZDtBHREeuIA0z')
        .then(res => {
            console.log('Sent')
        })
        .catch(err => console.log('Fail', err))
    }

    internalUsersAgenda = (session) => this.db.ref('session-agenda/' + session + '/usersInt');

    updateInternalUserToAgenda = (session, key) => this.db.ref('session-agenda/' + session + '/usersInt/' + key)
    .update({
        active: true,
    })

    addInternalUserToAgenda = (session, name, ref) => this.db.ref('session-agenda/' + session + '/usersInt/')
    .once('value', snapshot => {
        if (snapshot.exists()) {
            var o = snapshot.val();
            var length = o.length;
            var key = length + 1;
            this.db.ref('session-agenda/' + session + '/usersInt/' + key)
            .update({
                name: name,
                ref: ref,
                active: true,
            })
        }
    })

    deactivateInternalUserFromAgenda = (session, id) => this.db.ref('session-agenda/' + session + '/usersInt/' + id)
    .update({
        active: false,
    });

    addExternalUserToAgenda = (session, id, name, email) => this.db.ref('session-agenda/' + session + '/usersExt/' + id)
        .update({
            name: name,
            email: email,
            active: true,
        });

    tempUser = (uid) => this.db.ref('temp-users/' + uid);

    createTempUserFromInvite = (email, pass, name, inv, by, date, session) => {
        this.auth.createUserWithEmailAndPassword(email, pass)
        .then(authUser => {
            return (
                this.db.ref('temp-users/' + authUser.user.uid)
                .set({
                    name: name,
                    email: email,
                    inv: inv,
                    by: by,
                    date: date,
                    access: [session],
                    groupRef: this.groupRef(authUser.user.uid)
                }),
                this.db.ref('users/' + authUser.user.uid)
                .set({
                    name: name,
                    email: email,
                    type: 'guest',
                }))
            })
        .then(() => {
            this.db.ref('session-agenda/' + session)
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    this.addExternalUserToAgenda(session, inv, name, email);
                }
            })
            this.invite(inv).remove();
            this.userInvite(by, inv)
            .update({
                status: 'registered',
                expired: true,
                expiredAt: date,
                signup: true,
            })
        })
        .catch(error => {
            console.log(error);
        })

    };

    // SESSION CHECK INS //

    sessionKey = () => this.db.ref('session-agenda/').push().key;

    sessionInfo = (sess) => this.db.ref('session-agenda/' + sess);

    sessionTalentSelect = (sess, talent) => this.db.ref('session-agenda/' + sess + '/talent/' + talent);

    sessionSubjects = (sess) => this.db.ref('session-agenda/' + sess);

    sessionSchedule = (sess) => this.db.ref('session-agenda/' + sess + '/schedule/');

    sessionScheduleUser = (sess, position) => {

        this.db.ref('session-agenda/' + sess + '/schedule/' + position)   
    };

    checkInSessionUser = (groupKey, position) => this.db.ref('session-schedule/' + groupKey + '/schedule/' + position);
    checkInCastingUser = (uid, sess, position) => this.db.ref('user-casting-sessions/' + uid + '/' + sess + '/slotTimes/' + position);

    addSubjectToSessionTalent = (cid, subId, subName, topicName, subtopicName, topicId, subtopicId, subRep, sessionId) => {

        this.db.ref('session-agenda/' + sessionId)
        .update({
            focus: 'talent',
        })

        var subjectData = {
            cid: cid,
            subject: subName,
            topic: topicName,
            topicId: topicId,
            subtopic: subtopicName,
            subtopicId: subtopicId,
            subjectRep: subRep,
            active: true,
        }

        var updates={};
        updates['session-agenda/' + sessionId + '/talent/' + subId] = subjectData;

        return this.db.ref().update(updates);

    }

    deactivateSessionTalent = (sessionId, subId) => {
        this.db.ref('session-agenda/' + sessionId + '/talent/' + subId)
        .update({
            active: false,
        })
    };

    sessionMarker = (sess, id) => this.db.ref('session-agenda/' + sess + '/markers/' + id);
    
    addSessionTalentMarker = (sess, talent, id, by, name) => {

        this.db.ref('session-agenda/' + sess + '/talent/' + talent + '/markers/' + by)
        .update({
            mark: id,
            name: name,
        });
    }

    addCastingSessionMarker = (uid, castingSession, name) => {
        
        var data = {
            name: name, 
            active: true,
        }
        var castingKey = this.db.ref('user-casting-sessions/' + uid + '/' + castingSession + '/markers/').push().key;
        
        var updates = {}
        updates['/user-casting-sessions/' + uid + '/' + castingSession + '/markers/' + castingKey] = data;
        
        return this.db.ref().update(updates);
    
    }

    addSessionMarker = (sess, name, castingSession) => {
        var data = {
            name: name, 
            active: true,
            castingSession: castingSession
        }
        
        var groupKey = this.db.ref('session-agenda/' + sess + '/markers/').push().key;

        var updates = {}
        updates['session-agenda/' + sess + '/markers/' + groupKey] = data;

        return this.db.ref().update(updates);
    }

    generateSessionCode = (session, uGroupRef, uid) => {
        var data = {
            acc: '',
            code: '',
            sess: session,
            ref: uGroupRef,
        }

        var key = this.db.ref('session-share/').push().key;

        this.db.ref('session-access/' + uid)
        .update({
            ref: key,
        })
        
        var updates = {};
        updates['session-share/' + key] = data;

        return this.db.ref().update(updates);
    }

    // CASTING SESSIONS

    selectApptInviteResponse = (invite) => this.db.ref('appt-invite-response/' + invite);

    updateInviteStatus = (uid, sess, index, status) => {
        this.db.ref('user-casting-sessions/' + uid + '/' + sess + '/slotTimes/' + index)
        .update({
            invite: status,
        });
    }

    newCastingSessionInviteUser = (sendId, recId, email, p, pid, r, rid, date, time, name, sess, loc, index) => {

        var inviteData = {
            name: name,

        }
    }

    newCastingSessionInvite = (uid, email, p, pid, r, rid, date, time, name, sess, loc, index, ref, groupKey, company) => {

        var inviteData = {
            name: name,
            email: email,
            p: p,
            pid: pid,
            r: r,
            rid: rid,
            date: date,
            time: time,
            status: 'pending',
            ref: ref,
            groupKey: groupKey,
        }
        
        var acceptData = {
            email: email,
            accepted: false,
            reschedule: false,
            dismiss: false,
            recId: '',
            sess: sess,
            ref: ref,
            groupKey: groupKey,
        }
    
        var inviteKey = this.db.ref('user-appt-invites/' + uid + '/sess/' + sess).push().key;

        var company = company;
        var project = p;



        this.db.ref('user-casting-sessions/' + uid + '/' + sess + '/slotTimes/' + index)
        .update({
            inviteKey: inviteKey
        });

        this.db.ref('session-schedule/' + groupKey + '/schedule/' + index)
        .update({
            inviteKey: inviteKey
        });

        this.sentApptInvite('appt_invite', {company: company, project: project, time: time, date: date, name: name, email: email, invite: inviteKey});

        if (ref) {
            this.db.ref('talent-appts/' + ref + '/' + inviteKey)
            .update({
                p: p,
                pid: pid,
                r: r,
                rid: rid,
                date: date,
                time: time,
                status: 'pending',
            })
        };

        var updates={}
        updates['user-appt-invites/' + uid + '/sess/' + sess + '/' + inviteKey] = inviteData;
        updates['appt-invite-response/' + inviteKey] = acceptData;

        return this.db.ref().update(updates);

    }

    updateApptResponse = (invite, accept, dismiss, reschedule) => {
        this.db.ref('appt-invite-response/' + invite)
        .update({
            accepted: accept,
            dismiss: dismiss,
            reschedule: reschedule
        });
    };

    sentApptInvite (templateId, variables) {
        emailjs.send('sendgridanioffice', templateId, variables, 'user_UXrnDajVZDtBHREeuIA0z')
        .then(res => {
            console.log('Sent')
        })
        .catch(err => console.log('Fail', err))
    }

    draftNewCastingSession = (uid, sessKey) => {

        var sessData = {
            name: '',
            date: '',
            start: '',
            wrap: '',
            users: '',
            room: '',
            project: '',
            roles: '',
            draft: true,
            active: false,
            started: false,
            startTime: '',
            wrapped: false,
            wrapTime: '',
            prepped: false,
            talent: '',
        }

        var updates={}
        updates['user-casting-sessions/' + uid + '/' + sessKey] = sessData;

        return this.db.ref().update(updates)
    }

    addQuickCastingSession = (uid, name, date, start, wrap, room, users, project, roles, sessKey, groupKey) => {
        this.db.ref('user-casting-sessions/' + uid + '/' + sessKey)
        .update({
            name: name,
            date: date,
            start: start,
            wrap: wrap,
            room: room,
            groupKey: groupKey,
            activeSession: groupKey,
            active: false,
            started: false,
            startTime: '',
            wrapped: false,
            wrapTime: '',
            prepped: false,
            address: '',
        })
    }

    createNewCastingSession = (uid, name, date, start, wrap, room, project, sessKey, slotTimes, hasSche, address) => {


        var newSessionKey = this.db.ref('session-agenda/').push().key;

        this.db.ref('user-casting-sessions/' + uid + '/' + sessKey)
        .update({
            name: name,
            date: date,
            start: start,
            wrap: wrap,
            room: room,
            groupKey: newSessionKey,
            activeSession: newSessionKey,
            active: false,
            started: false,
            startTime: '',
            wrapped: false,
            wrapTime: '',
            prepped: false,
            address: address,
        })


        this.db.ref('session-agenda/' + newSessionKey)
        .update({
            company: '',
            room: '',
            usersInt: '',
            usersExt: '',
            focus: '',
            talent: '',
            sessionName: name,
            schedule: '',
            scheduleOff: true,
            countOff: false,
            customMark: false,
        });

        this.db.ref('user-sessions/' + uid + '/' + newSessionKey)
        .update({
            name: name,
            room: room,
            date: '',
        });

        this.db.ref('user-casting-group-session-connect/' + uid + '/' + sessKey)
        .update({
            group: newSessionKey
        });
        
    }

    castingSessionKey = (uid) => this.db.ref('user-casting-cessions/' + uid).push().key;
    


    selectCastingSessions = (uid) => this.db.ref('user-casting-sessions/' + uid);
    selectCastingSessionSchedule = (uid, cid) => this.db.ref('user-casting-sessions/' + uid + '/' + cid + '/slotTimes/');
    selectCastingSession = (uid, cid) => this.db.ref('user-casting-sessions/' + uid + '/' + cid);
    selectCastingSessionTalent = (uid, cid, talentId) => this.db.ref('user-casting-sessions/' + uid + '/' + cid + '/talent/' + talentId);
    selectCastingSessionTalentNote = (uid, cid, talentId, noteId) => this.db.ref('user-casting-sessions/' + uid + '/' + cid + '/talent/' + talentId + '/notes/' + noteId);
    selectCastingSessionTalentTag = (uid, cid, talentId, tagId) => this.db.ref('user-casting-sessions/' + uid + '/' + cid + '/talent/' + talentId + '/tags/' + tagId);
    userCastingSessions = (uid) => this.db.ref('user-casting-sessions/' + uid);
    addSubjectToSession = (uid, cid, talentId, name, rep, timestamp, roleId, role, checkInTime) => {
        this.selectCastingSessionTalent(uid, cid, talentId).update({
            name: name,
            notes: '',
            start: timestamp,
            rep: rep,
            tags: '',
            roleId: roleId,
            role: role,
            in: checkInTime,
        });
    }

    changeSessionName = (uid, cid, name) => {
        this.db.ref('user-casting-sessions/' + uid + '/' + cid)
        .update({
            name: name,
        });
    };

    changeSessionProject = (uid, cid, project) => {
        this.db.ref('user-casting-sessions/' + uid + '/' + cid)
        .update({
            project: project,
            roles: '',
        })
    };

    addUserToSessionFromQuick = (uid, cid, id, name) => {
        this.db.ref('user-casting-sessions/' + uid + '/' + cid + '/users/' + id)
        .update({
            name: name,
            avatar: '',
        });
    }

    addUserToSession = (uid, cid, user) => {
        this.db.ref('user-casting-sessions/' + uid + '/' + cid + '/users/' + user.value)
        .update({
            name: user.label,
            avatar: user.avatar,
        });
    };

    changeSessionRoom = (uid, cid, room) => {
        this.db.ref('user-casting-sessions/' + uid + '/' + cid)
        .update({
            room: room,
        })
    };

    

    removeUserFromSession = (uid, cid, user) => {
        this.db.ref('user-casting-sessions/' + uid + '/' + cid + '/users/' + user.value).remove();
    }

    addRoleToSessionFromQuick = (uid, cid, id, name) => {
        this.db.ref('user-casting-sessions/' + uid + '/' + cid + '/roles/' + id)
        .update({
            name: name,
        });
    }

    addRoleToSession = (uid, cid, role) => {
        this.db.ref('user-casting-sessions/' + uid + '/' + cid + '/roles/' + role.value)
        .update({
            name: role.label,
        });
    };

    removeRoleFromSession = (uid, cid, role) => {
        this.db.ref('user-casting-sessions/' + uid + '/' + cid + '/roles/' + role.value).remove();
    };

    addTalentToSessionSlot = (uid, cid, name, slotId, role) => {
        this.db.ref('user-casting-sessions/' + uid + '/' + cid + '/slotTimes/' + slotId)
        .update({
            name: name,
            role: role,
            status: 'Pending',
        })
    }
    
    createTalentFromSlot = (name, uid, talKey) => {
        var talentData = {
            name: name,
            createdBy: uid,
        };

        var updates = {};
        updates['temp-talent/' + talKey] = talentData;

        this.db.ref().update(updates);
    };

    addTalentToSessionSchedule = (uid, cid, name, time, role) => {

        var data = {
            name: name,
            time: time,
            role: role,
            status: 'Pending',
        }

        var key = this.db.ref('user-casting-sessions/' + uid + '/' + cid + '/schedule/').push().key;

        var updates={};
        updates['user-casting-sessions/' + uid + '/' + cid + '/schedule/' + key] = data;

        return this.db.ref().update(updates);

    }

    editSessionScheduledTalent = (uid, cid, ref) => this.db.ref('user-casting-sessions/' + uid + '/' + cid + '/' + ref);
    
    prepSecondSessionRoom = (uid, session, users, roles, groupKey, time, room) => {
        this.roomAttributes(room.id).update({ isActive: true, sessionMode: true, sessionId: session.id, activeSession: groupKey, isSecond: true });
        this.userRoomAttributes(uid, room.id).update({ isActive: true, sessionMode: true, sessionId: session.id, activeSession: groupKey });
        this.newRoomProject(uid, session.project.label, session.project.value, room.id, time);

        roles.map(i => {
            return (
                this.roleAttributes(i.id).update({ isActive: true }),
                this.projectRoleAttributes(session.project.value, i.id).update({ isActive: true }),
                this.newRoomRole(i.id, session.project.value, i.name, room.id, uid)
            );
        });

        users.map(u => {
            return (
                this.newRoomReader(uid, u.name, u.id, room.id, time)
            );
        });

        var room2 = {label: room.roomName, value: room.id};

        this.selectCastingSession(uid, session.id)
        .update({
            room2: room2
        })
    };

    prepSession = (uid, session, users, roles, time, groupKey) => {

        // ACTIVATE ROOM
        this.roomAttributes(session.room.value).update({ isActive: true, sessionMode: true, sessionId: session.id, activeSession: groupKey});
        this.userRoomAttributes(uid, session.room.value).update({ isActive: true, sessionMode: true, sessionId: session.id, activeSession: groupKey });

        // ACTIVATE PROJECT AND MOVE TO ROOM
        this.projectAttributes(session.project.value).update({ isActive: true });
        this.userProjectAttributes(uid, session.project.value).update({ isActive: true });
        this.newRoomProject(uid, session.project.label, session.project.value, session.room.value, time);

        // ACTIVE ROLES AND MOVE TO ROOM
        roles.map(i => {
            return (
                this.roleAttributes(i.id).update({ isActive: true }),
                this.projectRoleAttributes(session.project.value, i.id).update({ isActive: true }),
                this.newRoomRole(i.id, session.project.value, i.name, session.room.value, uid)
            );
        });

        // MOVE USERS TO ROOM
        users.map(u => {
            return (
                this.newRoomReader(uid, u.name, u.id, session.room.value, time)
            );
        })

        // UPDATE CASTING SESSION
        this.selectCastingSession(uid, session.id)
        .update({
            prepped: true,
            preptime: time,
            draft: false,
        })
    };

    activateSession = (uid, sessionId, time) => {
        this.selectCastingSession(uid, sessionId)
        .update({
            active: true,
            started: true,
            startTime: time,
            draft: false,
        });
    };

    resumeCastingSession = (uid, session, users, roles, time, groupKey) => {

        // ACTIVATE ROOM
        this.roomAttributes(session.room.value).update({ isActive: true, sessionMode: true, sessionId: session.id, activeSession: groupKey });
        this.userRoomAttributes(uid, session.room.value).update({ isActive: true, sessionMode: true, sessionId: session.id, activeSession: groupKey });

        // ACTIVATE PROJECT AND MOVE TO ROOM
        this.projectAttributes(session.project.value).update({ isActive: true });
        this.userProjectAttributes(uid, session.project.value).update({ isActive: true });
        this.newRoomProject(uid, session.project.label, session.project.value, session.room.value, time);

        // ACTIVE ROLES AND MOVE TO ROOM
        roles.map(i => {
            return (
                this.roleAttributes(i.id).update({ isActive: true }),
                this.projectRoleAttributes(session.project.value, i.id).update({ isActive: true }),
                this.newRoomRole(i.id, session.project.value, i.name, session.room.value, uid)
            );
        });

        // MOVE USERS TO ROOM
        users.map(u => {
            return (
                this.newRoomReader(uid, u.name, u.id, session.room.value, time)
            );
        })

        // UPDATE CASTING SESSION
        this.selectCastingSession(uid, session.id)
        .update({
            paused: false,
        })
    };

    pauseCastingSession = (uid, session, users, roles, time) => {

        // ACTIVATE ROOM
        this.roomAttributes(session.room.value).update({ 
            isActive: false, 
            sessionMode: false, 
            sessionId: '', 
            activeSession: '', 
            activeUsers: '',
            checkInRepCompany: '',
            checkInRole: '',
            roomReaders: '',
            activeRoles: '',
            activeProjects: '',
            activeCheckIn: false,
            checkInId: '',
            checkInName: '',
            checkInProject: '',
            checkInRole: '',
        });
        this.userRoomAttributes(uid, session.room.value).update({ isActive: false, sessionMode: false, sessionId: '',  activeSession: '' });

        if (session.room2) {
            this.roomAttributes(session.room2.value).update({ 
                isActive: false, 
                sessionMode: false, 
                sessionId: '', 
                activeSession: '', 
                activeUsers: '',
                checkInRepCompany: '',
                checkInRole: '',
                roomReaders: '',
                activeRoles: '',
                activeProjects: '',
                activeCheckIn: false,
                checkInId: '',
                checkInName: '',
                checkInProject: '',
                checkInRole: '',
                isSecond: false
            });
            this.userRoomAttributes(uid, session.room2.value).update({ isActive: false, sessionMode: false, sessionId: '',  activeSession: '', isSecond: false, });
        }

        // ACTIVATE PROJECT AND MOVE TO ROOM
        this.projectAttributes(session.project.value).update({ isActive: false });
        this.userProjectAttributes(uid, session.project.value).update({ isActive: false });

        this.db.ref('room-projects/')
        .orderByChild('roomId')
        .equalTo((session.room.value))
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                if (l) {
                    l.map(i => {
                        return (this.roomProject(i.id).remove(),
                        this.db.ref('rooms/' + session.room.value + '/activeProjects/' + i.id).remove());
                    })
                };
            };
        });


        // ACTIVE ROLES AND MOVE TO ROOM
        roles.map(i => {
            return (
                this.roleAttributes(i.id).update({ isActive: false }),
                this.projectRoleAttributes(session.project.value, i.id).update({ isActive: false }),
                this.db.ref('room-roles')
                .orderByChild('roomId')
                .equalTo((session.room.value))
                .once('value', snapshot => {
                    if (snapshot.exists()) {
                        var o = snapshot.val();
                        var l = Object.keys(o).map(key => ({
                            ...o[key],
                            id: key,
                        }))
                        if (l) {
                            l.map(i => {
                                return (this.roomRole(i.id).remove(),
                                this.db.ref('rooms/' + session.room.value + '/activeRoles/' + i.roleId).remove());
                            })
                        };
                    };
                })
            );
        });

        // MOVE USERS TO ROOM
        this.db.ref('room-readers')
        .orderByChild('roomId')
        .equalTo((session.room.value))
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                if (l) {
                    l.map(i => {
                        return (this.roomReader(i.id).remove(),
                        this.db.ref('rooms/' + session.room.value + '/roomReaders/' + i.id).remove());
                    })
                };
            };
        })

        // UPDATE CASTING SESSION
        this.selectCastingSession(uid, session.id)
        .update({
            paused: true,
        })
    };

    addRoomActiveUser = (rid, id, name) => this.db.ref('rooms/' + rid + '/activeUsers/' + id).update({ username: name });


    completeCastingSession = (uid, session, users, roles, time) => {
    
        // ACTIVATE ROOM
        this.roomAttributes(session.room.value).update({ 
            isActive: false, 
            sessionMode: false, 
            sessionId: '', 
            activeSession: '', 
            activeUsers: '',
            checkInRepCompany: '',
            checkInRole: '',
            roomReaders: '',
            activeRoles: '',
            activeProjects: '',
            activeCheckIn: false,
            checkInId: '',
            checkInName: '',
            checkInProject: '',
            checkInRole: '',
        });
        this.userRoomAttributes(uid, session.room.value).update({ isActive: false, sessionMode: false, sessionId: '',  activeSession: '' });

        if (session.room2) {
            this.roomAttributes(session.room2.value).update({ 
                isActive: false, 
                sessionMode: false, 
                sessionId: '', 
                activeSession: '', 
                activeUsers: '',
                checkInRepCompany: '',
                checkInRole: '',
                roomReaders: '',
                activeRoles: '',
                activeProjects: '',
                activeCheckIn: false,
                checkInId: '',
                checkInName: '',
                checkInProject: '',
                checkInRole: '',
                isSecond: false,
            });
            this.userRoomAttributes(uid, session.room2.value).update({ isActive: false, sessionMode: false, sessionId: '',  activeSession: '', isSecond: false, });
        }

        // ACTIVATE PROJECT AND MOVE TO ROOM
        this.projectAttributes(session.project.value).update({ isActive: false });
        this.userProjectAttributes(uid, session.project.value).update({ isActive: false });

        this.db.ref('room-projects/')
        .orderByChild('roomId')
        .equalTo((session.room.value))
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                if (l) {
                    l.map(i => {
                        return (this.roomProject(i.id).remove(),
                        this.db.ref('rooms/' + session.room.value + '/activeProjects/' + i.id).remove());
                    })
                };
            };
        });


        // ACTIVE ROLES AND MOVE TO ROOM
        roles.map(i => {
            return (
                this.roleAttributes(i.id).update({ isActive: false }),
                this.projectRoleAttributes(session.project.value, i.id).update({ isActive: false }),
                this.db.ref('room-roles')
                .orderByChild('roomId')
                .equalTo((session.room.value))
                .once('value', snapshot => {
                    if (snapshot.exists()) {
                        var o = snapshot.val();
                        var l = Object.keys(o).map(key => ({
                            ...o[key],
                            id: key,
                        }))
                        if (l) {
                            l.map(i => {
                                return (this.roomRole(i.id).remove(),
                                this.db.ref('rooms/' + session.room.value + '/activeRoles/' + i.roleId).remove());
                            })
                        };
                    };
                })
            );
        });

        // MOVE USERS TO ROOM
        this.db.ref('room-readers')
        .orderByChild('roomId')
        .equalTo((session.room.value))
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                if (l) {
                    l.map(i => {
                        return (this.roomReader(i.id).remove(),
                        this.db.ref('rooms/' + session.room.value + '/roomReaders/' + i.id).remove());
                    })
                };
            };
        })

        if (session.room2) {
            this.db.ref('room-readers')
            .orderByChild('roomId')
            .equalTo((session.room2.value))
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({
                        ...o[key],
                        id: key,
                    }))
                    if (l) {
                        l.map(i => {
                            return (this.roomReader(i.id).remove(),
                            this.db.ref('rooms/' + session.room2.value + '/roomReaders/' + i.id).remove());
                        })
                    };
                };
            })
        }

        // UPDATE CASTING SESSION
        this.selectCastingSession(uid, session.id)
        .update({
            complete: true,
            completeTime: time,
        })
    };

    // Talent List

    userQuickLists = (uid) => this.db.ref('/user-quick-lists/' + uid);

    draftTalentList = (uid, list) => {
        this.db.ref('/user-quick-lists/' + uid + '/' + list)
        .update({
            createdAt: new Date(),
            date: '',
            project: '',
            projectId: '',
            role: '',
            start: '',
            wrap: '',
            increment: '',
            slots: '',
            draft: true,
            list: '',
        })
    };

    updateTalentList = (uid, project, roles, date, start, wrap, increment, slots, listKey, sessKey) => {

        var data = {
            date: date,
            project: project.label,
            projectId: project.value,
            roles: roles,
            start: start,
            wrap: wrap,
            increment: increment,
            slots: slots,
            list: '',
            sessKey: sessKey,
        };
        
        var updates = {};
        updates['/user-quick-lists/' + uid + '/' + listKey] = data;

        return this.db.ref().update(updates);
    };

    /*updateTalentList = (uid, project, projectId, role, roleId, date, start, wrap, increment, slots, listKey) => {

        var data = {
            date: date,
            project: project,
            projectId: projectId,
            role: role,
            roleId: roleId,
            start: start,
            wrap: wrap,
            increment: increment,
            slots: slots,
            taken: '',
            confirmed: '',
            reschedule: '',
            list: '',
        };
        
        var updates = {};
        updates['/user-quick-lists/' + uid + '/' + listKey] = data;

        return this.db.ref().update(updates);
    };*/

    addSlotTimesToList = (uid, list, slotTimes) => this.db.ref('/user-quick-lists/' + uid + '/' + list).update({ slotTimes: slotTimes });
    addSlotTimesToSession = (uid, session, slotTimes) => this.db.ref('/user-casting-sessions/' + uid + '/' + session).update({ slotTimes: slotTimes });
    
    createSessionSchedule = (castSess, groupSess, groupRef, slotTimes) => {
        var data = {
            castingSess: castSess,
            owner: groupRef,
            schedule: slotTimes,
            access: '',
        };

        var updates = {};
        updates['session-schedule/' + groupSess] = data;

        if (groupSess) {
            return this.db.ref().update(updates);
        }
    };

    selectSessionSchedule = (groupKey) => this.db.ref('session-schedule/' + groupKey + '/schedule/');

    quickTalentListSelect = (uid, list) => this.db.ref('/user-quick-lists/' + uid + '/' + list);
    quickTalentListSelectSlots = (uid, list) => this.db.ref('/user-quick-lists/' + uid + '/' + list + '/slotTimes/');
    quickTalentListSelectSlot = (uid, list, slot) => this.db.ref('/user-quick-lists/' + uid + '/' + list + '/slotTimes/' + slot);

    updateTalentListSlot = (uid, list, slot, name, groupKey) => {
        this.db.ref('/user-quick-lists/' + uid + '/' + list + '/slotTimes/' + slot)
        .update({
            name: name,
            status: 'Pending',
        });

        this.db.ref('session-schedule/' + groupKey + '/schedule/' + slot)
        .update({
            name: name,
            status: 'Pending',
        });
    }

    updateSessionSlotRole = (uid, sess, slot, role, roleId, groupKey) => {
        this.db.ref('/user-casting-sessions/' + uid + '/' + sess + '/slotTimes/' + slot)
        .update({
            role: role,
            roleId: roleId,
        });

        this.db.ref('session-schedule/' + groupKey + '/schedule/' + slot)
        .update({
            role: role,
            roleId: roleId,
        });

    }
    
    updateTalentListSlotRole = (uid, list, slot, role, roleId, groupKey) => {
        this.db.ref('/user-quick-lists/' + uid + '/' + list + '/slotTimes/' + slot)
        .update({
            role: role,
            roleId: roleId,
        })

        this.db.ref('session-schedule/' + groupKey + '/schedule/' + slot)
        .update({
            role: role,
            roleId: roleId,
        });
    }

    addTalentToList = (item) => {
        console.log(item);
    };


    slotSwitch = (uid, sessKey, slot1, slot2, pos1, pos2, groupKey) => {
        var data1 = {
            ...slot1,
            time: slot2.time,
        };

        var data2 = {
            ...slot2,
            time: slot1.time,
        }

        this.db.ref('user-casting-sessions/' + uid + '/' + sessKey + '/slotTimes/' + pos2)
        .update({
            ...data1
        });

        this.db.ref('session-schedule/' + groupKey + '/schedule/' + pos2)
        .update({
            ...data1
        });

        if (slot2.name) {
            this.db.ref('user-casting-sessions/' + uid + '/' + sessKey + '/slotTimes/' + pos1)
            .update({
                ...data2
            });
            this.db.ref('session-schedule/' + groupKey + '/schedule/' + pos1)
            .update({
                ...data2
            });
        } else {
            this.db.ref('user-casting-sessions/' + uid + '/' + sessKey + '/slotTimes/' + pos1)
            .update({
                time: slot1.time,
                invite: false,
                name: '',
                status: 'Open',
                tempId: '',
                avatar: '',
            });
            this.db.ref('session-schedule/' + groupKey + '/schedule/' + pos1)
            .update({
                time: slot1.time,
                invite: false,
                name: '',
                status: 'Open',
                tempId: '',
                avatar: '',
            });
        }

    }


    updateSlotSession = (uid, sessKey, slot, name, groupKey) => {
        var talKey = this.db.ref('temp-talent').push().key;
        this.createTalentFromSlot(name, uid, talKey);
        this.db.ref('/user-casting-sessions/' + uid + '/' + sessKey + '/slotTimes/' + slot)
        .update({
            name: name,
            status: 'Draft',
            tempId: talKey,
        });

        this.db.ref('session-schedule/' + groupKey + '/schedule/' + slot)
        .update({
            name: name,
            status: 'Draft',
            tempId: talKey,
        });
    }

    updateSlotSessionUser = (uid, sessKey, slot, user, groupKey) => {
        this.db.ref('/user-casting-sessions/' + uid + '/' + sessKey + '/slotTimes/' + slot)
        .update({
            name: user.name,
            status: 'Draft',
            ref: user.id,
            avatar: user.avatar
        })

        this.db.ref('session-schedule/' + groupKey + '/schedule/' + slot)
        .update({
            name: user.name,
            status: 'Draft',
            ref: user.id,
            avatar: user.avatar
        });
    }

    clearSlotSession = (uid, sessKey, slot, groupKey) => {
        this.db.ref('/user-casting-sessions/' + uid + '/' + sessKey + '/slotTimes/' + slot)
        .update({
            name: '',
            role: '',
            roleId: '',
            status: 'Open',
            avatar: '',
        });

        this.db.ref('session-schedule/' + groupKey + '/schedule/' + slot)
        .update({
            name: '',
            role: '',
            roleId: '',
            status: 'Open',
            avatar: '',
        });
    }

    quickAddTalent = (uid, list, name, date, slotTime, slotId, sessKey, groupKey) => {
        
        var newKey = this.db.ref('/user-quick-lists/' + uid + '/' + list).push().key;

        this.db.ref('/user-casting-sessions/' + uid + '/' + sessKey + '/slotTimes/' + slotId)
        .update({
            name: name,
            status: 'Pending',
        })

        this.db.ref('session-schedule/' + groupKey + '/schedule/' + slotId)
        .update({
            name: name,
            status: 'Pending',
        });
    
        this.db.ref('/user-quick-lists/' + uid + '/' + list + '/list/' + newKey)
        .update({
            name: name,
            date: date,
            time: slotTime,
            submitted: false,
            status: 'Pending',
        })

        this.quickTalentListSelectSlot(uid, list, slotId)
        .update({
            name: name,
            ref: newKey,
            status: 'Pending',
        })
    }

    quickListTalentSelect = (uid, list, talent) => this.db.ref('user-quick-lists/' + uid + '/' + list + '/list/' + talent);

    

    // TALENT USERS 

    talentUser = (uid) => this.db.ref('talent-users/' + uid);

    updateTalentSearch = (uid) => this.db.ref('talent-search/' + uid);

    talentSearch = () => this.db.ref('talent-search');

    talentESearch = (uid) => this.db.ref('talent-email/' + uid);

    talentAppts = (uid) => this.db.ref('talent-appts/' + uid);

    createTalentUser = (uid, name, email, date) => {

        var userData = {
            name: name,
            email: email,
            avatar: '',
            rep: '',
            date: date,
            type: 'talent',
        }

        var userSearch = {
            name: name,
            avatar: '',
            rep: '',
        }

        var emailSearch = {
            email: email,
        }

        const updates = {};
        updates['talent-users/' + uid] = userData;
        updates['talent-search/' + uid] = userSearch;
        updates['talent-email/' + uid] = emailSearch;

        return this.db.ref().update(updates);
    }

    talentUserAuditions = (id) => {

    }

    // AUDITION TAKES 

    userAuditionTakes = (uid) => this.db.ref('user-audition-takes/' + uid);

    userAuditionTakesTalent = (uid, talentId) => this.db.ref('user-audition-takes/' + uid + '/' + talentId + '/takes/');

    userAuditionTakesTalentTake = (uid, talentId, takeId) => this.db.ref('user-audition-takes/' + uid + '/' + talentId + '/takes/' + takeId);

    userAuditionSessionTakesTalentTake = (uid, session, talentId, takeId) => this.db.ref('user-session-audition-takes/' + uid + '/' + session + '/' + talentId + '/takes/' + takeId);

    userAuditionSessionTakes = (uid, session) => this.db.ref('user-session-audition-takes/' + uid + '/' + session);

    addUserAuditionTake = (uid, talentId, number, roleId, projectId, talent, time, date, takeKey, sessionActive, session) => {

        var takeData = {
            number: number,
            keep: '',
            note: '',
            roleId: roleId,
            projectId: projectId,
            favorite: '',
            access: '',
            talent: talent,
            time: time,
            date: date,
        };

        var updates={};
        updates['user-audition-takes/' + uid + '/' + talentId + '/takes/' + takeKey] = takeData;
        if (sessionActive) {
            updates['user-session-audition-takes/' + uid + '/' + session + '/' + talentId + '/takes/' + takeKey] = takeData;
        };
        return this.db.ref().update(updates);
    }

    addUserAuditionTakeMark = (uid, talent, take, time, sessionActive, session) => {

        var key = this.db.ref('user-audition-takes/' + uid + '/' + talent + '/takes/' + take + '/marks').push().key;

        var data = {
            time: time,
        };

        var updates = {};
        updates['user-audition-takes/' + uid + '/' + talent + '/takes/' + take + '/marks/' + key] = data;
        if (sessionActive) {
            updates['user-session-audition-takes/' + uid + '/' + session + '/' + talent + '/takes/' + take + '/marks/' + key] = data;
        };

        return this.db.ref().update(updates);
    };

    // JOB FEED 

    jobFeed = () => this.db.ref('job-feed');
    jobFeedSelect = (ref) => this.db.ref('job-feed/' + ref);

    userJobFeed = (uid) => this.db.ref('user-job-feed/' + uid);
    userJobFeedSelect = (uid, ref) => this.db.ref('user-job-feed/' + uid + '/' + ref);

    addToFeed = (uid, company, date, time, role, roleId, project, projectId, tags, min, max, local, sag, gender, fluid, status) => {

        var feedData = {
            company: company,
            date: date,
            time: time,
            role: role,
            project: project,
            tags: tags,
            min: min,
            max: max,
            gender: gender,
            fluid: fluid,
            status: status,
            local: local,
            sag: sag,
        };

        var userFeedData = {
            date: date,
            time: time,
            roleId: roleId,
            projectId: projectId,
        };

        var userFeedCountData = {
            talSubmissions: '',
            repSubmissions: '',
        }

        var refKey = this.db.ref('job-feed').push().key;

        var updates = {};
        updates['job-feed/' + refKey] = feedData;
        updates['user-job-feed/' + uid + '/' + refKey] = userFeedData;
        updates['user-job-feed-count/' + uid + '/' + refKey] = userFeedCountData;

        return this.db.ref().update(updates);

    }

    newPres = (uid, title, cards, date) => {

        var data = {
            creator: uid, 
            title: title,
            cards: cards,
            access: '',
            views: '',
            date: date,
            share: false,
        }

        var key = this.db.ref('api/pres-decks/').push().key;

        var updates={};
        updates['api/pres-descks/' + key] = updates;

        return this.db.ref().update(updates);


    }

    // TALENT ACTIVATION EMAIL

    getActivationCode = (code) => this.db.ref('talent-activation-codes/' + code);

    sendTalentActivation = (name, email, uid, company, timestamp) => {

        var code = this.db.ref('talent-activation-codes').push().key;

        var data = {
            name: name,
            email: email,
            company: company,
            timestamp: timestamp,
            accepted: false,
            opened: false,
            openedOn: '',
        };

        var updates={};
        updates['talent-activation-codes/' + code] = data;

        var variables = {name: name, email: email, code: code};

        this.sendActivationEmail(variables);

        return this.db.ref().update(updates);
    };

    sendActivationEmail (variables) {
        emailjs.send('sendgridanioffice', 'talentactivation', variables, 'user_UXrnDajVZDtBHREeuIA0z')
        .then(res => {
            console.log('Sent')
        })
        .catch(err => console.log('Fail', err))
    }


    // PRESENTATIONS 

    presentations = () => this.db.ref('api/presentations');
    presentation = (id) => this.db.ref('api/presentations/' + id);

    userPresentations = (uid) => this.db.ref('user-presentations/' + uid);
    userPresentation = (uid, id) => this.db.ref('user-presentations/' + uid + '/' + id);

    presentationAccess = (id) => this.db.ref('api/presentation-access/' + id);
    presentationAccessUser = (id, uid) => this.db.ref('api/presentation-access/' + id + '/' + uid);

    slides = () => this.db.ref('api/slides');
    slide = (id) => this.db.ref('api/slides/' + id);
    slideVersion = (id, num) => this.db.ref('api/slides/' + id + '/versions/' + num);

    deckSlides = (id) => this.db.ref('api/deck-slides/' + id);
    deckSlide = (id, slide) => this.db.ref('api/deck-slides/' + id + '/' + slide);

    createSlide = (uid, id, timestamp, layout, key) => {

        var data = {
            createdOn: timestamp,
            origin: id,
            owner: uid,
            versions: '',
            layout: layout,
            header: '',
            subheader: '',
            text1: '',
            text2: '',
            text3: '',
            customStyle: '',
        }

        var deckData = {
            createdOn: timestamp,
            owner: uid,
            layout: layout,
            header: '',
            subheader: '',
            text1: '',
            text2: '',
            text3: '',
            customStyle: '',

        }

        var newKey = key ? key : this.db.ref('api/slides/').push().key;

        var updates = {};
        updates['api/slides/' + newKey] = data;
        updates['api/deck-slides/' + id + '/' + newKey] = deckData;

        return this.db.ref().update(updates);

    }

    createPresentation = (uid, timestamp, preKey) => {

        var code = this.db.ref('api/pres-codes').push().key;
        console.log(code);

        var data = {
            createdOn: timestamp,
            owner: uid,
            private: true,
            slides: '',
            views: 0,
            shares: 0,
            archived: false,
            activeShow: '',
            title: '',
            live: code,
        }

        var userData = {
            title: '',
            views: 0,
            shares: 0,
            private: true,
        }

        var key = preKey ? preKey : this.db.ref('api/presentations').push().key;

        var codeData = {
            pres: key
        }

        var updates = {};
        updates['api/presentations/' + key] = data;
        updates['user-presentations/' + uid + '/' + key] = userData;
        updates['api/pres-codes/' + code] = codeData;

        return this.db.ref().update(updates);
    }

    livePresentations = () => this.db.ref('api/live-presentations');
    livePresentation = (id) => this.db.ref('api/live-presentations/' + id);

    newLinkToLive = (link, id) => this.db.ref('api/live-link-pres/' + link)
    .update({
        deck: id
    });

    newUserLiveLink = (uid, link, id) => this.db.ref('user-live-links/' + uid + '/' + link)
    .update({
        deck: id,
    });

    generateLiveLink = (id) => this.db.ref('api/pres-links/' + id).push().key;

    addLiveLink = (id, link, time) => {
        this.db.ref('api/pres-links/' + id + '/links/' + link)
        .update({
            timestamp: time,
        })
    };

    addLiveSlide = (id, slideId, slide) => {
        var data = {
            ...slide
        }

        var updates = {};
        updates['api/live-presentations/' + id + '/slides/' + slideId] = data;

        return this.db.ref().update(updates);
    }

    createLivePresentation = (id, time, codeNeeded, code, groupRef) => {

        var data = {
            created: time,
            started: '',
            wrapped: '',
            accessType: 'link',
            access: '',
            activeViewers: '',
            oldViewers: '',
            codeNeeded: codeNeeded,
            code: codeNeeded ? code : '',
            host: groupRef,
            focus: '',
            live: false,
        };

        var updates = {};
        updates['api/live-presentations/' + id] = data;

        return this.db.ref().update(updates);

    };

    // UPDATES API //

    updates = () => this.db.ref('api/updates/');
    features = () => this.db.ref('api/updates/features/');

    // USER API //

    timestamp = () => moment().format('MM/DD/YYYY hh:mm:ss a');
    
    apiUser = (uid) => this.db.ref('api/users/' + uid);
    apiUserInfo = (uid) => this.db.ref('api/users/' + uid + '/info/');
    apiCompany = (company) => this.db.ref('api/companies/' + company);
    apiCompanyAccount = (company, uid) => this.db.ref('api/companies/' + company + '/accounts/' + uid);
    apiCompanyAccounts = (company) => this.db.ref('api/companies/' + company + '/accounts/');
    
    apiCompanyKey = () => this.db.ref('api/companies/').push().key;
    apiUserRef = () => this.db.ref('api/user-refs/').push().key;
    
    newSignUp = (uid, user, timestamp) => {

        this.user(uid)
        .update({
            name: user.name,
            email: user.email,
            created: timestamp,
            companyName: user.company,
            permission: 'admin',
        })

    }

    companyAccountRecover = (id) => this.db.ref('verify/company/' + id);

    createAccountFromRecover = (pck) => {

        var data = {
            name: pck.name,
            email: pck.email,
            created: this.timestamp(),
            companyName: pck.companyName,
            permission: 'basic',
        }
    }

    apiTempAccount = (cid, id) => this.db.ref('api/companies/' + cid + '/temps/' + id);
    newCompanyAccount = (pck) => {

        var link = process.env.NODE_ENV === 'production'
        ? 'https://www.anicheckin.com/verification/company#' + pck.tempKey
        : 'localhost:3000/verification/company#' + pck.tempKey;

        var coData = {
            name: pck.name,
            email: pck.email,
            permission: 'basic',
            avatar: '',
            timestamp: this.timestamp(),
        };

        var verData = {
            code: pck.code,
            email: pck.email,
            name: pck.name,
            cid: pck.company,
            co: pck.companyName,
            
        };

        var updates = {};
        updates['api/companies/' + pck.company + '/temps/' + pck.tempKey] = coData;
        updates['verify/company/' + pck.tempKey] = verData;

        var template_params = {
            "email": pck.email,
            "myemail": pck.myemail,
            "name": pck.name,
            "company": pck.companyName,
            "link": link,
            "code": pck.code,
         }
         
         var service_id = "default_service";
         var template_id = "companyinvite";
         emailjs.send(service_id, template_id, template_params, 'user_UXrnDajVZDtBHREeuIA0z')
            .then(res => {
                console.log('Sent')
            })
            .catch(err => console.log('Fail', err));

         return this.db.ref().update(updates);

    }


    sendCompanyAccountEmail = (pck) => {
        this.auth.sendSignInLinkToEmail(pck.email,)
    }

    newCompany = (uid, user, company, email, cid) => {

        this.apiCompany(cid)
        .update({
            office: '',
            admin: uid,
            created: this.timestamp(),
            name: company,
            type: '',
            files: {
                logo: '',
            },
            greeting: '',
            affiliations: [{
                name: 'General'
            }]
        })
        .then(() => {
            this.apiCompanyAccount(cid, uid)
            .update({
                name: user,
                email: email,
                permission: 'admin',
                avatar: '',
                emailNotifications: false,
            });
        })
    };

    // POSITIONS API // 

    apiCompanyPositions = (cid) => this.db.ref('api/companies/' + cid + '/positions/');
    apiCompanyPosition = (cid, pos) => this.db.ref('api/companies/' + cid + '/positions/' + pos);
    apiCompanyAffiliations = (cid) => this.db.ref('api/companies/' + cid + '/affiliations/');
    apiCompanyAffiliation = (cid, aff) => this.db.ref('api/companies/' + cid + '/affiliations/' + aff);

    apiNewCompanyPosition = (uid, cid, data) => {
        

        var key = this.apiCompanyPositions(cid).push().key;
        var affKey = data.affKey ? data.affKey : this.apiCompanyAffiliations(cid).push().key;
        var aff = data.aff ? data.aff : 'General';

        var affKey = data.affKey ? data.affKey :
            !data.affKey && data.aff ? this.apiCompanyAffiliations(cid).push().key :
            0

        var company = {
            name: data.name,
            aff: aff,
            affKey: affKey,
            created: this.timestamp(),
            uid: uid,
            status: 'open',
            active: false,
            archive: false,
        };

        var affdata = {
            name: data.aff,
        }

        var updates = {};
        updates['api/companies/' + cid + '/positions/' + key] = company;
        if (affKey !== 0) {
            updates['api/companies/' + cid + '/affiliations/' + affKey] = affdata;
        };
        return this.db.ref().update(updates);

    }

    // ALERT API //

    apiAlert = (cid, alert) => this.db.ref('api/companies/' + cid + '/alerts/' + alert);

    apiNewAlert = (pck) => {

        var data = {
            room: pck.room,
            time: this.timestamp(),
            name: pck.name,
            by: pck.by,
        };

        var key = this.db.ref('api/companies/' + pck.cid + '/alerts/').push().key;

        var updates = {};
        updates['api/companies/' + pck.cid + '/alerts/' + key] = data;

        return this.db.ref().update(updates);

    }

    // SESSION API //

    apiSession = (id) => this.db.ref('api/sessions/' + id);
    apiSessions = () => this.db.ref('api/sessions');
    apiCompanySession = (cid, id) => this.db.ref('api/companies/' + cid + '/sessions/' + id);
    apiCompanySessions = (cid, id) => this.db.ref('api/companies/' + cid + '/sessions/');

    apiInviteKey = () => this.db.ref('verify/invites/').push().key;

    apiSessionInvite = (id) => this.db.ref('verify/invites/' + id);
    apiNewSessionInvite = (pck) => {

        var timestamp = this.timestamp();
        var key = this.apiInviteKey();

        var link = 'https://www.anicheckin.com/verification/invite#' + key;

        var data = {
            name: pck.name,
            email: pck.email,
            cname: pck.cname,
            id: pck.id,
            timestamp: timestamp,
        }

        var session = {
            name: pck.name,
            email: pck.email,
            status: 'sent',
            timestamp: timestamp,
            link: link,
        }

        var template_params = {
            "email": pck.email,
            "company": pck.cname,
            "name": pck.name,
            "link": link
         }
         
         var service_id = "default_service";
         var template_id = "ani_session_invite";
         emailjs.send(service_id, template_id, template_params, 'user_UXrnDajVZDtBHREeuIA0z')
         .then(res => {
             console.log('Sent')
         })
         .catch(err => console.log('Fail', err));

         var updates={};
         updates['verify/invites/' + key] = data;
         updates['api/sessions/' + pck.id + '/guests/' + key] = session;

         return this.db.ref().update(updates);
    }

    apiNewSession = (uid, cid, key, timestamp, day, status) => {

        var data = {
            title: '',
            positions: '',
            subjects: '',
            host: uid,
            company: cid,
            team: '',
            guests: '',
            created: timestamp,
            date: day,
            dateLong: '',
            start: '',
            times: '',
            status: status,
            location: {
                type: 'office',
                address: {
                    line1: '',
                    line2: '',
                    city: '',
                    state: '',
                    zip: '',
                    parking: '',
                    contact: '',
                    tel: '',
                },
                office: '',
            },
        }

        var user = {
            created: timestamp,
            date: day,
            positions: '',
        }

        var updates = {};
        updates['api/sessions/' + key] = data;
        updates['api/companies/' + cid + '/sessions/' + key] = user;

        return this.db.ref().update(updates);

    }

    apiSessionSubject = (session, sid) => this.db.ref('api/sessions/' + session + '/subjects/' + sid);
    apiSessionSubjects = (session) => this.db.ref('api/sessions/' + session + '/subjects/');

    apiNewSessionSubject = (session, sid, pck) => {
        var data = {
            ...pck,
        };
        var updates = {};
        updates['api/sessions/' + session + '/subjects/' + sid] = data;
        return this.db.ref().update(updates);
    }

    apiSessionSubjectNote = (sess, sid, nid) => this.db.ref('api/sessions/' + sess + '/subjects/' + sid + '/notes/' + nid);
    apiSessionSubjectNotes = (sess, sid) => this.db.ref('api/sessions/' + sess + '/subjects/' + sid + '/notes/');
    apiCompanySubjectNote = (cid, sid, nid) => this.db.ref('api/company-guests/' + cid + '/' + sid +'/notes/' + nid);
    apiNewSessionNote = (pck, usertype) => {

        var data = {
            username: pck.username,
            user: pck.user,
            note: pck.note,
            created: this.timestamp(),
            privacy: pck.privacy,
        }

        var key = this.db.ref('api/company-guests/' + pck.cid + '/' + pck.sid + '/notes/').push().key;

        var updates={};
        var coUpdates={};

        if (!usertype || !usertype === 'Guest') {
            var com = {
                username: pck.username,
                user: pck.user,
                note: pck.note,
                created: this.timestamp(),
                session: pck.session,
                privacy: pck.privacy,
            }
            coUpdates['api/company-guests/' + pck.cid + '/' + pck.sid + '/notes/' + key] = com;
            this.db.ref().update(coUpdates);
        };

    
        updates['api/sessions/' + pck.session + '/subjects/' + pck.sid + '/notes/' + key] = data;

        return this.db.ref().update(updates);

    }

    

    // * TAGS * //
    apiCompanyTags = (cid) => this.db.ref('api/companies/' + cid + '/tags/');
    apiCompanyTag = (cid, tag) => this.db.ref('api/companies/' + cid + '/tags/' + tag);
    apiNewCompanyTag = (pck) => {

        var key = this.apiCompanyTags(pck.cid).push().key;

        var data = {
            name: pck.name.toLowerCase(),
            created: this.timestamp(),
            by: pck.uid,
            uses: 0,
            groups: '',
        }

        var updates={};
        updates['api/companies/' + pck.cid + '/tags/' + key] = data;

        this.db.ref('admin/tags/' + pck.name.toLowerCase() + '/' + pck.cid)
        .update({
            created: this.timestamp()
        });

        return this.db.ref().update(updates);

    }

    apiSessionSubjectTag = (sess, sub, tag) => this.db.ref('api/sessions/' + sess + '/subjects/' + sub  + '/tags/' + tag);
    apiSessionSubjectTags = (sess, sub) => this.db.ref('api/sessions/' + sess + '/subjects/' + sub  + '/tags/');
    apiCompanySubjectTag = (cid, sub, tag) => this.db.ref('api/company-guests/' + cid + '/' + sub + '/tags/' + tag);

    apiRemoveSubjectTag = (pck) => {

        this.apiSessionSubjectTag(pck.session, pck.sid, pck.tag.id).remove();
        this.apiCompanySubjectTag(pck.cid, pck.sid, pck.tag.id).remove();
        this.apiCompanyTag(pck.cid, pck.tag.id)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var uses = o.uses;
                this.apiCompanyTag(pck.cid, pck.tag.id)
                .update({
                    uses: uses - 1,
                });
            }
        });
    }
    
    apiNewSessionTag = (pck, usertype) => {

        var data = {
            username: pck.username,
            user: pck.user,
            created: this.timestamp(),
            privacy: pck.privacy,
            name: pck.tag.name,
        }

        var key = this.db.ref('api/company-guests/' + pck.cid + '/' + pck.sid + '/tags/').push().key;

        var updates={};
        var coUpdates={};

        if (!usertype || !usertype === 'Guest') {
            var com = {
                username: pck.username,
                user: pck.user,
                tag: pck.tag.name,
                created: this.timestamp(),
                session: pck.session,
                privacy: pck.privacy,
            }
            coUpdates['api/company-guests/' + pck.cid + '/' + pck.sid + '/tags/' + pck.tag.id] = com; 
            this.db.ref().update(coUpdates);
        };

        this.apiCompanyTag(pck.cid, pck.tag.id)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var uses = o.uses;
                this.apiCompanyTag(pck.cid, pck.tag.id)
                .update({
                    uses: uses + 1,
                });
            }
        });
       
        updates['api/sessions/' + pck.session + '/subjects/' + pck.sid + '/tags/' + pck.tag.id] = data;

        return this.db.ref().update(updates);
    }

    // * SESSION SCHEDULE * //
    apiSessionSlot = (sess, slot) => this.db.ref('api/sessions/' + sess + '/schedule/' + slot);
    apiAddSessionSlot = (pck) => {

        var talKey = this.apiCompanyGuests(pck.cid).push().key;

        var data = {
            name: pck.name,
            email: pck.email,
            phone: pck.phone,
            via: pck.via,
            account: pck.aid,
            picture: pck.apic,
            time: pck.time,
            created: this.timestamp(),
            status: 'draft',
            position: pck.position,
            by: pck.uid,
            rep: {
                name: pck.rep.name,
                email: pck.rep.email,
                phone: pck.rep.phone,
            },
            messages: '',
            to: pck.to,
            ref: talKey,
        }

        var slotKey = this.db.ref('api/sessions/' + pck.session + '/schedule/').push().key;

        var updates={};
        updates['api/sessions/' + pck.session + '/schedule/' + slotKey] = data;

        return this.db.ref().update(updates);
    }

    apiInvite = (invite) => this.db.ref('api/invites/' + invite);

    apiSendApptInvite = (pck) => {

        var inviteKey = this.db.ref('api/invites/').push().key;

        var link = process.env.NODE_ENV === 'production'
        ? 'www.anicheckin.com/appt-invite/' + inviteKey
        : 'localhost:3000/appt-invite/' + inviteKey;

        var portal = process.env.NODE_ENV === 'production'
        ? 'www.anicheckin.com/portal/check-in/' + inviteKey
        : 'localhost:3000/portal/check-in/' + inviteKey;

        var data = {
            ...pck,
            link: link,
            portal: portal,
        }

        this.apiSessionSlot(pck.session, pck.slot)
        .update({
            status: 'invited',
            inviteId: inviteKey,
            inviteSent: this.timestamp(),
            linke: link,
        });

        var template_params = {
            "email": pck.email,
            "replyto": pck.replyto ? pck.replyto : 'info@discoverani.com',
            "company": pck.company,
            "name": pck.name,
            "position": pck.position,
            "date": pck.date,
            "time": pck.time,
            "invite": link,
            "sender": pck.sender,
         }
         
         var service_id = "default_service";
         var template_id = "appt_invite";
         emailjs.send(service_id, template_id, template_params, 'user_UXrnDajVZDtBHREeuIA0z')
         .then(res => {
             console.log('Sent')
         })
         .catch(err => console.log('Fail', err));;


         var updates={};
         updates['api/invites/' + inviteKey] = data;

         return this.db.ref().update(updates);
    }

    // * ROOMS * //
    apiCompanyRooms = (cid) => this.db.ref('api/companies/' + cid + '/rooms/');
    apiCompanyRoom = (cid, id) => this.db.ref('api/companies/' + cid + '/rooms/' + id);
    apiNewRoom = (cid, uid, name) => {

        var data = {
            name: name,
            created: this.timestamp(),
            by: uid,
            session: '',
            activeGuest: '',
            activeGuestId: '',
        }

        var key = this.apiCompanyRooms(cid).push().key;

        var updates={};
        updates['api/companies/' + cid + '/rooms/' + key] = data;

        return this.db.ref().update(updates);
    }
    
    // * GUESTS * // 

    apiCompanyGuests = (cid) => this.db.ref('api/company-guests/' + cid + '/');
    apiCompanyGuest = (cid, guest) => this.db.ref('api/company-guests/' + cid + '/' + guest);
    apiNewCompanyGuest = (cid, pck) => {
        var data = {
            ...pck,
            track: {
                in: this.timestamp(),
                call: '',
                out: '',
            },
            complete: false,
            status: 'waiting',
        };

        var key = this.db.ref('api/company-guests/' + cid + '/').push().key;

        var updates={};
        updates['api/company-guests/' + cid + '/' + key] = data;

        return this.db.ref().update(updates);

    };

    // * EMPLOYEES * //


    // * MOBILE CODE * //

    apiMobileForms = () => this.db.ref('/api/mobile-forms/');
    apiMobileForm = (id) => this.db.ref('api/mobile-forms/' + id);

    apiAddMobileCode = (code, cid) => {

        var data = {
            code: code,
        }

        this.apiCompany(cid)
        .update({
            mobileAccess: code,
        });

        var updates = {};
        updates['api/mobile-forms/' + cid] = data;

        return this.db.ref().update(updates);
    };

    // STORAGE API
    
    userPic = (uid, image) => this.db.ref('users/' + uid + '/images/');



    userImageKey = (cid) => this.db.ref('api/images/' + cid + '/').push().key;
    companyImage = (cid, key) => this.db.ref('api/images/' + cid + '/' + key);


    uploadImage = (cid, image, sendBackLink) => {

        var imageKey = this.userImageKey(cid);
        var companyImage = this.companyImage(cid, imageKey);

        var uploadTask = this.storage.ref('images/accounts/' + cid + '/' + imageKey).put(image);
        uploadTask.on('state_changed', snapshot => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        }, function(error) {
            console.log(error);
        }, function() {
            uploadTask.snapshot.ref.getDownloadURL()
            .then(function(downloadURL) {
                companyImage
                .update({
                    link: downloadURL,
                    name: image.name,
                });
                sendBackLink(downloadURL);
            });
        })
    };



    companyAvatars = (uid, image) => {
        this.storage.ref('avatars/' + uid + '/images/' + image.name);
    };

    

    uploadCompanyAvatar = (uid, image, _handleRefreshPic) => {

        var userPic = (uid) => this.db.ref('users/' + uid + '/files/');

        var uploadTask = this.storage.ref('avatars/' + uid + '/images/' + image.name).put(image);

        uploadTask.on('state_changed', snapshot => {

            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        }, function(error) {
            console.log(error);
        }, function() {
        // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL()
            .then(function(downloadURL) {
                console.log('File available at', downloadURL);
                userPic(uid)
                .update({
                    avatar: downloadURL
                });
            })
        });
    };

    uploadEmployeeAvatar = (uid, eid, image) => {

        var userPic = (uid) => this.db.ref('users/' + uid + '/employees/' + eid);

        var uploadTask = this.storage.ref('avatars/' + uid + '/employees/' + eid + '/' + image.name).put(image);

        uploadTask.on('state_changed', snapshot => {
           
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            }, function(error) {
                console.log(error);
            }, function() {
            // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL()
                .then(function(downloadURL) {
                    console.log('File available at', downloadURL);
                    userPic(uid, eid)
                    .update({
                        avatar: downloadURL
                    });
                })
            });
    };

    uploadSlotAvatar = (image, uid, sessKey, slot, name, talKey, groupKey) => {

        var tempTalentPic = (talKey) => this.db.ref('temp-talent/' + talKey);
        var slotUpdate = (uid, sessKey, slot) => this.db.ref('user-casting-sessions/' + uid + '/' + sessKey + '/slotTimes/' + slot);
        var sessionScheduleSlotUpdate = (groupKey, slot) => this.db.ref('session-schedule/' + groupKey + '/schedule/' + slot);

        var uploadTask = this.storage.ref('temp-talent/' + talKey + '/avatar/' + image.name).put(image);
        uploadTask.on('state_changed', snapshot => {

        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        }, function(error) {
            console.log(error);
        }, function() {
            uploadTask.snapshot.ref.getDownloadURL() 
            .then(function(downloadURL) {
                console.log('File available at ', downloadURL);
                tempTalentPic(talKey)
                .update({
                    avatar: downloadURL
                });
                slotUpdate(uid, sessKey, slot)
                .update({
                    avatar: downloadURL,
                })
                sessionScheduleSlotUpdate(groupKey, slot)
                .update({
                    avatar: downloadURL,
                })
            });
        });
    };

    uploadTalentAvatar = (uid, image) => {

        var userPic = (uid) => this.db.ref('talent-users/' + uid);
        var searchPic = (uid) => this.db.ref('talent-search/' + uid);

        var uploadTask = this.storage.ref('talent/' + uid + '/profile-images/' + image.name).put(image);

        uploadTask.on('state_changed', snapshot => {

            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            }, function(error) {
                console.log(error);
            }, function() {
            // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL()
                .then(function(downloadURL) {
                    console.log('File available at', downloadURL);
                    userPic(uid)
                    .update({
                        avatar: downloadURL
                    });
                    searchPic(uid)
                    .update({
                        avatar: downloadURL
                    });
                })
            });
    }

}

export default Firebase;
