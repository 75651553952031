import React, { useState } from 'react';

import { NullUser } from '../../../../assets/nulluser';
import tag from '../../../../assets/tag.png';

export default function TagsListItem(props) {

    const [copy, setCopy] = useState(false);
    const [copyObject, setCopyObject] = useState('');

    var talent = 
        props.talent ? 
        props.talent.sort((a, b) => (a.talentName > b.talentName) ? 1 : -1) : 
        '';

    function copyList() {
        var names = talent.map(tal => tal.talentName);
        var object = props.item.tagName + ',,' + names;
        var list = object.split(',');
        list = list.join('\n');
        setCopyObject(list);
        setCopy(true);
    }

    function copyToClipboard(e) {
        var text = e.target.value;
        var copyText = document.getElementById('textarea-to-copy');
        copyText.select();
        copyText.setSelectionRange(0, 99999);

        document.execCommand("copy");

        var copyTimer = setTimeout(() => {
            setCopy(false);
            setCopyObject('');
        }, 2000)
        return (() => clearTimeout(copyTimer));
    };

    return (
        <div className="s-note-container">
            <div className="no-display">
                {
                    copy ?
                    <textarea id="textarea-to-copy" autoFocus value={copyObject} onFocus={(e) => copyToClipboard(e)} />
                    :
                    null
                }
            </div>
            <div className="s-note-header">
                <div>
                    <div className="s-note-tag-item-tagname">
                        <img className="s-note-tag-item-icon" src={tag} alt="Tag Name" />
                            {props.item.tagName}
                        </div>
                        <div className="copy-list-widget">
                        {
                            copy ?
                            <div className="text-copied">List Copied!</div>
                            :
                            <button className="copy-list" onClick={() => copyList()}>Copy List</button>
                        }
                    </div>
                    <div className="height-15" />
                    <div className={
                        props.view === 'list' ? 
                        "s-note-tag-item-talent" : 
                        "s-note-tag-item-talent view-as-grid"
                        }
                    >
                        {
                            talent ?
                            talent.map(tal => (
                                <div className="s-note-tag-item-talent-item" key={tal.talentId}>
                                    <img 
                                        className="s-note-tag-item-talent-item-avatar"     
                                        src={NullUser}
                                        alt="Actor Headshot"
                                    />
                                    <div className="s-note-tag-item-talent-item-name">
                                        {tal.talentName}
                                    </div>  
                                </div>
                            ))
                            :
                            <div />
                        }
                    </div>
                </div>
            </div>
        </div>  
    )
}
