import React, { Component } from 'react';

import remove from '../../assets/remove.png';

import './modals.css';

export default class ModalContainer extends Component {

    render() {

      return (
        <div className="custom-modal-layout">
          <div className="modal-bg-overlay" onClick={this.props.close} />
          <div className="custom-modal-card">
            <div className="custom-modal-header">
              <div>{this.props.modalHeader}</div>
              <div className="modal-close-btn" onClick={this.props.close}><img src={remove} alt='Close Modal' className="close-modal-x" /></div>
            </div>
            <div className="custom-modal-body">
              {this.props.modalBody}
            </div>
            <div className="custom-modal-footer">
              {this.props.modalFooter}
            </div>
          </div>
        </div>
      )
    }
  }