import React, { useState, useEffect } from 'react';
import moment, { diff } from 'moment';

import { ObjectList } from '../../../../Functions/Common';

export default function Summary(props) {

    const sess = props.sessObj;
    const [count, setCount] = useState('');
    const [notes, setNotes] = useState([]);
    const [tags, setTags] = useState([]);
    const [avgWait, setAvgWait] = useState('');
    const [avgCall, setAvgCall] = useState('');
    const [avgTime, setAvgTime] = useState('');

    useEffect(() => {
        if (props.sessObj.subjects) {
            var subjects = props.sessObj.subjects;
            var subjectList = ObjectList(subjects);
            setCount(subjectList.length);
            countNotes(subjectList);
            countTags(subjectList);
            times(subjectList, subjectList.length);
        }; 
    }, [props.sessObj.subjects]);

    function times(subjectList, count) {
        var waitTime = 0;
        var callTime = 0;
        var totalTime = 0;

        subjectList.map((s, index) => {
            var a = moment(s.track.in, 'MM/DD/YYYY hh:mm:ss a');
            var b = moment(s.track.call, 'MM/DD/YYYY hh:mm:ss a');
            var c = moment(s.track.out, 'MM/DD/YYYY hh:mm:ss a') || 'active';
            var wait = b.diff(a, 'minutes');
            var call = c !== 'active' ? c.diff(b, 'minutes') : 0;
            var total = wait + call;
            waitTime = waitTime + wait;
            callTime = callTime + call;
            totalTime = totalTime + total;
        });
        setAvgWait(Math.round(waitTime / count));
        setAvgCall(Math.round(callTime / count));
        setAvgTime(Math.round(totalTime / count));
    };

    function countNotes(subjectList) {
        var notes = 0;
        subjectList.map((s, index) => {
            if (s.notes) {
                var snotes = Object.keys(s.notes);
                var noteCount = snotes.length;
                notes = notes + noteCount;
            };
        });
        setNotes(notes);
    };

    function countTags(subjectList) {
        var tags = 0;
        subjectList.map((s, index) => {
            if (s.tags) {
                var stags = Object.keys(s.tags);
                var tagCount = stags.length;
                tags = tags + tagCount;
            };
        });
        setTags(tags);
    }

    return (
        <div>
            <button className="open-full-summary" onClick={() => props.expandSummary()}>Expand Summary</button>
            <div className="nav-section-header">Session Breakdown</div>
            <div className="height-10" />
            <div className="nav-summary-row">
                <h5>Complete</h5>
                <h2>{count ? count : '0'}</h2>
            </div>
            <div className="nav-summary-row">
                <h5>Notes</h5>
                <h2>{notes !== 0 ? notes : '0'}</h2>
            </div>
            <div className="nav-summary-row">
                <h5>Tags</h5>
                <h2>{tags !== 0 ? tags : '0'}</h2>
            </div>
            <div className="nav-summary-row">
                <h5>Avg. Wait</h5>
                <h2>{avgWait ? avgWait : '0'} mins</h2>
            </div>
            <div className="nav-summary-row">
                <h5>Avg. Call</h5>
                <h2>{avgCall ? avgCall : '0'} mins</h2>
            </div>
            <div className="nav-summary-row">
                <h5>Avg. Total</h5>
                <h2>{avgTime ? avgTime : '0'} mins</h2>
            </div>
        </div>
    )
}
