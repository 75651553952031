import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {Switch} from '../../../Common/CustomSwitch';

export default function Details(props) {
    
    const [title, setTitle] = useState('');
    const [start, setStart] = useState({
        h: '10',
        m: '00',
        ap: 'AM',
    });
    const [end, setEnd] = useState({
        h: '2',
        m: '00',
        ap: 'PM',
    });
    const [date, setDate] = useState('');
    const [dateLong, setDateLong] = useState(new Date());
    const [location, setLocation] = useState('office');
    const [session, setSession] = useState('');

    useEffect(() => {
        if (props.id && props.sessObj) {
            var s = props.sessObj;
            var sta = s.times.start || '';
            var end = s.times.end || '';
            if (sta) {
                sta = sta.split(' ');
                var nums = sta[0].split(':');
                setStart({
                    h: nums[0],
                    m: nums[1],
                    ap: sta[1],
                })
            };
            if (end) {
                end = end.split(' ');
                var nums = end[0].split(':');
                setEnd({
                    h: nums[0],
                    m: nums[1],
                    ap: end[1],
                })
            };
            setTitle(s.title);
            setSession(props.sessObj);
            setLocation(props.sessObj.location);
            setDate(new Date(s.date));
        }
    }, [props.id, props.sessObj])

    useEffect(() => {
        if (session.times) {
            var t = session.times;
            var s = t.start.replace(':', ' ').split(' ');
            var e = t.end.replace(':', ' ').split(' ');
            setStart({
                h: s[0],
                m: s[1],
                ap: s[2],
            });
            setEnd({
                h: e[0],
                m: e[1],
                ap: e[2],
            });
        }
    }, [session.times])

    function _setLocation(type) {
        props.functions.firebase.apiSession(props.id)
        .update({
            location: {
                address: location.address,
                type: type,
                office: location.office,
            }
        });
    };
    
    function _handleTimes(e, input, frame) {
        if (input === 'h') {
            if (frame === 'start') {
                setStart({
                    h: e, m: start.m, ap: start.ap,
                });
                if (e.length === 2) {
                    var startTimer = setTimeout(() => {
                        document.getElementById('2').focus();
                    }, 200)
                    return (() => clearTimeout(startTimer));
                };
            } else {
                setEnd({
                    h: e, m: end.m, ap: end.ap,
                });
                if (e.length === 2) {
                    var endTimer = setTimeout(() => {
                        document.getElementById('4').focus();
                    }, 200)
                    return (() => clearTimeout(endTimer));
                };
            }
        } else if (input === 'm') {
            if (frame === 'start') {
                setStart({
                    h: start.h, m: e, ap: start.ap,
                });
            } else {
                setEnd({
                    h: end.h, m: e, ap: end.ap,
                });
            };
        };
    };

    function _blurTimes(input) {
        var newStart = start.h + ':' + start.m + ' ' + (input === 'am1' ? 'AM' : input === 'pm1' ? 'PM' : start.ap);
        var newEnd = end.h + ':' + end.m + ' ' + (input === 'am2' ? 'AM' : input === 'pm2' ? 'PM' : end.ap);
        props.functions.firebase.apiSession(props.id)
        .update({
            times: {
                start: newStart,
                end: newEnd,
            }
        });
    };
    
    const [dateOpen, setDateOpen] = useState(false);
    const [posOpen, setPosOpen] = useState(false);
    const [locOpen, setLocOpen] = useState(false);
    const [titleOpen, setTitleOpen] = useState(false);
    const [onlineOpen, setOnlineOpen] = useState(false);


    function prepBlurTitle(e) {
        if (e.keyCode === 9) {
            _blurTitle()
            setPosOpen(true);
        }
    };
    function _blurTitle() {
        props.functions.firebase.apiSession(props.id)
        .update({
            title: title,
        })
    };

    const [rooms, setRooms] = useState('');

    useEffect(() => {
        if (props.cid) {
            props.functions.firebase.apiCompanyRooms(props.cid)
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({
                        ...o[key],
                        id: key,
                    }))
                    setRooms(l);
                }
            })
        }
    }, [props.cid])

    function _prepSetDate(e) {
        props.functions.firebase.apiSession(props.id)
        .update({
            date: e
        });
        props.functions.firebase.apiCompanySession(props.cid, props.id)
        .update({
            date: e,
        });
    };

    return (
        <div className="">
            <Title title={title} setTitle={setTitle} _blurTitle={_blurTitle} prepBlurTitle={prepBlurTitle} />
            <div className="height-10" />
            <div 
                className={posOpen ? "details-section expand-to-fit" : "details-section"}
                onClick={posOpen ? null : () => setPosOpen(true)}
                >
                <div className="nav-section-header color-nav-section" onClick={posOpen ? () => setPosOpen(false) : null}>Positions</div>
                <NavSection label="Positions" element={
                <Positions 
                    firebase={props.functions.firebase} 
                    cid={props.cid} 
                    session={props.id} 
                    active={props.sessObj.active}
                    posOpen={posOpen}
                    spositions={props.sessObj && props.sessObj.positions ? props.sessObj.positions : ''}
                    />} 
                />
            </div>
            <div 
                className={dateOpen ? "details-section expand-to-fit" : "details-section"}
                onClick={dateOpen ? null : () => setDateOpen(true)}
                >
                <div className="nav-section-header color-nav-section" onClick={dateOpen ? () => setDateOpen(false) : null}>Date and Time</div>
                <NavSection label="Date" element={<Day dateLong={dateLong} date={date} setDate={_prepSetDate} setDateLong={setDateLong} />} />
                <NavSection label="Times" element={<Times start={start} end={end} _blurTimes={_blurTimes} _handleTimes={_handleTimes}/>} />
                {

                }

            </div>
            <div 
                className={locOpen ? "details-section expand-to-fit" : "details-section"}
                onClick={locOpen ? null : () => setLocOpen(true)}
                >
                <div className="nav-section-header color-nav-section" onClick={locOpen ? () => setLocOpen(false) : null}>Room Setup</div>
                <NavSection label="Location" element={
                    <Location 
                        props={props.props} 
                        functions={props.functions} 
                        _setLocation={_setLocation} 
                        location={location}
                        rooms={rooms} 
                        id={props.id}
                    />
                } />
            </div>
            {/*<div 
                className="details-section"
                onClick={() => props.setOnlineOpen(!props.onlineOpen)}
            >
                <div className="nav-section-header color-nav-section">Online Settings</div>
            </div>*/}
            
        </div>
    )
}

function NavSection(props) {
    return (
        <div className="nav-section" id="session-nav-section">
            {props.element}
        </div>
    )
}

const Title = (props) => (
    <div>
    <div className="nav-input-label-title">Session Title</div>
    <input 
        className="nav-section-title-input color-nav-section"
        value={props.title} 
        onChange={(e) => props.setTitle(e.target.value)} 
        onBlur={() => props._blurTitle()} 
        placeholder="Label your session" 
        onKeyDown={(e) => props.prepBlurTitle(e)}
        id="title-session"
    />
    </div>
)

const Day = (props) => {

    function _setDate(newDate) {
        var format = moment(new Date(newDate)).format('MM/DD/YYYY');
        props.setDate(format);
        props.setDateLong(newDate);
    };

    const [focus, setFocus] = useState(false);

    return (
        <div className={focus ? "date-picker-container" : ''}>
        <DatePicker 
            selected={props.date} 
            onChange={(e) => _setDate(e)} 
            dateFormat="MM / dd / yyyy"
            className="full-date-picker"
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
        />
        </div>
    )
}

const Times = (props) => {
    
    var inputs = [
        {id1: '1', id2: '2', val1: props.start.h, val2: props.start.m, val3: props.start.ap, 
            blur1: 'h1', blur2: 'm1', blur3: 'am1', blur4: 'pm1', frame: 'start',},
        {id1: '3', id2: '4', val1: props.end.h, val2: props.end.m, val3: props.end.ap, 
            blur1: 'h2', blur2: 'm2', blur3: 'am2', blur4: 'pm2', frame: 'end'}
    ];

    return (
        <div className="">
            { 
                inputs.map((i, index) => (
                <div className="nav-time-row" key={index}>
                    <input id={i.id1} value={i.val1} 
                        className="time-inp"
                        onChange={(e) => props._handleTimes(e.target.value, 'h', i.frame)} 
                        onBlur={() => props._blurTimes(i.blur1)}    
                    />
                    <input id={i.id2} value={i.val2} 
                        className="time-inp"
                        onChange={(e) => props._handleTimes(e.target.value, 'm', i.frame)} 
                        onBlur={() => props._blurTimes(i.blur2)}   
                    />
                    <div className="am-pm-switch">
                        <button 
                            className={
                                index === 0 && props.start.ap === 'AM' ?
                                "ap-btn ap-sel" : 
                                index === 1 && props.end.ap === 'AM' ?
                                "ap-btn ap-sel" :
                                "ap-btn"
                            }
                            onClick={() => props._blurTimes(i.blur3)}>AM</button>
                        <button 
                            className={
                                index === 0 && props.start.ap === 'PM' ?
                                "ap-btn ap-sel" : 
                                index === 1 && props.end.ap === 'PM' ?
                                "ap-btn ap-sel" :
                                "ap-btn"
                            }
                            onClick={() => props._blurTimes(i.blur4)}>PM</button>
                    </div>
                </div>
                ))
            }
        </div>
    )
}

const Location = (props) => {

    const [type, setType] = useState('office');
    const options = [
        { id: 0, name: 'My Office', pic: '', slag: "office",
            function: () => props._setLocation('office') 
        },
        { id: 1, name: 'New Place', pic: '', slag: "new",
            function: () => props._setLocation('new')     
        },
        { id: 2, name: 'Online', pic: '', slag: "digital",
            function: () => props._setLocation('digital')
        },
    ];
    return (
        <LocationTypes type={props.location.type} location={props.location} session={props.id} rooms={props.rooms} firebase={props.functions.firebase} />
   
    )
}

const LocationOption = (props) => (
    <div className="nav-option">
        <div className={props.item.slag === props.type ? "nav-option-text sel-opt" : "nav-option-text"} onClick={props.item.function}>{props.item.name}</div>
    </div>
);

const LocationTypes = (props) => (
    props.type === 'office' ?
    <Rooms rooms={props.rooms} location={props.location} firebase={props.firebase} session={props.session} /> :
    props.type === 'new' ?
    <Address props={props} /> : 
    props.type === 'digital' ?
    <Digital props={props}  /> :
    <div />
);

function Rooms(props) {

    function _selectRoom(item) {
        props.firebase.apiSession(props.session).child('location/office/')
        .update({
            id: item.id,
            name: item.name,
        });
    };

    const [reception, setReception] = useState(false);

    return (
        <div>
        { props.location.office ? 
            <div>
            <Switch text="Notify Reception?" boolean={reception} toggleFunction={() => setReception(!reception)} /> 
            <div className="height-15" />
            </div>
            : null
        }
        {
        props.rooms ? 
        props.rooms.map((item, index) => (
            <div key={item.id} 
                className={props.location.office && props.location.office.id === item.id ? 
                    "session-position-item pos-sel" : 
                    "session-position-item"} 
                onClick={() => _selectRoom(item)}>
                {item.name}
            </div>
        ))
        :
        <div className="null-text">No rooms created</div>
        }
        </div>
    )
};

function Address(props) {
    return (
        <div />
    )
};

function Digital(props) {
    return (
        <div />
    )
};

function Positions(props) {

    const [list, setList] = useState('');
    const [listIds, setListIds] = useState('');

    useEffect(() => {
        if (props.cid) {
            props.firebase.apiCompanyPositions(props.cid)
            .orderByChild('status')
            .equalTo('open')
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({
                        ...o[key],
                        id: key,
                    }));
                    if (l) {
                        var sort = l.sort((a, b) => (a.name > b.name) ? 1 : -1);
                        setList(sort);
                    };
                };
            });
        };
    }, [props.cid]);

    useEffect(() => {
        if (props.spositions) {
            var l = Object.keys(props.spositions).map(key => ({
                id: key,
            }));
            var ids = l.map(i => i.id);
            setListIds(l.map(i => i.id));
            var newList = list ? list.sort((a, b) => (ids.includes(a.id) ? -1 : 1)) : '';
            if (newList) {
                setList(newList);
            }
        } else {
            setListIds('');
        }
    }, [props.spositions, props.posOpen])

    function addPosition(item) {
        if (listIds.includes(item.id)) { 
            props.firebase.apiSession(props.session).child('positions/' + item.id)
            .remove();
            props.firebase.apiCompanySession(props.cid, props.session)
            .child('positions/' + item.id)
            .remove()
        } else {
            props.firebase.apiSession(props.session).child('positions/' + item.id)
            .update({
                name: item.name,
                aff: item.aff,
            });
            props.firebase.apiCompanySession(props.cid, props.session)
            .child('positions/' + item.id)
            .update({
                name: item.name,
                aff: item.aff,
            })
        }
        if (props.active) {
            props.firebase.apiCompanyPosition(props.cid, item.id)
            .update({
                active: true,
            });
        };
    };

    const [search, setSearch] = useState('');

    return (
        <div className="">
            <input className="session-position-search" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search name or project" />
            {
                list ?
                <div className="nav-position-search no-scroll-bars">
                {
                list.filter(fil => {
                    return fil.name.toLowerCase().includes(search.toLowerCase()) || fil.aff.toLowerCase().includes(search.toLowerCase())})
                    .map((item, index) => (
                    <div key={item.id} className={listIds.includes(item.id) ? "session-position-item pos-sel" : "session-position-item"} 
                        onClick={() => addPosition(item)}>
                        {item.name}
                        <p>{item.aff}</p>
                    </div>
                ))
                }
                </div>
                :
                <div className="null-text">No positions created</div>
            }
        </div>
    )
}

