import React from 'react';

const Input = (props) => (
    <input 
        className="default-input" 
        onChange={props.onChange}
        placeholder={props.placeholder}
        value={props.value}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        type={props.type}
        onKeyDown={props.onKeyDown}
    />
)

const InputShift = (props) => (
    <div className="sign-in-input-box relative">
      <p className={
        props.value.length > 0 ? "top-left-shift" : ''
      }>{props.label}</p>
      <input 
        className="sign-in-input"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        type={props.type}
        onKeyDown={props.onKeyDown}
      />
    </div>
  )

export { Input, InputShift };