import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization, withAuthentication } from '../Session';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';


import { Button, Input, Card, CardText } from 'reactstrap';

const AddRolesPage = () => (
    <div>
        <AddRolesForm />  
    </div>
);

const INITIAL_STATE = {
    user: '',
    projects: [],
    project: '',
    roleName: '',
    projectRoles: [],
    createdAt: null,
    loading: false,
};

class AddRolesFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    };

    componentDidMount() {
        this.setState({ loading: true });

        this.props.firebase.projects().on('value', snapshot => {
            const projectsObject = snapshot.val();

            const projectsList = Object.keys(projectsObject).map(key => ({
                ...projectsObject[key],
                id: key,
            }));
            this.setState({
                projects: projectsList,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        this.props.firebase.projects().off();
    }

    onSubmit = event => {
        const { project, roleName } = this.state
        this.props.firebase.role(roleName, project);
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onChangeSelect = event => {
        const { projectRoles } = this.state;
        this.setState({ project: event.target.value });
        this.props.firebase.projectRoles(projectRoles);
    };

    render() {

        const ProjectList = ({ projects }) => (
            <Input 
                style={style.input}
                type="select"
                name="project"
                value={this.state.project}
                onChange={this.onChangeSelect}
                placeholder="Select project"
            >
                <option>Select Project</option>
                {projects.map(project => (
                    <option>{project.project}</option>
                ))}    
            </Input>
        )

        const RoleList = ({ projectRoles }) => (
            <Input 
                style={style.input}
                type="select"
                name="projectRole"
                value={this.state.projectRole}
                onChange={this.onChangeSelect}
                placeholder="Select project"
            >
                <option>Select Role</option>
                {projectRoles.map(projectRole => (
                    <option>{projectRole.roleName}</option>
                ))}    
            </Input>
        )

        const { projects, projectRoles } = this.state;

        return (
            <div style={style.container}>
                <Card style={style.card}>
                    <CardText>Add Role Here</CardText>
                    <form onSubmit={this.onSubmit}>
                        <ProjectList projects={projects} />
                        <RoleList projectRoles={projectRoles} />
                        <Button
                            style={style.button}
                            type="submit"
                            onSubmit={this.onSubmit}>
                            Add Role
                        </Button>
                    </form>
                </Card>
            </div>
        )
    };
};

const style={
    container: {
        flex: 1,
        marginTop: 100,
        marginBottom: 100,
    },
    card: {
        width: 600,
        height: 800,
        alignItems: 'center',
    },
    input: {
        width: 300,
        height: 50,
        marginTop: 5,
    },
    button: {
        width: 300,
        height: 50,
        marginTop: 10
    }
};

const AddRolesLink = () => (
    <Button>
        <Link to={ROUTES.ADD_ROLES}>Add Roles</Link>
    </Button>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AddRolesPage);

const AddRolesForm = compose(
    withRouter,
    withFirebase,
    withAuthentication
    )(AddRolesFormBase);

export { AddRolesForm, AddRolesLink };
