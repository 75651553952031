import React, { useState, useEffect } from 'react';
import { NullUser } from '../../../../assets/nulluser';

export default function Team(props) {

    const [emps, setEmps] = useState('');
    const [addTeam, setAddTeam] = useState(false);
    const [team, setTeam] = useState('');

    useEffect(() => {
        if (props.cid) {
            props.firebase.apiCompanyAccounts(props.cid)
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({
                        ...o[key], 
                        id: key,
                    }))
                    setEmps(l);
                } else {
                    setEmps('');
                }
            })
        }
    }, [props.cid]);

    useEffect(() => {
        if (props.team) {
            var list = Object.keys(props.team).map(key => ({
                ...props.team[key],
                id: key,
            }))
            setTeam(list);
        } else {
            setTeam('');
        }
    }, [props.team]);

    function addMe() {
        props.firebase.apiSession(props.id).child('team/' + props.uid) 
        .update({
            name: props.username,
            active: false,
        })
    }

    function addMember(item, bool) {
        if (bool) {
            props.firebase.apiSession(props.id).child('team/' + item.id).remove();
        } else {
            props.firebase.apiSession(props.id).child('team/' + item.id) 
            .update({
                name: item.name,
                active: false,
            })
        }
    }


    var teamids = team ? team.map(i => i.id) : '';

    return (
        <div>
        <div className="height-20" />
        <div className="details-section expand-to-fit relative">
        
        <button className="edit-team" onClick={() => setAddTeam(!addTeam)}>Edit Team</button>
        <div className="nav-section-header color-nav-section">Internal Team</div>
        <div className="team-section">
            {teamids.includes(props.uid) ? null : <button className="add-myself-btn" onClick={() => addMe()}>Add Myself</button>}
            <div className="sess-add-team">
                {
                    addTeam ?
                    <TeamMembers emps={emps} teamids={teamids} addMember={addMember} team={team} />
                    :
                    team ?
                    team.map((mem, index) => (
                        <ActiveTeam key={mem.id} mem={mem} firebase={props.firebase} id={props.id} />
                    ))
                    :
                    <div className="null-text">No Team Members Added</div>
                }
                
            </div>
        </div>
        </div>
        </div>
    )
}

function TeamMembers(props) {

    return (
        props.emps ?
        props.emps.sort((a, b) => (a.name > b.name) ? 1 : -1)
        .map((item, index) => (
            <div key={index} className={props.teamids.includes(item.id) ? "emp-item sel-emp" : "emp-item"} 
                onClick={() => props.addMember(item, props.teamids.includes(item.id))}>
                {item.name}
            </div>
        ))
        :
        null
    )
}

function ActiveTeam(props) {

    function activate(bool) {
        props.firebase.apiSession(props.id).child('team/' + props.mem.id)
        .update({
            active: bool
        });
    };

    return (
        <div className="active-team-member">
            <img className="active-team-avatar" src={NullUser} alt="Team Headshot" />
            <div className="active-team-name">{props.mem.name}</div>
            <AwayStatus mem={props.mem} activate={activate} />
        </div>
    )
}

function AwayStatus(props) {
    return (
        <div className={props.mem.active ? 'green-circle' : 'red-circle'} onClick={() => props.activate(!props.mem.active)} />
    )
}
