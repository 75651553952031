import React, { useEffect, useState } from 'react';

import { CSSTransitionGroup } from 'react-transition-group';

import TalentNoteItem from './TalentNoteItem';
import SlyNoteAdd from './SlyNotes/SlyNoteAdd';
import NoteDisplaySettings from './NoteDisplaySettings';
import SessionTalentModal from '../../../Modals/SessionTalentModal/SessionTalentModal';

import graynotepad from '../../../../assets/light-note-placeholder.png';

export default function GeneralNoteList(props) {

    const [length, setLength] = useState('');
    const [sortedNotes, setNotes] = useState(false);
    const [newMarkers, setMarkers] = useState('');
    const [talentObject, setTalentObject] = useState('');
    const [talentLength, setTalentLength] = useState('');
    const [talent, setTalent] = useState('');
    const [sendNote, setSendNote] = useState(false);
    const [clearProps, setClearProps] = useState(false);

    const _saveToMyNotes = (talent, noteId, note) => {
        props.firebase.db.ref('my-notes/' + props.uid + '/emps/' + props.sessUserId + '/talent/' + talent + '/' + noteId + '/')
        .update({
            note: note,
        })
    }

    const [secondFocus, setSecondFocus] = useState('');

    
    useEffect(() => {
        if (props.notes && props.notes.length !== length) {
            setLength(props.notes.length);
            var sortedNotes = props.notes.sort((a, b) => (a.index < b.index) ? 1 : -1);
            setNotes(sortedNotes);
        }
    }, [props.notes.length]);

    useEffect(() => {
        if (props.talent && props.talent.length !== talentLength) {
            setTalentLength(props.talent.length);
            var sortedTalent = props.talent.sort((a, b) => (a.index < b.index) ? 1 : -1);
            setTalent(sortedTalent);
        }
    }, [props.talent.length]);

    var currentNote = props.notes ? props.notes[0] : '';

    useEffect(() => {
        if (props.props.talentId) {
            setSendNote(true);
        } else {
            setClearProps(true);
            var propTimer = setTimeout(() => {
                setSendNote(false);
            }, 400);
            return (() => clearTimeout(propTimer));
        }
    }, [props.props.talentId])

    
    const props2 = props.props;

    return (
        <div>
        <div className="i-pad-group-note-list-container">
            {
                sendNote ?
                <div className={props.fadeAddNote ? "fadeOutNew" : "fadeInFast"}> 
                <SlyNoteAdd 
                    talentName={props2.talentName} 
                    talentId={props2.talentId} 
                    activeSession={props2.activeSession} 
                    sessionId={props2.sessionId}
                    sessionMode={props2.sessionMode}
                    props={props}
                    props2={props.props}
                    firebase={props.firebase}
                    currentNote={sendNote ? currentNote : ''}
                    userTags={props.userTags}
                    userTagGroups={props.userTagGroups}
                    secondFocus={secondFocus}
                    setSecondFocus={setSecondFocus}
                    sessUserId={props.sessUserId}
                    _saveToMyNotes={_saveToMyNotes}
                    mobileAddNote={props.mobileAddNote}
                    setMobileAddNote={props.setMobileAddNote}
                    clearProps={clearProps}
                />
                </div>
                :
                <div />
            }
            <div id={
                props.mobileState === 'complete' ? 
                "mobile-state-show" : 
                props.mobileState === 'checkins' ?
                "mobile-state-hide" :
                "mobile-state-show"}
            >
            <div className={props.hideWhileActive && props2.talentId ? "hide-div" : ""}>
            {props2.talentId ? <div className="height-20" /> : <div />}
            <NoteDisplaySettings 
                cardSize={props.cardSize}
                _switchView={props._switchView}
            />
            <div className="height-10" />
            <div className={talent ? "sly-note-scroll scroll-min-max" : "sly-note-scroll"}>
                {talent ?
                    talent
                    .map((item, index) => (
                        <div 
                        key={item.id} className={index === 0 && props.props.talentId ? "hide-div" : ""}>
                        <TalentNoteItem 
                            sess={props2.activeSession} 
                            uid={props.uid}
                            talentId={props2.talentId}
                            talentName={props2.talentName}
                            item={item} 
                            index={index} 
                            sessUserName={props.sessUserName}
                            sessUserId={props.sessUserId}
                            props={props.props}
                            firebase={props.firebase} 
                            groupRef={props2.groupRef}
                            length={props.notes ? props2.length : 0}
                            markers={newMarkers}
                            sessMarkers={props.markers}
                            userTags={props.userTags}
                            castSess={props2.sessionId}
                            setSecondFocus={setSecondFocus}
                            secondFocus={secondFocus}
                            _saveToMyNotes={_saveToMyNotes}
                            sessionId={props2.sessionId}
                            cardSize={props.cardSize}
                            _open={props._openTalentModal}
                        />
                        </div>
                    )
                    )
                :
                <img className="no-notes-icon" src={graynotepad} alt="Notes Icon" />
                }
                <div className="height-45" />
            </div>
            </div>
            </div>
            
        </div>
    
        </div>
    )
}
