import React, { Component } from 'react';

import { NullUser } from '../../../../assets/nulluser';

export default class NotesListItem extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {

        var noteObj = this.props.item.notes;
        var tagObj = this.props.item.tags;

        var notes = noteObj ? Object.keys(noteObj).map(key => ({ ...noteObj[key], id: key })) : '';
        var tags = tagObj ? Object.keys(tagObj).map(key => ({ ...tagObj[key], id: key })) : '';

        return (
            <div className="s-note-container">
                <div className="s-note-header">
                    <div className="s-note-user">
                        <img className="s-note-user-img" src={NullUser} alt="Actor Profile Pic" />
                        <div>
                            <div className="s-note-rep">{this.props.item.role}</div>
                            <div className="s-note-talent">{this.props.item.name}</div>
                            <div className="s-note-role">{this.props.item.rep}</div>
                        </div>
                    </div>
                </div>
                <div className="s-note-note">{notes ? notes.map((note, index) => (
                    <div key={note.id} className="note-item">
                    <div className="note-bullet" /><div key={note.id} className="s-note-text">{note.note}</div>
                    </div>
                )) : ''}
                </div>
                <div className="s-note-tag-container">
                    <div className="s-note-tag">Tags:</div>
                    {tags ? tags.map((tag, index) => (
                        <div key={tag.id} className="s-note-tag">{index === tags.length - 1 ? tag.tagName : tag.tagName + ','}</div>
                    )) : ''}
                </div>
            </div>  
        )   
        
    }

}