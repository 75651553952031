import React from 'react';

export default function SlyNoteTagItem(props) {

    var item = props.item;

    return (
        <div className={props.type === 'small' ? "sly-note-listed-tag-item smaller-tag" : "sly-note-listed-tag-item"}>
            {item.tagName}
        </div>
    )
}
