import React from 'react';

const NotesFAQ = () => {

    return (

        <div className="no-doc">No documentation available at this time. <br />Please check again shortly!</div>

    )
}

export { NotesFAQ };