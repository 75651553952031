import React from 'react';
import DeviceOwner from './DeviceOwner';
import * as firebase from 'firebase';
import axios from 'axios';

import './push.css';

export const askForPermissionsToReceiveNotifications = async () => {
    try {
        const messaging = firebase.messaging();
        await messaging.requestPermission();
        const token = await messaging.getToken();
        localStorage.setItem("ani-check-in-notification-token", token);

        return token;
    } catch (error) {
        console.error(error);
    }
};

export const showDeviceOwnerSelect = (employees, firebase, uid, _resetDevice) => {
    return (
        <DeviceOwner
            selectDeviceOwner={selectDeviceOwner} 
            employees={employees} 
            firebase={firebase} 
            uid={uid} 
            _resetDevice={_resetDevice} 
            setDeviceAsReception={setDeviceAsReception}
            setDeviceAsRoom={setDeviceAsRoom}
            setDeviceAsPublic={setDeviceAsPublic}
            setDeviceAsTalent={setDeviceAsTalent}
        />
    );
};

const setDeviceAsRoom = (item, firebase, uid) => {
    var token = localStorage.getItem('ani-check-in-notification-token');
    localStorage.setItem('ani-push-device-owner', 'no room set');
    localStorage.setItem('ani-push-device-type', 'room');
};

const setDeviceAsReception = (firebase, uid) => {
    var token = localStorage.getItem('ani-check-in-notification-token');
    localStorage.setItem('ani-push-device-owner', 'reception');
    localStorage.setItem('ani-push-device-type', 'reception');
    firebase.employeeDevice(token, 'Reception', uid, 'reception');
};

const selectDeviceOwner = (item, firebase, uid, _resetDevice) => {
    var token = localStorage.getItem('ani-check-in-notification-token');
    localStorage.setItem('ani-push-device-owner', item.id);
    localStorage.setItem('ani-push-device-type', 'employee');
    firebase.employeeDevice(token, item.id, uid, 'employee');
};

const setDeviceAsPublic = () => {
    localStorage.setItem('ani-check-in-notification-token', '');
    localStorage.setItem('ani-push-device-owner', '');
    localStorage.setItem('ani-push-device-type', 'public');
    localStorage.setItem('ani-push-notifications', 'none');
}

const setDeviceAsTalent = () => {
    localStorage.setItem('ani-check-in-notification-token', '');
    localStorage.setItem('ani-push-device-owner', '');
    localStorage.setItem('ani-push-device-type', 'public');
    localStorage.setItem('ani-push-notifications', 'none');
}

export const sendTalentSendNotification = (name, room, token) => {
    var Authorization = 'key=AAAAK9HmCVk:APA91bHNTYswaijqX-_YXuLLOhgVJKGBe9IQVI55McqiqQ1UM9Yf0akMV8CMd7dMsptSi2juTBM10qmm8IfICdvmUVLXGrW7mzY3vp62_2BJ5j2SYY5fvg-miFE72WOngknga0jyCqBI';
    var notification = JSON.stringify({
      notification: {
        title: "Send " + name,
        body: "to " + room,
        click_action: "http://anicheckin.com",
        icon: "https://firebasestorage.googleapis.com/v0/b/ani-check-in.appspot.com/o/static%2Faninotificationicon.png?alt=media&token=5901c52f-dc21-4dfd-957a-261e5d63dd34"
      },
      to: token,
    }) 
    axios.post(`https://fcm.googleapis.com/fcm/send`, notification, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': Authorization
      }
    })
  }


export const sendCheckInNotification = (name, id, token) => {
    var Authorization = 'key=AAAAK9HmCVk:APA91bHNTYswaijqX-_YXuLLOhgVJKGBe9IQVI55McqiqQ1UM9Yf0akMV8CMd7dMsptSi2juTBM10qmm8IfICdvmUVLXGrW7mzY3vp62_2BJ5j2SYY5fvg-miFE72WOngknga0jyCqBI';
    var notification = JSON.stringify({
      notification: {
        title: "New Check In",
        body: name + " has just checked in!",
        click_action: "http://anicheckin.com",
        icon: "https://firebasestorage.googleapis.com/v0/b/ani-check-in.appspot.com/o/static%2Faninotificationicon.png?alt=media&token=5901c52f-dc21-4dfd-957a-261e5d63dd34"
      },
      to: token,
    }) 
    axios.post(`https://fcm.googleapis.com/fcm/send`, notification, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': Authorization
      }
    })
  }