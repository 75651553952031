export const LANDING = '/landing';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const NEW_CASTING = '/new-casting';
export const CHECK_IN = '/check-in';
export const ADD_ROLES = '/add-roles';
export const CASTING_SESSION = '/casting-session/';
export const APPT_INVITE = '/appt-invite/';
export const ROOM = '/room';
export const LOCKED = '/acc-cod-req';
export const SEARCH = '/search';
export const GUEST_REGISTER = '/guest-register';
export const TEMP_DASHBOARD = '/guest-dashboard';
export const TALENT_SIGN_UP = '/talent-signup';
export const TALENT_DASHBOARD = '/talent-dashboard';
export const SESSION_OVERVIEW_GUEST_SIGN_IN = '/session-overview/guest';
export const SESSION_OVERVIEW_TALENT = '/session-overview/subjects/talent';
export const PRESENTATION_HOST = '/presentation/';
export const ANI_NOTE = '/testing/ani-note/';
export const PRESENTATION_JOIN = '/join/presentation/';
export const SESSION = '/session';
export const SESSION_GUEST = '/guest/session';
export const VERIFY_CO = '/verification/company/';
export const VERIFY_INVITE = '/verification/invite';
export const PORTAL_CHECKIN = '/portal/check-in/';
export const MOBILE_CHECKIN = '/mobile/check-in/';