import React, { Component } from 'react';

import { Overview } from './Overview';
import { NotesList } from './NotesList/NotesList';
import TalentList from './TalentList/TalentList';
import TakesList from './TakesList/TakesList';
import TagsList from './TagsList/TagsList';
import moment from 'moment';

import './sessiondetails.css';

export default class SessionDetailsBody extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentView: 'overview',
            tagFilter: '',
        }
    }

    _clickNotes = () => {
        this.setState({ currentView: 'notes' })
    };

    _clickTimes = () => {
        this.setState({ currentView: 'times' })
    };

    _clickTalent = () => {
        this.setState({ currentView: 'talent' })
    };

    _clickOverview = () => {
        this.setState({currentView: 'overview' })
    };

    _clickTakes = () => {
        this.setState({ currentView: 'takes' })
    };

    _clickTags = () => { 
        this.setState({ currentView: 'tags' })
    };

    componentDidMount(){
        var uid = this.props.uid;
        var item = this.props.session;
        var talObj = item.talent;
        var userObj = item.users;
        var rolesObj = item.roles;
        if (talObj) {
            var talent = Object.keys(talObj).map(key => ({
                ...talObj[key],
                id: key
            }));
            this.setState({ unsortedTalent: talent });
            var tagCount = [0];
            var allTags = [];
            if (talent) {talent.map(i => {
                var talentTags = i.tags;
                if (talentTags) {
                var tagList = Object.keys(talentTags).map(key => ({
                    ...talentTags[key],
                    id: key,
                }))
                if (tagList) {
                    tagList.map(t => {
                        return allTags.push({
                            tagId: t.id,
                            tagName: t.tagName,
                            talentId: i.id, 
                            talentName: i.name
                        },
                        );
                    })
                }
                tagCount = allTags.length || 0;
                this.setState({
                    allTags: allTags ? allTags : '',
                    tagCount,
                });
                }
            });
            }
        };
        if (userObj) {
            var users = Object.keys(userObj).map(key => ({
                ...userObj[key],
                id: key
            }));
        };
        if (rolesObj) {
            var roles = Object.keys(rolesObj).map(key => ({
                ...rolesObj[key],
                id: key,
            }));
        };

        this.props.firebase.userAuditionSessionTakes(uid, item.id)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                if (l) {
                    this.setState({
                        talentForTakes: l,
                    })
                    var allTakes = [];
                    l.map(talent => {
                        var takes = talent.takes ? talent.takes : '';
                        if (takes || takes !== '') {
                            var takeList = Object.keys(takes).map(key => ({
                                ...takes[key],
                                id: key,
                            }));
                            if (takeList) {
                                this.setState({ takeList });
                                takeList.map(take => {
                                    return allTakes.push({
                                        take
                                    })
                                })
                            }
                        }
                    });
                    if (allTakes.length > 0) { this.setState({ takeCount: allTakes.length })
                    } else { this.setState({ takeCount: 0 }) }
                } else { this.setState({ takeCount: 0 })}
            } else { this.setState({ takeCount: 0 })
            }
        })

        this.setState({
            active: item.active,
            date: item.date,
            name: item.name,
            room: item.room,
            prepped: item.prepped,
            wrapped: item.wrapped,
            started: item.started,
            preptime: item.preptime,
            project: item.project,
            roles: roles ? roles : '',
            start: item.start,
            wrap: item.wrap,
            actualWrap: item.wrapTime,
            actualStart: item.startTime,
            talent: talent ? talent : '',
            filterByTalentList: talent ? talent : '',
            filterByNotesList: talent ? talent : '',
            users: users ? users : '',
            talentFilter: '',
            notesFilter: '',
        })
    }

    _onChangeTalentFilter = (event) => {
        this.setState({
            talentFilter: event.target.value,
        });
        if (event.target.value.length > 0) {
            var filter = this.state.talent.filter(fil => {
                return fil.name.toLowerCase().includes(event.target.value.toLowerCase());
            });
            this.setState({
                filterByTalentList: filter,
            })
        } else {
            this.setState({
                filterByTalentList: this.state.talent,
            })
        }
    };

    _onChangeTagFilter = (event) => {
        this.setState({
            tagFilter: event.target.value,
        });

        if (event.target.value.length > 0) {
            var filter = this.state.allTags.filter(fil => {
                fil.tagName.toLowerCase().includes(event.target.value.toLowerCase())
            });
            console.log(filter);
        }
    }

    _onChangeNoteFilter = (event) => {
        this.setState({
            notesFilter: event.target.value,
        });
        if (event.target.value.length > 0) {
        var filter = this.state.talent.filter(fil => {
            return fil.name.toLowerCase().includes(event.target.value.toLowerCase());
        });
        this.setState({
            filterByNotesList: filter,
        })
        } else {
            this.setState({
                filterByNotesList: this.state.talent,
            })
        }
       
    };

    _timeDifference = (time1, time2) => {
        var diff = moment(time1, "hh:mma").diff(moment(time2, "hh:mma"));
        var time = diff > 60 ? diff / 1000 : 60;
        var mins = time / 60;
        return mins;
    };

    _findAverage = (array) => {
        var total = array.reduce((a, b) => a + b, 0);
        var avg = total / array.length;
        var adj = Math.round(avg);
        return adj + 'm';
    };

    _findMostCommon = (array) => {
        var counts = {};
        var compare = 0;
        var mostFrequent;
        for (var i = 0, len = array.length; i < len; i++) {
            var tag = array[0];
            if(counts[tag] === undefined) {
                counts[tag] = 1;
            } else {
                counts[tag] = counts[tag] + 1;
            }
            
            if(counts[tag] > compare){
                compare = counts[tag];
                mostFrequent = array[i].name;
            };
        }
        return mostFrequent;
    }   
    
    render() {

        var { active, date, name, room, prepped, wrapped, started, preptime, project, roles, start, wrap, preptime, actualWrap, actualStart, talent, users } = this.state;

        var talentCount = talent ? talent.length : 0;
        var lengthArray = [];
        var noteCount = 0;
        var allNotes = [];

        if (talent) {
            talent.map(i => {
            var talentNotes = i.notes;
            if (talentNotes) {
            var notesList = Object.keys(talentNotes).map(key => ({
                ...talentNotes[key],
                id: key,
            }))

            if (notesList) {
                notesList.map(n => {
                    return allNotes.push({
                        n
                    })
                })
            }
            noteCount = allNotes.length || 0;
            var length = this._timeDifference(i.wrap, i.start);
            lengthArray.push(length);
            }
        })

        };
        var allTags = [];
        if (talent) {talent.map(i => {
            var talentTags = i.tags;
            if (talentTags) {
            var tagList = Object.keys(talentTags).map(key => ({
                ...talentTags[key],
                id: key,
            }))
            if (tagList) {
                tagList.map(t => {
                    return allTags.push({
                        name: t.tagName,
                    });
                })
            }
            }
        });        
        }

        return (
            this.state.currentView === 'talent' ? 
            <TalentList 
                roles={roles}
                talent={talent}
                filterByTalentList={this.state.filterByTalentList}
                talentFilter={this.state.talentFilter}
                _timeDifference={this._timeDifference}
                _clickOverview={this._clickOverview}
                _onChangeTalentFilter={this._onChangeTalentFilter}
            />
            :
            this.state.currentView === 'notes' ?
            <NotesList 
                roles={roles}
                talent={talent}
                filterByNoteList={this.state.filterByNoteList}
                noteFilter={this.state.noteFilter}
                _clickOverview={this._clickOverview}
                filterByNotesList={this.state.filterByNotesList}
                _onChangeNoteFilter={this._onChangeNoteFilter}
            />
            :
            this.state.currentView === 'takes' ?
            <TakesList 
                takeList={this.state.takeList}
                talentForTakes={this.state.talentForTakes}
                _clickOverview={this._clickOverview}
                unsortedTalent={this.state.unsortedTalent}
            />
            :
            this.state.currentView === 'tags' ?
            <TagsList 
                allTags={this.state.allTags}
                talent={talent}
                _clickOverview={this._clickOverview}
                _onChangeTagFilter={this._onChangeTagFilter}
            />
            :
            <Overview 
                name={name} 
                users={users} 
                project={project} 
                roles={roles} 
                date={date} 
                start={start}
                actualStart={start} 
                wrap={wrap}
                takeCount={this.state.takeCount}
                actualWrap={actualWrap}
                talentCount={talentCount}
                noteCount={noteCount}
                lengthArray={lengthArray}
                allTags={allTags}
                _findAverage={this._findAverage}
                _findMostCommon={this._findMostCommon}
                _clickNotes={this._clickNotes}
                _clickTimes={this._clickTimes}
                _clickTalent={this._clickTalent}
                _clickTakes={this._clickTakes}
                _clickTags={this._clickTags}
                tagCount={this.state.tagCount}
            />
        )
    }
}