import React, { Component } from 'react'

import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withAuthentication } from '../Session';

import { SearchListContent } from './ListContent';
import { SearchSidebar } from './Sidebar';

import ModalContainer from '../Modals/index.js';
import NoteModalBody from '../Modals/NotesModal/NoteModalBody';

import arrowdown from '../../assets/arrowdown.png';
import filtericon from '../../assets/filtericon.png';


import './search.css';
import '../Modals/modals.css';

const AdvancedSearch = () => (
    <div>
        <SearchPage />
    </div>
)

class SearchPageBase extends Component {

    constructor(props) {
        super(props);

        this.state = {
            uid: '',
            filters: true,

            master: '',
            masterFilter: '',
            masterFilterPreTags: '',
            masterTags: '',

            projects: '',
            projectOptions: '',
            projectSelect: '',

            roles: '',
            roleOptions: '',
            roleOptionsFilter: '',
            roleSelect: '',

            tags: '',

            modal: false,
            modalType: '',
            modalTalent: '',

            sidebar: true,

            numfilters: 0,

            selectedTags: [],
            tagMatches: [],

            searchbar: '',
        }
    }

    componentDidMount() {

        this.listener = this.props.firebase.auth.onAuthStateChanged(
            authUser => {
                authUser
                ? this.setState({ uid: authUser.uid })
                : this.setState({ authUser: null })

                if (authUser) {
                    
                    this.props.firebase.completedUserCheckins(authUser.uid)
                    .once('value', snapshot => {
                        if (snapshot.exists()) {
                            var o = snapshot.val();
                            var l = Object.keys(o).map(key => ({
                                ...o[key],
                                id: key
                            }));
                            if (l) { this.setState({ master: l, masterFilter: l }) 
                            } else { this.setState({ master: '', masterFilter: '' }) }
                        } else { this.setState({ master: '', masterFilter: '' })
                        };
                    })

                    this.props.firebase.userTagsFilter(authUser.uid)
                    .on('value', snapshot => {
                        if (snapshot.exists()) {
                            var o = snapshot.val();
                            var l = Object.keys(o).map(key => ({
                                ...o[key],
                                id: key
                            }))
                            if (l) {
                                this.setState({
                                    masterTags: l,
                                })
                            } else {
                                this.setState({
                                    masterTags: '',
                                })
                            }
                        }
                    })

                    this.props.firebase.projects()
                    .orderByChild('createdBy')
                    .equalTo((authUser.uid))
                    .on('value', snapshot => {
                        if (snapshot.exists()) {
                            var o = snapshot.val();
                            var l = Object.keys(o).map(key => ({
                                ...o[key],
                                id: key,
                            }))
                            if (l) { this.setState({ projects: l }) 
                            
                            var projectOptions = [];
                            l.map(proj => {
                                return projectOptions.push({
                                    value: proj.id,
                                    label: proj.projectName,
                                })
                            })
                            this.setState({
                                projectOptions,
                            })

                            } else { this.setState({ projects: '', projectOptions: '' }) }
                        } else { this.setState({ projects: '', projectOptions: '' })
                        }
                    })

                    this.props.firebase.roles()
                    .orderByChild('createdBy')
                    .equalTo((authUser.uid))
                    .on('value', snapshot => {
                        if (snapshot.exists()) {
                            var o = snapshot.val();
                            var l = Object.keys(o).map(key => ({
                                ...o[key],
                                id: key,
                            }))
                            if (l) { this.setState({ roles: l }) 

                            var roleOptions = [];
                            l.map(role => {
                                return roleOptions.push({
                                    value: role.id,
                                    label: role.roleName,
                                    projectId: role.projectId,
                                })
                            })
                            this.setState({
                                roleOptions,
                                roleOptionsFilter: roleOptions,
                            })

                            } else { this.setState({ roles: '', roleOptions: '' }) }
                        } else { this.setState({ roles: '', roleOptions: '' })
                        }
                    })

                    this.props.firebase.userTags(authUser.uid)
                    .on('value', snapshot => {
                        if (snapshot.exists()) {
                            var o = snapshot.val();
                            var l = Object.keys(o).map(key => ({
                                ...o[key],
                                id: key,
                            }))
                            if (l) { this.setState({ tags: l }) 
                            } else { this.setState({ tags: '' }) }
                        } else { this.setState({ tags: '' })
                        }
                    })

                }   
            }
        )
    };

    _onChangeSearchBar = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
        if (event.target.value.length > 2) {
            var filterBySearch = this.state.master.filter(fil => {
                return (
                    fil.checkInName.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    fil.checkInProject.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    fil.checkInRole.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    fil.checkInRepCompany.toLowerCase().includes(event.target.value.toLowerCase())
                )});
            this.setState({
                numfilters: this.state.numfilters + 1,
                masterFilter: filterBySearch,
            })
        } else {
            this.setState({
                masterFilter: this.state.master,
                numfilters: 0
            });
        };
    };

    _openNotesModal = (item) => {
        this.setState({
            modalType: 'notes',
            modalTalent: item,
        })
        this._showModal();
    };

    _closeModal = () => {
        this.setState({
            modal: false,
            modalType: '',
        })
    };

    _showModal = () => {
        this.setState({
            modal: true,
        })
    };

    _notesModalBody = (modalTalent) => {
        return <NoteModalBody talent={modalTalent} uid={this.state.uid} firebase={this.props.firebase} />
    };

    currentModalState = () => {
        var { modalType, modalTalent } = this.state;

        if (modalType === 'notes') {
            return (
              <ModalContainer
              modalHeader={modalTalent.checkInName}
              modalBody={this._notesModalBody(modalTalent)}
              close={this._closeModal}
              />
            )
        };
    }

    _handleProjectSelect = projectSelect => {
        this.setState({
            projectSelect,
            roleSelect: '',
            numfilters: this.state.numfilters + 1
        })
        var roleOptionsFilter = this.state.roleOptions.filter(f => {
            return f.projectId === projectSelect.value;
        })
        var masterFilter = this.state.master.filter(f => {
            return f.projectId === projectSelect.value;
        })
        this.setState({
            roleOptionsFilter,
            masterFilterProject: masterFilter,
            masterFilterRole: '',
            masterFilter,
        })
    }

    _handleRoleSelect = roleSelect => {
        var s = this.state;
        this.setState({
            roleSelect,
        })
        var roleFilter = this.state.master.filter(f => {
            return f.roleId === roleSelect.value;
        })
        var matchCombine = [];
        /*if (s.selectedTags.length > 0) {
            s.selectedTags.map(t => {
                return s.masterTags.filter(mt => (
                    matchCombine.push(mt.tag === t.newTag)
                ))
            })
            var indexes = [];
            matchCombine.map((i, index) => {
                if (i === true) {
                    return indexes.push(index);
                }
            },
            );
        } else {
        */
        this.setState({
            selectedTags: [],
            masterFilter: roleFilter,
            masterFilterRole: roleFilter,
            numfilters: this.state.numfilters + 1
        })
    }
    

    _handleTagSelect = (tag) => {
        this.setState({
            tagSelect: tag.id,
            numfilters: this.state.numfilters + 1
        })
        var allTags = this.state.selectedTags;
        var newTag = tag.id;
        if (allTags) {
            allTags.push({newTag});
            this.setState({
                selectedTags: allTags,
            })
        } else {
            var firstTag = [newTag];
            this.setState({
                selectedTags: firstTag,
            })
        };
        var tagMatches = this.state.tagMatches;
        var newMatches = this.state.masterTags.filter(f => f.tag === tag.id);
        var allMatches = tagMatches.concat(newMatches);
        this.setState({
            tagMatches: allMatches
        });
        var tagMatchSubjects = allMatches.map(i => i.subject);
        var master = this.state.masterFilterRole ? this.state.masterFilterRole : this.state.masterFilterProject ? this.state.masterFilterProject : this.state.master
        var masterFilter = master.filter(f => {
            return tagMatchSubjects.includes(f.id);
        })
        this.setState({
            masterFilter: masterFilter,
        })
    }

    _handleTagDeselect = (tag) => {
        const s = this.state;
        var newTags = s.selectedTags.filter(f => {
            return f.newTag !== tag.id;
        })
        var tagMatches = s.tagMatches;
        var newMatches = tagMatches.filter(f => {
            return f.tag !== tag.id
        });
        var tagMatchSubjects = newMatches.map(i => i.subject);
        var master = s.masterFilterRole ? s.masterFilterRole : s.masterFilterProject ? s.masterFilterProject : s.master
        var masterFilter = master.filter(f => {
            return tagMatchSubjects.includes(f.id);
        })
        this.setState({
            selectedTags: newTags ? newTags : '',
            tagMatches: newMatches,
            masterFilter: newMatches ? masterFilter : newMatches && s.masterFitlerRole ? s.masterFitlerRole : newMatches && !s.masterFilterRole ? s.masterFilterProject : s.master,
            numfilters: s.numfilters - 1
        })
    }

    _toggleSidebar = () => {
        this.setState({
            sidebar: !this.state.sidebar,
        })
    }

    componentWillUnmount() {
        const { firebase } = this.props;
        const { uid } = this.state.uid;

        firebase.projects().off();
        firebase.roles().off();
        firebase.listCompleted().off();
        firebase.userTags(uid).off();
        this.props.firebase.userTagsFilter(uid).off();
    }

    render() {

        const { searchbar, masterFilter, roleOptions, roleOptionsFilter, roleSelect, projectOptions, projectSelect, tags, selectedTags } = this.state;

        return (

            <div className="search-layout">
            {
                this.state.filters ?
                <div className="search-layout-with-sidebar">
                    <div className={this.state.sidebar ? "search-sidebar" : "search-sidebar sidebar-min"}>
                        <img src={this.state.sidebar ? arrowdown : filtericon} className={this.state.sidebar ? "arrow-sidebar-min left" : "arrow-sidebar-min"} alt="Minimize Sidebar" onClick={this._toggleSidebar.bind(this) }/>
                        {this.state.sidebar ? 
                        <SearchSidebar 
                            projectOptions={projectOptions} 
                            projectSelect={projectSelect}

                            searchbar={searchbar}

                            _onChangeSearchBar={this._onChangeSearchBar}

                            roleOptions={roleOptions}
                            roleOptionsFilter={roleOptionsFilter}
                            roleSelect={roleSelect}

                            tags={tags}

                            _handleProjectSelect={this._handleProjectSelect}
                            _handleRoleSelect={this._handleRoleSelect}
                            _handleTagSelect={this._handleTagSelect}
                            _handleTagDeselect={this._handleTagDeselect}

                            selectedTags={selectedTags.map(f => f.newTag)}
                        />
                        :
                        <div />
                        }
                    </div>
                    <SearchListContent 
                        masterFilter={masterFilter}
                        firebase={this.props.firebase}
                        _openNotesModal={this._openNotesModal}
                        numfilters={this.state.numfilters}
                    />
                </div>

                :

                <div className="search-layout-without-sidebar">
                    <div className="search-nav">Open</div>
                    <SearchListContent />
                </div>
            }
            {
                this.state.modal ?
                <div className="modals-container">{this.currentModalState()}</div>
                :
                <div />
            }
            </div>
        )

    }
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AdvancedSearch);

const SearchPage = compose(
    withFirebase,
    withAuthentication
    )(SearchPageBase);

export { SearchPage };