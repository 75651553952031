import React from 'react';

import SlotItem from './SlotItem';

const TalentScheduleList = (props) => {

    return (

    <div>
        <div className="tal-sch-list-header">
            <div className="tal-sche-list-header-text">Add talent to next available slot</div>
            <input className="tal-sche-list-input" placeholder="Type name..." name="newTalent" value={props.s.newTalent} onChange={props._onChange.bind(this)} onKeyDown={props._onEnterTalent} />
        </div>
        <div className="tal-sch-list-body">
            {
                props.ps.sessSlotTimes ?
                props.ps.sessSlotTimes.map((slot, index) => (
                    <SlotItem 
                        roles={props.ps.sessionRoles} 
                        key={index} 
                        slotId={index}
                        slot={slot} 
                        onChange={props._onChange} 
                        firebase={props.pp.firebase} 
                        uid={props.pp.uid} 
                        listKey={props.s.listKey}
                        sessKey={props.s.sessKey} 
                        details={props.ps}
                    />
                ))
                :
                <div>No slots</div>
            }
        </div>
    </div>

    )

}

export { TalentScheduleList }