import React, { useState, useEffect } from 'react';
import { ObjectList } from '../../../Functions/Common';
import { NullUser } from '../../../../assets/nulluser';
import Select from '../../../Common/Select';

export default function TimeSlots(props) {
    
    const [slots, setSlots] = useState('');
    const [add, setAdd] = useState('');

    return (
        <div className="dash-time-slots-container relative">
            <div className="height-20" />
            <div className="dash-sub-section-header">Time Slots</div>
            {
                add ?
                <AddSlot 
                    firebase={props.firebase} 
                    id={props.id} 
                    cid={props.cid} 
                    uid={props.uid}
                    positions={props.positions}

                />
                :
                <button id="add-slot-btn" className="save-change-btn" onClick={() => setAdd(true)}>Add Slot</button>
            }
            <div className="height-20" />
            <Slots 
                schedule={props.schedule}
                firebase={props.firebase} 
                id={props.id} 
                cid={props.cid} 
                uid={props.uid}
                date={props.date}
                username={props.username}
                cname={props.cname}
            />
        </div>
    )
}

function AddSlot(props) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [h, setH] = useState('12');
    const [m, setM] = useState('00');
    const [ap, setAP] = useState('PM');
    const [aid, setAid] = useState('');
    const [apic, setApic] = useState('');
    const [position, setPosition] = useState('');
    const [posList, setPosList] = useState('');

    function prepPhone(e) {
        setPhone(e);
        if (e.length === 3) {
            setPhone(e + '-');
        }
        if (e.length === 7) {
            setPhone(e + '-');
        }
    }

    function addSlot() {

        var pos = {
            name: position.label,
            id: position.value,
        }

        var pck = {
            session: props.id,
            time: h + ':' + m + ' ' + ap,
            name: name,
            email: email,
            phone: phone,
            aid: aid,
            apic: apic,
            via: 'email',
            to: 'direct',
            uid: props.uid,
            cid: props.cid,
            rep: {
                name: '',
                email: '',
                phone: '',
            },
            position: {
                name: position.label,
                id: position.value,
            },
        };
        props.firebase.apiAddSessionSlot(pck);
        setName(''); setEmail('');
        setPhone(''); setH('');
        setM(''); setAP('');
        setAid(''); setApic('');
        setPosition('');
    }

    useEffect(() => {
        if (props.positions) {
            const pos = ObjectList(props.positions);
            const labs = [];
            pos.map((p) => {
                return labs.push({
                    label: p.name,
                    value: p.id
                })
            });
            setPosList(labs);
        } else {
            setPosList('');
        }
    }, [props.positions])

    const def = {
        label: 'Add a role',
        value: 'none',
    }
    return (
        <div className="nav-item-card card-white expand-to-fit">
            <div className="dash-sub-section-label">New Slot</div>
                <div className="height-10" />
                <div className="dash-add-slot-inputs das-one">
                    <div>
                        <p className="dash-input-label">Name</p>
                        <input placeholder="ex. John Smith" className="dash-input" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div>
                        <p className="dash-input-label">Position</p>
                        <Select 
                            id='dash-add-slot-position' 
                            default={position.label || 'Select position'}
                            options={posList ? posList : def}
                            selection={position.value}
                            function={(e) => setPosition(e)}
                        />
                    </div>
                    <div>
                        <p className="dash-input-label">Time Slot</p>
                        <input type="number" min="1" max="12" maxLength="2" placeholder="12" className="dash-input time" value={h} onChange={(e) => setH(e.target.value)} />
                        <input type="number" min="00" max="59" maxLength="2" placeholder="00" className="dash-input time" value={m} onChange={(e) => setM(e.target.value)} />
                        <button className={ap === 'AM' ? "ampm-button selected-item" : 'ampm-button'} onClick={() => setAP('AM')}>AM</button>
                        <button className={ap === 'PM' ? "ampm-button selected-item" : 'ampm-button'} onClick={() => setAP('PM')}>PM</button>
                    </div>
                </div>
                <div className="height-20" />
                <div className="dash-add-slot-inputs das-two">
                    <div className="inline">
                        <p className="dash-input-label">Email Address (Invites)</p>
                        <input placeholder="ex. ani@anicheckin.com" className="dash-input" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="inline">
                        <p className="dash-input-label">Phone (Optional)</p>
                        <input 
                            className="dash-input text-center" 
                            type="tel" 
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" 
                            maxLength="12" 
                            minLength="12"
                            placeholder="555-555-5555"
                            value={phone}
                            onChange={(e) => prepPhone(e.target.value)} 
                            />
                    </div>
                </div>
                <div id="dash-add-slot-btn">
                    <button id="add-to-schedule" className="save-changes-btn" onClick={() => addSlot()}>Add to Schedule</button>
                </div>
        </div>
    )
}

function Slots(props) {

    const [slots, setSlots] = useState('');

    useEffect(() => {
        if (props.schedule) {
            setSlots(ObjectList(props.schedule));
        } else {
            setSlots('');
        }
    }, [props.schedule]);

    const [exp, setExp] = useState('');
    const [modal, setModal] = useState('');
    const [replyto, setReplyto] = useState('');

    function sendEmail(i) {

        var pck = {
            name: i.name,
            email: i.email,
            replyto: replyto,
            company: props.cname,
            cid: props.cid,
            position: i.position,
            date: props.date,
            time: i.time,
            sender: props.username,
            session: props.id,
            slot: i.id,
        }
        props.firebase.apiSendApptInvite(pck);
        setModal('');
    }

    return (
        slots ?
        slots.map((item, index) => (
            <div 
                className={exp === item.id ? "nav-item-card card-white expand-to-fit relative" : "nav-item-card card-white relative"}
                onClick={exp !== item.id ? () => setExp(item.id) : null}
                key={item.id}
            >
                <div className="dash-slot-item-row-one grid">
                    <div className="dash-slot-item-time">{item.time}</div>
                    <img className="dash-slot-item-avatar" src={item.picture ? item.picture : NullUser} alt="Headshot" />
                    <div>
                        <div className="dash-slot-item-name">{item.name}</div>
                        <div className="dash-slot-item-name">{item.position.name}</div>
                    </div>
                    <div>
                        <div className="dash-slot-item-contact">Status</div>
                        <div className="dash-slot-item-contact">{
                            item.status === 'draft' ?
                            <div className="dash-slot-item-name">Not Sent</div> :
                            item.status === 'invited' ?
                            <div className="dash-slot-item-name">Invite Sent</div> :
                            item.status === 'confirmed' ?
                            <div className="dash-slot-item-name">Confirmed</div> :
                            item.status === 'reschedule' ?
                            <div className="dash-slot-item-name">New Time Request</div> :
                            null
                            
                        }</div>
                    </div>
                    {
                        item.status === 'invited' ?
                        null :
                        item.status === 'confirmed' ? 
                        <div>
                            <div className="dash-slot-item-contact">Next Step</div>
                            <button className="dash-slot-item-contact" onClick={() => setModal(item.id)}>Check-In</button>
                        </div> :
                        <div>
                            <div className="dash-slot-item-contact">Next Step</div>
                            <button className="dash-slot-item-contact" onClick={() => setModal(item.id)}>Send Invite</button>
                        </div>
                    }
                </div>
                {
                    modal ?
                    <InviteModal    
                        replyto={replyto}
                        setReplyto={setReplyto}
                        sendEmail={sendEmail}
                        item={item}
                        modal={modal}
                    />
                    :
                    null
                }
            </div>
        ))
        :
        <div className="null-description">No slots created</div>
    )
}

function InviteModal(props) {

    return (
        props.item.id === props.modal ?
        <div className="invite-modal-container">
            <input className="dash-input" value={props.replyto} onChange={(e) => props.setReplyto(e.target.value)} />
            <button 
                className="save-changes-btn"
                disabled={props.replyto.length < 5}
                onClick={() => props.sendEmail(props.item)}
            >Send Invite</button>
        </div>
        :
        null
    )
}