import React, { useState, useEffect } from 'react';
import { NullUser } from '../../../assets/nulluser';
import SessionFocus from '../../Focus/SessionFocus';
import moment from 'moment';
import OnlineSettings from './Online/OnlineSettings';
import smoothscroll from 'smoothscroll-polyfill';

export default function SessionContent(props) {

    const [focus, setFocus] = useState('');
    const [myFocus, setMyFocus] = useState('');
    const [auto, setAuto] = useState(true);
    const [tags, setTags] = useState('');

    const [length, setLength] = useState('');

    function prepAuto(auto) {
        if (auto) {
            setAuto(false);
        } else {
            setAuto(true);
            pushScroll(length)
        }
    }

    useEffect(() => {
        if (props.focus) {
            setFocus(props.focus);
            if (auto) {
                setMyFocus(props.focus);
            };
        } else {
            setFocus('');
        }
    }, [props.focus]);

    useEffect(() => {
        if (props.cid) {
            props.firebase.apiCompanyTags(props.cid)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({
                        ...o[key],
                        id: key,
                    }));
                    setTags(l);
                } else {
                    setTags('');
                }
            })
        }
    }, [props.cid]);

    const [isOpened, setIsOpened] = useState(true);

    useEffect(() => {
        if (props.open) {
            setIsOpened(true);
        } else {
            setIsOpened(false)
        }
    }, [props.open])

    function pushScroll(index) {
        
        if (props.mobile) {
            document.getElementById('scroll-selector').scrollLeft = index * window.innerWidth * .8 
        } else {
            document.getElementById('scroll-selector').scrollLeft = index * window.innerWidth * .8; 
        }
    }

    useEffect(() => {
        if (props.push) {
            if (props.push === 'first') {
                pushScroll(0);
            } else {
            pushScroll(props.push);
            }
        }
    }, [props.push]);

    useEffect(() => {
        if (props.subjects) {
            var o = Object.keys(props.subjects);
            if ((o.length - 1) !== length && auto) {
                setLength(o.length - 1);
                setScrollIndex(o.length - 1);
                if (props.mobile) {

                    document.getElementById('scroll-selector').scrollLeft = (o.length - 1) * window.innerWidth * .8;
                } else {
                    document.getElementById('scroll-selector').scrollLeft = (o.length - 1) * window.innerWidth * .8; 
                }
                
            }
        }
    }, [props.subjects]);

    const [cardWidth, setCardWidth] = useState('');
    const [scrollIndex, setScrollIndex] = useState(0);

    function prepScrollIndex(index) {
        if (props.mobile) {

            document.getElementById('scroll-selector').scrollLeft = index * window.innerWidth * .8;
        } else {
            document.getElementById('scroll-selector').scrollLeft = index * window.innerWidth * .8;
        }
        setScrollIndex(index);
    };

    function toNext() {
        document.getElementById('scroll-selector').scrollLeft = (scrollIndex + 1) * window.innerWidth * .8;
        setScrollIndex(scrollIndex + 1);
    }

    function toPrevious() {
        document.getElementById('scroll-selector').scrollLeft = (scrollIndex - 1) * window.innerWidth * .8;
        setScrollIndex(scrollIndex - 1);
    }

    const [magnify, setMagnify] = useState('');
    const [hold, setHold] = useState(false);

    useEffect(() => {
        if (magnify) {
            props.setRemoveToggle(true);
        } else {
            props.setRemoveToggle(false);
        }
    }, [magnify])

    function unFocusMobileCard() {
        setMagnify('');
        setHold(false);
    }

    function focusThisMobileCard(index) {
        var mag = magnify;
        setMagnify(index);
        document.getElementById('scroll-selector').scrollLeft = index * window.innerWidth * .8;
        var scrollStop = setTimeout(() => {
            setHold(true);
        }, 200);
        return (() => clearTimeout(scrollStop));
    };

    return (
        props.onlineOpen ?
            <OnlineSettings 
                firebase={props.firebase} 
                subjects={props.subjects}
                id={props.id} 
                cid={props.cid} 
                uid={props.uid}
                schedule={props.schedule}
                positions={props.positions}
                date={props.date}
                cname={props.cname}
                username={props.username}
            />
            :
            props.summary ?
            <SummaryView 
                firebase={props.firebase} 
                subjects={props.subjects}
                id={props.id} 
                cid={props.cid} 
                uid={props.uid}
            />
            :
            props.subjects ?
            <div id="scroll-selector" className={
                magnify === 0 ?
                "subject-scroll no-padding-left pause-scroll no-scroll-bars" :
                magnify === length - 1 ?
                "subject-scroll more-padding-right pause-scroll no-scroll-bars" :
                hold && props.mobile ? 
                "subject-scroll pause-scroll no-scroll-bars" : "subject-scroll no-scroll-bars"}
                >
                <div className={magnify ? "subject-holder full-screen" : "subject-holder"}>

                {
                props.subjects ?
                Object.keys(props.subjects).map(key => ({
                    ...props.subjects[key],
                    id: key,
                })).sort((a, b) => (moment(a.track.call, 'MM/DD/YYYY hh:mm:ss a').isBefore(moment(b.track.call, 'MM/DD/YYYY hh:mm:ss a')) ? -1 : 1))
                .map((item, index) => (
                <SessionFocus 
                    username={props.username} 
                    id={props.id} 
                    cid={props.cid} 
                    uid={props.uid}
                    index={index}
                    firebase={props.firebase} 
                    subjects={props.subjects}
                    focus={focus} 
                    myFocus={myFocus}
                    prepAuto={prepAuto}
                    auto={auto}
                    item={item}
                    key={item.id} 
                    scrollIndex={scrollIndex}
                    prepScrollIndex={prepScrollIndex}
                    tags={tags}
                    mobile={props.mobile}
                    magnify={magnify}
                    open={props.open}
                    focusThisMobileCard={focusThisMobileCard}
                    unFocusMobileCard={unFocusMobileCard}
                    pushOpenFromWrap={props.pushOpenFromWrap}
                />
                )) : null
                }
            </div>
            {props.mobile ? null : <Navigate toPrevious={toPrevious} toNext={toNext} />}
        </div>
        :
        <NoSubjects />
    )
};

function NoSubjects() {

    return (

        <div className="no-subjects-container">
            <img className="no-subjects-img" src={NullUser} alt="No Subjects" />
            <div className="no-subjects-text">No one seen yet!</div>
        </div>
    )
}

function Navigate(props) {

    return (
        <div className="session-view-navigate">
            <button onClick={() => props.toPrevious()}>Previous</button>
            <button onClick={() => props.toNext()}>Next</button>
        </div>

    )
}

function SummaryView(props) {

    return (
        <div className="dash-content">
            <div className="summary-container">
                <div className="dash-header">Session Summary</div>
                <div className="summary-section">
                    
                </div>
                <div className="summary-section">
                    <div className="summary-scroll">
                        {
                            props.subjects ?
                            Object.keys(props.subjects).map(key => ({
                                ...props.subjects[key],
                                id: key,
                            })).sort((a, b) => moment(a.track.call, 'MM/DD/YYYY hh:mm:ss a').isBefore(moment(b.track.call, 'MM/DD/YYYY hh:mm:ss a')) ? -1 : 1)
                            .map((item, index) => (
                                <SummaryCard key={item.id} item={item} />
                            )) : <div>'No subjects recorded'</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function SummaryCard(props) {

    return (
        <div className="summary-card">
            <div className="summary-header">
                <img src={NullUser} alt="Subject" />
                <div>
                    <div className="summary-name">{props.item.name}</div>
                    <div className="summary-pos">{props.item.interview.name}</div>
                </div>
            </div>
            <div className="summary-body">

            </div>
        </div>
    )
}