import React from 'react';

import { Switch } from '../Common/CustomSwitch';

export default function TalentGreeting(props) {
    return (
        <div>
            <div className="quick-bar-back-btn">
                <button className="quick-bar-back" onClick={props._backQuickBar}>Back</button>
            </div>
            <div className="greeting-header">
                <div className="greeting-label">Talent Greeting</div>
                <div className="greeting-description">This message displays to the actor after they complete the check in form.</div>
            </div>
            <div className="greeting-message-top">
                <div className="user-talent-signup-toggle">
                    <div className="qui-bar-label">User Talent Signup</div>
                    <Switch 
                        disabled={false}
                        boolean={props.talentSignUp}
                        text={props.talentSignUp ? 'Talent signup on' : 'Talent signup off'}
                        toggleFunction={props.talentSignUp ? 
                            props._turnOffTalentSignUp :
                            props._turnOnTalentSignUp
                        }
                    />
                </div>
                <br />
                    <div className="qui-bar-label">Current Greeting</div>
                    { 
                        props.newGreeting !== props.greeting ?
                        <button className="qui-greeting-save" onClick={props._updateGreeting}>Save Changes</button>
                        :
                        <div />
                    }
                </div>
            <div className="greeting-message-container">
                
                <textarea className="greeting-textarea" placeholder="Type in a greeting message for actors..." name="newGreeting" value={props.newGreeting} onChange={props.onInputChange.bind(this)} />
               
            </div>
        </div>
    )
}
