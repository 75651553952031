import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import moment from 'moment';

import { withFirebase } from '../../Firebase';
import * as ROUTES from '../../../constants/routes';

import formColor from '../../../assets/formColor.png';
import logo from '../../../assets/aniicon.png';


import '../../CheckIn/checkin.css';

const INITIAL_STATE = {
    name: '',
    email: '',
    pass: '',
    repass: '',
    repped: '',
    rep: '',
    error: null,
    loading: true,
}

class TalentSignUpFormBase extends Component {

    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE } 
    };

    componentDidMount() {
        var time = moment().format('MM/DD/YYYY hh/mm a');
        var URL = window.location.pathname;
        if (URL.includes('/talent-signup/-')) {
            const code = URL.replace('/talent-signup/', '');
            this.setState({
                url: window.location.pathname,
                code,
            })
            console.log(code);
            this.props.firebase.getActivationCode(code)
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    this.setState({
                        name: o.name,
                        email: o.email,
                        focus: 'password',
                        loading: false,
                    });
                };
            })
            this.props.firebase.getActivationCode(code)
            .update({
                opened: true,
                openedOn: time,
            });
        } else {
            this.setState({
                focus: 'name',
                loading: false,
            })
        }
    };
    

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onSubmit = event => {
        const { name, email, pass } = this.state;
        var date = moment().format('MM/DD/YYYY');

        this.props.firebase.doCreateUserWithEmailAndPassword(email, pass)
        .then(authUser => {
            this.props.firebase.createTalentUser(authUser.user.uid, name, email, date);
            this.props.firebase.user(authUser.user.uid)
            .set({
                name: name,
                email: email,
                type: 'talent',
            });
        })
        .catch(error => {
            this.setState({ error });
        });
        event.preventDefault();
    };

    render() {

        const { name, email, pass, repass, error } = this.state;

        const isInvalid = 
        pass !== repass ||
        pass.length < 8 ||
        email === '' ||
        name === '';

        return (
            this.state.loading ?
            <div />
            :
            <div className="talent-signin">
                <div className="check-in-form-container  talent-signin-container">
                <div className="header">
                    <div className="low-opacity"><img className="form-color" src={formColor} alt="Background Color"/></div>
                    <img className="ani-logo-talent-sign-in" src={logo} alt="ANi Logo"/>
                </div>

                <div className="check-in-form">

                <div className="check-in-header-text-talent-signup sign-up-margin">Sign up for free!</div> 
                <form onSubmit={this.onSubmit}>
                    <div className="form-signup-input-container">
                        <div className="form-signup-input-label">Name</div>
                        <input 
                            className="signup-talent-input"
                            name="name"
                            value={name}
                            autoFocus={this.state.focus === 'name' ? true : false}
                            onChange={this.onChange.bind(this)}
                            type="text"
                            placeholder="First and last name"
                            autoComplete="off"
                        />
                    </div>

                    <div className="form-signup-input-container">
                        <div className="form-signup-input-label">Email Address</div>
                        <input
                            className="signup-talent-input"
                            name="email"
                            value={email}
                            onChange={this.onChange.bind(this)}
                            type="text"
                            placeholder="Email"
                            autoComplete="off"
                        />
                    </div>


                    <div className="form-signup-input-container">

                        <div className="input-sub-label">Must be atleast 8 characters</div>
                        <div className="form-signup-input-label">Password</div>                      
                        <input  
                            className="signup-talent-input"
                            name="pass"
                            value={pass}
                            onChange={this.onChange.bind(this)}
                            type="password"
                            autoFocus={this.state.focus === 'password' ? true : false}
                            placeholder="Password"
                            autoComplete="off"
                        />
                    </div>
                    
                    <div className="form-signup-input-container">
                        <div className="form-signup-input-label">Retype Password</div>
                        <input
                            className="signup-talent-input" 
                            name="repass"
                            value={repass}
                            onChange={this.onChange.bind(this)}
                            type="password"
                            placeholder="Confirm Password"
                            autoComplete="off"
                        />
                    </div>
                    
                    <button disabled={isInvalid} type="submit" className="check-in-btn">Create My Account</button>

                    {error && <p>{error.message}</p>}
            </form>

            </div>
            </div>
      </div>
    )
    }
};

const TalentSignUpForm = compose(
    withRouter,
    withFirebase,
    )(TalentSignUpFormBase);

export default TalentSignUpForm;

export { TalentSignUpForm };



/*
<div className="form-signup-input-container">
<div className="rep-question">
    <div className="form-signup-input-label">Do you have a representative?</div>
    <div className="form-radio-container">
    <div className={repped ? "form-radio-option selected-radio" : 'form-radio-option unselected-radio'} onClick={(() => this.setState({ repped: true }))}>
        <div className="radio-text">Yes</div>
    </div>
    <div className={repped === false ? "form-radio-option selected-radio" : 'form-radio-option unselected-radio'} onClick={(() => this.setState({ repped: false }))}>
        <div className="radio-text">No</div>
    </div>
    </div>
</div>
</div>

{
repped ? 

<div className="form-signup-input-container">
<div className="form-signup-input-label">Rep Company</div>
    <input
        className="signup-talent-input" 
        name="rep"
        value={rep}
        onChange={this.onChange.bind(this)}
        type="text"
        placeholder="Rep company name"
    />
</div>
:
<div />
}
*/