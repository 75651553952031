import React from 'react';

import { Switch } from '../../Common/CustomSwitch';

const RoleItem = (props) => {

    return (
        <div className="room-cards role-card">
        <div>
        {
            props.item.isActive === false ?
            <div onClick={props._activateRole.bind(this, props.item)} className="role-card-right">
                <div className="card-name" >{props.item.roleName}</div>
            </div>
            :
            <div onClick={props._deactivateRole.bind(this, props.item)} className="role-card-right">
                <div className="card-name" >{props.item.roleName}</div>
            </div>
        }
        <div className="account-switch-padding">
            <Switch 
                boolean={props.item.isActive}
                toggleFunction={props.item.isActive ? 
                    props._deactivateRole.bind(this, props.item) : 
                    props._activateRole.bind(this, props.item)
                }
                text={props.item.isActive ? 'Role is active' : 'Role is disabled'}
            />
        </div>
        
        </div>
        <div className="settings-btn">
            <img className="trash-icon-list" src={props.trashcan} alt="Delete Role" onClick={props._deleteRole.bind(this, props.item)} />
        </div>
        </div>
    )
}

export { RoleItem };

/*
<button 
className={props.item.released ? "release-to-feed-btn released" : "release-to-feed-btn torelease"} 
onClick={props._releaseToJobFeed.bind(this, props.item)}
>
{!props.item.released ? 'Release to feed' : 'Remove from feed'}
</button>
*/
