import React, { Component } from 'react';

import moment from 'moment';

import close from '../../../assets/remove.png';
import discoveranilogo from '../../../assets/discoveranilogo.png';

import '../helptips.css';

var timer;
var timer1;

export default class ANiIDTip extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            name: '',
            hideContext: false,
            showThanks: false,
            scaleContainer: false,
        };
    };

    _onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    };

    _openTip = () => {
        this.props.toggleTip(true)
    };

    _closeTip = () => {
        this.props.toggleTip(false);
    };

    _sendActivation = () => {
        clearTimeout(timer, timer1);
        var p = this.props;
        var timestamp = moment().format('MM/DD/YYYY hh:mm a');

        //this.props.firebase.sendTalentActivation(this.state.name, this.state.email, p.uid, p.company, timestamp);

        this.setState({
            hideContext: true,
        });
        timer1 = setTimeout(() => {
            this.setState({
                scaleContainer: true
            })
        }, 300);
        
        timer = setTimeout(() => {
            this.setState({
                showThanks: true,
            })
        }, 500);

        clearTimeout(timer, timer1);
    };

    _returnToCheckIn = () => {

        this.props._setNameFromTip(this.state.name);
        this.setState({
            name: '',
            email: '',
        })
        this.props._closeANiIDTip();

    };


    render() {

        return (
            <div className={this.state.scaleContainer ? "tip-container tip-cont-scaled" : "tip-container"}>
                <div className="tip-logo-container">
                    <img className="tip-logo" src={discoveranilogo} alt="Discover ANi Logo" />
                </div>
                <div className="tip-header">
                    <div>Discover ANi</div>
                    <img className="help-tip-close" src={close} alt="Close Tip" onClick={this.props._closeANiIDTip} />
                </div>
                {
                    this.state.showThanks ?
                    <div className={this.state.showThanks ? "tip-body fadeIn" : "tip-body fadeOut"}>
                        <div className="tip-thanks-message">Thanks! An email has been sent. If you don't see it in the next few minutes, check your spam for an email from Discover ANi.</div>
                        <div className="tip-thanks-message">Once you finish checking in, the audition will be added to your account.</div>
                        <button className="return-to-form-btn" onClick={this._returnToCheckIn}>Return to Check In</button>
                    </div>
                    :
                    <div className={this.state.hideContext ? "tip-body fadeOut" : "tip-body fadeIn"}>
                        <div className="tip-thanks-message">
                            <b>ANi</b> is a new platform built to connect Entertainment 
                            professionals in a new way, and it's launching 
                            later this year.
                        </div>

                        <div className="tip-thanks-message">
                            By checking in to an audition using our form, you 
                            get <b>free early access</b> to an account, where you can 
                            start tracking your audtions.
                        </div>

                        <div className="tip-thanks-message">
                            If you'd like to sign up, please enter your name 
                            and email below to receive an activation link!
                        </div>
                        <div className="tip-line-divider" />
                        <div className="email-inputs-tip">
                        <input 
                            autoFocus 
                            className="ani-id-email-input" 
                            autoComplete="off" 
                            name="name" 
                            value={this.state.name} 
                            onChange={this._onChange.bind(this)} 
                            placeholder="Name" 
                        />
                        <div className="tip-input-container">
                            <input 
                                className="ani-id-email-input" 
                                autoComplete="off" 
                                name="email" 
                                value={this.state.email} 
                                onChange={this._onChange.bind(this)} 
                                placeholder="Email" 
                            />
                            <button className="confirm-input-container" onClick={this._sendActivation}>
                                <img className="confirm-input-icon" src={this.state.email.includes('@') && this.state.name.length > 3 ? this.props.checkpink : this.props.checkgray} alt="Confirm Email" />
                            </button>
                        </div>
                        </div>
                        <div className="tip-subtext">Your email will not be shared with this or any casting office and will remain confidential.</div>
                    </div>
                }
            </div>
        )
    }
}