import React from 'react';

export default function TagGroupsList(props) {
    return (
        <div className="sly-tag-add-box">
                <div 
                    className={
                        props.tagGroup === 'All' ?
                        "sly-tag-item sly-tag-selected" :
                        "sly-tag-item"
                    }
                    onClick={
                        () => props.setTagGroup('All')
                    }
                    >
                    All Tags
                </div>
                {
                    props.tagGroups ?
                    props.tagGroups.sort((a, b) => (a.group > b.group) ? 1 : -1)
                    .map((item, index) => (
                        <div 
                            key={item.id} 
                            id="group-tag-size"
                            className={
                                props.tagGroup.id === item.id ?
                                "sly-tag-item sly-tag-selected" :
                                "sly-tag-item"
                            }
                            onClick={
                                props._selectTagGroup.bind(this, item)
                            }
                            >
                            {item.group}
                        </div>
                    ))
                    :
                    <div className="null-text">
                        No groups created.
                    </div>
                }
                <div className="height-45" />
            </div>
    )
}
