import React, { useState, useEffect } from 'react';
import { ObjectList } from '../../Functions/Common';
import { NullUser } from '../../../assets/nulluser';
import tagicon from '../../../assets/tag.png';
import notesicon from '../../../assets/pencil.png';

export default function Candidates(props) {
    const [view, setView] = useState('list');
    const [add, setAdd] = useState(false);
    const [open, setOpen] = useState('');

    return (
        <div>
            Candidates
            <div className="pos-focus">{props.positionFocus}</div>
            <div className="height-10" />
            {/*<button 
                onClick={() => setView(view === 'list' ? 'grid' : 'list')}
                className="view-as">{view === 'list' ? 'View as: List' : 'View as: Grid'}
            </button>
            <button 
                onClick={() => setAdd(true)}
                className="add-candidate"
            >
                + Add Candidate
            </button>*/}
            <div className="height-10" />
            <div className="candidate-scroller no-scroll-bars scroll-90 padding-2">
            {
                props.candidates ?
                props.candidates.map((item, index) => (
                    <ListItem setOpen={setOpen} open={open} key={item.id} item={item} />
                ))
                :
                <div>No one interviewed for this positions</div>
            }
            </div>
        </div>
    )
}

function ListItem(props) {

    const [open, setOpen] = useState(false);
    const [notes, setNotes] = useState('');
    const [tags, setTags] = useState('');
    const [noteCount, setNoteCount] = useState('0');
    const [tagCount, setTagCount] = useState('0');

    useEffect(() => {
        if (props.open !== props.item.id) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }, [props.open])

    useEffect(() => {
        if (props.item.notes) {
            var nots = ObjectList(props.item.notes);
            setNotes(nots);
            setNoteCount(nots.length);
        } else {
            setNotes('');
            setNoteCount('0');
        }
    }, [props.item.notes])

    useEffect(() => {
        if (props.item.tags) {
            var tag = ObjectList(props.item.tags);
            setTags(tag);
            setTagCount(tag.length);
        } else {
            setTags('');
            setTagCount('0');
        }
    }, [props.item.tags])

    function prepOpen() {
        if (open) {
            props.setOpen('');
        } else {
            props.setOpen(props.item.id);
        }
    }

    return (
        <div className="candidate-item" onClick={() => prepOpen()}>
            <header>
                <img className="profilepic" src={NullUser} alt="Profile Picture" />
                <div className="inline">
                    <h1>{props.item.name}</h1>
                    <h2>{props.item.response ? props.item.response : 'None'}</h2>
                </div>
                <div className="candidate-counts">
                    <div className="candidate-count">
                        <img className="candidate-icon" src={notesicon} alt="Note Count" />
                        <h2>{noteCount}</h2>
                    </div>
                    <div className="candidate-count">
                        <img className="candidate-icon" src={tagicon} alt="Note Count" />
                        <h2>{tagCount}</h2>
                    </div>
                </div>
            </header>
            <div hidden={!open}>
                <div className="height-10" />
                <div className="candidate-body">
                    <div className="can-label">Notes</div>
                    <NoteDisplay notes={notes} />
                </div>
                <div className="height-10" />
                <div className="candidate-tags">
                    <div className="can-label">Tags</div>
                    <TagDisplay tags={tags} />
                </div>
            </div>
        </div>
    )
}

function NoteDisplay(props) {

    return (
        props.notes ? props.notes.map((item, index) => (
            <div className="can-note-item" key={item.id}>
                {item.note}
            </div>
        ))
        :
        null
    )
}


function TagDisplay(props) {

    return (
        props.tags ? props.tags.map((item, index) => (
            <div className="focus-tag-add-item mob-tag-it" key={item.id}>
                {item.tag}
            </div>
        ))
        :
        null
    )
}