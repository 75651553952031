import React, { useState, useEffect } from 'react';

import '../../transitions.css';

var timer1, timer2, timer3, timer4, timer5, timer6, timer7, timer8, timer9 = null;
var rTimer1, rTimer2 = null;

export default function FocusTalent(props) {

    const [talent, setTalent] = useState('');
    const [visible, setVisible] = useState(true);
    const [active, setActive] = useState(false);
    const [wrapped, setWrapped] = useState(false);
    const [transition, setTransition] = useState(false);
    const [step, setStep] = useState(0);
    
    useEffect(() => {
        if (props.activeCheckIn) {
            if (!active) {
                setActive(true);
            }  
        } else {
            if (active && !props.activeCheckIn) {
                setActive(false);
            } else {
                if (active) {
                    setActive(false);
                }
            }
        };
    }, [props.activeCheckIn])

    const removeCard = (e) => {
        setWrapped(true);
        setVisible(false);
        props.setFadeAddNote(true);
        rTimer1 = setTimeout(() => {
            props.removeCard();
        }, 200);
        rTimer2 = setTimeout(() => {
            setWrapped(false);
        }, 300)
        return (() => clearTimeout(rTimer1, rTimer2));
    }

    function startSequence() {
        timer1 = setTimeout(() => {
            setStep(1);
            setVisible(false);
        }, 100);
        timer2 = setTimeout(() => {
            setStep(2);
        }, 200);
        timer3 = setTimeout(() => {
            setStep(3);
        }, 300);
        timer4 = setTimeout(() => {
            setStep(4);
        }, 400);
        timer5 = setTimeout(() => {
            setStep(5);
        }, 500);
        timer6 = setTimeout(() => {
            props.prepAssign()
            setTransition(false)
            setVisible(true);
            setStep(0);
        }, 600)
        return (() => clearTimeout(timer1, timer2, timer3, timer4, timer5, timer6, timer7))
    }

    useEffect(() => {
        if (props.topFadeIn) {
            setTransition(true);
            startSequence()
        }
    }, [props.topFadeIn])

    const prepSendBack = () => {
        setVisible(false);
        timer7 = setTimeout(() => {
            props._sendTalentBackToList();
        }, 500)
        return (() => clearTimeout(timer7))
    }


    return (

        <div className={step > 1 || active ? "vacant-focus-card" : "vacant-focus-card shrinking-card height-20"}>   
            {
                active ?
                <div>
                    <div className={
                            visible ? 
                            "i-pad-focus-talent-card active-focus-talent-card fadeInHeader" :
                            "i-pad-focus-talent-card active-focus-talent-card fadeOutNew"}
                            >
                        <div className="focus-talent-elements-display">
                            <div>
                                <img className="user-avatar-active-card" src={props.NullUser} alt="Actor Pic" />
                                <div className="talent-elements">
                                    <div className="rp-active-talent-name">{props.name}</div>
                                    <div className="rp-active-talent-rep">{props.rep}</div>
                                    <div className="rp-active-talent-project">{props.project}</div>
                                    <div className="rp-active-talent-role">{props.role}</div>
                                </div>
                            </div>
                            <div>
                                <div className="height-20" />
                                <button 
                                    className="complete-btn-room-2" 
                                    onClick={() => removeCard()}>
                                    Complete
                                </button>
                                <div className="send-back-to-q" onClick={prepSendBack}>
                                    Send Back
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                props.checkIns ?
                    step !== 0 ?
                    <div className={
                            step === 1 ? "card-assign step-1 fadeInNew" :
                            step === 2 ? "card-assign step-1 step-2" :
                            step === 3 ? "card-assign step-1 step-2 step-3" :
                            step === 4 ? "card-assign step-1 step-2 step-3 step-4" :
                            step === 5 ? "card-assign step-1 step-2 step-3 step-4 step-5"
                            : "fadeOutFast"
                        }
                    >
                      
                    </div>
                    :
                    <div className="ro-current-available-btn cur-point text-align-center padding-top-15">
                        <div className="null-text padding-top-15">
                            Click name to assign
                        </div>
                    </div>
                    :
                    <div className="ro-current-available-btn cur-point text-align-center padding-top-15" >
                        <div className="null-text padding-top-15">
                            Click name to assign
                        </div>
                    </div>

                }
        </div>
    )
}
