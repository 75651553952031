import React, { Component } from 'react';

export default class ProfileImageUpload extends Component {

    constructor(props) {
        super(props);

        this.state = {
            avatar: '',
            isUploading: false,
            progress: 0,
            url: "",
            loading: true,
        };
    }

    componentDidMount() {
        this.setState({
            loading: false,
        })
    }

    render() {

        return (
            <div>
                <label className="upload-prof-pic">
                <img src={this.props.avatar} alt="Avatar" className="tal-sche-slot-avatar" />
                <input
                    hidden
                    type="file"
                    name="avatar"
                    onChange={this.props._handleProfPicUpload}
                />
                </label>
            </div>
        )
    };
};