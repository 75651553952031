import React, { useState, useEffect }  from 'react';
import ArrivalCard from '../../../Common/ArrivalCard';
import moment from 'moment';
import gear from '../../../../assets/nav/gear.png';

export default function Complete(props) {

    function moveBack(item) {
        props.firebase.apiCompanyGuest(props.cid, item.id)
        .update({
            complete: false,
            track: {
                in: item.track.in,
                out: '',
                call: '',
            },
            status: 'waiting',
        });
        props.firebase.apiSessionSubject(props.id, item.id)
        .remove();
    }

    const [list, setList] = useState('');

    useEffect(() => {
        if (props.sessObj.subjects) {
            var complete = Object.keys(props.sessObj.subjects).map(key => ({
                ...props.sessObj.subjects[key],
                id: key,
            }));
            setList(complete);
        } else {
            setList('');
        }
    }, [props.sessObj.subjects]);

    function prepIndex(index) {
        if (index === 0) {
            props.pushScroll(0);
        } else {
            props.pushScroll(index);
        }
    }

    return (
        <div className="complete-section">
            <div className="nav-section-label">Completed</div>
            {
                list ?
                list.filter(fil => {
                    return fil.track.call
                })
                .sort((a, b) => moment(a.track.call, 'MM/DD/YYYY hh:mm:ss a').isBefore(moment(b.track.call, 'MM/DD/YYYY hh:mm:ss a')) ? -1 : 1)
                .filter(fil => { return fil.track.out})
                .map((item, index) => (
                    <ArrivalComponent moveBack={moveBack} pushScroll={prepIndex} index={index} key={item.id} item={item} />
                ))
                :
                <div className="null-text">No completed items</div>
            }
        </div>
    )
}

function ArrivalComponent(props) {

    const [settings, setSettings] = useState(false);
    var item = props.item;

    function sendBackToCheck() {
        props.moveBack(props.item);
    }

    return (
        <div className="relative">
            <img className="arrival-card-icon" src={gear} alt="Actor Settings" onClick={() => setSettings(!settings)} />
            <div onClick={settings ? null : () => props.pushScroll(props.index)}>
                <ArrivalCard name={item.name} sub={item.response} type="complete" track={item.track} removeFunction={sendBackToCheck} settings={settings} functionText="Send Back" />
            </div>
        </div>
    )
}
