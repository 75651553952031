import React, { Component } from 'react';

export default class QuickAddList extends Component {

    render() {

        return (
            <div></div>
        )
    }
}