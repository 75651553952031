import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { withAuthorization, withAuthentication } from '../Session';
import * as ROUTES from '../../constants/routes';
import { compose } from 'recompose';
import moment from 'moment';
import discoveranilogo from '../../assets/ANiLogoDark.png';
import anditsgone from '../../assets/anditsgone.jpg';

function PortalCheckIn(props) {

    const [data, setData] = useState('');
    const [id, setId] = useState('');
    const [checkedIn, setCheckedIn] = useState(false);
    const [join, setJoin] = useState(false);
    const [boot, setBoot] = useState('');

    useEffect(() => {
        var URL = window.location.pathname;
        var invite = URL.replace('/portal/check-in/', '');
        setId(invite);
    
        props.firebase.apiInvite(invite)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                setData(o);
                if (o.checkedIn) {
                    setCheckedIn(true);
                }

                if (o.join) {
                    setJoin(true);
                }

                if (o.boot) {
                    setBoot(true);
                }
            };
        })
    }, []);

    function checkIn() {
        var browser = window.innerWidth;
        var defaultMtg = {
            name: '',
            id: '',
        };
        var defaultInt = {
            name: '',
            id: '',
        };
        var newPck = {
            appt: id,
            browser: browser < 500 ? 'mobile' : 'web',
            name: data.name,
            ref: data.slot,
            meeting: '',
            interview: data.position || defaultInt,
            type: 'audition',
            via: 'online',
            response: data.response || '',
            apptTime: data.time,
        };
        props.firebase.apiNewCompanyGuest(data.cid, newPck);
        props.firebase.apiSessionSlot(data.session, data.slot)
        .update({
            status: 'checked',
        });
        props.firebase.apiInvite(id)
        .update({
            checkedIn: true,
        });
    }
    
    return (
        <div className="invite-bg">
            <div className="invite-card">

                {
                checkedIn ?
                    boot ?
                    <img className="and-its-gone" src={anditsgone} alt="And its gone!" />
                    :
                <div className="input-container">
                <div className="register-form-header">The link to join will appear when they are ready!</div>
                <div>
                
                    {join ? <button className="download-app">Join</button> : null}
                        
                </div>
                <div className="height-30" />
                </div>
                :
                <div className="input-container">
                    <div className="register-form-header">Please Check-In below to let the team know you are ready!</div>
                    <div>
                       
                        <button className="download-app" onClick={() => checkIn()}>Check In</button>
                        
                    </div>
                <div className="height-30" />
                </div>
                }
              
            </div>
        </div>
    )
}


const condition = authUser => !!authUser;

const VerCompose = compose(
    withFirebase,
    withRouter,
    withAuthentication
    )(PortalCheckIn);

export default withAuthorization(condition)(PortalCheckIn);