import React, { useState, useEffect } from 'react';
import SessionSchedule from './Schedule/SessionSchedule';

export default function Talent(props) {

    const [schedule, setSchedule] = useState('');

    useEffect(() => {
        if (props.props.uid && props.props.groupId) {
            props.firebase.sessionSchedule(props.props.groupId)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    setSchedule(o);
                }
            })
        }
    }, [])

    const _importSchedule = () => {
        props.firebase.selectCastingSessionSchedule(props.props.uid, props.props.sessionId)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                props.firebase.sessionInfo(props.props.groupId)
                .update({
                    schedule: o
                });
            } else {
                setSchedule('');
            }
        })
    };

    return (
        <div>
            {
                schedule === '' ? 
                    <button 
                        className="import-schedule-btn"
                        onClick={_importSchedule}    
                    >  
                        Import Schedule
                    </button>
                    :
                    <SessionSchedule firebase={props.firebase} schedule={schedule} groupId={props.props.groupId} />
            }
        </div>
    )
}
