import React from 'react';

import pendingschedule from '../../../../assets/pendingschedule.png';
import speechbubble from '../../../../assets/speech-bubble.png';

const TalentListItem = ({item, _timeDifference, index}) => {

    var length = _timeDifference(item.wrap, item.start);

    var tags = item.tags ? Object.keys(item.tags).map(key => ({
        ...item.tags[key],
        id: key,
    })) : '';

    var notes = item.notes ? Object.keys(item.notes).map(key => ({
        ...item.notes[key],
        id: key,
    })) : '';

    var noteLength = notes ? notes.length : '0';

    return (

        <div className="sb-talent-card">
            <div className="sb-talent-card-top">
                <div className="sb-talent-card-note-count">
                    <img className="sb-talent-card-note-icon" src={speechbubble} alt="Tag Icon" />
                    <div className="sb-talent-card-note-count-text">{noteLength}</div>
                </div>
                <div className="sb-talent-card-talent">
                    <div className="sb-talent-card-name">{item.name}</div>
                    <div className="sb-talent-card-role">{item.role}</div>
                    <div className="sb-talent-card-rep">{item.rep}</div>
                </div>
                <div className="sb-talent-card-tag-container">
                    <div className="sb-talent-card-hash">#</div>
                    <div className="sb-talent-card-tag-list">
                        {tags ? tags.map((item, index) => (
                            <div key={item.id} className="sb-talent-card-tag">{index === tags.length - 1 ? item.tagName : item.tagName + ', '}</div>
                        )) : 'No tags'}
                    </div>
                </div>
            </div>
            <div className="sb-talent-card-bottom">
                    <div className="sb-talent-card-time-section">
                        <img className="sb-talent-card-time-icon" src={pendingschedule} alt="Time Icon" />
                        <div className="sb-talent-card-time-text">{item.in ? item.in : ''}</div>
                    </div>
                    <div className="sb-talent-card-time-section">
                        <img className="sb-talent-card-time-icon" src={pendingschedule} alt="Time Icon" />
                        <div className="sb-talent-card-time-text">{item.start ? item.start : ''}</div>
                    </div>
                    <div className="sb-talent-card-time-section">
                        <img className="sb-talent-card-time-icon" src={pendingschedule} alt="Time Icon" />
                        <div className="sb-talent-card-time-text">{length ? length === 1 ? length + ' minute' : length + ' minutes' : 'N/A'}</div>
                    </div>
            </div>
        </div>
    )
}

export { TalentListItem };