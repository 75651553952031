import React, { useState, useEffect } from 'react';

import { NullUser } from '../../../assets/nulluser';

export default function OnDeckItem(props) {

    const [active, setActive] = useState('');
    const [onDeck, setOnDeck] = useState('');

    useEffect(() => {
        
        var roomActive = props.active ? 
        props.active.filter(fil => {
            return props.room.id === fil.roomId
        }) : '';
        
        if (active !== roomActive) {
            setActive(roomActive); 
        }

        var roomNext = props.onDeck ?
            props.onDeck.filter(fil => {
                return fil.room === props.room.id
            }) : '';

        if (onDeck != roomNext) {
            setOnDeck(roomNext)
        }
    }, [props.active, props.onDeck])

    return (
        <div className="on-deck-room">
            <div className="on-deck-room-name">{props.room.roomName}</div>
            <div className="on-deck-columns">
            <div className="on-deck-card-padding">
                <div className="on-deck-label">Active</div>
                {
                    active.length > 0 ? active.map(active => (
                            <div key={active.name}>
                                <div className="on-deck-talent-name">
                                    {active.name}
                                </div>
                                <div className="on-deck-talent-project">
                                    {active.project}
                                </div>
                        </div>
                    ))
                    :
                    <div className="null-text"></div>
                }
            </div>
                <div className="on-deck-card-padding">
                <div className="on-deck-label">On Deck</div>
                {
                    onDeck ?
                    onDeck.map((tal, index) => (
                            <div key={tal.next.checkInName}>
                                <div className="on-deck-talent-name">
                                    {tal.next.checkInName}
                                </div>
                                <div className="on-deck-talent-project">
                                    {tal.next.checkInProject}
                                </div>
                            </div>
                    )) : ''
                    }
                </div>
            </div>
            </div>
    )
}
