import React, { Component } from 'react';

import ProfileImageUpload from './ProfileImageUpload';

export default class EmployeeItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            newEmail: '',
            disabled: true,
            avatar: '',
        }
    };

    componentDidMount() {
        this.props.firebase.userEmployee(this.props.uid, this.props.employee.id)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                this.setState({
                    email: o.email,
                    newEmail: o.email,
                    avatar: o.avatar ? o.avatar : 'https://firebasestorage.googleapis.com/v0/b/ani-check-in.appspot.com/o/static%2Fusernull.png?alt=media&token=6f44f86c-53bc-449f-baef-7465901d6b60'});
            } 
        });
    };

    _onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    _updateEmployeeEmail = (item) => {
        this.props.firebase.updateEmployeeEmail(this.props.uid, item.id, this.state.email);
        this.setState({
            disabled: true,
        })
    };

    _handleProfPicUpload = (e) => {
        if (e.target.files[0]) {
            const image = e.target.files[0];
            this.setState({
                image,
            })
            this.props.firebase.uploadEmployeeAvatar(this.props.uid, this.props.employee.id, image);
        }
    }

    render() {

        return (
            <div key={this.props.employee.id} 
                className={
                    this.state.disabled ? 
                    "employee-cards" :
                    "employee-cards edit-employee-card"    
                }
            >
                <div className="settings-btn">
                  <img className="trash-icon-list" src={this.props.trashcan} alt="Remove Employee" onClick={this.props.deleteEmployeeClick.bind(this, this.props.employee)} />
                </div>
                <div className="employee-item-layout">

                    <div className="upload-prof-pic"> 
                    <ProfileImageUpload avatar={this.state.avatar} _handleProfPicUpload={this._handleProfPicUpload} />
                    </div>
                    <div>
                        <div className="card-name">{this.props.employee.employeeName}</div>
                        {
                        this.state.disabled ? 
                        <div className="employee-email" onClick={(() => this.setState({disabled: false}))}>{this.state.email ? this.state.email : 'Add email address'}</div>
                        :
                        <div>
                            <input 
                                className="add-employee-email-input" 
                                placeholder="Email address..." 
                                value={this.state.email} 
                                name="email" 
                                autoFocus
                                autoComplete="nope"
                                onChange={this._onChange.bind(this)} 
                            />
                            
                            <button className="remove-employee-email"
                                onClick={(() => this.setState({ disabled: true, email: this.state.newEmail }))}
                            >
                            Cancel
                            </button>
                            {
                                this.state.email !== this.state.newEmail ?
                                <button className="save-employee-email" 
                                    onClick={this._updateEmployeeEmail.bind(this, this.props.employee)}
                                    disabled={
                                        this.state.email === '' ? 
                                        false :
                                        this.state.email ? 
                                            this.state.email.includes('@') ? 
                                            false : 
                                        false : 
                                        true
                                    }
                                    >
                                    Save Changes
                                </button>  
                                :
                                <div />
                            }
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}