import React, { Component } from 'react';

import Select from 'react-select';

export default class SessionSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            session: '',
            sessionOptions: '',
        };
    }

    componentWillReceiveProps(nextProps, props) {
        if (nextProps.sessionId !== this.props.sessionId) {
            this.props.firebase.selectCastingSession(this.props.uid, nextProps.sessionId)
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var name = o.name;
                    var sessId = o.id;
                    var session = [];
                    session.push({
                        label: name,
                        value: sessId,
                    });
                    this.setState({ session });
                } else {
                    this.setState({ session: '' });
                };
            });
        }
    }

    _handleSelectSession = session => {
        this.setState({
            session,
        })
    }

    componentDidMount() {
        

        this.props.firebase.userCastingSessions(this.props.uid)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }));
                if (l) {
                    var sessionOptions = [];
                    l.map(i => {
                        return sessionOptions.push({
                            label: i.name,
                            value: i.id,
                        });
                    });
                    this.setState({
                        sessionOptions,
                    });
                } else {
                    this.setState({
                        sessionOptions: '',
                    });
                }
            } else {
                this.setState({
                    sessionOptions: '',
                })
            }
        });
    }

    componentWillUnmount() {
        this.props.firebase.userCastingSessions(this.props.uid).off();
    }

    render() {

        return (
            <div className="session-select-display">

                <Select 
                    value={this.state.session}
                    onChange={this._handleSelectSession}
                    placeholder='Select Session'
                    options={this.state.sessionOptions}
                    isDisabled
                    />
                    
            </div>
        )

    }
}