import React, { Component } from 'react';

import moment from 'moment';

import arrowdown from '../../../assets/arrowdown.png';

export default class ModalProjectRoleDropdown extends Component {

    constructor(props) {
        super(props)

        this.state = {
            roleDropdown: false,
            roles: '',
        };
    }

    _expandRoleDropdown = () => {
        this.setState({
            roleDropdown: !this.state.roleDropdown,
        })
    }

    _closeRoleDropdown = () => {
        this.setState({
            roleDropdown: false,
        })
    }

    _addRole = (item) => {
        var roleId = item.id;
        var projectId = item.projectId;
        var roleName = item.roleName;
        var roomId = this.props.roomId;
        var uid = this.props.uid;

        if (this.props.activeRoles !== '') {
            var roleIds = this.props.activeRoles.map(i => i.id);

            if (roleIds.includes(roleId)) {
                alert('already added') 
            } else {
                this.props.firebase.newRoomRole(roleId, projectId, roleName, roomId, uid);
            }   
        } else {
            this.props.firebase.newRoomRole(roleId, projectId, roleName, roomId, uid);
        }

        this.props.firebase.roleUpdate(roleId)
        .update({
            isActive: true,
        })

        this.props.firebase.projectRoleUpdate(projectId, roleId)
        .update({
            isActive: true,
        })
    }

    _removeRoleFromDropdownPrep = (item) => {
        var timeRemoved = moment().format('MM/DD/YY hh:mm a');
        var roleId = item.id;
        var projectId = item.projectId;
        var roleName = item.roleName;
        var roomId = this.props.roomId;
        var uid = this.props.uid;
        this.props.firebase._removeRoleFromRoomRoleModalDropdown(uid, roomId, roleId, projectId, roleName, timeRemoved);
    }

    componentDidMount() {
        var item = this.props.item;
        var projectId = item.projectId;
        this.props.firebase.roles()
        .orderByChild('projectId')
        .equalTo((projectId))
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                this.setState({
                    roles: l,
                })
            } else {
                this.setState({
                    roles: ''
                })
            }
        });
    };

    componentWillUnmount() {
        this.props.firebase.roles().off();
    }

    render(){

        const { roles, roleDropdown } = this.state;
        const { item, activeRoles } = this.props;

        var activeArray = activeRoles ? activeRoles.map(ar => ar.id) : '';

        var projectRoles = roles ? 
        roles.filter(f => { return f.projectId === item.projectId }) : '';

        return (
            <div className="select-custom-project-roles">
                <div className={this.props.projectDropdown || this.props.userDropdown ? "select-arrow-roles invisible" : "select-arrow-roles"} onClick={this._expandRoleDropdown.bind(this)}>
                    <img src={arrowdown} className={roleDropdown ? "up-arrow" : "down-arrow"} alt="Expand" />
                </div>
                {
                this.state.roleDropdown ?
                <div className="custom-dropdown-project-roles" onMouseLeave={this._closeRoleDropdown} >
                    <div className="dropdown-message">Select roles below</div>
                    {
                    projectRoles ?
                    projectRoles.map((item, index) => {
                        return (
                            <div 
                                className={activeArray.includes(item.id) ? "custom-dropdown-item-select" : "custom-dropdown-item"}
                                onClick={activeArray.includes(item.id) ? this._removeRoleFromDropdownPrep.bind(this, item) : this._addRole.bind(this, item)}
                                key={index}
                                >
                                {item.roleName}
                            </div>
                            )
                        })
                        :
                        <div className="custom-dropdown-item">No roles</div>
                    }
                </div>
                :
                <div />
                }
                
            </div>
        )
    }
}