import React, { Component } from 'react';

import leftarrow from '../../assets/left-arrow.png';

export default class ConnectOptions extends Component {

    constructor(props) {
        super(props)

        this.state = {
            combine: false,
            project: '',
        }
    }

    _keepProject = () => {
        const { uid, item } = this.props;
        console.log('logged');

        this.props.firebase.projectSelect(item.id)
        .update({
            temp: false,
        })

        this.props.firebase.userProjectAttributes(uid, item.id)
        .update({
            temp: false,
        })
    };

    _onSelect = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
        this._handleProjectTransfer(event.target.value);
    }

    _handleProjectDelete = () => {
        
        const { item, uid } = this.props;
        var projectId = item.id;
        var transferTo = item.transferTo;

        this.props.firebase.removeProject(projectId);
        this.props.firebase.removeUserProject(uid, projectId);

    }

    _handleProjectTransfer = (projectId) => {

        var uid = this.props.uid;
        var item = this.props.item;

        this.props.firebase.projectSelect(item.id)
        .update({
            transfer: true,
            transferTo: projectId,
        });

        this.props.firebase.userProjectAttributes(uid, item.id)
        .update({
            transfer: true,
            transferTo: projectId,
        });

        this.props.firebase.projectSelect(projectId)
        .once('value', snapshot => {
            var o = snapshot.val();
            var projectName = o.projectName;

            this.props.firebase.roles()
            .orderByChild('projectId')
            .equalTo((item.id))
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({
                        ...o[key],
                        id: key
                    }));
                    l.map((role, index) => {
                        this.props.firebase.roleAttributes(role.id)
                        .update({
                            projectId: projectId,
                            project: projectName
                        })
                    })
                }
            });

            this.props.firebase.viewProjectRoles(item.id)
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({
                        ...o[key],
                        id: key,
                    }));
                    l.map((role, index) => {
                        this.props.firebase.transferProjectRole(role.id, role.roleName, projectId, role.createdOn);
                    })
                }
            });

            this.props.firebase.removeRoomProjectFromTransfer(item.id, projectId, projectName);
            this.props.firebase.removeProjectRoomRolesFromTransfer(item.id, projectId);

            this.props.firebase.listCheckInQ()
            .orderByChild('projectId')
            .equalTo((item.id))
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({
                        ...o[key],
                        id: key
                    }))
                    var i = l[0].id;
                    this.props.firebase.selectCheckIn(i)
                    .update({
                        checkInProject: projectName,
                        projectId: projectId,
                    });
                } else {
                    this.props.firebase.listInSession()
                    .orderByChild('projectId')
                    .equalTo((item.id))
                    .once('value', snapshot => {
                        if (snapshot.exists()) {
                            var o = snapshot.val();
                            var l = Object.keys(o).map(key => ({
                                ...o[key],
                                id: key
                            }))
                            var i = l[0].id;
                            var r = l[0].checkInRoom;
                            this.props.firebase.selectInSession(i)
                            .update({
                                checkInProject: projectName,
                                projectId: projectId,
                            });
                            this.props.firebase.roomAttributes(r)
                            .update({
                                checkInProject: projectName,
                            })
                            this.props.firebase.userRoomAttributes(uid, r)
                            .update({
                                checkInProject: projectName,
                            });
                        } else {
                            this.props.firebase.listCompleted()
                            .orderByChild('projectId')
                            .equalTo((item.id))
                            .once('value', snapshot => {
                                if (snapshot.exists()) {
                                    var o = snapshot.val();
                                    var l = Object.keys(o).map(key => ({
                                        ...o[key],
                                        id: key
                                    }))
                                    var i = l[0].id;
                                    this.props.firebase.selectCompleted(i)
                                    .update({
                                        checkInProject: projectName,
                                        projectId: projectId,
                                    });
                                } else {
                                }
                            })
                        }
                    })
                }
            })
        })
    }


    render() {

        const { projects } = this.props;
        var verified = projects.filter(f => {
            return f.temp === false || f.temp === ''
        });

        const item = this.props.item; 

        return (
            <div className="connect-container">
                {
                    item.transfer ? 
                    <div>Project elements successfully transferred. Confirm deletion?
                        <div id="connect-remove" className="connect-btn" onClick={this._handleProjectDelete}>Delete</div>
                    </div>
                    :
                    <div>This project was added manually at check-in. What would you like to do?
                    {
                    this.state.combine ?
                        <div className="connect-select-container">
                            <img className="cancel-select" src={leftarrow} alt="Back" onClick={(() => this.setState({combine: false}))}/>
                            <select className="connect-select" onChange={this._onSelect.bind(this)} value={this.state.project} name="project">
                                <option>Choose project to connect</option>
                                {verified.map((p, index) => (
                                    <option key={index} value={p.id} label={p.projectName}></option>
                                ))}
                            </select>
                        </div>
                        :
                        <div className="connect-options">
                            <div id="connect-keep" className="connect-btn" onClick={this._keepProject}>Keep</div>
                            <div id="connect-combine" className="connect-btn" onClick={(() => this.setState({combine:true}))}>Transfer</div>
                        </div>
                    }   
                    </div>
                }
        </div>
        )
    }
}