import React, { Component } from 'react';

import moment from 'moment';

import ModalProjectRoleDropdown from './ProjectRoleDropdown';
import { CustomDropdown } from '../../Common/CustomDropdown';

import '../modals.css';
import '../../transitions.css';
import arrowdown from '../../../assets/arrowdown.png';
import remove from '../../../assets/remove.png';
import addplususers from '../../../assets/addplususers.png';
import addproject from '../../../assets/addproject.png';

export default class RoomModalBody extends Component {

    constructor(props) {
        super(props);

        this.state = {
            optionsReaders: [],
            activeReaders: [],
            activeProjects: [],
            activeRoles: [],
            employeeSelect: '',
            projectSelect: '',
            userDropdown: false,
            projectDropdown: false,
            roleDropdown: false,
            selectedReaderIds: [],
            selectedProjectIds: [],
            projectSearch: '',
            employeeSearch: '',
            roleSearch: '',
        }
    }

    componentDidMount() {
        var { room, firebase, uid } = this.props;
        var roomId = room.id;
        this.setState({
            roomId: roomId,
            uid: uid,
        })

        firebase.room(roomId)
        .on('value', snapshot => {
            if (snapshot.exists()) {
            var o = snapshot.val();
            var areaders = o.roomReaders;
            var aprojects = o.activeProjects;
            var aroles = o.activeRoles;

            var readers = this.props.employees;
                var optionsReaders = [];
                readers.map((r, index) => {
                    optionsReaders.push({
                        value: r.id,
                        label: r.employeeName
                    })
                    this.setState({
                        optionsReaders,
                    })
                })

            if (areaders) {
                var ar = Object.keys(areaders).map(key => ({
                    ...areaders[key],
                    id: key
                }))
                this.setState({
                    activeReaders: ar,
                })
                var selectedReaderIds = ar.map(item => item.employeeId);
                this.setState({
                    selectedReaderIds,
                })
            } else {
                this.setState({
                    activeReaders: '',
                    selectedReaderIds: ''
                })
            }
            if (aprojects) {
                var ap = Object.keys(aprojects).map(key => ({
                    ...aprojects[key],
                    id: key
                }))
                this.setState({
                    activeProjects: ap,
                })

                var selectedProjectIds = ap.map(item => item.projectId);
                this.setState({
                    selectedProjectIds,
                })
            } else {
                this.setState({
                    activeProjects: '',
                    selectedProjectIds: '',
                })
            }
            if (aroles) {
                var aro = Object.keys(aroles).map(key => ({
                    ...aroles[key],
                    id: key
                }))
                this.setState({
                    activeRoles: aro,
                })

                var selectedRoleIds = aro.map(item => item.id);
                this.setState({
                    selectedRoleIds,
                })
            } else {
                this.setState({
                    activeRoles: '',
                    selectedRoleIds: '',
                })
            }
            }
        });

        var readers = this.props.employees;
        var optionsReaders = [];
        readers.map((r, index) => {
            optionsReaders.push({
                label: r.employeeName,
                value: r.id,
                
            })
            this.setState({
                optionsReaders,
            })
        })

        var projects = this.props.projects;
        var optionsProjects = [];
        projects.map((p, index) => {
            optionsProjects.push({
                value: p.id,
                label: p.projectName
            })
            this.setState({
                optionsProjects,
            })
        })

        var roles = this.props.activeRoomRolesList;
        this.setState({
            roles,
        })
    }


    componentWillUnmount() {
        var { room, firebase } = this.props;
        var roomId = room.id;
        firebase.room(roomId)
        .off()
    }

    // FILTER PROJECT LIST

    _onChangeSearch = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    // ADD READER TO ROOM

    _addOptionReader = (option) => {
        var readers = this.state.selectedReaders;
        readers.push({
            label: option.label,
            value: option.value,
        })
        this.setState({
            selectedReaders: readers,
        })
    }

    _addReader = (item) => {
        var timeAdded = moment().format('MM/DD/YY hh:mm a')
        var uid = this.state.uid;
        var name = item.label;
        var employeeId = item.value;
        var roomId = this.state.roomId;

        if (this.state.activeReaders !== '') {
            var readers = this.state.activeReaders;
            var readerIds = readers.map(r => r.employeeId);

            if (readerIds.includes(employeeId)) {
                console.log('Already added')
            } else {
                this.props.firebase.newRoomReader(uid, name, employeeId, roomId, timeAdded);
            }
        } else {
            this.props.firebase.newRoomReader(uid, name, employeeId, roomId, timeAdded);
        }
        
    }

    // REMOVE EMPLOYEE FROM ROOM 

    _removeReader = (item) => {
        var uid = this.state.uid;
        var name = item.name;
        var employeeId = item.employeeId;
        var roomReaderId = item.id;
        var timeRemoved = moment().format('MM/DD/YY hh:mm a');
        var roomId = this.state.roomId;
        this.props.firebase.roomReader(roomReaderId)
        .once('value', snapshot => {
            var reader = snapshot.val();
            var timeAdded = reader.timeAdded;

            this.props.firebase.archiveRoomReader(uid, name, employeeId, roomId, timeAdded, timeRemoved);

            this.props.firebase.removeRoomReader(roomReaderId)
            .remove();
    
            this.props.firebase.removeRoomReaderRoom(roomId, roomReaderId)
            .remove();
        })
    }

    _removeReaderFromRoomModalDropdown = (item) => {
        var uid = this.state.uid;
        var name = item.label;
        var employeeId = item.value;
        var roomId = this.state.roomId; 
        this.props.firebase.roomReaders()
        .orderByChild('roomId')
        .equalTo((roomId))
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                var thisReader = l.filter(f => {
                    return f.employeeId === employeeId
                });

                var roomReaderId = thisReader[0].id;

                var timeRemoved = moment().format('MM/DD/YY hh:mm a');
                this.props.firebase.roomReader(roomReaderId)
                .once('value', snapshot => {
                    var reader = snapshot.val();
                    var timeAdded = reader.timeAdded;

                    this.props.firebase.archiveRoomReader(uid, name, employeeId, roomId, timeAdded, timeRemoved);

                    this.props.firebase.removeRoomReader(roomReaderId)
                    .remove();
            
                    this.props.firebase.removeRoomReaderRoom(roomId, roomReaderId)
                    .remove();
                })
            }
        })
    }

    // ADD PROJECT TO ROOM

    _addProject = projectSelect => {
        var timeAdded = moment().format('MM/DD/YY hh:mm a')
        var uid = this.state.uid;
        var name = projectSelect.label;
        var projectId = projectSelect.value;
        var roomId = this.state.roomId;
        var projects = this.state.activeProjects;
        var projectIds = projects ? projects.map(p => p.roomProjectId) : '';

        if (projectIds) {
            if (projectIds.includes(projectId)) {
                console.log('Already added')
            } else {
                this.props.firebase.newRoomProject(uid, name, projectId, roomId, timeAdded);
            }
        } else {
            this.props.firebase.newRoomProject(uid, name, projectId, roomId, timeAdded);
        }

        this.props.firebase.projectSelect(projectId)
        .update({
            isActive: true,
        });

        this.props.firebase.userProjectAttributes(uid, projectId)
        .update({
            isActive: true,
        })
    };

    // REMOVE PROJECT FROM ROOM VIA DROPDOWN

    _handleRemoveProjectFromDropdown = item => {
        var timeRemoved = moment().format('MM/DD/YY hh:mm a');
        var uid = this.state.uid;
        var name = item.label;
        var projectId = item.value;
        var roomId = this.state.roomId;
        this.props.firebase._removeProjectFromRoomProjectModalDropDown(uid, name, projectId, roomId, timeRemoved);
    }

    _expandUserDropdown = () => {
        this.setState({
            userDropdown: !this.state.userDropdown,
            projectDropdown: false,
            roleDropdown: false,
        })
    
    }

    _expandProjectDropdown = () => {
        this.setState({
            projectDropdown: !this.state.projectDropdown,
            userDropdown: false,
            roleDropdown: false,
        })
       
    }

    _closeProjectDropdown = () => {
        this.setState({
            projectDropdown: false,
            projectSearch: '',
        })
    }

    _closeUserDropdown = () => {
        this.setState({
            userDropdown: false,
            userSearch: '',
        })
    }

    _expandRoleDropdown = () => {
        this.setState({
            roleDropdown: !this.state.roleDropdown,
            projectDropdown: false,
            userDropdown: false,
        })
    }

    render() {

        const { activeReaders, activeProjects, projectDropdown, userDropdown } = this.state;

        return (
            <div>
                <div className="modal-section">
                    <div className="select-arrow">
                        <img src={userDropdown ? arrowdown : addplususers} className={userDropdown ? "up-arrow" : "down-arrow-users"} alt="Expand" onClick={this._expandUserDropdown.bind(this)} />
                        {
                        this.state.userDropdown ?
                        <CustomDropdown 
                            onMouseLeave={this._closeUserDropdown} 
                            list={this.state.optionsReaders} 
                            deselectFunction={this._removeReaderFromRoomModalDropdown}
                            selectFunction={this._addReader}
                            selectedItems={this.state.selectedReaderIds}
                            dropdownMessage="Select or search employees"
                            inputName="employeeSearch"
                            searchFilter={this.state.employeeSearch}
                            _onChangeSearch={this._onChangeSearch}
                        />
                        :
                        <div />
                        }
                    </div>
                    <div className="modal-section-label">Active Readers</div>
                    
                    <div className="select-custom-multi">
                        <div className="modal-room-readers-list">
                            { activeReaders !== '' ?
                                activeReaders.map((item, index) => (
                                    <div key={index} className="reader-container">
                                        <div className="reader-layout">
                                            <div className="reader-name">{item.name}</div>
                                            <button className="remove-btn" onClick={this._removeReader.bind(this, item)}>
                                                <img src={remove} className="x-icon" alt="Remove" />
                                            </button>
                                        </div>
                                    </div>
                                ))
                                :
                                <div>No employees active</div>
                            }
                        </div>
                    </div>
                </div>
                <div className="modal-section">
                    <div className="select-arrow">
                        <img src={projectDropdown ? arrowdown : addproject} className={projectDropdown ? "up-arrow" : "down-arrow-project"} alt="Expand" onClick={this._expandProjectDropdown.bind(this)} />
                        {
                        this.state.projectDropdown ?
                        <CustomDropdown 
                            onMouseLeave={this._closeProjectDropdown} 
                            list={this.state.optionsProjects} 
                            deselectFunction={this._handleRemoveProjectFromDropdown}
                            selectFunction={this._addProject}
                            selectedItems={this.state.selectedProjectIds}
                            dropdownMessage="Select or search projects"
                            inputName="projectSearch"
                            searchFilter={this.state.projectSearch}
                            _onChangeSearch={this._onChangeSearch}
                        />

                            :
                            <div />
                            }
                            </div>
                            <div className="modal-section-label">Active Projects</div>

                            <div className="modal-project-list">
                                {
                                    activeProjects.length > 0 ?
                                    activeProjects.map((item, index) => (
                                        <div key={index} className="project-container">
                                            <div className="proj-modal-header">
                                                <div className="modal-project-title">{item.name}</div>
                                                <ModalProjectRoleDropdown roomId={this.state.roomId} uid={this.state.uid} firebase={this.props.firebase} activeRoles={this.state.activeRoles} roles={this.state.roles} item={item} userDropdown={this.state.userDropdown} projectDropdown={this.state.projectDropdown} />
                                            </div>
                                            <div className="proj-modal-body">
                                                <ProjectRolesList activeRoles={this.state.activeRoles} item={item} />
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <div />
                                }
                            </div>
                        </div>
                    </div>
        )
    }
}

const ProjectRolesList = ({activeRoles, item}) => {


    var roleArray = activeRoles ? activeRoles.filter(ar => { return ar.projectId === item.projectId }) : '';
    
    if (roleArray) {
        return (
        roleArray.map((r, index) => (
            <div key={index} className="reader-container">
                    <div className="reader-name">{r.roleName}</div>
            </div>
        ))
        )
    }
    else {
        return (
            <div>No roles active</div>
        )
    }
} 