import React, { Component } from 'react';

export default class RoomActiveCount extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pLength: '',
            rLength: '',
        }
    }
 
    componentDidMount() {

        var room = this.props.room;
        var roomId = room.id;
        var firebase = this.props.firebase;

        firebase.roomAttributes(roomId)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var ap = o.activeProjects;
                var ar = o.activeRoles;
                if (ap) {
                    var pl = Object.keys(ap).map(key => ({
                        ...ap[key],
                        id: key,
                    }))
                    this.setState({
                        pLength: pl.length
                    })
                } else {
                    this.setState({
                        pLength: 0,
                    })
                }
                if (ar) {
                    var rl = Object.keys(ar).map(key => ({
                        ...ar[key],
                        id: key
                    }))
                    this.setState({
                        rLength: rl.length
                    })
                } else {
                    this.setState({
                        rLength: 0,
                    })
                };
            } else {
                this.setState({
                    rLength: 0,
                    pLength: 0,
                })
            }
        })
    }

    componentWillUnmount() {
        var room = this.props.room;
        var roomId = room.id;
        this.props.firebase.roomAttributes(roomId)
        .off();
    }

    render() {

        const { rLength, pLength } = this.state;

        return (
            <div>
                <div className="role-count">
                    {pLength === 1 ? <div className="role-count-text">{pLength} active project,  </div> : <div className="role-count-text">{pLength} active projects,  </div>} 
                    {rLength === 1 ? <div className="role-count-text">{rLength} active role</div> : <div className="role-count-text">{rLength} active roles</div>}
                </div>
            </div>
        )
    }
}