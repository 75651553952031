import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { CSSTransitionGroup } from 'react-transition-group';
import * as ROUTES from '../../constants/routes';

import ModalContainer from '../Modals/index.js';
import ScheduleModal from '../Modals/ScheduleModal/ScheduleLayout';
import RoomModalBody from '../Modals/RoomModal/RoomModalBody';
import NoteModalBody from '../Modals/NotesModal/NoteModalBody';
import NewSessionBody from '../Modals/NewSessionModal/NewSessionBody';
import TagModalBody from '../Modals/NoteTags/TagModalBody';
import ScheduleModalBody from '../Modals/ScheduleModal/ScheduleModalBody';
import SessionDetailsBody from '../Modals/SessionDetails/SessionDetailsBody';
import { CheckInCard } from './CheckInCard';
import CastingSessionsList from './CastingSessionsList';
import moment from 'moment';
import NavModal from '../Modals/NewSessionModal/NavModal/NavModal';
import QuickMenuComponent from '../QuickMenu/QuickMenuComponent';
import { showDeviceOwnerSelect } from '../PushNotifications/push-notifications';
import OnDeck from './CheckIns/OnDeck';
import FilterDropdown from '../Common/FilterDropdown';
import QuickSessionModal from '../Modals/QuickSession/QuickSessionModal';


import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { withAuthorization, withAuthentication } from '../Session';

import './home.css';
import '../transitions.css';
import arrowdown from '../../assets/arrowdown.png';
import tagCountIcon from '../../assets/tag.png';
import noteCountIcon from '../../assets/speech-bubble.png';


var timer = null;

const HomePage = () => (
  <div>
    <HomePageForm />
  </div>
)

class HomePageFormBase extends Component { 
  constructor(props) {
    super(props);

    this.state = {
      authUser: null,
      loading: true,
      alerts: [],
      sessions: [],
      checkIns: [],
      inSessions: [],
      checkInsInSessions: [],
      completedCheckIns: [],
      allCompletedCheckIns: [],
      newRoleName: '',
      roleNames: [],
      collapse: true,
      dropdownOpen: false,
      activeTab: 'waiting',
      sessionId: '',
      sessionRedirect: false,
      myId: '',
      uid: '',
      myEmail: '',
      employees: [],

      projects: [],
      roomProjectName: '',
      roomProjectId: '',
      roomProjectActive: 0,
      roomProject: '',
      roomProjects: [],
      listedRoomProjects: [],
      timeProjectAdded: '',
      timeProjectRemoved: '',

      addedRoomProjectRoles: [],

      roles: [],
      roomProjectRoles: [],
      activeRolesList: [],
      activeRoomRolesList: [],
            
      viewText: 'Rooms',
      collapseText: 'Hide',
      sessionModal: false,

      checkModal: false,
      completeModal: false,

      rooms: [],
      roomOptions: [],
      openRooms: [],
      nextAvailableRoom: '',
      roomToAssign: '',
      roomModal: false,
      roomName: '',
      roomId: '',
      roomReaderName: '',
      roomReaderId: '',
      timeReaderAdded: '',
      timeReaderRemoved: '',
      roomReaderActive: 0,
      roomReaders: [],

      listedRoomReaders: '',

      filteredCheckIns: '',
      filteredChecks: '',
      filter: '',
      filterOne: '',

      todayAvg: '',
      weekAvg: '',
      monthAvg: '',
      allAvg: '',

      dateFilter: 'today',

      assignNextActive: false,
      assignNextId: '',

      activeEmployee: 'None',

      time: '',
      day: '',
      sessionUserName: '',
      sessionKey: '',

      modal: false,
      modalRoom: '',
      modalTalent: '',

      editGreeting: false,
      greeting: '',
      newGreeting: '',

      roomToAssignRole: '',
      highlightedRoom: null,
      rightSideBar: true,
      quickMenu: '',
      employeeChange: false,

      rightSideBarClose: false,
      checkInToDrop: '',
      
    };
    this.tabToggle = this.tabToggle.bind(this);
  };

  // *** MODALS *** //

  _openRoomModal = (room) => {
    this.setState({
      modalRoom: room,
      modalType: 'room',
    })
    this._showModal();
  };

  _openNotesModal = (item) => {
    this.setState({
      modalType: 'notes',
      modalTalent: item,
    })
    this._showModal();
  }

  _openSessionModal = (item) => {
    this.setState({
      modalType: 'sessiondetails',
      modalSession: item,
    })
    this._showModal();
  };

  _openTagsModal = () => {
    this.setState({
      modalType: 'tags',
    })
    this._showModal();
  }

  _openScheduleModal = () => {
    this.setState({
      modalType: 'schedule',
    })
    this._showModal();
  }

  _openNewSessionModal = () => {
    this.setState({
      modalType: 'newsession',
    })
    this._showModal();
  };

  _openQuickSessionModal = () => {
    this.setState({
      modalType: 'quicksession'
    })
    this._showModal();
  };

  _editSessionModal = (item) => {
    this.setState({
      modalType: 'editsession',
      modalSession: item,
    });
    this._showModal();
  };

  _closeModal = () => {
    this.setState({
      modal: false,
      modalType: '',
    })
  }

  _showModal = () => {
    this.setState({
      modal: true,
    })
  }

  _roomModalBody = (modalRoom) => {
    const { employees, projects} = this.state;
    return <RoomModalBody activeRoomRolesList={this.state.activeRoomRolesList} room={modalRoom} uid={this.state.uid} employees={employees} projects={projects} firebase={this.props.firebase} />
  }

  _notesModalBody = (modalTalent) => {
    return <NoteModalBody talent={modalTalent} uid={this.state.uid} firebase={this.props.firebase} />
  }

  _tagsModalBody = () => {
    const { uid } = this.state;
    return <TagModalBody uid={uid} firebase={this.props.firebase} />
  } 

  _newSessionBody = () => {
    const { uid } = this.state;
    return <NewSessionBody uid={uid} firebase={this.props.firebase} _closeModal={this._closeModal} />
  }

  _quickSessionBody = () => {
    const { uid } = this.state;
    return <QuickSessionModal uid={uid} firebase={this.props.firebase} employees={this.state.employees} rooms={this.state.rooms} _closeModal={this._closeModal} />
  }

  _editSessionBody = (modalSession) => {
    const { uid } = this.state;
    return (<NewSessionBody uid={uid} firebase={this.props.firebase} _closeModal={this._closeModal} editSession={true} session={modalSession} />)
  }

  _sessionDetailsBody = (modalSession) => {
    const { uid } = this.state;
    return <SessionDetailsBody uid={uid} firebase={this.props.firebase} _closeModal={this._closeModal} session={modalSession} />

  }

  _scheduleModalBody = () => {
    const { uid } = this.state;
    return <ScheduleModalBody uid={uid} firebase={this.props.firebase} />
  }


  currentModalState = () => {
    var { modalType, modalRoom, modalTalent, modalSession } = this.state;
    
    if (modalType === 'room') {
      return (
        <ModalContainer 
        modalHeader={modalRoom.roomName}
        modalBody={this._roomModalBody(modalRoom)}
        close={this._closeModal}
      />
      )
    } else if (modalType === 'notes') {
      return (
        <ModalContainer
        modalHeader={modalTalent.checkInName}
        modalBody={this._notesModalBody(modalTalent)}
        close={this._closeModal}
        />
      )
    } else if (modalType === 'tags') {
      return (
        <ModalContainer 
        modalHeader="My Tags"
        modalBody={this._tagsModalBody()}
        close={this._closeModal}
        />
      )
    } else if (modalType === 'schedule') {
      return (
        <ScheduleModal 
          modalHeader="Appointment Schedule"
          modalBody={this._scheduleModalBody()}
          close={this._closeModal}
          modal={this.state.modal}
        />
      )
    } else if (modalType === 'quicksession') {
      return (
        <ModalContainer 
          close={this._closeModal}
          modal={this.state.modal}
          modalBody={this._quickSessionBody()}
          modalHeader="Quick Setup"
          uid={this.state.uid} firebase={this.props.firebase}
        />
      ) 
    } else if (modalType === 'newsession') {
      return (
        <NavModal 
          _closeModal={this._closeModal}
          modal={this.state.modal}
          uid={this.state.uid} firebase={this.props.firebase}
        />
      );
    } else if (modalType === 'editsession') {
      return (
        <NavModal 
          _closeModal={this._closeModal}
          modal={this.state.modal}
          uid={this.state.uid} firebase={this.props.firebase} editSession={true} session={modalSession}
        />
      )
    } else if (modalType === 'sessiondetails') {
      return (
        <ModalContainer 
          modalHeader="Session Details"
          modalBody={this._sessionDetailsBody(modalSession)}
          close={this._closeModal}
          modal={this.state.modal}
        />
      )
    }
  };

  // *** FILTERS *** //

  handleFilterChange = event => {
    this.setState({
      filter: event.target.value
    });
  };

  handleCheckSearchChange = event => {
    this.setState({
      filterOne: event.target.value
    });
  };

  timeConvert(n){ 
    var num = n;
    if (num < 0 || num > 300) {
      num = 300;
    }
    if (num > 60 && num < 300) {
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + "h " + rminutes + "m";
    } else if (num === 300) {
      return '6h+';
    } else {
      return num + "m";
    }
  };

  diffConvert(n) {
    var num = n;
    var seconds = (num / 1000);
    var minutes = seconds / 60;
    if (minutes > 60) {
      var hours = minutes / 60;
      var rhours = Math.floor(hours);
      return (<div className="check-in-card-time green-text"><div className="far-right">{rhours + "h"}</div></div>);
    } else if (minutes < -60) {
      var nhours = minutes * -1 / 60;
      var nrhours = Math.floor(nhours);
      return (<div className="check-in-card-time red-text"><div className="far-right">{nrhours + "h"}</div></div>);
    } else if (minutes < 0 && minutes > -59) {
      return (<div className="check-in-card-time red-text"><div className="far-right">{minutes * -1 + "m"}</div></div>);
    } else if (minutes > 0 && minutes < 59) {
      return (<div className="check-in-card-time green-text"><div className="far-right">{minutes + "m"}</div></div>);
    } else {
      return '';
  }}

  _handleTimeDiff = (a, b) => {
    var diff = moment(a, 'h:mma').diff(moment(b, 'h:mma'));
    var display = this.diffConvert(diff);
    return display;
  }

  _findAverage = (array) => {
    var todayAvgArray = array.map(t => Number(t.totalTime));
    var usableArray = todayAvgArray.filter(i => {
      return i < 300 && i > 0;
    })
    var total = 0;
    for(var i = 0; i < usableArray.length; i++) {
      total += usableArray[i];
    }
    var avgNum = total / usableArray.length;
    if (avgNum > 60) {
    var hours = (avgNum / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
      return rhours + "h " + rminutes.toFixed(2) + "m";
    } else {
      return avgNum.toFixed(2) + "m";
    } 
  }

  _filterByToday = () => {
    var checks = this.state.allCompletedCheckIns;
    var daystamp = moment().format('MM/DD/YYYY');

    var todayFilter = checks.filter(item => {
      return item.checkInTimeDay === daystamp;
    });

    var avg = this._findAverage(todayFilter); 

    if (avg !== 'NaNm') {
      this.setState({
        todayAvg: avg,
      })
    } else {
      this.setState({
        todayAvg: 'No auditions today',
      })
    }
   
    this.setState({
      completedCheckIns: todayFilter,
      dateFilter: 'today',
    })
  }

  _filterByWeek = () => {
    var checks = this.state.allCompletedCheckIns;
    var daystamp = moment().format('MM/DD/YYYY');
    var weekFilter = checks.filter(item => {
      return moment(item.checkInTimeDay).isAfter(moment(daystamp).subtract(1, 'week'));
    }) 

    var avg = this._findAverage(weekFilter); 
    
    if (avg !== 'NaNm') {
      this.setState({
        weekAvg: avg,
      })
    } else {
      this.setState({
        weekAvg: 'No auditions this week',
      })
    }

    this.setState({
      completedCheckIns: weekFilter,
      dateFilter: 'week',
    })
  }

  _filterByMonth = () => {
    var checks = this.state.allCompletedCheckIns;
    var daystamp = moment().format('MM/DD/YYYY');
    var monthFilter = checks.filter(item => {
      return moment(item.checkInTimeDay).isAfter(moment(daystamp).subtract(1, 'month'));
    }) 

    var avg = this._findAverage(monthFilter);

    if (avg !== 'NaNm') {
      this.setState({
        monthAvg: avg,
      })
    } else {
      this.setState({
        monthAvg: 'No auditions this month',
      })
    }

    this.setState({
      completedCheckIns: monthFilter,
      dateFilter: 'month',
    })
  }

  _filterByAll = () => {
    var avg = this._findAverage(this.state.allCompletedCheckIns); 
    
    if (avg !== 'NaNm') {
      this.setState({
        allAvg: avg,
      })
    } else {
      this.setState({
        allAvg: 'No auditions yet',
      })
    }

    this.setState({
      completedCheckIns: this.state.allCompletedCheckIns,
      dateFilter: 'all',
    })
  }


  // *** MODAL NOTES *** //

  updateNote = (checkIn, event) => {
    const checkInId = this.state.checkModalId;
    const timestamp = moment().format('hh:mm a');
    const daystamp = moment().format('MM/DD');

    this.props.firebase.notes()
    .orderByChild('checkInId')
    .equalTo((checkInId))
    .once('value', snapshot => {
      if (snapshot.exists()) {
        const noteObject = snapshot.val();
        const note = Object.keys(noteObject).map(key => ({
          ...noteObject[key],
          id: key,
        }));
        const noteId = note[0].id;
        this.props.firebase.updateNote(noteId)
        .update({
          note: this.state.noteBodyValue,
          timestamp: timestamp,
          daystamp: daystamp,
        })
  }})}

  saveNotes = (c, event) => {
    const userId = this.state.myId;
    const checkInName = this.state.checkModalName;
    const projectId = this.state.checkModalProjectId;
    const roleId = this.state.checkModalRoleId;
    const checkInId = this.state.checkModalId;
    const timestamp = moment().format('hh:mm a');
    const daystamp = moment().format('MM/DD');
    const note = this.state.noteBodyValue;
    const noteBy = this.state.activeEmployee;

    this.props.firebase.addNote(userId, checkInName, projectId, roleId, checkInId, timestamp, daystamp, note, noteBy);
    
    this.setState({
      noteBodyValue: ''
    })
  
  };

  // ** SELECTS ** //

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // ** GREETING FUNCTIONS ** // 

  _openGreetingEdit = () => {
    this.setState({
      editGreeting: true,
    })
  }

  _updateGreeting = () => {
    var uid = this.state.uid;

    this.props.firebase.user(uid)
    .update({
      greeting: this.state.newGreeting,
    });

    this.setState({
      editGreeting: false,
    })
  };

  _closeGreetingEdit = () => {
    this.setState({
      editGreeting: false,
    })
  }

  // ** ASSIGN TALENT ** //

  assignNext = (checkIn, event) => {
    if (this.state.assignNextActive === false) {
      this.setState({
        assignNextActive: true,
        assignNextId: checkIn.id,
      })
    } if (this.state.assignNextActive === true) {
      if (this.state.assignNextId === checkIn.id) {
        this.setState({
          assignNextActive: false,
          assignNextId: '',
        })
      } else {
        this.setState({
          assignNextId: checkIn.id,
        })
      }
    }
  };

  completeSessionClick = (checkInsInSession, event) => {
    var userId = checkInsInSession.id;
    var roomId = checkInsInSession.currentRoom;
    const timestamp = moment().format('h:mma MM/DD')
    this.props.firebase.checkInSession(userId)
    .update({
      timeWrapped: timestamp,
      status: 'Complete',
      currentRoom: '',
      wrapped: true,
    });
    this.props.firebase.room(roomId)
      .update({
        activeCheckIn: false,
        checkInId: '',
        checkInName: '',
        checkInRepCompany: '',
        checkInRole: '',
        sessionMode: '',
        sessionId: '',
        activeSession: '',
        timeCalled: timestamp,
      })
  };

  reCheckIn = (completedCheckIn, event) => {
    var userId = completedCheckIn.id;
    const timestamp = moment().format('h:mma MM/DD')
    this.props.firebase.checkInSession(userId)
    .update({
      timeCheckedIn: timestamp,
      status: 'Waiting',
      currentRoom: '',
      wrapped: false,
    });
  };

  _prepCountToComplete = (room) => {
    var myId = this.state.myId; 
    var userId = room.checkInId;

    this.props.firebase.talentNotes(myId, userId)
    .once('value', snapshot => {
      if (snapshot.exists()) {
        var o = snapshot.val();
        var l = Object.keys(o).map(key => ({
          ...o[key],
          id: key,
        }))
        this.setState({
          noteCount: l.length,
        })
      } else {
        this.setState({
          noteCount: '0',
        })
      }
    })

    this.props.firebase.talentTags(userId)
    .once('value', snapshot => {
      if (snapshot.exists()) {
        var o = snapshot.val();
        var l = Object.keys(o).map(key => ({
          ...o[key],
          id: key,
        }))
        this.setState({
          tagCount: l.length,
        })
      } else {
        this.setState({
          tagCount: '0',
        })
      }}
    )
    clearTimeout(timer);
    timer = setTimeout(() => {
      this.completeFromSessionClick(room);
    }, 200);
    clearTimeout(timer);
  }

  completeFromSessionClick = (room) => {
    var myId = this.state.myId; 
    var roomId = room.id;
    var userId = room.checkInId;
    const timestamp = moment().format('hh:mma');

    this.props.firebase.listInSession()
    .orderByChild('checkInQId')
    .equalTo((userId))
    .once('value', snapshot => {
      const sessionUserObject = snapshot.val();
      const sessionUser = Object.keys(sessionUserObject).map(key => ({
        ...sessionUserObject[key],
        id: key,
      })); 
      var checkInId = sessionUser[0].id;
      var checkInName = sessionUser[0].checkInName;
      var checkInProject = sessionUser[0].checkInProject;
      var checkInRole = sessionUser[0].checkInRole;
      var checkInRepCompany = sessionUser[0].checkInRepCompany;
      var checkInRoom = room.id;
      var checkInQId = sessionUser[0].checkInQId;
      var projectId = sessionUser[0].projectId;
      var roleId = sessionUser[0].roleId;
      var checkInTime = sessionUser[0].checkInTime;
      var checkInTimeDay = sessionUser[0].checkInTimeDay;
      var callInTime = sessionUser[0].callInTime;
      var waitTime = sessionUser[0].waitTime;
      var completedTime = timestamp;
      var accountId = sessionUser[0].accountId;
      var auditionLength = moment(completedTime,"hh:mma").diff(moment(callInTime,"hh:mma"));
      var totalLength = moment(completedTime,"hh:mma").diff(moment(checkInTime,"hh:mma"));
      var auditionTime = (auditionLength / 1000) / 60;
      var totalTime = (totalLength / 1000) / 60;
      var noteCount = this.state.noteCount;
      var tagCount = this.state.tagCount;

      this.props.firebase.completed(checkInName, checkInProject, checkInRole, checkInRepCompany, checkInRoom, checkInQId, projectId, roleId, checkInTime, checkInTimeDay, callInTime, completedTime, waitTime, auditionTime, totalTime, accountId, noteCount, tagCount);
      
      this.props.firebase.removeInSession(checkInId)
      .remove();

      this.props.firebase.addTalentReaders(roomId, checkInQId);

    })

    this.props.firebase.clearUserRoom(myId, roomId)
    .update({
      activeCheckIn: false,
      checkInId: '',
      checkInName: '',
      checkInProject: '',
      checkInRole: '',
      timeCalled: '',
    });

    this.props.firebase.room(roomId)
      .update({
        activeCheckIn: false,
        checkInId: '',
        checkInName: '',
        checkInProject: '',
        checkInRole: '',
        timeCalled: '',
      });

  };

  assignFunction = (options, roomId) => {
    var talent = options[0];
    const timestamp = moment().format('hh:mma');

    const talentId = talent.id;
    const checkInName = talent.checkInName;
    const checkInProject = talent.checkInProject;
    const checkInRole = talent.checkInRole;
    const checkInRepCompany = talent.checkInRepCompany;
    const checkInRoom = roomId;
    const checkInTime = talent.checkInTime;
    const checkInTimeDay = talent.checkInDay;
    const callInTime = timestamp
    const projectId = talent.projectId;
    const roleId = talent.roleId;
    const accountId = talent.accountId;
    const waitLength = moment(callInTime,"hh:mma").diff(moment(checkInTime,"hh:mma"));
    const waitTime = (waitLength / 1000) / 60;
          
    this.props.firebase.inSession(checkInName, checkInProject, checkInRole, checkInRepCompany, checkInRoom, talentId, checkInTime, checkInTimeDay, callInTime, projectId, roleId, waitTime, accountId);

    this.props.firebase.removeCheckInQ(talentId)
    .remove();

    this.props.firebase.userCheckinSelect(accountId, talentId).remove();

    this.props.firebase.assignRoom(roomId)
      .update({
        activeCheckIn: true,
        checkInId: talentId,
        checkInName: checkInName,
        checkInProject: checkInProject,
        checkInRole: checkInRole,
        timeCalled: timestamp,
      })

    this.props.firebase.assignUserRoom(this.state.myId, roomId)
      .update({
        activeCheckIn: true,
        checkInId: talentId,
        checkInName: checkInName,
        checkInProject: checkInProject,
        checkInRole: checkInRole,
        timeCalled: timestamp,
      })
  };

  assignFromSessionClick = (room, event) => {

    const { checkIns } = this.state;

    var roomId = room.id;

    this.props.firebase.roomActiveRoles(roomId)
    .once('value', snapshot => {
      if (snapshot.exists()) {
        const obj = snapshot.val();
        const list = Object.keys(obj).map(key => ({
          ...obj[key],
          id: key,
        }));

        var roleArray = list.map(role => role.id);

        var filteredByRoles = checkIns.filter(f => {
          return roleArray.includes(f.roleId)
        })
        if (filteredByRoles.length > 0) {
          var filteredByDibs = filteredByRoles.filter(fil => {
            return fil.dibs === roomId || fil.dibs === ''
          }) 
          if (filteredByDibs.length > 0) {
            var filteredByVIP = filteredByDibs.filter(filvip => {
              return filvip.id === room.vip 
            })
            if (filteredByVIP.length > 0) {
              this.assignFunction(filteredByVIP, roomId)
            } else {
              this.assignFunction(filteredByDibs, roomId)
            }
          } else {
            console.log('No one to assign');
          }
        } else {
          console.log('No roles match');
        }
      }
    })
  }

  _assignRoleToRoom = (c, room) => {

    var uid = this.state.uid;
    var name = c.checkInProject;
    var projectId = c.projectId;
    var roomId = room.value;
    var timeAdded = moment().format('h:mma MM/DD');

    var roleId = c.roleId;
    var roleName = c.checkInRole; 

    this.props.firebase.roomActiveProjects(roomId)
    .once('value', snapshot => {
      if (snapshot.exists()) {
        var o = snapshot.val();
        var l = Object.keys(o).map(key => ({
          ...o[key],
          id: key,
        }))
        var array = l.map(l => l.projectId);
        if (array.includes(projectId)) {
          this.props.firebase.newRoomRole(roleId, projectId, roleName, roomId, uid);
        } else {
          this.props.firebase.newRoomProject(uid, name, projectId, roomId, timeAdded);
          this.props.firebase.newRoomRole(roleId, projectId, roleName, roomId, uid);
        }
      } else {
        this.props.firebase.newRoomProject(uid, name, projectId, roomId, timeAdded);
        this.props.firebase.newRoomRole(roleId, projectId, roleName, roomId, uid);
      }
    })
  }

  // ** ALERTS ** //

  deleteAlert = (item) => {
    const myId = this.state.myId;
    const alertId = item.id;
    this.props.firebase.removeAlert(myId, alertId)
    .remove()
  }
  
  tabToggle(tab) {
    this.setState({
      activeTab: tab
    })
  };

  _tabCheckedIn = () => {
    this.setState({
        activeTab: 'waiting',
    })
  }

  _tabOnDeck = () => {
    this.setState({
      activeTab: 'deck'
    })
  };

  _tabComplete = () => {
    this.setState({
        activeTab: 'complete',
    })
    var checks = this.state.allCompletedCheckIns;
    var daystamp = moment().format('MM/DD/YYYY');

    var todayFilter = checks.filter(item => {
      return item.checkInTimeDay === daystamp;
    });

    var avg = this._findAverage(todayFilter); 

    if (avg !== 'NaNm') {
      this.setState({
        todayAvg: avg,
      })
    } else {
      this.setState({
        todayAvg: 'No auditions today',
      })
    }
   
    this.setState({
      completedCheckIns: todayFilter,
      dateFilter: 'today',
    })
  }

  _tabChat = () => {
    this.setState({
      activeTab: 'chat',
    })
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }


  onChangeInputs = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  

  componentDidMount() {

    const time = moment().format('hh:mma');
    const day = moment().format('MM/DD/YYYY');

    var devicePush = localStorage.getItem('ani-check-in-notification-token');
    var deviceOwner = localStorage.getItem('ani-push-device-owner');

    var deviceOpt = devicePush ? true : false;
    var deviceHasOwner = deviceOwner ? true : false;

    this.listener = this.props.firebase.auth.onAuthStateChanged(
      authUser => {
        authUser 
        ? 
        this.setState({
            myId: authUser.uid, 
            uid: authUser.uid,
            myEmail: authUser.email,
        })
        : this.setState({ authUser: null });

        if (authUser) {

        }

        authUser ? 
        this.props.firebase.tempUser(authUser.uid)
        .once('value', snapshot => {
          if (snapshot.exists()) {
            this.setState({
              loading: true
            })
          } else {
            this.setState({
            loading: false,
          })
        }})
        :
        this.setState({loading: true});

        if (authUser) {
        var createdBy = this.state.myId;
        var uid = this.state.myId;

        this.props.firebase.user(uid)
        .on('value', snapshot => {
          if (snapshot.exists()) {
            var user = snapshot.val();
            if (!user.groupRef) {
              var key = this.props.firebase.db.ref('user-group-ref/').push().key;
      
              this.props.firebase.db.ref('user-group-ref/' + authUser.uid)
              .update({
                  groupRef: key
              });

            }
            this.setState({
              greeting: user.greeting,
              newGreeting: user.greeting,
              talentSignUp: user.talentSignUp ? user.talentSignUp : false,
            })
          }
        })

        this.props.firebase.apiUser(uid)
        .once('value', snapshot => {
          var o = snapshot.val();
          this.setState({
            cid: o.company
          })
        });

        if (authUser) {
          var sessionState = localStorage.getItem( 'SessionState' ) || '';
          this.setState({
            sessionState: sessionState
          })
          if (sessionState === '') {
            const newSessionUserKey = this.props.firebase.sessionUser().push().key;
            this.props.firebase.newSessionUser(newSessionUserKey, createdBy, this.state.sessionUserName, this.state.sessionUserId, time, day);
            this.selectedState(newSessionUserKey);
          } else {
            const sessionKey = sessionState;
            this.props.firebase.sessionUserSelect(sessionKey)
            .on('value', snapshot => {
              if (snapshot.exists()) {
              const object = snapshot.val();
              this.setState({
                sessionUserName: object.sessionUserName,
                sessionUserId: object.sessionUserId,
              })
            }
          })
        }
        }

        this.props.firebase.userAppts(uid)
        .orderByChild('date')
        .equalTo(moment().format('MM/DD/YYYY'))
        .on('value', snapshot => {
          if (snapshot.exists()) {
            var o = snapshot.val();
            var l = Object.keys(o).map(key => ({
              ...o[key],
              id: key
            }))
            var currentTime = moment().format('HH');
            var timeAppts = l.filter((i, index) => {
              return Number(i.hr) >= currentTime; 
            })
            var projArray = timeAppts.map(i => i.projectId);
            var roleArray = timeAppts.map(i => i.roleId);

            var uProjects = projArray.filter( this.onlyUnique );
            var uRoles = roleArray.filter( this.onlyUnique );

            uProjects.map(p => {
              return this.props.firebase.projectSelect(p)
              .update({ isActive: true })
            })

            uProjects.map(p => {
              return this.props.firebase.userProjectAttributes(uid, p)
              .update({ isActive: true });
            });

            uRoles.map(r => {
              return this.props.firebase.roleUpdate(r)
              .update({ isActive: true });
            })

            uRoles.map(r => {
              return this.props.firebase.roleUpdate(r)
              .once('value', snapshot => {
                if (snapshot.exists()) {
                  var o = snapshot.val();
                  var projectId = o.projectId;
                  this.props.firebase.projectRoleUpdate(projectId, r)
                  .update({isActive: true})
                }
              })
            })
          }
        })

        this.props.firebase.userAlerts(createdBy)
        .on('value', snapshot => {
          if (snapshot.exists()) {
            const object = snapshot.val();

            const alerts = Object.keys(object).map(key => ({
              ...object[key],
              id: key,
            }));
            this.setState({
              alerts: alerts,
            })
          } else {
            this.setState({
              alerts: '',
            })
          }
        })

        this.props.firebase.userProjects(createdBy)
        .on('value', snapshot => {
          if (snapshot.exists()) {
            const projectsObject = snapshot.val();

            const projectsList = Object.keys(projectsObject).map(key => ({
              ...projectsObject[key],
              id: key,
            }));
            this.setState({
              projects: projectsList
            });
          } else {
            this.setState({
              projects: ''
            })
          };
        });

        this.props.firebase.userRooms(createdBy)
        .orderByChild('isActive')
        .equalTo(true)
        .on('value', snapshot => {
          if (snapshot.exists()) {
            const roomsObject = snapshot.val();
    
            const roomsList = Object.keys(roomsObject).map(key => ({
              ...roomsObject[key],
              id: key,
            }));

            const roomOptions = [''];

            roomsList.map(r => (
              roomOptions.push({
                value: r.id,
                label: r.roomName,
              })
            ))
            this.setState({
              rooms: roomsList,
              roomOptions: roomOptions,
            });
            } else {
              this.setState({
                rooms: ''
              })
            };
          });

          this.props.firebase.userEmployees(createdBy)
          .on('value', snapshot => {
            if (snapshot.exists()) {
              const employeesObject = snapshot.val();
  
              const employeesList = Object.keys(employeesObject).map(key => ({
                ...employeesObject[key],
                id: key,
              }));

              if (employeesList && !deviceHasOwner && deviceOpt) {
                this.setState({
                  showDeviceSelect: true,
                })
              };

              this.setState({
                employees: employeesList
              });
            } else {
              this.setState({
                employees: '',
              })
            };
          });

          
          //API for WAITING with If Clause for 0 CheckIns

          this.props.firebase.userCheckins(authUser.uid)
          .on('value', snapshot => {
            if (snapshot.exists()) {
            const checkInsObject = snapshot.val();
      
            const checkInsList = Object.keys(checkInsObject).map(key => ({
              ...checkInsObject[key],
              id: key,
            }));
            this.setState({
              checkIns: checkInsList,
              filteredChecks: checkInsList,
            });
            } else {
              this.setState({
                checkIns: '',
              })
            }
          });

          this.props.firebase.completedUserCheckins(authUser.uid)
          .on('value', snapshot => {
            if (snapshot.exists()) {
              var obj = snapshot.val();        
              var list = Object.keys(obj).map(key => ({
                ...obj[key],
                id: key,
              }));

              if (list) {
                
                this.setState({
                  completedCheckIns: list,
                  allCompletedCheckIns: list,
                  filteredCheckIns: list,
                });
                if (this.state.dateFilter === 'week') {
                    this._filterByWeek();
                  } else if (this.state.dateFilter === 'month') {
                    this._filterByMonth()
                  } else if (this.state.dateFilter === 'all') {
                    this._filterByAll()
                  } else {
                    this._filterByToday()
                  }
                  
                } else {
                  this.setState({
                    completedCheckIns: '',
                  })
                }

                
              }
            });
                
            //API for COMPLETE with If Clause for 0 CompletedSessions

            // *** FOR LISTING ACTIVE ROLES ON ROOM CARDS

            this.props.firebase.roomRoles()
            .orderByChild('uid')
            .equalTo((uid))
            .on('value', snapshot => {
              if (snapshot.exists()) {
                const activeRoomRolesObject = snapshot.val();

                const activeRoomRoles = Object.keys(activeRoomRolesObject).map(key => ({
                  ...activeRoomRolesObject[key],
                  id: key,
                }))
                this.setState({
                  activeRoomRolesList: activeRoomRoles
                });
              } else {
                return (
                  <div></div>
                )
              }
            });

            // *** FOR SEEING WHICH ROLES ARE ACTIVE BUT NOT ASSIGNED *** //

            this.props.firebase.roles()
              .orderByChild('createdBy')
              .equalTo((uid))
              .on('value', snapshot => {
                  if (snapshot.exists()) {
                      var o = snapshot.val();
                      var l = Object.keys(o).map(key => ({
                          ...o[key],
                          id: key,
                      }))
                      this.setState({
                        userActiveRoles: l,
                      })
                  } else {
                    this.setState({
                      userActiveRoles: ''
                    })
                  };
              })


            // *** FOR LISTING ACTIVE PROJECTS ON ROOM CARDS

            this.props.firebase.roomProjects()
            .orderByChild('uid')
            .equalTo((uid))
            .on('value', snapshot => {
            if (snapshot.exists()) {
              const listedRoomProjectsObject = snapshot.val();

              const listedRoomProjectsList = Object.keys(listedRoomProjectsObject).map(key => ({
                ...listedRoomProjectsObject[key],
                id: key,
              }));
              this.setState({
                listedRoomProjects: listedRoomProjectsList 
              })
            } else {
              this.setState({
                listedRoomProjects: '',
              })
            }
            });

            // *** FOR LISTING ACTIVE READERS ON ROOM CARDS

            this.props.firebase.roomReaders()
            .orderByChild('uid')
            .equalTo((uid))
            .on('value', snapshot => {
              if (snapshot.exists()) {
                const listedRoomReadersObject = snapshot.val();

                const listedRoomReadersList = Object.keys(listedRoomReadersObject).map(key => ({
                  ...listedRoomReadersObject[key],
                  id: key
                }));
                this.setState({
                  listedRoomReaders: listedRoomReadersList
                })
              } else {
                this.setState({
                  listedRoomReaders: '',
                })
              }
            });

            //The below If Clauses are associated with the CheckInsList, CheckInsInSessionsList, CompletedCheckInsList components below


            //API for SESSION with If Clause for 0 CheckInsInSessions

          }
        })
      };
      

  componentWillUnmount() {

    var createdBy = this.state.createdBy;
    var uid = this.state.createdBy;

    this.props.firebase.user(uid).off();
    this.props.firebase.userRooms(createdBy).off();
    this.props.firebase.userProjects(createdBy).off();
    this.props.firebase.userAlerts(createdBy).off();
    this.props.firebase.completedUserCheckins(createdBy).off();
    this.props.firebase.listInSession().off();
    this.props.firebase.userCheckins(createdBy).off();
    this.props.firebase.roomReaders().off();
    this.props.firebase.roomProjects().off();
    this.props.firebase.roomRoles().off();
    this.props.firebase.userEmployees(uid).off();
    this.props.firebase.notes().off();
    this.props.firebase.userAppts(uid).off();
  };

  // DRAG AND DROP

  

  _capRoleData = (i) => {
    this.setState({
      draggedRole: i,
      isDragging: true,
    })
  }

  _dropRoleData = (i) => {
    var uid = this.state.uid;

    this.setState({
      isDragging: false,
    })

    if (this.state.highlightedRoom) {

      var projectId = i.projectId; 
    
      this.props.firebase.projectSelect(projectId)
      .once('value', snapshot => {
        var o = snapshot.val();
        var name = o.projectName;
          
        var roomId = this.state.highlightedRoom.id;
        var timeAdded = moment().format('h:mma MM/DD');
        var roleId = i.id;
        var roleName = i.roleName;

        this.props.firebase.roomActiveProjects(roomId)
        .once('value', snapshot => {
          if (snapshot.exists()) {
            var o = snapshot.val();
            var l = Object.keys(o).map(key => ({
              ...o[key],
              id: key
            }))
            var array = l.map(l => l.projectId);
            if (array.includes(projectId)) {
              this.props.firebase.newRoomRole(roleId, projectId, roleName, roomId, uid);
            } else {
              this.props.firebase.newRoomProject(uid, name, projectId, roomId, timeAdded);
              this.props.firebase.newRoomRole(roleId, projectId, roleName, roomId, uid);
            }
          } else {
            this.props.firebase.newRoomProject(uid, name, projectId, roomId, timeAdded);
            this.props.firebase.newRoomRole(roleId, projectId, roleName, roomId, uid);
          }
        })
      })
    }
  }

  _highlightRoom = (room) => {
    if (this.state.highlightedRoom !== room) {
    this.setState({
      highlightedRoom: room,
    })
    } 
  }

  _clearHighlight = () => {
      this.setState({
        highlightedRoom: ''
      });
  };

  _highlightOver = () => {

  };

  _toggleRightSidebar = () => {
    this.setState({
      rightSideBar: !this.state.rightSideBar,
    })
  };

  _expandSessions = () => {
    this.setState({
      hasSessions: true
    });
  };

  _minimizeSessions = () => {
    this.setState({
      hasSessions: false,
    })
  }
  
  _openChangeEmployee = () => {
    this.setState({
      employeeChange: true,
    })
  };

  _closeChangeEmployee = () => {
    this.setState({
      employeeChange: false,
    })
  };

  _openAccountSettings = () => {
    this.setState({
      rightSideBarClose: true,
      rightSideBar: false,
    })
    

    timer = setTimeout(() => {
      this.props.history.push('/account')
    }, 200);
    return (() => clearTimeout(timer));
  };
  
  _setActiveUser = (employee) => {
    var sessionState = localStorage.getItem( 'SessionState' ) || '';

    var newSessionUserKey = this.props.firebase.sessionUser().push().key;

    this.props.firebase.sessionUserSelect(sessionState)
    .once('value', snapshot => {
      if (snapshot.exists()) {
        const object = snapshot.val();
        this.setState({
          sessionUserName: object.sessionUserName ? object.sessionUserName : '',
        })
      }
    });
    const time = moment().format('hh:mma');
    const day = moment().format('MM/DD/YYYY');
    const uid = this.state.uid;
    if (this.state.sessionUserName === '') {
      let sessionUserName = employee.employeeName;
      let sessionUserId = employee.id;

      this.setState({
        sessionUserName,
        sessionUserId,
      });

      this.props.firebase.newSessionUser(newSessionUserKey, uid, sessionUserName, sessionUserId, time, day);
    }
    if (this.state.sessionUserName !== '' && this.state.sessionUserName !== employee.employeeName) {
      let sessionUserName = employee.employeeName;
      let sessionUserId = employee.id;

      this.setState({
        sessionUserName,
        sessionUserId,
      });

      this.props.firebase.newSessionUser(newSessionUserKey, uid, sessionUserName, sessionUserId, time, day);
    }

    this.selectedState(newSessionUserKey);
    
    this.setState({
      employeeChange: false,
    })
  };

  selectedState = (newSessionUserKey) => {
    localStorage.setItem( 'SessionState', newSessionUserKey);
    this.setState({
      sessionState: newSessionUserKey,
    })
  };

  _setSectionLength = (length) => {
    this.setState({
      sessionLength: length
    })
  };

  _signOut = () => {
    this.props.firebase.doSignOut();
  };

  _showDeviceOwnerSelect = () => {
    return (
      <div className="show-device-message">Hello</div>
    )
  }

  _resetDevice = () => {
    this.setState({
      showDeviceSelect: false
    })
  };

  _showDeviceSelect = () => {
    console.log('true');
    this.setState({
      showDeviceSelect: true,
      editDevice: true,
    })
  }

  _deleteCheckIn = (item) => {
    this.props.firebase.db.ref('/user-check-ins/' + this.state.uid + '/' + item.id).remove();
  };

  _showDelete = (item) => {
      if (item.id !== this.state.checkInHoverId) {
      this.setState({
        checkInHoverId: item.id,
      })
    };
  };

  _removeDelete = () => {
    this.setState({
      checkInHoverId: '',
    })
  };

  _turnOnTalentSignUp = () => {
    console.log('turnOn');
    this.props.firebase.user(this.state.uid)
    .update({
      talentSignUp: true,
    })
  };

  _turnOffTalentSignUp = () => {
    console.log('turnOff');
    this.props.firebase.user(this.state.uid)
    .update({
      talentSignUp: false,
    })
  };

  prepToAddManually = (checkIn) => {
    this.setState({
      manualDrop: true,
      checkInToDrop: checkIn,
    })
  }

  removeManualDropStatus = () => {
    this.setState({
      manualDrop: false,
      checkInToDrop: '',
    })
  }

  cancelManualDrop = () => {
    this.setState({
      manualDrop: false,
      checkInToDrop: '',
    })
  }

/*
  <div className="open-schedule-modal-btn" onClick={this._openScheduleModal}>
  <img src={calendar} className="open-schedule-icon" alt="Calendar Icon" />
    Calendar
  </div>
*/

/*

/*
 <div className={
    this.state.sessionLength === 1 ? 
    "home-page-section single-expand-section" : 
    this.state.sessionLength === 2 && this.state.rightSideBarClose ?
    "home-page-section double-expand-section-close" : 
    this.state.sessionLength === 2 && !this.state.rightSideBarClose ?
    "home-page-section double-expand-section-open" : 
    this.state.sessionLength > 2 ?
    "home-page-section expand-section" :
    "home-page-section"
  }>
  */

  _newSession = () => {
    var key = this.props.firebase.db.ref('api/sessions/').push().key;
    var timestamp = moment().format('MM/DD/YYYY hh:mm a');
    var day = moment().format('MM/DD/YYYY');
    this.props.firebase.apiNewSession(this.state.uid, this.state.cid, key, timestamp, day, 'inactive');
    this.props.history.push(ROUTES.SESSION + '/' + key);
  }

  render() {
    const { 
      checkIns, completedCheckIns, listedRoomProjects,
      loading, filteredCheckIns, filteredChecks, activeRoomRolesList,
      rooms,
      checkInNotes, noteBy,
      alerts, dateFilter, roomOptions, roomToAssignRole,
      todayAvg, weekAvg, monthAvg, allAvg,
    } = this.state;

      return (
        <div>
        {
          this.state.showDeviceSelect && this.state.employees ?
          showDeviceOwnerSelect(this.state.employees, this.props.firebase, this.state.uid, this._resetDevice)
          :
          <div />
        }
        <div className="alert-panel">
          {
            alerts !== '' ?
            <CSSTransitionGroup
            transitionName="test"
            transitionEnter={true}
            transitionLeave={true}
            transitionEnterTimeout={200}
            transitionLeaveTimeout={200}
            >{
            alerts.map((item, index) => (
              <div key={index} className="alert-card">
                <div className="alert-bold-text">{item.talent}</div>
                <div>to {item.roomName}</div>
                <button className="alert-delete" onClick={this.deleteAlert.bind(this, item)}>On their way!</button>
              </div>
            ))
            }
            </CSSTransitionGroup>
            :
            <div></div>
          }
        </div>
        <div className="page-header">
        </div>
        <div className="background-page">
        <div className="right-side" onClick={this.state.rightSideBar ? null : this._toggleRightSidebar.bind(this)} >
              
          <div
            className={
              this.state.rightSideBar ? 
              "check-in-container full-width" : 
              "check-in-container"
            }
          >
          <div 
            className={
                this.state.rightSideBar ? 
                "minimize-arrow arrow-left" : 
                "minimize-arrow arrow-right"} 
                onClick={this._toggleRightSidebar.bind(this)}
            >
                <img className="toggle-arrow" src={arrowdown} alt="Toggle Sidebar" />
            </div>  
          {
            this.state.rightSideBar ?
              this.state.quickMenu === 'talentgreeting' ||
              this.state.quickMenu === 'quicktags' ||
              this.state.quickMenu === '' ?
                <QuickMenuComponent 
                  firebase={this.props.firebase}
                  state={this.state}
                  onInputChange={this.onInputChange}
                  _updateGreeting={this._updateGreeting}
                  _setActiveUser={this._setActiveUser}
                  ROUTES={ROUTES}
                  dashboardBtn={false}
                  _signOut={this._signOut}
                  _openAccountSettings={this._openAccountSettings}
                  _showDeviceSelect={this._showDeviceSelect}
                  _turnOffTalentSignUp={this._turnOffTalentSignUp}
                  _turnOnTalentSignUp={this._turnOnTalentSignUp}
                  talentSignUp={this.state.talentSignUp}
                />
              :
              <div>
                <div>
                  <div className="quick-bar-back-btn">
                      <button className="quick-bar-back" onClick={this._backQuickBar}>Back</button>
                  </div>
                  <div className="greeting-header">
                    <div className="greeting-label">Check In List</div>
                  </div>
                  <div className="home-page-tab-container">
                    <div 
                      className={
                        this.state.activeTab === 'waiting' ? 
                        "home-page-tab active-tab" : 
                        "home-page-tab"
                        } 
                      onClick={this._tabCheckedIn}
                    >
                        In Queue
                    </div>   

                    <div 
                      className={
                        this.state.activeTab === 'complete' ? 
                        "home-page-tab active-tab" : 
                        "home-page-tab"
                        } 
                      onClick={this._tabComplete}
                    >
                        Complete
                    </div>              
                  </div>
                </div>
                <div className={!this.state.rightSideBar ? "no-opacity" : "full-opacity"}>
                  {
                  this.state.activeTab === 'complete' ?
                    <div className="check-in-box">
                      <div className="check-in-scroll">
                        <CompletedCheckInsList 
                          timeConvert={this.timeConvert} 
                          handleFilterChange={this.handleFilterChange} 
                          filter={this.state.filter} 
                          filteredCheckIns={filteredCheckIns} 
                          filterCheckIns={this.filterCheckIns} 
                          completedCheckIns={completedCheckIns}
                          onChange={this.onChange}
                          _filterByToday={this._filterByToday}
                          _filterByWeek={this._filterByWeek}
                          _filterByMonth={this._filterByMonth}
                          _filterByAll={this._filterByAll}
                          dateFilter={dateFilter}
                          _openNotesModal={this._openNotesModal}
                          todayAvg={todayAvg}  weekAvg={weekAvg} monthAvg={monthAvg} allAvg={allAvg}
                        />
                      </div>
                    </div>
                    :
                    <div className="check-in-box">
                      <div className="check-in-scroll">
                        <CheckInsList 
                          checkIns={checkIns} assignNext={this.assignNext}
                          assignNextId={this.state.assignNextId} handleCheckSearchChange={this.handleCheckSearchChange}
                          filterOne={this.state.filterOne} filteredChecks={filteredChecks}
                          onChange={this.onChange}  checkInNotes={checkInNotes} noteBy={noteBy}
                          activeRoomRolesList={activeRoomRolesList} listedRoomProjects={listedRoomProjects}
                          onInputChange={this.onInputChange}
                          roomOptions={roomOptions} roomToAssignRole={roomToAssignRole} _assignRoleToRoom={this._assignRoleToRoom}
                          _openScheduleModal={this._openScheduleModal}
                          _handleTimeDiff={this._handleTimeDiff}
                          _deleteCheckIn={this._deleteCheckIn}
                          _showDelete={this._showDelete}
                          checkInHoverId={this.state.checkInHoverId}
                          _removeDelete={this._removeDelete}
                          prepToAddManually={this.prepToAddManually}
                          manualDrop={this.state.manualDrop}
                          checkInToDrop={this.state.checkInToDrop}
                          cancelManualDrop={this.cancelManualDrop}
                        />
                      </div>
                    </div>
                    } 
                </div>
              </div>
              :
              <div />
            }
            </div>
        </div>
        <div className={this.state.rightSideBarClose ? "left-side fadeOutFast" : "left-side fadeIn"}>
          <div className="home-page-section">
            <div id="quick-session-btn" className="create-new-session-btn" onClick={this._newSession}>Quick Session</div>
            <div className="create-new-session-btn" onClick={this._openNewSessionModal}>Build Session</div>
            <div className="casting-session-section">
              <div className="home-section-header">My Sessions</div>
              {
                rooms ?
                <div id="on-deck-section-underline">

                <div id="in-progress-label" className="active-room-section">In Progress</div>
                  <OnDeck 
                    checkIns={checkIns}
                    rooms={rooms}
                    activeRoomRolesList={activeRoomRolesList}
                  />
                </div>
                :
                <div />
              }
               <div className="rooms-list-scroll">
                <div className="rooms-list-section">
                  <CastingSessionsList 
                    _minimizeSessions={this._minimizeSessions} 
                    _expandSessions={this._expandSessions} 
                    uid={this.state.uid} 
                    firebase={this.props.firebase} 
                    _openSessionModal={this._openSessionModal} 
                    _editSessionModal={this._editSessionModal} 
                    _setSectionLength={this._setSectionLength}
                    ROUTES={ROUTES}
                    roomOptions={this.state.roomOptions}
                    employees={this.state.employees}
                    manualDrop={this.state.manualDrop}
                    checkInToDrop={this.state.checkInToDrop}
                    removeManualDropStatus={this.removeManualDropStatus}

                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
      this.state.modal ?
      <div className="modals-container">{this.currentModalState()}</div>
      :
      <div></div>
      }
    </div>
    )
  };
};

// Put in check-in-card-elements <AssignNextBtn checkIn={checkIn} assignNext={assignNext} assignNextId={assignNextId} />

const CheckInsList = ({ 
  onChange, checkIns, rooms, assignNext, assignNextId,
  activeRoomRolesList, listedRoomProjects,
  editGreeting, greeting, newGreeting, onInputChange,
  _openGreetingEdit, _updateGreeting, _closeGreetingEdit, 
  roomOptions, roomToAssignRole, _assignRoleToRoom, _openScheduleModal, _handleTimeDiff,
  _deleteCheckIn, _showDelete, checkInHoverId, _removeDelete,
  prepToAddManually, checkInToDrop, manualDrop, cancelManualDrop
}) => {

  const activeRolesArray = activeRoomRolesList ? activeRoomRolesList.map(role => role.roleId) : '';
  const activeProjectsArray = listedRoomProjects ? listedRoomProjects.map(project => project.projectId) : '';

  return (

    <div>
      {checkIns !== '' ? 
        <CSSTransitionGroup
          transitionName="test"
          transitionEnter={true}
          transitionLeave={true}
          transitionEnterTimeout={200}
          transitionLeaveTimeout={200}
          >
          {checkIns.map(c => (
            <CheckInCard 
              c={c}
              key={c.id}
              activeProjectsArray={activeProjectsArray}
              activeRolesArray={activeRolesArray}
              roomOptions={roomOptions}
              roomToAssignRole={roomToAssignRole}
              _assignRoleToRoom={_assignRoleToRoom}
              _handleTimeDiff={_handleTimeDiff}
              _deleteCheckIn={_deleteCheckIn}
              _showDelete={_showDelete}
              checkInHoverId={checkInHoverId}
              _removeDelete={_removeDelete}
              prepToAddManually={prepToAddManually}
              manualDrop={manualDrop}
              checkInToDrop={checkInToDrop}
              cancelManualDrop={cancelManualDrop}
            />
            ))}
            <div className="tall-padding-bottom">
            </div>
          </CSSTransitionGroup>
          :
          <div className="default-null-message">There is currently no one waiting for a session</div>
          }
        </div>
    )
  };

const CompletedCheckInsList = ({ 
  onChange, 
  timeConvert, 
  handleFilterChange, filter, 
  completedCheckIns, reCheckIn, 
  filteredCheckIns,
  _filterByToday, _filterByWeek, _filterByMonth, _filterByAll,
  dateFilter,
  _openNotesModal,
  checkInNotes, checkModalName, checkModalProject, checkModalRole,
  addNewNote, saveNotes, noteBodyValue, newNoteValue, completeModal, toggleCompleteModalOn, toggleCompleteModalOff, 
  todayAvg, weekAvg, monthAvg, allAvg,
}) => {

  const lowercasedFilter = filter.toLowerCase();

  if (filteredCheckIns.length > 0) {

    const updatedList = completedCheckIns.filter(item => {
      return Object.keys(item).some(key => 
        item[key].toLowerCase().includes(lowercasedFilter)
      );
    })

    return (
      <div className="completed-list-search">
        <div className="date-filter">
        { dateFilter === 'today' ? <div className="date-range-select">TODAY</div> : <div onClick={_filterByToday}>TODAY</div> }
        { dateFilter === 'week' ? <div className="date-range-select">WEEK</div> : <div onClick={_filterByWeek}>WEEK</div> }
        { dateFilter === 'month' ? <div className="date-range-select">MONTH</div> : <div onClick={_filterByMonth}>MONTH</div> }
        { dateFilter === 'all' ? <div className="date-range-select">ALL</div> : <div onClick={_filterByAll}>ALL</div> }
        </div>
        <div className="avg-time-container">{
            dateFilter === 'today' ? <div>Average time: {todayAvg}</div> :
            dateFilter === 'week' ? <div>Average time:  {weekAvg}</div> :
            dateFilter === 'month' ? <div>Average time:  {monthAvg}</div> :
            dateFilter === 'all' ? <div>Average time:  {allAvg}</div> :
            <div>Average time: {this.state.todayAvg}</div>
            }
        </div>
        <div className="search-container"><input className="filter-talent" type="text" placeholder="Search" value={filter} onChange={handleFilterChange} /></div>
        <div className="completed-box">
        <div className="check-in-scroll">
        { updatedList.length > 0 ? 
          updatedList.map((item, index) => (
            <div key={item.id} className="check-in-card-container">
            <div 
            className="check-in-cards"
            onClick={_openNotesModal.bind(this, item)} 
            >
            <div className="check-in-card-elements">
                <div className="check-in-card-time-complete">{timeConvert(item.totalTime)}</div>
                <div className="check-in-card-name">{item.checkInName}</div>
                <div className="check-in-card-line-two text-bold">{item.checkInProject}</div>
                <div className="check-in-card-line-two"> for</div>
                <div className="check-in-card-line-two text-bold">'{item.checkInRole}'</div>
                <div className="check-in-card-rep">Rep: {item.checkInRepCompany}</div>
                <div className="check-in-card-count-container">
                    <div className="check-in-card-count">
                      <img className="talent-count-icon" src={noteCountIcon} alt="Note Count" />
                      <div className="count-number">{item.notes}</div>
                    </div>
                    <div className="check-in-card-count">
                      <img className="talent-count-icon" src={tagCountIcon} alt="Tag Count"/>
                      <div className="count-number">{item.tags}</div>
                    </div>
                </div>
            </div>
          </div>
          </div>
          )
        )
        : 
        <div className="default-null-message">There is no completed auditions for this time period</div>
      }
        </div>
      </div>
      </div>
    )
  } else {
    return (
      <div className="default-null-message">There is no completed auditions for this time period</div>
    )
  };
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(HomePage);

const HomePageForm = compose(
  withFirebase,
  withRouter,
  withAuthentication
  )(HomePageFormBase);

export {HomePageForm}

