import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { withAuthorization, withAuthentication } from '../Session';
import * as ROUTES from '../../constants/routes';
import { compose } from 'recompose';
import moment from 'moment';
import discoveranilogo from '../../assets/discoveranilogo.png';

function InviteCompose(props) {

    const [data, setData] = useState('');
    const [id, setId] = useState('');
    const [pass, setPass] = useState('');

    useEffect(() => {

        console.log('hello');
    }, [])

    useEffect(() => {
        if (window.location.hash) {
            var iden = window.location.hash.substr(1);
            setId(iden);
            props.firebase.apiSessionInvite(iden)
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    setData(snapshot.val());
                }
            })
        };
    }, []);

    function register() {
        props.firebase.doCreateUserWithEmailAndPassword(data.email, pass)
        .then(authUser => {
            props.firebase.apiUser(authUser.user.uid)
            .set({
                name: data.name,
                email: data.email, 
                company: '',
                companyName: '',
                permission: 'guest',
                created: moment().format('MM/DD/YYYY hh:mm a'),
            });
            props.firebase.apiSession(data.id).child('guests/' + id)
            .update({
                status: 'accepted'
            });
            props.firebase.apiSessionInvite(id).remove();
        })
        .then(() => {
            props.history.push(ROUTES.SESSION_GUEST + '/' + data.id);
        })
        .catch(error => {
            console.log(error);
        });
    }

    return (
        <div className="session-background-page">
            <div className="form-view-mobile">
                <img className="form-logo" src={discoveranilogo} alt="Logo" />
                <div className="input-container">
                    <div className="register-form-header">{data.cname} invited you to join</div>
                <div className="form-sub-description">
                    <div className="form-sub-text">To access this page, please create a temporary account by filling your name and a password.</div>
                    <div className="form-sub-text">After the session, you can keep this account to enhance your talent tracking capabilities, or you can delete it.</div>
                </div>
                <div className="height-30" />
                </div>
                <div>
                    <div className="input-container">
                        <div className="input-label">Name</div>
                        <div className="email-text">{data.name}</div>
                    </div>
                    <div className="input-container">
                        <div className="input-label">Email Address</div>
                        <div className="email-text">{data.email}</div>
                    </div>
                    <div className="input-container">
                        <div className="input-label">Password</div>
                        <input className="form-input-guest" placeholder="password" type='password' autoComplete="off" value={pass} onChange={(e) => setPass(e.target.value)} />
                    </div>
                    </div>
                    <button onClick={() => register()} className="submit-register-btn">Submit</button>
                <div />
            </div>
        </div>
    )
}


const condition = authUser => !!authUser;

const InviteSignUp = compose(
    withFirebase,
    withRouter,
    )(InviteCompose);

export default InviteSignUp;