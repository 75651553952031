import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import { Input } from 'reactstrap';

import moment from 'moment';

import discoveranilogo from '../../assets/discoveranilogo.png';

const GuestRegisterPage = () => (
    <div>
        <GuestRegisterForm />
    </div>
);

class GuestRegisterFormBase extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pw: '',
            name: '',
            email: '',
            com: '',
            by: '',
            sess: '',
            access: '',
        }
    }

    componentDidMount() {
        var URL = window.location.pathname;
        var inv = URL.replace('/guest-register/', '');
        this.setState({
            inv: inv,
        })
        this.props.firebase.invite(inv)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                this.setState({
                    com: o.c,
                    email: o.e,
                    by: o.u,
                    room: o.r,
                    sess: o.s,
                })
            }
        })}

    _onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    _onSubmitRegister = event => {
        var s = this.state;
        var email = s.email;
        var name = s.name;
        var pass = s.pw;
        var by = s.by;
        var inv = s.inv;
        var sess = s.sess;
        var access = s.access;
        var date = moment().format('MM/DD/YYYY hh:mm:ss');
        this.props.firebase.createTempUserFromInvite(email, pass, name, inv, by, date, sess);
        this.props.history.push('/session-guest/' + sess);
        event.preventDefault();
    }

    render() {

        return (

            <div className="session-background-page">
                <div className="form-view-mobile">
                <img className="form-logo" src={discoveranilogo} alt="Logo" />
                <div className="input-container">
                <div className="register-form-header">{this.state.com} invited you to join a session</div>
                <div><div className="form-sub-text">To access this page, please create a temporary account by filling your name and a password.</div>
                <div className="form-sub-text">After the session, you can keep this account to enhance your talent tracking capabilities, or you can delete it.</div></div>
                </div>
                <form className="register-form" onSubmit={this._onSubmitRegister}>
                    <div className="input-container">
                        <div className="input-label">Email</div>
                        <div className="email-text">{this.state.email}</div>
                    </div>
                    <div className="input-container">
                        <div className="input-label">Name</div>
                        <input className="form-input-guest" placeholder="name" name="name" autoComplete="off" value={this.state.name} onChange={this._onChange.bind(this)} />
                    </div>
                    <div className="input-container">
                        <div className="input-label">Password</div>
                        <input className="form-input-guest" placeholder="password" type='password' name="pw" autoComplete="off" value={this.state.pw} onChange={this._onChange.bind(this)} />
                    </div>
                    <div>
                    <div className="btn-padding">
                    <div />
                    <button type="submit" className="submit-register-btn">Submit</button>
                    <div />
                    </div>
                    </div>
                </form>
                </div>
            </div>
        )
    };
};

const GuestRegisterForm = compose(
    withRouter,
    withFirebase,
  )(GuestRegisterFormBase);

export default GuestRegisterPage;

export { GuestRegisterForm };