import React, { Component } from 'react';

import remove from '../../../assets/remove.png';

import '../modals.css';

export default class ScheduleModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    }
  }

  componentDidMount() {
    this.setState({
      modalOpen: true,
    })
  }

  _closeModal = () => {
    this.setState({
      modalOpen: false,
    })
    var timer = setTimeout(() => {
      this.props.close()
    }, 500);
    clearTimeout(timer);
  }
  

    render() {

      return (
        <div className={this.state.modalOpen ? "fadeInFast" : "fadeOutFast"}>
        <div className="custom-modal-layout">
          <div className="modal-bg-overlay" onClick={this._closeModal} />
          <div className="schedule-modal-card">
            <div className="custom-modal-header">
              <div>{this.props.modalHeader}</div>
              <div className="modal-close-btn" onClick={this._closeModal}><img src={remove} alt='Close Modal' className="close-modal-x" /></div>
            </div>
            <div className="custom-modal-body-schedule">
              {this.props.modalBody}
            </div>
          </div>
        </div>
        </div>
      )
    }
  }