import React, { useState, useEffect } from 'react';
import { InputShift } from '../Common';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';

function MobileFormCompose(props) {

    const [code, setCode] = useState('');
    const [connect, setConnect] = useState(false);

    function checkCode() {
        props.firebase.apiMobileForms()
        .orderByChild('code')
        .equalTo((code))
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var key = Object.keys(o)[0];
                console.log(key);
            }
        })
    }

    return (
        <div className="mobile-container">
            {
                connect ?
                <Form />
                :
                <div>
                <div className="input-label">
                    Please enter the code provided to you below
                </div>
                <InputShift 
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    label="Access Code"
                />
                <button onClick={() => checkCode()} className="confirm-code">Confirm</button>
                </div>
            }
        
        </div>
    )
}

function Form(props) {

    return (
        <div />
    )
}


const MobileForm = compose(
    withFirebase,
    )(MobileFormCompose);

export default MobileForm;