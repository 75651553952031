import React from 'react';

function ObjectList(objs) {
    if (objs) {
        return Object.keys(objs).map(key => ({
            ...objs[key],
            id: key,
        }))
    } else {
        return ('');
    };
};

export { ObjectList };