import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { withAuthorization, withAuthentication } from '../Session';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';

import { ObjectList } from '../Functions/Common';

import QuickMenuComponent from '../QuickMenu/QuickMenuComponent';
import Content from './Content';
import Update from '../Updates/Update';

import menu from '../../assets/nav/menucollapse.png';

import './dash.css';

function Dashboard(props) {

    const [uid, setUid] = useState('');
    const [cid, setCid] = useState('');
    const [myEmail, setMyEmail] = useState('');
    const [arrivals, setArrivals] = useState('');
    const [username, setUsername] = useState('');
    const [page, setPage] = useState('sessions');
    const [mobile, setMobile] = useState(false);
    const [alerts, setAlerts] = useState('');
    const [p, setP] = useState('');

    useEffect(() => {
        var width = window.innerWidth;
        if (width < 560) {
            setMobile(true);
        }
    }, [])

    useEffect(() => { 
        var authTimer = setTimeout(() => {
            props.firebase.auth.onAuthStateChanged(
                authUser => {
                    console.log(authUser);
                    if (authUser && authUser.uid !== uid) {
                        setUid(authUser.uid);
                        props.firebase.apiUser(authUser.uid)
                        .once('value', snapshot => {
                            var o = snapshot.val();
                            var c = o.company;
                            var u = o.name;
                            var em = o.email;
                            var p = o.permission;
                            setP(p);
                            setCid(c);
                            openAlerts(c);
                            setUsername(u);
                            setMyEmail(em);
                            props.firebase.apiCompanyGuests(c)
                            .orderByChild('complete')
                            .equalTo(false)
                            .on('value', snapshot => {
                                if (snapshot.exists()) {
                                    var ao = snapshot.val();
                                    var al = Object.keys(ao).map(key => ({
                                        ...ao[key],
                                        id: key,
                                    }));
                                    setArrivals(al);
                                } else {
                                    setArrivals('');
                                }
                            })
                        })}
                    })
            }, 500);
            return (() => clearTimeout(authTimer));
    }, []);

    function openAlerts(c) {
        props.firebase.apiCompany(c)
        .child('alerts')
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var list = ObjectList(snapshot.val());
                setAlerts(list);
            } else {
                setAlerts('');
            };
        });
    };


    return (
        <Dash 
            mobile={mobile} 
            userdata={{uid: uid, cid: cid, username: username, myemail: myEmail, arrivals: arrivals, p: p}} 
            page={page} setPage={setPage} firebase={props.firebase} history={props.history} 
            alerts={alerts}
        />
    )
}




function Dash(props) {

    const [open, setOpen] = useState(true);
    const [positionUsers, setPositionUsers] = useState('');
    const [positionFocus, setPositionFocus] = useState('');
    const [fadeInContent, setFadeInContent] = useState(false);

    useEffect(() => {
        setFadeInContent(false);
        var width = window.innerWidth;
        if (width < 560) {
            setOpen(false);
        }
        var fadeContentTimer = setTimeout(() => {
            setFadeInContent(true);
        }, 500);
        return (() => clearTimeout(fadeContentTimer));
    }, []);
    

    function showCandidates(item) {
        if (item) {
            setPositionFocus(item.name);
            props.firebase.apiCompanyGuests(props.userdata.cid)
            .orderByChild('interview/id')
            .equalTo((item.id))
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    setPositionUsers(ObjectList(snapshot.val()));
                    props.setPage('candidates')
                } else {
                    setPositionUsers('');
                    props.setPage('candidates')
                }
            });
        } else {
            props.setPage('sessions');
            setPositionUsers('');
            setPositionFocus('');
        }
    };


    return (
        <div>
            <Alerts alerts={props.alerts} firebase={props.firebase} cid={props.userdata.cid} />
            <div className="dashboard-layout color-bg">
                <div className={
                    !open && !props.mobile ? 
                    "nav nav-color nav-min-dash relative" : 
                    !open && props.mobile ?
                    "nav nav-color nav-min-mobile-dash relative" :
                    "nav nav-color relative"
                    }
                    onClick={!open && !props.mobile ? () => setOpen(true) : null}    
                >
                <img className={
                    props.mobile && open ? "nav-toggle-mobile" : 
                    props.mobile && !open ? "nav-toggle-mobile ntg-right" :
                    "nav-toggle"} src={menu} alt="Menu Toggle" onClick={() => setOpen(!open)} />
                <div className={!open ? "display-none" : "full-width"}>
                <DashboardNav 
                    open={open}
                    mobile={props.mobile} 
                    userdata={props.userdata} 
                    page={props.page} 
                    setPage={props.setPage} 
                    firebase={props.firebase} 
                    history={props.history} 
                    showCandidates={showCandidates}
                    fadeInContent={fadeInContent}
                    setFadeInContent={setFadeInContent}
                />
                </div>
                </div>
                <div className={fadeInContent ? 'fade-in' : 'fade-out'}>
                <Content 
                    open={open} 
                    mobile={props.mobile} 
                    page={props.page}
                    setPage={props.setPage} 
                    userdata={props.userdata} 
                    firebase={props.firebase}
                    history={props.history} 
                    candidates={positionUsers}
                    positionFocus={positionFocus}
                />
                </div>
            </div>
        </div>
    )
}

function Alerts(props) {

    function deleteAlert(item) {
        props.firebase.apiAlert(props.cid, item.id).remove();
    };

    return (
        <div className="alert-window">
            {
                props.alerts ?
                props.alerts.map((item, index) => (
                    <AlertItem key={item.id} item={item} deleteAlert={deleteAlert} />
                ))
                :
                null
            }
        </div>
    )
}

function AlertItem(props) {

    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        var fTimer = setTimeout(() => {
            setFadeIn(true);
        }, 200);
        return (() => clearTimeout(fTimer));
    }, []);

    function prepDelete() {
        setFadeIn(false);
        var f2Timer = setTimeout(() => {
            props.deleteAlert(props.item);
        }, 400);
        return (() => clearTimeout(f2Timer));
    }

    return (
        <div className={fadeIn ? 'fade-in' : 'fade-out'}>
            <div className="alert-card">
                <div className="alert-bold-text">{props.item.name}</div>
                <div>to {props.item.room}</div>
                <button className="alert-delete" onClick={() => prepDelete()}>
                    On their way!
                </button>
            </div>
        </div>
    )
}

function DashboardNav(props) {

    return (
        <div className={props.fadeInContent ? "fade-in" : "fade-out"}>
        <QuickMenuComponent 
            mobile={props.mobile} 
            page={props.page} 
            setPage={props.setPage} 
            userdata={props.userdata} 
            firebase={props.firebase} 
            history={props.history} 
            showCandidates={props.showCandidates}
        />
        </div>
    )
}

const condition = authUser => !!authUser;

const DashboardCompose = compose(
    withFirebase,
    withRouter,
    withAuthentication
    )(Dash);

export default withAuthorization(condition)(Dashboard);
  
export { Dash };