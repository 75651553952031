import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import { Navbar, Nav, NavItem } from 'reactstrap';
import { withAuthorization, withAuthentication } from '../Session';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import Select from 'react-select';
import moment from 'moment';
import HelpBox from './FAQ/HelpBox';
import LockScreen from '../Security/LockScreen';

import ScheduleModal from '../Modals/ScheduleModal/ScheduleLayout';
import ScheduleModalBody from '../Modals/ScheduleModal/ScheduleModalBody';
import Update from '../Updates/Update';

import aniicon from '../../assets/aniicon.png';
import calnav from '../../assets/calnav.png';
import expandnav from '../../assets/expand-nav.png';

import './app.css';

var timer;

const Navigation = (authUser) => (
  <div>
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? 
        <NavigationAuth authUser={authUser} /> : <NavigationNonAuth />
      }
    </AuthUserContext.Consumer>
  </div>
)

class NavigationAuthComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
        uid: '',
    };
  };

  selectedState = (newSessionUserKey) => {
    localStorage.setItem( 'SessionState', newSessionUserKey);
    this.setState({
      sessionState: newSessionUserKey
    })
  }

  signOut = () => {
    localStorage.setItem( 'SessionState', '');
    localStorage.setItem( 'ANiCheckInLocked', '');
    this.props.history.push(ROUTES.SIGN_IN);
    this.props.firebase.doSignOut();
  }

  _handleCompareCheck = () => {
    if (this.state.up === this.state.apple) {
      localStorage.setItem('ANiCheckInLocked', '')
      this.setState({
        locked: false,
      })
    }
  }

  _handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  };

  componentDidMount() {
    this.setState({
      uid: this.props.authUser.uid,
    })
  }

  componentWillUnmount() {

  }

  render() {
    return (
        <Update firebase={this.props.firebase} uid={this.state.uid} />
      )
    }
  };

const NavigationNonAuth = () => (
  <div>
    <Navbar color="light" light expand="md">
      <Nav className="ml-auto" navbar>
        <NavItem>
          <NavLink className="sign-out-btn" style={style.navLink} to={ROUTES.SIGN_IN}>Sign In</NavLink>
        </NavItem>
      </Nav>
    </Navbar>
  </div>
);

const style = {
  navLink: {
    textDecoration: 'none',
  }
}

const NavigationAuth = compose(
  withRouter,
  withFirebase,
  withAuthentication
)(NavigationAuthComponent);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Navigation, NavigationAuth);


