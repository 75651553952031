import React, { Component } from 'react';

var timer;

export default class SessionUserSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            clicked: false,
        }
    }


    componentDidMount() {
        var userIds = this.props.sessionUsers ? this.props.sessionUsers.map(i => i.value) : '';
        var id = this.props.item.value;
        var selected = userIds.includes(id);
        this.setState({
            clicked: selected ? true : false,
        })
    };

    handleClick = (item, index) => {
        this.setState({
            clicked: !this.state.clicked,
        })
        clearTimeout(timer);
        timer = setTimeout(() => {
        this.props._selectUser(item, this.state.clicked)
        }, 50);
        clearTimeout(timer);
    };

    render() {

        const {item, index, userblank, sessionUsers} = this.props;
        return (

            <div key={item.value} className="listed-user-session-modal"
                onClick={this.handleClick.bind(this, item, index)}
            >
                <div className="user-icon-session-modal"><img src={userblank} className={this.state.clicked ? "template-avatar selected-item" : "template-avatar"} alt="User Badge" /></div>
                <div className="user-name-session-modal">{item.label}</div>
            </div>

        )
    }
}