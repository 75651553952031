import React from 'react';

import Select from 'react-select';

const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
}

const OppSection = (props) => {

    var roleIds = props.state.sessionRoles ? props.state.sessionRoles.map(role => role.id) : '';
    var focus = props.state.sessionName === '';

    return (
        <div className="nav-section-container">

            <div className="nav-section-header-text">Opportunity Section</div>

            <div className="nav-section-section">
            <div className="session-modal-label">How would you like to label your session?</div>
                <input 
                    autoFocus={focus ? true : false}
                    className="session-modal-input" 
                    value={props.state.sessionName} 
                    name="sessionName" 
                    onChange={props._onChange.bind(this)} 
                    placeholder="Title your session" 
                    onKeyDown={props._onEnterSessionName}
                />
                {
                props.state.newSessionName !== props.state.sessionName ?
                <button className="quick-save-btn" onClick={props._saveSessionName}>Save</button>
                :
                <div />
                }
            </div>
            
            {
                props.state.nameExists ?
                <div className="nav-section-section">
                <div className="session-modal-label">Which project is casting?</div>
                <Select 
                    styles={customStyles}
                    value={props.state.sessionProject}
                    onChange={props._selectProject}
                    className="nav-react-select"
                    placeholder="Select project"
                    autoFocus
                    options={props.state.projectOptions ? props.state.projectOptions : []}
                />
                </div>
                :
                <div />
            }
            <div className="nav-section-section">
            {
                props.state.sessionProject ?
                <div>
                <div className="session-modal-label">Select the roles you are casting</div>
                {
                    props.state.roleOptions ?
                    props.state.roleOptions.map((item, index) => (
                        <button 
                            key={item.value} 
                            className={roleIds ? roleIds.includes(item.value) ? "session-type-option selected-option type-role" : "session-type-option type-role" : "session-type-option type-role"}
                            onClick={roleIds ? roleIds.includes(item.value) ? props._deselectRole.bind(this, item) : props._selectRole.bind(this, item) : props._selectRole.bind(this, item)}
                        >
                            <div className="nav-modal-role-name">{item.label}</div>
                        </button>
                    ))
                    :
                    <div />
                }
                </div>
                :
                <div />
            }
            
            </div>
            <div className="nav-sess-buttons-container">
                <div className="nav-modal-next-btn">
                {
                    props.state.sessionProject && props.state.sessionName && roleIds.length > 0 ?
                    
                    <button className="nav-sess-btn" onClick={props._toSessionDetails}>Next</button>
                    :
                    <div />
                }
                </div>
            </div>
        </div>
    )
}

export { OppSection };