import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { format, addDays } from 'date-fns';
import { times } from './times';

import ApptBlock from './ApptBlock';

import '../../transitions.css';

import arrowdown from '../../../assets/arrowdown.png';
import clockoutline from '../../../assets/clockoutline.png';

export default class ScheduleGrid extends Component {

    constructor(props) {
        super(props);

        this.state = {
            step: '30m',
            filteredTimes: '',
            date: new Date(),
            appts: '',

            draggedOnIndex: '',
            draggedOnMins: '',
            draggedOnHours: '',
            draggedOnNum: '',

            minSelect: '',
            apptDropped: false,
            draggedAppt: '',
            removeDraggedItem: false,
            

        }
    }

    componentDidMount() {

        this.setState({
            date: new Date(),
        })

        this.setState({
            originalTimes: times.filter(f => {
                return (Number(f.hr) >= 8 && Number(f.hr) < 23);
            }),
            filteredTimes: times.filter(f => {
                return (Number(f.hr) >= 8 && Number(f.hr) < 23) && (f.min === '30' || f.min === '00');
            }),
            allTimes: times,
        })

        var dateFilter = format(new Date(this.state.date), 'MM/dd/yyyy');

        this.props.firebase.userAppts(this.props.uid)
        .orderByChild('date')
        .equalTo((dateFilter))
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                var sortedAppts = l.sort((a, b) => (a.num > b.num) ? 1 : -1);
                this.setState({
                    appts: sortedAppts,
                });
            } else {
                this.setState({
                    appts: ''
                })
            };
        });
    }

    componentWillUnmount() {
        this.props.firebase.userAppts(this.props.uid).off();
    }

    _handleChangeIncrement = (event, value) => {

        this.setState({
            [event.target.name]: event.target.value,
        });

        if (event.target.value === '5m') {
            this.setState({ filteredTimes: this.state.originalTimes})
        } else if (event.target.value === '15m') {
            var filter15 = this.state.originalTimes.filter(f => {
                return f.min === '15' || f.min === '30' || f.min === '45' || f.min === '00';
            })
            this.setState({ filteredTimes: filter15 })
        } else if (event.target.value === '30m') {
            var filter30 = this.state.originalTimes.filter(f => {
                return f.min === '30' || f.min === '00';
            })
            this.setState({ filteredTimes: filter30 })
        } else if (event.target.value === '1h') {
            var filter60 = this.state.originalTimes.filter(f => {
                return f.min === '00';
            })
            this.setState({ filteredTimes: filter60 })
        }
    }
    
    _selectNextDate = () => {
        this.props.firebase.userAppts(this.props.uid).off();

        var nextDate = addDays(new Date(this.state.date), 1);
        var date = format(new Date(nextDate), 'MM/dd/yyyy');

        this.setState({
            date: nextDate
        })

        this.props.firebase.userAppts(this.props.uid)
        .orderByChild('date')
        .equalTo((date))
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                this.setState({
                    appts: l,
                });
            } else {
                this.setState({
                    appts: ''
                })
            };
        });
    }

    _selectPrevDate = () => {
        this.props.firebase.userAppts(this.props.uid).off();

        var prevDate = addDays(new Date(this.state.date), -1);
        var date = format(new Date(prevDate), 'MM/dd/yyyy');

        this.setState({
            date: prevDate
        })

        this.props.firebase.userAppts(this.props.uid)
        .orderByChild('date')
        .equalTo((date))
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                this.setState({
                    appts: l,
                });
            } else {
                this.setState({
                    appts: ''
                })
            };
        });
    }

    _selectDate = date => {
        this.props.firebase.userAppts(this.props.uid).off();

        this.setState({
            date,
        })

        var dateFilter = format(new Date(date), 'MM/dd/yyyy');

        this.props.firebase.userAppts(this.props.uid)
        .orderByChild('date')
        .equalTo((dateFilter))
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                this.setState({
                    appts: l,
                });
            } else {
                this.setState({
                    appts: ''
                })
            };
        });
    }

    _onDragStart = (i) => {
        this.setState({
            apptDragOn: true,
            draggedAppt: i.id,
        })
    };

    _onDragEnd = (i) => {
        this.setState({
            apptDragOn: false,
            removeDraggedItem: true,
        })
        this._changeApptTime();
    }

    _onDragLeave = () => {
        this.setState({
        });
    };

    _handleDragOver = (time, index) => {
        if (this.state.draggedOnIndex !== '' || this.state.draggedOnIndex !== index) {
            this.setState({
                apptDropped: true,
            })
        }
        if (this.state.draggedOnIndex !== index) {
            this.setState({
                draggedOnIndex: index,
                draggedOnMins: time.min,
                draggedOnHours: time.hr,
                draggedOnNum: time.num,
                minSelect: '',
            })
        }
    }

    _clickCancel = () => { if (this.state.minSelect !== '') { this.setState({minSelect: ''})}};
    _click0 = () => { if (this.state.minSelect !== '00') { this.setState({minSelect: '00'})}};
    _click5 = () => { if (this.state.minSelect !== '05') { this.setState({minSelect: '05'})}};
    _click10 = () => { if (this.state.minSelect !== '10') { this.setState({minSelect: '10'})}};
    _click15 = () => { if (this.state.minSelect !== '15') { this.setState({minSelect: '15'})}};
    _click20 = () => { if (this.state.minSelect !== '20') { this.setState({minSelect: '20'})}};
    _click25 = () => { if (this.state.minSelect !== '25') { this.setState({minSelect: '25'})}};
    _click30 = () => { if (this.state.minSelect !== '30') { this.setState({minSelect: '30'})}};
    _click35 = () => { if (this.state.minSelect !== '35') { this.setState({minSelect: '35'})}};
    _click40 = () => { if (this.state.minSelect !== '40') { this.setState({minSelect: '40'})}};
    _click45 = () => { if (this.state.minSelect !== '45') { this.setState({minSelect: '45'})}};
    _click50 = () => { if (this.state.minSelect !== '50') { this.setState({minSelect: '50'})}};
    _click55 = () => { if (this.state.minSelect !== '55') { this.setState({minSelect: '55'})}};

    _changeApptTime = () => {
        const { uid } = this.props;
        var ampm = this.state.draggedOnNum > 144 ? 'pm' : 'am';

        if (this.state.minSelect !== '') {
            const timer = setTimeout(() => {
                this.props.firebase.userAppt(uid, this.state.draggedAppt)
                .update({
                    ampm: ampm,
                    hr: this.state.draggedOnHours,
                    min: this.state.minSelect,
                    num: Number(this.state.draggedOnHours) * 12 + (Number(this.state.minSelect) / 5) + 1,
                });
                this.setState({
                    draggedOnIndex: '',
                    draggedOnMins: '',
                    draggedOnHours: '',
                    draggedOnNum: '',
                    minSelect: '',
                    apptDropped: false,
                    draggedAppt: '',
                })
            }, 100);
            clearTimeout(timer);
        } else {
            this.setState({
                apptDropped: false,
            })
        }
    }

    render () {

        const { filteredTimes, date, minSelect } = this.state;

        return (
            <div className="times-layout">
                <div className="date-view-container">
                    <div />
                    <img className="prev-date-arrow" src={arrowdown} alt="Previous Date" onClick={this._selectPrevDate} />
                        <DatePicker 
                            selected={date} 
                            onChange={this._selectDate} 
                            className="view-datepicker" 
                            dateFormat="iiii, MMM do"
                        />
                    <img className="next-date-arrow" src={arrowdown} alt="Previous Date" onClick={this._selectNextDate} />
                    <div />
                </div>
                <select name="step" onChange={this._handleChangeIncrement} value={this.state.step} className="step-select" >
                    <option>5m</option>
                    <option>30m</option>
                    <option>1h</option>
                </select>
                
                <div className="schedule-times-container">
                {filteredTimes ? filteredTimes.map((time, index) => (
                    <div key={index} className="time-row" onDragOver={this._handleDragOver.bind(this, time, index)}>
                        <div className="time-section">
                        {Number(time.hr) > 12 ? (Number(time.hr) - 12).toString() : Number(time.hr) === 0 ? '12' : time.hr }
                        :{time.min}{Number(time.hr) > 11 && Number(time.hr) < 24 ? 'p' : 'a'} 
                        </div>
                        <div className="time-section">
                            {
                                this.state.appts ?
                                    this.state.step === '5m' ?
                                    this.state.appts.filter(f => {
                                        return f.num === time.num
                                    }).map((i, index) => (
                                        <ApptBlock 
                                        key={i.id}
                                        i={i}
                                        _onDragStart={this._onDragStart}
                                        _onDragEnd={this._onDragEnd}
                                        uid={this.props.uid}
                                        firebase={this.props.firebase}
                                        />
                                    ))
                                    :
                                    this.state.step === '30m' ?
                                    <div>
                                    {this.state.appts.filter(f => {
                                        var n = Number(time.num);
                                        var window = [n, n + 1, n + 2, n + 3, n + 4, n + 5];
                                        return window.includes(Number(f.num));
                                    }).map((i, index) => (
                                        <ApptBlock 
                                        key={i.id}
                                        i={i}
                                        _onDragStart={this._onDragStart}
                                        _onDragEnd={this._onDragEnd}
                                        uid={this.props.uid}
                                        firebase={this.props.firebase}
                                        />
                                    ))}
                                    {
                                        this.state.apptDropped === true && this.state.draggedOnIndex === index ?
                                                this.state.draggedOnMins === '00' ?
                                                <div className="exact-time-select" onDragLeave={this._onDragLeave}>
                                                    <div className={minSelect === '00' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click0}>00</div>
                                                    <div className={minSelect === '05' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click5}>05</div>
                                                    <div className={minSelect === '10' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click10}>10</div>
                                                    <div className={minSelect === '15' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click15}>15</div>
                                                    <div className={minSelect === '20' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click20}>20</div>
                                                    <div className={minSelect === '25' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click25}>25</div>
                                                </div>
                                                :
                                                this.state.draggedOnMins === '30' ?
                                                <div className="exact-time-select" onDragLeave={this._onDragLeave}>
                                                    <div className={minSelect === '30' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click30}>30</div>
                                                    <div className={minSelect === '35' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click35}>35</div>
                                                    <div className={minSelect === '40' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click40}>40</div>
                                                    <div className={minSelect === '45' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click45}>45</div>
                                                    <div className={minSelect === '50' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click50}>50</div>
                                                    <div className={minSelect === '55' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click55}>55</div>
                                                </div>
                                                :
                                                <div />
                                            :
                                            <div>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div>
                                    {this.state.appts.filter(f => {
                                        return f.hr === time.hr;
                                    }).map((i, index) => (
                                        <ApptBlock 
                                        key={i.id}
                                        i={i}
                                        _onDragStart={this._onDragStart}
                                        _onDragEnd={this._onDragEnd}
                                        uid={this.props.uid}
                                        firebase={this.props.firebase}
                                        />
                                    ))}
                                    {
                                        this.state.apptDropped === true && this.state.draggedOnIndex === index ?
                                        <div className="exact-time-select" onDragLeave={this._onDragLeave}>
                                            <div className={minSelect === '00' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click0}>00</div>
                                            <div className={minSelect === '05' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click5}>05</div>
                                            <div className={minSelect === '10' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click10}>10</div>
                                            <div className={minSelect === '15' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click15}>15</div>
                                            <div className={minSelect === '20' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click20}>20</div>
                                            <div className={minSelect === '25' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click25}>25</div>
                                            <div className={minSelect === '' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._clickCancel}>X</div>
                                            <br />
                                            <div className={minSelect === '30' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click30}>30</div>
                                            <div className={minSelect === '35' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click35}>35</div>
                                            <div className={minSelect === '40' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click40}>40</div>
                                            <div className={minSelect === '45' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click45}>45</div>
                                            <div className={minSelect === '50' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click50}>50</div>
                                            <div className={minSelect === '55' ? "exact-time-value-over" : "exact-time-value"} onDragOver={this._click55}>55</div>
                                        </div>
                                        :
                                        <div></div>
                                    }
                                    </div>
                                :
                                <div />
                            }
                        </div>
                    </div>
                ))
            :
            <div />
            }
            </div>
            </div>
        )
    };
};