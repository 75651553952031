import React from 'react';

import { NullUser } from '../../../assets/nulluser';

import clock from '../../../assets/clockwise-rotation.png';

const Overview = (props) => {

    var { name, users, project, roles, date, actualStart, start, actualWrap, wrap, talentCount, lengthArray, noteCount, allTags, takeCount } = props;

    return (

    <div className="sd-body-container session-overview-scroll">
        <div className="sd-title-container">
            <div className="sd-title">{name}</div>
        </div>
        <div className="sd-body-header">
            <div className="sd-details-layout">
                <div>
                    <div className="sd-detail-container">
                        <div className="sd-sub-label">Date</div>
                        <div className="sd-element">{date ? date : 'No date'}</div>
                    </div>
                    <div className="sd-detail-container">
                        <div className="sd-sub-label">Time</div>
                        <div className="sd-element">{actualStart ? actualStart : start} - {actualWrap ? actualWrap : wrap}</div>
                    </div>
                    <div className="sd-detail-container">
                        <div className="sd-sub-label">Project</div>
                        <div className="sd-project-container">
                        <div className="sd-element">{project ? project.label : 'No project'}</div>
                        </div>
                    </div>
                    <div className="sd-detail-container">
                        <div className="sd-sub-label">Roles</div>
                        <div className="sd-roles-list-container">
                            {roles ? roles.map((item, index) => (
                                <div key={index} className="sd-role-item inline-block-layout">
                                    <div className="sd-element">{index === roles.length - 1 ? item.name : item.name + ', '}</div>
                                </div>
                            ))
                            :
                            <div className="no-data">No roles</div>
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <div className="sd-sub-label label-margin-bottom">Casting Team</div>
                    <div className="sd-users-list-container">
                        {users ? users.map((item, index) => (
                            <div key={index} className="user-list-inline-block">
                                <img className="sd-user-avatar-img" src={item.avatar ? item.avatar : NullUser} alt="User Profile Avatar" />
                            </div>
                        ))
                        :
                        <div />
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className="sd-body-body">
            <div className="sd-links-container">
                <div className="sd-link-item" onClick={props._clickTalent}>
                    <div className="sd-link-number">{talentCount}</div>
                    <div className="sd-link-label">Talent</div>
                </div>
                <div className="sd-link-item" onClick={props._clickNotes}>
                    <div className="sd-link-number">{noteCount}</div>
                    <div className="sd-link-label">Notes</div>
                </div>
                <div className="sd-link-item" onClick={props.tagCount ? props._clickTags : null}>
                    <div className="sd-link-number">{props.tagCount ? props.tagCount : 0}</div>
                    <div className="sd-link-label">Tags</div>
                </div>
            </div>
            <div className="sd-links-container">
                <div className="sd-link-item" onClick={props.takeCount ? props._clickTakes : null}>
                    <div className="sd-link-number">{takeCount}</div>
                    <div className="sd-link-label">Takes</div>
                </div>
                <div className="sd-link-item non-clickable">
                    <div className="sd-link-number">{lengthArray.length > 0 ? props._findAverage(lengthArray): 0}</div>
                    <div className="sd-link-label">Avg. Time</div>
                </div>
                
            </div>
        </div>
    </div>
    )
}

export { Overview };


/*
<div className="sd-tags-container">
<div className="sd-sub-label">Most Used Tags</div>
<div className="sd-most-tags-list">
    {allTags.length > 0 ? 
    <div className="sd-tag">
        {props._findMostCommon(allTags)}
    </div>
    :
    <div />
    }
</div>
</div>
*/