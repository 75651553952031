import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization, withAuthentication } from '../Session';
import { withFirebase } from '../Firebase';

import UserForm from './UserForm';

import ANiIDTip from '../HelpTips/SignInANiID/Container';
import Suggest from './Suggest/Suggest';

import moment from 'moment';

import './checkin.css';
import '../transitions.css';
import logo from '../../assets/aniicon.png';
import formColor from '../../assets/formColor.png';
import lock from '../../assets/lock.png';
import shield from '../../assets/shield.png';
import arrow from '../../assets/right-arrow.png';
import question from '../../assets/questionpink.png';
import { NullUser } from '../../assets/nulluser';
import rightarrow from '../../assets/rightwhite.png';

import checkgray from '../../assets/checkgray.png';
import checkpink from '../../assets/checkpink.png';
import graypencil from '../../assets/graypencil.png';

const CheckInPage = () => (
    <div>
        <CheckInForm />
    </div>
)

const INITIAL_STATE = {
        authUser: null,
        myId: '',
        uid: '',
        loading: false,
        rolesLoading: false,
        sessions: [''],
        projectValue: '',
        projectRoles: [''],
        name: '',
        repCompany: '',
        project: '',
        projectId: '',
        projects: [],
        projectArray: [
        ],
        roles: [''],
        projectRole: '',
        projectRolesList: [''],
        projectRolesArray: [],
        projectRolesArrayTemplate: [
        ],
        status: '',
        timeCheckedIn: null,
        timeCalled: 0,
        timeWrapped: 0,
        checkedIn: false,
        showRoles: false,
        checkedInMessage: false,
        formStatus: true,
        thanksStatus: false,
        inputFocus: 1,
        rolesLoaded: false,
        roleId: '',
        customRole: '',
        customProject: '',
        customProjectShow: false,
        customRoleShow: false,
        currentFocus: 'name',
        greeting: '',
        checkType: 1,
        lock: '',
        scheduleNames: '',
        suggestionBox: false,
        nameFilter: '',
        suggestName: '',
        autoFilled: false,
        apptId: '',
        apptTime: '',
        view: '',
        repValid: '',
        checkInType: '',
        talentUser: '',
        aniIDTip: false,
        tipFade: false,
        projectSelectOpen: false,
        roleSelectOpen: false,
        talentSignUp: false,
        fadeSelection: false,
        actorSuggestions: '',
    };

class CheckInFormBase extends Component {
    constructor(props) {
        super(props);
    
        this.state = { ...INITIAL_STATE };
        };
        
      componentDidMount() {
        this.setState({ loading: true });

        var lockStatus = localStorage.getItem('ANiCheckInLocked') || '';
        this.setState({
        lock: lockStatus
        });

        this.setState({
            inputFocus: 1,
        })

        const today = moment().format('MM/DD/YYYY');

        this.listener = this.props.firebase.auth.onAuthStateChanged(
            authUser => {
            authUser 
            ? this.setState({ myId: authUser.uid, uid: authUser.uid })
            : this.setState({ authUser: null });

            var createdBy = this.state.myId;

            this.props.firebase.user(this.state.uid)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    var user = snapshot.val();
                    var files = user.files;
                    var greeting = user.greeting; 
                    this.setState({
                        talentSignUp: user.talentSignUp ? user.talentSignUp : false,
                    })
                    if (files) {
                        this.setState({
                            avatar: files.avatar,
                        })
                    };
                    var sec = user.sec;

                    this.setState({
                        greeting: greeting,
                        sec: sec,
                        company: user.company,
                    })
                }
            });

            this.props.firebase.userCastingSessions(this.state.uid)
            .orderByChild('date')
            .equalTo((moment().format('MM/DD/YYYY')))
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({
                        ...o[key],
                        id: key,
                    }));
                    if (l) {
                        var talentOptions = [];
                        l.map((session, index) => {
                            if (session.slotTimes) {
                                var sessionTalent = session.slotTimes.filter(f => {
                                    return f.name !== ''
                                });
                                if (sessionTalent) {
                                    sessionTalent.map(st => {
                                        return talentOptions.push({
                                            name: st.name,
                                            time: st.time,
                                            roleId: st.roleId,
                                            roleName: st.role,
                                            projectName: session.project.label,
                                            projectId: session.project.value,
                                            sessionId: session.id,
                                            groupKey: session.groupKey,
                                            tempId: st.tempId,
                                        });
                                    });
                                }
                            };
                        });
                        if (talentOptions) {
                            this.setState({
                                actorSuggestions: talentOptions
                            })
                        } else {
                            this.setState({
                                actorSuggestions: ''
                            })
                        }
                    } else {
                        this.setState({
                            actorSuggestions: ''
                        })
                    }
                } else {
                    this.setState({
                        actorSuggestions: ''
                    })
                }
            });

            this.props.firebase.userAppts(this.state.uid)
            .orderByChild('date')
            .equalTo((today))
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({
                        ...o[key],
                        id: key,
                    }))
                    this.setState({
                        scheduleNames: l,
                    })
                } else {
                    this.setState({
                        scheduleNames: ''
                    })
                };
            })


            this.props.firebase.userProjects(createdBy)
            .orderByChild('isActive')
            .equalTo(true)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    const projectsObject = snapshot.val();
        
                    const projectsList = Object.keys(projectsObject).map(key => ({
                        ...projectsObject[key],
                        id: key,
                    }));
                    if (this.state.name === '') {
                        this.setState({
                            projects: projectsList,
                            loading: false,
                            projectArray: [
                            ],
                            projectRolesArray: [
                            ],
                        });
                        this.setState({
                            customProjectShow: false,
                            customRoleShow: false,
                        })
                        projectsList.map((item, index) => {
                            this.state.projectArray.push({
                                value: item.id,
                                label: item.projectName,
                            });
                        })
                    } else {
                        var timer8 = setTimeout(() => {
                        this.setState({
                            projects: projectsList,
                            loading: false,
                            projectArray: [
                            ],
                            projectRolesArray: [
                            ],
                            });
                            this.setState({
                                customProjectShow: false,
                                customRoleShow: false,
                            })
                            projectsList.map((item, index) => {
                                this.state.projectArray.push({
                                    value: item.id,
                                    label: item.projectName,
                                });
                            })
                        }, 15000);
                        return (() => clearTimeout(timer8));
                    }
                } else {
                    if (this.state.name === '') {
                        this.setState({
                            projects: '',
                            loading: false,
                            roleId: '',
                            customRole: '',
                            customProject: '',
                            customProjectShow: true,
                            customRoleShow: true,
                            showRoles: false,
                            checkType: 3,
                            rolesLoaded: true,
                        })
                    } else {
                        var timer9 = setTimeout(() => {
                            this.setState({
                                projects: '',
                                loading: false,
                                roleId: '',
                                customRole: '',
                                customProject: '',
                                customProjectShow: true,
                                customRoleShow: true,
                                showRoles: false,
                                checkType: 3,
                                rolesLoaded: true,
                            })
                        }, 15000);
                        return (() => clearTimeout(timer9));
                    }
            }});
        }
    )}

    componentWillUnmount() {
        var { createdBy } = this.state.myId;
        var uid = this.state.uid;
        this.props.firebase.userProjects(createdBy).off();
        this.props.firebase.user(uid).off();
        this.props.firebase.userAppts(uid).off();
        this.props.firebase.userCastingSessions(this.state.uid).off();
    }

    _backToStart = () => {
        this.setState({
            name: '',
            repCompany: '',
            project: '',
            projectId: '',
            roles: '',
            projectRole: '',
            projectRolesList: '',
            status: '',
            showRoles: false,
            rolesLoaded: false,
            roleId: '',
            customRole: '',
            customProject: '',
            customProjectShow: false,
            customRoleShow: false,
            suggestionBox: false,
            nameFilter: '',
            suggestName: '',
            autoFilled: false,
            nameConfirmed: false,
            projectConfirmed: false,
            roleConfirmed: false,
            repConfirmed: false,
            currentFocus: 'name',

            apptId: '',
            apptTime: '',

            checkInType: '',
            talentUser: '',

            fadeSelection: false,
        });
    };

    _setUserDetails = (name, rep, id) => {
        this.setState({
            name: name,
            repCompany: rep,
            talentUser: id,
            currentFocus: 'project',
        })
    }

    selectViaName = () => {
        this.setState({
            fadeSelection: true
        });
        var viaNameTimer = setTimeout(() => {
            this.setState({ checkInType: 'name', fadeSelection: false })  
        }, 500)
        return (() => clearTimeout(viaNameTimer));
    };

    selectViaId = () => {
        this.setState({ checkInType: 'aniid' })
    };

    handleChangeRoleSelect = (roleId) => {
        this.setState({
            roleId,
        })
        if (roleId.value === 'not listed') {
            this.setState({
                customRoleShow: true,
                customRole: '',
                checkType: 2,
                roleSelectOpen: false,
            })
        } else {
            this.setState({
                customRoleShow: false,
                customRole: '',
                checkType: 1,
                roleSelectOpen: false,
                roleConfirmed: true,
                currentFocus: 'rep',
            })
            
        }
    }

    handleChangeProjectSelect = (projectId) => {
        const projectRolesArray = this.state.projectRolesArray;
        const arrayLength = projectRolesArray.length;
        const newArray = projectRolesArray.splice(0, arrayLength);

        this.setState({
            projectId,
        })

        if (projectId.value === 'not listed') {
            this.setState({
                roleId: '',
                customRole: '',
                customProject: '',
                customProjectShow: true,
                customRoleShow: true,
                showRoles: false,
                projectRolesArray: newArray,
                checkType: 3,
                rolesLoaded: true,
                inputFocus: 2,
                projectSelectOpen: false,
            })
        } else {
            this.setState({
                customProjectShow: false,
                customRoleShow: false,
                customRole: '',
                customProject: '',
                inputFocus: 0,
                showRoles: true,
                projectRolesArray: newArray,
                checkType: 1,
                roleId: '',
                projectSelectOpen: false,
                roleSelectOpen: true,
                projectConfirmed: true,
                currentFocus: 'role',
            })
            this.props.firebase.viewProjectRoles(projectId.value)
            .orderByChild('isActive')
            .equalTo(true)
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    const projectRolesObject = snapshot.val();

                    const projectRolesList = Object.keys(projectRolesObject).map(key => ({
                            ...projectRolesObject[key],
                            id: key,
                            }));

                    const array = projectRolesArray.splice(0, arrayLength);
                    projectRolesList.map((item, index) => {
                        array.push({
                            value: item.id,
                            label: item.roleName,
                        })
                        this.setState({
                            projectRolesArray: this.state.projectRolesArrayTemplate.concat(array),
                            rolesLoaded: true,
                        })
                    })
                } else {
                    this.setState({
                        showRoles: false,
                        projectRolesArray: [
                        ],
                        customRoleShow: true,
                        inputFocus: 3,
                        rolesLoaded: true,
                        checkType: 2,
                    })
                }
            })

        }
    }

    onChangeInputs = event => {
        this.setState({ [event.target.name]: event.target.value, inputFocus: 1 });
    };

    onChangeNameInput = event => {
        this.setState({ [event.target.name]: event.target.value, inputFocus: 1});
        if (event.target.value.length > 4 && this.state.projectId === '') {
            this.setState({
                projectSelectOpen: true,
            })
        } else {
            this.setState({
                projectSelectOpen: false,
            })
        };
        var nameArray = this.state.scheduleNames ? this.state.scheduleNames.map(i => i.name.toLowerCase()) : '';
        var suggestedNames = nameArray ? nameArray.filter(item => {
            return item.includes(event.target.value.toLowerCase()
        )}) : '';
        if (suggestedNames.length === 1 && event.target.value.length > 4) {
            var lowerName = suggestedNames[0];
            var firstlast = lowerName.split(" ");
            var first = firstlast[0].replace(/^\w/, c => c.toUpperCase());
            var last = firstlast[1] ? firstlast[1].replace(/^\w/, c => c.toUpperCase()) : '';
            var upperName = first + ' ' + last;
            var nameItem = this.state.scheduleNames.filter(f => {
                return f.name.toLowerCase() === lowerName
            })
            this.setState({
                suggestedName: upperName,
                suggestionBox: true,
                autoName: nameItem[0],
            })
        } else if (suggestedNames.length === 0 || event.target.value.length < 4) {
            this.setState({
                suggestedName: '',
                suggestionBox: false,
                autoName: ''
            })
        }
    }

    

    onChangeCustom = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onChangeRepInput = event => {
        this.setState({ [event.target.name]: event.target.value});  
    }



    thanks = () => {
        var timer = null;
        var timer2 = null;
        var timer3 = null;
        var timer4 = null;
        clearTimeout(timer, timer2, timer3, timer4);

        timer = setTimeout(() => {
            this.setState({
                formStatus: false,
            })
            var lockStatus = localStorage.getItem('ANiCheckInLocked') || '';
            this.setState({
            lock: lockStatus
            });
            
        }, 100);

        timer2 = setTimeout(() => {
            this.setState({
                showRoles: false,
                checkedInMessage: true,
                thanksStatus: true,
                name: '',
                repCompany: '',
                repValid: false,
                projectId: '',
                roleId: '',
                checkInType: '',
                talentUser: '',
                repConfirmed: false,
                nameConfirmed: false,
                roleConfirmed: false,
                projectConfirmed: false,
                customRoleShow: false,
                customProjectShow: false,
                customRole: '',
                customProject: '',
                projectArray: this.state.projectArray,
                projectRoles: [],
                projectRolesList: [''],
                projectRolesArray: [],
            })}, 1200);
        
        timer3 = setTimeout(() => {
            this.setState({ 
                thanksStatus: false 
            })}, 6000);

        timer4 = setTimeout(() => {
            this.setState({
                checkedInMessage: false, 
                formStatus: true
            })}, 6500);   

        return () => {
            clearTimeout(timer, timer2, timer3, timer4);
        }
    }
    
    onSubmit = event => {

        event.preventDefault();
        const timestampHours = moment().format('hh:mma');
        const timestampDay = moment().format('MM/DD/YYYY');
        const { name, repCompany, projectId, roleId, myId, apptId, apptTime, talentUser } = this.state;

        var selectedProjectId = projectId.value;
        var projectValue = projectId.label;

        var projectRole = this.state.roleId === '' ? this.state.customRole : roleId.label;
        var selectedRoleId = this.state.roleId === '' ? this.state.customRole + selectedProjectId : roleId.value;


        if (this.state.apptId !== '') {
            this.props.firebase.userAppt(this.state.uid, this.state.apptId)
            .update({
                checkedIn: true,
            })
        }

        this.thanks();

        var avatar = this.state.avatar ? this.state.avatar : NullUser;

        this.props.firebase.checkInQ(name, repCompany, projectValue, projectRole, timestampHours, timestampDay, selectedProjectId, selectedRoleId, myId, apptId, apptTime, talentUser, avatar, this.state.tempId)
        .then(() => {
        this.setState({ 
            timeCheckIn: null,
            checkedIn: false,
            status: '',
            apptId: '',
            autoFilled: false,
            apptTime: '',
        });
        })
        .catch(error => {
            console.log(error);
            this.setState({ error });
        });
    };

    onSubmit2 = event => {
        const timestampHours = moment().format('hh:mma');
        const timestampDay = moment().format('MM/DD/YYYY');
        const createdOn = moment().format('MM/DD/YY hh:mm a');

        const { name, repCompany, myId, customRole, apptId, apptTime, talentUser, avatar} = this.state;

        // FOR CHECK IN
        var projectValue = this.state.projectId.label;
        var selectedProjectId = this.state.projectId.value;
        var projectRole = customRole;

        // FOR NEW ROLE ADD
        var projectId = selectedProjectId;
        var roleName = customRole;
        var project = projectValue;
        var uid = myId;
        var newRoleKey = this.props.firebase.roles().push().key;
        var selectedRoleId = newRoleKey;
        
        this.thanks();

        this.props.firebase.addTempRole(roleName, uid, project, projectId, createdOn, newRoleKey);

        this.props.firebase.checkInQ(name, repCompany, projectValue, projectRole, timestampHours, timestampDay, selectedProjectId, selectedRoleId, myId, apptId, apptTime, talentUser, avatar)
        .then(() => {
        this.setState({ 
            timeCheckIn: null,
            checkedIn: false,
            status: '',
            checkType: 1,
            apptId: '',
            autoFilled: false,
            apptTime: '',
        });
        })
        .catch(error => {
            this.setState({ error });
        });
        event.preventDefault();
    };

    onSubmit3 = event => {
        const timestampHours = moment().format('hh:mma');
        const timestampDay = moment().format('MM/DD/YYYY');
        const createdOn = moment().format('MM/DD/YY hh:mm a');

        const { name, repCompany, myId, customRole, customProject, apptId, apptTime, talentUser, avatar} = this.state;

        var newProjectKey = this.props.firebase.projects().push().key;
        var newRoleKey = this.props.firebase.roles().push().key;

        // FOR CHECK IN
        var projectValue = customProject;
        var selectedProjectId = newProjectKey;
        var selectedRoleId = newRoleKey;
        var projectRole = customRole;

        // FOR ADD TEMP ROLE
        var projectId = newProjectKey;
        var roleName = customRole;
        var project = projectValue;
        var uid = myId;

        // FOR ADD TEMP PROJECT
        var projectName = customProject;

        this.thanks();

        this.props.firebase.addTempProject(projectName, uid, createdOn, newProjectKey);

        this.props.firebase.addTempRole(roleName, uid, project, projectId, createdOn, newRoleKey);

        this.props.firebase.checkInQ(name, repCompany, projectValue, projectRole, timestampHours, timestampDay, selectedProjectId, selectedRoleId, myId, apptId, apptTime, talentUser, avatar)
        .then(() => {
        this.setState({ 
            timeCheckIn: null,
            checkedIn: false,
            status: '',
            checkType: this.state.projects === '' ? 3 : 1,
            apptId: '',
            autoFilled: false,
            apptTime: '',
        });
        })
        .catch(error => {
            this.setState({ error });
        });
        event.preventDefault();
    };

    onSubmit4 = event => {
        alert('Invalid form, please re-enter information');
        event.preventDefault();
    };

    _lockDevice = (event) => {
        if (this.state.sec === '' || !this.state.sec) {
            alert('No sec')
        } else {
            localStorage.setItem('ANiCheckInLocked', true);
            this.setState({
                lock: true,
            })
            event.preventDefault();
            alert('This device has been locked from sensistive pages on the app. To regain access, enter your ANi PIN at the prompt (for testing: 2288)')
        }
    };
    
    renderBtn = (isInvalid, checkType) => {
        return (
        <div className="check-in-btn-container-border">
        {checkType === 1 ?
        <button 
            className="check-in-btn"
            onClick={this.onSubmit}
            disabled={isInvalid}
            >
                Complete check in
        </button>
        :
        checkType === 2 ?
        <button 
            className="check-in-btn"
            onClick={this.onSubmit2}
            disabled={isInvalid}>
                Complete check in
        </button>
        :
        checkType === 3 ?
        <button 
            className="check-in-btn"
            onClick={this.onSubmit3}
            disabled={isInvalid}>
                Complete check in
        </button>
        :
        <button 
            className="check-in-btn"
            onClick={this.onSubmit4}
            disabled={isInvalid}>
                Complete check in
        </button>
        }
        </div>
        )
    }

    _noRep = () => {
        this.setState({
            repCompany: 'None',
            inputFocus: 1,
            repConfirmed: true,
        })
    }

    _showANiIDTip = () => {
        this.setState({
            aniIDTip: true,
        })
        var timer = setTimeout(() => {
            this.setState({
                tipFade: true,
            })
        }, 10);
        clearTimeout(timer);
    };

    _closeANiIDTip = () => {
        this.setState({
            tipFade: false,
        });
        var timer = setTimeout(() => {
            this.setState({
                aniIDTip: false,
            })
        }, 500);
        clearTimeout(timer);
    }

    _autoFill = (item) => {
        this.setState({
            name: item.name,
            projectId: {label: item.projectName, value: item.projectId},
            roleId: {label: item.roleName, value: item.roleId},
            tempId: item.tempId ? item.tempId : '',
        });
        var timer = setTimeout(() => {
            this.setState({
                nameConfirmed: true,
                projectConfirmed: true,
                roleConfirmed: true,
                rolesLoaded: true,
                checkType: 1,
                avatar: item.avatar ? item.avatar : '',
            })
        }, 100)
        
        return (() => clearTimeout(timer));
    }

    _autoForm = () => {
        const projectRolesArray = this.state.projectRolesArray;
        const arrayLength = projectRolesArray.length;
        const newArray = projectRolesArray.splice(0, arrayLength);
        var nameItem = this.state.autoName;
        var hr = Number(nameItem.hr) > 12 ? Number(nameItem.hr) - 12 : Number(nameItem.hr) === 0 ? Number(nameItem.hr) + 12 : Number(nameItem.hr)
        this.setState({
            projectRolesArray: newArray,
            apptTime: hr.toString() + ':' + nameItem.min + nameItem.ampm
        });
        var project = { value: nameItem.projectId, label: nameItem.projectName };
        var role = { value: nameItem.roleId, label: nameItem.roleName };
        this.props.firebase.viewProjectRoles(nameItem.projectId)
        .orderByChild('isActive')
        .equalTo(true)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                const projectRolesObject = snapshot.val();

                const projectRolesList = Object.keys(projectRolesObject).map(key => ({
                        ...projectRolesObject[key],
                        id: key,
                        }));

                const array = projectRolesArray.splice(0, arrayLength);
                projectRolesList.map((item, index) => {
                    array.push({
                        value: item.id,
                        label: item.roleName,
                    })
                    this.setState({
                        projectRolesArray: this.state.projectRolesArrayTemplate.concat(array),
                        rolesLoaded: true,
                        roleId: role,
                    })
                })
            } 
        })
        this.setState({
            projectId: project,
            showRoles: true,
            rolesLoaded: true,
            suggestedName: '',
            autoFilled: true,
            suggestionBox: false,
            nameFilter: '',
            name: nameItem.name,
            apptId: nameItem.id,
        })
    };

    _confirmName = () => {
        this.setState({ nameConfirmed: true, editName: false, currentFocus: 'project', });
    };

    _editName = () => {
        this.setState({ editName: true, currentFocus: 'name' })
    }

    _confirmCustomProject = () => {
        this.setState({ projectConfirmed: true, currentFocus: 'role', })
    };

    _editProject = () => {
        this.setState({ projectConfirmed: false, currentFocus: 'project', roleConfirmed: false, roleId: '', customRole: '', showRoles: false })
    };

    _confirmCustomRole = () => {
        this.setState({ roleConfirmed: true, currentFocus: 'rep', })
    };

    _editRole = () => {
        this.setState({ roleConfirmed: false, currentFocus: 'role', })
    }

    _confirmRep = () => {
        this.setState({ repConfirmed: true, currentFocus: '', })
    };

    _editRep = () => {
        this.setState({ repConfirmed: false, currentFocus: 'rep', })
    };

    _onEnterName = (event) => {
        if (event.keyCode === 13 && event.shiftKey === false) {
            event.preventDefault();
            this.setState({
                nameConfirmed: true,
                currentFocus: 'project',
            })
          }
    }

    _onEnterCustomProject = (event) => {
        if (event.keyCode === 13 && event.shiftKey === false) {
            event.preventDefault();
            this.setState({
                projectConfirmed: true,
                currentFocus: 'role',
            })
          }
    }

    _onEnterCustomRole = (event) => {
        if (event.keyCode === 13 && event.shiftKey === false) {
            event.preventDefault();
            this.setState({
                roleConfirmed: true,
                currentFocus: 'rep',
            })
          }
    }

    _onEnterRep = (event) => {
        if (event.keyCode === 13 && event.shiftKey === false) {
            event.preventDefault();
            this.setState({
                repConfirmed: true
            })
          }
    }

    _selectUnlistedProject = () => {
        var projectId = {value: 'not listed', label: 'not listed'};
        this.handleChangeProjectSelect(projectId);
    };

    _selectUnlistedRole = () => {
        var roleId = {value: 'not listed', label: 'not listed'};
        this.handleChangeRoleSelect(roleId);
    };

    _setNameFromTip = (name) => {
        this.setState({
            name: name,
            nameConfirmed: true,
            checkInType: 'name',
            currentFocus: 'project',
        })
    };

       

    render() {

        const ProjectsList = ({ projects }) => {
            if (this.state.projects !== '') {
                return (
                    <div 
                        className={
                            this.state.nameConfirmed ? 
                            "projects-input-container fadeIn" : 
                            "projects-input-container fadeOut"
                        }
                    >
                        <div className={this.state.currentFocus === 'project' ? "input-label focus-label" : "input-label"}>
                                Which project are you here for?
                        </div>
                        <div>
                            {
                                this.state.projectArray.map((proj, index) => (
                                    <div 
                                        className="check-in-project-option" 
                                        onClick={this.handleChangeProjectSelect.bind(this, proj)}
                                        key={proj.value}
                                    >
                                        <div className="check-in-proj-title">
                                            {proj.label}
                                        </div>
                                    </div>
                                ))
                            }
                            <div className="not-listed-option" onClick={this._selectUnlistedProject}>Not listed?</div>
                        </div>
                    </div>
                    )
            } else {
                this.setState({
                    customProjectShow: true,
                    customRoleShow: true,
                })
            }
        }

        const ProjectRolesList = ({ projectRoles, onChangeInputs, customRole }) => {

            if (this.state.projectRolesArray.length > 0 && this.state.rolesLoaded) {
                return (
                    <div className={
                        this.state.projectConfirmed ? 
                        "projects-input-container fadeInBtn" : 
                        "projects-input-container fadeOut"
                    }
                    > 
                        <div className={this.state.currentFocus === 'role' ? "input-label focus-label" : "input-label"}>
                            Which role are you auditioning for?
                        </div>
                        <div>
                            {
                                this.state.projectRolesArray.map((role, index) => (
                                    <div 
                                        className="check-in-project-option"
                                        onClick={this.handleChangeRoleSelect.bind(this, role)}
                                        key={role.value}
                                    >
                                        <div className="check-in-proj-title">
                                            {role.label}
                                        </div>
                                    </div>
                                ))
                            }
                            <div className="not-listed-option" onClick={this._selectUnlistedRole}>Not listed?</div>
                        </div>
                    </div>
                )
            } else {
                return (
                <div className={this.state.rolesLoaded ? "fadeInBtn" : "fadeOut"}> 

                </div>
                )
            }
        };

        const { projects, checkedInMessage, name, repCompany, projectRoles, formStatus, thanksStatus, checkType, rolesLoaded} = this.state;

        const isInvalid = 
            name === '' ||
            (checkType === 1 && this.state.projectId === '' && this.state.roleId === '') ||
            (checkType === 1 && this.state.projectId !== '' && this.state.roleId === '') ||
            (checkType === 1 && this.state.projectId === '' && this.state.roleId !== '') ||
            (checkType === 2 && this.state.customRole === '') ||
            (checkType === 3 && this.state.customRole === '' && this.state.customProject === '') ||
            (checkType === 3 && this.state.customRole === '' && this.state.customProject !== '') ||
            (checkType === 3 && this.state.customRole !== '' && this.state.customProject === '') ||
            this.state.repCompany === '' ||
            rolesLoaded === false;

        return (
            <div className="check-in-layout">
                <div className="check-in-form-container">
                    <div className="header">
                        <div className="check-in-padding-top"></div>
                        <img className="ani-logo" src={this.state.avatar ? this.state.avatar : NullUser } alt="Company Logo"/>
                    </div>

                    <div className="check-in-form">
                        {
                        checkedInMessage === false ?
                        <div className={formStatus === true ? "fadeIn" : "fadeOutFast"}>
                            {
                                this.state.checkInType === '' ? 
                                <div className={this.state.fadeSelection ? 'fadeOutHeader' : 'fadeInHeader'}>
                                    <div className="check-in-header-text">{this.state.company}</div> 
                                    <div className="check-in-header-sub-text">Audition sign in below</div>
                                </div>
                                :
                                <div />
                            }
                            {
                                this.state.checkInType === '' ? 
                                    this.state.talentSignUp ?
                                        <div>
                                            <button onClick={this.selectViaName} className="check-in-type-btn">Check in via Name</button>
                                            <div className="or-text">OR</div>
                                            <div className="check-in-type-btn-container">
                                                <button onClick={this.selectViaId} className="check-in-type-btn ani-btn">Check in via ANi Account</button>
                                                <img onClick={this._showANiIDTip} src={question} className="question-mark-tip" alt="What is ANi Id?" />
                                            </div>
                                        </div>
                                        :
                                        <div className={this.state.fadeSelection ? "fadeOutHeader" : "fadeInHeader"}>
                                        <button onClick={this.selectViaName} className="check-in-type-btn ani-btn">
                                            Click here to sign in
                                            <img className="sign-in-arrow" src={rightarrow} alt="Arrow" />    
                                        </button>
                                        </div>

                                :

                                this.state.checkInType === 'name' ?
                                <form className="check-in-form-div-container">
                                    <div className="name-input-container">
                                        {
                                            this.state.nameConfirmed && !this.state.editName ?
                                            <div className="input-container-position">
                                                <img 
                                                    className="edit-input-icon"
                                                    alt="Confirm"
                                                    src={graypencil}
                                                    onClick={this._editName}   
                                                />
                                                <div className="input-label">Checking In</div>
                                                <div className="confirmed-text">{this.state.name}</div>
                                                
                                            </div>
                                            :
                                            <div>
                                                <div className={this.state.currentFocus === 'name' ? "input-label focus-label" : "input-label"}>First and Last Name</div>
                                                <input 
                                                    autoFocus
                                                    onFocus={(() => this.setState({ currentFocus: 'name'}))}
                                                    ref={(input) => { this.nameInput = input }}
                                                    className="check-in-input" 
                                                    type="text" 
                                                    name="name" 
                                                    value={name}
                                                    autoComplete="off"
                                                    autoCorrect="off"
                                                    placeholder="Name..."
                                                    onChange={this.onChangeNameInput} 
                                                    onKeyDown={this._onEnterName}
                                                />
                                                <button className="confirm-input-container" onClick={this._confirmName} >
                                                    <img 
                                                        className="confirm-input-icon"
                                                        alt="Confirm"
                                                        src={this.state.name.length > 3 ? checkpink : checkgray}
                                                    />
                                                </button>

                                                <Suggest 
                                                    actorSuggestions={this.state.actorSuggestions} 
                                                    name={name} 
                                                    uid={this.state.uid} 
                                                    firebase={this.props.firebase} 
                                                    _autoFill={this._autoFill}
                                                />
                                            </div>
                                        }

                                        {
                                            this.state.suggestionBox ? 
                                            <div className="no-rep" onClick={this._autoForm}>
                                                {this.state.suggestedName}?
                                            </div>
                                            :
                                            <div />
                                        }
                                    </div>

                                
                                    {
                                        (this.state.projects === '' || this.state.projectId.value === 'not listed') && this.state.nameConfirmed ?
                                        <div className=
                                            {
                                                this.state.nameConfirmed ? 
                                                "projects-input-container fadeIn" : 
                                                "projects-input-container fadeOut"
                                            }
                                        >

                                            {
                                                this.state.projectConfirmed ?
                                                <div className="input-container-position">
                                                    <img 
                                                        className="edit-input-icon"
                                                        alt="Confirm"
                                                        src={graypencil}
                                                        onClick={this._editProject}   
                                                    />
                                                    <div className="input-label">Project</div>
                                                    <div className="confirmed-text">{this.state.customProject}</div>
                                                </div>
                                                :
                                                <div>
                                                    <div className={this.state.currentFocus === 'project' ? "input-label focus-label" : "input-label"}>Enter Project Title</div>
                                                    <input 
                                                        autoFocus
                                                        onFocus={(() => this.setState({ currentFocus: 'project'}))}
                                                        className="check-in-input" 
                                                        type="text" 
                                                        name="customProject" 
                                                        value={this.state.customProject}
                                                        autoComplete="off"
                                                        placeholder="Type project title..."
                                                        onChange={this.onChangeInputs} 
                                                        onKeyDown={this._onEnterCustomProject}
                                                    />
                                                    <button className="confirm-input-container" onClick={this._confirmCustomProject} >
                                                        <img 
                                                            className="confirm-input-icon"
                                                            alt="Confirm"
                                                            src={this.state.customProject.length > 2 ? checkpink : checkgray}
                                                        />
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                        :
                                        this.state.projects !== '' && this.state.nameConfirmed ?
                                            this.state.projectConfirmed ?
                                            <div className="projects-input-container">
                                                <div className="input-container-position">
                                                    <img 
                                                        className="edit-input-icon"
                                                        alt="Confirm"
                                                        src={graypencil}
                                                        onClick={this._editProject}   
                                                    />
                                                    <div className="input-label">Project</div>
                                                    <div className="confirmed-text">{this.state.projectId.label}</div>
                                                </div>
                                            </div>
                                            :
                                            <ProjectsList projects={projects} _editProject={this._editProject} />
                                        :
                                        <div />
                                    }

                                    {
                                        (this.state.customProjectShow || 
                                        this.state.roleId.value === 'not listed' ||
                                        this.state.projectRoles === '') && 
                                        this.state.projectConfirmed ?
                                        <div className=
                                            {
                                                this.state.projectConfirmed ?
                                                "projects-input-container fadeIn" : 
                                                "projects-input-container fadeOut"
                                            }
                                        >
                                            {
                                                this.state.roleConfirmed ?
                                                <div className="projects-input-container">
                                                    <div className="input-container-position">
                                                        <img 
                                                            className="edit-input-icon"
                                                            alt="Confirm"
                                                            src={graypencil}
                                                            onClick={this._editRole}   
                                                        />
                                                        <div className="input-label">Role</div>
                                                        <div className="confirmed-text">{this.state.customRole}</div>
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    <div className="input-label">Enter Role Name</div>
                                                    <input 
                                                        autoFocus
                                                        onFocus={(() => this.setState({ currentFocus: 'role'}))}
                                                        className="check-in-input" 
                                                        type="text" 
                                                        name="customRole" 
                                                        value={this.state.customRole}
                                                        autoComplete="off"
                                                        placeholder="Type role..."
                                                        onChange={this.onChangeInputs} 
                                                        onKeyDown={this._onEnterCustomRole}
                                                    />
                                                    <button className="confirm-input-container" onClick={this._confirmCustomRole} >
                                                        <img 
                                                            className="confirm-input-icon"
                                                            alt="Confirm"
                                                            src={this.state.customRole.length > 2 ? checkpink : checkgray}
                                                        />
                                                    </button>
                                                </div>
                                                }
                                            </div>
                                            :
                                            this.state.projectConfirmed && this.state.projectRoles ?
                                                this.state.roleConfirmed ?
                                                <div className="projects-input-container">
                                                    <div className="input-container-position">
                                                        <img 
                                                            className="edit-input-icon"
                                                            alt="Confirm"
                                                            src={graypencil}
                                                            onClick={this._editRole}   
                                                        />
                                                        <div className="input-label">Role</div>
                                                        <div className="confirmed-text">{this.state.roleId.label}</div>
                                                    </div>
                                                </div>
                                                :
                                            
                                            <ProjectRolesList 
                                                projectRoles={projectRoles} 
                                                rolesLoaded={this.state.rolesLoaded} 
                                                onChangeInputs={this.onChangeInputs} 
                                            />
                                        :
                                        <div />
                                    }
                                    <div className="projects-input-container">
                                        {
                                            this.state.roleConfirmed ? 
                                                <div>
                                                    {
                                                        this.state.repConfirmed ?
                                                        <div className="input-container-position">
                                                            <img 
                                                                className="edit-input-icon"
                                                                alt="Confirm"
                                                                src={graypencil}
                                                                onClick={this._editRep}   
                                                            />
                                                            <div className="input-label">Represented by</div>
                                                            <div className="confirmed-text">{this.state.repCompany}</div>
                                                        </div>
                                                        :
                                                        <div>
                                                            <div className={this.state.currentFocus === 'rep' ? "input-label focus-label" : "input-label"}>Rep Company</div>
                                                            <input 
                                                                ref={(input) => { this.repInput = input }}
                                                                className="check-in-input" 
                                                                type="text" 
                                                                autoFocus
                                                                onFocus={(() => this.setState({ currentFocus: 'rep'}))}
                                                                name="repCompany" 
                                                                value={repCompany}
                                                                autoComplete="off"
                                                                autoCorrect="off"
                                                                placeholder="Type company name..."
                                                                onChange={this.onChangeInputs} 
                                                                onKeyDown={this._onEnterRep}
                                                            />
                                                            <button className="confirm-input-container" onClick={this._confirmRep} >
                                                                <img 
                                                                    className="confirm-input-icon"
                                                                    alt="Confirm"
                                                                    src={this.state.repCompany.length > 2 ? checkpink : checkgray}
                                                                />
                                                            </button>
                                                            {
                                                                this.state.repCompany !== '' ? 
                                                                <div /> : 
                                                                <div className="no-rep" onClick={this._noRep}>No rep?</div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            :
                                            <div />
                                        }
                                    </div>
                                    {
                                        <div 
                                            className={
                                                this.state.repConfirmed ? 
                                                "fadeIn" : 
                                                "fadeOutFast"
                                            }
                                        >
                                        {this.renderBtn(isInvalid, checkType)}
                                        </div>
                                    }
                                </form>
                                :
                                <div>
                                <UserForm 
                                    firebase={this.props.firebase} 
                                    state={this.state}
                                    handleChangeProjectSelect={this.handleChangeProjectSelect}
                                    handleChangeRoleSelect={this.handleChangeRoleSelect}
                                    onChangeInputs={this.onChangeInputs}
                                    renderBtn={this.renderBtn}
                                    isInvalid={isInvalid}
                                    checkType={checkType}
                                    _setUserDetails={this._setUserDetails}
                                    _onEnterCustomProject={this._onEnterCustomProject}
                                    _onEnterCustomRole={this._onEnterCustomRole}
                                    _onEnterName={this._onEnterName}
                                    _editProject={this._editProject}
                                    _editRole={this._editRole}
                                    _confirmName={this._confirmName}
                                    _confirmCustomProject={this._confirmCustomProject}
                                    _confirmCustomRole={this._confirmCustomRole}
                                    _selectUnlistedProject={this._selectUnlistedProject}
                                    _selectUnlistedRole={this._selectUnlistedRole}
                                    graypencil={graypencil}
                                    checkgray={checkgray}
                                    checkpink={checkpink}
                                />
                                </div>
                                }  
                            </div> 
                            :
                            <div className={thanksStatus === true ? "fadeIn" : "fadeOut"}>
                                <div className="check-in-header-text-2">All set!</div> 
                                <div className="talent-greeting">{this.state.greeting ? this.state.greeting : 'We will grab you shortly'}</div>
                            </div>
                        }
                    </div>
                    {
                        this.state.lock === true || this.state.lock === 'true' ?
                        <img src={shield} className="shield-icon" alt="Secure Account"/>
                        :
                        <img src={lock} onClick={this._lockDevice} className="lock-icon" alt="Secure Account"/>
                    }
                    {
                        this.state.checkInType !== '' ?
                        <div className="back-to-start-container" onClick={this._backToStart}>
                        <img src={arrow} className="back-to-start-arrow" alt="Back to start" />
                        <div className="back-to-start-text">Start over</div>
                        </div>
                        :
                        <div />
                    }
                    <div className="ani-logo-container">
                        <img className="ani-icon-footer" src={logo} alt="ANi Logo" />
                    </div>
                    
                </div>
                {
                    this.state.aniIDTip ? 
                    <div className="tip-layout-container">
                        <div className={this.state.tipFade ? "tip-bg tipFadeInBg" : "tip-bg tipFadeOutBg"} onClick={this._closeANiIDTip}></div>
                        <div className={this.state.tipFade ? "fadeInFast" : "fadeOutFast"}>
                            <ANiIDTip 
                                tipFade={this.state.tipFade} 
                                _closeANiIDTip={this._closeANiIDTip}
                                _toggleTip={this._toggleTip} 
                                checkpink={checkpink}
                                checkgray={checkgray}
                                company={this.state.company}
                                uid={this.state.uid}
                                firebase={this.props.firebase}
                                _setNameFromTip={this._setNameFromTip}
                            />
                        </div>
                    </div>
                    :
                    <div />
                }
            </div>
            )
        }
    };

const style={
    container: {
        flex: 1,
        marginBottom: 50,
    },
    background: {
        backgroundColor: 'orange',
        height: 600,
        padding: 20,
      },
    card: {
        width: 500,
        height: 500,
        marginTop: 20,
        alignItems: 'center',
        paddingTop: 25,
        borderRadius: 20,
    },
    input: {
        width: 330,
        height: 50,
        paddingLeft: -1,
        marginTop: 0,
        backgroundColor: '#f8f9fa',
        border: 2,
        borderColor: '#f8fefe',
        fontSize: 19,
        fontWeight: '500',
        fontColor: '#525A5F'
    },
    button: {
        width: 320,
        height: 50,
        marginTop: 10,
    }
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(CheckInPage);

const CheckInForm = compose(
    withRouter,
    withFirebase,
    withAuthentication
    )(CheckInFormBase);


export { CheckInForm };
