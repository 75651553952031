import React, { useState } from 'react';

import '../overview.css';

export default function SessionOverviewSignIn(props) {

    var timer;

    const [ani, setAni] = useState(false);
    const [code, setCode] = useState('');
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [fade, setFade] = useState(false);
    const [secondFade, setSecondFade] = useState(false);

    const _onEnterCode = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            this._submitCode(e);
        }
    }

    const _submitCode = () => {

    }

    const _signIn = () => {
        
    }

    const _checkAccess = () => {
        clearTimeout(timer);
        if (code === '000000') {
            setFade(true);
            timer = null;
            clearTimeout(timer);
            timer = setTimeout(() => {
                setSecondFade(true)
            }, 500)
            clearTimeout(timer)
        };
    }

    return (
        <div className="background-container">
            <div className={!secondFade ? "orange-background" : "orange-background fadeOutFast"} />
            <div className={!secondFade ? "shift-to-gray inactive-shift" : "shift-to-gray"} />
            <div className={fade ? "check-in-form-container fadeOut" : "check-in-form-container"}>
                <div className="padding-20">
                {
                    !ani ?
                    <div className="please-enter-code-container">
                        <div className="static-text">Please enter the code you received in the email, or sign in using your ANi Account</div>
                        <div className="height-30" />
                        <input 
                            className="activation-code div-center" 
                            placeholder="------" 
                            onChange={(e) => setCode(e.target.value)}
                            autoFocus
                            value={code}
                            onKeyDown={_onEnterCode}    
                        />
                        <div className="height-10" />
                        <button className="with-ani-btn div-center width-200" onClick={_checkAccess}>
                            Submit
                        </button>
                        <div className="height-40" />
                        <div className="sign-in-with-ani">
                            <button className="ani-btn with-ani-btn div-center width-200" onClick={() => setAni(true)}>
                                Sign in with ANi
                            </button>
                        </div>
                    </div>
                    :
                    <div className="sign-in-with-ani">
                        <div className="sign-in-input-section div-center">
                            <div className="sign-in-label">
                                Enter account email
                            </div>
                            <input 
                                className="login-input"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="sign-in-input-section div-center">
                            <div className="sign-in-label">
                                Enter password
                            </div>
                            <input 
                                className="login-input"
                                value={pass}
                                onChange={(e) => setPass(e.target.value)}
                            />
                        </div>
                        <div className="height-20" />
                        <div className="sign-in-with-ani">
                            <button className="ani-btn with-ani-btn div-center width-300" onClick={() => setAni(true)}>
                                Sign in
                            </button>
                        </div>
                    </div>
                }
            </div>
            </div>
        </div>
    )
}
