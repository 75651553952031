import React from 'react';

const CreateRPS = () => {

    return (

        <div className="topic">
        <div className="topic-header">Managing rooms, projects and roles</div>
        <div className="topic-description">
            All of these can be created and managed on your Account page, which is located on your navigation bar.
            <br />
            <br />
            <div className="topic-sub-header">ROOMS</div>
            <b>The rooms section</b> will list your rooms, whether or not they are active, and how many projects and roles are currently assigned to the room.
            <br />
            <br />
            <b>To add a room</b>, type in the room's name at the top of the room section and press enter. 
            <br />
            <br />
            <b>To activate the room</b>, press the grey bar to the left of the room's name so it turns green. Once the room is activated, you can view it on your dashboard.
            <br />
            <br />
            <b>To deactivate the room</b>, click on the green bar so it turns back to grey. Please note that deactivating a room will also clear the room of the employees, projects and roles that are assigned to it. 
            <br />
            <br />
            <b>To delete a room</b>, click on the trashcan to the right of the room's name. 
            <br />
            <br />
            <div className="topic-sub-header">PROJECTS</div>
            <b>The projects section</b> will list your projects, whether or not they are active, and how many roles are currently active on the project.
            <br />
            <br />
            <b>To add a project</b>, type in the project's title at the top of the project section and press enter. 
            <br />
            <br />
            <b>To activate the project</b>, press the grey bar to the left of the project's title so it turns green. When a project is active it will appear as an option on the actor's check in form. See <b>Check In Form</b>.
            <br />
            <br />
            <b>To deactivate the project</b>, click on the green bar so it turns back to grey. The project will not show up on the check in form when it is deactivated. Please note that deactivating a project will also deactivate all of the roles of the project as well.
            <br />
            <br />
            <b>To delete a project</b>, click on the project's gear icon to open up the project's settings page, and click on the trashcan on the top right of the section.
            Please note that once a project is deleted, it will also delete the roles of the project as well, and will remove the project from each room it is assigned to. All of the actors who have previously read for the project will still be accessible.
            <br />
            <br />
            <div className="topic-sub-header">ROLES</div>
            <b>The roles section</b> is accessed by clicking on the gear icon of a project in the projects section. This will list the project's roles and whether or not they are active.
            <br />
            <br />
            <b>To add a role</b>, type in the role's name at the top of the project role's section and press enter. 
            <br />
            <br />
            <b>To activate the role</b>, press the grey bar to the left of the role's name so it turns green. When a role is active it will appear as an option on the actor's check in form. See <b>Check In Form</b>.
            <br />
            <br />
            <b>To deactivate the role</b>, click on the green bar so it turns back to grey. The role will not show up on the check in form when it is deactivated.
            <br />
            <br />
            <b>To delete a role</b>, click on the trashcan to the right of the role's name. Please note that once a role is deleted, it will remove the role from each room it is assigned to. All of the actors who have previously read for the role will still be accessible.
            <br />
            <br />
        </div>      
        </div>
    )
}

export { CreateRPS };