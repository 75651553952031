import React from 'react'

const TalentJobFeed = (props) => {

        return (
            <div className="tal-dash-jobs-container">
                <div className="tal-dash-section-label">Opportunity Feed</div>
                {
                    props.state.jobs ?
                    props.state.jobs.map((job, index) => (
                        <div key={job.id} className="job-feed-item">
                            <div className="job-header">
                                <div className="job-company">Released by {job.company} at {job.time}</div>
                                <div className="job-role">{job.role}</div>
                                <div className="job-project">{job.project}</div>
                            </div>
                            <div className="job-body">
                                <div className="job-label">Character Details</div>
                                <div className="job-gender">{job.gender}</div>
                                {
                                    job.fluid ? 
                                    <div className="job-fluid-container">

                                    </div>
                                    :
                                    <div />
                                }
                                <div className="job-age-range">
                                    Character plays between {job.min} to {job.max}
                                </div>
                            </div>
                            <div className="job-footer">
                                <button className="job-submission-btn">Submit</button>
                            </div>
                        </div>
                    ))
                    :
                    <div>No new opportunities</div>
                }
            </div>
        )
    }

export { TalentJobFeed };


