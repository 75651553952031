import React from 'react';

const EditAppt = (props) => {

    return (
        <div className="edit-appt-popover">
            <div className="pop-over-label">Status </div>
            <div className="status-radio">
                <button onClick={props._toConfirmed} className="appt-icon-btn"> <img 
                src={props.confirmedicon} 
                className={props.state.newStatus === 'confirmed' ? "status-select-icon" : "status-select-icon unselected"}
                alt="Set to confirmed"
                />
                </button>
                <button onClick={props._toPending} className="appt-icon-btn">
                <img 
                src={props.pendingicon} 
                onClick={props._toPending} 
                className={props.state.newStatus === 'pending' ? "status-select-icon" : "status-select-icon unselected"}
                alt="Set to pending"
                />
                </button>
                <button onClick={props._toReschedule} className="appt-icon-btn">
                <img 
                src={props.rescheduleicon} 
                className={props.state.newStatus === 'reschedule' ? "status-select-icon" : "status-select-icon unselected"}
                alt="Set to reschedule"
                />
                </button>
            </div>

            <div className="pop-over-label">Date</div>
            <div className="date-edit-inputs" onClick={props._openDateEdit}>
                <input className="date-edit-input" name="newMonth" value={props.state.newMonth} onChange={props._onChange.bind(this)} />
                <div>/</div>
                <input className="date-edit-input" name="newDay" value={props.state.newDay} onChange={props._onChange.bind(this)} />
                <div>/</div>
                <input className="date-edit-input" name="newYear" value={props.state.newYear} onChange={props._onChange.bind(this)} />
            </div>
            <div className="pop-over-label">Time</div>
            <div className="time-edit-inputs">
                <input className="time-edit-hm" value={props.state.adjustedHr} name="adjustedHr" onChange={props._onChangeHr.bind(this)}/>
                <div>:</div>
                <input className="time-edit-hm" value={props.state.newMin} name="newMin" onChange={props._onChange.bind(this)}/>
                <div>:</div>
                <select className="time-edit-ampm" value={props.state.newAmpm} name="newAmpm" onChange={props._onChange.bind(this)}>
                    <option value="am">am</option>
                    <option value="pm">pm</option>
                </select>
            </div>
            <button 
                className={props.disabled ? "btn-appt-edit save" : "btn-appt-edit save disabled"}
                disabled={props.disabled ? false : true}
                onClick={props._saveApptChanges}
                >
                Save Edits
            </button>
            <button className="btn-appt-edit cancel" onClick={props._cancelApptChanges}>Cancel Edits</button>
        </div>
    )

}

export { EditAppt };