import React, { Component } from 'react';

import ProfileImageUpload from './Slot/ProfileImageUpload';

import moment from 'moment';

import rubber from '../../../../../assets/rubber.png';
import share from '../../../../../assets/share.png';
import { NullUser } from '../../../../../assets/nulluser';
import envelope from '../../../../../assets/envelope.png';
import swap from '../../../../../assets/swap.png';

export default class SlotItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            nameEdit: false,
            newTalentName: '',
            share: false,
            email: '',
            arrowIndex: '',
        };
    };
    
    componentWillReceiveProps(nextProps, props) {
        if (nextProps.state.activeSharePopup !== this.props.state.activeSharePopup) {
            if (nextProps.state.activeSharePopup !== this.props.index) {
                this.setState({
                    share: false
                })
            };
        };
    }

    componentDidMount() {
        if (this.props.slot.inviteKey) {
            this.props.firebase.selectApptInviteResponse(this.props.slot.inviteKey)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    this.setState({
                        accepted: o.accepted,
                        reschedule: o.reschedule,
                        dismiss: o.dismiss,
                    })
                };
            })
        };

        if (this.props.state.sessionRoles.length === 1) {
            this.props.firebase.updateSessionSlotRole(this.props.uid, this.props.state.sessKey, this.props.index, this.props.state.sessionRoles[0].name, this.props.state.sessionRoles[0].id, this.props.state.groupKey);
        };
    }

    componentWillUnmount() {
        if (this.props.slot.inviteKey) {
        this.props.firebase.selectApptInviteResponse(this.props.slot.inviteKey).off()
        }
    }

    _onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    };

    _onSearchTalent = (e) => {
        this.setState({ 
            [e.target.name]: e.target.value,
            arrowIndex: ''
        });
        if (e.target.value.length > 2) {
            var filteredTalent = this.state.talent.filter(f => {
                return f.name.includes(e.target.value);
            })
            var sortedTalent = filteredTalent.sort((a, b) => (a.name > b.name) ? 1 : -1);
            this.setState({
                filteredTalent: sortedTalent.filter((f, index) => { return index < 8})
            });
        }
    };

    _enableSearch = () => {
        this.setState({
            searchActive: true,
        });
        this.props.firebase.talentSearch()
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var talent = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key
                }));
                this.setState({
                    talent: talent,
                });
            };
        });
    };

    _disableSearch = () => {
        this.setState({
            searchActive: false,
        })
    };

    _onSelect = (e) => {
        var filter = this.props.state.sessionRoles.filter(f => {
            return f.id === e.target.value;
        });
        if (e.target.value !== 'select role') {
            this.props.firebase.updateSessionSlotRole(this.props.uid, this.props.state.sessKey, this.props.index, filter[0].name, filter[0].id);
            this.setState({
                role: e.target.value,
            })
        } else {
            this.props.firebase.updateSessionSlotRole(this.props.uid, this.props.state.sessKey, this.props.index, '', '');
            this.setState({
                role: e.target.value,
            })
        }
    }

    _onEnterNewTalent = (event) => {
        if (event.keyCode === 13 && event.shiftKey === false) {
            if (this.state.arrowIndex !== '') {
                var user = this.state.filteredTalent[this.state.arrowIndex];
                this._adjustTalentSlotUser(user);
                this.setState({
                    arrowIndex: '',
                })
            } else {
                this.setState({
                    arrowIndex: ''
                });
                event.preventDefault();
                this._adjustTalentSlot(event);
            }
        }
        if (event.keyCode === 40) {
            if (this.state.filteredTalent && this.state.arrowIndex === this.state.filteredTalent.length) {
                this.setState({
                    arrowIndex: this.state.arrowIndex
                })
            } else if (this.state.arrowIndex === '') {
                this.setState({
                    arrowIndex: 0
                })
            } else {
            this.setState({
                arrowIndex: this.state.arrowIndex + 1,
            })
        }
        } else if (event.keyCode === 38) { 
            if (this.state.arrowIndex === 0) {
                this.setState({
                    arrowIndex: ''
                })
            } else {
                this.setState({
                    arrowIndex: this.state.arrowIndex - 1,
                })
            }
        }
    };

    _setArrowIndex = (index) => {
        if (this.state.arrowIndex !== index) {
            this.setState({
                arrowIndex: index
            })
        }
    }

    _adjustTalentSlot = (event) => {
       this.props.firebase.updateSlotSession(this.props.uid, this.props.state.sessKey, this.props.index, this.state.newTalentName, this.props.state.groupKey);
    }

    _adjustTalentSlotUser = (user) => {
        this.props.firebase.updateSlotSessionUser(this.props.uid, this.props.state.sessKey, this.props.index, user, this.props.state.groupKey)
    };

    _addFromTalentSuggest = (user, index) => {
        this.props.firebase.updateSlotSessionUser(this.props.uid, this.props.state.sessKey, this.props.index, user, this.props.state.groupKey)
    };

    _clearSessionSlot = () => {
        this.props.firebase.clearSlotSession(this.props.uid, this.props.state.sessKey, this.props.index, this.props.state.groupKey);
    }

    _openShare = () => {
        this.setState({
            share: true,
        })
        this.props._currentShareModal(this.props.index);
    }

    _shareNotAvailable = () => {
        alert('Scheduling is currently in maintenance')
    }

    _submitUserInvite = () => {
        var state = this.props.state;
        var date = moment(state.sessionDate).format('MM/DD/YYYY');
        var ref = this.props.slot.ref;
        this.props.firebase.talentESearch(this.props.slot.ref)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var e = o.email;
                this.props.firebase.newCastingSessionInvite(
                    this.props.uid, 
                    e,
                    state.sessionProject.label,
                    state.sessionProject.value, 
                    this.props.slot.role, 
                    this.props.slot.roleId, 
                    date,
                    this.props.slot.time,
                    this.props.slot.name,
                    this.props.state.sessKey,
                    'Location',
                    this.props.index,
                    ref,
                    this.props.state.groupKey
                );
                this.setState({
                    share: false,
                    email: ''
                });
                this.props.firebase.updateInviteStatus(this.props.uid, this.props.state.sessKey, this.props.index, true);
            };
        });
    }

    _submitInvite = () => {
        var state = this.props.state;
        var ref = '';
        var date = moment(state.sessionDate).format('MM/DD/YYYY');
        this.props.firebase.newCastingSessionInvite(
            this.props.uid, 
            this.state.email,
            state.sessionProject.label,
            state.sessionProject.value, 
            this.props.slot.role, 
            this.props.slot.roleId, 
            date,
            this.props.slot.time,
            this.props.slot.name,
            this.props.state.sessKey,
            'Location',
            this.props.index,
            ref,
            this.props.state.groupKey
        );
        this.setState({
            share: false,
            email: ''
        });
        this.props.firebase.updateInviteStatus(this.props.uid, this.props.state.sessKey, this.props.index, true);
    };

    _prepHoverOver = () => {
        this.props._hoverIndex(this.props.index);
    };

    _prepHoverOut = () => {
        this.props._hoverOut();
    };

    _handleProfPicUpload = (e) => { 
        if (e.target.files[0]) {
            const image = e.target.files[0];
            this.props.firebase.uploadSlotAvatar(image, this.props.uid, this.props.state.sessKey, this.props.index, this.props.slot.name, this.props.slot.tempId, this.props.state.groupKey);
        };
    };

    render() {

        var slot = this.props.slot;

        return (
            <div className={this.state.share ? "tal-sche-slot-item highlight-slot-item" : "tal-sche-slot-item"}>
                <div className="tal-sch-slot-time">{slot.time}</div>
               
                <div>
                    {   
                        slot.name ? 
                        <div>
                        {slot.avatar && slot.ref ? 
                            <div className="tal-sch-slot-user-container">
                                <img src={slot.avatar} className="tal-sche-slot-avatar" alt="Headshot" /> 
                                <div onClick={(() => this.setState({ nameEdit: true }))} className="tal-sche-slot-name">{slot.name}</div> 
                            </div>
                            :  
                            <div className="tal-sch-slot-user-container">
                                <ProfileImageUpload avatar={slot.avatar ? slot.avatar : NullUser } _handleProfPicUpload={this._handleProfPicUpload} />  
                                <div onClick={(() => this.setState({ nameEdit: true }))} className="tal-sche-slot-name">{slot.name}</div> 
                            </div>
                            }
                       
                        </div>
                        : 
                        <div>
                            <input 
                                className="tal-sche-slot-input nav-slot-input" 
                                placeholder="Add talent" name="newTalentName" 
                                value={this.state.newTalentName} 
                                onFocus={this._enableSearch} 
                                onChange={this._onSearchTalent.bind(this)} 
                                onKeyDown={this._onEnterNewTalent} 
                                autoComplete="off"
                            />
                            {
                                this.state.searchActive && this.state.newTalentName.length > 2 ?
                                    <div className="talent-search-box-nav-schedule">
                                        {
                                            this.state.filteredTalent.length > 0 ? 
                                            this.state.filteredTalent.map((item, index) => (
                                            <div 
                                                key={item.id} 
                                                className={
                                                    index === this.state.filteredTalent.length - 1 && this.state.arrowIndex === index ? "talent-suggestion-nav-schedule no-border-bottom hovered-item" : 
                                                    index === this.state.filteredTalent.length - 1 && this.state.arrowIndex !== index ? "talent-suggestion-nav-schedule no-border-bottom" :
                                                    index !== this.state.filteredTalent.length - 1 && this.state.arrowIndex === index ? "talent-suggestion-nav-schedule hovered-item" :
                                                    "talent-suggestion-nav-schedule"
                                                }
                                                onMouseOver={this._setArrowIndex.bind(this, index)}
                                                onClick={this._addFromTalentSuggest.bind(this, item, index)}
                                                >
                                                <div className="talent-avatar-nav-schedule"><img src={item.avatar ? item.avatar : NullUser} className="talent-avatar-img-nav-schedule" alt="Headshot" /></div>
                                                <div>
                                                    <div className="talent-name-nav-schedule">{item.name}</div>
                                                    <div className="talent-rep-nav-schedule">{item.rep}</div>
                                                </div>
                                            </div>
                                            ))
                                            :
                                            <div className="centered-text font-size-13 opacity-0-5">No match</div>
                                        }
                                </div>
                                :
                                <div />
                            }
                        </div>
                    }
                </div>
                {this.props.slot.name ?
                    slot.invite ?
                    <div className="tal-sche-slot-name">{slot.role}</div>
                    :
                    this.props.state.sessionRoles.length > 1 ?
                    <select 
                        className="tal-sch-slot-role nav-select-slot" 
                        defaultValue={this.props.slot.roleId ? this.props.slot.roleId : 'select role'}
                        name="role"
                        onChange={this._onSelect.bind(this)}
                    >
                        <option value='select role'>Select role</option>
                        {this.props.state.sessionRoles ? this.props.state.sessionRoles.map((role, index) => (
                            <option key={role.id} value={role.id}>{role.name}</option>
                        ))
                        :
                        null
                    }
                    </select>
                    :
                    <div className="tal-sche-slot-name">{this.props.state.sessionRoles[0].name}</div>
                    :
                    <div />
                }
                {this.props.swapActive && this.props.swap.name !== slot.name ? 
                <div className="swap-item-show" onClick={this.props.selectSwap.bind(this, slot, this.props.index)}>Click to swap</div>
                :
                null    
                }
                {
                slot.invite ?
                    this.state.accepted ?
                    <div>Con</div>
                    :
                    this.state.reschedule ?
                    <div>Res</div>
                    :
                    this.state.dismiss ?
                    <div>Dis</div>
                    :
                    <img className="erase-slot-icon envelope-icon-nav" src={envelope} alt="Email Sent" />
                    :
                    this.props.slot.name && this.props.slot.roleId ? <img className="erase-slot-icon" src={share} alt="Share Appt" onClick={this._shareNotAvailable}/> : <div />
                    }
                    {this.props.slot.name && !slot.invite ? <img className="erase-slot-icon" src={rubber} alt="Clear slot" onClick={this._clearSessionSlot} /> : <div />}
                    {this.props.slot.name && !slot.invite ? <img className="erase-slot-icon" src={swap} alt="Swap Slot" onClick={this.props.setSwapSlot.bind(this, slot, this.props.index)} /> : <div />}
                    {
                        this.state.share ? 
                        <div>
                            <div 
                                className="invite-popup" 
                                onMouseOver={this._prepHoverOver}
                                onMouseLeave={this._prepHoverOut}
                            > 
                                {
                                    this.props.slot.ref ? 
                                    <div className="invite-via-ani-popup">
                                        <div className="invite-popup-text">Invite via ANi:</div>
                                        <button className="invite-via-ani-btn" onClick={this._submitUserInvite}>Send to Actor's Account</button>
                                    </div>
                                    :
                                    <div />
                                }
                                <div className="invite-popup-text">Invite via Email: </div>
                                <input className="invite-popup-input" value={this.state.email} autoComplete="off" onChange={this._onChange.bind(this)} name="email" placeholder="Email address"/>
                                <button className="share-invite-btn" onClick={this._submitInvite}><img className="erase-slot-icon share-popup-icon" src={share} alt="Share Appt"/></button>
                            </div>
                        </div>
                        :
                        <div />
                }
            </div>
        )
    };
};