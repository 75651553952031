import React, { Component } from 'react';

import { withAuthorization } from '../Session';

import TempNav from './TempNav';
import TempHome from './TempHome';

class TempDashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {

    this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
            if (authUser) {
                this.setState({
                    uid: authUser.uid,
                    account: 'temp',
                })
                this.props.firebase.tempUser(authUser.uid)
                .once('value', snapshot => {
                    if (snapshot.exists()) {
                        var o = snapshot.val();
                        this.setState({
                            userData: o,
                        })
                        console.log(o);
                    }
                })
            } else {
                this.setState({ authUser: null });
            }
        })
    }

    componentWillUnmount() {
        this.props.firebase.tempUser(this.state.uid).off();
    }

    render() {
        return (
            <div className="background-page">
                <div className="temp-dash-layout">
                    <div className="temp-dash-left">
                        <TempHome uid={this.state.uid} firebase={this.props.firebase} userData={this.state.userData} account={this.state.account} />
                    </div>
                    <div className="temp-dash-right">
                        <TempNav uid={this.state.uid} firebase={this.props.firebase} userData={this.state.userData} account={this.state.account} />
                    </div>
                </div>
            </div>
        )
    }
}


const condition = authUser => !!authUser;

export default withAuthorization(condition)(TempDashboard);