import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../Firebase';
import { withAuthorization, withAuthentication } from '../../Session';
import * as ROUTES from '../../../constants/routes';
import { compose } from 'recompose';
import moment from 'moment';
import line from '../../../assets/line-white.png';

import SessionFocus from '../../Focus/SessionFocus';

function SessionGuest(props) {

    const [uid, setUid] = useState('');
    const [id, setId] = useState('');
    const [userdata, setUserdata] = useState('');
    const [access, setAccess] = useState('');


    useEffect(() => {
        var URL = window.location.pathname;
        var sess = URL.replace('/guest/session/', '');
        setId(sess);
        props.firebase.auth.onAuthStateChanged(
            authUser => {
            if (authUser) {
                setUid(authUser.uid);
                props.firebase.apiUser(authUser.uid)
                .on('value', snapshot => {
                    if (snapshot.exists()) {
                        var o = snapshot.val();
                        setUserdata(o);
                        props.firebase.apiSession(sess)
                        .child('guests')
                        .orderByChild('email')
                        .equalTo((o.email))
                        .on('value', snapshot => {
                            if (snapshot.exists()) {
                                setAccess(true);
                            } else {
                                setAccess(false);
                            }
                        });
                    };
                })
            }
        })
    }, [])

    const [mobile, setMobile] = useState(false);

    useEffect(() => {
        var width = window.innerWidth;
        if (width < 560) {
            setMobile(true);
        }
    }, [])

    const [sessionData, setSessionData] = useState('');
    const [showUsers, setShowUsers] = useState(false);

    useEffect(() => {
        if (access) {
            props.firebase.apiSession(id)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    setSessionData(snapshot.val());
                } else {
                    setSessionData('');
                }
            })
        }
    }, [access])

    return (
        !access ?
        <div>No Access</div>
        :
        <div className={mobile ? "s-page-layout-mobile color-bg" : "s-page-layout color-bg"}>
            {mobile ? <MobileNav sessionData={sessionData} /> : <GuestNav sessionData={sessionData} />}
            <GuestContent showUsers={showUsers} setShowUsers={setShowUsers} mobile={mobile} subjects={sessionData.subjects} focus={sessionData.focus} firebase={props.firebase} userdata={userdata} id={id} uid={uid} />
        </div>
    )
}

function GuestNav(props) {

    const [open, setOpen] = useState(true);

    return (
        <div className={open ? "s-page-nav nav-color s-nav-ext" : "s-page-nav nav-color"} onClick={() => setOpen(!open)}>
            {open ? <div className="acct-settings-back-btn">Back to Dashboard</div> : null}

        </div>
    )
}

function MobileNav(props) {

    const [open, setOpen] = useState('false');

    return (
        <div className={open ? "s-page-nav-mobile nav-color s-nav-ext-mobile" : "s-page-nav-mobile nav-color"}>

        </div>
    )
}



function GuestContent(props) {

    const [scrollIndex, setScrollIndex] = useState(0);
    const [focus, setFocus] = useState('');
    const [myFocus, setMyFocus] = useState('');
    const [auto, setAuto] = useState(true);
    const [length, setLength] = useState('');

    function prepAuto(auto) {
        if (auto) {
            setAuto(false);
        } else {
            setAuto(true);
            pushScroll(length)
        }
    }

    useEffect(() => {
        if (props.focus) {
            setFocus(props.focus);
            if (auto) {
                setMyFocus(props.focus);
            };
        } else {
            setFocus('');
        }
    }, [props.focus]);

    const [listLength, setListLength] = useState('');

    useEffect(() => {
        if (props.subjects) {
            var o = Object.keys(props.subjects);
            setListLength(o.length);
            if ((o.length - 1) !== length && auto) {
                setLength(o.length - 1);
                setScrollIndex(o.length - 1);
                document.getElementById('scroll-selector').scrollLeft = (o.length - 1) * window.innerWidth * .8;

            }
        }
    }, [props.subjects]);

    function prepScrollIndex(index) {

        document.getElementById('scroll-selector').scrollLeft = index * window.innerWidth * .8;

        setScrollIndex(index);
    }

    function pushScroll(index) {
        document.getElementById('scroll-selector').scrollLeft = index * window.innerWidth * .8;

    }

    const [magnify, setMagnify] = useState('');
    const [hold, setHold] = useState(false);

    function unFocusMobileCard() {
        setMagnify('');
        setHold(false);
        setAuto(true);
    }

    function focusThisMobileCard(index) {
        var mag = magnify;
        setMagnify(index);
        setAuto(false);
        document.getElementById('scroll-selector').scrollLeft = index * window.innerWidth * .8;
        var scrollStop = setTimeout(() => {
            setHold(true);
        }, 200);
        return (() => clearTimeout(scrollStop));
    };

    return (
        <div id="scroll-selector" className={
                magnify === 0 ?
                "subject-scroll no-padding-left pause-scroll no-scroll-bars" :
                magnify === listLength - 1 ?
                "subject-scroll pause-scroll no-scroll-bars" :
                hold && props.mobile ? 
                "subject-scroll pause-scroll no-scroll-bars" : "subject-scroll no-scroll-bars"}
            >
                {magnify ? null : <div className="guest-nav-container">
                    <button className="guest-nav-button" onClick={props.showUsers ? null : () => props.setShowUsers(true)}>
                        <img className="line-button-img" src={line} alt="Line" />
                    </button>
                </div>}
            
            <div className={magnify ? "subject-holder full-screen" : "subject-holder"}>
            {
                props.subjects ?
                Object.keys(props.subjects).map(key => ({
                    ...props.subjects[key],
                    id: key,
                })).sort((a, b) => moment(a.track.call, 'MM/DD/YYYY hh:mm:ss a').isBefore(moment(b.track.call, 'MM/DD/YYYY hh:mm:ss a')) ? -1 : 1)
                .map((item, index) => (
                <SessionFocus 
                    username={props.userdata.name} 
                    id={props.id} 
                    cid={props.cid} 
                    uid={props.uid}
                    index={index}
                    firebase={props.firebase} 
                    subjects={props.subjects}
                    focus={focus} 
                    myFocus={myFocus}
                    prepAuto={prepAuto}
                    auto={auto}
                    item={item}
                    key={item.id} 
                    scrollIndex={scrollIndex}
                    prepScrollIndex={prepScrollIndex}
                    usertype={'Guest'}
                    mobile={props.mobile}
                    magnify={magnify}
                    focusThisMobileCard={focusThisMobileCard}
                    unFocusMobileCard={unFocusMobileCard}
                />
                )) : null
                }
            </div>

            {
                props.showUsers ? 
                <GuestNavigator magnify={magnify} subjects={props.subjects} 
                prepScrollIndex={prepScrollIndex} setShowUsers={props.setShowUsers} /> : null
            }
        </div>
    )
}

function GuestNavigator(props) {

    const [fadeIn, setFadeIn] = useState('');

    useEffect(() => {
        var fadein = setTimeout(() => {
            setFadeIn(true);
        }, 200);
        return (() => clearTimeout(fadein));
    })

    function prepFade() {
        setFadeIn(false)
        var fadeOut = setTimeout(() => {
        props.setShowUsers(false);
        }, 200);
        return (() => clearTimeout(fadeOut));
    }

    return (
        <div className={fadeIn ? 'fade-in' : 'fade-out'}>
        <div className="guest-navigator-background">
            {
                props.subjects ?
                Object.keys(props.subjects).map(key => ({
                    ...props.subjects[key],
                    id: key,
                })).sort((a, b) => moment(a.track.call, 'MM/DD/YYYY hh:mm:ss a').isBefore(moment(b.track.call, 'MM/DD/YYYY hh:mm:ss a')) ? -1 : 1)
                .map((item, index) => (
                    <div className="guest-nav-subject" onClick={() => props.prepScrollIndex(index)}>
                        {item.name}
                    </div>
                ))
                :
                null
            }

        </div>
        {props.magnify ? null : <div className="guest-nav-container">
            <button className="guest-nav-button" onClick={() => prepFade()}>
                <img className="line-button-img" src={line} alt="Line" />
            </button>
        </div>}
        </div>
    )
}


const condition = authUser => !!authUser;

const SessionCompose = compose(
    withFirebase,
    withRouter,
    withAuthentication
    )(SessionGuest);

export default withAuthorization(condition)(SessionGuest);