import React, { Component } from 'react';

import { NullUser } from '../../assets/nulluser';

export default class ImageUpload extends Component {

    constructor(props) {
        super(props);

        this.state = {
            avatar: '',
            isUploading: false,
            progress: 0,
            url: "",
            loading: true,
        };
    }

    componentDidMount() {
        this.setState({
            loading: false,
        })
    }

    render() {

        return (
        this.state.loading ?
        <div></div>
        :
        <div className="pic-section">
            <img src={this.props.avatar ? this.props.avatar : NullUser} alt="Avatar" className="profile-pic" />
            <label className="change-pic-btn">
                Change Picture
            <input 
                hidden
                type="file"
                name="avatar"
                onChange={this.props._handleImageUpload}
            />
            </label>
        </div>
        )
    }   
}
