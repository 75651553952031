import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

import line from '../../assets/line-white.png';
import dash from '../../assets/dash-white.png';
import check from '../../assets/check-white.png';

export default function MobileNavBar(props) {

    const [page, setPage] = useState('checkins');

    const _setPage = (value) => {
        setPage(value);
        props._setMobileState(value);
    }

    return (
        <div className="mobile-nav-layout"> 
            <img 
                className={
                    page === 'checkins' ?
                    "mobile-nav-icon sel-mob-ic" :
                    "mobile-nav-icon"
                }
                src={line}
                alt="Currently waiting"
                onClick={(e) => _setPage('checkins')}
            />
            <img 
                className={
                    page === 'complete' ?
                    "mobile-nav-icon sel-mob-ic" :
                    "mobile-nav-icon"
                }
                src={check}
                alt="Currently waiting"
                onClick={(e) => _setPage('complete')}
            /> 
            <Link 
                to={ROUTES.HOME} 
                className="mobile-nav-icon"
            >    
            <img 
            className={
                page === 'dash' ?
                "mobile-nav-icon sel-mob-ic" :
                "mobile-nav-icon"
            }
            id="mobile-dash-link"
            src={dash}
            alt="Back to dashboard"
            onClick={(e) => _setPage('dash')}
            />
            </Link>
        </div>
    )
}
