import React from 'react';

const InviteModal = (props) => {

    return (
        <div>
            <div className="invite-modal-click-out"
                onClick={props._closeInvite}>
            </div>
            <div className="invite-modal">
                {
                    props.inviteConfirmMessage ?
                    <div className="invite-header">
                        Your invite has been sent!
                    </div>
                    :
                    <div>
                        <div className="invite-header">
                            Send invite via email
                        </div>
                        <input autoFocus className="invitee-input" placeholder="Email address..." name="invitee" value={props.invitee} onChange={props._onInputChange.bind(this)} />
                        <button className="send-invite-btn" onClick={props._sendInvite}>Send</button>        
                    </div>   
                }
            </div>
        </div>
    )

}

export { InviteModal };