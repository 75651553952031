import React from 'react'

export default function WelcomeMessage(props) {
    return (
        <div className="background-container modal-background-cover">
            <div className="welcome-modal">

            </div>
        </div>
    )
}
