import React, { Component } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import {Page2} from './Page2';

import moment from 'moment';

var timer;

export default class QuickAddForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            projectOptions: '',
            roleOptions: '',
            project: '',
            role: '',
            increment: '',
            start: '',
            startTime: '',
            wrapTime: '',
            wrap: '',
            date: new Date(),
            page: 1,
            slots: '',
            listKey: '',
            difference: '',
            newTalent: '',
            length: '',
            list: '',
            currentLists: '',
            prep1: false,
            prep2: false,
            prevListSelected: false,

        };
    }

    _initiatePage = () => {

        var listKey = this.props.firebase.db.ref('/user-quick-lists/' + this.props.uid).push().key; 

        this.setState({
            listKey,
            tempKey: listKey,
            date: new Date(),
        })

        this.props.firebase.userQuickLists(this.props.uid)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var currentLists = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key
                }));
                this.setState({
                    currentLists,
                })
            } else {
                this.setState({
                    currentLists: '',
                })
            }
        })

        this.props.firebase.draftTalentList(this.props.uid, listKey);
    }
    
    componentDidMount() {

        this.setState({ prep1: true });

        this._initiatePage();

        this.props.firebase.projects()
        .orderByChild('createdBy')
        .equalTo((this.props.uid))
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                if (l) { this.setState({ projects: l }) 
                
                var projectOptions = [];
                l.map(proj => {
                    return projectOptions.push({
                        value: proj.id,
                        label: proj.projectName,
                        roles: proj.roles ? Object.keys(proj.roles).map(key => ({ ...proj.roles[key], id: key })) : '',
                    })
                })
                this.setState({
                    projectOptions,
                })

                } else { this.setState({ projects: '', projectOptions: '' }) }
            } else { this.setState({ projects: '', projectOptions: '' })
            }
        });

    };

    componentWillUnmount() {
        this.props.firebase.userQuickLists(this.props.uid).off();
        this.props.firebase.quickTalentListSelect(this.props.uid, this.state.list.id).off();
        if (this.state.listItme) {
            this.props.firebase.quickTalentListSelect(this.props.uid, this.state.listItem.id).off();
        };
    };

    _timeDifference = (time1, time2) => {
        var diff = moment(time1, "hh:mma").diff(moment(time2, "hh:mma"));
        var time = diff > 60 ? diff / 1000 : 60;
        var mins = time / 60;
        return mins;
    };

    _onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    };

    _handleProjectSelect = (project) => {
        var roleOptions = [];
        var projRoles = project.roles ? project.roles : '';
        if (projRoles) { projRoles.map(i => {
            return roleOptions.push({
                label: i.roleName,
                value: i.id,
            });
        })};
        this.setState({
            project,
            roleOptions: roleOptions ? roleOptions : '',
            rolesLoaded: true,
        });
    };

    _handleRoleSelect = (role) => {
        this.setState({ role });
    };

    _handleDateSelect = (date) => {
        this.setState({ date })
    };

    _openPrevList = (i) => {
        this.props.firebase.quickTalentListSelect(this.props.uid, i.id)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var obj = o.list;
                var list = obj ? Object.keys(obj).map(key => ({ ...obj[key], id: key })) : '';
                var slotTimes = o.slotTimes ? Object.keys(o.slotTimes).map(key => ({ ...o.slotTimes[key], id: key })) : '';
                this.setState({
                    list,
                    slotTimes,
                })
            }
        })

        this.props.firebase.quickTalentListSelect(this.props.uid, this.state.tempKey).remove();

        this.setState({
            project: i.project,
            projectId: i.projectId,
            increment: i.increment,
            listItem: i,
            startTime: i.start,
            wrapTime: i.wrap,
            slots: i.slots,
            listKey: i.id,
            page: 2,
            tempList: '',
            prevListSelected: true,
        })
    }

    _listSlotTimes = (slots, counter, slotTimes, startTime) => {
        var start = moment(startTime, 'hh:mma');
        var increment = this.state.increment;
        var additional = counter * increment;
        if (counter < slots) {
            slotTimes.push({
                time: start.add(Number(additional), 'minutes').format('hh:mma'),
                name: '',
                ref: '',
                status: 'Open',
            });
            counter++;
            this._listSlotTimes(slots, counter, slotTimes, startTime);
        } else {
            console.log('Slots complete');
        };
        if (counter === slots) { 
            this.props.firebase.addSlotTimesToList(this.props.uid, this.state.listKey, slotTimes);
        };
    };

    _toTalent = () => {

        this.setState({ prep1: false })
        var startTime = this._handleTimeChange(this.state.start);
        var start = moment(startTime, 'hh:mma');
        var wrapTime = this._handleTimeChange(this.state.wrap);
        var difference = this._timeDifference(wrapTime, startTime);
        this.setState({ difference: difference });
        var slots = difference/this.state.increment;
        var formatDate = moment(this.state.date).format('MM/DD/YYYY');

        this.props.firebase.updateTalentList(this.props.uid, this.state.project.label, this.state.project.value, this.state.role.label, this.state.role.value, formatDate, startTime, wrapTime, this.state.increment, slots, this.state.listKey);
        this.setState({
            startTime,
            wrapTime,
            slots,
            formatDate,
        });

        this._listSlotTimes(slots, 0, [], startTime);

        clearTimeout(timer);
        timer = setTimeout(() => {
            this.props.firebase.quickTalentListSelect(this.props.uid, this.state.listKey)
            .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var list = o.list ? Object.keys(o.list).map(key => ({ ...o.list[key], id: key })) : '';
                var slotTimes = o.slotTimes ? Object.keys(o.slotTimes).map(key => ({ ...o.slotTimes[key], id: key })) : '';
                var taken = o.taken ? o.taken : 0;
                this.setState({
                    listItem: o,
                    page: 2,
                    taken,
                    list,
                    slotTimes,
                })
            };
            });
            }, 500)
           
        clearTimeout(timer)
    };

    _addTalentToSchedule = () => {
        var list = this.state.listItem;
        this.state.list.map(i => {
            var hrsplit = i.time.split(":");
            var minsplit = hrsplit[1].split("");
            var ampm = minsplit[2] + minsplit[3];
            var hr = 
                ampm === 'pm' && Number(hrsplit[0]) !== 12 ? Number(hrsplit[0]) + 12:
                ampm === 'am' && Number(hrsplit[0]) === 12 ? 0 : 
                hrsplit[0];
            var min = minsplit[0] + minsplit[1];
            var name = i.name;
            var projectId = list.projectId;
            var projectName = list.project;
            var roleId = list.roleId;
            var roleName = list.role;
            var type = 'Casting Session';
            var date = list.date;
            var createdOn = moment().format('MM/DD/YYYY');

            this.props.firebase.createApptFromQuickList(this.props.uid, name, projectId, projectName, roleId, roleName, type, hr, min, ampm, date, createdOn, i.id, this.state.listKey);
            
            this.props.firebase.quickListTalentSelect(this.props.uid, this.state.listKey, i.id).update({ submitted: true });
           
            this.props.firebase.quickTalentListSelectSlots(this.props.uid, this.state.listKey)
            .orderByChild('ref')
            .equalTo((i.id))
            .once('value', snapshot => { 
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({ ...o[key], id: key }));
                    this.props.firebase.quickTalentListSelectSlot(this.props.uid, this.state.listKey, l[0].id)
                    .update({
                        status: 'Pending'
                    })
                }
            });
        });
    }

    _removeTalentFromSchedule = () => {

    }


    _handleTimeChange = (input) => {
        var array = input.split("");
        var numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
        var num = array.filter(f => { return numbers.includes(f)});
        var ampm = array.includes('a') || array.includes('A') ? 'am' : 'pm';
        if (num.length === 3) {
            var time = num[0] + ':' + num[1] + num[2] + ampm;
        } else if (num.length === 4) {
            time = num[0] + num[1] + ':' + num[2] + num[3] + ampm;
        } else if (num.length === 1) {
            time = num[0] + ':00' + ampm;
        } else if (num.length === 2) {
            time = num[0] + num[1] + ':00' + ampm;
        } else {
            console.log('Not valid');
        }
        return time;
    };

    _onEnterTalent = (event) => {
        if (event.keyCode === 13 && event.shiftKey === false) {
            event.preventDefault();
            this._addTalentToList(event);
          }
    };

    _addTalentToList = (event) => {
        var available = this.state.slotTimes.filter(f => f.status === 'Open');
        var slotTime = available[0].time;
        var slotId = available[0].id;
        this.props.firebase.quickAddTalent(this.props.uid, this.state.listKey, this.state.newTalent, this.state.date, slotTime, slotId);
        this.setState({
            newTalent: ''
        });

    };

    _backToCreateList = () => {
        this._initiatePage();
        this.setState({
            page: 1,
            prevListSelected: false,
            project: '',
            projectId: '',
            increment: '',
            listItem: '',
            startTime: '',
            wrapTime: '',
            slots: '',
        })
    };

    render() {

        return (
            <div className="quick-form-container">
                { 
                this.state.prevListSelected ? 
                <div className="quick-form-back-btn" onClick={this._backToCreateList}>Back to Create</div> :
                <div className="quick-form-back-btn" onClick={this.props.togglePage.bind(this)}>Back to Schedule</div>
                } 
                    {
                    this.state.page === 1 ?
                    <div className={this.state.prep1 ? "quick-form-layout fadeIn" : "quick-form-layout fadeOut"}>
                    <div className="quick-form-inputs-side">
                        <div className="quick-form-header-text">Try our new Schedule Generator!</div>
                        <div className="description">Simply fill in the form below, enter the talent you want to see, and submit.</div>
                   
                        <div className="quick-add-section-title">The Opportunity</div>
                        <div className="quick-add-input-section">
                            <div>I am creating a session for</div>
                            <Select 
                                options={this.state.projectOptions ? this.state.projectOptions : []}
                                value={this.state.project}
                                onChange={this._handleProjectSelect}
                                className="quick-add-select"
                                placeholder='Search or select from projects'
                            />
                            <div>for the opportunity of</div>
                            <Select 
                                options={this.state.rolesLoaded && this.state.roleOptions ? this.state.roleOptions : []}
                                value={this.state.role}
                                onChange={this._handleRoleSelect}
                                className="quick-add-select"
                                placeholder="Choose a role"
                            />
                            
                        </div>
                        <div className="quick-add-section-title">Session Timeframe</div>
                        <div className="quick-add-input-section">   
                            The session is taking place on
                            <DatePicker 
                                format="MM / DD / YYYY"
                                selected={this.state.date}
                                onChange={this._handleDateSelect}
                                defaultValue={this.state.date}
                                className="quick-add-time-input"
                            />
                            <div>
                                Between <input value={this.state.start} className="quick-add-time-input" name="start" onChange={this._onChange.bind(this)} placeholder="ex. 1030a" /> 
                                and <input value={this.state.wrap} className="quick-add-time-input" name="wrap" onChange={this._onChange.bind(this)} placeholder="ex. 230p" />
                            </div>
                        </div>
                        <div className="quick-add-section-title">Appointment Length</div>
                        <div className="quick-add-input-section">   
                            I'll be seeing talent every <input className="quick-add-time-input increment-input" value={this.state.increment} type="number" name="increment" onChange={this._onChange.bind(this)} placeholder="15" /> minutes.
                        </div>
                        <button className="to-talent-btn" onClick={this._toTalent}>Next</button>
                    </div>
                    <div className="prev-quick-lists">
                        <div className="quick-add-section-title">Previous Lists</div>
                        {
                            this.state.currentLists ? 
                            <div className="prev-quick-list-item">
                                {this.state.currentLists.map((i, index) => (
                                    <div key={i.id} onClick={this._openPrevList.bind(this, i)}>{i.project}</div>
                                ))}
                            </div>
                            :
                            <div>No lists in drafts</div>
                        }
                    </div>
                </div>
                :
                this.state.page === 2 ?
                <div className={this.state.page === 2 ? "fadeIn" : "fadeOut"}>
                    <Page2 state={this.state} props={this.props} _onChange={this._onChange} _onEnterTalent={this._onEnterTalent} _addTalentToSchedule={this._addTalentToSchedule} />
                </div>
                :
                <div />
                }
            </div>

        )
    }
}