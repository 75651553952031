import React, { useState, useEffect, useRef } from 'react';

import SlyNoteContainer from './SlyNotes/SlyNoteContainer';
import SlyTagContainer from './SlyNotes/SlyTagContainer';
import ShowTalentNoteMarker from './SlyNotes/ShowTalentNoteMarker';
import SlyNoteTagItem from './SlyNotes/SlyTags/SlyNoteTagItem';
import SlyGroupNoteContainer from './SlyNotes/SlyGroupNoteContainer';

import quicktag from '../../../../assets/circletag.png';
import quickpencil from '../../../../assets/quickpencil.png';
import quickchat from '../../../../assets/chatcircle.png';

import { NullUser } from '../../../../assets/nulluser';

export default function TalentNoteItem(props) {

    const [privacy, setPrivacy] = useState(true);
    const [expanded, setExpanded] = useState(false);
    const [fade, setFade] = useState(false);
    const [talTags, setTalTags] = useState('');
    const [myTags, setMyTags] = useState(true);
    const [thisTalent, setTalent] = useState('');
    const [markers, setMarkers] = useState('');
    const [item, setItem] = useState('');
    const [intTags, setIntTags] = useState('');
    const [ids, setIds] = useState('');
    const [save, setSave] = useState('');
    const [tagsExpanded, setTagsExpanded] = useState('');
    const [notes, setNotes] = useState('');
    const [viewType, setViewType] = useState('my');

    useEffect(() => {
        props.firebase.sessionNotes(props.sess, props.item.id)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var notes = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }));
                if (notes) {
                    setNotes(notes);
                }
            } else {
                setNotes('');
            }
        });
    }, [])

        {/*props.firebase.sessionTalentSelect(props.sess, props.item.id)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                setTalent({name: o.subject, rep: o.subjectRep, project: o.topic, role: o.subtopic});
            };
        })*/}

    useEffect(() => {
        setItem(props.item);
        setTalent({
            name: props.item.subject,
            rep: props.item.subjectRep, 
            project: props.item.topic,
            role: props.item.subtopic,
        })
    }, [])

        
    /*
    useEffect(() => {
        props.firebase.sessionUserTags(props.sess, props.item.id)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var talTags = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key
                }))
                setTalTags(talTags);
            } else {
                setTalTags('');
            }
        });
    }, [])
    */
        

    useEffect(() => {
        props.firebase.selectCastingSessionTalent(props.uid, props.castSess, props.item.id)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var talent = snapshot.val();
                var tags = talent.tags ? talent.tags : '';
                var list = tags ? Object.keys(tags).map(key => ({
                    ...tags[key],
                    id: key
                })) : '';
                setIntTags(list);
            } else {
                setIntTags('');
            }})
    }, [])
        

    

    useEffect(() => {
        props.firebase.db.ref('/my-notes/' + props.uid + '/emps/' + props.sessUserId + '/talent/' + props.item.id)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var newIds = o ? Object.keys(o) : '';
                setIds(newIds);
            } else {
                setIds('');
            };
        })
    }, [])
    
    var fired = false;

    /*if (props.castTalent && !fired) {
        fired = true
    }

    if (props.secondFocus) {
        var secFoc = props.secondFocus;
        if (secFoc !== props.item.id) {
            if (expanded) {
                setExpanded(!expanded);
            }
        }
    }

    const removeFocusSelect = (item) => {
        if (props.setSecondFocus === item.id) {
            props.setSecondFocus('');
        }
        setExpanded(false)
    }

    const secondFocusSelect = (item) => {
        if (!expanded) {
            props.setSecondFocus(item.id);
            setExpanded(true);
        }
    };*/

    const wrapperRef = useRef(null);
    
    /*useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
        document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    
    const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setSave(true);
        }
    };

    const _handleClick = () => {
        secondFocusSelect(props.item)
        setSave(false);
    };

    const _toggleTagView = () => {
        setTagsExpanded(!tagsExpanded)
    }


    const _viewType = (type) => {
        setViewType(type);
    }
    */


    return (
        <div className={fade ? 'fadeOutHeader cur-point' : 'fadeInHeader cur-point'}>
        <div className="relative">
            <div 
                ref={wrapperRef}
                className={
                    props.cardSize === 'small' ? 
                    "i-pad-group-note-item display-small-group-note-item" :
                    props.cardSize === 'medium' ? 
                    "i-pad-group-note-item display-medium-group-note-item min-height-ipad-group-note-item " :
                    "i-pad-group-note-item"
                }
                onClick={props._open.bind(this, props.item)}
            >
                <div className="i-pad-group-note-layout">
                    <img className="i-pad-group-note-avatar" src={NullUser} alt="User Headshot" />
                    <div>
                        <div className="i-pad-sly-note-header">
                            <div className="i-pad-sly-note-name">
                                {thisTalent.name}
                            </div>
                            <div className="i-pad-sly-note-rep">
                                {thisTalent.rep}
                            </div>
                            {/*{markers ?
                            <ShowTalentNoteMarker 
                                markers={markers} 
                                sessMarkers={props.newMarkers} 
                            />
                            :
                            <div />
                            }*/}
                        </div>                        
                    </div>
                </div>
                
                   
                <div className={
                    !expanded && props.cardSize === 'small' ?
                    "sly-note-tag-list-container hide-div" :
                    "sly-note-tag-list-container"
                }>
                {
                    intTags ?
                    intTags.map((item, index) => (
                        <SlyNoteTagItem key={item.id} item={item} /> 
                    )
                    )
                    :
                    <div />
                    }
                </div>
                
                {/*{ 
                <div className={
                    expanded ? 
                    "min-card notes-expanded" : 
                    props.cardSize === 'small' ? 
                    "min-card notes-very-minimized" :
                    props.cardSize === 'medium' ?
                    "min-card-notes-very-minimized" :
                    "min-card notes-mininized"
                    
                }
                >
                <div className={
                    (props.cardSize === 'medium' || props.cardSize === 'small') && !expanded ? 
                    "hide-div" : 
                    expanded && viewType !== 'my' ? 
                    "hide-div" :
                    ""
                    }>
                    <SlyNoteContainer 
                        groupRef={props.props.groupRef} 
                        firebase={props.firebase} 
                        list={notes} 
                        sessUserName={props.sessUserName}
                        sessUserId={props.sessUserId}
                        _saveToMyNotes={props._saveToMyNotes}
                        ids={ids}
                        uid={props.uid}
                        sess={props.sess} 
                        sessionId={props.sessionId}
                        save={save}
                        removeFocusSelect={removeFocusSelect}
                        talentName={thisTalent.name}
                    />
                    </div>
                    {
                        <div className={
                            expanded && viewType === 'group' ? 
                            "group-note-padding" : 
                            "group-note-padding hide-div"
                        }>
                        <SlyGroupNoteContainer 
                            groupRef={props.props.groupRef}
                            list={notes}
                            ids={ids}
                            sessUserId={props.sessUserId}
                        />
                        </div>
                    }
                    
                </div>
            </div>*/}
            <div className="note-icons-clickable">
                {
                    notes ? 
                    <img className="sly-tag-icon-small" src={quickpencil} alt="Add Notes" /> 
                    :
                    null
                }
                {
                    notes && !ids ?
                    <img className="sly-tag-icon-small" src={quickchat} alt="Add Tags" />
                    :
                    null
                }
                {
                    intTags ?
                    <img className="sly-tag-icon-small" src={quicktag} alt="Add Tags" />
                    :
                    <div />
                }
            </div>
            </div>
            </div>
        </div>
    )
}
