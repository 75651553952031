import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import { Input } from '../Common';

import logo from '../../assets/aniicon.png';
import formColor from '../../assets/formColor.png';

const PasswordForgetPage = () => (
  <div>
    <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  onSubmit = (email) => {

    this.props.firebase 
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <div className="check-in-layout">
      <div className="check-in-form-container">
      <div className="header">
        </div>

        <div className="check-in-form">
        <br />
        <br />

        <div className="check-in-header-text">Type in your email</div> 
        <br />
        <div className="password-forget-padding">
          <EmailInput submit={this.onSubmit} />
        {error && <div>{error.message}</div>}
      </div>
      </div>
      </div>
      </div>
    );
  }
}

function EmailInput(props) {
  const [email, setEmail] = useState('');

  function submit() {
    props.submit(email);
  }

  return (
    <div>
    <Input 
      name="email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      type="text"
      placeholder="Email Address"
    />
    <button className="check-in-btn" disabled={email.length < 4 || !email.includes('@')} onClick={() => submit()}>
      Reset Password
    </button>
    </div>
  )
}

const style = {
  input: {
    width: 330,
    height: 50,
    paddingLeft: -1,
    marginTop: 0,
    backgroundColor: '#f8f9fa',
    border: 2,
    borderColor: '#f8fefe',
    fontSize: 17,
    fontWeight: '500',
    fontColor: '#525A5F'
},
}

const PasswordForgetLink = () => (
    <Link to={ROUTES.PASSWORD_FORGET}><div style={{textAlign: 'center', color: '#aaa', marginTop: 15}}>Forgot Password?</div></Link>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };