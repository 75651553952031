import React, { Component } from 'react';

import GeneralNoteList from './GeneralNoteList';
import GroupNoteAdd from './GroupNoteAdd';
import GroupNoteList from './GroupNoteList';
import NoteDisplaySettings from './NoteDisplaySettings';

export default class GroupNotes extends Component {

    constructor(props) {
        super(props);

        this.state = {
            allNotes: '',
            userTags: '',
            cardSize: 'small',
            talent: '',
            activeSession: '',
        }
    }

    componentWillReceiveProps(nextProps, props) {

        if (nextProps.hideWhileActive !== this.props.hideWhileActive) {
            this.setState({
                hideWhileActive: nextProps.hideWhileActive
            })
        }

        if (nextProps.activeSession !== this.state.activeSession) {
            this.props.firebase.sessionInfo(nextProps.activeSession)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    this.setState({
                        talent: o.talent ? Object.keys(o.talent).map((key, index) => ({
                            ...o.talent[key],
                            id: key,
                            index: index,
                        })) : '',
                    })
                    var newMarkers = o.markers ? o.markers : '';
                    if (newMarkers) {
                        this.setState({
                            newMarkers: newMarkers,
                        });
                    }
                }
            });
        }; 
        this.props.firebase.allSessionNotes(this.props.activeSession)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map((key, index) => ({
                    ...o[key],
                    id: key,
                    index: index,
                }))
            if (l) {
                this.setState({
                    allNotes: l
                })
                }
            }
        })
    }

    componentDidMount() {

        this.props.firebase.userTagGroups(this.props.uid)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }));
                if (l) {
                
                    this.setState({
                        userTagGroups: l,
                    })
                } else {
                    this.setState({
                        userTagGroups: '',
                    })
                }
            } else {
                this.setState({
                    userTagGroups: '',
                })
            }
        });

        this.props.firebase.userTags(this.props.uid)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }));
                if (l) {
                    this.setState({
                        userTags: l,
                    })
                } else {
                    this.setState({
                        userTags: '',
                    })
                }
            } else {
                this.setState({
                    userTags: '',
                })
            }
        })
    }
        

    componentWillUnmount() {
        this.props.firebase.allSessionNotes(this.props.activeSession).off();
        this.props.firebase.sessionInfo(this.props.activeSession).off();
        this.props.firebase.userTags(this.props.uid).off();
    };

    _switchView = (view) => {
        this.setState({
            cardSize: view,
        })
    };

    /*
    return (
        <div className="i-pad-focus-note-pad-container">
        <GroupNoteAdd 
            props={props}
            groupRef={props.groupRef}
            firebase={props.firebase} 

        />
        {props.talentId ?
        <GroupNoteList props={props} firebase={props.firebase}  />
        :
        <div />
        }
        </div>
    )*/
    
   render() {
        return ( 
            <div>
                <GeneralNoteList 
                    _switchView={this._switchView}
                    notes={this.state.allNotes} 
                    props={this.props} 
                    firebase={this.props.firebase}
                    markers={this.state.newMarkers}
                    userTags={this.state.userTags}
                    userTagGroups={this.state.userTagGroups}
                    uid={this.props.uid}
                    sessUserName={this.props.sessUserName}
                    sessUserId={this.props.sessUserId}
                    fadeAddNote={this.props.fadeAddNote}
                    cardSize={this.state.cardSize}
                    hideWhileActive={this.state.hideWhileActive}
                    talent={this.state.talent}
                    mobileAddNote={this.props.mobileAddNote}
                    setMobileAddNote={this.props.setMobileAddNote}
                    mobileState={this.props.mobileState}
                    _openTalentModal={this.props._openTalentModal}
                />
            </div>
        )
    }
    
}



/*
{
   
   
}
*/

/*
props.talentId ?
<GroupNoteList props={props} firebase={props.firebase}  />
:
*/
