import React, { useEffect, useState } from 'react';
import moment from 'moment';

export default function Suggest(props) {

    const suggestions = props.actorSuggestions;

    const [display, setDisplay] = useState(false);

    if (props.name.length > 4 && props.name.length !== 0 && suggestions) {
        var filter = suggestions.filter(fil => {
            return fil.name.toLowerCase().includes(props.name.toLowerCase())
        })
       
        if (filter.length === 1 && !display) {
            setDisplay(true)
        } else if (filter.length !== 1 && display) {
            setDisplay(false)
        };
    }

    const _prepAutoFill = (item) => {
        props.firebase.selectSessionSchedule(item.groupKey)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                var talent = l.filter(fil => {
                    return fil.name.toLowerCase() === item.name.toLowerCase();
                });

                if (talent) {
                    props.firebase.checkInSessionUser(item.groupKey, talent[0].id)
                    .update({
                        status: 'checking in',
                        checkInTime: moment().format('hh:mma'),
                    });

                    props.firebase.checkInCastingUser(props.uid, item.sessionId, talent[0].id)
                    .update({
                        status: 'checking in',
                        checkInTime: moment().format('hh:mma'),
                    })
                }
            }
        })
        props._autoFill(item);
    }

    /*
    const _prepAutoFill = (item) => {
        props.firebase.sessionConnect(props.uid, item.sessionId)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var group = o.group;
                props.firebase.sessionSchedule(group)
                .once('value', snapshot => {
                    if (snapshot.exists()) {
                        var us = snapshot.val();
                        var list = Object.keys(us).map(key => ({
                            ...us[key],
                            id: key
                        }));
                        if (list) {
                            var toUpdate = list.filter((fil, index) => {
                                return fil.name.toLowerCase() === item.name.toLowerCase()
                            })
                            if (toUpdate) {
                            console.log(toUpdate[0].id);
                            }
                            if (toUpdate) {
                                props.firebase.sessionScheduleUser(group, toUpdate[0].id)
                                .update({
                                    status: 'checking in',
                                    checkInTime: moment().format('hh:mma')
                                });
                            };
                        };
                    };
                })
            }
        })
        props._autoFill(item);
    }
    */


    return (
        display && filter ?
        <div>
        {filter.map((fil, index) => (
            <div key={index} className="single-suggest-box" onClick={_prepAutoFill.bind(this, fil)}>
                {
                <div className="check-in-single-suggestion">
                    {fil.name}
                </div>
                }
            </div>
        ))}
        </div>
        :
        <div />
    )
}