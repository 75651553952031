import React, { useState, useEffect } from 'react';

import SlyTagAdd from './SlyTags/SlyTagAdd';
import SlyNoteTagItem from './SlyTags/SlyNoteTagItem';



import moment from 'moment';

import trash from '../../../../../assets/trashmodernred.png';
import TalentTags from '../../../TalentTags';
import quicktag from '../../../../../assets/circletag.png';
import quickpencil from '../../../../../assets/quickpencil.png';

export default function SlyNoteAdd(props) {

    var timer;
    var awayTimer;

    const [focused, setFocused] = useState(false);
    const [note, setNote] = useState('');
    const [oldNote, setOldNote] = useState('');
    const [save, setSave] = useState(false);
    const [fade, setFade] = useState(false);
    const [noteId, setNoteId] = useState('');
    const [hasId, setHasId] = useState('');
    const [thisUserName, setThisUserName] = useState('');
    const [myNoteId, setMyNoteId] = useState('');
    const [tagsExpanded, setTagsExpanded] = useState('');
    const [tagMenu, setTagMenu] = useState(false);
    const [talentTags, setTalentTags] = useState('');
    const [listedTalentTags, setListedTalentTags] = useState('');
    const [talentId, setTalentId] = useState('');
    const [tagGroups, setTagGroups] = useState('');
    const [groupTags, setGroupTags] = useState('');
    const [tagGroup, setTagGroup] = useState('');
    const [mobileAddNote, setMobileAddNote] = useState(false);

    const _onEnterNote = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            _addSessionNote(e);
        }
    }

    const textAreaRef = React.createRef();


    const _onKeyDown = (e) => {

    };

    /*const _onKeyDown = (e) => {
        
        awayTimer = 0;

        if ((e.keyCode === 191 || e.keyCode === 111) && e.shiftKey === false) {
            props.firebase.userTags(props.props.props.uid)
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({
                        ...o[key],
                        id: key,
                    }));
                    if (l) {
                        console.log(l);
                    }
                }
            })
            if (!tagMenu) {
                setTagMenu(true);
            }
        } 
    }*/

    var sessUserId = localStorage.getItem('SessionState');
    useEffect(() => {
        if (sessUserId) {
            if (!hasId) {
                setHasId(true);
                var empId = props.firebase.sessionUserSelect(sessUserId)
                .once('value', snapshot => {
                    if (snapshot.exists()) {
                        var o = snapshot.val();
                        var userName = o.sessionUserName ? o.sessionUserName : 'Host';
                        if (userName !== thisUserName) {
                            setThisUserName(userName);
                        }      
                    }
                })
            }
        }
    }, [sessUserId]);
    

    const _deleteNote = () => {

        window.clearTimeout(timer);

        setFade(true)
        var pp = props.props;
        var uid = pp.uid;
        var castSessId = pp.sessionId;
        var groupId = props.sess;
        var talentId = props.item.subjectId;
        var noteId = props.item.id;

        timer = setTimeout(() => {
            props.firebase.removeHostGroupSessionNote(uid, castSessId, groupId, talentId, noteId);
            setFade(false);
        }, 500)
        return (() => clearTimeout(timer));
    };

    useEffect(() => {
        if (props.mobileAddNote) {
            textAreaRef.current.focus();
            var val = textAreaRef.current.value;
            textAreaRef.current.value = '';
            textAreaRef.current.value = val;
        }
    }, [props.mobileAddNote])

    const moveCursorToEnd = (e) => {
        var temp_value = e.target.value 
        e.target.value = ''
        e.target.value = temp_value
        setFocused(true);
        props.setSecondFocus('Top');
    };


    const _addSessionNote = (blankNote) => {

        props.setMobileAddNote(false);
        setFocused(false);

        var props2 = props.props;
        var props3 = props.props2;
        var sess = props.activeSession;
        var uid = props3.uid;
        var groupRef = props3.groupRef;
        var employee = thisUserName ? thisUserName : 'Host';
        var talent = props3.talentName;
        var talentId = props3.talentId;
        var project = props3.projectName;
        var projectId = props3.projectId
        var role = props3.roleName;
        var roleId = props3.roleId
        var privacy = 'private';
        var timestamp = moment().format('hh:mm a');
        var daystamp = moment().format('MM/DD/YYYY');
        var sessionUser = sessUserId;

        if (myNoteId) {
            if (note !== oldNote) {
                props.firebase.editHostGroupSessionNote(uid, props.sessionId, sess, talentId, myNoteId, note);
                props._saveToMyNotes(talentId, myNoteId, note);
                setOldNote(note);
            };
        } else {
            if (note !== '') {
            var newKey = props.firebase.sessionNotes(props.sessionId).push().key;
            props.firebase.addSessionNote(
                sess, 
                newKey, 
                groupRef, 
                sessionUser, 
                talent, 
                talentId, 
                project, 
                projectId, 
                role, 
                roleId, 
                note, 
                privacy, 
                timestamp, 
                daystamp, 
                uid, 
                props.sessionId, 
                'host', 
                sessionUser,
                thisUserName
            );
            props._saveToMyNotes(talentId, newKey, note);
            setMyNoteId(newKey);
            setOldNote(note);

            }
            }
        }

        
    useEffect(() => {
        if (props.currentNote) {
            if (noteId !== props.currentNote.id) {
                var notes = Object.keys(props.currentNote).map(key => ({
                    ...props.currentNote[key],
                    id: key,
                }))
                if (notes) {
                    var myNotes = notes.filter(fil => {
                        return fil.employee === thisUserName;
                    })
                    if (myNotes) {
                        if (myNotes[0] && myNotes[0].subjectId === props.props.props.talentId) {
                            if (!note && note !== oldNote && myNotes.lenght > 0) {
                                setNote(myNotes[0].note);
                                setOldNote(myNotes[0].note)
                                setMyNoteId(myNotes[0].id);
                            }
                            if (note && myNotes[0] && note !== myNotes[0].note) {
                                setNote(myNotes[0].note);
                                setOldNote(myNotes[0].note)
                                setMyNoteId(myNotes[0].id);
                            } 
                            
                            if (myNotes[0] && myNotes.length > 1 && myNotes[0].id !== myNoteId) {
                                setMyNoteId(myNotes[0].id);
                                setNote(myNotes[0].note);
                            }

                            if (note === '' && myNotes[0] && myNotes[0].id !== myNoteId) {
                                setMyNoteId(myNotes[0].id);
                                setNote(myNotes[0].note);
                            }
                        }

                    }
                
        
                    var otherNotes = notes.filter(fil => {
                        return fil.employee !== 'host' && fil.privacy !== 'private';
                    })
                
                }
            }
        }
        else {
            
        }
    }, []);

    useEffect(() => {
        if (props.clearProps) {
            props.firebase.selectCastingSessionTalent(props.props.props.uid, props.sessionId, props.props.props.talentId).off();
        }
    }, [props.clearProps])

    useEffect(() => {
        props.firebase.selectCastingSessionTalent(props.props.props.uid, props.sessionId, props.props.props.talentId)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var talent = snapshot.val();
                if (talent.tags && talent.tags !== talentTags) {
                    setTalentTags(talent.tags);
                } else if (talentTags) {
                        setTalentTags('');
                    } else {
                        if (talentTags!== '') {
                            setTalentTags('');
                            }
                        }
                } else {
                    if (talentTags !== '') {
                        setTalentTags('');
                    }
                }
            
                var tags = talent && talent.tags ? talent.tags : '';
                if (tags) {
                    var list = Object.keys(tags).map(key => ({
                        ...tags[key],
                        id: key
                    }))
                    if (list && listedTalentTags === '') {
                        setListedTalentTags(list);
                    }
                    if (listedTalentTags !== '') {
                        setListedTalentTags('');
                    }
                } else {
                    setListedTalentTags('');
                }
        });
    }, [])
    


    var counter = 1;

    if (note && !tagsExpanded) {
        var textarea = document.getElementById('note-text-1');
        var textareaRows =  note ? note.split("\n") : '';
        if (textareaRows[0] !== "undefined") {
            counter = textareaRows.length;
            if (textarea) {
                textarea.rows = counter;
            } 
        } else {
            counter = 1;
        } 
    }   

    const _selectTagGroup = (item) => {
        if (item === 'All') {
            setGroupTags(listedTalentTags)
            } else {
            setTagGroup(item.id);
            var tags = item.tags ? Object.keys(item.tags).map(key => ({
                ...item.tags[key],
                id: key,
            })) : '';
            if (tags) {
                setGroupTags(tags)
            }
        }
    }


    const _toggleTagView = () => {
        setFocused(true)
        setTagsExpanded(!tagsExpanded);
    };

    useEffect(() => {
        if (!tagsExpanded) {
            textAreaRef.current.focus();
        }
    }, [tagsExpanded])
    

    return (
        <div id="the-slyest-add-note" className={props.mobileAddNote ? "relative" : "phone-hide-div"}>
            <div id="sly-note-confirm-note-button" className={props.mobileAddNote ? "" : "hide-div"} onClick={_addSessionNote}>Confirm</div>
            <div className={
                props.secondFocus === 'Top' && focused && !tagsExpanded ? 
                    counter >= 0 && counter < 3 ? 
                    "i-pad-note-textarea adding-note line-is-2 over-rule-margin" : 
                    counter >= 3 && counter < 8  ?
                    "i-pad-note-textarea adding-note line-is-3 over-rule-margin" :
                    counter >= 8?
                    "i-pad-note-textarea adding-note line-is-4 over-rule-margin" :
                    "i-pad-note-textarea"
                :
                props.secondFocus === 'Top' && focused && tagsExpanded ? 
                "i-pad-note-textarea adding-note line-is-4 over-rule-margin"
                
                : !focused && note !== '' ?
                    counter >= 0 && counter < 3 ? 
                    "i-pad-note-textarea less-shadow line-is-1" : 
                    counter >= 3 && counter < 7  ?
                    "i-pad-note-textarea less-shadow line-is-2-5" :
                    counter >= 7?
                    "i-pad-note-textarea less-shadow line-is-3" :
                    "i-pad-note-textarea"
                : focused && tagsExpanded ?
                "i-pad-note-textarea adding-note line-is-tags over-rule-margin" :
                "i-pad-note-textarea"
            }>
            {
                tagsExpanded ?
                <div className="sly-note-tag-columns">
                    <SlyTagAdd 
                        firebase={props.firebase} 
                        quicktag={quicktag} 
                        userTags={props.userTags} 
                        talentId={props.props.props.talentId}
                        talentTags={talentTags} 
                        uid={props.props.props.uid}
                        sessionId={props.sessionId}
                        talentName={props.talentName}
                        projectId={props.props.props.projectId}
                        roleId={props.props.props.roleId}  
                        tagGroups={props.userTagGroups}
                        groupTags={groupTags}
                        tagGroup={tagGroup}
                        _selectTagGroup={_selectTagGroup}
                    />
                </div>
                :
                <div className="sly-note-add-rows">
                <textarea 
                    className="full-textarea sly-notearea"
                    id="note-text-1"
                    ref={textAreaRef}
                    placeholder={"Add note for " + props.talentName}
                    onFocus={moveCursorToEnd}
                    value={note}
                    onKeyDown={_onKeyDown}
                    onChange={(e) => setNote(e.target.value)} 
                    onBlur={props.mobileAddNote ? null : _addSessionNote}
                />
               
                    <div className="sly-note-tag-list-container adding-first-note">
                            {
                                listedTalentTags ?
                                listedTalentTags.map((item, index) => (
                                    <SlyNoteTagItem key={item.id} item={item} />
                                ))
                                :
                                <div />
                            }
                    </div>
                </div>
                
            }   
            </div>
            
            {props.secondFocus === 'Top' ?
            <button className="no-focus" onClick={_toggleTagView.bind(this)}>
                <img onClick={_toggleTagView.bind(this)} id="sly-tag-icon-mobile" className="sly-tag-icon" src={tagsExpanded ? quickpencil : quicktag} alt="Add Tags" />
            </button>
            :
            <div />
        }
        </div>
    )
}

/*
<div>
                    <div 
                        onClick={_deleteNote}
                        className="delete-note-box smaller-box save-smaller-box">
                        <div>Delete</div>
                        <img className="i-pad-note-trash-icon" src={trashred} alt="Delete Note" />
                    </div>



                     {
                note && note !== oldNote ?
                <div>
                    <div 
                        onClick={_deleteNote}
                        className="delete-note-box smaller-box save-smaller-box">
                        <div>Delete</div>
                        <img className="i-pad-note-trash-icon" src={trashred} alt="Delete Note" />
                    </div>
                </div>
                :
                <div />
            }
                    */
