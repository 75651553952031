import React from 'react';

import { NullUser } from '../../../../../../assets/nulluser';

export default function SessionScheduleItem(props) {
    return (
        <div className="group-session-schedule-item">
            <div className="group-session-schedule-item-layout">
                <div>
                <div className="group-session-schedule-time">{props.item.time}</div>
                <div className="group-session-schedule-time">{props.item.checkInTime ? props.item.checkInTime : ''}</div>
                </div>
                {
                    props.item.name ?
                    <div className="group-session-schedule-talent">
                        <img className="group-session-schedule-avatar" src={NullUser} alt="Actor Headshot" />
                        <div>
                            <div className="group-session-schedule-name">{props.item.name}</div>
                            <div className="group-session-schedule-role">{props.item.role}</div>
                        </div>
                    </div>
                    :
                    <div className="null-text">Open slot</div>
                }
            </div>
        </div>
    )
};
