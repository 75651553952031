import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { CSSTransitionGroup } from 'react-transition-group';
import moment from 'moment';
import * as ROUTES from '../../constants/routes';

import NoteModalBody from '../Modals/NotesModal/NoteModalBody';
import ModalContainer from '../Modals/index';
import { RoomSettings } from './RoomSettings';
import { RoomTalent } from './RoomTalent';
import { InviteModal } from './InviteModal';
import CheckInList from './CheckInList';
import SessionSelect from './SessionSelect';
import QuickMenuComponent from '../QuickMenu/QuickMenuComponent';
import { showDeviceOwnerSelect } from '../PushNotifications/push-notifications';
import SessionPanel from './GroupSession/SessionPanel/SessionPanel';
import StartSessionPopUp from './StartSessionPopUp';
import SelectUser from './SelectUser';
import MobileNavBar from '../Mobile/MobileNavBar';
import SessionTalentModal from '../Modals/SessionTalentModal/SessionTalentModal';

import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withAuthentication } from '../Session';

import '../transitions.css';
import './room.css';
import '../Home/home.css';

import starOn from '../../assets/starOn.png';
import starOff from '../../assets/starOff.png';
import remove from '../../assets/remove.png';
import bookmark from '../../assets/bookmark.png';
import bookmarkoff from '../../assets/bookmarkoff.png';
import addplususers from '../../assets/addplususers.png';
import addproject from '../../assets/addproject.png';
import gearhover from '../../assets/gearhover.png';
import noteCountIcon from '../../assets/speech-bubble.png';
import tagCountIcon from '../../assets/tag.png';
import invitation from '../../assets/invitation.png';
import arrowdown from '../../assets/arrowdown.png';
import roomicon from '../../assets/roomicon.png';
import pendingclock from '../../assets/clockwise-rotation.png';

var timer;

const RoomPage = () => (
    <div>
        <RoomPageForm />
    </div>
)

class RoomPageFormBase extends Component {

    constructor(props) {
        super(props);

        this.state = {
            uid: '',
            companyName: '',
            roomId: null,
            roomName: null,
            url: null,
            roomProjects: [],
            roomReaders: [],
            roomRoles: '',
            activeCheckIn: false,
            isActive: false,
            checkInId: '',
            checkInName: '',
            checkInProject: '',
            checkInRole: '',
            checkIns: [],
            allCheckIns: [],
            filteredCheckIns: [],
            completed: [],
            roomCheckIns: [],
            priority: false,
            priorityId: '',
            activeTab: 'waiting',
            authorized: false,
            message: '',
            employees: [],
            employeeOptions: [],
            projectOptions: [],
            roleOptions: [],
            selectedReaderIds: [],
            selectedProjectIds: [],
            userDropdown: false,
            projectDropdown: false,
            roleDropdown: false,
            employeeSelect: '',
            projectSelect: '',
            roleSelect: '',
            addReaderOn: false,
            addProjectOn: false,
            note: '',
            notes: '',
            name: '',
            modal: false,
            modalType: '',
            modalTalent: '',
            settingsOpen: false, 
            projectSearch: '',
            employeeSearch: '',
            roleSearch: '',
            invitee: '',
            externals: '',
            inviteModal: false,
            activeSession: '',
            activeGroup: false,
            sessionTags: '',
            sidebar: false,
            activeSidebar: false,
            okay: false,
            requestPending: false,
            countActive: false,
            count: 0,
            takeNote: '',
            sessionMode: false,
            sessionId: '',
            quickMenu: 'menu',
            rightSideBarClose: false,
            loading: true,
            assignSort: 'time',
            loadingComplete: false,
            controller: '',
            mobileState: 'checkins',
            sessTalentModal: false,
            sessModalTalent: '',
            sessModalFade: true,
        };
    };

    componentWillMount() {

        const time = moment().format('hh:mma');
        const day = moment().format('MM/DD/YYYY');

        var URL = window.location.pathname;
        const roomId = URL.replace('/room/', '');
        this.setState({
            url: window.location.pathname,
            roomId: roomId,
        })

        var sessionKey = localStorage.getItem( 'SessionState' );

        this.listener = this.props.firebase.auth.onAuthStateChanged(
            authUser => {
                authUser 
                ? this.setState({ uid: authUser.uid }) 
                : this.setState({ authUser: null });

                sessionKey ? 
                this.props.firebase.sessionUserSelect(sessionKey)
                .once('value', snapshot => {
                    if (snapshot.exists()) {
                        var currentUser = snapshot.val();

                        this.setState({
                            name: currentUser.sessionUserName,

                        })
                    } else {
                        this.setState({
                            name: ''
                        })
                    }
                })
                :
                this.setState({
                    name: ''
                });

                if (authUser) {
                    var sessionState = localStorage.getItem( 'SessionState' ) || '';
                    this.setState({
                      sessionState: sessionState
                    })
                    if (sessionState === '') {
                      const newSessionUserKey = this.props.firebase.sessionUser().push().key;
                      this.props.firebase.newSessionUser(newSessionUserKey, authUser.uid, this.state.sessionUserName, this.state.sessionUserId, time, day);
                      this.selectedState(newSessionUserKey);
                    } else {
                      const sessionKey = sessionState;
                      this.props.firebase.sessionUserSelect(sessionKey)
                      .on('value', snapshot => {
                        if (snapshot.exists()) {
                        const object = snapshot.val();
                        if (!object.sessionUserName) {
                            this.setState({
                                showSelectUser: true,
                            })
                        }
                        this.setState({
                          sessionUserName: object.sessionUserName,
                          sessionUserId: object.sessionUserId,
                        })
                      }
                    })
                  }
                }

                authUser
                ? 
                this.props.firebase.user(authUser.uid)
                .on('value', snapshot => {
                    if (snapshot.exists()) {
                        var o = snapshot.val();
                        var r = o.rooms;
                        var reception = o.receptionEmail ? o.receptionEmail : '';
                        this.setState({
                            receptionEmail: reception,
                            groupRef: o.groupRef ? o.groupRef : '',
                        });
                        var l = Object.keys(r).map(key => ({
                            ...r[key],
                            id: key,
                        }))
                        this.setState({
                            company: o.username,
                        })
                        var roomArray = l.map(r => r.roomId);
                        if (roomArray.includes(roomId)) {
                            this.setState({
                                authorized: true,
                            })

                            // PULLING COMPANY EMPLOYEES FOR DROPDOWN

                            var e = o.employees;
                            if (e) {
                            var el = Object.keys(e).map(key => ({
                                ...e[key],
                                id: key,
                            }))
                            this.setState({
                                employees: el,
                            })
                            var employeeOptions = [];
                            el.map((e, index) => (
                                employeeOptions.push({
                                    value: e.id,
                                    label: e.employeeName
                                })
                            ))
                            this.setState({
                                employeeOptions: employeeOptions,
                            })
                            }

                            // PULLING COMPANY PROJECTS FOR DROPDOWN

                            var p = o.projects;
                            if (p) {
                            var pl = Object.keys(p).map(key => ({
                                ...p[key],
                                id: key,
                            }))
                            var projectOptions = [];
                            pl.map((p, index) => (
                                projectOptions.push({
                                    value: p.id,
                                    label: p.projectName
                                })
                            ));
                            this.setState({
                                projectOptions: projectOptions
                            });
                            }

                            // PULLING ROLE OPTIONS FOR DROPDOWN - WILL NEED TO OPTIMIZE LATER

                            this.props.firebase.roles()
                            .orderByChild('createdBy')
                            .equalTo((this.state.uid))
                            .on('value', snapshot => {
                                if (snapshot.exists()) {

                                
                                var roleObj = snapshot.val();
                                var roleList = Object.keys(roleObj).map(key => ({
                                    ...roleObj[key],
                                    id: key,
                                })) 
                                var roleOptions = [];
                                roleList.map((rl, index) => (
                                    roleOptions.push({
                                        value: rl.id,
                                        label: rl.roleName,
                                        projectId: rl.projectId,
                                    })
                                ))
                                this.setState({
                                    roleOptions: roleOptions,
                                })
                                } else {
                                    this.setState({
                                        roleOptions: '',
                                    })
                                }
                                }
                            )

                            // PULLING ALERTS FOR A LISTENE


                            // PULLING ROOM INFO 

                            this.props.firebase.room(roomId)
                            .on('value', snapshot => {
                                if (snapshot.exists()) {
                                var r = snapshot.val();
                                this.setState({
                                    roomName: r.roomName,
                                    activeCheckIn: r.activeCheckIn,
                                    isActive: r.isActive,
                                    checkInId: r.checkInId,
                                    checkInName: r.checkInName,
                                    checkInProject: r.checkInProject,
                                    checkInTime: r.timeCalled,
                                    checkInRole: r.checkInRole,
                                    checkInRepCompany: r.checkInRepCompany,
                                    vip: r.vip ? r.vip : '',
                                    sessionMode: r.sessionMode,
                                    sessionId: r.sessionId,
                                    controller: r.controller,
                                })
                                
                                if (r.sessionMode) {
                                    this.props.firebase.selectCastingSession(authUser.uid, r.sessionId)
                                    .on('value', snapshot => {
                                        if (snapshot.exists()) {
                                            var o = snapshot.val();
                                            var talent = o.talent;
                                            var schedule = o.slotTimes;
                                            var sessionName = o.name;
                                            var roles = o.roles;
                                            var talentList = talent ? Object.keys(talent).map(key => ({
                                                ...talent[key],
                                                id: key,
                                            })) : '';
                                            var roleList = Object.keys(roles).map(key => ({
                                                ...roles[key],
                                                id: key,
                                            }))
                                            
                                            this.setState({
                                                sessionName: sessionName,
                                                sessionRoles: roleList,
                                            })
                                            if (schedule) {
                                                this.setState({
                                                    sessionSchedule: schedule,
                                                })
                                            };
                                            if (talentList) {
                                                var completedTalent = talentList ? talentList.filter(fil => { return fil.wrap }) : '';
                                                this.setState({
                                                   sessionCompletedTalent: completedTalent && completedTalent.length > 0 ? completedTalent : '',
                                                   sessionCompletedIds: completedTalent && completedTalent.length > 0 ? completedTalent.map(t => t.id) : '',             
                                                })
                                            }
                                        } else {
                                            this.setState({
                                                sessionCompletedList: '',
                                                sessionCompletedIds: '',
                                            })
                                        }
                                    })
                                }
                                    // CHECK TO SEE IF THERE IS A SESSION TODAY
                                    this.props.firebase.selectCastingSessions(authUser.uid)
                                    .orderByChild('date')
                                    .equalTo((moment().format('MM/DD/YYYY')))
                                    .once('value', snapshot => {
                                        if (snapshot.exists()) {
                                            var o = snapshot.val();
                                            var l = Object.keys(o).map(key => ({
                                                ...o[key],
                                                id: key
                                            }));
                                            if (l) {
                                                var todaysSessions = l.filter(fil => {
                                                    return fil.room.value === roomId
                                                })
                                                if (todaysSessions) {
                                                    var notPrepped = todaysSessions.filter(fil => {
                                                        return fil.prepped === false;
                                                    });
                                                    if (notPrepped) {
                                                        this.setState({
                                                            sessionsToPrep: notPrepped,
                                                            startSessionPopUp: true,
                                                            loading: false,
                                                        })
                                                    } else {
                                                        this.setState({ loading: false})
                                                    }
                                                }} else {
                                                    this.setState({ loading: false})
                                                }
                                            } else {
                                                this.setState({ loading: false})
                                            }
                                        
                                    })
                                
                                if (r.activeSession && r.activeSession !== '') {
                                    this.setState({
                                        activeSession: r.activeSession,
                                        activeGroup: true,
                                        countOff: r.countOff || false,
                                    })

                                    this.props.firebase.sessionInfo(r.activeSession)
                                    .on('value', snapshot => {
                                        if (snapshot.exists()) {
                                            var so = snapshot.val();
                                            this.setState({
                                                sessionData: so,
                                            })
                                            var externalsObj = so.usersExt;
                                            if (externalsObj) { var externals = Object.keys(externalsObj).map(key => ({
                                                ...externalsObj[key],
                                                id: key,
                                            }))
                                            if (externals !== '' || externals.length > 0) {
                                                this.setState({
                                                    externals: externals,
                                                })
                                            } else {
                                                this.setState({
                                                    externals: '',
                                                })
                                            }
                                        }}
                                    })
                                    this.props.firebase.sessionTags(r.activeSession)
                                    .on('value', snapshot => {
                                        if (snapshot.exists()) {
                                            var o = snapshot.val();
                                            var list = Object.keys(o).map(key => ({
                                                ...o[key],
                                                id: key,
                                            }));
                                            this.setState({
                                                sessionTags: list,
                                            });
                                        } else {
                                            this.setState({
                                                sessionTags: '',
                                            })
                                        }
                                    })
                                    if (r.checkInId !== '') {
                                    this.props.firebase.sessionNotes(r.activeSession, r.checkInId)
                                    .on('value', snapshot => {
                                        if (snapshot.exists()) {
                                            var o = snapshot.val();
                                            var l = Object.keys(o).map(key => ({
                                                ...o[key],
                                                id: key,
                                            }))
                                            if (l) {
                                                this.setState({
                                                    notes: l
                                                });
                                            } else {
                                                this.setState({
                                                    notes: ''
                                                });
                                            }
                                        }
                                    })
                                    }
                                    } else {
                                        this.setState({
                                            activeSession: '',
                                            activeGroup: false,
                                            quickMenu: 'menu'
                                        })
                                    }
                                    if (r.checkInId) {
                                    this.props.firebase.listInSession()
                                    .orderByChild('checkInQId')
                                    .equalTo((r.checkInId))
                                    .once('value', snapshot => {
                                        if (snapshot.exists()) {
                                            var o = snapshot.val();
                                            var l = Object.keys(o).map(key => ({
                                                ...o[key],
                                                id: key,
                                            }))
                                            this.setState({
                                                projectId: l[0].projectId,
                                                roleId: l[0].roleId,
                                            })
                                        };
                                    });
                                    if (r.checkInId) {
                                        this.props.firebase.talentNotes(authUser.uid, r.checkInId)
                                        .on('value', snapshot => {
                                            if (snapshot.exists()) {
                                                var o = snapshot.val();
                                                var l = Object.keys(o).map(key => ({
                                                    ...o[key],
                                                    id: key,
                                                }))
                                                this.setState({
                                                    notes: l,
                                                })
                                            } else {
                                                this.setState({
                                                    notes: '',
                                                })
                                            }
                                        });
                                    };

                                    if (r.checkInId) {
                                        this._openAuditionTakes(authUser.uid, r.checkInId);
                                    };
                                    

                                }

                                this.props.firebase.userAlerts(authUser.uid)
                                    .orderByChild('roomId')
                                    .equalTo((roomId))
                                    .on('value', snapshot => {
                                        if (snapshot.exists()) {
                                            this.setState({
                                                requestPending: true,
                                            })
                                        } else {
                                            this.setState({
                                                requestPending: false,
                                            });
                                            }
                                    })


                            

                                var roomReadersObject = r.roomReaders;
                                
                                var roomProjectsObject = r.activeProjects;
                                var roomRolesObject = r.activeRoles;
                                if (roomReadersObject) {
                                    var roomReaders = Object.keys(roomReadersObject).map(key => ({
                                    ...roomReadersObject[key],
                                    id: key,
                                    }))
                                    if (roomReaders !== '') {
                                        var internalArray = [];
                                        roomReaders.map(item => {
                                            return internalArray.push({
                                                name: item.name,
                                                ref: item.employeeId,
                                                active: true,
                                                isAway: item.isAway ? item.isAway : false,
                                                rrid: item.id,
                                            })
                                        })
                                        this.setState({
                                            attendees: internalArray,
                                        })
                                        var selectedReaderIds = roomReaders.map(reader => {
                                            return reader.employeeId
                                        })
                                        this.setState({
                                            roomReaders: roomReaders,
                                            selectedReaderIds: selectedReaderIds,
                                        })
                                        } else {
                                            this.setState({
                                                roomReaders: '',
                                                selectedReaderIds: ''
                                            })
                                        }
                                } else {
                                    this.setState({
                                        roomReaders: '',
                                        selectedReaderIds: ''
                                    })
                                }
                                if (roomProjectsObject) {
                                    var roomProjects = Object.keys(roomProjectsObject).map(key => ({
                                        ...roomProjectsObject[key],
                                        id: key,
                                    }))
                                    if (roomProjects !== '') {
                                        var selectedProjectIds = roomProjects.map(project => {
                                            return project.projectId
                                        })
                                        this.setState({
                                            roomProjects: roomProjects,
                                            selectedProjectIds: selectedProjectIds,
                                        })
                                    } else {
                                        this.setState({
                                            roomProjects: '',
                                            selectedProjectIds: '',
                                        })
                                    }
                                } else {
                                    this.setState({
                                        roomProjects: '',
                                        selectedProjectIds: '',
                                    })
                                }
                                if (roomRolesObject) {
                                var roomRoles = Object.keys(roomRolesObject).map(key => ({
                                    ...roomRolesObject[key],
                                    id: key,
                                    }))
                                    this.setState({
                                        roomRoles: roomRoles
                                    })
                                } else {
                                    this.setState({
                                        roomRoles: '',
                                    })
                                }
                            }});

                            // PULLING CHECK INS MATCHING THE ROOM
                            
                            
                                this.props.firebase.userCheckins(authUser.uid)
                                .on('value', snapshot => {
                                    if (snapshot.exists()) {
                                        const checkInsObject = snapshot.val();
                                        const checkInsList = Object.keys(checkInsObject).map(key => ({
                                            ...checkInsObject[key],
                                            id: key,
                                        }));
                                        var roleArray = this.state.roomRoles ? this.state.roomRoles.map(role => role.id) : '';
                                        if (roleArray) {
                                            this.setState({
                                                roleArray: roleArray,
                                                allCheckIns: checkInsList,
                                            })
                                            var filterCheckInsList = checkInsList.filter((item) => {
                                                return (roleArray.includes(item.roleId))
                                            })
                                            if (filterCheckInsList.length !== 0) {
                                                var filterDibs = filterCheckInsList.filter(f => {
                                                    return f.dibs !== roomId && f.dibs !== '';
                                                })
                                                if (filterDibs.length === filterCheckInsList.length) {
                                                    this.setState({
                                                        okay: false,
                                                    })
                                                } else {
                                                    this.setState({
                                                        okay: true,
                                                    })
                                                }
                                                this.setState({
                                                    checkIns: filterCheckInsList,
                                                });
                                            } else {
                                                this.setState({
                                                    checkIns: '',
                                                    okay: false,
                                                })
                                            }
                                        } else {
                                            this.setState({
                                                roleArray: '',
                                                checkIns: '',
                                                allCheckIns: checkInsList,
                                            })
                                        };
                                        
                                    } else {
                                        this.setState({
                                            checkIns: '',
                                            okay: false,
                                        })
                                    }
                                })

                            if (this.state.checkIns === false) {
                                this.setState({
                                    okay: false,
                                })
                            }

                            // PULLING COMPLETED CHECKINS MATCHING THE ROOM

                            this.props.firebase.completedUserCheckins(authUser.uid)
                            .on('value', snapshot => {
                                if (snapshot.exists()) {
                                const object = snapshot.val();
                        
                                const list = Object.keys(object).map(key => ({
                                    ...object[key],
                                    id: key,
                                }));

                                this.setState({
                                    completed: list,
                                });

                                } else {
                                this.setState({
                                    completed: '',
                                })
                                }
                            });

                            // MESSAGE FOR NOT AUTHORIZED

                        } else { 
                            this.setState({
                                message: 'You do not have access to this page.',
                            })
                        }
                    } else {
                    console.log('No rooms');
                    }
                })
                : console.log('Please log in');
            }
        )
    };

    componentDidMount() {
            this.setState({
                loadingComplete: true,
            })

    }

    _handlePageLoad = () => {
        
    }

   

    componentWillUnmount() {
        clearTimeout(this.state.newTimer);
        var uid = this.state.uid;
        var roomId = this.state.roomId;
        this.props.firebase.user(uid).off();
        this.props.firebase.userCheckins(uid).off();
        this.props.firebase.userTags(this.state.uid).off();
        if (this.state.checkInId) {
            this.props.firebase.talentTags(this.state.checkInId).off();
        }
        this.props.firebase.completedUserCheckins(uid).off();
        this.props.firebase.roles().off();
        this.props.firebase.room(roomId).off();
        this.props.firebase.selectCastingSession(uid, this.state.sessionId);
        this.props.firebase.talentNotes(uid, this.state.checkInId);
        this.props.firebase.sessionInfo(this.state.activeSession).off();
        this.props.firebase.sessionTags(this.state.activeSession).off();
        this.props.firebase.userAlerts(uid).off();
        if (this.state.checkInId) {
            this.props.firebase.userAuditionTakesTalent(uid, this.state.checkInId).off();
        }
        if (this.state.activeSession) {
            this.props.firebase.allSessionNotes(this.state.activeSession);
            this.props.firebase.sessionInfo(this.state.activeSession);
        }
    }

    _openInviteUser = () => {
        this.setState({
            inviteModal: !this.state.inviteModal,
            userDropdown: false,
            projectDropdown: false,
        })
    }

    _closePopUp = () => {
        this.setState({
            startSessionPopUp: false,
        })
    }


    _prepSession = (session) => {
        var time = moment().format('hh:mm a');
        var uo = session.users ? session.users : '';
        var ro = session.roles ? session.roles : '';
        if (uo) {
        var users = Object.keys(uo).map(key => ({
            ...uo[key], id: key,
        }))} else { users = '' }
        if (ro) {
        var roles = Object.keys(ro).map(key => ({
            ...ro[key], id: key,
        }))
        } else { roles = ''; }

        this.props.firebase.prepSession(this.state.uid, session, users, roles, time);
        this._closePopUp();
    }

    _prepClearTimeout = (timer) =>{
        clearTimeout(timer);
    }

    _sendInvite = () => {

        if (this.state.invitee.includes('@') && this.state.invitee.includes('.')) {
            var sent = moment().format('MM/DD/YYYY h:mm:ss');
            var uid = this.state.uid;
            var room = this.state.roomId;
            var email = this.state.invitee;
            var company = this.state.company;
            var roomName = this.state.roomName;
            var attendees = this.state.attendees;
            var session = this.state.activeSession ? this.state.activeSession : '';
            var sessionId = this.state.sessionId ? this.state.sessionId : '';
            var sessionMode = this.state.sessionMode ? this.state.sessionMode : false;
            var sessionName = this.state.sessionName ? this.state.sessionName : 'Session'; 
            var sessionSchedule = this.state.sessionSchedule ? this.state.sessionSchedule : '';
            this.props.firebase.sendRoomInviteViaEmail(company, uid, room, email, sent, session, roomName, attendees, sessionId, sessionMode, sessionName, sessionSchedule);
            this.setState({
                inviteConfirmMessage: true,
                userDropdown: false,
                projectDropdown: false,
                invitee: '',
            })
            clearTimeout(timer);
            timer = setTimeout(() => {
                this.setState({
                    inviteModal: false
                })
            }, 2000)
            clearTimeout(timer)
        } else {
            alert('Please enter a valid email')
        };
    };

    _closeInvite = () => {
        this.setState({
            inviteModal: false,
            invitee: ''
        })
    }

    onInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    
    _onChangeSearch = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    };

    _sendTalentBackToList = () => {
        var talentId = this.state.checkInId;
        var uid = this.state.uid;
        var apptId = '';
        var apptTime = '';
        var avatar = '';
        var day = moment().format('MM/DD/YYYY');
        var name = this.state.checkInName;
        var project = this.state.checkInProject;
        var rep = this.state.checkInRepCompany;
        var role = this.state.checkInRole;
        var time = this.state.checkInTime;
        var projectId = this.state.projectId;
        var roleId = this.state.roleId;
        var talentUserId = '';

        this.props.firebase.sendBackToQ(
            talentId, name, rep, project, role, time, day, projectId, roleId, uid, apptId, apptTime, talentUserId, avatar
        );

        this.setState({
            checkInId: '',
            checkInName: '',
            checkInProject: '',
            checkInRepCompany: '',
            checkInTimeDay: '',
            checkInTime: '',
            checkInRole: '',
            activeCheckIn: false,
            note: '',
            projectId: '',
            roleId: '',
        });

        this.props.firebase.listInSession()
        .orderByChild('checkInQId')
        .equalTo((talentId))
        .once('value', snapshot => {
            const userObject = snapshot.val();
            const user = Object.keys(userObject).map(key => ({
                ...userObject[key],
                id: key,
            }));

            this.props.firebase.clearUserRoom(uid, this.state.roomId)
            .update({
                activeCheckIn: false,
                checkInId: '',
                checkInName: '',
                checkInProject: '',
                checkInRole: '',
                timeCalled: '',
            });
        
            this.props.firebase.room(this.state.roomId)
            .update({
                activeCheckIn: false,
                checkInId: '',
                checkInName: '',
                checkInProject: '',
                checkInRole: '',
                timeCalled: '',
            });

            this.props.firebase.removeInSession(user[0].id)
            .remove();
        })
    };




    setPriority = (item) => {
        if (item.id !== this.state.vip) {
            this.setState({
                vip: item.id
            });
            this.claimDibs(item);
            this.props.firebase.roomAttributes(this.state.roomId)
            .update({
                vip: item.id
            })
            this.props.firebase.userRoomAttributes(this.state.uid, this.state.roomId)
            .update({
                vip: item.id
            })
        } else {
            this.setState({
                vip: '',
            })
            this.props.firebase.roomAttributes(this.state.roomId)
            .update({
                vip: ''
            });
            this.props.firebase.userRoomAttributes(this.state.uid, this.state.roomId)
            .update({
                vip: ''
            })
        };
    }

    claimDibs = (item) => {
        var talentId = item.id;
        var roomId = this.state.roomId;

        this.props.firebase.checkInDibs(this.state.uid, talentId)
        .update({
            dibs: roomId
        })

        //var roleArray = this.state.roomRoles.map(role => role.id);

        //var filterObjectArray = this.state.checkIns.filter((item) => {
        //    return (item.dibs === this.state.roomId || item.dibs === '') && roleArray.includes(item.roleId);
        //})
    };


    removeDibs = (item) => {
        var talentId = item.id;

        this.props.firebase.checkInDibs(this.state.uid, talentId)
        .update({
            dibs: ''
        })
    };

    _tabCheckedIn = () => {
        this.setState({
            activeTab: 'waiting',
        })
    }

    _tabComplete = () => {
        this.setState({
            activeTab: 'complete',
        })
    }

    _tabChat = () => {
        this.setState({
            activeTab: 'chat',
        })
    }

    _onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    _onNoteChange = (event, value) => {
        this.setState({
            note: event.target.value
        })
    }

    // ROOM MODAL

    _openNotesModal = (item) => {
        this.setState({
            modalType: 'notes',
            modalTalent: item,
        })
        this._showModal();
    }

    _closeModal = () => {
        this.setState({
          modal: false,
          modalType: '',
        })
    }

    _handleOkay = (value) => {
        if (this.state.okay !== value) {
        this.setState({
            okay: value,
        })
        }
    }
    
      _showModal = () => {
        this.setState({
          modal: true,
        })
    }

    _assignSortTime = () => {
        this.setState({
            assignSort: 'time'
        });
    }

    _assignSortAppt = () => {
        this.setState({
            assignSort: 'appt'
        })
    }

    _notesModalBody = (modalTalent) => {
        return <NoteModalBody talent={modalTalent} uid={this.state.uid} firebase={this.props.firebase} />
    }

    currentModalState = () => {
        var { modalType, modalTalent } = this.state;
        
        if (modalType === 'notes') {
          return (
            <ModalContainer
            modalHeader={modalTalent.checkInName}
            modalBody={this._notesModalBody(modalTalent)}
            close={this._closeModal}
            />
            )
        }
    }

    // ASSIGN NEXT FROM THIS ROOM

    assignFunction = (options, roomId) => {
        var talent = options[0];
        var roomName = this.state.roomName;
        var myId = this.state.uid;
        const timestamp = moment().format('hh:mma');
    
        const talentId = talent.id;
        const checkInName = talent.checkInName;
        const checkInProject = talent.checkInProject;
        const checkInRole = talent.checkInRole;
        const checkInRepCompany = talent.checkInRepCompany;
        const checkInRoom = roomId;
        const checkInTime = talent.checkInTime;
        const checkInTimeDay = talent.checkInDay;
        const callInTime = timestamp
        const projectId = talent.projectId;
        const roleId = talent.roleId;
        const accountId = talent.accountId;
        const waitLength = moment(callInTime,"hh:mma").diff(moment(checkInTime,"hh:mma"));
        const waitTime = (waitLength / 1000) / 60;

        this._openAuditionTakes(myId, talentId);
              
        this.props.firebase.inSession(checkInName, checkInProject, checkInRole, checkInRepCompany, checkInRoom, talentId, checkInTime, checkInTimeDay, callInTime, projectId, roleId, waitTime, accountId, this.state.sessionMode, this.state.sessionId);
    
        this.props.firebase.removeCheckInQ(talentId)
        .remove();

        this.props.firebase.userCheckinSelect(accountId, talentId).remove();

        this.props.firebase.assignRoom(roomId)
          .update({
            activeCheckIn: true,
            checkInId: talentId,
            checkInName: checkInName,
            checkInProject: checkInProject,
            checkInRole: checkInRole,
            timeCalled: timestamp,
            checkInRepCompany: checkInRepCompany,
          })
    
        this.props.firebase.assignUserRoom(this.state.uid, roomId)
        .update({
            activeCheckIn: true,
            checkInId: talentId,
            checkInName: checkInName,
            checkInProject: checkInProject,
            checkInRole: checkInRole,
            timeCalled: timestamp,
            checkInRepCompany: checkInRepCompany,
        })

        const message = ("Please send " + checkInName + " to " + roomName);

        // IF EXTERNAL SESSION
        if (this.state.activeGroup === true) {
            this.props.firebase.addSubjectToSessionTalent(accountId, talentId, checkInName, checkInProject, checkInRole, projectId, roleId, checkInRepCompany, this.state.activeSession);
        }

        // IF CASTING SESSION
        if (this.state.sessionMode) {
            this.props.firebase.addSubjectToSession(myId, this.state.sessionId, talentId, checkInName, checkInRepCompany, timestamp, roleId, checkInRole, checkInTime);
        }

        // NOTIFY RECEPTION 

        this.props.firebase.createAlert(myId, roomId, roomName, checkInName, message, timestamp, talentId);

        this.props.firebase.sendToReception(accountId, checkInName, roomName);
        if (this.state.receptionEmail) {
            this.props.firebase.sendEmailToReception(checkInName, roomName, this.state.receptionEmail);
        };

        this.setState({
            checkInId: talentId,
            checkInName: checkInName,
            checkInProject: checkInProject,
            checkInProjectId: projectId,
            checkInRoleId: roleId,
            checkInRole: checkInRole,
            activeCheckIn: true,
            projectId: projectId,
            roleId: roleId,
            checkInRepCompany: checkInRepCompany,
            checkInTime: checkInTime,
            checkInTimeDay: checkInTimeDay,
        });
    };

    assignFromSessionClick = (event) => {

        const { checkIns } = this.state;
    
        var roomId = this.state.roomId;
        var vip = this.state.vip;
    
        this.props.firebase.roomActiveRoles(roomId)
        .once('value', snapshot => {
          if (snapshot.exists()) {
            const obj = snapshot.val();
            const list = Object.keys(obj).map(key => ({
              ...obj[key],
              id: key,
            }));
    
            var roleArray = list.map(role => role.id);
    
            var filteredByRoles = checkIns ? checkIns.filter(f => {
              return roleArray.includes(f.roleId)
            }) : '';
            if (filteredByRoles && filteredByRoles.length > 0) {
              var filteredByDibs = filteredByRoles.filter(fil => {
                return fil.dibs === roomId || fil.dibs === ''
              }) 
              if (filteredByDibs.length > 0) {
                var filteredByVIP = filteredByDibs.filter(filvip => {
                  return filvip.id === vip 
                })
                if (filteredByVIP.length > 0) {
                  this.assignFunction(filteredByVIP, roomId)
                } else {
                  this.assignFunction(filteredByDibs, roomId)
                }
              } else {
                console.log('No one to assign');
              }
            } else {
              console.log('No roles match');
            }
          }
        })
    }

    // COMPLETE AUDITION FROM THIS ROOM 

    _prepCountToComplete = () => {
        var myId = this.state.uid; 
        var userId = this.state.checkInId;
    
        this.props.firebase.talentNotes(myId, userId)
        .once('value', snapshot => {
          if (snapshot.exists()) {
            var o = snapshot.val();
            var l = Object.keys(o).map(key => ({
              ...o[key],
              id: key,
            }))
            this.setState({
              noteCount: l.length,
            })
          } else {
            this.setState({
              noteCount: '0',
            })
          }
        })
    
        this.props.firebase.talentTags(userId)
        .once('value', snapshot => {
          if (snapshot.exists()) {
            var o = snapshot.val();
            var l = Object.keys(o).map(key => ({
              ...o[key],
              id: key,
            }))
            this.setState({
              tagCount: l.length,
            })
          } else {
            this.setState({
              tagCount: '0',
            })
          }}
        )
        clearTimeout(timer);
        timer = setTimeout(() => {
          this.completeFromRoomClick();
        }, 200);
        return(() => clearTimeout(timer));
    }

    completeFromRoomClick = () => {

        var roomId = this.state.roomId;
        var checkInId = this.state.checkInId;
        var userId = this.state.uid;
        var myId = this.state.uid;
        var createdBy = this.state.uid;
        var noteContents = this.state.note;
        var note = noteContents;
        var noteCount = this.state.noteCount;
        var tagCount = this.state.tagCount;
        var activeSession = this.state.activeSession;

        this.setState({
            checkInId: '',
            checkInName: '',
            checkInProject: '',
            checkInRepCompany: '',
            checkInTimeDay: '',
            checkInTime: '',
            checkInRole: '',
            activeCheckIn: false,
            note: '',
            projectId: '',
            roleId: '',
        })

        var timestamp = moment().format('hh:mma');
        const daystamp = moment().format('MM/DD');

        this.props.firebase.userAuditionTakesTalent(myId, checkInId).off();
        this.props.firebase.talentNotes(myId, checkInId).off();

        this.props.firebase.listInSession()
        .orderByChild('checkInQId')
        .equalTo((checkInId))
        .once('value', snapshot => {
            const userObject = snapshot.val();
            const user = Object.keys(userObject).map(key => ({
                ...userObject[key],
                id: key,
            }));

            this.props.firebase.clearUserRoom(myId, roomId)
            .update({
                activeCheckIn: false,
                checkInId: '',
                checkInName: '',
                checkInProject: '',
                checkInRole: '',
                timeCalled: '',
            });
        
            this.props.firebase.room(roomId)
            .update({
                activeCheckIn: false,
                checkInId: '',
                checkInName: '',
                checkInProject: '',
                checkInRole: '',
                timeCalled: '',
            });

            this.props.firebase.removeInSession(user[0].id)
            .remove();

            const checkInName = user[0].checkInName;
            const checkInProject = user[0].checkInProject;
            const checkInRole = user[0].checkInRole;
            const checkInRepCompany = user[0].checkInRepCompany;
            const checkInRoom = roomId;
            const checkInQId = user[0].checkInQId;
            const projectId = user[0].projectId;
            const roleId = user[0].roleId;
            const checkInTime = user[0].checkInTime;
            const checkInTimeDay = user[0].checkInTimeDay;
            const callInTime = user[0].callInTime;
            const waitTime = user[0].waitTime;
            const completedTime = timestamp;
            const accountId = user[0].accountId;
            

            const auditionLength = moment(completedTime,"hh:mma").diff(moment(callInTime,"hh:mma"));
            const totalLength = moment(completedTime,"hh:mma").diff(moment(checkInTime,"hh:mma"));
            const auditionTime = (auditionLength / 1000) / 60;
            const totalTime = (totalLength / 1000) / 60;

            this.props.firebase.completed(checkInName, checkInProject, checkInRole, checkInRepCompany, checkInRoom, checkInQId, projectId, roleId, checkInTime, checkInTimeDay, callInTime, completedTime, waitTime, auditionTime, totalTime, accountId, noteCount, tagCount);

            if (this.state.activeGroup && activeSession !== '') {
                this.props.firebase.deactivateSessionTalent(activeSession, checkInQId);
            };

            if (this.state.sessionMode) {
                this.props.firebase.selectCastingSessionTalent(this.state.uid, this.state.sessionId, checkInQId).update({wrap: timestamp});
            };

            var noteBy = this.state.name;

            if (note !== '') {
                this.props.firebase.addNote(userId, checkInName, projectId, roleId, checkInId, timestamp, daystamp, note, noteBy)
            };

            this.props.firebase.userAlerts(createdBy)
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({
                        ...o[key],
                        id: key,
                    }))
                    var a = l.filter(t => { return t.talentId === checkInQId });
                    if (a.length > 0) { 
                        var alertId = a[0].id;
                        this.props.firebase.removeAlert(myId, alertId).remove();
                    }
                }
            });

            this.props.firebase.addTalentReaders(roomId, checkInId)

        })
    
    }

    
    _addSessionNote = (privacy) => {
        const timestamp = moment().format('hh:mma');
        const daystamp = moment().format('MM/DD'); 
        var talentId = this.state.checkInId;
        var talent = this.state.checkInName;
        var projectId = this.state.projectId;
        var project = this.state.checkInProject;
        var roleId = this.state.roleId;
        var role = this.state.checkInRole;
        var uid = this.state.uid;
        var sess = this.state.activeSession;
        var noteId = false;
        var employee = this.state.name;
        var note = this.state.note;
        this.props.firebase.addSessionNote(sess, noteId, uid, employee, talent, talentId, project, projectId, role, roleId, note, privacy, timestamp, daystamp);
        this.setState({
            note: '',
        })
    }

    _onEnterNote = (event) => {
        if (event.keyCode === 13 && event.shiftKey === false) {
          event.preventDefault();
          this._addNote(event);
        }
    };

    _addNote = () => {
        const timestamp = moment().format('hh:mma');
        const daystamp = moment().format('MM/DD');
        var checkInId = this.state.checkInId;
        var userId = this.state.uid;
        var checkInName = this.state.checkInName;
        var projectId = this.state.projectId;
        var roleId = this.state.roleId;
        var note = this.state.note;
        var noteBy = this.state.name;

        this.props.firebase.addNote(userId, checkInName, projectId, roleId, checkInId, timestamp, daystamp, note, noteBy, this.state.sessionMode, this.state.sessionId);

        this.setState({
            note: '',
        })
    }
    

    _removeNote = (item) => {
        this.props.firebase.removeNoteLive(item.id, this.state.uid, this.state.checkInId, this.state.sessionId, this.state.sessionMode);
    }

    // ADD EMPLOYEE TO ROOM

    _snoozeReader = (item) => {
        var roomId = this.state.roomId;
        var roomReaderId = item.rrid;
        this.props.firebase.snoozeRoomReader(roomId, roomReaderId);
    };

    _unsnoozeReader = (item) => {
        var roomId = this.state.roomId;
        var roomReaderId = item.rrid;
        this.props.firebase.unsnoozeRoomReader(roomId, roomReaderId);
    };

    _addReader = employeeSelect => {
        var timeAdded = moment().format('MM/DD/YY hh:mm a')
        var uid = this.state.uid;
        var name = employeeSelect.label;
        var employeeId = employeeSelect.value;
        var roomId = this.state.roomId;
        var session = this.state.activeSession;

        if (this.state.roomReaders !== '') {
            var readers = this.state.roomReaders;
            var readerIds = readers.map(r => r.employeeId);
            if (readerIds.includes(employeeId)) {
                console.log('Already added')
            } else {
                this.props.firebase.newRoomReader(uid, name, employeeId, roomId, timeAdded);
            }
        } else {
            this.props.firebase.newRoomReader(uid, name, employeeId, roomId, timeAdded);
        }

        if (this.state.activeGroup) {
            this.props.firebase.internalUsersAgenda(session)
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({
                        ...o[key],
                        id: key,
                    }))
                    var fil = l.filter(f => f.ref === employeeId);
                    if (fil.length > 0) {
                        this.props.firebase.updateInternalUserToAgenda(session, fil[0].id);
                    } else {
                        this.props.firebase.addInternalUserToAgenda(session, name, employeeId);
                    }
                } else {
                    this.props.firebase.addInternalUserToAgenda(session, name, employeeId);
                }
            })
        };
    };
    
    // REMOVE EMPLOYEE FROM ROOM 

    _removeReader = (item) => {
        var roomReaderId = item.id;
        var name = item.name;
        var employeeId = item.employeeId;
        var roomId = this.state.roomId;
        var roomReaders = this.state.roomReaders;
        var session = this.state.activeSession;

        if (roomReaders.length === 1) {

            this.props.firebase.removeRoomReaderRoom(roomId, roomReaderId)
            .remove();

            this.props.firebase.removeRoomReader(roomReaderId)
            .remove();

            this.setState({
                roomReaders: '',
            })
        } else {
            this.props.firebase.removeRoomReaderRoom(roomId, roomReaderId)
            .remove();

            this.props.firebase.removeRoomReader(roomReaderId)
            .remove();
        }   
        
        if (this.state.activeGroup) {
            this.props.firebase.internalUsersAgenda(session)
            .orderByChild('ref')
            .equalTo((employeeId))
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({
                        ...o[key],
                        id: key,
                    }))
                    if (l.length > 0) {
                        this.props.firebase.deactivateInternalUserFromAgenda(session, l[0].id);
                    } else {
                    }
                }
            })
        };
    }

    // REMOVE PROJECT FROM DROPDOWN

    _handleRemoveProjectFromDropdown = item => {
        var timeRemoved = moment().format('MM/DD/YY hh:mm a');
        var uid = this.state.uid;
        var name = item.label;
        var projectId = item.value;
        var roomId = this.state.roomId;
        this.props.firebase._removeProjectFromRoomProjectModalDropDown(uid, name, projectId, roomId, timeRemoved);
    }

    // REMOVE READER FROM DROPDOWN 

    _removeReaderFromRoomModalDropdown = (item) => {
        var uid = this.state.uid;
        var name = item.label;
        var employeeId = item.value;
        var roomId = this.state.roomId; 
        var session = this.state.activeSession;
        this.props.firebase.roomReaders()
        .orderByChild('roomId')
        .equalTo((roomId))
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                var thisReader = l.filter(f => {
                    return f.employeeId === employeeId
                });

                var roomReaderId = thisReader[0].id;

                var timeRemoved = moment().format('MM/DD/YY hh:mm a');
                this.props.firebase.roomReader(roomReaderId)
                .once('value', snapshot => {
                    var reader = snapshot.val();
                    var timeAdded = reader.timeAdded;

                    this.props.firebase.archiveRoomReader(uid, name, employeeId, roomId, timeAdded, timeRemoved);

                    this.props.firebase.removeRoomReader(roomReaderId)
                    .remove();
            
                    this.props.firebase.removeRoomReaderRoom(roomId, roomReaderId)
                    .remove();
                })

                if (this.state.activeGroup) {
                    this.props.firebase.internalUsersAgenda(session)
                    .orderByChild('ref')
                    .equalTo((employeeId))
                    .once('value', snapshot => {
                        if (snapshot.exists()) {
                            var o = snapshot.val();
                            var l = Object.keys(o).map(key => ({
                                ...o[key],
                                id: key,
                            }))
                            if (l.length > 0) {
                                this.props.firebase.deactivateInternalUserFromAgenda(session, l[0].id);
                            }
                        }
                    })
                };
            }
        })
    }

    // ADD PROJECT TO ROOM

    _addProject = projectSelect => {
        var timeAdded = moment().format('MM/DD/YY hh:mm a')
        var uid = this.state.uid;
        var name = projectSelect.label;
        var projectId = projectSelect.value;
        var roomId = this.state.roomId;

        if (this.state.roomProjects !== '') {
            var projects = this.state.roomProjects;
            var projectIds = projects.map(p => p.projectId)
            if (projectIds.includes(projectId)) {
                alert('Already added')
            } else {
                this.props.firebase.newRoomProject(uid, name, projectId, roomId, timeAdded);
            }
        } else {
            this.props.firebase.newRoomProject(uid, name, projectId, roomId, timeAdded);
        }  



        // SET THE PROJECT TO ACTIVE IN THE PROJECT TABLE

        this.props.firebase.projectSelect(projectId)
        .update({
            isActive: true,
        });

        // SET THE PROJECT TO ACTIVE IN THE USER TABLE

        this.props.firebase.userProjectAttributes(uid, projectId)
        .update({
            isActive: true,
        });


    }

    // REMOVE PROJECT FROM ROOM AND MOVE TO ARCHIVE

    _removeProject = (item) => {
        var uid = item.uid;
        var name = item.name;
        var projectId = item.projectId;
        var roomProjectId = item.id;
        var roomId = this.state.roomId;
        var timeRemoved = moment().format('MM/DD/YY hh:mm a');
        this.props.firebase.roomProject(roomProjectId)
        .once('value', snapshot => {
            var project = snapshot.val();
            var timeAdded = project.timeAdded;

            if (this.state.roomProjects.length === 1) {
                this.setState({
                    roomProjects: ''
                })
            };

            // ARCHIVE ROOM

            this.props.firebase.archiveRoomProject(uid, name, projectId, roomId, timeAdded, timeRemoved);

            // REMOVE FROM ROOM-PROJECTS TABLE

            this.props.firebase.removeRoomProject(roomProjectId)
            .remove()

            // REMOVE FROM ROOM - ACTIVE PROJECTS TABLE

            this.props.firebase.removeRoomProjectRoom(roomId, roomProjectId)
            .remove();

            // ARCHIVE ROLES THAT WERE ACTIVE WITHIN THE PROJECT FROM ROOM ROLES TABLE AND ARCHIVE

            this.props.firebase.roomActiveRoles(roomId)
            .orderByChild('projectId')
            .equalTo((projectId))
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    var l = Object.keys(o).map(key => ({
                        ...o[key],
                        id: key,
                    }))
                    l.map(role => {
                        var rid = role.roomRoleId;
                        var roleId = role.id;
                        var roleName = role.roleName;
                        
                        this.props.firebase.archiveRoomRole(uid, roleId, projectId, roleName, roomId, timeRemoved);

                        this.props.firebase.roomRole(rid)
                        .remove()

                        this.props.firebase.roomActiveRole(roomId, roleId)
                        .remove()

                        this.props.firebase.roomActiveRoles(roomId)
                        .once('value', snapshot => {
                            if (snapshot.exists()) {
                            var object = snapshot.val();
                            var list = Object.keys(object).map(key => ({
                                ...object[key],
                                id: key,
                            }))
                            this.setState({
                                roomRoles: list,
                            })
                        } else {
                            this.setState({
                                roomRoles: [],
                            })
                        }
                        })
                    })
                }
            })

            // RESET ROLE ARRAY ON PAGE TO UPDATE THE CHECK IN FILTER ON THE RIGHT

            this.props.firebase.roomActiveRoles(roomId)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                var obj = snapshot.val();
                var list = Object.keys(obj).map(key => ({
                    ...obj[key],
                    id: key,
                }))
                var roleArray = list.map(role => role.id);
                this.setState({
                    roleArray: roleArray
                }) 
            } else {
                this.setState({
                    roleArray: '',
                    okay: false,
                })
            }
        })

        })
    }

    // ADD PROJECT ROLE TO ROOM

    _addRole = (projectId, roleName, roleId, roomId, roomProjectId, uid) => {

        if (this.state.roomRoles !== '') {
            var roleIds = this.state.roomRoles.map(i => i.id);

            if (roleIds.includes(roleId)) {
                alert('already added') 
            } else {
                this.props.firebase.newRoomRole(roleId, projectId, roleName, roomId, roomProjectId, uid);
            }   
        } else {
            this.props.firebase.newRoomRole(roleId, projectId, roleName, roomId, roomProjectId, uid);
        }

        this.props.firebase.roleUpdate(roleId)
        .update({
            isActive: true,
        })

        this.props.firebase.projectRoleUpdate(projectId, roleId)
        .update({
            isActive: true,
        })

        // RESET ROLE ARRAY ON PAGE TO UPDATE THE CHECK IN FILTER ON THE RIGHT

        this.props.firebase.roomActiveRoles(roomId)
        .once('value', snapshot => {
            if (snapshot.exists()) {
            var obj = snapshot.val();
            var list = Object.keys(obj).map(key => ({
                ...obj[key],
                id: key,
            }))
            
            this.setState({
                roomRoles: list,
            })
            var roleArray = list.map(role => role.id);
            this.setState({
                roleArray: roleArray
            })
            var checkIns = this.state.allCheckIns;
            var filterCheckInsList = checkIns.filter((item) => {
                return (roleArray.includes(item.roleId))
            })
            if (filterCheckInsList.length !== 0) {
                var filterDibs = filterCheckInsList.filter(f => {
                    return f.dibs !== roomId && f.dibs !== '';
                })
                if (filterDibs.length === filterCheckInsList.length) {
                    this.setState({
                        okay: false,
                    })
                } else {
                    this.setState({
                        okay: true,
                    })
                }
                this.setState({
                    checkIns: filterCheckInsList,
                });
            } else {
                this.setState({
                    checkIns: '',
                    okay: false,
                })
            }
        }
        }
    )};

    updateStateWithTalent = (elements) => {
        this.setState({
            ...elements
        })
    };

    // REMOVE PROJECT ROLE FROM ROOM - INITIALLY THIS ITEM PULLS FROM ROOM/ACTIVE-ROLES

    _removeRole = (item) => {
        this.props.firebase.roomRoles()
        .orderByChild('roleId')
        .equalTo((item.value))
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key
                }))
                var roomRole = l.filter(f => {
                    return f.roomId === this.state.roomId
                });
                var timeRemoved = moment().format('MM/DD/YY hh:mm a');
                var uid = this.state.uid;
                var roomId = this.state.roomId;
                var roleId = item.value;
                var projectId = item.projectId;
                var roleName = item.label;
                var rid = roomRole[0].id;

                if (this.state.roomRoles.length === 1) {
                    this.setState({
                        roomRoles: [],
                        checkIns: '',
                    })
                };

                // REMOVE ROLE FROM ROOM/ACTIVE ROLE TABLE

                this.props.firebase.roomActiveRole(roomId, roleId)
                .remove()

                // ARCHIVE ROLE

                this.props.firebase.roomRole(rid)
                .remove()

                // RESET ROLE ARRAY ON PAGE TO UPDATE THE CHECK IN FILTER ON THE RIGHT

                this.props.firebase.roomActiveRoles(roomId)
                .once('value', snapshot => {
                    if (snapshot.exists()) {
                    var obj = snapshot.val();
                    var list = Object.keys(obj).map(key => ({
                        ...obj[key],
                        id: key,
                    }))
                    var roleArray = list.map(role => role.id);
                    this.setState({
                        roleArray: roleArray,
                    })
                } else {
                    this.setState({
                        roleArray: '',
                        okay: false,
                    })
                }
                })

                this.props.firebase.archiveRoomRole(uid, roleId, projectId, roleName, roomId, timeRemoved);
                        
            }
        })
    }

    _turnOnAddProject = () => {
        this.setState({
            addProjectOn: !this.state.addProjectOn,
            projectDropdown: !this.state.projectDropdown,
            userDropdown: false,
        })
    };

    _turnOffAddProject = () => {
        this.setState({
            addProjectOn: false
        })
    };

    _turnOnAddReader = () => {
        this.setState({
            addReaderOn: !this.state.addReaderOn,
            userDropdown: !this.state.userDropdown,
            projectDropdown: false,
        })
    }

    _turnOffAddReader = () => {
        this.setState({
            addReaderOn: false,
        })
    };

    _closeUserDropdown = () => {
        this.setState({
            userDropdown: false,
        })
    }

    _closeProjectDropdown = () => {
        this.setState({
            projectDropdown: false,
        })
    }

    _closeRoleDropdown = () => {
        this.setState({
            roleDropdown: false,
        })
    }

    _collapseSidebar = () => {
        this.setState({
            sidebar: !this.state.sidebar,
        })
    };

    _addTake = () => {
        var number = this.state.takes ? this.state.takes.length + 1 : 1;
        var talentId = this.state.checkInId;
        var uid = this.state.uid;
        var talent = this.state.checkInName
        var roleId = this.state.roleId;
        var projectId = this.state.projectId;
        var session = this.state.sessionId ? this.state.sessionId : '';
        var sessionMode = this.state.sessionMode ? true : false;
        var time = moment().format('hh:mm:ss a');
        var date = moment().format('MM/DD/YYYY');
        var takeKey = this.props.firebase.db.ref('user-audition-takes/' + uid + '/' + talentId).push().key;
        this.props.firebase.addUserAuditionTake(uid, talentId, number, roleId, projectId, talent, time, date, takeKey, sessionMode, session);

        this.setState({
            activeTake: takeKey,
            countActive: true,
            startTime: time,
        });
    }

    _confirmCount = (count) => {
        this.setState({
            count,
        })
    };

    _favoriteTake = (take) => {
        var uid = this.state.uid;
        var fav = take.favorite;
        var session = this.state.sessionId ? this.state.sessionId : '';
        var sessionMode = this.state.sessionMode ? true : false;
        this.props.firebase.userAuditionTakesTalentTake(uid, this.state.checkInId, take.id)
        .update({
            favorite: fav === '' ? true : !fav,
        })
        if (sessionMode) {
            this.props.firebase.userAuditionSessionTakesTalentTake(uid, session, this.state.checkInId, take.id)
            .update({
                favorite: fav === '' ? true : !fav,
            });
        }
    };

    _addNoteToTake = (take) => {
        var uid = this.state.uid;
        this.props.firebase.userAuditionTakesTalentTake(uid, this.state.checkInId, take.id)
        .update({
            note: this.state.takeNote,
        })
    };

    _addMark = () => {
        var wrapTime = moment().format('hh:mm:ss a');
        var startTime = this.state.startTime;
        var time = this._timeDifference(startTime, wrapTime);
        var take = this.state.takes[0];
        var uid = this.state.uid;
        var talent = this.state.checkInId;
        var session = this.state.sessionId ? this.state.sessionId : '';
        var sessionMode = this.state.sessionMode ? true : false;
        this.props.firebase.addUserAuditionTakeMark(uid, talent, take.id, time, sessionMode, session);
    };

    _timeDifference = (time1, time2) => {
        var diff = moment(time2, "hh:mm:ss a").diff(moment(time1, "hh:mm:ss a"));
        return diff;
    };

    _stopTake = () => {
        const wrapTime = moment().format('hh:mm:ss a');
        const startTime = this.state.startTime;
        
        this.props.firebase.userAuditionTakesTalentTake(this.state.uid, this.state.checkInId, this.state.activeTake)
        .update({
            count: this._timeDifference(startTime, wrapTime),
        });
        if (this.state.sessionMode) {
            this.props.firebase.userAuditionSessionTakesTalentTake(this.state.uid, this.state.sessionId, this.state.checkInId, this.state.activeTake)
            .update({
                count: this._timeDifference(startTime, wrapTime),
            });
        }
        clearTimeout(timer);
        timer = setTimeout(() => {
            this.setState({
                countActive: false,
                count: 0,
                activeTake: '',
            });
            this._prepClearTimeout(timer);
        }, 300)
         clearTimeout(timer);
    }

    //      <div className="select-session-component">
    //         <SessionSelect uid={this.state.uid} roomId={this.state.roomId} firebase={this.props.firebase} sessionId={this.state.sessionId} />
    //      </div>


    _openAuditionTakes = (uid, talentId) => {
        this.props.firebase.userAuditionTakesTalent(uid, talentId)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }));
                var sort = l ? l.sort((a, b) => (a.number > b.number) ? -1 : 1) : '';

                this.setState({
                    takes: l ? sort : '',
                });
            } else {
                this.setState({
                    takes: '',
                });
            };
        })
    };

    // FOR QUICK BAR 

    _openCheckInView = () => {
        this.setState({
          quickMenu: 'checkinview',
        })
      };
    
    _openTalentGreetingView = () => {
    this.setState({
        quickMenu: 'talentgreeting',
    })
    }

    _openQuickTags = () => {
    this.setState({
        quickMenu: 'quicktags',
    })
    };

    _backQuickBar = () => {
    this.setState({
        quickMenu: 'menu',
    })
    };
    
    _openChangeEmployee = () => {
    this.setState({
        employeeChange: true,
    })
    };

    _closeChangeEmployee = () => {
    this.setState({
        employeeChange: false,
    })
    };

    _selectThisEmployee = (emp) => {
        
        const newSessionUserKey = this.props.firebase.sessionUser().push().key;
        this.props.firebase.addRoomActiveUser(this.state.roomId, emp.value, emp.label);
        this.props.firebase.newSessionUser(newSessionUserKey, this.state.uid, emp.label, emp.value, moment().format('hh:mm a'), moment().format('MM/DD/YYYY')) 
        this.selectedState(newSessionUserKey);
        this.setState({
            sessionUserName: emp.label,
            sessionUserId: emp.value,
            thisUserSelected: emp,
        })
        var empTimer = setTimeout(() => {
            this.setState({
                showSelectUser: false,
            })
        }, 500)
        return (() => clearTimeout(empTimer));
    }
    
    _setActiveUser = (employee) => {
        var sessionState = localStorage.getItem( 'SessionState' ) || '';

        var newSessionUserKey = this.props.firebase.sessionUser().push().key;

        this.props.firebase.sessionUserSelect(sessionState)
        .once('value', snapshot => {
            if (snapshot.exists()) {
            const object = snapshot.val();
            this.setState({
                sessionUserName: object.sessionUserName ? object.sessionUserName : '',
                sessionUserId: object.sessionUserId ? object.sessionUserId : '',
            })
            }
        });
        const time = moment().format('hh:mma');
        const day = moment().format('MM/DD/YYYY');
        const uid = this.state.uid;
        if (this.state.sessionUserName === '') {
            let sessionUserName = employee.employeeName;
            let sessionUserId = employee.id;

            this.setState({
                sessionUserName,
                sessionUserId,
            });

            this.props.firebase.newSessionUser(newSessionUserKey, uid, sessionUserName, sessionUserId, time, day);
        }
        if (this.state.sessionUserName !== '' && this.state.sessionUserName !== employee.employeeName) {
            let sessionUserName = employee.employeeName;
            let sessionUserId = employee.id;

            this.setState({
                sessionUserName,
                sessionUserId,
            });

            this.props.firebase.newSessionUser(newSessionUserKey, uid, sessionUserName, sessionUserId, time, day);
        }

        this.selectedState(newSessionUserKey);
        
        this.setState({
            employeeChange: false,
        })
    };
    
    selectedState = (newSessionUserKey) => {
        localStorage.setItem( 'SessionState', newSessionUserKey);
        this.setState({
            sessionState: newSessionUserKey,
    })
    };

    _signOut = () => {
        this.props.firebase.doSignOut();
    };

    _openAccountSettings = () => {
        this.setState({
            rightSideBarClose: true,
        })
        var timer = null;
        clearTimeout(timer);
        timer = setTimeout(() => {
            this.props.history.push(ROUTES.ACCOUNT);
        }, 100);
        return (() => clearTimeout(timer));
    };

    _resetDevice = () => {
        this.setState({
          showDeviceSelect: false
        })
      };
    
    _showDeviceSelect = () => {
        this.setState({
            showDeviceSelect: true,
            editDevice: true,
        })
    };

    exitSessionMode = () => {
        this.setState({
            quickMenu: 'menu'
        });
    }

    enterSessionMode = () => {
        this.setState({
            quickMenu: 'session-mode'
        })
    }

    _changeMobileState = (value) => {
        this.setState({
            mobileState: value,
        })
    };

    _openSessTalentModal = (talent) => {
        this.setState({
            sessModalFade: false,
            sessModalTalent: talent,
            sessTalentModal: true,
        })
    };

    _closeSessTalentModal = () => {
        var modalTimer = null
        this.setState({
            sessModalFade: true,
        })
        modalTimer = setTimeout(() => {
            this.setState({
                sessModalTalent: '',
                sessTalentModal: false,
            })
        }, 200)
        return (() => clearTimeout(modalTimer));
    }





    render() {

        const { attendees, uid, checkInId, note, notes, activeCheckIn, checkIns, checkInName, checkInProject, checkInRole, activeGroup, activeSession } = this.state;
        const { firebase } = this.props;

        const customStyles = {
            option: (provided, state) => ({
              ...provided,
              fontSize: 12,
            }),
            control: (provided, state) => ({
                ...provided,
                fontSize: 12,
                border: 2,
                backgroundColor: '#fefefe'
            }),
            singleValue: (provided, state) => {
              const opacity = state.isDisabled ? 0.5 : 1;
              const transition = 'opacity 300ms';
          
              return { ...provided, opacity, transition };
            }
        }

        return (
            !this.state.loadingComplete ?
            <div />
            :
            this.state.showSelectUser ?
            <div className="select-user-bg">
                <div className="select-user-message">
                <div className="select-user-label">
                    To keep track of your notes, select who is using this device?
                </div>
                <div className="select-user-scroll">
                    <SelectUser 
                        _selectThisEmployee={this._selectThisEmployee} 
                        employees={this.state.employeeOptions} 
                        thisUserSelected={this.state.thisUserSelected}
                    />
                </div>
                </div>

            </div>
            :
            <div className="roompage-container">
                {
                    this.state.authorized === false ?
                    <div>{this.state.message}</div>
                    
                    :
                    <div className="roompage-layout">
                        <div className="layout-left">
                            {
                                this.state.showDeviceSelect && this.state.employees ?
                                showDeviceOwnerSelect(this.state.employees, this.props.firebase, this.state.uid, this._resetDevice)
                                :
                                <div />
                            }
                            {
                                <div id="mobile-bottom-nav">
                                    <MobileNavBar 
                                        _setMobileState={this._changeMobileState}
                                        mobileState={this.state.mobileState}
                                    />
                                </div>
                            }
                            <CSSTransitionGroup
                                transitionName="appear"
                                transitionAppear={true}
                                transitionAppearTimeout={500}
                                transitionEnter={false}
                                transitionLeave={false}
                            >
                            <div className={this.state.navView === 'hidden' ? "room-view-card full-screen" : "room-view-card"}>
                                <img 
                                    className={this.state.settingsOpen ? "room-card-nav-icon-open" : "room-card-nav-icon"} 
                                    src={gearhover} 
                                    alt="Toggle Settings" 
                                    onClick={(() => this.setState({settingsOpen: !this.state.settingsOpen}))}
                                />
                                <img 
                                    className={this.state.settingOpen ? "room-card-invite-icon-open" : "room-card-invite-icon"}
                                    src={invitation}
                                    alt="Invite User"
                                    onClick={this._openInviteUser}
                                />
                                {
                                    this.state.inviteModal ?
                                    <InviteModal 
                                        _closeInvite={this._closeInvite} 
                                        _sendInvite={this._sendInvite}
                                        _onInputChange={this._onInputChange}
                                        inviteConfirmMessage={this.state.inviteConfirmMessage}    
                                    />
                                    :
                                    <div />
                                }
                                <div className="room-card-content">
                                    <div className="room-card-header-room-view">
                                        <div className="room-page-name-container">
                                            <img className="room-page-room-icon" src={roomicon} alt="Room Icon" />
                                            <div className="room-page-room-name">
                                                {this.state.roomName}
                                            </div>
                                            {this.state.requestPending ? <img className="pending-clock" src={pendingclock} alt="Pending user" /> : <div />}
                                        </div>
                                   
                                    </div>
                                    {/*
                                    {   
                                        this.state.settingsOpen ? 
                                        <RoomSettings

                                            // Ids
                                            uid={this.state.uid}
                                            roomId={this.state.roomId}

                                            // Styles
                                            customStyles={customStyles}

                                            // Icons
                                            addplususers={addplususers} 
                                            remove={remove} 
                                            addproject={addproject}

                                            // Select Open/Close Status
                                            addReaderOn={this.state.addReaderOn} 
                                            addProjectOn={this.state.addProjectOn}

                                            // Open/Close Selects
                                            _turnOffAddReader={this._turnOffAddReader} 
                                            _turnOnAddReader={this._turnOnAddReader}
                                            _turnOffAddProject={this._turnOffAddProject} 
                                            _turnOnAddProject={this._turnOnAddProject}

                                            // Dropdown Items
                                            employeeOptions={this.state.employeeOptions}
                                            projectOptions={this.state.projectOptions}
                                            roleOptions={this.state.roleOptions}

                                            selectedReaderIds={this.state.selectedReaderIds}
                                            selectedProjectIds={this.state.selectedProjectIds}

                                            userDropdown={this.state.userDropdown}
                                            projectDropdown={this.state.projectDropdown}
                                            roleDropdown={this.state.roleDropdown}

                                            _closeUserDropdown={this._closeUserDropdown}
                                            _closeProjectDropdown={this._closeProjectDropdown}
                                            _closeRoleDropdown={this.closeRoleDropdown}

                                            // Filter
                                            employeeSearch={this.state.employeeSearch}
                                            projectSearch={this.state.projectSearch}
                                            _onChangeSearch={this._onChangeSearch}


                                            // Selected Items
                                            employeeSelect={this.state.employeeSelect}
                                            projectSelect={this.state.projectSelect}
                                            roleSelect={this.state.roleSelect}

                                            // Settings Lists
                                            roomReaders={this.state.roomReaders}
                                            roomProjects={this.state.roomProjects}
                                            roomRoles={this.state.roomRoles}

                                            // Add Items
                                            _addReader={this._addReader}
                                            _addProject={this._addProject}
                                            _addRole={this._addRole}


                                            // Remove Items
                                            _removeReader={this._removeReader}
                                            _removeProject={this._removeProject}
                                            _removeRole={this._removeRole}

                                            // Remove Items from Dropdown
                                            _removeReaderFromRoomModalDropdown={this._removeReaderFromRoomModalDropdown}
                                            _handleRemoveProjectFromDropdown={this._handleRemoveProjectFromDropdown}

                                            // Externals
                                            externals={this.state.externals}
                                            
                                            groupRef={this.state.groupRef}
                                            sessionId={this.state.sessionId}
                                            sessionMode={this.state.sessionMode}
                                            

                                        />
                                    */}
                                        
                                        <RoomTalent 

                                            mobileState={this.state.mobileState}
                                            _openSessTalentModal={this._openSessTalentModal}

                                            _sendTalentBackToList={this._sendTalentBackToList}
                                            
                                            roleArray={this.state.sessionRoles ? this.state.sessionRoles.map(r => r.id) : ''}
                                            
                                            sessionData={this.state.sessionData}
                                            controller={this.state.controller}
                                            assignFromRoomClick={this.assignFromRoomClick}
                                            _prepCountToComplete={this._prepCountToComplete}
                                            assignFromSessionClick={this.assignFromSessionClick}
                                            _snoozeReader={this._snoozeReader}
                                            _unsnoozeReader={this._unsnoozeReader}
                                            attendees={this.state.attendees}
                                            updateStateWithTalent={this.updateStateWithTalent}

                                            firebase={firebase}
                                            uid={uid}
                                            roomId={this.state.roomId}
                                            sessionMode={this.state.sessionMode}
                                            sessionId={this.state.sessionId}

                                            notes={notes}
                                            note={note}

                                            activeCheckIn={activeCheckIn}
                                            checkInId={checkInId}
                                            checkIns={checkIns}
                                            checkInName={checkInName}
                                            checkInProject={checkInProject}
                                            checkInRole={checkInRole}

                                            roomName={this.state.roomName}

                                            _onNoteChange={this._onNoteChange}
                                            _addNote={this._addNote}
                                            _removeNote={this._removeNote}
                                            _onEnterNote={this._onEnterNote}
                                            _addSessionNote={this._addSessionNote}

                                            settingsOpen={this.state.settingsOpen}

                                            // Session Related
                                            activeSession={activeSession}
                                            activeGroup={activeGroup}
                                            sessionTags={this.state.sessionTags}

                                            projectId={this.state.projectId}
                                            roleId={this.state.roleId}
                                            rep={this.state.checkInRepCompany}

                                            okay={this.state.okay}
                                            sidebar={this.state.sidebar}

                                            // Takes related 

                                            takes={this.state.takes}
                                            _addTake={this._addTake}
                                            _stopTake={this._stopTake}
                                            _favoriteTake={this._favoriteTake}
                                            _addNoteToTake={this._addNoteToTake}

                                            count={this.state.count}
                                            countActive={this.state.countActive}

                                            _confirmCount={this._confirmCount}
                                            _addMark={this._addMark}

                                            groupRef={this.state.groupRef}
                                            sessUserName={this.state.sessionUserName}
                                            sessUserId={this.state.sessionUserId}
                                            sessionName={this.state.sessionName}

                                            countOff={this.state.countOff}

                                        />
                                </div>
                                </div>
                            </CSSTransitionGroup>
                        </div>

                        <div id="right-side-bar" className="right-side">
                            <div className={this.state.sidebar ? 
                                "minimize-arrow arrow-right" : 
                                "minimize-arrow arrow-left"} 
                                onClick={this._collapseSidebar.bind(this)}>
                                    <img className="toggle-arrow" 
                                    src={arrowdown} 
                                    alt="Toggle Sidebar" 
                                />
                            </div>
          
                            <div className={
                                this.state.sidebar ? 
                                "check-in-container-room" : 
                                "check-in-container-room room-min-width" 
                                }
                            >
                            {
                            this.state.sidebar === false ? 
                            <div onClick={this._collapseSidebar.bind(this)}>
                            
                            </div>
                            :
                            this.state.quickMenu === 'talentgreeting' ||
                            this.state.quickMenu === 'quicktags' ||
                            this.state.quickMenu === 'menu' ?
                            
                            <QuickMenuComponent 
                                firebase={this.props.firebase}
                                state={this.state}
                                onInputChange={this.onInputChange}
                                _updateGreeting={this._updateGreeting}
                                _backQuickBar={this._backQuickBar}
                                _openTalentGreetingView={this._openTalentGreetingView}
                                _openCheckInView={this._openCheckInView}
                                _openQuickTags={this._openQuickTags}
                                _checkInLength={this.state.checkIns ? this.state.checkIns.length : 0}
                                _openChangeEmployee={this._openChangeEmployee}
                                _closeChangeEmployee={this._closeChangeEmployee}
                                _setActiveUser={this._setActiveUser}
                                ROUTES={ROUTES}
                                dashboardBtn={true}
                                _signOut={this._signOut}
                                _openAccountSettings={this._openAccountSettings}
                                _showDeviceSelect={this._showDeviceSelect}
                                _resetDevice={this._resetDevice}
                                enterSessionMode={this.enterSessionMode}
                                activeSession={this.state.activeSession}
                                groupId={this.state.activeSession}
                            />
                            :
                            this.state.quickMenu === 'session-mode' ?
                            <SessionPanel 
                                externals={this.state.externals}
                                internals={this.state.attendees}
                                sessionMode={this.state.sessionMode}
                                sessionId={this.state.sessionId}
                                groupId={this.state.activeSession}
                                exitSessionMode={this.exitSessionMode}
                                firebase={this.props.firebase}
                                uid={this.state.uid}
                                sessionData={this.state.sessionData}
                            />
                            :
                            this.state.quickMenu === '' ?
                            <div />
                            :
                            <div>
                                <div>
                                    <div className="quick-bar-back-btn">
                                        <button className="quick-bar-back" onClick={this._backQuickBar}>Back</button>
                                    </div>
                                    <div className="greeting-header">
                                        <div className="greeting-label">Filtered by Room</div>
                                    </div>
                                    <div className="home-page-tab-container">
                                        <div className={this.state.activeTab === 'waiting' ? "home-page-tab active-tab" : "home-page-tab"} onClick={this._tabCheckedIn}>WAITING</div>              
                                        <div className={this.state.activeTab === 'complete' ? "home-page-tab active-tab" : "home-page-tab"} onClick={this._tabComplete}>COMPLETE</div>
                                    </div>
                                </div>
                                {
                                    this.state.activeTab === 'complete' ?
                                    <div className="check-in-box">
                                        <div id="room-page-check-in-cards" className="check-in-scroll">
                                        {
                                            this.state.completed && this.state.completed !== '' ?
                                            this.state.completed.map((item, index) => {
                                                if (this.state.sessionCompletedIds && this.state.sessionCompletedIds !== '' && this.state.sessionCompletedIds.length > 0) 
                                                    {
                                                    if (this.state.sessionCompletedIds.includes(item.id)) {
                                                    return (
                                                        <div key={item.id} className="check-in-card-container">
                                                        <div className="check-in-cards" onClick={this._openNotesModal.bind(this, item)}>
                                                            <div className="check-card-elements">
                                                                <div className="check-in-card-name">{item.checkInName}</div>
                                                                <div className="check-in-card-line-two text-bold">{item.checkInProject}</div>
                                                                <div className="check-in-card-line-two"> for</div>
                                                                <div className="check-in-card-line-two text-bold">'{item.checkInRole}'</div>
                                                                <div className="check-in-card-rep">Rep: {item.checkInRepCompany}</div>
                                                                <div className="check-in-card-count-container">
                                                                <div className="check-in-card-count">
                                                                    <img className="talent-count-icon" src={noteCountIcon} alt="Note Count" />
                                                                    <div className="count-number">{item.notes}</div>
                                                                </div>
                                                                <div className="check-in-card-count">
                                                                    <img className="talent-count-icon" src={tagCountIcon} alt="Tag Count"/>
                                                                    <div className="count-number">{item.tags}</div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    )
                                                }}
                                            })
                                            :
                                            <div className="default-null-message">There have been no actors read in this room today</div>
                                        }
                                        </div>
                                    </div>
                                    :
                                    <CheckInList 
                                        state={this.state}
                                        bookmark={bookmark}
                                        bookmarkoff={bookmarkoff}
                                        starOn={starOn}
                                        starOff={starOff}
                                        claimDibs={this.claimDibs}
                                        removeDibs={this.removeDibs}
                                        setPriority={this.setPriority}
                                        _handleOkay={this._handleOkay}
                                        firebase={this.props.firebase}
                                        _assignSortTime={this._assignSortTime}
                                        _assignSortAppt={this._assignSortAppt}
                                    />
                                    }
                            </div>
                            }   
                        </div>
                    </div>
                {
                this.state.modal ?
                <div className="modals-container">{this.currentModalState()}</div>
                :
                <div></div>
                }
                { !this.state.sessionId ? 
                
                    this.state.startSessionPopUp ?
                    <StartSessionPopUp sessionsToPrep={this.state.sessionsToPrep} _prepSession={this._prepSession} _close={this._closePopUp}/>
                    :
                    <div />
                :
                <div />

                }
                {
                    this.state.sessTalentModal ?
                    <div className={!this.state.sessModalFade ? "fadeInHeader" : "fadeOutHeader"}>
                        <SessionTalentModal 
                            modalTalent={this.state.sessModalTalent} 
                            close={this._closeSessTalentModal} 
                            groupSess={this.state.activeSession}
                            castSess={this.state.sessionId}
                            uid={this.state.uid}
                            sessUserId={this.state.sessionUserId}
                            sessUserName={this.state.sessionUserName}
                            firebase={this.props.firebase}
                        />
                    </div>
                    :
                    <div />
                }
            </div>
        }
        </div>
        )}
} ;

const condition = authUser => !!authUser;

export default withAuthorization(condition)(RoomPage);

const RoomPageForm = compose(
    withFirebase,
    withRouter,
    withAuthentication
    )(RoomPageFormBase);

export { RoomPageForm };


/*
{
this.state.checkIns !== '' ?
<div>
    <div>
    {this.state.checkIns.map((item, index) => (
    <div key={index} className="transparent-checkin">
        <div className="transparent-checkin-name">{item.checkInName}</div>
    </div>
    ))}
    </div>
</div>
:
<div className="transparent-no-checkins">

</div>
}
*/