import React, { useEffect, useState } from 'react';

import moment from 'moment';

export default function QuickSessionModal(props) {

    const [rooms, setRooms] = useState('');
    const [projects, setProjects] = useState('');
    const [roles, setRoles] = useState('');
    const [roleSelects, setRoleSelects] = useState([]);
    const [room, setRoom] = useState('');
    const [project, setProject] = useState('');
    const [users, setUsers] = useState([]);
    const [roleSelectIds, setRoleSelectIds] = useState([]);
    const [page, setPage] = useState(1);
    const [roleMatches, setRoleMatches] = useState('');
    const [userMatches, setUserMatches] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        props.firebase.userRooms(props.uid)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var list = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                var filter = list ? list.filter(fil => {
                    return fil.isActive === false
                }) : '';
                setRooms(filter);
            }
        });

        props.firebase.userProjects(props.uid)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var list = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                setProjects(list);
            }
        });
    }, [])

    function selectProject(item) {
        setProject(item);
        props.firebase.viewProjectRoles(item.id)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var list = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                setRoles(list);
            }
        });
        var page2timer = setTimeout(() => {
            setPage(2);
        }, 200);
        return (() => clearTimeout(page2timer));
    }

    function selectRole(item) {
        var ids = roleSelectIds;
        var name = item.roleName;
        var id = item.id;
        var newNames = ids.push({name: name, id: id});
        setRoleMatches(ids.map(i => i.id));
    }

    function selectUser(item) {
        var us = users; 
        var name = item.employeeName;
        var id = item.id;
        var newNames = us.push({name: name, id: id});
        setUserMatches(us.map(i => i.id));
    }

    function prepCastingSession(item) {
        setLoading(true);
        var date = moment().format('MM/DD/YYYY');
        var room = {label: item.roomName, value: item.id};
        var uid = props.uid;
        var projectSelect = {label: project.projectName, value: project.id};
        var sessName = projectSelect.label + ' - ' + moment().format('MM/DD');
        var time = moment().format('hh:mm a');
        var sessKey = props.firebase.db.ref('user-casting-sessions/' + props.uid).push().key;
        var groupKey = props.firebase.db.ref('session-agenda').push().key;
        props.firebase.db.ref('user-casting-sessions/' + uid + '/' + sessKey) 
        .update({
            name: sessName,
            date: date,
            start: moment().format('hh:mm a'),
            wrap: '7:00 pm',
            users: '',
            room: room,
            project: projectSelect,
            roles: '',
            draft: false,
            active: true,
            started: true,
            startTime: time,
            wrapped: false,
            prepped: true,
            talent: '',
            groupKey: groupKey,
        })

        var usersTimer = setTimeout(() => {
            if (users) {
                users.map((item, index) => {
                    if (item.name) {
                        return props.firebase.addUserToSessionFromQuick(props.uid, sessKey, item.id, item.name);
                    }
                })
            }
        }, 500);

        var rolesTimer = setTimeout(() => {
            if (roleSelectIds) {
                roleSelectIds.map((item, index) => {
                    return props.firebase.addRoleToSessionFromQuick(props.uid, sessKey, item.id, item.name);
                })
            }
        }, 500);

        props.firebase.db.ref('session-agenda/' + groupKey)
        .update({
            company: '',
            room: '',
            usersInt: '',
            usersExt: '',
            focus: '',
            talent: '',
            sessionName: sessName,
            schedule: '',
            scheduleOff: true,
            countOff: false,
            customMark: false,
        });

        props.firebase.db.ref('user-sessions/' + props.uid + '/' + groupKey)
        .update({
            name: sessName,
            room: room,
            date: date,
        });

        props.firebase.db.ref('user-casting-group-session-connect/' + props.uid + '/' + sessKey)
        .update({
            group: groupKey
        });

        var prepRoomTimer = setTimeout(() => {
            props.firebase.roomAttributes(room.value).update({ isActive: true, sessionMode: true, sessionId: sessKey, activeSession: groupKey});
            props.firebase.userRoomAttributes(uid, room.value).update({ isActive: true, sessionMode: true, sessionId: sessKey, activeSession: groupKey });

            props.firebase.projectAttributes(projectSelect.value).update({ isActive: true });
            props.firebase.userProjectAttributes(props.uid, projectSelect.value).update({ isActive: true });
            props.firebase.newRoomProject(props.uid, projectSelect.label, projectSelect.value, room.value, time);

            roleSelectIds.map(i => {
                return (
                    props.firebase.roleAttributes(i.id).update({ isActive: true }),
                    props.firebase.projectRoleAttributes(projectSelect.value, i.id).update({ isActive: true }),
                    props.firebase.newRoomRole(i.id, projectSelect.value, i.name, room.value, uid)
                );
            });

            users.map(u => {
                if (u.name) {
                    return (
                        props.firebase.newRoomReader(uid, u.name, u.id, room.value, time)
                    );
                }
            });

            props.firebase.selectCastingSession(uid, sessKey)
            .update({
                prepped: true,
                preptime: time,
                draft: false,
            })
        }, 1000)

        var loadingTimer = setTimeout(() => {
            setLoading(false);
            setPage(5)
        }, 1500);

        return (() => clearTimeout(usersTimer, rolesTimer, prepRoomTimer, loadingTimer));
    };


    return (
        loading ?
        <div className="preparing-room">
            <div className="preparing-room-text">Preparing room for you</div>
            <div className="preparing-room-loading">Loading</div>
        </div>
        :
        page === 1 ?
        <div><Page1 projects={projects} selectProject={selectProject}/></div> :
        page === 2 ?
        <div><Page2 roles={roles} selectRole={selectRole} roleMatches={roleMatches} setPage={setPage} /></div> : 
        page === 3 ?
        <div><Page3 userMatches={userMatches} emps={props.employees} selectUser={selectUser} setPage={setPage} /></div> : 
        page === 4 ?
        <div><Page4 rooms={rooms} prepCastingSession={prepCastingSession} /></div> :
        page === 5 ?
        <div><Page5 /></div> :
        <div />
    )
};

function Page1(props) {
    return (
        <div className="quick-page-padding">
            <div className="quick-page-action">Which project?</div>
            <div className="modern-scroll">
            {
                props.projects ?
                props.projects.map((item, index) => (
                    <div className="quicksess-project-item cur-point" onClick={props.selectProject.bind(this, item)}>{item.projectName}</div>
                ))
                :
                <div />
            }
            </div>
        </div>
    )
}

function Page2(props) {

    return (
        <div className="quick-page-padding">
            <div className="quick-page-action">Which roles are casting?</div>
            <div className="modern-scroll">
            {
                props.roles ?
                props.roles.map((item, index) => (
                    item !== '' && item.roleName !== '' ? 
                    <div 
                        key={item.id} 
                        onClick={props.selectRole.bind(this, item)} 
                        className={
                            props.roleMatches.includes(item.id) ? 
                            "quicksess-role-item cur-point quicksess-select" :
                            "quicksess-role-item cur-point"
                        }>
                        {item.roleName}
                    </div>
                    :
                    null
                ))
                :
                <div />
            }
            </div>
            <button onClick={() => props.setPage(3)} className="qui-next-button">Next</button>
        </div>
    )
}

function Page3(props) {
    return (
        <div className="quick-page-padding">
            <div className="quick-page-action">Who will be in the session?</div>

            <div className="modern-scroll">
            {
                props.emps ?
                props.emps.map((item, index) => (
                    <div onClick={props.selectUser.bind(this, item)} key={item.id} 
                        className={
                            props.userMatches.includes(item.id) ? 
                            "quicksess-role-item cur-point quicksess-select" :
                            "quicksess-role-item cur-point"
                        }
                        >{item.employeeName}</div>
                ))
                :
                <div />
            }
            </div>
            <button onClick={() => props.setPage(4)} className="qui-next-button">Next</button>
        </div>
    )
}

function Page4(props) {
    return (
        <div className="quick-page-padding">
            <div className="quick-page-action">Click a room to prep the session!</div>
            {
                props.rooms ?
                props.rooms.map((item, index) => (
                    <div 
                        onClick={props.prepCastingSession.bind(this, item)}
                        key={item.id} 
                        className="quicksess-role-item cur-point">
                        {item.roomName}
                    </div>
                ))
                :
                <div />
            }
        </div>
    )
};

function Page5() {
    return (
        <div className="quick-page-padding">
            <div className="quick-page-action">All set! Your room and form are set up and ready for the session.</div>
            
        </div>
    )
}
