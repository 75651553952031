import React, { Component } from 'react';

import moment from 'moment';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

import "react-datepicker/dist/react-datepicker.css";

const initialState = {
    name: '',
    project: '',
    role: '',
    type: {
        value: 'Casting Reading',
        label: 'Casting Reading',    
        },
    hr: '',
    min: '',
    ampm: 'am',
    date: new Date(),

    roleSelectActive: false,

    projectOptions: '',
    roleOptions: '',
    typeOptions: [
        {
            value: 'Casting Reading',
            label: 'Casting Reading',
        },
        {
            value: 'Director Reading',
            label: 'Director Reading',
        },
    ]
}

export default class ScheduleForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...initialState
        }
    }

    componentWillMount() {

        this.setState({
            date: new Date(),
        })

        this.props.firebase.projects()
        .orderByChild('createdBy')
        .equalTo((this.props.uid))
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                if (l) { this.setState({ projects: l }) 
                
                var projectOptions = [];
                l.map(proj => {
                    return projectOptions.push({
                        value: proj.id,
                        label: proj.projectName,
                    })
                })
                this.setState({
                    projectOptions,
                })

                } else { this.setState({ projects: '', projectOptions: '' }) }
            } else { this.setState({ projects: '', projectOptions: '' })
            }
        })
    }

    _onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    };

    _selectProject = project => {
        this.setState({
            project,
        })

        this.props.firebase.roles()
        .orderByChild('projectId')
        .equalTo((project.value))
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key
                }));
                if (l) { this.setState({ roles: l })
                
                var roleOptions = [];
                l.map(role => {
                    return roleOptions.push({
                        value: role.id,
                        label: role.roleName,
                        projectId: role.projectId,
                    })
                })
                this.setState({
                    roleOptions,
                })
            } else { this.setState({ roles: '', roleOptions: '' })}
            } else { this.setState({ roles: '', roleOptions: '' })}
        })
    }

    _selectRole = role => {
        this.setState({
            role,
        })
    }

    _selectType = type => {
        this.setState({
            type,
        })
    }

    _selectDate = date => {
        this.setState({
            date
        })
    }

    _onSubmitAppt = () => {
        var s = this.state;
        var createdOn = moment().format('MM/DD/YYYY');
        var date = format(new Date(s.date), 'MM/dd/yyyy');
        var hr = (s.ampm === 'pm' && (Number(s.hr) < 12)) ? (Number(s.hr) + 12).toString() : 
                (s.ampm === 'am' && (Number(s.hr) === 12)) ? 12 : 
                s.hr;

        this.props.firebase.createAppt(this.props.uid, s.name, s.project.value, s.project.label, s.role.value, s.role.label, s.type.label, hr, s.min, s.ampm, date, createdOn);
        this.setState({
            project: '',
            role: '',
            hr: '',
            min: '',
            ampm: 'am',
            date: new Date(),
            name: '',
            type: {
                value: 'Casting Reading',
                label: 'Casting Reading',    
            },
        })
    }

    _onSubmitApptAnother = () => {
        var s = this.state;
        var createdOn = moment().format('MM/DD/YYYY');
        var date = format(new Date(s.date), 'MM/dd/yyyy');
        var hr = s.ampm === 'pm' ? (Number(s.hr) + 12).toString() : s.hr;

        this.props.firebase.createAppt(this.props.uid, s.name, s.project.value, s.project.label, s.role.value, s.role.label, s.type.label, hr, s.min, s.ampm, date, createdOn);
        this.setState({
            name: '',
            hr: '',
            min: '',
            ampm: 'am',
            type: {
                value: 'Casting Reading',
                label: 'Casting Reading',    
            },
        })
    }

    render() {

        const { name, project, role, type, date, hr, min, ampm, projectOptions, roleOptions, typeOptions } = this.state;

        const isValid = this.state.name !== '' 
                        && this.state.project !== '' 
                        && this.state.role !== '' 
                        && this.state.type !== ''
                        && this.state.hr !== ''
                        && this.state.min !== ''
                        && this.state.date !== '';

        return (
            <div className="schedule-form">
                <div className="schedule-form-content">
                <div className="schedule-form-quick-add-container">
                    <div className="input-label">Schedule Generator (New)</div>
                    <button className="schedule-generator-btn" onClick={this.props.togglePage.bind(this)}>Try it now!</button>
                </div>
                <div className="input-container">
                    <div className="input-label">Select Project</div>
                    <Select 
                        placeholder="Select project..."
                        value={project}
                        options={projectOptions ? projectOptions : [{value: '', label: 'No projects'}]}
                        onChange={this._selectProject}
                    />
                </div>
                <div className="input-container">
                    <div className="input-label">Select Role</div>
                    <Select 
                        placeholder="Select role..."
                        value={role}
                        options={roleOptions ? roleOptions : [{value: '', label: 'No roles'}]}
                        onChange={this._selectRole}
                        isDisabled={this.state.project === '' ? true : false}
                    />
                </div>
                <div className="input-container">
                    <div className="input-label">Appointment Type</div>
                    <Select 
                        placeholder="Select type..."
                        value={type}
                        options={typeOptions}
                        onChange={this._selectType}
                    />
                </div>
                <div className="input-container">
                    <div className="input-label">Actor's Name</div>
                    <input className="schedule-form-input" autoComplete="off" name="name" value={name} onChange={this._onChange.bind(this)} placeholder="Type name..."/>
                </div>
                <div className="input-container">
                    <div className="input-label">Date</div>
                    <DatePicker 
                        selected={date} 
                        onChange={this._selectDate} 
                        className="date-picker" 
                        dateFormat="MM / dd / yyyy"
                        />
                </div>
                <div className="input-container">
                    <div className="input-label">Time</div>
                    <div className="time-input-container">   
                        <input className="schedule-form-time-input" maxLength="2" name="hr" value={hr} placeholder="08" onChange={this._onChange.bind(this)}/>
                        <input className="schedule-form-time-input" maxLength="2" name="min" value={min} placeholder="15" onChange={this._onChange.bind(this)}/>
                        <select className="schedule-form-time-input" name="ampm" value={ampm} onChange={this._onChange.bind(this)} >
                            <option value="am">AM</option>
                            <option value="pm">PM</option>
                        </select>
                    </div>
                </div>
                <button className="add-to-schedule-btn" disabled={isValid ? false : true} onClick={this._onSubmitAppt}>Set</button>
                <button className="add-to-schedule-btn" disabled={isValid ? false : true} onClick={this._onSubmitApptAnother}>Set and Add Another</button>
                </div>
            </div>
        )
    }
}