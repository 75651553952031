import React, { useState, useEffect } from 'react'; 
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { withAuthorization, withAuthentication } from '../Session';
import * as ROUTES from '../../constants/routes';
import { compose } from 'recompose';
import moment from 'moment';

function VerCompose(props) {

    const [data, setData] = useState('');
    const [id, setId] = useState('');

    useEffect(() => {
        if (window.location.hash) {
            var iden = window.location.hash.substr(1);
            setId(iden);
        }
    }, []);

    function checkCode() {
        props.firebase.companyAccountRecover(id)
        .once('value', snapshot => {
            var o = snapshot.val();
            if (o.code === code) {
                setData(o);
                var timer = setTimeout(() => {
                    setShow(true);
                }, 500);
                return (() => clearTimeout(timer))
            };
        });
    }

    const [code, setCode] = useState('');
    const [show, setShow] = useState(false);

    return (
        <div>
            {!show ? 
            <CodeDisplay checkCode={checkCode} code={code} setCode={setCode} /> :
            <Complete id={id} data={data} history={props.history} firebase={props.firebase} />
            }
        </div>
    )
}

function CodeDisplay(props) {

    return (
        <div className="code-container">
            <h2>Please enter verification code</h2>
            <input className="ver-input" value={props.code} onChange={(e) => props.setCode(e.target.value)} />
            <button onClick={() => props.checkCode()}>Submit</button>
        </div>
    )
}

function Complete(props) {

    const [pass, setPass] = useState('');
    const [repass, setRepass] = useState('');

    function register() {

        props.firebase.doCreateUserWithEmailAndPassword(props.data.email, pass)
        .then(authUser => {
            props.firebase.apiUser(authUser.user.uid)
            .set({
                name: props.data.name,
                email: props.data.email, 
                company: props.data.cid,
                companyName: props.data.co,
                permission: 'basic',
                created: moment().format('MM/DD/YYYY hh:mm a'),
                positionType: '',
                lastUpdate: 0,
            });
            props.firebase.apiCompanyAccount(props.data.cid, authUser.user.uid)
            .update({
                avatar: '',
                name: props.data.name,
                email: props.data.email, 
                permission: 'basic',
                created: moment().format('MM/DD/YYYY hh:mm a'),
                emailNotifications: false,
                positionType: '',
            });
            props.firebase.apiTempAccount(props.data.cid, props.id).remove();
            props.firebase.companyAccountRecover(props.id).remove();
        })
        .then(() => {
            var time = setTimeout(() => {
                props.history.push(ROUTES.HOME);
            }, 300);
            return (() => clearTimeout(time));
        })
        .catch(error => {
            console.log(error);
        });
    }

    return (
        <div className="finish-sign-up-container">
            <h3>Dear {props.data.name},</h3>
            <h4>Please create a password to complete sign up.</h4>
            <div className="in-container">
                <h3>Email</h3>
                <h5>{props.data.email}</h5>
            </div>
            <div className="in-container">
                <h3>Password</h3>
                <input type="password" className="pass-in" value={pass} onChange={(e) => setPass(e.target.value)} />
            </div>
            <div className="in-container">
                <h3>Confirm Password</h3>
                <input type="password" className="pass-in" value={repass} onChange={(e) => setRepass(e.target.value)} />
            </div>
            <button disabled={pass !== repass || pass.length < 8} className="complete-su-btn" onClick={() => register()}>Complete Sign Up</button>
        </div>
    )
}

const condition = authUser => !!authUser;

const CompanyVerification = compose(
    withFirebase,
    withRouter,
    )(VerCompose);

export default CompanyVerification;
  