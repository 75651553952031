import React from 'react';

export default function TagsList(props) {

    var tags = 
        props.userTags && props.tagGroup === 'All' ? 
        props.userTags : 
        props.userTags && props.tagGroup !== 'All' ?
            props.userTags.filter(fil => {
                return props.groupTagIds.includes(fil.id)
            })
        : '';

    return (
        <div className="sly-tag-add-box">
            {
                tags && tags.length > 0 ?
                    tags
                    .sort((a, b) => (a.name > b.name) ? 1 : -1)
                    .map((item, index) => {
                        return (
                            <div 
                                key={item.id} 
                                className={
                                    props.selectedIds && props.selectedIds.includes(item.id) ?
                                    "sly-tag-item sly-tag-selected" :
                                    "sly-tag-item"
                                }
                                onClick={
                                    props.selectedIds && props.selectedIds.includes(item.id) ?
                                    props._removeTalentTag.bind(this, item) : 
                                    props._addTalentTag.bind(this, item)
                                }
                                >
                                {item.name}
                            </div>
                            )
                        }
                    )
                    :
                    <div className="null-text">
                        No tags in this group.
                    </div>
                }
            <div className="height-45" />
        </div>
    )
}
