import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import { askForPermissionsToReceiveNotifications } from '../PushNotifications/push-notifications';

const withAuthorization = condition => Component => {
    
    class WithAuthorization extends React.Component {

        componentDidMount() {
            var URL = window.location.pathname;
            this.listener = this.props.firebase.auth.onAuthStateChanged(
                authUser => {
                    if (condition(authUser)) {
                        if (!localStorage.getItem('ani-check-in-notification-token') && localStorage.getItem('ani-push-notifications') !== 'none') {
                            askForPermissionsToReceiveNotifications();
                        }
                    }
                    if (!condition(authUser)) {
                        if (
                            URL.includes('/guest-register') || 
                            URL.includes('/session-guest') || 
                            URL.includes('/talent-signup') ||
                            URL.includes('/appt-invite') ||
                            URL.includes('/verification/') ||
                            URL.includes('/join/presentation/') ||
                            URL.includes('/session-overview/')
                        ) {

                        } else {
                            this.props.history.push(ROUTES.LANDING);
                        }
                   }
                },
            );
        }
        render() {
            return (
                <AuthUserContext.Consumer>
                    {authUser => 
                        condition(authUser) ? <Component {...this.props} /> : null
                    }
                </AuthUserContext.Consumer>
                );
            }
        }

    return compose(
        withRouter,
        withFirebase,
    )(WithAuthorization);
};

export default withAuthorization;