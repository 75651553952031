import React, { useEffect, useState } from 'react';

import TagGroupsList from './TagGroupsList';
import TagsList from './TagsList';

export default function SlyTagAdd(props) {

    const [selectedIds, setSelectedIds] = useState('');
    const [tagGroups, setTagGroups] = useState('');
    const [tagGroup, setTagGroup] = useState('All');
    const [groupTagIds, setGroupTagIds] = useState('');


    const _addTalentTag = (item) => {
        const { uid, talentId, talentName, projectId, roleId, sessionId } = props;
        props.firebase.addTalentTag(uid, talentId, item.name, talentName, item.id, projectId, roleId, sessionId, true );
        props.firebase.addUserTalentTag(uid, talentId, item.id, item.name, projectId, roleId);
    };

    const _removeTalentTag = (item) => {
        const { uid, talentId, sessionId } = props;
        props.firebase.removeTalentTag(uid, talentId, item.id, sessionId, true);
        props.firebase.removeUserTalentTag(uid, talentId, item.id);
    };

    
    useEffect(() => {
        if (props.talentTags) {
            var selected = Object.keys(props.talentTags).map(key => ({
                ...props.talentTags[key],
                id: key,
            }))
            if (selected) {
                setSelectedIds(selected.map(tag => tag.id));
            } else {
                setSelectedIds('');
            } 
        } else if (props.talentTags.length === 0) {
            if (selectedIds) {
                setSelectedIds('');
            }
        } else {
            if (selectedIds) {
                setSelectedIds('');
            }
        }
    }, [props.talentTags])

    useEffect(() => {
        if (props.tagGroups) {
            setTagGroups(tagGroups);
        };
    })

    const _selectTagGroup = (item) => {
        setTagGroup(item);
        var tags = item.tags ? Object.keys(item.tags).map(key => ({...item.tags[key], id: key})) : '';
        setGroupTagIds(tags ? tags.map(i => i.id) : '');
    }



    return (
        <div>

        <div className="sly-box-header">Internal Tags</div>
        <div className="sly-tag-columns">
            <TagGroupsList 
                setTagGroup={setTagGroup}
                tagGroup={tagGroup}
                tagGroups={props.tagGroups}
                _selectTagGroup={_selectTagGroup}
            />
            <div className="column-divider" />
            <TagsList
                userTags={props.userTags}
                tagGroup={tagGroup}
                groupTagIds={groupTagIds}
                selectedIds={selectedIds}
                _removeTalentTag={_removeTalentTag}
                _addTalentTag={_addTalentTag}
            />
        </div>
        </div>
    )
}
