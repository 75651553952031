import React, { useEffect, useState } from 'react';
import { ObjectList } from '../../Functions/Common';
import moment from 'moment';

export default function SessionList(props) {

    const [sessions, setSessions] = useState('');

    useEffect(() => {
        if (props.filtered) {
            setSessions(props.filtered.sort((a, b) => (a.date > b.date) ? -1 : 1))
        }
    }, [props.filtered]);
    return (
        sessions ? 
        sessions
        .map((item, index) => (
            <SessionItem 
                key={item.id} item={item} 
                openSession={props.openSession} 
                setSessSum={props.setSessSum} 
                arrivals={props.arrivals}
            />
        ))
        :
        null
    )
}

function SessionItem(props) {

    var i = props.item;
    const [subjects, setSubjects] = useState('');
    const [subjectCount, setSubjectCount] = useState(0);
    const [noteCount, setNoteCount] = useState(0);
    const [tagCount, setTagCount] = useState(0);
    const [focus, setFocus] = useState('');
    const [positions, setPositions] = useState('');
    const [onDeck, setOnDeck] = useState('');


    useEffect(() => {
        if (props.item) {
            var subs = props.item.subjects ? ObjectList(props.item.subjects) : 0;
            var pos = props.item.positions ? ObjectList(props.item.positions) : '';
            setSubjects(subs);
            setPositions(pos);
            setSubjectCount(subs ? subs.length : 0);
            if (subs) {
                subs.map((item, index) => {
                    var notes = ObjectList(item.notes).length || 0;
                    var tags = ObjectList(item.tags).length || 0;
                    setNoteCount(noteCount + notes);
                    setTagCount(tagCount + tags); 
                })
            }
        }
    }, [props.item])
    
    useEffect(() => {
        if (props.item.focus && subjects) {
            var current = subjects.filter(fil => {
                return fil.id === props.item.focus
            })[0];
            setFocus(current);
        } else {
            setFocus('');
        }
    }, [props.item.focus, subjects]);

    useEffect(() => {
        if (props.arrivals && positions) {
            var positionIds = positions.map(i => i.id); 
            var potential = props.arrivals.filter(fil => {
                return positionIds.includes(fil.interview.id) && !fil.track.call;
            }); 
            potential.sort((a, b) => 
                (moment(a.track.in, 'MM/DD/YYYY hh:mm a'))
                .isBefore
                (moment(b.track.in, 'MM/DD/YYYY hh:mm a'))
            );
            setOnDeck(potential[0]);
        }
    }, [props.arrivals, positions])

    return (
        <div className="session-item relative">
            <h2>{i.title ? i.title : 'Untitled Session'}</h2>
            <h4>{i.date}</h4>
            { 
                i.times.start ?
                <h3>{i.times.start} to {i.times.end}</h3> :
                <h3>No time frame set</h3>
            }
            <div className="height-20" />
            <div className="session-item-label">
                Active:
            </div>
            {
                focus ?
                <div className="session-item-focus">
                    {focus.name}
                </div>
                :
                <div className="session-item-focus">
                    Empty 
                </div>
            }
             <div className="session-item-label">
                On Deck:
            </div>
            {
                onDeck ?
                <div className="session-item-focus">
                    {onDeck.name}
                </div>
                :
                <div className="session-item-focus">
                    None available 
                </div>
            }
            <div className="session-item-buttons">
                <button id="open-session" onClick={() => props.openSession(i.id)}>Open Session</button>
                <button id="open-report" onClick={() => props.setSessSum(i.id)}>Summary</button>
            </div>
        </div>
    )
}