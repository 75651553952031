import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import axios from 'axios';

import moment from 'moment';

import { withAuthorization } from '../Session';
import { PasswordForgetLink } from '../PasswordForget';

import arrowdown from '../../assets/arrowdown.png';

import ProjectView from './ProjectPanel/ProjectView';
import ActiveCount from './ProjectPanel/ActiveCount';
import RoomActiveCount from './RoomPanel/RoomActiveCount';
import ImageUpload from './ImageUpload';
import ConnectOptions from './ConnectOptions';
import EmployeeItem from './Employees/EmployeeItem';

import { Switch } from '../Common/CustomSwitch';

import './account.css';

import gearhover from '../../assets/gearhover.png';
import trashcan from '../../assets/trashcan.png';
import pencil from '../../assets/pencil.png';

var timer, timer2;

class AccountPage extends React.Component {

  timestamp = moment().format('MM/DD/YY hh:mm a');

  constructor(props) {
    super(props);

    this.state = {
        authUser: null,
        authUserEmail: '',
        loading: true,

        company: '', companyEdit: '',
        email: '', emailEdit: '',
        phone: '', phoneEdit: '',
        extension: '', extensionEdit: '',
        address: '', addressEdit: '',
        address2: '', address2Edit: '',
        city: '', cityEdit: '',
        state: '', stateEdit: '',
        zip: '', zipEdit: '',

        token: '',

        showSaveChanges: false,

        employeeName: '',
        employeeEmail: '',
        employeePassword: '',
        employeePassword2: '',

        newEmployeeModal: false,

        createdBy: '',
        roomName: '',
        projectName: '',
        userEmail: '',
        employees: [],
        rooms: [],
        projects: [],
        myId: '',
        projectModal: false,
        modalProjectName: '',
        modalProjectId: '',
        modalProjectRoles: [],
        roleName: '',
        modal: false,
        modalType: '',
        projectView: false,
        editMode: false,

        leftSidebar: false,
        leftSideBarNone: true,

        showSettings: false,

        receptionEmail: '',
        receptionEmailEdit: '',
    };

    this.projectToggle = this.projectToggle.bind(this);
    this.projectToggleOff = this.projectToggleOff.bind(this);
  }


  projectToggle(project) {

    var projectName = project.projectName;
    var projectId = project.id;

    this.setState({
      projectModal: true,
      modalProjectName: projectName,
      modalProjectId: projectId,
    });

    this.props.firebase.projectRoles()
    .orderByChild('projectId')
    .equalTo((projectId))
    .on('value', snapshot => {
      if (snapshot.exists()) {
      const projectRolesObject = snapshot.val();
      const projectRolesList = Object.keys(projectRolesObject).map(key => ({
        ...projectRolesObject[key],
        id: key,  
      }));
      this.setState({
        modalProjectRoles: projectRolesList
      });
    } else {
      this.setState({
        modalProjectRoles: ''
      })
    };
    })
  }

  projectToggleOff() {
    this.setState({
      projectModal: false,
      modalProjectName: '',
      modalProjectId: ''
    })
  };

  componentDidMount() {

  
    this.listener = this.props.firebase.auth.onAuthStateChanged(
      authUser => {
        authUser
        ? this.setState({ createdBy: authUser.uid, authUserEmail: authUser.email, uid: authUser.uid})
        : this.setState({ authUser: null });

        var token = localStorage.getItem('ani-check-in-notification-token');
        this.setState({
          token: token ? token : ''
        });

        this.props.firebase.user(this.state.uid)
        .on('value', snapshot => {
          var u = snapshot.val();

          if (u.files) {
            this.setState({
              avatar: u.files.avatar,
            })
          }
          
          this.setState({
            receptionEmail: u.receptionEmail,
            receptionEmailEdit: u.receptionEmail,
            company: u.username,
            companyEdit: u.username,
            email: u.email,
            emailEdit: u.email,
            phone: u.phone,
            phoneEdit: u.phone,
            extension: u.extension,
            extensionEdit: u.extension,
            address: u.address,
            addressEdit: u.address,
            address2: u.address2,
            address2Edit: u.address2,
            city: u.city,
            cityEdit: u.city,
            state: u.state,
            stateEdit: u.state,
            zip: u.zip,
            zipEdit: u.zip,
          })
        })
        
    
        this.props.firebase.userRooms(this.state.createdBy)
        .on('value', snapshot => {
          if (snapshot.exists()) {
            var roomsObject = snapshot.val();
    
            var roomsList = Object.keys(roomsObject).map(key => ({
              ...roomsObject[key],
              id: key,
            }));
            this.setState({
              rooms: roomsList
            });
            } else {
              this.setState({
                rooms: ''
              })
            };
          });

        this.props.firebase.userProjects(this.state.createdBy)
        .on('value', snapshot => {
          if (snapshot.exists()) {
            const projectsObject = snapshot.val();

            const projectsList = Object.keys(projectsObject).map(key => ({
              ...projectsObject[key],
              id: key,
            }));
            this.setState({
              projects: projectsList
            });
          } else {
            this.setState({
              projects: ''
            })
          };
        });

        this.props.firebase.userEmployees(this.state.createdBy)
        .on('value', snapshot => {
          if (snapshot.exists()) {
            const employeesObject = snapshot.val();

            const employeesList = Object.keys(employeesObject).map(key => ({
              ...employeesObject[key],
              id: key,
            }));
            this.setState({
              employees: employeesList
            });
          } else {
            this.setState({
              employees: '',
            })
          };
        })
      })
      this.setState({
        loading: false,
      });

      var timer5 = setTimeout(() => {
        this.setState({
          leftSidebar: true,
        })
      }, 50);
      

      var timer6 = setTimeout(() => {
        this.setState({
          showSettings: true,
          leftSideBarNone: false,
          
        })
      }, 250);

      var timer7 = setTimeout(() => {
        this.setState({
          showAlert: true,
        })
      }, 1000);
      
      return (() => clearTimeout(timer5, timer6, timer7))
  };

  componentWillUnmount() {
    this.props.firebase.user(this.state.uid).off();
    this.props.firebase.userRooms(this.state.createdBy).off();
    this.props.firebase.userEmployees(this.state.createdBy).off();
    this.props.firebase.userProjects(this.state.createdBy).off();
    this.props.firebase.projectRoles().off();


  }
      
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  _onEnterEmployee = (event) => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      event.preventDefault();
      this.addEmployee(event);
    }
  }

  _onEnterRoom = (event) => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      event.preventDefault();
      this.addRoom(event);
    }
  }

  _onEnterProject = (event) => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      event.preventDefault();
      this.addProject(event);
    }
  }

  addEmployee = event => {
    const createdOn = moment().format('MM/DD/YY hh:mm a');
    const { createdBy, employeeName } = this.state;
    if (employeeName !== '') {
    this.props.firebase.addEmployee(employeeName, createdBy, createdOn)
    .then(() => {
      this.setState({
        employeeName: '',
      })
    })
    } else {
      alert('Name can not be blank');
    }
  };

  addRoom = event => {
    const { createdBy, roomName } = this.state;
    if (roomName !== '') {
    this.props.firebase.addRoom(roomName, createdBy)
    .then(() => {
      this.setState({
        roomName: '',
      })
    })
    } else {
      alert('Room name can not be blank');
    }
  };

  addProject = event => {
    const createdOn = moment().format('MM/DD/YY hh:mm a');
    const { createdBy, projectName } = this.state;
    if (projectName !== '') {
    this.props.firebase.addProject(projectName, createdBy, createdOn)
    .then(() => {
      this.setState({
        projectName: '',
      })
    });
    } else {
      alert('Project name can not be blank');
    }
  };

  onAddRole = event => {
    const createdOn = moment().format('MM/DD/YY hh:mm a');
    const { roleName, createdBy, modalProjectName, modalProjectId } = this.state;
    if (roleName !== '') {
      this.props.firebase.addRole(roleName, createdBy, modalProjectName, modalProjectId, createdOn)
      .then(() => {
        this.setState({
          roleName: '',
        })
      })
    } else {
      alert('Name can not be blank')
    }
  };

  activateRole = (role, event) => {
    var roleId = role.id;
    var projectId = role.projectId;
    var userId = this.state.createdBy;
    
    this.props.firebase.projectAttributes(projectId)
    .once('value', snapshot => {
      var object = snapshot.val();
      if (object.isActive === false) {
        this.props.firebase.projectAttributes(projectId)
        .update({
          isActive: true,
        })
        this.props.firebase.userProjectAttributes(userId, projectId)
        .update({
          isActive: true,
        })
      }
    })
    this.props.firebase.roleAttributes(roleId)
    .update({
      isActive: true
    })
  
    this.props.firebase.projectRoleAttributes(projectId, roleId)
    .update({
      isActive: true
    })
  };

  deactivateRole = (role, event) => {
    var roleId = role.id;
    var projectId = role.projectId;
    this.props.firebase.roleAttributes(roleId)
    .update({
      isActive: false
    })

    this.props.firebase.projectRoleAttributes(projectId, roleId)
    .update({
      isActive: false
    })
  };

  activateProject = (project, event) => {
    var projectId = project.id;
    var userId = this.state.createdBy;

    this.props.firebase.projectAttributes(projectId)
    .update({
      isActive: true
    })

    this.props.firebase.userProjectAttributes(userId, projectId)
    .update({
      isActive: true
    })
  };

  deactivateProject = (project, event) => {
    var projectId = project.id;
    var userId = this.state.createdBy;

    this.props.firebase.projectAttributes(projectId)
    .update({
      isActive: false
    })

    this.props.firebase.projectAttributes(projectId)
    .once('value', snapshot => {
      var object = snapshot.val();
      var roles = object.roles;
      if (roles) {
        var roleList = Object.keys(roles).map(key => ({
          ...roles[key],
          id: key,
        }))
        if (roleList)
          roleList.map((r, index) => {
            var role = r.id;
            this.props.firebase.roleUpdate(role)
            .update({
              isActive: false,
            })
            this.props.firebase.deactivateProjectRoles(projectId, role)
            .update({
              isActive: false,
            })
          })}
        }
    )

    this.props.firebase.userProjectAttributes(userId, projectId)
    .update({
      isActive: false
    })
  };

  activateRoom = (room, event) => {
    var createdBy = this.state.createdBy;
    var roomId = room.id;

    this.props.firebase.roomAttributes(roomId)
    .update({
      isActive: true
    })

    this.props.firebase.userRoomAttributes(createdBy, roomId)
    .update({
      isActive: true
    })
  };


  deactivateRoom = (room, event) => {
    clearTimeout(timer);
    var createdBy = this.state.createdBy;
    var roomId = room.id;

    this.props.firebase.listCheckInQ()
    .orderByChild('dibs')
    .equalTo((room.id))
    .once('value', snapshot => {
      if (snapshot.exists()) {
        var o = snapshot.val();
        var l = Object.keys(o).map(key => ({
          ...o[key],
          id: key,
        }))
        if (l) {
          l.map(c => {
            return this.props.firebase.checkInDibs(c.id).update({ dibs: '' });
          })
        }
      }
    })
    timer = setTimeout(() => {
      this.props.firebase.roomProjects()
      .orderByChild('roomId')
      .equalTo((roomId))
      .once('value', snapshot => {
        if (snapshot.exists()) {
          var o = snapshot.val();
          var l = Object.keys(o).map(key => ({
            ...o[key],
            id: key
          }));
          l.map((i, index) => {
            return this.props.firebase.roomProject(i.id)
            .remove();
          });
        }
      });
  
      this.props.firebase.roomRoles()
      .orderByChild('roomId')
      .equalTo((roomId))
      .once('value', snapshot => {
        if (snapshot.exists()) {
          var o = snapshot.val();
          var l = Object.keys(o).map(key => ({
            ...o[key],
            id: key
          }));
          l.map((i, index) => {
            return this.props.firebase.roomRole(i.id)
            .remove();
          });
        }
      });
  
      this.props.firebase.roomReaders()
      .orderByChild('roomId')
      .equalTo((roomId))
      .once('value', snapshot => {
        if (snapshot.exists()) {
          var o = snapshot.val();
          var l = Object.keys(o).map(key => ({
            ...o[key],
            id: key
          }));
          l.map((i, index) => {
            return this.props.firebase.roomReader(i.id)
            .remove();
          })
        }
      })
  
      this.props.firebase.roomAttributes(roomId)
      .update({
        activeProjects: '',
        activeRoles: '',
        roomReaders: '',
      });
  
      
      this.props.firebase.roomAttributes(roomId)
      .update({
        isActive: false
      })
  
      this.props.firebase.userRoomAttributes(createdBy, roomId)
      .update({
        isActive: false
      })
    }, 300);
    return (() => clearTimeout(timer));
  };

  deleteProjectRoleClick = (role, event) => {

    var roleId = role.id;
    var projectId = role.projectId;

    this.props.firebase.removeProjectRole(projectId, roleId)
    .child(roleId)
    .remove()

    this.props.firebase.removeRole(roleId)
    .child(roleId)
    .remove()
  };

  deleteRoomClick = (room, event) => {

    
    clearTimeout(timer);

    var roomId = room.id;
    var createdBy = this.state.createdBy;
    this.props.firebase.listCheckInQ()
    .orderByChild('dibs')
    .equalTo((room.id))
    .once('value', snapshot => {
      if (snapshot.exists()) {
        var o = snapshot.val();
        var l = Object.keys(o).map(key => ({
          ...o[key],
          id: key,
        }))
        if (l) {
          l.map(c => {
            return this.props.firebase.checkInDibs(c.id).update({ dibs: '' });
          })
        }
      }
    })
    timer = setTimeout(() => {
      this.props.firebase.removeRoom(roomId)
      .child(roomId)
      .remove()
  
      this.props.firebase.removeUserRoom(createdBy, roomId)
      .child(roomId)
      .remove()
  
      this.props.firebase.deleteRoomReadersFromDeleteRoom(roomId);
      this.props.firebase.deleteRoomProjectsFromDeleteRoom(roomId);
      this.props.firebase.deleteRoomRolesFromDeleteRoom(roomId);
    }, 300);
    clearTimeout(timer);
  };

  deleteEmployeeClick = (employee, event) => {

    var createdBy = this.state.createdBy;
    var employeeId = employee.id;

    this.props.firebase.removeEmployee(employeeId)
    .child(employeeId)
    .remove();

    this.props.firebase.removeUserEmployee(createdBy, employeeId)
    .child(employeeId)
    .remove();

    this.props.firebase.deleteRoomReader(employeeId);
  };


  deleteProjectClick = (project, event) => {

    var createdBy = this.state.createdBy;
    var projectId = project.id;

    this.props.firebase.removeProject(projectId)
    .child(projectId)
    .remove()

    this.props.firebase.removeUserProject(createdBy, projectId)
    .child(projectId)
    .remove()
  };

  showSave = () => {
    const { 
      company, companyEdit, email, emailEdit, phone, phoneEdit, extension, extensionEdit, address, addressEdit, 
      address2, address2Edit, city, cityEdit, state, stateEdit, zip, zipEdit, receptionEmail, receptionEmailEdit,
    } = this.state;

    if (
      company === companyEdit &&
      email === emailEdit &&
      phone === phoneEdit &&
      extension === extensionEdit &&
      address === addressEdit &&
      address2 === address2Edit &&
      city === cityEdit &&
      state === stateEdit &&
      receptionEmail === receptionEmailEdit &&
      zip === zipEdit) {
        return (
          <div></div>
        )
      } else {
        return (
          <button className="save-changes-btn" onClick={this.updateSettings}>Save Changes</button>
        )
      }
  }

  updateSettings = () => {
   const { createdBy, emailEdit, companyEdit, phoneEdit, extensionEdit, addressEdit, address2Edit, cityEdit, zipEdit, stateEdit } = this.state;

   this.props.firebase.user(createdBy)
   .update({
     email: emailEdit,
     username: companyEdit,
     phone: phoneEdit,
     extension: extensionEdit,
     address: addressEdit,
     address2: address2Edit,
     city: cityEdit,
     zip: zipEdit,
     state: stateEdit, 
     receptionEmail: this.state.receptionEmailEdit,

   })
   this.setState({
    email: emailEdit,
    company: companyEdit,
    phone: phoneEdit,
    extension: extensionEdit,
    address: addressEdit,
    address2: address2Edit,
    city: cityEdit,
    zip: zipEdit,
    state: stateEdit, 
    receptionEmail: this.state.receptionEmailEdit,
    editMode: false,
   })
  }

  _passProjectProps = (item) => {
    this.setState({
      projectItem: item,
    })
    this._setProjectView();
  };

  _setProjectView = () => {
    this.setState({
      projectView: true,
    })
  }

  _setProjectListView = () => {
    this.setState({
      projectView: false,
      projectItem: ''
    })
  }

  _handleImageUpload = (e) => {
    if (e.target.files[0]) {
        var _handleRefreshPic = this._handleRefreshPic;
        const image = e.target.files[0];
        this.setState({
            image,
        })
        const { uid } = this.state;
        this.props.firebase.uploadCompanyAvatar(uid, image, _handleRefreshPic);
    }
  }

  _enableEdit = () => {
    this.setState({
      editMode: !this.state.editMode,
    })
  }

  _toggleLeftSidebar = () => {
    this.setState({
      leftSidebar: !this.state.leftSidebar
    })
  }

  _backToDashboard = () => {
    this.setState({
      leftSideBarNone: true,
      leftSidebar: false,
    })
    var timer4;
    timer4 = setTimeout(() => {
      this.props.history.push(ROUTES.HOME);
    }, 500)
    return (() => clearTimeout(timer4));
  };

  _handleRefreshPic = (downloadURL) => {
  }

  _handleProjectPanel = (item) => {
    if (this.state.projectView === false) {
      return (
        <div>
        <div className="account-header">
        <div className="account-list-label">Projects</div> 
          <input 
              className="account-input"
              name="projectName"
              value={this.state.projectName}
              onChange={this.onChange.bind(this)}
              onKeyDown={this._onEnterProject}
              type="text"
              placeholder="Enter title to add..."
            />  
          </div>  
          <div className="projects-list-container">
            <ProjectsList 
              _findRoleLength={this._findRoleLength}
              projects={this.state.projects}
              deleteProjectClick={this.deleteProjectClick}
              roleName={this.state.roleName}
              onChange={this.onChange}
              onAddRole={this.onAddRole}
              deleteProjectRoleClick={this.deleteProjectRoleClick}
              activateRole={this.activateRole}
              deactivateRole={this.deactivateRole}
              activateProject={this.activateProject}
              deactivateProject={this.deactivateProject}
              _passProjectProps={this._passProjectProps}
              firebase={this.props.firebase}
              _onEnterProject={this._onEnterProject}
              projectName={this.state.projectName}
              uid={this.state.uid}
              company={this.state.company}
            />
          </div>
        </div>
      )
    } else {
      return (
        <ProjectView 
          company={this.state.company} 
          item={this.state.projectItem} 
          firebase={this.props.firebase} 
          _setProjectListView={this._setProjectListView} 
          uid={this.state.createdBy} 
        />
      )
    }
  };

  


  _sendMeNotification = () => {
    console.log('o');
    var Authorization = 'key=AAAAld7riic:APA91bFWJKPBZqwLF80HTBWWcmT5iarAdnEK6Z58tuzww-31Wm3lz1W8zPDbmPmttCHnjOKbCBu6jYkwlpHUO4i3kexor_J-JbdgfLN9luslRMzeLK6PPpENkE1UHiENDpKzqCpsudeC';
    var name = 'Dilan';
    var notification = JSON.stringify({
      notification: {
        title: "Firebase",
        body: "Dilan has just checked in!",
        click_action: "http://localhost:3000/",
        icon: "https://firebasestorage.googleapis.com/v0/b/ani-check-in.appspot.com/o/static%2Faninotificationicon.png?alt=media&token=5901c52f-dc21-4dfd-957a-261e5d63dd34"
      },
      to:"drO8iXL7haQ:APA91bGc2ra-y3zqWeDCuD8Rhj89gCboEm8nmglmrKdpXu5iczdH5Z9W3rQx6lIlP5ENNNaZjX2gZwaTu5FXSDg-UdUgYFs1MABirhfN9jTdtVlqXBmAN1PjkcXJK-ANPUjQB6iaWR8V"
    }) 
    console.log(notification);
    axios.post(`https://fcm.googleapis.com/fcm/send`, notification, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': Authorization
      }
    })
    
  }

  /*
  {
    this.state.token === ''?
    <button className="enable-notifications" onClick={askForPermissionsToReceiveNotifications}>Enable notifications</button>
    :
    <div />
  }
  */


  render() {

    const { 
      employeeName, roomName, rooms, employees, 
      companyEdit, emailEdit, phoneEdit, extensionEdit, addressEdit, address2Edit, cityEdit, stateEdit, zipEdit,
    } = this.state;
    return (

      <div className="background">
        {
          this.state.loading ? 
          <div>Loading</div>
        :
          
          <div className="account-page-layout">
            <div className="account-page-left-side">
              
              <div className={
                this.state.leftSidebar ? 
                "user-settings-container" : 
                this.state.leftSideBarNone && !this.state.leftSideBar ?
                "user-settings-container no-sidebar" : 
                "user-settings-container slide-out"}>
              <img className={
                  !this.state.leftSidebar ? 
                    "account-minimize-arrow acct-right-arrow" : 
                    "account-minimize-arrow acct-left-arrow"
                  } 
                  src={arrowdown} 
                  alt="Toggle Left Sidebar" 
                  onClick={this._toggleLeftSidebar.bind(this)}
                />
                <div className={this.state.showSettings ? "fadeIn" : "fadeOut"}>
                {
                this.state.leftSidebar && !this.state.leftSideBarNone ?
                <div>
                  <div className="acct-settings-back-btn" onClick={this._backToDashboard}>Back to Dashboard</div>
                  <div className="acct-settings-label">My Account</div>
            
                <ImageUpload firebase={this.props.firebase} uid={this.state.uid} avatar={this.state.avatar} _handleImageUpload={this._handleImageUpload}/>
                <div className="edit-btn" onClick={this._enableEdit.bind(this)}><img src={pencil} className={this.state.editMode ? "edit-btn-icon-edit-mode":"edit-btn-icon"} alt="Edit" /></div>
                <div className="acct-settings-input-container">
                  <div className="acct-settings-input-label">Company Name</div>
                  <input 
                    className={this.state.editMode ? "account-input-settings-edit" : "account-input-settings"} 
                    placeholder="ex. Discover ANi" 
                    name="companyEdit" 
                    value={companyEdit} 
                    onChange={this.onChange.bind(this)} 
                    disabled={this.state.editMode ? false : true}
                    />
                </div>
                <div className="acct-settings-input-container">
                  <div className="acct-settings-input-label">Email Address</div>
                  <input 
                    disabled={this.state.editMode ? false : true} 
                    className={this.state.editMode ? "account-input-settings-edit" : "account-input-settings"} 
                    placeholder="ex. john@discoverani.com" 
                    name="emailEdit" value={emailEdit} 
                    onChange={this.onChange.bind(this)} />
                </div>
                <div className="acct-settings-input-container">
                  <div className="acct-settings-input-label">Reception Email Address</div>
                  <input 
                    disabled={this.state.editMode ? false : true} 
                    className={this.state.editMode ? "account-input-settings-edit" : "account-input-settings"} 
                    placeholder="ex. reception@discoverani.com" 
                    name="receptionEmailEdit" value={this.state.receptionEmailEdit} 
                    onChange={this.onChange.bind(this)} />
                </div>
                <div className="acct-settings-input-container">
                  <div className="acct-settings-input-label">Office Number</div>
                  <div className="acct-settings-number-inputs">
                    <input 
                      disabled={this.state.editMode ? false : true} 
                      className={this.state.editMode ? "account-input-settings-edit" : "account-input-settings"} 
                      placeholder="555-555-5555" 
                      name="phoneEdit" value={phoneEdit} 
                      onChange={this.onChange.bind(this)} 
                    />
                  <div />
                  <input 
                    disabled={this.state.editMode ? false : true} 
                    className={this.state.editMode ? "account-input-settings-edit" : "account-input-settings"} 
                    placeholder="ex. 123" 
                    name="extensionEdit" 
                    value={extensionEdit} 
                    onChange={this.onChange.bind(this)} 
                  />
                  </div>
                </div>
                <div className="acct-settings-input-container">
                  <div className="acct-settings-input-label">Address</div>
                  <input 
                    disabled={this.state.editMode ? false : true} 
                    className={this.state.editMode ? "account-input-settings-edit" : "account-input-settings"} 
                    placeholder="Street Address" 
                    name="addressEdit" 
                    value={addressEdit} 
                    onChange={this.onChange.bind(this)} />
                  <input 
                    disabled={this.state.editMode ? false : true} 
                    className={this.state.editMode ? "account-input-settings-edit" : "account-input-settings"} 
                    placeholder="Apartment or Suite #" 
                    name="address2Edit" 
                    value={address2Edit} 
                    onChange={this.onChange.bind(this)} 
                  />
                  <div className="acct-settings-city-inputs">
                    <input disabled={this.state.editMode ? false : true} 
                      className={this.state.editMode ? "account-input-settings-edit" : "account-input-settings"} 
                      placeholder="City" 
                      name="cityEdit" 
                      value={cityEdit} 
                      onChange={this.onChange.bind(this)} 
                    />
                    <div />
                    <input 
                      disabled={this.state.editMode ? false : true} 
                      className={this.state.editMode ? "account-input-settings-edit-center" : "account-input-settings-center"} 
                      placeholder="CA" 
                      name="stateEdit" 
                      value={stateEdit} 
                      onChange={this.onChange.bind(this)} 
                      />
                    <div />
                    <input 
                      disabled={this.state.editMode ? false : true} 
                      className={this.state.editMode ? "account-input-settings-edit-center" : "account-input-settings-center"} 
                      placeholder="Zip" 
                      name="zipEdit" 
                      value={zipEdit} 
                      onChange={this.onChange.bind(this)} 
                   />
                  </div>
                  {this.showSave()}
                  
                  <div className="password-container">
                    <div className="forgot-password"><PasswordForgetLink /></div>
                  </div>
                  </div>
                  </div>
                  :
                  <div />
                }
                </div>
              </div>
            </div>
            <div className={this.state.leftSideBarNone ? "account-page-right-side fadeOutFast" : "account-page-right-side fadeIn"}>
            <div className="lists-container">
            <div className="list-scroller">
                <div className="account-list-label">Employees</div>        
                  <EmployeesList 
                    employees={employees}
                    deleteEmployeeClick={this.deleteEmployeeClick} 
                    employeeName={employeeName}
                    onChange={this.onChange}
                    _onEnterEmployee={this._onEnterEmployee}
                    firebase={this.props.firebase}
                    uid={this.state.uid}
                    />
              </div>
              <div className="list-scroller">
                {
                  this._handleProjectPanel()
                }
              </div>
              <div className="list-scroller">
                <div className="account-header">
                  <div className="account-list-label">Rooms</div>
                    <input 
                      className="account-input"
                      name="roomName"
                      value={roomName}
                      onChange={this.onChange.bind(this)}
                      onKeyDown={this._onEnterRoom}
                      type="text"
                      placeholder="Enter room name to add..."
                    />  
                    </div>     
                    <RoomsList 
                      rooms={rooms} 
                      deleteRoomClick={this.deleteRoomClick}
                      activateRoom={this.activateRoom}
                      deactivateRoom={this.deactivateRoom}
                      firebase={this.props.firebase}
                      roomName={roomName}
                    />
              </div>              
            </div>
          </div>
          <div className="account-right-nav">

          </div>
        </div>
      }
      {
        this.state.showAlert ?
        <div className="alert-settings-modal">
          <div className="alert-settings-text">
            With the new update, to activate Rooms, Projects and Roles, please click on Quick Session on the Dashboard
          </div>
          <button className="sounds-good" onClick={() => this.setState({ showAlert: false })}>Got it!</button>
        </div>
        :
        null
      }
      {
      this.state.modal ?
      <div className="modals-container">{this.currentModalState()}</div>
      :
      <div></div>
      }
    </div>
    )
  };
};



const RoomsList = ({ roomName, rooms, deleteRoomClick, activateRoom, deactivateRoom, firebase, _onEnterRoom, onChange }) => {

    return (
    <div className="list-background">
        { rooms ?
          <div className="employees-layout">
          {
          rooms.map(room => {
            return (
              <div key={room.id} className="room-cards">
                <div className="project-card-right">
                  <div className="card-name">{room.roomName}</div>
                  <RoomActiveCount room={room} firebase={firebase} />
                  <div className="account-switch-padding">
                    <Switch 
                      boolean={room.isActive} 
                      toggleFunction={room.isActive ? 
                        deactivateRoom.bind(this, room) : 
                        activateRoom.bind(this, room)
                      }
                      disabled={room.sessionMode ? true : false}
                      text={room.isActive ? room.sessionMode ? 'Room is in session' : 'Room is active' : 'Room is disabled'}
                    />
                  </div>
                </div>
                <div className="settings-btn">
                  <img className="trash-icon-list" src={trashcan} alt="Settings" onClick={deleteRoomClick.bind(this, room)} />
                </div>
              </div>
            )
          })
          }
          </div>
          :
          <div className="null-text">No Rooms</div>
        } 
    </div>
    )
};

const ProjectsList = ({ uid, projectName, onChange, _onEnterProject, myId, firebase, _getActiveCount, _passProjectProps, activateProject, deactivateProject, deactivateRole, activateRole, deleteProjectRoleClick, modalProjectRoles, onAddRole, roleName, projects, deleteProjectClick, projectModal, projectToggle, modalProjectName, modalProjectId, projectToggleOff }) => {

    return (
      <div className="list-background">
          
          { projects ?

            <div className="employees-layout">
            {
            projects.map(item => {
              return (
                <div key={item.id} className="room-cards">
                    <div className="project-card-right">
                      <div className="card-name">{item.projectName}</div>
                      <ActiveCount item={item} firebase={firebase} />
                      <div className="account-switch-padding">
                        <Switch 
                          boolean={item.isActive} 
                          toggleFunction={item.isActive ? 
                            deactivateProject.bind(this, item) : 
                            activateProject.bind(this, item)
                          }
                          text={item.isActive ? 'Project is active' : 'Project is disabled'}
                        />                      
                      </div>
                      {
                        item.temp === true ? 
                        <ConnectOptions item={item} projects={projects} firebase={firebase} uid={uid} />
                        :
                        <div />
                      }
                    </div>
                    <div className="settings-btn">
                      <img className="settings-icon" src={gearhover} onClick={_passProjectProps.bind(this, item)} alt="Settings" />
                    </div>
                </div>
              )
              })
            }
            </div>
            :
            <div className="null-text">No projects</div>
            }
        </div>
      )
    }


const EmployeesList = ({ employees, firebase, deleteEmployeeClick, employeeName, onChange, _onEnterEmployee, uid}) => {
  
  return (
    <div className="list-background">
      <input 
          className="account-input"
          name="employeeName"
          value={employeeName}
          onChange={onChange.bind(this)}
          onKeyDown={_onEnterEmployee}
          placeholder="Enter name to add..."
          autoComplete="off"
        />

        { employees ? 
          <div className="employees-layout">
            {
              employees.map(employee => (
                <EmployeeItem 
                  uid={uid} 
                  firebase={firebase} 
                  key={employee.id} 
                  employee={employee} 
                  trashcan={trashcan} 
                  deleteEmployeeClick={deleteEmployeeClick} 
                />
              ))
            }
          </div>
          :
          <div className="null-text">No employees</div>
          }
    </div>
  )
};



//<PasswordForgetForm />
//<PasswordChangeForm />


const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);