import React, { useState, useEffect } from 'react';

import {QuickMenu} from './QuickMenu';
import TalentGreeting from './TalentGreeting';
import Tags from './Tags/Tags';
import DashArrival from './Arrived/DashArrival';
import ManagePositions from './Positions/ManagePositions';
import Rooms from './Rooms/Rooms';

export default function QuickMenuComponent(props) {

    const [tab, setTab] = useState('');

    useEffect(() => {
        if (tab === '') {
            props.setPage('sessions');
        }
    }, [tab])

    function _signOut() {
        props.firebase.doSignOut();
    }
  
    return (
        tab === 'greeting' ?
            <TalentGreeting 
                newGreeting={props.state.newGreeting}
                greeting={props.state.greeting}
                _updateGreeting={props._updateGreeting}
                onInputChange={props.onInputChange}
                _turnOffTalentSignUp={props._turnOffTalentSignUp}
                _turnOnTalentSignUp={props._turnOnTalentSignUp}
                talentSignUp={props.talentSignUp}
                setTab={setTab}
                cid={props.userdata.cid}
            />
        :
        tab === 'tags' ? 
            <Tags 
                firebase={props.firebase}
                uid={props.userdata.uid}
                setTab={setTab}
                cid={props.userdata.cid}
            />
        :
        tab === 'projects' ?
            <ManagePositions 
                firebase={props.firebase}
                uid={props.userdata.uid}
                cid={props.userdata.cid}
                setTab={setTab}
                showCandidates={props.showCandidates}
            />
        :
        tab === 'arrived' ? 
            <DashArrival 
                firebase={props.firebase}
                uid={props.userdata.uid}
                cid={props.userdata.cid}
                setTab={setTab}
                arrivals={props.userdata.arrivals}
            />
        :
        tab === 'rooms' ?
            <Rooms 
                firebase={props.firebase}
                uid={props.userdata.uid}
                cid={props.userdata.cid}
                setTab={setTab}
            />
        :
        <QuickMenu 
            _openCheckInView={props._openCheckInView}
            dashboardBtn={props.dashboardBtn}
            _signOut={_signOut}
            _showDeviceSelect={props._showDeviceSelect}
            setTab={setTab}
            arrivals={props.userdata.arrivals}
            page={props.page}
            setPage={props.setPage}
            firebase={props.firebase}
            username={props.userdata.username}
            uid={props.userdata.uid}
            cid={props.userdata.cid}
            p={props.userdata.p}
            mobile={props.mobile}
            open={props.open}
        />
        
    )
}
