import React, { useState } from 'react';
import OnDeckItem from './OnDeckItem';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import { Switch } from '../../Common/CustomSwitch';

import './checkin.css';

export default function OnDeck(props) {

    const [view, setView] = useState(false);

    var p = props;
    var roles = p.activeRoomRolesList ? p.activeRoomRolesList : '';
    var onDeck = [];
    var onSecDeck = [];
    var active = [];

    if (p.rooms) {
        p.rooms.map(room => {
            var activeTalent = room.activeCheckIn ? 
            {
                name: room.checkInName, 
                talentId: room.checkInId, 
                project: room.checkInProject,
                role: room.checkInRole,
                roomId: room.id,
                roomName: room.roomName, 
            } : '';
            if (activeTalent) {
                active.push(activeTalent)
            };
        
            var roomRoles = roles ? roles.filter(fil => {
                return fil.roomId === room.id
            }) : '';
            var array = roomRoles ? roomRoles.map(rr => rr.roleId) : '';
            if (array) {
                var options = p.checkIns ? p.checkIns.filter(fil => {
                    return array.includes(fil.roleId)
                }) : '';
                var next = options ? options[0] : '';
                var secNext = options && options.length > 1 ? options[1] : '';
                if (next) {
                    next = {next: next, room: room.id, roomName: room.roomName}
                    onDeck.push(next);
                };
                if (secNext) {
                    secNext = {next: next, room: room.id, roomName: room.roomName}
                    onSecDeck.push(secNext);
                }
            }
            onDeck = onDeck ? Object.keys(onDeck).map(key => ({
                ...onDeck[key],
                id: key,
            })) : '';

            onSecDeck = onSecDeck ? Object.keys(onSecDeck).map(key => ({
                ...onSecDeck[key],
                id: key,
            })) : '';
    })
    
    return (
        <div>
        <div className="on-deck-container">
            {
                p.rooms ?
                p.rooms.map((room, index) => (
                    room.room2 ?
                        <div key={room.id} className="inline-block">
                            <Link key={room.id} style={style.link} to={ROUTES.ROOM + '/' + room.id}>
                                <OnDeckItem view={view} key={room.id} room={room} onDeck={onSecDeck} active={active} />
                            </Link>
                        </div>
                        :
                        <div key={room.id} className="inline-block">
                            <Link key={room.id} style={style.link} to={ROUTES.ROOM + '/' + room.id}>
                                <OnDeckItem view={view} key={room.id} room={room} onDeck={onDeck} active={active} />
                            </Link>
                        </div>
                    ))
                    : 
                    null
                }
        </div>
        </div>
    );
}
}

const style = {
    link: {
        textDecoration: 'none',
    }
}
