import React, { Component } from 'react';

import trashcan from '../../assets/trashcan.png';

export default class TalentNoteItem extends Component {

    render() {

        var props = this.props;

        return (
            <div className="note-container-room-view">   
                <div className="note-initials">{props.initials}</div>
                <div className="note-by">
                    <div className="note-text-room-view">{props.n.note}</div>
                    <div className="note-date-room-view">{props.n.daystamp}</div>
                </div>
                <div className="note-btns"><div />
                <img className="remove-note-room-view" onClick={props._removeNote.bind(this, props.n)} src={trashcan} alt="Delete note" />
            </div>
        </div>
        )
    }
}