import React, { Component } from 'react';

import remove from '../../../assets/remove.png';

export default class NoteTags extends Component {

    constructor(props) {
        super(props);

        this.state = {
            newTag: '',
            currentTags: '',
            tagType: 'Talent',
        }
    }

    componentDidMount() {

        this.props.firebase.userTags(this.props.uid)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }))
                this.setState({
                    currentTags: l,
                })
            } else {
                this.setState({
                    currentTags: ''
                })
            };
        })
    }

    componentWillUnmount() {

        this.props.firebase.userTags(this.props.uid).off();
    }

    _onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    _onEnterTag = (event) => {
        if (event.keyCode === 13 && event.shiftKey === false) {
          event.preventDefault();
          this._addTag(event);
        }
    };

    _addTag = () => {
        const uid = this.props.uid;
        const tagName = this.state.newTag;
        const tagType = this.state.tagType;
        this.props.firebase.addUserTag(uid, tagName, tagType);

        this.setState({
            newTag: '',
        })
    }

    _removeTag = (item) => {
        const uid = this.props.uid;
        this.props.firebase.removeUserTag(uid, item.id);
    }

    render() {

        return (
            <div className="modal-body">
                <div className="modal-context">Tags are utilized in the Room View when an actor is in session. Rather than having to type, just create some note-presets to quick reference.</div>
                <div className="new-note-input-container">
                    <input className="new-note-textarea" onKeyDown={this._onEnterTag} name="newTag" value={this.state.newTag} placeholder="Add new tag here..." onChange={this._onChange.bind(this)} />
                    <button className="new-note-submit-btn" onClick={this._addTag}>+</button>
                </div>
                <div className="tags-container">
                    <div className="note-label">Current Tags</div>
                    {
                        this.state.currentTags ? 
                        this.state.currentTags.map((item, index) => (
                            <div key={index} className="tag">
                                <img className="tag-delete" src={remove} alt="Delete Tag" onClick={this._removeTag.bind(this, item)}/>
                                <div className="tag-elements">
                                    <div className="tag-count">{item.count}</div>
                                    <div className="tag-name">{item.name}</div>
                                </div>
                            </div>
                        ))
                        :
                        <div>No tags added yet!</div>
                    }
                </div>
            </div>
        )
    }
}