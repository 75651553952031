import React, { useState } from 'react';

import rightarrow from '../../../../assets/right-arrow.png';

import { TalentListItem } from './TalentListItem';

export default function TalentList(props) {

    const [sort, setSort] = useState('ABC');
    const list = props.talent;
    const [filter, setFilter] = useState('');

    return (

        <div className="s-notes-body">
            <div className="sess-dets-back-btn" onClick={props._clickOverview}>
                <img className="sb-talent-back-arrow" src={rightarrow} alt="Back" />
                <div className="sb-talent-header-text">Back to Overview</div>
            </div>
            <div className="sb-body-header-talent">
            <div className="view-switch-container" onClick={() => setSort(sort === 'ABC' ? '123' : 'ABC')} >
                <div className="sb-filter-label">Order talent:</div>
                    {
                        sort === '123' ? 
                        <button 
                            onClick={() => setSort('ABC')} 
                            className="sd-talent-sort-option sort-text-selected"
                        >
                        Audition Time
                    </button>
                    :
                    <button 
                        onClick={() => setSort('123')} 
                        className="sd-talent-sort-option sort-text-selected"
                    >
                        ABC
                    </button>
                    }
                </div>
                <input 
                    className="sb-talent-view-search" 
                    placeholder="Search talent..."     
                    value={filter}
                    onChange={e => setFilter(e.target.value)}
                />
            </div>
            <div className="talent-list-container-sb">
                {
                    sort === 'ABC' ? 
                    <div className="sb-talent-body-body">
                        {list ? list
                        .filter(fil => { return fil.name && fil.name.includes(filter ? filter : '')})
                        .sort((a, b) => (a.name > b.name) ? 1 : -1)
                        .map((item, index) => (
                            <TalentListItem index={index} key={item.id} item={item} _timeDifference={props._timeDifference} />
                        )) : <div />}
                    </div>
                    :
                    sort === '123' ? 
                    <div className="sb-talent-body-body">
                        {props.talent ? props.talent
                        .filter(fil => { return fil.name && fil.name.includes(filter)})
                        .map((item, index) => (
                            <TalentListItem index={index} key={item.id} item={item} _timeDifference={props._timeDifference} />
                        )) : <div />
                    }
                    </div>
                    :
                    <div className="sb-null-text">No talent recorded</div>
                }
            </div>
        </div>
    )
}

export { TalentList };