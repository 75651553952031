import React from 'react';

import rightarrow from '../../../../assets/right-arrow.png';

import TakesListItem from './TakesListItem';

export default function TakesList(props) {

    var talent = props.unsortedTalent;
    var talentTakes = props.talentForTakes;
    var talentTakesIds = talentTakes.map(i => i.id);

    var talentTakeCombine = [];
    var talentWithTakes = talent.filter(tal => {
        return talentTakesIds.includes(tal.id);
    });
    talentWithTakes.map(tal => {
        return talentTakes.filter(fil => {
            if (tal.id === fil.id) {
                return talentTakeCombine.push({
                    takes: fil.takes,
                    name: tal.name,
                    id: tal.id,
                });
            };
        })
    })
    console.log(talentTakeCombine);

    return (
        <div className="s-notes-body">
            <div className="sb-body-header-talent">
                <img className="sb-talent-back-arrow" src={rightarrow} alt="Back" onClick={props._clickOverview}/>
                <div className="sb-talent-header-text" onClick={props._clickOverview}>Back to Session Overview</div>
            </div>
            <div className="s-notes-body-body">
                {
                    talentTakeCombine ? 
                    talentTakeCombine.map((item, index) => (
                        <TakesListItem key={item.id} item={item} />
                    )) : <div />
                }
            </div>
        </div>
    )
}
