import React, { useState, useEffect } from 'react';

import TimeSlots from './TimeSlots';

export default function OnlineSettings(props) {

    return (
        
        <div className="dash-content">
            <div className="summary-container">
                <div className="dash-header">Online Settings</div>
                <div className="dash-sub-section">
                    <div className="dash-sub-label">Invite Links</div>
                    <div className="dash-invite-item">Talent Register</div>
                    <div />
                </div>
                <div className="divider-dark" />
                <div className="dash-sub-section">
                    <div className="dash-sub-label">Talent Schedule</div>
                        <TalentSchedule 
                            firebase={props.firebase} 
                            subjects={props.subjects}
                            id={props.id} 
                            cid={props.cid} 
                            uid={props.uid}
                            schedule={props.schedule}
                            positions={props.positions}
                            date={props.date}
                            cname={props.cname}
                            username={props.username}
                        />
                    <div />
                </div>
            </div>
        </div>
    )
};

function TalentSchedule(props) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [start, setStart] = useState('');
    const [wrap, setWrap] = useState('');

    return (
        <div className="dash-sub-content-container">
            <div className="dash-sub-section-header">Create your talent schedule</div>
            <div className="dash-sub-section-description">
                To set up a schedule, first confirm your time frame, then set an increment to generate time slots.
            </div>
            <Window 
                setWrap={setWrap}
                setStart={setStart}
            />
            <TimeSlots 
                firebase={props.firebase} 
                subjects={props.subjects}
                id={props.id} 
                cid={props.cid} 
                uid={props.uid}
                positions={props.positions}
                schedule={props.schedule}
                date={props.date}
                cname={props.cname}
                username={props.username}
            />
            
        </div>
    )
}

function Window(props) {
    const [starth, setStartH] = useState('');
    const [startm, setStartM] = useState('');
    const [startap, setStartAP] = useState('');
    const [wrapm, setWrapH] = useState('');
    const [wraph, setWrapM] = useState('');
    const [wrapap, setWrapAP] = useState('');
    const [inc, setInc] = useState('');
    
    const pass = {
        s: {
            h: starth,
            hf: setStartH,
            m: startm,
            mf: setStartM,
            ap: startap,
            apf: setStartAP,
        },
        w: {
            h: wraph,
            hf: setWrapH,
            m: wrapm,
            mf: setWrapM,
            ap: wrapap,
            apf: setWrapAP,
        }
    }

    useEffect(() => {
        props.setWrap(wraph + ':' + wrapm + ' ' + wrapap);
        props.setStart(starth + ':' + startm + ' ' + startap);
    }, [pass]);


    return (
        <div className="dash-talent-schedule-window">
            <div className="height-10" />
            <div className="dash-time-inputs inline">
                <div className="inline">
                    <p className="dash-input-label">Start Time </p>
                    <input type="number" min="1" max="12" placeholder="12" className="dash-input time" value={starth} onChange={(e) => setStartH(e.target.value)} />
                    <input type="number" min="00" max="59" placeholder="00" className="dash-input time" value={startm} onChange={(e) => setStartM(e.target.value)} />
                    <div className="inline"><AMPM pass={pass} i={'start'}/></div>
                </div>
                <div className="inline">
                    <p className="dash-input-label">Wrap Time </p>
                    <input type="number" min="1" max="12" placeholder="12" className="dash-input time" value={wraph} onChange={(e) => setWrapH(e.target.value)} />
                    <input type="number" min="00" max="59" placeholder="00" className="dash-input time" value={wrapm} onChange={(e) => setWrapM(e.target.value)} />
                    <div className="inline"><AMPM pass={pass} i={'wrap'} /></div>
                </div>
                <div className="inline">
                    <p className="dash-input-label">Increment </p>
                    <input type="number" min="00" max="59" placeholder="15" className="dash-input time" value={inc} onChange={(e) => setInc(e.target.value)} />
                </div>
            </div>
        </div>
    )
}

function AMPM(props) {

    function click(i, v) {
        if (i === 'start') {
            props.pass.s.apf(v);
        } else {
            props.pass.w.apf(v);
        }
    };

    return (
        <div className="ampm-widget">
            <button className="ampm-button" onClick={() => click(props.i, 'AM')}>AM</button>
            <button className="ampm-button" onClick={() => click(props.i, 'PM')}>PM</button>
        </div>
    );
}
