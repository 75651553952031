import React, { Component } from 'react'

export default class SessionModeOverview extends Component {

    render() {
        return (
            <div className="session-mode-overview-container">
                
            </div>
        )
    }
}
