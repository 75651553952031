import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import moment from 'moment';

import SessionUserSelect from './SessionUserSelect';
import SessionRoleSelect from './SessionRoleSelect';
import SessionTalentSchedule from './SessionTalentSchedule';

import userblank from '../../../assets/user-blank.png';
import roomicon from '../../../assets/roomicon.png';

const INITIAL_STATE = {
    sessionName: '',
    sessionDate: new Date(),
    sessionStart: '',
    sessionWrap: '',
    sessionProject: '',
    sessionRoles: [],
    sessionUsers: [],
    sessionRoom: '',
    sessSlotTimes: '',

    hr1: '',
    hr2: '',
    min1: '',
    min2: '',
    ampm1: 'am',
    ampm2: 'am',

    roomOptions: '',
    projectOptions: '',
    employeeOptions: '',

    showRooms: false,
    showUsers: false,
    showRoles: false,

    currentPage: 'main',
}

export default class NewSessionBody extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sessionName: '',
            sessionDate: new Date(),
            sessionStart: '',
            sessionWrap: '',
            sessionProject: '',
            sessionRoles: [],
            sessionUsers: [],
            sessionRoom: '',
            sessKey: '',
            sessSlotTimes: '',

            hr1: '',
            hr2: '',
            min1: '',
            min2: '',
            ampm1: 'am',
            ampm2: 'am',

            roomOptions: '',
            projectOptions: '',
            roleOptions: '',
            employeeOptions: '',

            showRooms: false,
            showUsers: false,
            showRoles: false,

        }
    }

    componentDidMount() {


        const { uid, firebase } = this.props;

        if (this.props.editSession) {
            var sess = this.props.session;

            this.props.firebase.selectCastingSession(uid, sess.id)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    var session = snapshot.val();
                    var sessionStart = session.start;
                    var sessionWrap = session.wrap;

                    if (sessionStart) { 
                        var hrsplit = sessionStart.split(":");
                        var minsplit = hrsplit[1].split("");
                        var ampm1 = minsplit[2] + minsplit[3];
                        var hr1 = hrsplit[0];
                        var min1 = minsplit[0] + minsplit[1];

                        this.setState({
                            hr1,
                            min1,
                            ampm1: ampm1 === 'a' ? 'am' : 'pm',
                        })
                    };

                    if (sessionWrap) {
                        var hrsplit2 = sessionWrap.split(":");
                        var minsplit2 = hrsplit2[1].split("");
                        var ampm2 = minsplit2[2] + minsplit2[3];
                        var hr2 = hrsplit2[0];
                        var min2 = minsplit2[0] + minsplit2[1];
                        this.setState({
                            hr2,
                            min2,
                            ampm2: ampm2 === 'a' ? 'am' : 'pm',
                        })
                    }

                    if (session.project) {
                    this.props.firebase.viewProjectRoles(sess.project.value)
                    .once('value', snapshot => {
                        if (snapshot.exists()) {
                            var o = snapshot.val();
                            var l = Object.keys(o).map(key => ({
                                ...o[key],
                                id: key,
                            }));
                            if (l) {
                                var roleOptions = [];
                                l.map(i => {
                                    return roleOptions.push({
                                        label: i.roleName,
                                        value: i.id,
                                    })
                                });
                                this.setState({
                                    roleOptions: roleOptions,
                                    showRoles: true,
                                })
                            } else {
                                this.setState({
                                    roleOptions: '',
                                })
                            }
                        } else {
                            this.setState({
                                roleOptions: '',
                            })
                        }
                    })}

                    this.setState({ 
                        session: session,
                        sessKey: this.props.session.id,
                        sessionProject: session.project,
                        sessionRoles: session.roles,
                        sessionStart: session.start,
                        sessionWrap: session.wrap,
                        sessionUsers: session.users ? session.users : [],
                        sessionName: session.name,
                        sessionRoom: session.room,
                        sessSlotTimes: session.slotTimes ? session.slotTimes : '',
                        scheduleCreated: session.scheduleCreated ? true : false,
                        started: session.started,
                    })
                }
            })
        } else {
            var sessKey = firebase.db.ref('user-casting-sessions/' + uid).push().key;
            this.setState({ sessKey: sessKey });

            firebase.draftNewCastingSession(uid, sessKey);
            this.props.firebase.selectCastingSession(uid, sessKey)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    var session = snapshot.val();
                    var sessSlotTimes = session.slotTimes ? session.slotTimes : ''
                    this.setState({
                        session,
                        sessSlotTimes,
                    })
                }
            })
        };

        firebase.user(uid)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var e = o.employees;
                var p = o.projects;
                if (p) {
                    var pl = Object.keys(p).map(key => ({
                        ...p[key],
                        id: key,
                    }))
                }
                if (e) {
                    var el = Object.keys(e).map(key => ({
                    ...e[key],
                    id: key,
                }))}
                if (el) {
                    var userOptions = [];
                    el.map(i => {
                        return userOptions.push({
                            label: i.employeeName,
                            value: i.id,
                        })
                    })
                    this.setState({
                        userOptions: userOptions,
                        showUsers: true,
                    });
                } else {
                    this.setState({
                        userOptions: '',
                    })
                };
                if (pl) {
                    var projectOptions = [];
                    pl.map(i => {
                        return projectOptions.push({
                            label: i.projectName,
                            value: i.id,
                        })
                    })
                    this.setState({
                        projectOptions: projectOptions,
                    });
                } else {
                    this.setState({
                        projectOptions: '',
                    })
                }
            } else {
                this.setState({
                    userOptions: '',
                    projectOptions: '',
                })
            };
        });

        firebase.rooms()
        .orderByChild('createdBy')
        .equalTo((uid))
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }));
                if (l) {
                    var roomOptions = [];
                    l.map(item => {
                        return roomOptions.push({
                            value: item.id,
                            label: item.roomName,
                        })
                    });
                    this.setState({
                        roomOptions: roomOptions,
                        showRooms: true,
                    })
                } else {
                    this.setState({
                        roomOptions: ''
                    });
                };
            } else {
                this.setState({
                    roomOptions: ''
                });
            };
        })
    }

    componentWillUnmount() {
        this.props.firebase.selectCastingSession(this.props.uid, this.state.sessKey).off();
        if (this.props.editSession) {
            this.props.firebase.selectCastingSession(this.props.uid, this.props.session.id).off();
        }
    }

    _onChange = (event) => {
        this.setState({
            [event.target.name]:event.target.value,
        })
    }

    _selectRoom = sessionRoom => {
        this.setState({
            sessionRoom
        });
    };

    _selectDate = sessionDate => {
        this.setState({
            sessionDate
        })
    };

    _alertStarted = () => {
        alert('Already Started')
    }

    _selectProject = sessionProject => {
        this.setState({
            sessionProject,
        });
        this.props.firebase.viewProjectRoles(sessionProject.value)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                var o = snapshot.val();
                var l = Object.keys(o).map(key => ({
                    ...o[key],
                    id: key,
                }));
                if (l) {
                    var roleOptions = [];
                    l.map(i => {
                        return roleOptions.push({
                            label: i.roleName,
                            value: i.id,
                        })
                    });
                    this.setState({
                        roleOptions: roleOptions,
                        showRoles: true,
                    })
                } else {
                    this.setState({
                        roleOptions: '',
                    })
                }
            } else {
                this.setState({
                    roleOptions: '',
                })
            }
        })
    }

    _selectRole = (item, clicked) => {
        var sessionRoles = this.state.sessionRoles;
        if (clicked) {
            sessionRoles.push(item);
        } else if (!clicked && sessionRoles.length === 1) {
            sessionRoles=[];
        } else {
            sessionRoles = sessionRoles.filter(i => {return i.value !== item.value});
        }
        this.setState({
            sessionRoles,
        })
    }

    _selectUser = (item, clicked) => {
        var sessionUsers = this.state.sessionUsers;
        if (clicked) {
            sessionUsers.push(item);
        } else if (!clicked && sessionUsers.length === 1) {
            sessionUsers=[];
        } else {
            sessionUsers = sessionUsers.filter(i => {return i.value !== item.value});
        }
        this.setState({
            sessionUsers,
        })
    };


    _submitSession = () => {
        var s = this.state;
        var uid = this.props.uid;
        var sessionStart = s.hr1 + ':' + s.min1 + ' ' + s.ampm1;
        var sessionWrap = s.hr2 + ':' + s.min2 + ' ' + s.ampm2;
        var sessionDate = moment(this.state.sessionDate).format('MM/DD/YYYY');
        var slotTimes = s.sessSlotTimes ? s.sessSlotTimes : '';
        var hasSche = this.state.scheduleCreated ? true : false;
        this.props.firebase.createNewCastingSession(
            uid, s.sessionName, sessionDate, sessionStart, sessionWrap, s.sessionUsers, s.sessionRoom, s.sessionProject, s.sessionRoles, s.sessKey, slotTimes, hasSche
        );
        this.setState({
            ...INITIAL_STATE
        });
        this.props._closeModal();
    }
    
    _deleteSession = () => {
        this.props.firebase.selectCastingSession(this.props.uid, this.state.sessKey).remove();

        this.props._closeModal();
    }

    _editTalentSchedule = () => {
        this.setState({
            currentPage: 'schedule',
        })
    }

    _createTalentSchedule = () => {
        this.setState({
            currentPage: 'schedule',
        })

        this.setState({
            scheduleCreated: true,
        })

        this.props.firebase.db.ref('user-casting-sessions/' + this.props.uid + '/' + this.state.sessKey)
        .update({
            scheduleCreated: true,
        })
    };

    _backToMain = () => {
        this.setState({ currentPage: 'main' })
    };

    render() {
        

        return (

            <div className="new-session-modal-body">
            {
                this.state.currentPage === 'schedule' ?       
                <SessionTalentSchedule _backToMain={this._backToMain} state={this.state} props={this.props} />
                :
                <div>
                <div className="session-room-container">
                    <div className="session-modal-label">How would you like to label your session?</div>
                    <input className="session-modal-input" value={this.state.sessionName} name="sessionName" onChange={this._onChange.bind(this)} placeholder="Title your session" />
                </div>
                <div className="session-room-container">
                <div className="session-modal-label">Which project is casting?</div>
                <Select 
                    value={this.state.sessionProject}
                    onChange={this._selectProject}
                    placeholder="Select project"
                    options={this.state.projectOptions ? this.state.projectOptions : []}
                />
                </div>
                {
                    this.state.showRoles ?
                        <div>
                        <div className="session-modal-label">Select the roles that are being cast</div>
                        <div className="session-room-container">
                            {
                                this.state.roleOptions ?
                                <div className="user-list-session-modal">
                                    {
                                    this.state.roleOptions.map((item, index) => (
                                        <SessionRoleSelect userblank={userblank} key={item.value} item={item} index={index} _selectRole={this._selectRole} sessionRoles={this.state.sessionRoles} />
                                    ))
                                }
                                </div>
                                :
                                <div>No roles</div>
                            }
                        </div> 
                        </div>
                    :
                    <div />
                }
                <div className="session-room-container">
                    <div className="session-modal-label">When is the session taking place?</div>
                    <div className="session-time-layout">
                    <div className="time-window-section">
                        <div className="session-modal-label sub-label">Start Time</div>
                        <DatePicker 
                            className="session-modal-input center-input" 
                            selected={this.state.sessionDate}
                            onChange={this._selectDate} 
                            dateFormat="MM / dd / yyyy"
                        />
                    </div>
                    <div className="time-window-section">
                        <div className="session-modal-label sub-label">Start Time</div>
                            <div className="time-inputs">
                                <input className="session-modal-time-input center-input" value={this.state.hr1} type="number" name="hr1" onChange={this._onChange.bind(this)} placeholder="10" />
                                <input className="session-modal-time-input center-input" value={this.state.min1} type="number" name="min1" onChange={this._onChange.bind(this)} placeholder="30" />
                                <select className="session-modal-input center-input" value={this.state.ampm1} name="ampm1" onChange={this._onChange.bind(this)}>
                                    <option value="am">AM</option>
                                    <option value="pm">PM</option>
                                </select>
                            </div>
                        </div>
                        <div className="time-window-section">
                            <div className="session-modal-label sub-label">Wrap Time</div>
                            <div className="time-inputs">
                                <input className="session-modal-time-input center-input" disabled={this.state.hr1 === '' || this.state.min1 === '' ? true : false} value={this.state.hr2} type="number" name="hr2" onChange={this._onChange.bind(this)} placeholder="12" />
                                <input className="session-modal-time-input center-input" disabled={this.state.hr1 === '' || this.state.min1 === '' ? true : false} value={this.state.min2} type="number" name="min2" onChange={this._onChange.bind(this)} placeholder="00" />
                                <select className="session-modal-input center-input" disabled={this.state.hr1 === '' || this.state.min1 === '' ? true : false} value={this.state.ampm2} name="ampm2" onChange={this._onChange.bind(this)}>
                                    <option value="am">AM</option>
                                    <option value="pm">PM</option>
                                </select>
                            </div>
                        </div>
                            {
                            this.state.hr2 !== '' & this.state.min2 !== '' ? 
                            this.state.scheduleCreated ? 
                            <button onClick={this._editTalentSchedule} className="talent-schedule-button">Edit Talent Schedule</button>
                            :
                            <button onClick={this._createTalentSchedule} className="talent-schedule-button">Create Talent Schedule</button>
                            :
                            <div />
                            }
                    </div>
                </div>

                <div className="session-room-container">
                    <div className="session-modal-label">Who will be in the session?</div>
                    <div className="user-list-session-modal">
                        {
                            this.state.showUsers && this.state.userOptions ?
                            this.state.userOptions.map((item, index) => (
                               <SessionUserSelect userblank={userblank} key={item.value} item={item} index={index} _selectUser={this._selectUser} sessionUsers={this.state.sessionUsers} />
                            ))
                            :
                            <div>Loading Users</div>
                        }
                    </div>
                </div>
                <div className="session-room-container">
                    <div className="session-modal-label">Which room will this be in?</div>
                    <div className="user-list-session-modal">
                        {
                        this.state.showRooms === true && this.state.roomOptions ?
                        
                        this.state.roomOptions.map((item, index) => (
                            <div key={item.value} className="listed-user-session-modal" onClick={this._selectRoom.bind(this, item)}>
                                <div className="room-icon-session-modal"><img src={roomicon} className={this.state.sessionRoom.value === item.value ? "template-avatar selected-item" : "template-avatar"} alt="User Badge" /></div>
                                <div className="user-name-session-modal">{item.label}</div>
                            </div>
                        ))
                        :
                        <div>Loading Rooms</div>
                    }
                    </div>
                </div>
                <div className="session-room-container">
                
                {
                    this.props.editSession ?
                    <button onClick={this.state.started ? this._alertStarted : this._submitSession} className="submit-session-button">Save Changes</button>
                    :   
                    <button onClick={this.state.started ? this._alertStarted : this._submitSession}  className="submit-session-button">Create Session</button>
                }
                </div>
                <div className="session-room-container">
                    <button onClick={this._deleteSession} className="delete-session-button">Delete Session</button>
                </div>
                
            </div>
            }
        </div>
        
        )
    };
};