import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { compose } from 'recompose';
import checkinlogo from '../../assets/logos/check-in-logo.png';
import clock from '../../assets/landing/clock.png';
import light from '../../assets/landing/light.png';
import notepad from '../../assets/landing/notepad.png';
import { signUpRequest } from '../Functions/Emails';

import './landing.css';

//<Nav setPage={setPage} page={page} history={props.history} />

const panels = [
  {
    title: 'Check-in Alerts',
    description: 'Get notified when guests arrive, and ping the front desk when you are ready',
    icon: light,
  },
  {
    title: 'Team Notes',
    description: 
      'Add more collaboration to your sessions with our innovative, mobile-friendly notepad',
    icon: notepad,
  },
  {
    title: 'Time Tracking',
    description: 'Our built-in analytics help you stay on schedule so you can see more candidates',
    icon: clock,
  }
];


function LandingCompose(props) {

  const [page, setPage] = useState('home');
  
  return (
    <div className="landing-page">
      <div className="landing-background-color">
        <div />
        <div className="landing-background-color-bottom" />
      </div>
      <button onClick={() => props.history.push(ROUTES.SIGN_IN)} className="landing-login">Sign in</button>
      <div className="landing-container no-scroll-bars">
        <Header />
        <Body page={page} history={props.history} />
        <Footer />
      </div>
    </div>
  )
}

function Header(props) {

  const [email, setEmail] = useState('');
  const [requested, setRequested] = useState(false);

  function register() {
      if (email.includes('@')) {
        var e = email;
        signUpRequest(e);
        setEmail('');
        setRequested(true);
      }
  };

  return (
    <div className="landing-header">
      <img className="landing-logo" src={checkinlogo} alt="Logo" />
      <div className="header-tagline">Streamline your interview process</div>
      <div className="request-layout">
      {/*<div className="request-access-box">
        <input 
          placeholder="Type your email address..."
          className={
            email.length > 0 ? 
            "request-access-input request-access-live" :
            "request-access-input"} 
            value={email} type="email" onChange={(e) => setEmail(e.target.value)} />
        <button 
          className={
            email.length > 0 ?
            "landing-action-btn landing-btn-live" :
            "landing-action-btn"} onClick={() => register()}>Request Access!</button>
      </div>
      {
        requested ?
        <div className="landing-sent">Thank you! We will reach out to you shortly</div>
        :
        null
      }*/}
      </div>

    </div>
  )
}

function Nav() {
  return (
    <div />
  )
}
function Body(props) {

  return (
    <div className="landing-body">  
      <div className="header"></div>
      <div className="panels">
        {
          panels.map((item, index) => (
            <PanelItem key={index} item={item} />
          ))
        }
      </div>
    </div>
  )
}

function PanelItem(props) {

  var i = props.item;

  return (
    <div className="landing-panel-item">
      <div className="landing-panel-rows">
        <img className="landing-panel-icon" src={i.icon} alt="Icon" />
        <div className="landing-panel-label">{i.title}</div>
        <div className="landing-panel-description">{i.description}</div>
      </div>
    </div>
  )
}

function Footer() {

  return (
    <div className="landing-footer">

    </div>
  )
}

function AboutSection(props) {

  return (
    <div />
  )
}

function LandingAction(props) {

  return (
    
    <div className="landing-action">
      <div className="action-label">ANi Check-In</div>
      <button onClick={() => props.history.push(ROUTES.SIGN_UP)}>Try it free!</button>
    </div>  
    
  )
}


const LandingPage = compose(
  withRouter,
)(LandingCompose);

export default LandingPage;


// <img className="ani-landing-img" src={anilandingpicture} alt="Picture of Application" />


