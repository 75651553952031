import React, { Component } from 'react';
import Select from 'react-select';

import { CreateRPS } from './Features/CreateRPS';
import { EmployeeFAQ } from './Features/EmployeeFAQ';
import { RoomFAQ } from './Features/RoomFAQ';
import { FormFAQ } from './Features/FormFAQ';
import { AssignFAQ } from './Features/AssignFAQ';
import { NotesFAQ } from './Features/NotesFAQ';
import { ChatFAQ } from './Features/ChatFAQ';

import './faq.css';

const topicOptions = [
    {
        value: 0,
        label: 'Creating and utilizing employees accounts'
    },
    {
        value: 1,
        label: 'Managing my rooms, projects and roles',
    },
    {
        value: 2,
        label: 'Setting up a room for a session',
    },
    {
        value: 3,
        label: 'Setting up the actor check in form'
    },
    {
        value: 4,
        label: 'Assigning actors to rooms'
    },
    {
        value: 5,
        label: 'Keeping notes on actors'
    },
    {
        value: 6,
        label: 'Everything group chat'
    },

]

export default class HelpBox extends Component {

    constructor(props) {
        super(props);

        this.state = {
            topics: '',
            currentTopic: '',
        };
    };

    _selectTopic = currentTopic => {
        this.setState({
            currentTopic,
        })
    }

    _showTopic = (currentTopic) => {
        switch(currentTopic.value) {
            case 0: return <EmployeeFAQ />;
            case 1: return <CreateRPS />;
            case 2: return <RoomFAQ />;
            case 3: return <FormFAQ />;
            case 4: return <AssignFAQ />;
            case 5: return <NotesFAQ />;
            case 6: return <ChatFAQ />;
            default: return (
                <div className="no-doc">Please select a topic to view documentation!</div>
            )

        }
    }

    render() {
        return (
            <div className="help-box-container">
                <button className="close-help" onClick={this.props._closeHelp}>Close</button>
                <div className="help-box-header">
                <Select 
                    className="help-select"
                    options={topicOptions}
                    value={this.state.currentTopic}
                    placeholder="What would you like to know more about?"
                    onChange={this._selectTopic}
                />
                </div>
                <div className="help-box-body">
                    <div className="body-section-features">
                        <div className="body-content">
                        {
                            this.state.currentTopic === '' ?
                            <div className="no-doc">Please select a topic to view documentation!</div>
                            :
                            <div className="topic-container">
                                {this._showTopic(this.state.currentTopic)}
                            </div>
                        }
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }
}

/*
<div className="body-section">
    <div className="body-label">Make a suggestion</div>
    <textarea className="suggestion-textarea" placeholder="What would make this better for you?"/>
</div>
*/