import React, { Component } from 'react';

import TalentNoteItem from './TalentNoteItem';

export default class TalentNotes extends Component {

    constructor(props) {
        super(props);

        this.state = {
            privacy: false,
        }
    }

    _prepSessionNote = () => {
        var privacy = this.state.privacy;
        this.props._addSessionNote(privacy);
    }

    componentWillReceiveProps(nextProps, props) {
        if (nextProps.notes !== this.props.notes) {
            this.setState({
                notes: nextProps.notes,
            })
        }
    }

    render() {

        var props = this.props;
        var activeGroup = this.props.activeGroup;

        return (
            <div>
            <div className="new-note-input-container">
                <input className="new-note-textarea-room" placeholder="Add a note here..." value={props.note} onChange={props._onNoteChange} onKeyDown={props._onEnterNote}/>
                <button className="new-note-submit-btn" onClick={activeGroup ? this._prepSessionNote : props._addNote}>+</button>
            </div>
            <div className="note-display-room-view">
            {
            
            props.notes !== '' ? 
            props.notes.map((n, index) => {
                var initials = activeGroup && n.employee ? n.employee.split(" ").map((n) => n[0]).join("") : n.noteBy.split(" ").map((n) => n[0]).join("");
                return (
                <TalentNoteItem key={index} initials={initials} n={n} _removeNote={props._removeNote} />
                )
            })
            :
            <div className="default-null-message">No notes</div>
            }
            </div>
            </div>
        )
    }
}