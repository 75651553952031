import React, { useState, useEffect }  from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { Input } from '../Common';
import { ObjectList } from '../Functions/Common';

import './quickmenu.css';

import company from '../../assets/nav/company.png';
import device from '../../assets/nav/device.png';
import gear from '../../assets/nav/gear.png';
import line from '../../assets/nav/line.png';
import form from '../../assets/nav/office-form.png';
import positions from '../../assets/nav/positions.png';
import rooms from '../../assets/nav/rooms.png';
import search from '../../assets/nav/search.png';
import shapes from '../../assets/nav/shapes.png';
import tags from '../../assets/nav/tags.png';
import greeting from '../../assets/nav/greeting.png';


function QuickMenu(props) {

        const [filter, setFilter] = useState('');

        return (
            <div className="nav-content margin-center nav-text-color">
                <div className="nav-userbox">
                    <h2>{props.username}</h2>
                </div>
                <div />
                <div className="height-20" />
                <div className="qui-men-label">
                    Operations
                </div>
                <TabItem tab="sessions" setTab={props.setPage} icon={shapes} alt="Icon" text="Sessions List" />
                <TabItem tab="arrived" setTab={props.setTab} icon={line} alt="Icon" text="Current Guests" />
                <LinkItem route={ROUTES.CHECK_IN} icon={form} alt="Open Check-In" text="Open Check In Form" set="form" />
                <ActionItem tab='greeting' icon={greeting} alt="Icon" text="Edit Greeting" 
                    component={<Greeting firebase={props.firebase} uid={props.uid} cid={props.cid} />}
                />
                {/*<ActionItem tab='greeting' icon={greeting} alt="Icon" text="Mobile Check-in" 
                    component={<MobileAccess firebase={props.firebase} uid={props.uid} cid={props.cid} />}
                />*/}
                <div className="height-20" />
                <div className="qui-men-label">
                    Quick Manage
                </div>
                <TabItem tab='projects' setTab={props.setTab} icon={positions} alt="Icon" text="Positions" />
                <TabItem tab='rooms' setTab={props.setTab} icon={rooms} alt="Icon" text="Rooms" />
                <TabItem tab='tags' setTab={props.setTab} icon={tags} alt="Icon" text="Team Tags" />
                <ActionItem tab='device' icon={device} alt="Icon" text="Notifications" 
                    component={<Notifications firebase={props.firebase} uid={props.uid} cid={props.cid} />}
                />

                <div className="height-20" />
                {
                    props.p === 'admin' ?
                <div>
                    <div className="qui-men-label">
                        Settings
                    </div>
                    {/*<TabItem tab="mysettings" setTab={props.setPage} icon={gear} alt="Icon" text="My Settings" />*/}
                    <TabItem tab="company" setTab={props.setPage} icon={company} alt="Icon" text="Company Settings" />
                    <div className="height-10" />
                </div>
                :
                null 
                }
                <div className="qui-sign-out" onClick={props._signOut}>Log Out</div>
            </div>
        )
}

const LinkItem = (props) => (
    <Link 
        to={props.route} 
        style={{ textDecoration: 'none' }}
        target={props.set === 'form' ? "_blank" : null}
        >
        <div className="qui-men-option nav-text-color">
            <img className="qui-men-icon" src={props.icon} alt={props.alt} />
            {props.text}
        </div>
    </Link>
)

function ActionItem (props) {

    const [action, setAction] = useState(false);

    return (
        <div>
            <div className="qui-men-option" onClick={() => setAction(!action)}>
                <img className="qui-men-icon" src={props.icon} alt={props.alt} />
                {props.text}
            </div>
            {
                action ? 
                props.component : null
            }
        </div>
    )
}

function Notifications(props) {

    return (
        <div />
    )
}

const TabItem = (props) => (
    <div className="qui-men-option" onClick={() => props.setTab(props.tab)}>
        <img className="qui-men-icon" src={props.icon} alt={props.alt} />
        {props.text}
    </div>
);

const InputItem = (props) => (
        <input className="nav-input margin-center" placeholder="Search name, session or position" src={props.icon} alt={props.alt} />

);

function Greeting(props) {

    const [greeting, setGreeting] = useState('');
    const [saved, setSaved] = useState('');
    const [savedStatus, setSavedStatus] = useState(false);

    useEffect(() => {
        if (props.cid) {
            props.firebase.apiCompany(props.cid)
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    var o = snapshot.val();
                    setGreeting(o.greeting || '');
                    setSaved(o.greeting || '');
                } else {
                    setGreeting('');
                }
            })
        }
    }, [props.cid]);

    function saveGreeting() {
        props.firebase.apiCompany(props.cid)
        .update({
            greeting: greeting,
        })
        setSavedStatus(true);
        var saveTimer = setTimeout(() => {
            setSavedStatus(false);
            setSaved(greeting);
        }, 1000);
        return (() => clearTimeout(saveTimer));
    };

    var placeholder = "This message will display after people check-in using the form";
    return (
        <div className="relative">
        <textarea 
            placeholder={placeholder} 
            className="quick-menu-customize-greeting-textarea" 
            value={greeting} 
            onChange={(e) => setGreeting(e.target.value)} 
        />
        {
            greeting !== saved ? 
            <button onClick={() => saveGreeting()} id="greeting-save" className="save-change-btn">{savedStatus ? 'Saved!' : 'Save Changes'}</button>
            : null
        }
        </div>
    )

}

function MobileAccess(props) {

    const [code, setCode] = useState('');
    const [oldCode, setOldCode] = useState('');
    const [taken, setTaken]= useState(false);

    useEffect(() => {
        if (props.cid) {
            props.firebase.apiCompany(props.cid)
            .child('mobileAccess')
            .once('value', snapshot => {
                var o = snapshot.val();
                setCode(o);
                setOldCode(o);
            });
        };
    }, [props.cid]);

    function confirmCode() {
        props.firebase.apiMobileForm(code)
        .once('value', snapshot => {
            if (snapshot.exists()) {
                setTaken(true);
            } else {
                props.firebase.apiAddMobileCode(code, props.cid);
                setOldCode(code);
            };
        });
    };

    return (
        <div className="relative">
            <div>Create Mobile Access Code</div>
            <Input 
                value={code}
                onChange={(e) => setCode(e.target.value)}
            />
            {
                taken ?
                <div className="taken-message">Please use a different code</div>
                :
                null
            }
            {oldCode !== code ?
            <button className="save-changes-btn" onClick={() => confirmCode()}>Confirm Code</button>
            :
            null 
            }
        </div>
    )
}

export { QuickMenu };