import React, { Component } from 'react';

import ScheduleGrid from './ScheduleGrid';
import ScheduleForm from './ScheduleForm';
import QuickAddForm from './QuickAdd/QuickAddForm';
import QuickAddList from './QuickAdd/QuickAddList';

import arrowdown from '../../../assets/arrowdown.png';

export default class ScheduleModalBody extends Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            quickAdd: true,
        }
    };

    togglePage = () => {
        this.setState({
            quickAdd: !this.state.quickAdd
        });
    };

    render() {
        return (
            this.state.quickAdd ?
            <div className="quick-add-modal-layout">
                <QuickAddForm firebase={this.props.firebase} uid={this.props.uid} togglePage={this.togglePage} />
                <QuickAddList firebase={this.props.firebase} uid={this.props.uid} />
            </div>
            :
            <div className={this.state.expanded ? "schedule-modal-layout" : "schedule-modal-layout-min"}>
                {
                    this.state.expanded ? 
                    <div className="schedule-form-container">
                        <div className="min-form" onClick={(() => this.setState({expanded: false}))}>
                        <img className="min-form-arrow" src={arrowdown} alt="Minimize" />
                        </div>
                        <ScheduleForm firebase={this.props.firebase} uid={this.props.uid} togglePage={this.togglePage} />    
                    </div> 
                    : 
                    <div className="schedule-form-container">
                        <div className="schedule-form" onClick={(() => this.setState({expanded: true}))}><div className="expand-appt">+</div></div>
                    </div>
                }
                <div className="schedule-container">
                    <ScheduleGrid firebase={this.props.firebase} uid={this.props.uid} />
                </div>
            </div>
        )
    };
};