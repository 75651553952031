import React from 'react';

import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';

import Select from 'react-select';

const SearchSidebar = (props) => {

        return (

            <div>

                <div className="sidebar-header">
                    <Link className="acct-settings-back-btn" to={ROUTES.HOME}>Back to Dashboard</Link>
                    <br />
                    <br />
                    <div className="sidebar-title">Advanced Search</div>
                </div>
                <div className="sidebar-body">
                    <div className="sidebar-section-container"> 
                        <div className="sidebar-label">Custom filter</div>
                        <input className="advanced-search-bar" placeholder="Type name, title, role or rep" value={props.searchbar} name="searchbar" onChange={props._onChangeSearchBar.bind(this)} />
                    </div>
                    <div className="sidebar-section-container">
                        <div className="sidebar-label">Filter by project</div>
                        <Select 
                            options={props.projectOptions ? props.projectOptions : [{value: '', label: 'No projects'}]}
                            value={props.projectSelect}
                            onChange={props._handleProjectSelect}
                            />
                    </div>
                    <div className="sidebar-section-container">
                        <div className="sidebar-label">Filter by role</div>
                        <Select 
                            options={props.roleOptionsFilter ? props.roleOptionsFilter : [{value: '', label: 'No roles'}]}
                            value={props.roleSelect}
                            onChange={props._handleRoleSelect}
                            />
                    </div>
                    <div className="sidebar-section-container">
                        <div className="sidebar-label">Filter by tags</div>
                        {
                        props.tags ?
                        props.tags.map((tag, index) => (
                            <div 
                                key={tag.id} 
                                className={props.selectedTags.includes(tag.id) ? "search-tag search-tag-selected" : "search-tag"} 
                                onClick={props.selectedTags.includes(tag.id) ? props._handleTagDeselect.bind(this, tag) : props._handleTagSelect.bind(this, tag)}
                                >
                                {tag.name}
                            </div>
                        ))
                        :
                        <div className="description">No tags created</div> 
                    }
                    </div>
                </div>

            </div>
        )
    };

export { SearchSidebar };