import React, { useState } from 'react';

import trashgray from '../../../../assets/trashmodern.png';
import trashred from '../../../../assets/trashmodernred.png';



const NavModalNav = (props) => {

    const [hover, setHover] = useState(false);

    const state = props.state;

    return (
        <div className="nav-modal-nav-container">
            <div className="nav-modal-title">New Session</div>
            <div className="nav-modal-links">
                <div className={state.contentPage === 1 ? "nav-modal-link link-selected" : "nav-modal-link"} onClick={props._page1}>Opportunity</div>
                <div className={state.contentPage === 2 ? "nav-modal-link link-selected" : "nav-modal-link"} onClick={props._page2}>Session Details</div>
                <div className={state.contentPage === 3 ? "nav-modal-link link-selected" : "nav-modal-link"} onClick={props._page3} >Talent List</div>
                <div className={state.contentPage === 4 ? "nav-modal-link link-selected" : "nav-modal-link"} onClick={props._page4}>Operations</div>
            </div>
            <div 
                className="delete-session-btn" 
                onMouseOver={(() => setHover(true))}
                onMouseLeave={(() => setHover(false))}
                onClick={props._deleteSession}
            >
                <img className="delete-session-icon" src={hover ? trashred : trashgray} alt="Remove Session" />
                <div className="nav-modal-link">Delete Session</div>
            </div>
        </div>
    )
}

export { NavModalNav };