import React from 'react'

const TalentAuditions = (props) => {

        return (
            <div className="tal-dash-appts-container">
                <div className="tal-dash-section-label">My Auditions</div>
                {
                    props.state.auditions ?
                    props.state.auditions.map((item, index) => (
                        <div key={item.id} className="tal-dash-appt-item">
                            <div className="tal-dash-appt-project">{item.p}</div>
                            <div className="tal-dash-appt-role">{item.r}</div>
                            <div className="tal-dash-appt-date">{item.date}</div>
                            <div className="tal-dash-appt-time">{item.time}</div>
                            <div className="tal-dash-appt-status">{item.status}</div>
                        </div>
                    ))
                    :
                    <div>No auditions recorded</div>
                }
            </div>
        )
    }

export { TalentAuditions };


