import React from 'react';

export default function TakesListItem(props) {
    var takes = props.item.takes ? props.item.takes : '';
    var takeList = takes ? Object.keys(takes).map(key => ({ ...takes[key], id: key })) : '';

    return (
        <div className="s-note-container">
            <div className="sd-talent-take-name">{props.item.name}</div>
            <div className="sd-talent-take-list">
                {
                    takeList ? 
                    takeList.map((item, index) => (
                        <div key={item.id} className="sd-talent-take-take-item">
                        <div className="sd-talent-take-number">
                            Take {index + 1}
                        </div>
                        <div className="sd-talent-take-count">
                            {item.count  / 1000 + ' seconds'}
                        </div>
                        {
                            item.favorite ?
                            <div className="sd-talent-take-favorite">
                                Favorite 
                            </div>
                            :
                            <div />
                        }
                        <div className="sd-talent-take-pins">
                            <div className="pins-label">Pins</div>
                            {
                                item.marks ?
                                Object.keys(item.marks).map(key => ({ ...item.marks[key], id: key })).map((mark, index) => (
                                    <div key={mark.id} className="sd-talent-take-pin-item">
                                        {mark.time / 1000 + ' seconds'}
                                    </div>
                                ))
                                :
                                <div />
                            }
                        </div>
                    </div>
                    ))
                    :
                    <div />
                }
            </div>
        </div>
    )
};
