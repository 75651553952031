import React, { Component } from 'react';

export default class ActiveCount extends Component {

    constructor(props) {
        super(props);

        this.state = {
            length: '',
        }
    }
 
    componentDidMount() {

        var item = this.props.item;
        var firebase = this.props.firebase;
        var projectId = item.id;

        firebase.projectSelect(projectId)
        .on('value', snapshot => {
            if (snapshot.exists()) {
                var p = snapshot.val();
                if (p.roles) {
                    var obj = p.roles;
                    var list = Object.keys(obj).map(key => ({
                        ...obj[key],
                        id: key,
                    }))
                    
                    const active = list.filter((item) => {
                    return item.isActive
                    })

                    this.setState({
                        length: active.length
                    })
                } else {
                    this.setState({
                        length: 0,
                    })
                }
            } else {
                this.setState({
                    length: 0,
                })
            }
        })
    }

    componentWillUnmount() {
        var item = this.props.item;
        var projectId = item.id;
        this.props.firebase.projectSelect(projectId)
        .off();
    }

    render() {

        const { length } = this.state;

        return (

            <div className="role-count">{length} active roles</div>
        )
    }
}