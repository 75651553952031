import React, { useState, useEffect } from 'react';
import DashSessionsItem from './DashSessionsItem';

export default function DashSessionsList(props) {

    const [access, setAccess] = useState('');

    useEffect(() => {
        var data = props.props.userData;
        if (data) {
            var acc = data.access;
            setAccess(acc);
        }
        
    }, [props.props.userData])


    return (
        <div className="dash-session-list-container">

            {
                access ? 
                access.map((item, index) => (
                    <DashSessionsItem 
                        item={item} 
                        firebase={props.props.firebase} 
                        key={item} 
                    />
                ))
                :
                <div>You don't have any sessions listed.</div>
            }

        </div>

    )

}
